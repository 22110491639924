// import { LocalConvenienceStoreOutlined } from "@mui/icons-material";
import { useState } from "react";
// import isEmail from 'validator/es/lib/isEmail';
// import isMobilePhone from 'validator/es/lib/isMobilePhone';
import { useCreateTagMasterMutation, useCreatingSampleMutation, useUpdatigsampleMutation } from "../../../services/adminLogIn";

const StrenthUomLogic = () => {


    const [showerror, setErrorMessage] = useState(false);

    const [AllTextFieldState, setTextFieldState] = useState({
        is_update: false,
        strengthUOMCode: '',
        strengthUOMDescription: '',
        active: true
    });


    const [open, setOpen] = useState(false);
    const [openFilterModal, setOpenFilterModal] = useState(false);
    const [isSearchEvent, setIsSearchEvent] = useState(false);
    const [status, setStatus] = useState("");
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleFilterModalOpen = () => { setOpenFilterModal(true); }
    const handleFilterModalClose = () => { setOpenFilterModal(false); }

    const [filterData, setFilterData] = useState({ classificationId: "", sampleName: "", filterStatus: "" });


    const [dropdownState, setDropdowntate] = useState({
        strengthUOMCode: '',
        strengthUOMDescription:'',
        isActive: true
    });

    const [isError, setError] = useState({
        isdurationCodeError: false,
        isdisplayError: false,
       
       
    });

    // dropdown handler 
    const handleDropDownChange = (e, v) => {
        const value = v;
      
        if (e === "system") {
            setTextFieldState((prev) => ({
                ...prev,
                [e]: value,
            }))
            // setError((prev) => ({
            //     ...prev,
            //     isTagMasterTypeError: isError.isRoute = false
            // }))
        }


        if (e === "status") {
            handleOpen()
        }

    }

    const halndleClickonProceed = () => {
        console.log( AllTextFieldState.status)
        setTextFieldState((prev) => ({
            ...prev,
            ["status"]: AllTextFieldState.status === true ? false : true

        }))
        setStatus(AllTextFieldState.status === true ?  false : true)
        handleClose()
    }


    const resetErrorHandler = (e) => {
        const name = e.target.strengthUOMCode;
        switch (name) {
            case "strengthUOMCode":
                setError((prev) => ({
                    ...prev,
                    isdurationCodeError: isError.isdurationCodeError = false
                }))
                break;

            case "strengthUOMDescription":
                setError((prev) => ({
                    ...prev,
                    isdurationDescriptionError: isError.isdurationDescriptionError = false
                }))
                break;
           
            

        }
    }

    // form on submit handleer 

    const whenInputsChange = (e) => {
        const { name, value } = e.target;
        switch (name) {
            case "strengthUOMCode":
                setError((prev) => ({
                    ...prev,
                    isdurationCodeError: isError.isdurationCodeError = false
                }))
                break;

            case "strengthUOMDescription":
                setError((prev) => ({
                    ...prev,
                    isdurationDescriptionError: isError.isdurationDescriptionError = false
                }))
                break;
           
          
        }
        setTextFieldState((prev) => ({
            ...prev,
            [name]: value

        }))


    }
    // exporting all handler and state and etc
    const exportAll = {
        whenInputsChange,
        handleDropDownChange,
        //    dropsampleState,
        setError,
        AllTextFieldState,
        isError,
        resetErrorHandler,
        setTextFieldState,
        //    setDropsampleState,
        dropdownState,
        setDropdowntate,
        open,
        handleClose,
        handleOpen,
        setOpen,
        halndleClickonProceed,
        setErrorMessage,
        handleFilterModalOpen,
        handleFilterModalClose,
        openFilterModal,
        showerror,
        filterData,
        isSearchEvent,
        setIsSearchEvent,
        //    districtlist

    }

    return exportAll;
}

export default StrenthUomLogic;
