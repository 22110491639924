import React, { useState } from 'react';
import { Grid } from '@mui/material';
import '../table.css';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

const ProgramNameList = (props) => {
  const { hasFocus, value,handleRowClick ,setId,id} = props;


  const handleClick = (row) => {
    let data = {};
    data.is_update = true;
    handleRowClick(row);
    setId(row.programId);
    props.showFormActions();
  };

  const handleOnChange = (event, page) => {
    // props.programListData(page)
    props.programListDataPage(page);
    
  };
  function formatDate(dateString) {
    if (!dateString) {
      return ''; // Return an empty string if dateString is null or empty
    }
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1; // Months are 0-indexed
    const year = date.getFullYear();
  
    return `${day}/${month}/${year}`;
  }
  return (
    <Grid className="">
      <div className="scrollit">
        {props.tagsview === true && props.programList && props.programList.length > 0 ? (
          <table className="b" style={{ width: '90%', marginLeft: '20px', tableLayout: 'fixed', height: '20vh' }}>
            {/* Table Header */}
            <thead style={{ position: 'sticky', zIndex: '1', top: '0' }}>
              <tr>
                <th className="m-r-f" style={{ width: '50px' }}>
                  <input type="checkbox" id="" name="vehicle1" value="Bike" />
                </th>
                <th className="m-r-f">Program Name</th>
                <th className="m-r-f">Start Date</th>
                <th className="m-r-f">End Date</th>
                <th className="m-r-f">Status</th>
              </tr>
            </thead>
            {/* Table Body */}
            <tbody>
              {props.programList.map((item, i) => (
                <tr
                  key={i}
                  className="tablebor"
                  style={{
                    cursor: 'pointer',
                    backgroundColor: id === item.programId ? 'rgb(15, 67, 145)' : '',
                    color: id === item.programId ? 'white' : '',
                  }}
                >
                  <td>
                    <input type="checkbox" id={i} />
                  </td>
                  <td className="m-r-f" onClick={() => handleClick(item)}>
                    <a>{item.programName}</a>
                  </td>
                  <td className="m-r-f" onClick={() => handleClick(item)}>
                    <a>{formatDate(item?.startDateTime)}</a>
                  </td>
                  <td className="m-r-f" onClick={() => handleClick(item)}>
                    <a>{formatDate(item.endDateTime)}</a>
                  </td>
                  <td className="m-r-f" style={{ paddingLeft: '' }} onClick={() => handleClick(item)}>
                    {item.isDeleted}
                    <span className={item.isDeleted ? 'dotr' : 'dotg'}></span>{' '}
                    {item.isDeleted ? ' Inactive' : ' Active'}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className="record">No Records Found</div>
        )}
      </div>
       {props.tagsview===true && props.programList&& props.programList && props.programList.length>0 &&
      <div className="pagesam pt-1 m-1">
        
        <Stack spacing={2}>
          <Pagination count={props?.totalPage} color="primary" page={props.currentPage} onChange={(e, page) => handleOnChange(e, page)} />
        </Stack>
        <div className='pagenum'>
          <span className='ml-2'>{props?.calculateData?.number * 20 + 1}-{props?.calculateData?.number * 20 + props?.calculateData?.numberOfElements} of {props?.calculateData?.totalElements}</span>
        </div>

       
      </div>
        }
    </Grid>
  );
};

export default ProgramNameList;
