import * as React from 'react';
import { useState, useEffect, useRef } from "react";
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import autoprefixer from 'autoprefixer';
import { useGetActiveOrgValueMutation, useGetActiveStateValueMutation,useSearchCountryListMutation } from "../../../services/adminLogIn";
import Link from '@mui/material/Link';
import useOrgHook from "../orga/OrgCusHook";
import { Grid } from '@mui/material';
import "../table.css"
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

const OrgTable = (props) => {
  const { hasFocus, value } = props;

  const buttonElement = React.useRef(null);
  const rippleRef = React.useRef(null);

  const [data, setData] = useState([]);
  const [rowHighlight, setrowHighlight] = useState(false);
  const [id, setId] = useState("");
  const [access, setAccess] = useState(
    JSON.parse(sessionStorage.getItem("access"))
  );
  const [searchCountry] = useSearchCountryListMutation();
  useEffect(() => {
    setData(props.orgList)
    if (props.calculateData.number != 0) {
      // setStartValue(props.calculateData.number*20+1)
      // setEndValue(props.calculateData.number*20+props.calculateData.numberOfElements)
    }
    // getActiveValFun();
  }, [])


  // const whenInputsChange = (e)=>{
  //   const {name , value} = e.target;
  //   if(name==="sampleTypeName"){
  //        setbodyTextFieldState((prev)=>({
  //            ...prev,
  //            [name]:value.replace(/[^a-z]/gi, "")

  //         }))
  //   }
  // }

  const handleClick = async (row) => {
    const resp = await searchCountry({"searchStr": row.address[0].countryISOCode})
    let data = {}
    resp.data.content.forEach(element => {
    if(element.isoCode == row.address[0].countryISOCode){
    data.country = {"country" : element.country, "isoCode" : element.isoCode}}    });
    data.is_update = true
    data.name = row.name
    data.level = row.level
    data.active = row.active
    data.id = row.id
    setId(row.id)
    data.address = row.address
    data.postalCode = row.address[0].postalCode
    data.city = row.address[0].city
    data.state = {
      name: row.address[0].state,
      isUt: false
    }
    data.city = {
      name: row.address[0].city,
      stateName: row.address[0].state,
    }
    data.phoneNumber = row.telecom[0] && row.telecom[0].value
    data.emailId = row.telecom[0] && row.telecom[1].value
    data.partOf = row?.partOf?.reference
    let data1 = {}
    data1.id = row.partOf && row.partOf != null && row.partOf.identifier && row.partOf.identifier.value
    data1.name = row.partOf && row.partOf != null && row.partOf.identifier && row.partOf.identifier.value
    console.log(data1)
    props.setDropOrgState({ ...data1 })
    props.setErrorMessage(false)

    props.setOrgTextFieldState({ ...data })
    props.showFormActions()
    setrowHighlight(true)

    // }
  };
  const handleOnChange = (event, page) => {
    props.getorgapageList(page)
  };
  return (
    <Grid className=""  >
      <div class="scrollit">
        {props.organisationview===true  && props.orgList && props.orgList.length > 0 ?
          <table style={{ width: '90%', marginLeft: '20px', height: "10vh" }}>
            <thead >
              <tr>
                <th className=" m-r-f" style={{ width: '50px' }}><input type="checkbox" id="" name="vehicle1" value="Bike" /></th>
                <th className=" m-r-f" >Organisation Name</th>
                <th className=" m-r-f" >Part Of</th>
                <th className=" m-r-f" >Status</th>

              </tr>
            </thead>

            <tbody style={{ color: "#000000" }}>
              {props && props.orgList && props.orgList.length > 0 && props.orgList.map((item, i) => {
                return (
                  <tr key={i} className="" style={{ cursor: 'pointer', backgroundColor: id === item.id ? "rgb(15, 67, 145)" : "", color: id === item.id ? "white" : "" }}>
                    <td  >< input type="checkbox" id={i} /></td>
                    <td className=" m-r-f" onClick={access?.organisationupdate ? () => handleClick(item) : null}  ><a >{item.name}</a></td>
                    <td className=" m-r-f" onClick={access?.organisationupdate ? () => handleClick(item) : null} >{item?.partOf?.identifier?.value}</td>
                    <td className=" m-r-f" onClick={access?.organisationupdate ? () => handleClick(item) : null}><span className={item.active.toString() === "true" ? "dotg" : "dotr"} ></span>  {item.active.toString() === "true" ? " Active" : " Inactive"}</td>
                  </tr>

                )
              })
 
              }
            </tbody>

          </table>
          :
          <div className='record'>
            No Records Found
          </div>}
      </div>
      {props.organisationview===true  && props.orgList && props.orgList.length > 0 &&
        <div className="pagesam pt-1 m-1">

          <Stack spacing={2}>
            <Pagination count={props.totalPage} color="primary" onChange={(e, page) => handleOnChange(e, page)} page={props.currentPage} />
          </Stack>

          <div className='pagenum'>
            <span className='ml-2'>{props.calculateData.number * 20 + 1}-{props.calculateData.number * 20 + props.calculateData.numberOfElements} of {props.calculateData.totalElements}</span>
          </div>

        </div>
      }

    </Grid>

  );

}

export default OrgTable
