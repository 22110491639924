import { useState, useEffect, useRef } from "react";
import AdminHome from "../AdminHome";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import TagLogic from "./taglogic";
import TagList from "./taglist";
import filter from "../../../images/filter.png";
import {
  useGetTagListMutation,
  useCreateTagMasterMutation,
  useUpdatigsampleMutation,
  useGetTagByTagMasterNameMutation,
  useGetUserByUserIdMutation,
  useGetUsageCountTagMutation,
  useUpdateTagMasterMutation
} from "../../../services/adminLogIn";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import "../Organization/Mainhome.css";
import { Grid } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Breadcrums from "../../global/breadcrums/Breadcrums";
import { useSelector } from "react-redux";
import { toasterFunction } from "../../../globalFunctions/getLocalStorageData";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminCookieConsentBanner from "../home/AdminCookieConsentBanner";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const Tags = (props) => {
  const [createApi] = useCreateTagMasterMutation();
  const [updateApi] = useUpdateTagMasterMutation();
  const [getUserByUserId] = useGetUserByUserIdMutation();
  const [getUsageCountTag] = useGetUsageCountTagMutation();
  const [getTagByTagNameApi] = useGetTagByTagMasterNameMutation();
  const [access, setAccess] = useState(
    JSON.parse(sessionStorage.getItem("access"))
  );

  const [expand, setexpand] = useState({
    heading: "",
    icon: "",
    isSubListExpanded: false,
  });
  const [expand1, setexpand1] = useState({
    empty: true,
  });
  const [getTagMasterList] = useGetTagListMutation();

  const [isNewData, setIsNewData] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [calculateData, setTotalPageCalculate] = useState({
    number: "",
    numberOfElements: "",
    totalElements: "",
    showcount: "",
  });

  const {
    whenInputsChange,
    AllTextFieldState,
    isError,
    dropdownState,
    setDropdowntate,
    handleDropDownChange,
    resetErrorHandler,
    setTextFieldState,
    setDropdownState,
    open,
    handleClose,
    setErrorMessage,
    showerror,
    setError,
    halndleClickonProceed,
  } = TagLogic();
  const [statuslist] = useState(["ACTIVE", "INACTIVE"]);
  const [tagTypeList] = useState(["Regular Tag", "Date Tag"]);
  const [totalPage, setTotalPage] = useState(0);
  const [searchVal, setsearchVal] = useState("");
  const [fullName, setFullName] = useState("");
  const [usageCount, setUsageount] = useState(0);
  const [selectedPage, setSelectedPage] = useState(1);
  const [isFormVisible, setFormVisible] = useState({
    isOpen: false,
    isClose: true,
  });
  const [id, setId] = useState("");
  const [tagList, setTagList] = useState([]);
  const [paginationdata, setPaginationData] = useState({
    string: "",
    page: 0,
  });

  const getListFunction = async () => {
    const res = await getTagMasterList();
    let data = [...res?.data];
    setTagList(data.reverse());
  };
  useEffect(() => {
    getListFunction();
  }, []);
  const showFormActions = () => {
    if (access?.tagsview == true) {
      setErrorMessage(false);
      setId("");
      if (!isFormVisible.isOpen) {
        setFormVisible((prev) => ({
          ...prev,
          isOpen: (isFormVisible.isOpen = true),
          isFormVisible,
          isClose: (isFormVisible.isClose = false),
        }));
        if (AllTextFieldState.is_update === false) {
          setId("");
        }
      }
    } else {
      toasterFunction(" No Permission", "error");
    }
  };
  const SubmitHandler = async (e) => {
    e.preventDefault();
    if (AllTextFieldState.tagMasterName === "") {
      setError((prev) => ({
        ...prev,
        isTagMasterNameError: (isError.isTagMasterNameError = true),
        isTagMasterTypeError: (isError.isTagMasterTypeError = true),
        isColor: (isError.isColor = true),
        // issampleError:isError.issampleError = true
      }));
    } else if (AllTextFieldState.tagMasterType == "") {
      setError((prev) => ({
        ...prev,
        isTagMasterNameError: (isError.isTagMasterNameError = false),
        isTagMasterTypeError: (isError.isTagMasterTypeError = true),
        isColor: (isError.isColor = true),
      }));
    } else if (AllTextFieldState.color == "") {
      setError((prev) => ({
        ...prev,
        isTagMasterNameError: (isError.isTagMasterNameError = false),
        isTagMasterTypeError: (isError.isTagMasterTypeError = false),
        isColor: (isError.isColor = true),
      }));
    } else {
      setError((prev) => ({
        ...prev,
        isTagMasterNameError: (isError.isTagMasterNameError = false),
        isTagMasterTypeError: (isError.isTagMasterTypeError = false),
        isColor: (isError.isColor = false),
      }));
      const sendOrgDetails = {
        // sampleTypeId: AllTextFieldState.sampleTypeId,
        // SampleTypeMasterId: AllTextFieldState.is_update === false ? "SAM-" + AllTextFieldState.sampleTypeName.split(' ').join('-').toUpperCase().replace(/[^a-zA-Z0-9 ]/g, '-') : AllTextFieldState.sampleTypeName,
        tagMasterId: AllTextFieldState.tagMasterName
          .toUpperCase()
          .replace(" ", "-"),
        tagMasterName: AllTextFieldState.tagMasterName,
        tagMasterType: AllTextFieldState.tagMasterType,
        description: AllTextFieldState.description,
        color: AllTextFieldState.color,
        icon: AllTextFieldState.icon,
        followUpBeforeHours: AllTextFieldState.followUpBeforeHours,
        followUpCallback: AllTextFieldState.followUpCallback,
        roles: AllTextFieldState.roles,
        status: "ACTIVE" 
      };
      if (AllTextFieldState.is_update === false) {
        const res = await createApi(sendOrgDetails);
        console.log(res);
        if (res?.data?.tagMasterId) {
          hideForm();
          toasterFunction("Tag Created successfully", "success");
        } else {
          toasterFunction(res?.error?.data?.message, "error");
        }
      }
      else {
        const updateTagPayload = {
          tagMasterId: AllTextFieldState.tagMasterName
          .toUpperCase()
          .replace(" ", "-"),
        tagMasterName: AllTextFieldState.tagMasterName,
        tagMasterType: AllTextFieldState.tagMasterType,
        description: AllTextFieldState.description,
        color: AllTextFieldState.color,
        icon: AllTextFieldState.icon,
        followUpBeforeHours: AllTextFieldState.followUpBeforeHours,
        followUpCallback: AllTextFieldState.followUpCallback,
        roles: AllTextFieldState.roles,
        status: AllTextFieldState.status === "ACTIVE" ? "ACTIVE" : "DELETED",
        tagMasterId : AllTextFieldState.tagMasterId
        }
          const res = await updateApi(updateTagPayload);
          if (res?.data?.tagMasterId) {
            hideForm();
            toasterFunction("Tag Updated successfully", "success");
          } else {
            toasterFunction(res?.error?.data?.message, "error");
          }
      }
      setErrorMessage(true);
    }
  };
  const hideForm = () => {
    setErrorMessage(false);

    setTextFieldState({
      is_update: false,
      tagMasterId: "",
      tagMasterName: "",
      tagMasterType: "",
      description: "",
      followUpBeforeHours: "",
      followUpCallback: "",
      icon: "",
      roles: "",
      color: "",
      status: "",
    });
    if (isFormVisible.isOpen) {
      setFormVisible((prev) => ({
        ...prev,
        isOpen: (isFormVisible.isOpen = false),
        isFormVisible,
        isClose: (isFormVisible.isClose = true),
      }));
    }
    setError((prev) => ({
      ...prev,
      isTagMasterNameError: (isError.isTagMasterNameError = false),
      isTagMasterTypeError: (isError.isTagMasterTypeError = false),
      isColor: (isError.isColor = false),
    }));
    setsearchVal("");
    getListFunction();
    setSelectedPage(1);
  };

  const handleColorInput = (value) => {
    setTextFieldState((prev) => ({
      ...prev,
      ["color"]: AllTextFieldState.color == value ? "" : value,
    }));
    setError((prev) => ({
      ...prev,
      isColor: (isError.isColor = false),
    }));
  };

  const getUserByIdFunct = async (userId, tagId) => {
    const res = await getUserByUserId(userId);
    if (res?.data?.content.length > 0) {
      setFullName(res?.data?.content[0].fullName);
    }

    const usageRes = await getUsageCountTag(tagId);
    if (usageRes?.data?.content.length > 0) {
      setUsageount(usageRes?.data?.totalElements);
    }
  };

  const onSearchevent = async (value) => {
    setsearchVal(value);
    if (value.length != 0) {
      const res = await getTagByTagNameApi({ tagMasterName: value });
      if (res?.data?.content) {
        let data = [...res?.data.content];
        setTagList(data.reverse());
      } else {
        setTagList([]);
      }
    } else {
      getListFunction();
    }
  };

  return (
    <>
      <div className={props.expand1.empty === true ? "main1" : "main"}>
        <Breadcrums
          firsttab={"Categories"}
          secondtab="Command Centre"
          thirdtab="Tags"
        ></Breadcrums>
        <ToastContainer />
        <Grid container mt={-2}>
          <Grid item xs={6}>
            <div
              className={
                isFormVisible.isOpen
                  ? "leftcontainer rounded-lg"
                  : "leftcontainer1 rounded-lg"
              }
            >
              <Grid className=" p-7 ">
                <TextField
                  label=""
                  onChange={(e) => onSearchevent(e.target.value)}
                  onKeyDown={(e) => {
                    e.key === "Enter" && e.preventDefault();
                  }}
                  sx={{ m: 1, width: "25ch" }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchOutlinedIcon />
                      </InputAdornment>
                    ),
                  }}
                  value={searchVal}
                />
              </Grid>
              <Grid className="editContainer rounded-radiusFourPix ">
                <Grid
                  disabled={access?.useradd == false}
                  onClick={showFormActions}
                  className={isFormVisible.isOpen ? "addIconchange" : "addIcon"}
                >
                  <span className=" p-2 m-r-f ">Add</span>
                  <AddCircleOutlineIcon
                    style={{
                      fontSize: "14px",
                      color: "#545454",
                      marginBottom: "3px",
                      color: `${isFormVisible.isOpen ? "#ffffff" : "#545454"}`,
                    }}
                  />
                </Grid>
                <Grid className="addIcon">
                  <span className="p-2 m-r-f">Filter</span>
                  <img
                    src={filter}
                    alt="filter.png"
                    style={{
                      position: "absolute",
                      left: "90%",
                      right: "20px",
                      top: "15px",
                      bottom: "95px",
                      width: "11px",
                      paddingTop: "3px",
                    }}
                  ></img>
                </Grid>
              </Grid>
              <>
                <TagList
                  tagList={tagList}
                  // setTotalPage={setTotalPage}
                  // totalPage={totalPage}
                  setId={setId}
                  id={id}
                  showFormActions={showFormActions}
                  setTextFieldState={setTextFieldState}
                  getUserByIdFunct={getUserByIdFunct}
                  tagsview={access?.tagsview}
                  tagsupdate={access?.tagsupdate}
                  selectedPage={selectedPage}
                  setSelectedPage={setSelectedPage}
                  // setDropdowntate={setDropdowntate}
                  // calculateData={calculateData}
                  // currentPage={isNewData ? 1 : currentPage}
                />
              </>
            </div>
          </Grid>

          {isFormVisible.isOpen ? (
            <Grid item xs={6}>
              <div className="rightcontainer rounded-lg">
                <div className="overflow-y-auto o-form-container">
                  <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={style}>
                      <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                      >
                        <Alert severity="info">
                          {" "}
                          {AllTextFieldState.status === "ACTIVE"
                            ? "Are you sure you want to reactivate  the Sample ?"
                            : "Are you sure you want to Deactivate the Sample ?"}
                        </Alert>
                      </Typography>
                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {AllTextFieldState.status === "INACTIVE"
                          ? "This activity will enable the Sample and user will be able to access it across all products."
                          : "This activity disable the Sample and user will not able to access it. You can reactivate the Sample at anytime?"}

                        <div className="mt-10 flex justify-center items-center  towButtonContainer">
                          <Button
                            type="submit"
                            onClick={handleClose}
                            variant="contained"
                            disableElevation
                            style={{
                              marginRight: "10px",
                              borderColor: "#0F4391",
                              width: "99px",
                              height: "39px",
                              borderRadius: "9999px",
                              backgroundColor: "#E8FAFF",
                              color: "#0F4391",
                              border: "1px solid #0F4391",
                              marginBottom: "100px",
                            }}
                          >
                            CANCEL
                          </Button>

                          <Button
                            type="submit"
                            onClick={halndleClickonProceed}
                            variant="contained"
                            disableElevation
                            style={{
                              borderColor: "#0F4391",
                              width: "99px",
                              height: "39px",
                              borderRadius: "9999px",
                              backgroundColor: "#E8FAFF",
                              color: "#0F4391",
                              border: "1px solid #0F4391",
                              marginBottom: "100px",
                            }}
                          >
                            PROCEED
                          </Button>
                        </div>
                      </Typography>
                    </Box>
                  </Modal>
                  <ToastContainer />
                  <>
                    <div className="font-normal text-o-form-label-color mainhead flex justify-between items-center pl-5 pr-1 h-10">
                      <h1>Tag Overview</h1>
                    </div>

                    <form autoComplete="off" onSubmit={SubmitHandler}>
                      <div className=" pl-5 OrgNameContainer">
                        <h3
                          className="font-normal text-o-form-label-color"
                          id="Org"
                        >
                          Tag Name
                          <span style={{ color: "red" }}> *</span>
                        </h3>
                        <TextField
                          onFocus={(e) => resetErrorHandler(e)}
                          autoComplete="OFF"
                          error={isError.isTagMasterNameError === true}
                          helperText={
                            isError.isTagMasterNameError
                              ? "Valid (Name) Required !"
                              : ""
                          }
                          onChange={(e) => whenInputsChange(e)}
                          name="tagMasterName"
                          value={AllTextFieldState.tagMasterName}
                          style={{ width: "360px" }}
                          id="standard-basic"
                          label=""
                          variant="standard"
                        />
                      </div>

                      <div className="mt-5 pl-5 OrgNameContainer ">
                        <h3 className="font-normal text-o-form-label-color">
                          Tag Type <span style={{ color: "red" }}> *</span>
                        </h3>
                        <Autocomplete
                          autoHighlight={true}
                          value={
                            AllTextFieldState &&
                            AllTextFieldState.tagMasterType == "GENERIC"
                              ? "Regular Tag"
                              : AllTextFieldState.tagMasterType == "FOLLOWUP"
                              ? "Date Tag"
                              : ""
                          }
                          onChange={(e, v) =>
                            handleDropDownChange("tagMasterType", v)
                          }
                          getOptionLabel={(option) => (option ? option : "")}
                          id="city-list-option"
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          options={tagTypeList ? tagTypeList : []}
                          sx={{ width: 360 }}
                          renderInput={(params) => (
                            <TextField
                              variant="standard"
                              {...params}
                              label=""
                              error={isError.isTagMasterTypeError === true}
                              helperText={
                                isError.isTagMasterTypeError
                                  ? "Valid (Name) Required !"
                                  : ""
                              }
                            />
                          )}
                        />
                      </div>

                      <div className=" pl-5 mt-5 OrgNameContainer">
                        <h3
                          className="font-normal text-o-form-label-color"
                          id="Org"
                        >
                          Instructions
                        </h3>
                        <TextField
                          rows={3}
                          multiline
                          onFocus={(e) => resetErrorHandler(e)}
                          autoComplete="OFF"
                          onChange={(e) => whenInputsChange(e)}
                          name="description"
                          value={AllTextFieldState.description}
                          style={{ width: "360px" }}
                          id="standard-basic"
                          label=""
                          variant="outlined"
                        />
                      </div>
                      <div className=" pl-5 mt-5 OrgNameContainer">
                        <h3
                          className="font-normal text-o-form-label-color"
                          id="Org"
                        >
                          Choose colour <span style={{ color: "red" }}> *</span>
                        </h3>
                      </div>
                      <div className="pl-8">
                        <i
                          className={
                            AllTextFieldState.color == "#FFD53E4B"
                              ? "cirActive1"
                              : "cir1"
                          }
                          onClick={(e, v) => handleColorInput("#FFD53E4B")}
                        ></i>
                        <i
                          className={
                            AllTextFieldState.color == "#FFD72E68"
                              ? "cirActive2"
                              : "cir2"
                          }
                          onClick={(e, v) => handleColorInput("#FFD72E68")}
                        ></i>
                        <i
                          className={
                            AllTextFieldState.color == "#FFB545C8"
                              ? "cirActive3"
                              : "cir3"
                          }
                          onClick={(e, v) => handleColorInput("#FFB545C8")}
                        ></i>
                        <i
                          className={
                            AllTextFieldState.color == "#FF8360C4"
                              ? "cirActive4"
                              : "cir4"
                          }
                          onClick={(e, v) => handleColorInput("#FF8360C4")}
                        ></i>
                        <i
                          className={
                            AllTextFieldState.color == "#FF626ECA"
                              ? "cirActive5"
                              : "cir5"
                          }
                          onClick={(e, v) => handleColorInput("#FF626ECA")}
                        ></i>
                        <i
                          className={
                            AllTextFieldState.color == "#FF2379C0"
                              ? "cirActive6"
                              : "cir6"
                          }
                          onClick={(e, v) => handleColorInput("#FF2379C0")}
                        ></i>
                        <i
                          className={
                            AllTextFieldState.color == "#FF147FB2"
                              ? "cirActive7"
                              : "cir7"
                          }
                          onClick={(e, v) => handleColorInput("#FF147FB2")}
                        ></i>
                      </div>

                      <div className="pl-8">
                        <i
                          className={
                            AllTextFieldState.color == "#FF078296"
                              ? "cirActive8"
                              : "cir8"
                          }
                          onClick={(e, v) => handleColorInput("#FF078296")}
                        ></i>
                        <i
                          className={
                            AllTextFieldState.color == "#FF0A857A"
                              ? "cirActive9"
                              : "cir9"
                          }
                          onClick={(e, v) => handleColorInput("#FF0A857A")}
                        ></i>
                        <i
                          className={
                            AllTextFieldState.color == "#FF07870C"
                              ? "cirActive10"
                              : "cir10"
                          }
                          onClick={(e, v) => handleColorInput("#FF07870C")}
                        ></i>
                        <i
                          className={
                            AllTextFieldState.color == "#FF727B02"
                              ? "cirActive11"
                              : "cir11"
                          }
                          onClick={(e, v) => handleColorInput("#FF727B02")}
                        ></i>
                        <i
                          className={
                            AllTextFieldState.color == "#FF827503"
                              ? "cirActive12"
                              : "cir12"
                          }
                          onClick={(e, v) => handleColorInput("#FF827503")}
                        ></i>
                        <i
                          className={
                            AllTextFieldState.color == "#FFA76502"
                              ? "cirActive13"
                              : "cir13"
                          }
                          onClick={(e, v) => handleColorInput("#FFA76502")}
                        ></i>
                        <i
                          className={
                            AllTextFieldState.color == "#FFC44F2A"
                              ? "cirActive14"
                              : "cir14"
                          }
                          onClick={(e, v) => handleColorInput("#FFC44F2A")}
                        ></i>
                      </div>
                      {isError.isColor ? (
                        <div
                          className="locationPricingError"
                          style={{
                            color: "#d32f2f",
                            marginLeft: "20px",
                            fontSize: "0.75rem",
                          }}
                        >
                          Color is required.
                        </div>
                      ) : null}
                      {AllTextFieldState.is_update === true ? (
                        <>
                          <div className=" pl-5 mt-5 OrgNameContainer">
                            <h3
                              className="font-normal text-o-form-label-color"
                              id="Org"
                            >
                              Usage Count
                            </h3>
                            <TextField
                              onFocus={(e) => resetErrorHandler(e)}
                              autoComplete="OFF"
                              name="usageCount"
                              value={usageCount}
                              style={{ width: "360px" }}
                              id="standard-basic"
                              disabled
                              label=""
                              variant="standard"
                            />
                          </div>
                          <div className="mt-5 pl-5 OrgNameContainer">
                            <h3 className="font-normal text-o-form-label-color">
                              Created By
                            </h3>
                            <p className="last-modified-value m-r-f">
                              <TextField
                                disabled
                                variant="standard"
                                autoComplete="OFF"
                                name="encounterType"
                                value={fullName}
                                style={{ width: "360px" }}
                                id="standard-basic"
                                label=""
                              />
                            </p>
                            <h3 className=" mt-5 font-normal text-o-form-label-color">
                              Last Modified Date & Time
                            </h3>
                            <p className="last-modified-value m-r-f">
                              <TextField
                                disabled
                                variant="standard"
                                autoComplete="OFF"
                                name="encounterType"
                                value={new Date(
                                  AllTextFieldState.lastModifiedTime
                                ).toLocaleString("en-US")}
                                style={{ width: "360px" }}
                                id="standard-basic"
                                label=""
                              />
                            </p>
                          </div>
                          <div className="mt-5 pl-5 OrgNameContainer ">
                          <h3 className="font-normal text-o-form-label-color">
                            Status
                          </h3>
                          <Autocomplete
                            autoHighlight={true}
                            value={AllTextFieldState && AllTextFieldState.status === "ACTIVE" ? "ACTIVE" : "INACTIVE"}
                            onChange={(e, v) =>
                              handleDropDownChange("status", v)
                            }
                            getOptionLabel={(option) => (option ? option : "")}
                            id="city-list-option"
                            onKeyDown={(e) => {
                              e.key === "Enter" && e.preventDefault();
                            }}
                            options={statuslist ? statuslist : []}
                            sx={{ width: 360 }}
                            renderInput={(params) => (
                              <TextField
                                variant="standard"
                                {...params}
                                label=""
                              />
                            )}
                          />
                        </div>
                        </>
                      ) : (
                        ""
                      )}

                      <div className="mt-5  OrgNameContainer ">
                        {/* <div className="progressContainer mt-5 flex justify-center items-center">
                          {createResponse.isError ||
                            (updateResponse.isError && showerror === true) ? (
                            <Alert severity="error" color="warning">
                              {createResponse.error &&
                                createResponse.error.data &&
                                AllTextFieldState.is_update === false
                                ? createResponse.error.data.message
                                : updateResponse.error &&
                                updateResponse.error.data &&
                                updateResponse.error.data.message}
                            </Alert>
                          ) : createResponse.isLoading ||
                            updateResponse.isLoading ? (
                            <CircularProgress />
                          ) : (
                            ""
                          )}
                        </div> */}
                        <div className="mt-5 flex justify-center   towButtonContainer h-40">
                          <Button
                            onClick={hideForm}
                            type="submit"
                            variant="contained"
                            disableElevation
                            style={{
                              marginRight: "10px",
                              borderColor: "#0F4391",
                              width: "99px",
                              height: "39px",
                              borderRadius: "9999px",
                              backgroundColor: "#E8FAFF",
                              color: "#0F4391",
                              border: "1px solid #0F4391",
                            }}
                          >
                            CANCEL
                          </Button>

                          <Button
                            type="submit"
                            variant="contained"
                            disableElevation
                            onClick={SubmitHandler}
                            style={{
                              borderColor: "#0F4391",
                              width: "99px",
                              height: "39px",
                              borderRadius: "9999px",
                              backgroundColor: "#E8FAFF",
                              color: "#0F4391",
                              border: "1px solid #0F4391",
                            }}
                          >
                            SAVE
                          </Button>
                        </div>
                      </div>
                    </form>
                  </>
                </div>
              </div>
            </Grid>
          ) : (
            ""
          )}
        </Grid>
      </div>
      <AdminCookieConsentBanner />
    </>
  );
};

export default Tags;
