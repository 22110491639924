import { useState, useEffect } from "react";

const useCreateEncounterTypesHook = () => {
  const encounterTypesFormData = {
    encounterType: '',
  };
  const [encounterFormFields, setEncounterFormFields] = useState(encounterTypesFormData);
  const [encounterSystemStates, setEncounterSystemStates] = useState([]);
  const [encounterCustomStates, setEncounterCustomStates] = useState([]);
  const [showCustomFiedlError, setShowCustomFieldError] = useState(false);
  const [openFilterModal, setOpenFilterModal] = useState(false);

  const [AllTextFieldState, setTextFieldState] = useState({
    is_update: false,
    extension: '',
   
});

  const handleInputChange = (e, i) => {
    const { name, value } = e.target;
    console.log(value)
    console.log(name)
    console.log(i)
    if(name === "encounterType") {
        setEncounterFormFields((prev) => ({
            ...prev,
            [name]: value,
          }));
    };
    if (name === "customState" ) {
        let arr = [...encounterCustomStates];
        console.log(encounterCustomStates)
        arr[i].value = value;
        arr[i].id = i;
        arr[i].isDisabled = false;
        setEncounterCustomStates([...arr]);
        setShowCustomFieldError(false);
        const systemArr = encounterSystemStates;
        systemArr[i].isDisabled = false;
        setEncounterSystemStates(systemArr);
    }
    if (name === "isdefault" ) {
    let isdefaultData =  encounterSystemStates.find(data => data.isDefault===true)
      let arr = [...encounterCustomStates];
      arr[i].isDisabled = false;

    
     
      setEncounterCustomStates([...arr]);
      setShowCustomFieldError(false);
      const systemArr = encounterSystemStates;
      if(!systemArr[i].isDefault===true){
        systemArr.forEach((element) => {
          if (isdefaultData && element.id === isdefaultData.id) {
            element.isDefault = false;
          }
        });
      }
      
      systemArr[i].isDisabled = false;
      systemArr[i].isDefault = !systemArr[i].isDefault;
      setEncounterSystemStates(systemArr);
      console.log(systemArr)
  }
  };

  const handleDropdownStates = (field, i, v) => {
    const value = v;
    if (field === "systemStates") {
        encounterSystemStates.forEach((element, index) => {
          if (i === index) {
            element.name = value;
            element.isDisabled = false;
          }
        });
        setEncounterSystemStates([...encounterSystemStates]);
        setShowCustomFieldError(false);
        const customArr = encounterCustomStates;
        customArr[i].isDisabled = false;
        setEncounterCustomStates(customArr);
      }
    
  };

  const addRow = () => {
    const systemStateRow = {
        name: '',
        id: '',
        isDisabled: false,
        isChecked: false,
        isNew:false,
        isDefault: false,
        extension:{color: ""}
    }
    const customStateRow = {
        value: '',
        id: '',
        isChecked: false,
        isNew:false,
        isDefault: false,
    }
    setEncounterSystemStates((prev) => [...prev, systemStateRow]);
    setEncounterCustomStates((prev) => [...prev, customStateRow]);
  };

  const deleteRow = (i) => {
    const systemStatesArr = encounterSystemStates.filter((item, index) => index !== i);
    const customStatesArr = encounterCustomStates.filter((item, index) => index !== i);
    setEncounterSystemStates([...systemStatesArr]);
    setEncounterCustomStates([...customStatesArr]);
    setShowCustomFieldError(false);
  };

  const resetErrorHandler = (e) => {
    
  };

  const getPackageTransformedData = () => {
    
  };


  const exportAll = {
    handleInputChange,
    resetErrorHandler,
    addRow,
    deleteRow,
    handleDropdownStates,
    getPackageTransformedData,
    setEncounterSystemStates,
    setEncounterCustomStates,
    setShowCustomFieldError,
    showCustomFiedlError,
    encounterFormFields,
    encounterSystemStates,
    encounterCustomStates,
    openFilterModal,
    AllTextFieldState,
     setTextFieldState,
  };

  return exportAll;
};

export default useCreateEncounterTypesHook;
