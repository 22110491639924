import { useState } from "react";
import { useGetCreatingReportCategoryMutation } from "../../../services/adminLogIn";

const MessageTemplateLogic = () => {
    const [showerror, setErrorMessage] = useState(false);

    const [AllTextFieldState, setAllTextFieldState] = useState({
        is_update: false,
        templateId:'',
        channel: "WHATSAPP",
        language: 'English',
        messageType:'',
        templateName:'',
        body:'',
        value1: '',
        value2: '',
        value3: '',
        value4: '',
        status:'',
      });
      const [dropLocState, setDropLocState] = useState({});
      const [modalOpen, setModalopen] = useState(false);
      const getDistrictValFun1 = (value) => {
        //   getDistrictValFun(value)
      };

      const [isError, setError] = useState({
        ischannelError: false,
        islanguageError: false,
        ismessageTypeError: false,
        istemplateNameError: false,
        isbodyError: false,
       
    });
  

      const [templatePropertyMappings, setTemplatePropertyMappings] = useState([]); 
      // form on submit handleer
      const handleInputChange = (e) => {
        const { name, value } = e.target;
    setAllTextFieldState((prev) => ({
      ...prev,
      [name]: value,
    }));
         if (name === "templateName") {
            setError((prev) => ({
                ...prev,
                istemplateNameError: isError.istemplateNameError = false
            }))
          setAllTextFieldState((prev) => ({
            ...prev,
           
           
            }));
        }
        if (name === "body") {
            setError((prev) => ({
                ...prev,
                isbodyError: isError.isbodyError = false
            }))
          setAllTextFieldState((prev) => ({
            ...prev,
          
           
          }));
        }
        
      };
    
      const handleDropDownChange = (e, v) => {
        const value = v;
        if (e === 'channel') {
            setError((prev) => ({
                ...prev,
                ischannelError: isError.ischannelError = false
            }))
          AllTextFieldState.channel = value
          setAllTextFieldState((prev) => ({
              ...prev,
              ['channel']: value

          }))
      }
      if (e === 'language') {
        setError((prev) => ({
            ...prev,
            islanguageError: isError.islanguageError = false
        }))
        AllTextFieldState.language = value
        setAllTextFieldState((prev) => ({
            ...prev,
            ['language']: value

        }))
    }
    if (e === 'messageType') {
        setError((prev) => ({
            ...prev,
            ismessageTypeError: isError.ismessageTypeError = false
        }))
      AllTextFieldState.messageType = value
      setAllTextFieldState((prev) => ({
          ...prev,
          ['messageType']: value

      }))
  }
  if (e === 'status') {
    AllTextFieldState.status = value
    setAllTextFieldState((prev) => ({
        ...prev,
        ['status']: value

    }));
    setModalopen(true);
}
}
const handleInputValueChange = (e) => {
  const { name, value } = e.target;
  if(name === "value1") {
    setTemplatePropertyMappings((prev) => ({
          ...prev,
          [name]: value,
        }));
  }
}

 
const resetErrorHandler = (e) => {
    const name = e.target.code;
    switch (name) {
        case "channel":
            setError((prev) => ({
                ...prev,
                ischannelError: isError.ischannelError = false
            }))
            break;

        case "language":
            setError((prev) => ({
                ...prev,
                islanguageError: isError.islanguageError = false
            }))
            break;
       
            case "messageType":
                setError((prev) => ({
                    ...prev,
                    ismessageTypeError: isError.ismessageTypeError = false
                }))
                break;
                case "templateName":
                    setError((prev) => ({
                        ...prev,
                        istemplateNameError: isError.istemplateNameError = false
                    }))
                    break;
                    case "body":
                        setError((prev) => ({
                            ...prev,
                            isbodyError: isError.isbodyError = false
                        }))
                        break;

    }
}   
      // exporting all handler and state and etc
      const exportAll = {
        setDropLocState,
        dropLocState,
        AllTextFieldState,
        setAllTextFieldState,
        handleInputChange,
        handleDropDownChange,
        templatePropertyMappings,
        handleInputValueChange,
        setTemplatePropertyMappings,
        modalOpen,
        setModalopen,
        resetErrorHandler,
        isError,
        setError,
        setErrorMessage,
    
        //    districtlist
      };
    
      return exportAll;
    };

export default MessageTemplateLogic;
