import * as React from 'react';
import { useState, useEffect, useRef } from "react";
import { Grid, Icon } from '@mui/material';
import "../table.css"
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import DeleteIcon from "@mui/icons-material/Delete";
import Logout from '@mui/icons-material/Logout';
import Delete from '../../global/icons/delete.png';



const MedicineDurationList = (props) => {
    const { handleDelete } = props;

  const { hasFocus, value } = props;
  const [id, setId] = useState(0);


  const handleClick = (row) => {
    
    props.setId(row.medicineDurationUOMMasterId)  
    setId(row.medicineDurationUOMMasterId)
    // props.getUserByIdFunct(row.lastModifiedBy,row.tagMasterId)
    props.showFormActions()
    let sys = {}
    props.bodysiteSystem.forEach(element => {
        if (element.system === row.system) {
            sys = element
        }
      });
      props.setTextFieldState({...row , 'is_update':true,'medicineDurationUOMMasterId':row.medicineDurationUOMMasterId,'status':row.isActive})
  }

  const handleOnChange = (event, page) => {
    props.getMedicineDosagePage(page)

  }; 
  return (
    <Grid className="" >
      <div class="scrollit">
      { props.tagsview===true && props.MedicinerouteList && props.MedicinerouteList.length > 0  ?
    
          <table className='b ' style={{ width: '90%', marginLeft: '20px', tableLayout: "fixed", height: "20vh" }}>
            <thead style={{ position: 'sticky', zIndex: '1', top: '0' }}>
              <tr>
                <th className=" m-r-f" style={{ width: '50px' }}><input type="checkbox" id="" name="vehicle1" value="Bike" /></th>
                <th className=" m-r-f">Duration Code</th>
                <th className=" m-r-f">Description</th>
                <th className=" m-r-f">Status</th>

              </tr>
            </thead>
            <tbody>

              {props.MedicinerouteList&& props.MedicinerouteList && props.MedicinerouteList.length > 0 && props.MedicinerouteList.map((item, i) => {
                return (
                  //
                  <tr key={i} className="tablebor"  style={{ cursor: 'pointer', backgroundColor: id === item.medicineDurationUOMMasterId ? "rgb(15, 67, 145)" : "", color: id === item.medicineDurationUOMMasterId ? "white" : "" }} >
                    <td  >< input type="checkbox" id={i} /></td>
                    <td className="  m-r-f" onClick={event => handleClick(item)} ><a>{item.durationCode}</a></td>
                    <td className="  m-r-f" onClick={event => handleClick(item)} style={{ cursor: 'pointer', }}  >{item.durationDescription}</td>
                    <td className=" m-r-f" onClick={event => handleClick(item)} style={{ paddingLeft: "" }}><span className={item.isActive === true ? "dotg" : "dotr"} ></span>  {item.isActive === true ? " Active" : " Inactive"}</td>
                  </tr>

                )
              })
            }
              
            </tbody>

          </table>
          : <div className='record'>
            No Records Found
          </div>}
      
      </div>
      
      {props.tagsview===true && props.MedicinerouteList&& props.MedicinerouteList && props.MedicinerouteList.length>0 &&
      <div className="pagesam pt-1 m-1">
        
        <Stack spacing={2}>
          <Pagination count={props?.totalPage} color="primary" page={props.currentPage} onChange={(e, page) => handleOnChange(e, page)} />
        </Stack>
        <div className='pagenum'>
          <span className='ml-2'>{props?.calculateData?.number * 20 + 1}-{props?.calculateData?.number * 20 + props?.calculateData?.numberOfElements} of {props?.calculateData?.totalElements}</span>
        </div>

       
      </div>
        }
    </Grid>

  );

}

export default MedicineDurationList; 
