import * as React from 'react';
import { useState, useEffect, useRef } from "react";
import PropTypes from 'prop-types';
import Link from '@mui/material/Link';
import { DataGrid } from '@mui/x-data-grid';
import autoprefixer from 'autoprefixer';
import { useGetActiveOrgValueMutation, useGetActiveDistrictValueMutation, useSearchCountryListMutation } from "../../../services/adminLogIn";
import useLocHook from "./locationLogic";
import "../table.css"
import "../Organization/Mainhome.css"
import { Grid } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';


const LocationTable = (props) => {
  const { hasFocus, value } = props;
  const [getDistrictValFun, { isLoading1, isSuccess1, isError1, ...cityList }] = useGetActiveDistrictValueMutation();
  const [getActiveValFun1, { isLoading2, isSuccess2, isError2, ...org }] = useGetActiveOrgValueMutation();
  const [access, setAccess] = useState(
    JSON.parse(sessionStorage.getItem("access"))
  );

  const [searchCountry] = useSearchCountryListMutation();
  const handleClick = async (row) => {

    let data = {}
     const resp = await searchCountry({"searchStr": row?.address?.countryISOCode})
     resp.data.content.forEach(element => {
         if(element.isoCode == row.address.countryISOCode){
         data.country = {"country" : element.country, "isoCode" : element.isoCode}}    });
    data.is_update = true
    data.name = row.name
    data.level = row.level
    data.status = row.status
    data.id = row.id
    data.postalCode = row.address != null && row.address.postalCode != 'false' && row.address.postalCode
    data.state = {
      name: row.address != null && row.address.state != 'false' && row.address.state,
      isUt: false
    }
    data.city = {
      name: row.address != null && row.address.city != 'false' && row.address.city,
      stateName: row.address != null && row.address.state != 'false' && row.address.state,
    }
    data.phoneNumber = row.telecom[0] && row.telecom[0].value
    data.emailId = row.telecom[0] && row.telecom[1].value
    let data1 = {}
    data1.name = row.partOf && row.partOf != null && row.partOf.identifier&& row.partOf.identifier.value
    data1.id = row.partOf && row.partOf != null && row.partOf.identifier&& row.partOf.identifier.value
    props.setDropLocState({ ...data1 })
    let data2 = {}
    org.data.content.forEach(element => {
      if (element.id === (row.managingOrganization && row.managingOrganization.reference && row.managingOrganization.reference.split('/')[1])) {
        data2.id = element.id
        data2.name = element.name
      }
    });
    props.setDropLocManage({ ...data2 })
    props.setLocTextFieldState({ ...data })
    props.showFormActions()
    props.setErrorMessage(false)
    props.setId(row.id)
    props.setLocTextFieldState({
      ...data,
      is_update: true,
      lastModifiedTime: row.lastModifiedTime,
      id:row.id,
    });
    props.getUserByIdFunct(row.lastModifiedBy, row.id);

  }
  const [data, setData] = useState([]);
  useEffect(() => {
    setData(props.loclist)
    getActiveValFun1()
    // getActiveValFun();
  }, [])

  const handleOnChange = (event, page) => {
    props.getlocpageList(page)
  };
  return (
    <Grid className="" >
      <div class="scrollit">
        { props.locationview===true && props.loclist && props.loclist.length > 0 ?
          <table style={{ width: '90%', marginLeft: '20px', tableLayout: "fixed", height: "20vh" }}>
            <thead style={{ position: 'sticky', top: '0', zIndex: '1' }} >
              <tr>
                <th className=" m-r-f" style={{ width: '50px' }}><input type="checkbox" id="" name="vehicle1" value="Bike" /></th>
                <th className=" m-r-f" style={{ width: "" }}>Location Name</th>
                <th className=" m-r-f" style={{ width: "", paddingLeft: "" }}>Part Of</th>
                <th className=" m-r-f" style={{ width: "", paddingLeft: "" }}>Level</th>
                <th className=" m-r-f" style={{ width: "", paddingLeft: "" }}>Status</th>

              </tr>
            </thead>
            <tbody >
              {props && props.loclist && props.loclist.length > 0 && props.loclist.map((item, i) => {
                return (
                  <tr key={i} className="tablebor" style={{ cursor: 'pointer', backgroundColor: props.id === item.id ? "rgb(15, 67, 145)" : "", color: props.id === item.id ? "white" : "" }}>
                    <td  >< input type="checkbox" id={i} /></td>
                    <td className=" m-r-f" onClick={access?.locationupdate ? () => handleClick(item) : null} style={{ cursor: 'pointer', width: "" }}><a >{item.name}</a></td>
                    <td className=" m-r-f" onClick={access?.locationupdate ? () => handleClick(item) : null} style={{ width: "" }}>{item && item.partOf && item.partOf.identifier && item.partOf.identifier.value}</td>
                    <td className=" m-r-f" onClick={access?.locationupdate ? () => handleClick(item) : null} style={{ width: "" }}><a >{item.level}</a></td>
                    <td className=" m-r-f" onClick={access?.locationupdate ? () => handleClick(item) : null} style={{ paddingLeft: "" }}><span className={item.status === "ACTIVE" ? "dotg" : "dotr"} ></span>  {item.status === "ACTIVE" ? " Active" : " Inactive"}</td>

                  </tr>

                )
              })

              }
            </tbody>

          </table>
          : <div className='record'>
            No Records Found
          </div>}
      </div>
      {/* <table>
  <tr>
    <th><input type="checkbox" id="" name="vehicle1" value="Bike" /></th>
    <th>Location Name</th>
    <th>Part Of</th>
  </tr>
  {props && props.content && props.content.length>0 && props.content.map((item, i) => {
          return (
  <tr>
    <td></td>
    <td>Maria Anders</td>
    <td>Germany</td>
  </tr>
          )
        })
      
      }

 
</table> */}

      {props.locationview===true && props.loclist && props.loclist.length > 0 &&
        <div className="pagesam pt-1 m-1">
          <Stack spacing={2}>
            <Pagination count={props.totalPage} color="primary" onChange={(e, page) => handleOnChange(e, page)} page={props.currentPage} />
          </Stack>
          <div className='pagenum'>
            <span className='ml-2'>{props.calculateData.number * 20 + 1}-{props.calculateData.number * 20 + props.calculateData.numberOfElements} of {props.calculateData.totalElements}</span>
          </div>
        </div>
      }

    </Grid>
  );

}

export default LocationTable
