// import { LocalConvenienceStoreOutlined } from "@mui/icons-material";
import { useState } from "react";
// import isEmail from 'validator/es/lib/isEmail';
// import isMobilePhone from 'validator/es/lib/isMobilePhone';
import { useCreatingOrganizationMutation, useCreatingSampleMutation, useUpdatigsampleMutation,useCreateTaskResolutionMutation } from "../../../services/adminLogIn";

const CategoryProcedureLogic = () => {
    const [getCreateTaskResolution] = useCreateTaskResolutionMutation();
    const [createSampleFunc, createResponse] = useCreatingSampleMutation();
    const [updatesampleFunc, updateResponse] = useUpdatigsampleMutation();
    const [showerror, setErrorMessage] = useState(false);
    const [orgState, setOrgState] = useState();
    const [taskSubmitStatus, setTaskSubmitStatus] = useState(false);
    const [AllTextFieldState, setTextFieldState] = useState({
        is_update: false,
        procedureCategoryId: "",
        procedureCategory: '',
        terminology: '',
        code: '',
        deleted: false,


    });
    const [open, setOpen] = useState(false);
    const [openFilterModal, setOpenFilterModal] = useState(false);
    const [isSearchEvent, setIsSearchEvent] = useState(false);
    const [status, setStatus] = useState("");
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleFilterModalOpen = () => { setOpenFilterModal(true); }
    const handleFilterModalClose = () => { setOpenFilterModal(false); }

    const [filterData, setFilterData] = useState({ procedureCategoryId: "", sampleName: "", filterStatus: "" });


    const [dropsampleidState, setDropdownState] = useState({
        sampleClassificationId: '', sampleClassificationName: ''
    });

    const [isError, setError] = useState({
        isTaskResolutionListError: false,
        isCodeError: false,
        isSystemError: false,
        });

    // dropdown handler 
    const handleDropDownChange = (e, v) => {
        const value = v;
        if (e === "terminology") {
            setTextFieldState((prev) => ({
                ...prev,
                [e]: value,
              }));
        }
        if (e === "status") {
            handleOpen()
        }
    }

    const halndleClickonProceed = () => {
        setTextFieldState((prev) => ({
            ...prev,
            ["deleted"]: !AllTextFieldState.deleted 

        }))
        handleClose()
    }
    const getDistrictValFun1 = (value) => {
        //   getDistrictValFun(value)
    }

    const resetErrorHandler = (e) => {
        const name = e.target.name;
        switch (name) {
            case "procedureCategory":
                setError((prev) => ({
                    ...prev,
                    isTaskResolutionListError: isError.isTaskResolutionListError = false
                }))
                break;
                case "terminology":
                    setError((prev) => ({
                        ...prev,
                        isSystemError: isError.isSystemError = false
                    }))
                    break;
                    case "code":
                        setError((prev) => ({
                            ...prev,
                            isCodeError: isError.isCodeError = false
                        }))
                        break;

           
        }
    }

    
    const whenInputsChange = (e) => {
        const { name, value } = e.target;
      
            setTextFieldState((prev) => ({
            ...prev,
            [name]: value,
          }));
      };
    // exporting all handler and state and etc
    const exportAll = {
        whenInputsChange,
        handleDropDownChange,
        orgState,
        //    dropsampleState,
        AllTextFieldState,
        isError,
        createResponse,
        updateResponse,
        resetErrorHandler,
        setTextFieldState,
        //    setDropsampleState,
        getDistrictValFun1,
        dropsampleidState,
        setDropdownState,
        open,
        handleClose,
        handleOpen,
        setOpen,
        halndleClickonProceed,
        setErrorMessage,
        handleFilterModalOpen,
        handleFilterModalClose,
        openFilterModal,
        showerror,
        filterData,
        isSearchEvent,
        setIsSearchEvent,
        //    districtlist

    }

    return exportAll;
}

export default CategoryProcedureLogic;
