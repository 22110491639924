import { useState } from "react";
import {
  useCreateHeaderFooterMutation,
  useUpdatigHeaderFooterMutation,
} from "../../../services/adminLogIn";

const HeaderFooterLogic = () => {
  const [AllTextFieldState, setAllTextFieldState] = useState({
    name: "",
    is_update: false,
    id: "",
    locationIds: [],
    encounterTypes: {},
    imageUrl: "",
    pline1: "",
    pline2: "",
    pline3: "",
    pline4: "",
    pline5: "",
    pline6: "",
    kline7: "",
    kline8: "",
    kline9: "",
    documentTemplate: "",
    participantRoles: "",
    footerImgUrl: "",
    documentProperties: "",
    reportCategoryId: "",
  });

  const [updateHeaderFooter] = useUpdatigHeaderFooterMutation();
  const [dropLocState, setDropLocState] = useState({});
  const getDistrictValFun1 = (value) => {
    //   getDistrictValFun(value)
  };

  const [headerFooterErrorstates, setHeaderFooterErrorStates] = useState({
    locationError: false,
    encounterTypeError: false,
    imageUrlError: false,
    ispline1Error: false,
    ispline2Error: false,
    ispline3Error: false,
  });

  // form on submit handleer

  const handleDropDownChange = (e, v) => {
    const value = v;
    if (e === "locname") {
      AllTextFieldState.locationIds = value;
      setDropLocState(value);
      setAllTextFieldState(AllTextFieldState);
      if (value) {
        setHeaderFooterErrorStates((prev) => ({
          ...prev,
          locationError: false,
        }));
      }
    }
    if (e === "encname") {
      AllTextFieldState.encounterTypes = value;
      setDropLocState(value);
      setAllTextFieldState(AllTextFieldState);
      if (value) {
        setHeaderFooterErrorStates((prev) => ({
          ...prev,
          encounterTypeError: false,
        }));
      }
    }
  };
  const whenInputsChange = (e) => {
    const { name, value } = e.target;
    setAllTextFieldState((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (name === "imageUrl" && value) {
      if (value) {
        const valid = /^(ftp|http|https):\/\/[^ "]+$/.test(value);
        if (valid) {
          setHeaderFooterErrorStates((prev) => ({
            ...prev,
            imageUrlError: false,
          }));
        }
      }
    } else if (name === "pline1") {
      setHeaderFooterErrorStates((prev) => ({
        ...prev,
        ispline1Error: false,
      }));
    }
    else if (name === "pline2") {
      setHeaderFooterErrorStates((prev) => ({
        ...prev,
        ispline2Error: false,
      }));
    }
    else if (name === "pline3") {
      setHeaderFooterErrorStates((prev) => ({
        ...prev,
        ispline3Error: false,
      }));
    }
  };

  // exporting all handler and state and etc
  const exportAll = {
    setDropLocState,
    dropLocState,
    AllTextFieldState,
    setAllTextFieldState,
    handleDropDownChange,
    whenInputsChange,
    headerFooterErrorstates,
    setHeaderFooterErrorStates,
  };

  return exportAll;
};

export default HeaderFooterLogic;
