import { useState, useEffect, useRef } from "react";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import TextField from "@mui/material/TextField";
import MessageTemplateLogic from "./messageTemplateLogic";
import MessageTemplateList from "./messageTemplateList";
import Autocomplete from "@mui/material/Autocomplete";
import { Button } from "@mui/material";

import {
  useGetmessagetemplateListMutation,
  useCreateUpdateMessageTemplateMutation,
  useGetTemplateConfigMutation,
} from "../../../services/adminLogIn";
import filter from "../../../images/filter.png";
import "../Organization/Mainhome.css";
import { Grid } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import Breadcrums from "../../global/breadcrums/Breadcrums";
import { toasterFunction } from "../../../globalFunctions/getLocalStorageData";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Alert from "@mui/material/Alert";
import AdminCookieConsentBanner from "../home/AdminCookieConsentBanner";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const MessageTemplate = (props) => {
  const [getmessagetemplateList] = useGetmessagetemplateListMutation();
  const [createUpdateMessageTemplate] =
    useCreateUpdateMessageTemplateMutation();
  const [templateConfigList] = useGetTemplateConfigMutation();

  const [access, setAccess] = useState(
    JSON.parse(sessionStorage.getItem("access"))
  );
  const [Messagetemplatedata, setMessagetemplatedata] = useState([]);
  const [templateConfigData, setTemplateConfigData] = useState([]);

  const [isFormUpdate, setIsFormUpdata] = useState(false);
  const [isNewData, setIsNewData] = useState(true);
  const [searchresultsObj, setSearchResultsObj] = useState();
  const [searchVal, setsearchVal] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [indexvalue, setIndex] = useState(null);
  const [status, setStatus] = useState("");
  const channelList = [" WHATSAPP", "SMS", "EMAIL"];
  const languageList = ["English"];
  const messageTypeList = ["Text", "Document", "Image", "Video"];
  const variableInputList = [
    { label: "Auto Populate", value: "autoPopulate" },
    { label: "Constant", value: "constantValue" },
    { label: "User Input", value: "userInput" },
  ];
  const [variableInputs, setVariableInputs] = useState({
    constantValue: "",
    autoPopulate: "",
    userInput: "",
    variableInputType: "",
  });
  const [locationMultiSelectOptions, setLocationMultiSelectOptions] = useState(
    []
  );

  const [calculateData, setTotalPageCalculate] = useState({
    number: "",
    numberOfElements: "",
    totalElements: "",
    showcount: "",
  });
  const [paginationdata, setPaginationData] = useState({
    string: "",
    page: 0,
    size: 20,
  });
  const {
    setErrorMessage,
    showerror,
    setError,
    isError,
    AllTextFieldState,
    setAllTextFieldState,
    handleDropDownChange,
    handleInputChange,
    handleInputValueChange,
    templatePropertyMappings,
    setTemplatePropertyMappings,
    modalOpen,
    setModalopen,
    resetErrorHandler,
  } = MessageTemplateLogic();
  const [isFormVisible, setFormVisible] = useState({
    isOpen: false,
    isClose: true,
  });
  const [id, setId] = useState("");
  const [statuslist] = useState(["ACTIVE", "INACTIVE"]);

  const showFormActions = (from) => {
    if (access?.userview == true) {
      setErrorMessage(false);
      setId("");
      if (!isFormVisible.isOpen) {
        setFormVisible((prev) => ({
          ...prev,
          isOpen: (isFormVisible.isOpen = true),
          isFormVisible,
          isClose: (isFormVisible.isClose = false),
        }));
        if (AllTextFieldState.is_update === false) {
          setId("");
        }
        if (from !== "fromRowClick") {
          const data = {
            constantValue: false,
            autoPopulate: false,
            userInput: false,
            inputData: {
              constantValue: "",
              autoPopulate: "",
              userInput: "",
              variableInputType: "",
            },
          };
          setTemplatePropertyMappings((prev) => [...prev, data]);
        }
      }
    } else {
      toasterFunction(" No Permission", "error");
    }
  };

  const handleColorInput = (value) => {
    setAllTextFieldState((prev) => ({
      ...prev,
      ["color"]: AllTextFieldState.color == value ? "" : value,
    }));
    setError((prev) => ({
      ...prev,
      isColor: (isError.isColor = false),
    }));
  };
  const bodySubmitHandler = async (e) => {
    e.preventDefault();
    if (
      AllTextFieldState.channel == "" ||
      AllTextFieldState.channel == undefined
    ) {
      setError((prev) => ({
        ...prev,
        ischannelError: (isError.ischannelError = true),
        islanguageError: (isError.islanguageError = true),
        ismessageTypeError: (isError.ismessageTypeError = true),
        istemplateNameError: (isError.istemplateNameError = true),
        isbodyError: (isError.isbodyError = true),
      }));
    } else if (
      AllTextFieldState.language == "" ||
      AllTextFieldState.language == undefined
    ) {
      setError((prev) => ({
        ...prev,
        ischannelError: (isError.ischannelError = false),
        islanguageError: (isError.islanguageError = true),
        ismessageTypeError: (isError.ismessageTypeError = true),
        istemplateNameError: (isError.istemplateNameError = true),
        isbodyError: (isError.isbodyError = true),
      }));
    } else if (
      AllTextFieldState.messageType == "" ||
      AllTextFieldState.messageType == undefined
    ) {
      setError((prev) => ({
        ...prev,
        ischannelError: (isError.ischannelError = false),
        islanguageError: (isError.islanguageError = false),
        ismessageTypeError: (isError.ismessageTypeError = true),
        istemplateNameError: (isError.istemplateNameError = true),
        isbodyError: (isError.isbodyError = true),
      }));
    } else if (AllTextFieldState.templateName == "") {
      setError((prev) => ({
        ...prev,
        ischannelError: (isError.ischannelError = false),
        islanguageError: (isError.islanguageError = false),
        ismessageTypeError: (isError.ismessageTypeError = false),
        istemplateNameError: (isError.istemplateNameError = true),
        isbodyError: (isError.isbodyError = true),
      }));
    } else if (AllTextFieldState.body == "") {
      setError((prev) => ({
        ...prev,
        ischannelError: (isError.ischannelError = false),
        islanguageError: (isError.islanguageError = false),
        ismessageTypeError: (isError.ismessageTypeError = false),
        istemplateNameError: (isError.istemplateNameError = false),
        isbodyError: (isError.isbodyError = true),
      }));
    } else {
      setError((prev) => ({
        ...prev,
        ischannelError: (isError.ischannelError = false),
        islanguageError: (isError.islanguageError = false),
        ismessageTypeError: (isError.ismessageTypeError = false),
        istemplateNameError: (isError.istemplateNameError = false),
        isbodyError: (isError.isbodyError = false),
      }));
      const dataObj = {};
      const templatePropertySampleValueMap = {};
      templatePropertyMappings.forEach((item, index) => {
        if (item?.inputData?.autoPopulate?.templatedConfig) {
          dataObj[`{{${index + 1}}}`] =
            item?.inputData?.autoPopulate?.templatedConfig;
        } else {
          let templatedConfig = {
            constantValue: item?.inputData?.constantValue,
            dataType: "STRING",
            jsonPath: null,
            outputFormatStr: "NONE",
            type: "CONSTANT",
          };
          dataObj[`{{${index + 1}}}`] = templatedConfig;
        }
      });

      templatePropertyMappings.forEach((item, index) => {
        templatePropertySampleValueMap[`{{${index + 1}}}`] =
          item?.inputData?.autoPopulate?.templatePropertySampleValueMap;
      });
      const sendHeaderDetails = {
        channel: AllTextFieldState.channel,
        locale: AllTextFieldState?.language,
        templateName: AllTextFieldState.templateName,
        status: AllTextFieldState.status === "INACTIVE" ? "DELETED" : "ACTIVE",
        sourceAppName: null,
        eventId: null,
        targetAppName: null,
        mailSubject: "",
        clientPatronName: "ALL",
        recipientType: null,
        ruleId: null,
        title: null,
        description: "Lab invoice sharing msg",
        addonAttributes: {
          messageType: AllTextFieldState?.messageType,
        },
        content: encodeURIComponent(AllTextFieldState.body),
        templatePropertyMappings: dataObj,
        templatePropertySampleValueMap: templatePropertySampleValueMap,
      };

      if (AllTextFieldState.is_update === false) {
        const res = await createUpdateMessageTemplate(sendHeaderDetails);
        if (res?.data) {
          toasterFunction("Created successfully", "success");
          hideForm();
          getmessagedata();
        } else {
          toasterFunction("Something went wrong", "error");
        }
        setErrorMessage(true);
      } else {
        const updateHeaderDetails = {
          templateId: AllTextFieldState.templateId,
          channel: AllTextFieldState.channel,
          locale: AllTextFieldState?.language,
          templateName: AllTextFieldState.templateName,
          status:
            AllTextFieldState.status === "INACTIVE" ? "DELETED" : "ACTIVE",
          sourceAppName: null,
          eventId: null,
          targetAppName: null,
          mailSubject: "",
          clientPatronName: "ALL",
          recipientType: null,
          ruleId: null,
          title: null,
          description: "Lab invoice sharing msg",
          addonAttributes: {
            messageType: AllTextFieldState?.messageType,
          },
          content: encodeURIComponent(AllTextFieldState.body),
          templatePropertyMappings: dataObj,
          templatePropertySampleValueMap: templatePropertySampleValueMap,
        };

        const res = await createUpdateMessageTemplate(updateHeaderDetails);
        if (res?.data?.templateId) {
          toasterFunction("Update successfully", "success");
          getmessagedata();
        } else {
          toasterFunction("Something went wrong", "error");
        }
      }

      hideForm();
    }
  };

  const getTemplateConfigData = async () => {
    const res = await templateConfigList();
    if (res?.data) {
      setTemplateConfigData(res?.data);
    }
  };

  const getmessagedata = async () => {
    let payload = {
      string: "",
      page: 0,
      size: 20,
    };
    const res = await getmessagetemplateList(payload);

    if (res?.data) {
      const sortedArray = [...res?.data];
      sortedArray.sort((a, b) => {
        const nameA = a.templateName.toUpperCase();
        const nameB = b.templateName.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      setMessagetemplatedata(sortedArray);
      setTotalPage(res?.data?.totalPages);
      setTotalPageCalculate({
        numberOfElements: res?.data?.numberOfElements,
        number: res?.data?.number,
        totalElements: res?.data?.totalElements,
      });
    }
  };
  const handlePageChange = (page) => {
    const paginationData = {
      string: "",
      page: page - 1,
      size: 20,
    };
    getmessagedata(paginationData);
  };
  const handleClose = (item, index) => {
    setModalopen(false);
    setIndex(item);
  };

  const hideForm = () => {
    if (isFormVisible.isOpen) {
      setFormVisible((prev) => ({
        ...prev,
        isOpen: (isFormVisible.isOpen = false),
        isFormVisible,
        isClose: (isFormVisible.isClose = true),
      }));
      setTemplatePropertyMappings([]);
      setErrorMessage(false);
      setAllTextFieldState({
        is_update: false,
        channel: "WHATSAPP",
        language: "English",
        messageType: "",
        templateName: "",
        body: "",
        value1: "",
        value2: "",
        value3: "",
        value4: "",
        status: "",
      });
      setId("");
      setCurrentPage(1)
    }
    setError((prev) => ({
      ...prev,
      ischannelError: (isError.ischannelError = false),
      islanguageError: (isError.islanguageError = false),
      ismessageTypeError: (isError.ismessageTypeError = false),
      istemplateNameError: (isError.istemplateNameError = false),
      isbodyError: (isError.isbodyError = false),
    }));
    setsearchVal("");
  };

  const [totalPage, setTotalPage] = useState(0);
  useEffect(() => {
    if (searchVal) {
      const searchResults = getmessagedata?.templateName?.filter((item) =>
        item.toLowerCase().includes(searchVal.toLowerCase())
      );
      const searchResultsObj = {
        searchVal: searchResults,
      };
      setSearchResultsObj(searchResultsObj);
    }
  }, [searchVal]);

  const halndleClickonProceed = () => {
    setAllTextFieldState((prev) => ({
      ...prev,
      ["status"]: AllTextFieldState.status === "ACTIVE" ? "ACTIVE" : "INACTIVE",
    }));
    setStatus(AllTextFieldState.status === "ACTIVE" ? "INACTIVE" : "ACTIVE");
    handleClose();
  };

  const getAutoPopulateValue = (value) => {
    return templateConfigData?.find(
      (item) => item?.templatedConfig?.jsonPath.trim() === value.trim()
    );
  };

  const handleRowClick = (row) => {
    const data = {
      channel: row?.channel,
      templateId: row?.templateId,
      templateName: row?.templateName,
      language: row?.locale,
      messageType: row?.addonAttributes?.messageType,
      body: decodeURIComponent(row?.content),
      is_update: true,
      status: row?.status === "DELETED" ? "INACTIVE" : "ACTIVE",
    };
    const mappings = row?.templatePropertyMappings
      ? Object?.values(row?.templatePropertyMappings)
      : [];
    console.log(mappings);

    const mappingsData = mappings?.map((item) => ({
      constantValue: item?.constantValue ? true : false,
      autoPopulate: item?.jsonPath ? true : false,
      userInput: false,
      inputData: {
        constantValue: item?.constantValue,
        autoPopulate: item?.jsonPath
          ? getAutoPopulateValue(item?.jsonPath)
          : item,
        userInput: "",
        variableInputType: item?.jsonPath
          ? { label: "Auto Populate", value: "autoPopulate" }
          : { label: "Constant", value: "constantValue" },
      },
    }));
    setTemplatePropertyMappings(mappingsData);
    setAllTextFieldState((prev) => ({ ...prev, ...data }));
    showFormActions("fromRowClick");
  };

  const handleAddRow = () => {
    const data = {
      constantValue: false,
      autoPopulate: false,
      userInput: false,
      inputData: {
        constantValue: "",
        autoPopulate: "",
        userInput: "",
        variableInputType: "",
      },
    };
    setTemplatePropertyMappings((prev) => [...prev, data]);
  };

  const handleVariableDropDown = (name, obj, ind) => {
    if (name === "variableInputType") {
      const val = obj.value;
      setVariableInputs((prev) => ({ ...prev, ["variableInputType"]: obj }));
      const mappings = templatePropertyMappings?.map((item, index) => {
        const keys = Object?.keys(item).filter((objValue) => objValue !== val);
        if (index === ind) {
          item[val] = true;
          item[keys[0]] = false;
          item[keys[1]] = false;
          item.inputData["variableInputType"] = obj;
          return item;
        } else {
          return item;
        }
      });
      setTemplatePropertyMappings(mappings);
    }
    if (name === "autoPopulate") {
      const mappings = templatePropertyMappings?.map((item, index) => {
        if (index === ind) {
          item.inputData["autoPopulate"] = obj;
          return item;
        } else {
          return item;
        }
      });
      setTemplatePropertyMappings(mappings);
    }
  };

  const handleVariableInputChange = (e, ind) => {
    var value = e.target.value;
    const mappings = templatePropertyMappings?.map((item, index) => {
      if (index === ind) {
        let inputData = {
          displayName: "",
          templatePropertySampleValueMap: "",
          templatedConfig: {
            constantValue: value,
            dataType: "STRING",
            jsonPath: null,
            outputFormatStr: "NONE",
            type: "CONSTANT",
          },
        };
        item.inputData["autoPopulate"] = inputData;
        return item;
      } else {
        return item;
      }
    });
    setTemplatePropertyMappings(mappings);
  };
  const onSearchevent = async (value) => {
    setsearchVal(value);
  };
  useEffect(() => {
    if (searchVal) {
      const searchResults = Messagetemplatedata?.filter((item) =>
        item.templateName.toLowerCase().includes(searchVal.toLowerCase())
      );
      setSearchResultsObj(searchResults);
    }
  }, [searchVal]);

  useEffect(() => {
    getmessagedata(paginationdata);
    getTemplateConfigData();
  }, []);

  return (
    <>
      <div className={props.expand1.empty === true ? "main1" : "main"}>
        <Breadcrums
          firsttab={"Categories"}
          secondtab="Command Centre"
          thirdtab="Message Template"
        ></Breadcrums>
        <ToastContainer />
        <Grid container mt={-2}>
          <Grid item xs={6}>
            <div
              className={
                isFormVisible.isOpen
                  ? "leftcontainer rounded-lg"
                  : "leftcontainer1 rounded-lg"
              }
            >
              <Grid className=" p-7 ">
                <TextField
                  label=""
                  onChange={(e) => onSearchevent(e.target.value)}
                  onKeyDown={(e) => {
                    e.key === "Enter" && e.preventDefault();
                  }}
                  sx={{ m: 1, width: "25ch" }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchOutlinedIcon />
                      </InputAdornment>
                    ),
                  }}
                  value={searchVal}
                />
              </Grid>
              <Grid className="editContainer rounded-radiusFourPix ">
                <Grid
                  disabled={access?.servicerequestadd == false}
                  onClick={showFormActions}
                  className={isFormVisible.isOpen ? "addIconchange" : "addIcon"}
                >
                  <span className=" p-2 m-r-f ">Add</span>
                  <AddCircleOutlineIcon
                    style={{
                      fontSize: "14px",
                      color: "#545454",
                      marginBottom: "3px",
                      color: `${isFormVisible.isOpen ? "#ffffff" : "#545454"}`,
                    }}
                  />
                </Grid>

                <Grid className="addIcon">
                  <span className="p-2 m-r-f">Filter</span>
                  <img
                    src={filter}
                    alt="filter.png"
                    style={{
                      position: "absolute",
                      left: "90%",
                      right: "20px",
                      top: "15px",
                      bottom: "95px",
                      width: "11px",
                      paddingTop: "3px",
                    }}
                  ></img>
                </Grid>
              </Grid>
              <>
                <MessageTemplateList
                  setTotalPage={setTotalPage}
                  totalPage={totalPage}
                  setId={setId}
                  id={id}
                  handleRowClick={handleRowClick}
                  locationMultiSelectOptions={locationMultiSelectOptions}
                  data={searchVal ? searchresultsObj : Messagetemplatedata}
                  calculateData={calculateData}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  sampleview={access?.sampleview}
                  sampleupdate={access?.sampleupdate}
                  handlePageChange={handlePageChange}
                />
              </>
            </div>
          </Grid>

          {isFormVisible.isOpen ? (
            <Grid item xs={6}>
              <div className="rightcontainer rounded-lg">
                <div className="overflow-y-auto o-form-container">
                  <>
                    <div className="font-normal text-o-form-label-color mainhead flex justify-between items-center pl-5 pr-1 h-10">
                      <h1>Message Template Overview</h1>
                    </div>

                    <form autoComplete="off" onSubmit={bodySubmitHandler}>
                      <div className="mt-5 pl-5 OrgNameContainer">
                        <h3 className="font-normal text-o-form-label-color">
                          channel <span style={{ color: "red" }}> *</span>
                        </h3>
                        <Autocomplete
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          value={
                            AllTextFieldState &&
                            AllTextFieldState.channel &&
                            AllTextFieldState.channel
                          }
                          autoHighlight={true}
                          onChange={(e, v) =>
                            handleDropDownChange("channel", v)
                          }
                          getOptionLabel={(option) => (option ? option : "")}
                          id="loc-list-option"
                          options={channelList ? channelList : []}
                          sx={{ width: 300 }}
                          renderInput={(params) => (
                            <TextField
                              onFocus={(e) => resetErrorHandler(e)}
                              data-testid="gender"
                              variant="standard"
                              sx={{ width: 358 }}
                              autoComplete="OFF"
                              error={isError?.ischannelError === true}
                              helperText={
                                isError?.ischannelError
                                  ? "Valid (channel) Required !"
                                  : ""
                              }
                              disabled={isFormUpdate}
                              {...params}
                            />
                          )}
                        />
                      </div>

                      <div className="mt-5 pl-5 OrgNameContainer">
                        <h3 className="font-normal text-o-form-label-color">
                          Language <span style={{ color: "red" }}> *</span>
                        </h3>
                        <Autocomplete
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          value={
                            AllTextFieldState &&
                            AllTextFieldState.language &&
                            AllTextFieldState.language
                          }
                          autoHighlight={true}
                          onChange={(e, v) =>
                            handleDropDownChange("language", v)
                          }
                          getOptionLabel={(option) => (option ? option : "")}
                          id="loc-list-option"
                          options={languageList ? languageList : []}
                          sx={{ width: 300 }}
                          renderInput={(params) => (
                            <TextField
                              onFocus={(e) => resetErrorHandler(e)}
                              data-testid="gender"
                              variant="standard"
                              sx={{ width: 358 }}
                              autoComplete="OFF"
                              error={isError?.islanguageError === true}
                              helperText={
                                isError?.islanguageError
                                  ? "Valid (language) Required !"
                                  : ""
                              }
                              disabled={isFormUpdate}
                              {...params}
                            />
                          )}
                        />
                      </div>
                      <div className="mt-5 pl-5 OrgNameContainer">
                        <h3 className="font-normal text-o-form-label-color">
                          Message Type <span style={{ color: "red" }}> *</span>
                        </h3>
                        <Autocomplete
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          value={
                            AllTextFieldState &&
                            AllTextFieldState.messageType &&
                            AllTextFieldState.messageType
                          }
                          autoHighlight={true}
                          onChange={(e, v) =>
                            handleDropDownChange("messageType", v)
                          }
                          getOptionLabel={(option) => (option ? option : "")}
                          id="loc-list-option"
                          options={messageTypeList ? messageTypeList : []}
                          sx={{ width: 300 }}
                          renderInput={(params) => (
                            <TextField
                              onFocus={(e) => resetErrorHandler(e)}
                              data-testid="gender"
                              variant="standard"
                              sx={{ width: 358 }}
                              autoComplete="OFF"
                              error={isError?.ismessageTypeError === true}
                              helperText={
                                isError?.ismessageTypeError
                                  ? "Valid (messageType) Required !"
                                  : ""
                              }
                              disabled={isFormUpdate}
                              {...params}
                            />
                          )}
                        />
                      </div>
                      <div className="mt-5 pl-5 OrgNameContainer">
                        <h3
                          className="font-normal text-o-form-label-color"
                          id="COLOR"
                        >
                          Template Name <span style={{ color: "red" }}> *</span>
                        </h3>
                        <TextField
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          autoComplete="OFF"
                          onChange={(e) => handleInputChange(e)}
                          name="templateName"
                          value={AllTextFieldState.templateName}
                          style={{ width: "360px" }}
                          id="standard-basic"
                          label=""
                          variant="standard"
                          onFocus={(e) => resetErrorHandler(e)}
                          error={isError?.istemplateNameError === true}
                          helperText={
                            isError?.istemplateNameError
                              ? "Valid (templateName) Required !"
                              : ""
                          }
                        />
                      </div>

                      <div className="mt-5 pl-5 OrgNameContainer">
                        <h3
                          className="font-normal text-o-form-label-color"
                          id="COLOR"
                        >
                          Body <span style={{ color: "red" }}> *</span>
                        </h3>
                        <TextField
                          multiline
                          rows={3}
                          autoComplete="OFF"
                          onChange={(e) => handleInputChange(e)}
                          name="body"
                          value={AllTextFieldState.body}
                          style={{ width: "360px" }}
                          id="standard-basic"
                          label=""
                          variant="outlined"
                          onFocus={(e) => resetErrorHandler(e)}
                          error={isError?.isbodyError === true}
                          helperText={
                            isError?.isbodyError
                              ? "Valid (body) Required !"
                              : ""
                          }
                        />
                      </div>
                      {AllTextFieldState.is_update ? (
                        <>
                          <div className="mt-5 pl-5 OrgNameContainer ">
                            <h3 className="font-normal text-o-form-label-color">
                              Status
                            </h3>
                            <Autocomplete
                              autoHighlight={true}
                              value={
                                AllTextFieldState && AllTextFieldState.status
                              }
                              onChange={(e, v) =>
                                handleDropDownChange("status", v)
                              }
                              getOptionLabel={(option) =>
                                option ? option : ""
                              }
                              id="city-list-option"
                              onKeyDown={(e) => {
                                e.key === "Enter" && e.preventDefault();
                              }}
                              options={statuslist ? statuslist : []}
                              sx={{ width: 360 }}
                              renderInput={(params) => (
                                <TextField
                                  variant="standard"
                                  {...params}
                                  label=""
                                />
                              )}
                            />
                          </div>
                          <Modal
                            open={modalOpen}
                            onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                          >
                            <Box sx={style}>
                              <Typography
                                id="modal-modal-title"
                                variant="h6"
                                component="h2"
                              >
                                <Alert severity="info">
                                  {" "}
                                  {AllTextFieldState.status === "ACTIVE"
                                    ? "Are you sure you want to reactivate  the message template ?"
                                    : "Are you sure you want to Deactivate the message template ?"}
                                </Alert>
                              </Typography>
                              <Typography
                                id="modal-modal-description"
                                sx={{ mt: 2 }}
                              >
                                {AllTextFieldState.status === "INACTIVE"
                                  ? "This activity disable the message template and user will not able to access it. You can reactivate the message template at anytime?"
                                  : "This activity will enable the message template and user will be able to access it across all products."}

                                <div className="mt-10 flex justify-center items-center  towButtonContainer">
                                  <Button
                                    type="submit"
                                    onClick={handleClose}
                                    variant="contained"
                                    disableElevation
                                    style={{
                                      marginRight: "10px",
                                      borderColor: "#0F4391",
                                      width: "99px",
                                      height: "39px",
                                      borderRadius: "9999px",
                                      backgroundColor: "#E8FAFF",
                                      color: "#0F4391",
                                      border: "1px solid #0F4391",
                                      marginBottom: "100px",
                                    }}
                                  >
                                    CANCEL
                                  </Button>

                                  <Button
                                    type="submit"
                                    onClick={halndleClickonProceed}
                                    variant="contained"
                                    disableElevation
                                    style={{
                                      borderColor: "#0F4391",
                                      width: "99px",
                                      height: "39px",
                                      borderRadius: "9999px",
                                      backgroundColor: "#E8FAFF",
                                      color: "#0F4391",
                                      border: "1px solid #0F4391",
                                      marginBottom: "100px",
                                    }}
                                  >
                                    PROCEED
                                  </Button>
                                </div>
                              </Typography>
                            </Box>
                          </Modal>
                        </>
                      ) : null}

                      {templatePropertyMappings.length
                        ? templatePropertyMappings.map((item, index) => (
                            <div className="mt-5 pl-2">
                              <div className="mt-5 pl-2 OrgNameContainer">
                                <h3 className="font-normal text-o-form-label-color">
                                  Variable {`{{${index + 1}}}`}
                                </h3>
                                <Autocomplete
                                  onKeyDown={(e) => {
                                    e.key === "Enter" && e.preventDefault();
                                  }}
                                  value={item?.inputData?.variableInputType}
                                  autoHighlight={true}
                                  onChange={(e, v) =>
                                    handleVariableDropDown(
                                      "variableInputType",
                                      v,
                                      index
                                    )
                                  }
                                  getOptionLabel={(option) =>
                                    option ? option.label : ""
                                  }
                                  id="loc-list-option"
                                  options={
                                    variableInputList ? variableInputList : []
                                  }
                                  sx={{ width: 300 }}
                                  renderInput={(params) => (
                                    <TextField
                                      data-testid="gender"
                                      variant="standard"
                                      sx={{ width: 358 }}
                                      {...params}
                                    />
                                  )}
                                />
                              </div>
                              {item.autoPopulate ? (
                                <div className="mt-5 pl-2 OrgNameContainer">
                                  <Autocomplete
                                    onKeyDown={(e) => {
                                      e.key === "Enter" && e.preventDefault();
                                    }}
                                    value={item?.inputData?.autoPopulate}
                                    autoHighlight={true}
                                    onChange={(e, v) =>
                                      handleVariableDropDown(
                                        "autoPopulate",
                                        v,
                                        index
                                      )
                                    }
                                    id="loc-list-option"
                                    options={
                                      templateConfigData &&
                                      templateConfigData?.length > 0
                                        ? templateConfigData
                                        : []
                                    }
                                    getOptionLabel={(option) =>
                                      option.displayName
                                        ? option.displayName
                                        : ""
                                    }
                                    sx={{ width: 300 }}
                                    renderInput={(params) => (
                                      <TextField
                                        data-testid="gender"
                                        variant="standard"
                                        sx={{ width: 358 }}
                                        {...params}
                                      />
                                    )}
                                  />
                                </div>
                              ) : null}
                              {item?.constantValue ? (
                                <div className="mt-5 pl-2 OrgNameContainer">
                                  <TextField
                                    onKeyDown={(e) => {
                                      e.key === "Enter" && e.preventDefault();
                                    }}
                                    rows={3}
                                    autoComplete="OFF"
                                    onChange={(e) =>
                                      handleVariableInputChange(e, index)
                                    }
                                    name="constant"
                                    value={
                                      item?.inputData?.autoPopulate
                                        ?.templatedConfig?.constantValue
                                        ? item?.inputData?.autoPopulate
                                            ?.templatedConfig?.constantValue
                                        : item?.inputData?.constantValue
                                    }
                                    style={{ width: "360px" }}
                                    id="standard-basic"
                                    label=""
                                    variant="outlined"
                                  />
                                </div>
                              ) : null}
                            </div>
                          ))
                        : null}
                      <div className="mt-3 flex justify-center items-center  towButtonContainer h-40">
                        <Button
                          onClick={handleAddRow}
                          variant="contained"
                          disableElevation
                          style={{
                            borderColor: "#0F4391",
                            width: "99px",
                            height: "39px",
                            borderRadius: "9999px",
                            backgroundColor: "#E8FAFF",
                            color: "#0F4391",
                            border: "1px solid #0F4391",
                            marginBottom: "100px",
                          }}
                        >
                          Add
                        </Button>
                      </div>
                      <div className="mt-3 flex justify-center items-center  towButtonContainer h-40">
                        <Button
                          onClick={hideForm}
                          type="submit"
                          variant="contained"
                          disableElevation
                          style={{
                            marginRight: "10px",
                            borderColor: "#0F4391",
                            width: "99px",
                            height: "39px",
                            borderRadius: "9999px",
                            backgroundColor: "#E8FAFF",
                            color: "#0F4391",
                            border: "1px solid #0F4391",
                            marginBottom: "100px",
                          }}
                        >
                          CANCEL
                        </Button>
                        <Button
                          onClick={bodySubmitHandler}
                          type="submit"
                          variant="contained"
                          disableElevation
                          style={{
                            borderColor: "#0F4391",
                            width: "99px",
                            height: "39px",
                            borderRadius: "9999px",
                            backgroundColor: "#E8FAFF",
                            color: "#0F4391",
                            border: "1px solid #0F4391",
                            marginBottom: "100px",
                          }}
                        >
                          SAVE
                        </Button>
                      </div>
                    </form>
                  </>
                </div>
              </div>
            </Grid>
          ) : (
            ""
          )}
        </Grid>
      </div>
      <AdminCookieConsentBanner />
    </>
  );
};

export default MessageTemplate;
