import * as React from "react";
import { useState, useEffect, useRef } from "react";
import { Grid } from "@mui/material";
import "../table.css";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Delete from '../../global/icons/delete.png';
import DeleteWhite from '../../global/icons/unnamed.png';

const ReportCategoryList = (props) => {
  const { categoryList, selectedPage, setSelectedPage } = props;
  const handleClick = (row,i) => {
    if (props.sampleupdate == true) {
      let data = {};
      data.is_update = true;
      data.value = row.value;
      props.setAllTextFieldState({ "reportCategoryName":row,is_update:true});
      props.showFormActions();
      props.setErrorMessage(false);
      props.setId(i);
    } 
  };
  const itemsPerPage = 20;
  const totalRoles = categoryList?.length;
  const totalpages = Math.ceil(totalRoles / itemsPerPage);

  const handlePageChange = (event, page) => {
    setSelectedPage(page);
  };

  const start = (selectedPage - 1) * itemsPerPage;
  const end = Math.min(start + itemsPerPage, totalRoles);


  return (
    <Grid className="">
      <div class="scrollit">
       {totalRoles > 0 ? (
          <table
            className="b "
            style={{
              width: "90%",
              marginLeft: "20px",
              tableLayout: "fixed",
              height: "20vh",
            }}
          >
            <thead style={{ position: "sticky", zIndex: "1", top: "0" }}>
              <tr>
                <th className=" m-r-f">Report Category</th>
                <th className=" m-r-f">Action</th>
              </tr>
            </thead>
            <tbody>
            {categoryList?.slice(start, end).map((item, i) => {
                const rowIndex = start + i;
                  return (
                    <tr
                      key={i}
                      className="tablebor"
                      style={{
                        cursor: "pointer",
                        backgroundColor:
                          props.id === rowIndex
                            ? "rgb(15, 67, 145)"
                            : "",
                        color: props.id === rowIndex ? "white" : "",
                      }}
                    >
                      <td
                        className="  m-r-f"
                        onClick={(event) => handleClick(item,rowIndex)}
                        style={{ cursor: "pointer" }}
                      >
                        <a>{item}</a>
                      </td>
                      <td>
                      <figure>
                      <img
                    className="h-10"
                    src={props.id === rowIndex ? DeleteWhite : Delete}
                    style={{
                      height:
                        props.id === rowIndex
                          ? "23px"
                          : "",
                      marginLeft: props.id === rowIndex ? "10px" : "",
                    }}
                    onClick={(event) => props.handleDelete(item,rowIndex)}

                      />
                    </figure>
                    </td>
                     
                    </tr>
                  );
                })}
            </tbody>
          </table>
        ) : (
          <div className="record">No Records Found</div>
        )}
      </div>
      {totalRoles > 0 && (
        <div className="pagesam pt-1 m-1">
          <Stack spacing={2}>
            <Pagination
              count={totalpages}
              color="primary"
              page={selectedPage}
              onChange={handlePageChange}
            />
          </Stack>
          <div className="pagenum">
            <span className="ml-2">
              {start + 1} - {end} of {totalRoles}
            </span>
          </div>
        </div>
      )}
    </Grid>
  );
};

export default ReportCategoryList;
