import { useState } from "react";
import { useGetCreatingReportCategoryMutation } from "../../../services/adminLogIn";

const DoctorpanelLogic = () => {

    const [userAllTextFieldState, setUserTextFieldState] = useState({
        is_update: false,
       locations: [],
        encounterId: [],
        userId:[],
      

      });
      const [dropLocState, setDropLocState] = useState({});
      const getDistrictValFun1 = (value) => {
        //   getDistrictValFun(value)
      };
    
      // form on submit handleer
    
      const handleDropDownChange = (e, v) => {
        const value = v;
        if (e === "locname") {
          userAllTextFieldState.locations = value;
          setDropLocState(value);
          setUserTextFieldState(userAllTextFieldState);
        }
        if (e === "encname") {
          userAllTextFieldState.encounterId = value;
          setDropLocState(value);
          setUserTextFieldState(userAllTextFieldState);
        }
        if (e === "userId") {
            userAllTextFieldState.userId = value;
            setDropLocState(value);
            setUserTextFieldState(userAllTextFieldState);
          }
      };
    
      // exporting all handler and state and etc
      const exportAll = {
        setDropLocState,
        dropLocState,
        userAllTextFieldState,
        setUserTextFieldState,
        handleDropDownChange,
    
        //    districtlist
      };
    
      return exportAll;
    };

export default DoctorpanelLogic;
