import { LocalConvenienceStoreOutlined } from "@mui/icons-material";
import { useState } from "react";

import {
  useCreatingLocationMutation,
  useGetActiveDistrictValueMutation,
  useUpdatingLocationMutation,
} from "../../../services/adminLogIn";

const bodySiteData = {
  bodysiteCode: "",
  bodysiteDesc: "",
  bodysiteUrl: "",
  bodysiteName: "",
  bodysiteSystemValue: "",
  bodysiteStatus:"",
};
const bodySitesErrordata = {
    bodySiteNameError: "",
    bodySiteNamenumerror:"",
    bodySiteSystemError: "",
}
const useBodySiteHook = () => {
  const [bodySiteFormData, setBodySiteFormData] = useState(bodySiteData);
  const [bodySiteErrors, setBodySiteErrors] = useState(bodySitesErrordata);

  // dropdown handler

  const handleDropDownChange = (name, value) => {
    setBodySiteFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    if(value) {
        setBodySiteErrors((prev) => ({...prev, bodySiteSystemError: false}));
    }
  };
  // onfoucs reset error warning

  // const resetErrorHandler = (e) => {
  //     const name = e.target.name;
  //     switch (name) {
  //         case "name":
  //             setError((prev) => ({
  //                 ...prev,
  //                 isNameError: isLocError.isNameError = false
  //             }))
  //             break;

  //         case "phoneNumber":
  //             setError((prev) => ({
  //                 ...prev,
  //                 isPhoneNumberError: isLocError.isPhoneNumberError = false
  //             }))
  //             break;

  //         case "emailId":
  //             setError((prev) => ({
  //                 ...prev,
  //                 isEmailError: isLocError.isEmailError = false
  //             }))
  //             break;

  //     }

  // }

  // const halndleClickonProceed = () => {
  //     console.log(locAllTextFieldState.status)
  //     setLocTextFieldState((prev) => ({
  //         ...prev,
  //         ["status"]: locAllTextFieldState.status === "ACTIVE" ? "INACTIVE" : "ACTIVE"

  //     }))
  //     handleClose()
  // }
  // form on submit handleer

  // getting inputs value onChange
  //    .replace(/[^A-Za-z]/gi, "")
  const handleInputsChange = (e) => {
    const { name, value } = e.target;
    if (name === "bodysiteCode") {
      if (value.length < 100) {
        setBodySiteFormData((prev) => ({
          ...prev,
          [name]: value,
        }));
      }
    }
    if (name === "bodysiteDesc") {
      if (value.length < 250) {
        setBodySiteFormData((prev) => ({
          ...prev,
          [name]: value,
        }));
      }
    }
    
    if (name === "bodysiteName") {
      if (value.length < 100) {
        setBodySiteFormData((prev) => ({
          ...prev,
          [name]: value.replace(/[^a-z A-Z0-9]/gi, ""),
          
        }));
      }
    } else {
      setBodySiteFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const resetErrorHandler = (e) => {
    const { name } = e.target;
    if (name === "bodysiteName") {
        setBodySiteErrors((prev) => ({...prev, bodySiteNameError: false}));
        setBodySiteErrors((prev) => ({...prev, bodySiteNamenumerror: false}));
       
    }
    
    if (name === "bodysiteSystem") {
        setBodySiteErrors((prev) => ({...prev, bodySiteSystemError: false}));
    }
    if (name === "bodysiteUrl") {
      setBodySiteErrors((prev) => ({...prev, bodySiteUrlError: false}));
  }
  

  };

  // exporting all handler and state and etc
  const exportAll = {
    handleInputsChange,
    handleDropDownChange,
    setBodySiteFormData,
    setBodySiteErrors,
    resetErrorHandler,
    bodySiteErrors,
    bodySiteFormData,
  };

  return exportAll;
};

export default useBodySiteHook;
