import { LocalConvenienceStoreOutlined } from "@mui/icons-material";
import { useState } from "react";

import { useCreatingLocationMutation, useGetActiveDistrictValueMutation, useUpdatingLocationMutation } from "../../../services/adminLogIn";

const useLocHook = () => {

    // services requests hooks
    const [createLocFunc, createLocRes] = useCreatingLocationMutation();
    const [updateLocFunc, updateLocRes] = useUpdatingLocationMutation();
    const [getDistrictValFun, { isLoading1, isSuccess1, isError1, ...districtlist }] = useGetActiveDistrictValueMutation();
    const isEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    // making state for for organisation form
    const [locState, setLocState] = useState();

    // making state for all org text fileds

    const [locAllTextFieldState, setLocTextFieldState] = useState({
        name: '',
        level: '',
        is_update: false,
        id: '',
        address: '',
        state: '',
        city: '',
        postalCode: '',
        country: '',
        phoneNumber: '',
        emailId: '',
        status: 'ACTIVE'
    });
    const [open, setOpen] = useState(false);
    const [openLocationFilterModal, setOpenLocationFilterModal] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleLocationFilterModalOpen = () => setOpenLocationFilterModal(true);
    const handleLocationFilterModalClose = () => { setOpenLocationFilterModal(false); }
    const [filterLocationData, setLocationFilterData] = useState({ classificationId: "", sampleName: "", filterStatus: "" });
    // making separate state for dropdown
    const [dropLocState, setDropLocState] = useState({
        id: ''
    });
    const [dropLocManage, setDropLocManage] = useState({
        id: ''
    });
    // making state for error handling for org-form

    const getDistrictValFun1 = (value) => {
        getDistrictValFun(value)

    }
    const [isLocError, setError] = useState({
        is_update: false,
        isNameError: '',
        isIdError: '',
        isDropDownError: false,
        isAddressError: false,
        isCityError: '',
        isStateError: '',
        isPostalCodeError: '',
        isCountryError: '',
        isPhoneError: '',
        isEmailError: ''
    });


    // dropdown handler 

    const handleDropDownChange = (e, v) => {
        const value = v;
        if (e === 'partof') {
            setDropLocState(value);
        }
        if (e === "state") {
            getDistrictValFun1(value)
            setLocTextFieldState((prev) => ({
                ...prev,
                [e]: value
            }))
            setLocTextFieldState((prev) => ({
                ...prev,
                ['city']: ''
            }))
        }
        if (e === "city") {
            getDistrictValFun1(value)
            setLocTextFieldState((prev) => ({
                ...prev,
                [e]: value
            }))
        }
        if (e === "country") {

                    setLocTextFieldState((prev) => ({
                        ...prev,
                        [e]: value
                    }))
                }
        if (e === "manageorg") {
            setDropLocManage(value)
        }
        if (e === "status") {
            handleOpen()
        }
    }
    // onfoucs reset error warning

    const resetErrorHandler = (e) => {
        const name = e.target.name;
        switch (name) {
            case "name":
                setError((prev) => ({
                    ...prev,
                    isNameError: isLocError.isNameError = false
                }))
                break;

            case "phoneNumber":
                setError((prev) => ({
                    ...prev,
                    isPhoneNumberError: isLocError.isPhoneNumberError = false
                }))
                break;



            case "emailId":
                setError((prev) => ({
                    ...prev,
                    isEmailError: isLocError.isEmailError = false
                }))
                break;

        }

    }

    const halndleClickonProceed = () => {
        setLocTextFieldState((prev) => ({
            ...prev,
            ["status"]: locAllTextFieldState.status === "ACTIVE" ? "INACTIVE" : "ACTIVE"

        }))
        handleClose()
    }
    // form on submit handleer 
    const locSubmitHandler = (e) => {
        e.preventDefault();
        console.log(dropLocState)
        if (locAllTextFieldState.name === "") {
            setError((prev) => ({
                ...prev,
                isNameError: isLocError.isNameError = true
            }))

        }

        else if (dropLocManage.id === '') {
            setError((prev) => ({
                ...prev,
                isNameError: isLocError.isNameError = false,
                isDropDownError: isLocError.isDropDownError = true
            }))
        }
        else if ((locAllTextFieldState.emailId != null && locAllTextFieldState.emailId != "") && !isEmail(locAllTextFieldState.emailId)) {
            setError((prev) => ({
                ...prev,
                isNameError: isLocError.isNameError = false,
                isDropDownError: isLocError.isDropDownError = false,
                isPhoneError: isLocError.isPhoneError = false,
                isEmailError: isLocError.isEmailError = true,


            }))
        } else {

            const finalRef = dropLocState.id;


            setError((prev) => ({
                ...prev,
                isNameError: isLocError.isNameError = false,
                isPhoneError: isLocError.isPhoneError = false,
                isEmailError: isLocError.isEmailError = false,
                isDropDownError: isLocError.isDropDownError = false,

            }))


            const sendLocDetails = {
                
                resourceType: "Location",
                id: locAllTextFieldState.is_update === false ? "LOC-" + locAllTextFieldState.name.split(' ').join('-').toUpperCase().replace(/[^a-zA-Z0-9 ]/g, '-') : locAllTextFieldState.id,
                identifier: [{
                    system: "http://karkinos.com",
                    value: locAllTextFieldState.name,
                }],
                status: locAllTextFieldState.is_update === false ? "ACTIVE" : locAllTextFieldState.status,
                name: locAllTextFieldState.name,
                level: locAllTextFieldState.level ? locAllTextFieldState.level : null,
                "type": [
                    {
                        "coding": [
                            {
                                "system": "",
                                "code": "",
                                "display": ""
                            }
                        ]
                    }
                ],
                address: {

                    // text:locAllTextFieldState.address,
                    city: locAllTextFieldState.city.name,
                    state: locAllTextFieldState.state.name,
                    district: "",
                    postalCode: locAllTextFieldState.postalCode,
                    countryISOCode: locAllTextFieldState.country ? locAllTextFieldState.country.isoCode : "",
                },
                telecom: [{
                    system: "PHONE",
                    value: locAllTextFieldState.phoneNumber,
                },
                {
                    system: "EMAIL",
                    value: locAllTextFieldState.emailId
                }
                ],

                partOf: {
                    type: "Location",
                    identifier: {
                        system: "http://karkinos.com",
                        value: dropLocState.id != '' && dropLocState.id != null ?  dropLocState.id : '',
                    },
                    reference: dropLocState.id != '' && dropLocState.id != null ? "Location/" + dropLocState.id : '',
                },

                "managingOrganization": {
                    "type": "WORK",
                    "identifier": {
                        "system": "http://karkinos.com",
                        "value":  dropLocManage.id
                    },
                    "reference": "Organization/" + dropLocManage.id
                },

                availabilityExceptions: ""
            }

            if (locAllTextFieldState.is_update === false) createLocFunc(sendLocDetails)
            else updateLocFunc(sendLocDetails)
            setErrorMessage(true)

            // end of else  
        }




    }

   

    // getting inputs value onChange
    //    .replace(/[^A-Za-z]/gi, "")
    const whenInputsChange = (e) => {
        const { name, value } = e.target;
        if (name === "name") {
            if (value.length <= 150) {
                setLocTextFieldState((prev) => ({
                    ...prev,
                    [name]: value

                }))
            }
        }
        if (name === "level") {
            
                setLocTextFieldState((prev) => ({
                    ...prev,
                    [name]: value

                }))
            
        }
        // else if (name === "level") {
        //     setLocTextFieldState((prev) => ({
        //         ...prev,
        //         [name]: value.replace(/[^a-z A-Z0-9]/gi, ""),

        //     }))
        // }
        else if (name === "phoneNumber") {
            setLocTextFieldState((prev) => ({
                ...prev,
                [name]: value.replace(/[^0-9]/gi, "")

            }))
        }
        else if (name === "postalCode") {
            if (value.length <= 8) {
                setLocTextFieldState((prev) => ({
                    ...prev,
                    [name]: value.replace(/[^0-9]/gi, "")

                }))
            }

        }
        else {
            setLocTextFieldState((prev) => ({
                ...prev,
                [name]: value

            }))
        }




    }
    const [showerror, setErrorMessage] = useState(false);

    // exporting all handler and state and etc
    const exportAll = {
        locSubmitHandler,
        whenInputsChange,
        locState,
        dropLocState,
        dropLocManage,
        locAllTextFieldState,
        isLocError,
        handleDropDownChange,
        handleLocationFilterModalOpen,
        handleLocationFilterModalClose,
        openLocationFilterModal,
        filterLocationData,
        createLocRes,
        updateLocRes,
        resetErrorHandler,
        setLocTextFieldState,
        setDropLocState,
        setDropLocManage,
        districtlist,
        getDistrictValFun1,
        open,
        handleClose,
        handleOpen,
        setOpen,
        halndleClickonProceed,
        setErrorMessage,
        showerror

    }

    return exportAll;
}

export default useLocHook;
