import React, { useState } from "react";
import { Grid } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import "../table.css";
import Delete from "../../global/icons/delete.png";
import DeleteWhite from "../../global/icons/unnamed.png";
import Tooltip from "@mui/material/Tooltip";

const HeaderFooterList = (props) => {
  const { hasFocus, value, handleRowClick, currentPage, setCurrentPage, handlePageChange, id, setId } =
    props;
  const handleClick = (item, id) => {
    handleRowClick(item, id);
    setId(item.reportTypeId);
  };

  const handleOnChange = (page) => {
    setCurrentPage(page)
    handlePageChange(page);
  };
  return (
    <Grid className="">
      <div class="scrollit">
        {props && props?.data && props?.data?.content?.length > 0 ? (
          <table
            className="b "
            style={{
              width: "90%",
              marginLeft: "20px",
              tableLayout: "fixed",
              height: "20vh",
            }}
          >
            <thead style={{ position: "sticky", zIndex: "1", top: "0" }}>
              <tr>
                <th className=" m-r-f" style={{ width: "50px" }}>
                  <input type="checkbox" id="" name="vehicle1" value="Bike" />
                </th>
                <th className=" m-r-f">Location</th>
                <th className=" m-r-f">Encounter Type</th>
                <th className=" m-r-f">Action</th>
              </tr>
            </thead>
            <tbody>
              {props &&
                props?.data &&
                props?.data?.content?.length > 0 &&
                props?.data?.content?.map((item, i) => {
                  return (
                    <tr
                      key={i}
                      className="tablebor"
                      style={{
                        cursor: "pointer",
                        backgroundColor:
                          id && id === item?.reportTypeId
                            ? "rgb(15, 67, 145)"
                            : "",
                        color: id === item.reportTypeId ? "white" : "",
                      }}
                    >
                      <td>
                        <input type="checkbox" id={i} />
                      </td>
                      <Tooltip
                        title={
                          item.locationdetails.length > 1
                            ? item.locationdetails
                                .map((location) => location.locationName)
                                .join(" , ")
                            : item.locationdetails[0]?.locationName
                        }
                        followCursor
                      >
                        <td
                          className="  m-r-f"
                          onClick={(event) => handleClick(item)}
                        >
                          <a>
                            {item.locationdetails.length > 1
                              ? item.locationdetails[0]?.locationName +
                                ` +${item.locationdetails.length - 1}`
                              : item.locationdetails[0]?.locationName}
                          </a>
                        </td>
                      </Tooltip>
                      <td
                        className=" m-r-f"
                        style={{ paddingLeft: "" }}
                        onClick={(e) => handleClick(item, i)}
                      >
                        {item.encounterTypeId}
                      </td>
                      <td className=" m-r-f">
                        <figure>
                          <img
                            className="h-10"
                            style={{
                              height: id === item.reportTypeId ? "23px" : "",
                              marginLeft:
                                id === item.reportTypeId ? "10px" : "",
                            }}
                            src={
                              id === item.reportTypeId ? DeleteWhite : Delete
                            }
                            onClick={() => props.handleDelete(item)}
                          />
                        </figure>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        ) : (
          <div className="record">No Records Found</div>
        )}
      </div>
      {props &&
        props.data &&
        props.data.content &&
        props.data.content.length > 0 && (
          <div className="pagesam pt-1 m-1">
            <Stack spacing={2}>
              <Pagination
                count={props?.data?.totalPages}
                color="primary"
                page={currentPage} 
                onChange={(e,page) => handleOnChange(page)}
              />
            </Stack>
            <div className="pagenum">
              <span className="ml-2">
                {props?.data?.number * 20 + 1}-
                {props?.data?.number * 20 + props?.data?.numberOfElements} of{" "}
                {props?.data?.totalElements}
              </span>
            </div>
          </div>
        )}
    </Grid>
  );
};

export default HeaderFooterList;
