import * as React from 'react';
import { useState, useEffect, useRef } from "react";
import { Grid } from '@mui/material';
import "../table.css"
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Delete from '../../global/icons/delete.png';
import DeleteWhite from '../../global/icons/unnamed.png';


const MedicineList = (props) => {
  const { hasFocus, value } = props;
  const [id, setId] = useState(0);

  const handleClick = (row) => {
    props.setId(row.medicineMasterId)
    setId(row.medicineMasterId)
    props.setTextFieldState({ ...row, 'is_update': true, 'medicineMasterId': row.medicineMasterId, "medicineMasterSingleLine": row.medicineMasterSingleLine, "route": row?.route?.split("/") })
    // props.getUserByIdFunct(row.lastModifiedBy,row.medicineMasterId)

    props.setIsCombDrug(/[0-9]/.test(row.displayStrength) == true ? false : true)
    props.showFormActions()
  }

  const handleOnChange = (event, page) => {
    props.getMedicineMasterListFunctByPage(page)

  };
  return (
    <Grid className="" >
      <div class="scrollit">
        {props.tagsview === true && props.medicineList && props.medicineList.length > 0 ?
          <table className='b ' style={{ width: '90%', marginLeft: '20px', tableLayout: "fixed", height: "20vh" }}>
            <thead style={{ position: 'sticky', zIndex: '1', top: '0' }}>
              <tr>
                <th className=" m-r-f" style={{ width: '50px' }}><input type="checkbox" id="" name="vehicle1" value="Bike" /></th>
                <th className=" m-r-f">Medicine</th>
                <th className=" m-r-f">Action</th>
              </tr>
            </thead>
            <tbody>

              {props.medicineList && props.medicineList && props.medicineList.length > 0 && props.medicineList.map((item, i) => {
                return (
                  //
                  <tr key={i} className="tablebor" style={{ cursor: 'pointer', backgroundColor: (id === item?.medicineMasterId) ? "rgb(15, 67, 145)" : "", color: id === item.medicineMasterId ? "white" : "" }}>
                    <td  >< input type="checkbox" id={i} /></td>
                    <td className="  m-r-f" onClick={event => handleClick(item)} style={{ cursor: 'pointer', }}><a>{item.genericName + " - " + item.name + " - " + item.displayStrength + " - " + item.drugForm + " - " + item.route}</a></td>
                    <td className=" m-r-f" onClick={event => handleClick(item)}>
                    <figure>
                      <img
                    className="h-10"
                    src={id === item.medicineMasterId ? DeleteWhite : Delete} 
                    style={{
                      height:
                      id === item.medicineMasterId
                          ? "23px"
                          : "",
                      marginLeft: id === item.medicineMasterId ? "10px" : "",
                    }}
                    onClick={() => props.handleDelete(item)}
                 
                      />
                    </figure>
                    </td>

                  </tr>

                )
              })
              }

            </tbody>

          </table>
          : <div className='record'>
            No Records Found
          </div>}

      </div>

      {props.medicineList && props.medicineList && props.medicineList.length > 0 &&
        <div className="pagesam pt-1 m-1">

          <Stack spacing={2}>
            <Pagination count={props?.totalPage} color="primary" page={props.currentPage} onChange={(e, page) => handleOnChange(e, page)} />
          </Stack>
          <div className='pagenum'>
            <span className='ml-2'>{props?.calculateData?.number * 20 + 1}-{props?.calculateData?.number * 20 + props?.calculateData?.numberOfElements} of {props?.calculateData?.totalElements}</span>
          </div>


        </div>
      }
    </Grid>

  );

}

export default MedicineList
