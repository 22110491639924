import { useState, useEffect, useRef } from "react";
import AdminHome from "../AdminHome";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import ProcedureLogic from "./procedureLogic";
import ProcedureList from "./procedureList";
import filter from "../../../images/filter.png";
import {
  useGetCategoryProcedureListMutation,
  useCreateUpdateProcedureMutation,
  useGetTaskTypeUsageCountTagMutation,
  useGetProcedureClassificationListMutation,
  useGetProcedureListMutation,
  useGetUserByUserIdMutation,
  useGetsamplematchingListMutation,
  useSearchsampleMutation,
  useGetpatientpaginationMutation,
  useUpdatigtaskresolutionMutation,
} from "../../../services/adminLogIn";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import "../Organization/Mainhome.css";
import { Grid } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Breadcrums from "../../global/breadcrums/Breadcrums";
import { useSelector } from "react-redux";
import { toasterFunction } from "../../../globalFunctions/getLocalStorageData";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminCookieConsentBanner from "../home/AdminCookieConsentBanner";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const Procedure = (props) => {
  const [getCategoryList] = useGetCategoryProcedureListMutation();
  const [getClassificationList] = useGetProcedureClassificationListMutation();
  const [getProcedureList] = useGetProcedureListMutation();
  const [createupdateProcedure] = useCreateUpdateProcedureMutation();
  const [updatigtaskresolution] = useUpdatigtaskresolutionMutation();
  const [getUserByUserId] = useGetUserByUserIdMutation();
  const [getUsageCountResolution] = useGetTaskTypeUsageCountTagMutation();
  const [access, setAccess] = useState(
    JSON.parse(sessionStorage.getItem("access"))
  );
  const [systemList, setSystem] = useState(["SNOMED","LOINC","ICD"]);
  const [expand, setexpand] = useState({
    heading: "",
    icon: "",
    isSubListExpanded: false,
  });
  const [expand1, setexpand1] = useState({
    empty: true,
  });

  const [isNewData, setIsNewData] = useState(true);
  const [fullName, setFullName] = useState("");
  const [usageCount, setUsageount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [calculateData, setTotalPageCalculate] = useState({
    number: "",
    numberOfElements: "",
    totalElements: "",
    showcount: "",
  });

  const {
    whenInputsChange,
    AllTextFieldState,
    setDropdownState,
    isError,
    handleDropDownChange,
    createResponse,
    updateResponse,
    resetErrorHandler,
    setTextFieldState,
    setDropDownState,
    open,
    handleClose,
    setErrorMessage,
    showerror,
    setError,
    taskSubmitStatus,
    halndleClickonProceed,
    setbodyTextFieldState,
    setDropsampleidState,
  } = ProcedureLogic();
  const [statuslist] = useState(["ACTIVE", "INACTIVE"]);
  const [isFormVisible, setFormVisible] = useState({
    isOpen: false,
    isClose: true,
  });
  const [id, setId] = useState("");
  const [procedureList, setProcedureList] = useState([]);
  const [classificationList, setClassificationList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [paginationdata, setPaginationData] = useState({
    string: "",
    page: 0,
  });

  const hideForm = () => {
    setErrorMessage((prev) => ({
        ...prev,
        isTaskResolutionListError: isError.isTaskResolutionListError = false,
        isSystemError: isError.isSystemError = false,
        isCodeError: isError.isCodeError = false,
        isCategoryError: isError.isCategoryError = false,
        isClassificationError: isError.isClassificationError = false,
      }));

    setTextFieldState({
      is_update: false,
      procedureMasterId: "",
      name: '',
      procedureCategoryId: "",
      classificationId: "",
      terminology: '',
      code: '',
      deleted: false,
    });
    if (isFormVisible.isOpen) {
      setFormVisible((prev) => ({
        ...prev,
        isOpen: (isFormVisible.isOpen = false),
        isFormVisible,
        isClose: (isFormVisible.isClose = true),
      }));
    }
    setsearchVal("");
    getListFunction({ searchStr: "", deleted: "", page: 0 });
  };

  const [totalPage, setTotalPage] = useState(0);
  const [searchVal, setsearchVal] = useState("");

  const getUserByIdFunct = async (userId, procedureCategoryId) => {
    const res = await getUserByUserId(userId);
    if (res?.data?.content.length > 0) {
      setFullName(res?.data?.content[0].fullName);
    }
    let data = {
      taskResolutionList: [procedureCategoryId],
    };
    const usageRes = await getUsageCountResolution(data);
    if (usageRes?.data?.content.length > 0) {
      setUsageount(usageRes?.data?.totalElements);
    }
  };

  const getPageData = (page) => {
    setIsNewData(false);
    setCurrentPage(page);
    getListFunction({ searchStr: "", deleted: "", page: page - 1 });
  };

  const getListFunction = async (payload) => {

    const result = await getProcedureList(payload);
    if (result?.data?.length > 0) {
      setProcedureList(result?.data);
    //   setTotalPage(res?.data?.totalPages);
    //   setTotalPageCalculate({
    //     numberOfElements: res?.data?.numberOfElements,
    //     number: res?.data?.number,
    //     totalElements: res?.data?.totalElements,
    //   });
    } else {
        setProcedureList([]);
    }

    const res = await getCategoryList({ searchStr: "", deleted: "", page: 0,size:499 });
    if (res?.data?.length > 0) {
      setCategoryList(res?.data);
    //   setTotalPage(res?.data?.totalPages);
    //   setTotalPageCalculate({
    //     numberOfElements: res?.data?.numberOfElements,
    //     number: res?.data?.number,
    //     totalElements: res?.data?.totalElements,
    //   });
    } else {
        setCategoryList([]);
    }
    const res1 = await getClassificationList({ searchStr: "", deleted: "", page: 0,size:499 });
    if (res1?.data?.content?.length > 0) {
      setClassificationList(res1?.data?.content);
     
    } else {
        setClassificationList([]);
    }
  };
  const getProcedurePage = async (page) => {
    const res = await getProcedureList({ string: "", page : page-1 , size: 20 });
    setCurrentPage(page)
    if (res?.data?.content) {
      setProcedureList(res?.data?.content)
      setTotalPage(res?.data?.totalPages)
      setTotalPageCalculate({ numberOfElements: res?.data?.numberOfElements, number: res?.data?.number, totalElements: res?.data?.totalElements })
   
    }
  }
  const showFormActions = () => {
    if (access?.taskresolutionview == true) {
      setErrorMessage(false);
      setId("");
      if (!isFormVisible.isOpen) {
        setFormVisible((prev) => ({
          ...prev,
          isOpen: (isFormVisible.isOpen = true),
          isFormVisible,
          isClose: (isFormVisible.isClose = false),
        }));
        if (AllTextFieldState.is_update === false) {
          setId("");
        }
      }
    } else {
      toasterFunction(" No Permission", "error");
    }
  };
  const SubmitHandler = async (e) => {
    e.preventDefault();
    if (AllTextFieldState.name === "") {
      setErrorMessage((prev) => ({
        ...prev,
        isTaskResolutionListError: isError.isTaskResolutionListError = true,
        isSystemError: isError.isSystemError = true,
        isCodeError: isError.isCodeError = true,
        isClassificationError: isError.isClassificationError = true,
        isCategoryError: isError.isCategoryError = true,
      }));
    } 
    else if(AllTextFieldState.procedureCategoryId === "") {
      setErrorMessage((prev) => ({
          ...prev,
          isTaskResolutionListError: isError.isTaskResolutionListError = false,
          isClassificationError: isError.isClassificationError = true,
          isCategoryError: isError.isCategoryError = true,
          isSystemError: isError.isSystemError = true,
          isCodeError: isError.isCodeError = true,
        }));
  }  else if(AllTextFieldState.classificationId === "") {
    setErrorMessage((prev) => ({
        ...prev,
        isTaskResolutionListError: isError.isTaskResolutionListError = false,
        isClassificationError: isError.isClassificationError = true,
        isCategoryError: isError.isCategoryError = false,
        isSystemError: isError.isSystemError = true,
        isCodeError: isError.isCodeError = true,
      }));
} else if(AllTextFieldState.terminology === "") {
        setErrorMessage((prev) => ({
            ...prev,
            isTaskResolutionListError: isError.isTaskResolutionListError = false,
            isSystemError: isError.isSystemError = true,
            isCodeError: isError.isCodeError = true,
            isClassificationError: isError.isClassificationError = false,
            isCategoryError: isError.isCategoryError = false,
          }));
    } 
    else if(AllTextFieldState.code === "") {
        setErrorMessage((prev) => ({
            ...prev,
            isTaskResolutionListError: isError.isTaskResolutionListError = false,
            isClassificationError: isError.isClassificationError = false,
            isCategoryError: isError.isCategoryError = false,
            isSystemError: isError.isSystemError = false,
            isCodeError: isError.isCodeError = true,
          }));
    } 
   
    else {
        setErrorMessage((prev) => ({
            ...prev,
            isTaskResolutionListError: isError.isTaskResolutionListError = false,
            isSystemError: isError.isSystemError = false,
            isCodeError: isError.isCodeError = false,
            isClassificationError: isError.isClassificationError = false,
            isCategoryError: isError.isCategoryError = false,
          }));
     
      if (AllTextFieldState.is_update === false) {
        let sendOrgDetails = {
          name: AllTextFieldState.name,
          code: AllTextFieldState.code,
          terminology: AllTextFieldState.terminology,
          procedureCategoryId: AllTextFieldState.procedureCategoryId.procedureCategoryId,
          classificationId: AllTextFieldState.classificationId.classificationId,
          deleted: AllTextFieldState.deleted,
        };
        const res = await createupdateProcedure(sendOrgDetails);
        
        if (res?.data?.procedureCategoryId) {
          hideForm();
          toasterFunction("Procedure Created successfully", "success");
        } else {
          toasterFunction(res?.error?.data?.message, "error");
        }
        
      }
      else {
        let sendOrgDetails = {
          procedureMasterId: AllTextFieldState.procedureMasterId,
          name: AllTextFieldState.name,
          code: AllTextFieldState.code,
          terminology: AllTextFieldState.terminology,
          procedureCategoryId: AllTextFieldState.procedureCategoryId.procedureCategoryId,
          classificationId: AllTextFieldState.classificationId.classificationId,
          deleted: AllTextFieldState.deleted,
        };
        const res = await createupdateProcedure (sendOrgDetails);
        if (res?.data?.procedureCategoryId) {
          hideForm();
          toasterFunction("Procedure Updated successfully", "success");
        } else {
          toasterFunction(res?.error?.data?.message, "error");
        }
    }
      setErrorMessage(true);
    }
  };
  const onSearchevent = (value) => {
    setsearchVal(value);

    let payload = {};
    payload.searchStr = value;
    payload.deleted = "";
    payload.page = 0;
    payload.size = 0;
    getListFunction(payload);
    setIsNewData(true);
    setCurrentPage(1);
  };

  useEffect(() => {
    getListFunction({ searchStr: "", deleted: "", page: 0 ,size:20});
    setIsNewData(true);
    setCurrentPage(1);
  }, []);

  return (
    <>
      <div className={props.expand1.empty === true ? "main1" : "main"}>
        {/* <Breadcrums
          firsttab={"Categories"}
          secondtab="Command Centre"
          thirdtab="Classification Procedure"
        ></Breadcrums> */}
         <div className="breadCumContainer flex justify-start items-center h-9 w-full bg-o-color-one organ1" >
            <ol className="ml-8 flex " style={{ listStyleType: 'none' }}>
            <div className="flex mt-1" >
                <li className="w-28 h-thirtyPix rounded-mysix flex justify-center items-center font-medium bg-white m-r-f orgbox" ><span>Categories</span></li> 
              </div><i className="right"></i>
              <div className="flex mt-1" >
                <li className="w-28 h-thirtyPix rounded-mysix flex justify-center items-center font-medium bg-white m-r-f orgbox rows" ><span>Procedure</span></li>
              </div><i className="right"></i>
              <div className="flex mt-1" >
                <li className="w-28 h-thirtyPix rounded-mysix flex justify-center items-center font-medium bg-white m-r-f orgbox rows breadback"  style={{ padding: '12px' }}><span>Classification Procedure</span></li>
              </div>
            </ol>
          </div>
        <ToastContainer />
        <Grid container mt={-2}>
          <Grid item xs={6}>
            <div
              className={
                isFormVisible.isOpen
                  ? "leftcontainer rounded-lg"
                  : "leftcontainer1 rounded-lg"
              }
            >
              <Grid className=" p-7 ">
                <TextField
                  label=""
                  onChange={(e) => onSearchevent(e.target.value)}
                  onKeyDown={(e) => {
                    e.key === "Enter" && e.preventDefault();
                  }}
                  sx={{ m: 1, width: "25ch" }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchOutlinedIcon />
                      </InputAdornment>
                    ),
                  }}
                  value={searchVal}
                />
              </Grid>
              <Grid className="editContainer rounded-radiusFourPix ">
                <Grid
                  disabled={access?.useradd == false}
                  onClick={showFormActions}
                  className={isFormVisible.isOpen ? "addIconchange" : "addIcon"}
                >
                  <span className=" p-2 m-r-f ">Add</span>
                  <AddCircleOutlineIcon
                    style={{
                      fontSize: "14px",
                      color: "#545454",
                      marginBottom: "3px",
                      color: `${isFormVisible.isOpen ? "#ffffff" : "#545454"}`,
                    }}
                  />
                </Grid>
                <Grid className="addIcon">
                  <span className="p-2 m-r-f">Filter</span>
                  <img
                    src={filter}
                    alt="filter.png"
                    style={{
                      position: "absolute",
                      left: "90%",
                      right: "20px",
                      top: "15px",
                      bottom: "95px",
                      width: "11px",
                      paddingTop: "3px",
                    }}
                  ></img>
                </Grid>
              </Grid>
              <>
                <ProcedureList
                  setErrorMessage={setErrorMessage}
                  setTotalPage={setTotalPage}
                  totalPage={totalPage}
                  setId={setId}
                  id={id}
                  procedureList={procedureList}
                  categoryList={categoryList}
                  classificationList={classificationList}
                  getPageData={getPageData}
                  getUserByIdFunct={getUserByIdFunct}
                  showFormActions={showFormActions}
                  setTextFieldState={setTextFieldState}
                  setDropDownState={setDropDownState}
                  setDropdownState={setDropdownState}
                  calculateData={calculateData}
                  getProcedurePage={getProcedurePage}
                  currentPage={isNewData ? 1 : currentPage}
                  taskresolutionview={access?.taskresolutionview}
                  taskresolutionupdate={access?.taskresolutionupdate}
                />
              </>
            </div>
          </Grid>

          {isFormVisible.isOpen ? (
            <Grid item xs={6}>
              <div className="rightcontainer rounded-lg">
                <div className="overflow-y-auto o-form-container">
                  <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={style}>
                      <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                      >
                        <Alert severity="info">
                          {" "}
                          {AllTextFieldState.deleted === true
                            ? "Are you sure you want to reactivate  the Procedure ?"
                            : "Are you sure you want to Deactivate the Procedure ?"}
                        </Alert>
                      </Typography>
                      <Typography id="modal-modal-code" sx={{ mt: 2 }}>
                        {AllTextFieldState.deleted === true
                          ? "This activity will enable the Procedure and user will be able to access it across all products."
                          : "This activity disable the Procedure and user will not able to access it. You can reactivate the Procedure at anytime?"}

                        <div className="mt-10 flex justify-center items-center  towButtonContainer">
                          <Button
                            type="submit"
                            onClick={handleClose}
                            variant="contained"
                            disableElevation
                            style={{
                              marginRight: "10px",
                              borderColor: "#0F4391",
                              width: "99px",
                              height: "39px",
                              borderRadius: "9999px",
                              backgroundColor: "#E8FAFF",
                              color: "#0F4391",
                              border: "1px solid #0F4391",
                              marginBottom: "100px",
                            }}
                          >
                            CANCEL
                          </Button>

                          <Button
                            type="submit"
                            onClick={halndleClickonProceed}
                            variant="contained"
                            disableElevation
                            style={{
                              borderColor: "#0F4391",
                              width: "99px",
                              height: "39px",
                              borderRadius: "9999px",
                              backgroundColor: "#E8FAFF",
                              color: "#0F4391",
                              border: "1px solid #0F4391",
                              marginBottom: "100px",
                            }}
                          >
                            PROCEED
                          </Button>
                        </div>
                      </Typography>
                    </Box>
                  </Modal>
                  <ToastContainer />
                  <>
                    <div className="font-normal text-o-form-label-color mainhead flex justify-between items-center pl-5 pr-1 h-10">
                      <h1>Procedure Overview</h1>
                    </div>

                    <form autoComplete="off" onSubmit={SubmitHandler}>
                      <div className=" pl-5 OrgNameContainer">
                        <h3
                          className="font-normal text-o-form-label-color"
                          id="Org"
                        >
                          Procedure
                          <span style={{ color: "red" }}> *</span>
                        </h3>
                        <TextField
                          onFocus={(e) => resetErrorHandler(e)}
                          autoComplete="OFF"
                          error={isError.isTaskResolutionListError === true}
                          helperText={
                            isError.isTaskResolutionListError
                              ? "Valid (Name) Required !"
                              : ""
                          }
                          onChange={(e) => whenInputsChange(e)}
                          name="name"
                          value={AllTextFieldState.name}
                          style={{ width: "360px" }}
                          id="standard-basic"
                          label=""
                          variant="standard"
                        />
                      </div>
                      <div className="mt-5 pl-5 OrgNameContainer ">
                          <h3
                            className="font-normal text-o-form-label-color "
                            id="color"
                          >
                            Procedure Category <span style={{ color: "red" }}> *</span>
                          </h3>
                          <Autocomplete
                            data-testid="procedureCategoryId"
                            value={AllTextFieldState?.procedureCategoryId}
                            onKeyDown={(e) => {
                              e.key === "Enter" && e.preventDefault();
                            }}
                            name="procedureCategoryId"
                            autoHighlight={true}
                            onChange={(e, v) =>
                              handleDropDownChange("procedureCategoryId", v)
                            }
                            getOptionLabel={(option) => (option ? option.procedureCategory : "")}
                            id="loc-list-option"
                            options={categoryList && categoryList}
                            sx={{ width: 360 }}
                            renderInput={(params) => (
                              <TextField
                                variant="standard"
                                {...params}
                                label=""
                                onFocus={(e) => resetErrorHandler(e)}
                                autoComplete="OFF"
                                error={
                                    isError.isCategoryError === true
                                  }
                                  helperText={
                                    isError.isCategoryError
                                      ? "Valid (procedureCategory) Required !"
                                      : ""
                                  }
                              />
                            )}
                          />
                        </div>
                        <div className="mt-5 pl-5 OrgNameContainer ">
                          <h3
                            className="font-normal text-o-form-label-color "
                            id="color"
                          >
                            Procedure Classification <span style={{ color: "red" }}> *</span>
                          </h3>
                          <Autocomplete
                            data-testid="classificationId"
                            value={AllTextFieldState?.classificationId}
                            onKeyDown={(e) => {
                              e.key === "Enter" && e.preventDefault();
                            }}
                            name="classificationId"
                            autoHighlight={true}
                            onChange={(e, v) =>
                              handleDropDownChange("classificationId", v)
                            }
                            getOptionLabel={(option) => (option ? option.classification : "")}
                            id="loc-list-option"
                            options={classificationList && classificationList}
                            sx={{ width: 360 }}
                            renderInput={(params) => (
                              <TextField
                                variant="standard"
                                {...params}
                                label=""
                                onFocus={(e) => resetErrorHandler(e)}
                                autoComplete="OFF"
                                error={
                                    isError.isClassificationError === true
                                  }
                                  helperText={
                                    isError.isClassificationError
                                      ? "Valid (classification) Required !"
                                      : ""
                                  }
                              />
                            )}
                          />
                        </div>
                      <div className="mt-5 pl-5 OrgNameContainer ">
                          <h3
                            className="font-normal text-o-form-label-color "
                            id="color"
                          >
                            System <span style={{ color: "red" }}> *</span>
                          </h3>
                          <Autocomplete
                            data-testid="partof"
                            value={AllTextFieldState.terminology}
                            onKeyDown={(e) => {
                              e.key === "Enter" && e.preventDefault();
                            }}
                            name="terminology"
                            autoHighlight={true}
                            onChange={(e, v) =>
                              handleDropDownChange("terminology", v)
                            }
                            getOptionLabel={(option) => (option ? option : "")}
                            id="loc-list-option"
                            options={systemList && systemList}
                            sx={{ width: 360 }}
                            renderInput={(params) => (
                              <TextField
                                variant="standard"
                                {...params}
                                label=""
                                onFocus={(e) => resetErrorHandler(e)}
                                autoComplete="OFF"
                                error={
                                    isError.isSystemError === true
                                  }
                                  helperText={
                                    isError.isSystemError
                                      ? "Valid (terminology) Required !"
                                      : ""
                                  }
                              />
                            )}
                          />
                        </div>
                      <div className=" pl-5 mt-5 OrgNameContainer">
                        <h3
                          className="font-normal text-o-form-label-color"
                          id="Org"
                        >
                          Code  <span style={{ color: "red" }}> *</span>
                        </h3>
                        <TextField
                            error={isError.isCodeError === true}
                            helperText={
                              isError.isCodeError
                                ? "Valid (Code) Required !"
                                : ""
                            }
                          onFocus={(e) => resetErrorHandler(e)}
                          autoComplete="OFF"
                          onChange={(e) => whenInputsChange(e)}
                          name="code"
                          value={AllTextFieldState.code}
                          style={{ width: "360px" }}
                          id="standard-basic"
                          label=""
                          variant="standard"
                        />
                      </div>
                      {/* new */}
                      {AllTextFieldState.is_update === true ? (
                        <>
                          
                          <div className="mt-5 pl-5 OrgNameContainer">
                            <h3 className="font-normal text-o-form-label-color">
                              Created By
                            </h3>
                            <p className="last-modified-value m-r-f">
                              <TextField
                                disabled
                                variant="standard"
                                autoComplete="OFF"
                                name="encounterType"
                                value={fullName}
                                style={{ width: "360px" }}
                                id="standard-basic"
                                label=""
                              />
                            </p>
                            <h3 className=" mt-5 font-normal text-o-form-label-color">
                              Last Modified Date & Time
                            </h3>
                            <p className="last-modified-value m-r-f">
                              <TextField
                                disabled
                                variant="standard"
                                autoComplete="OFF"
                                name="encounterType"
                                value={new Date(
                                  AllTextFieldState.lastModifiedTime
                                ).toLocaleString("en-US")}
                                style={{ width: "360px" }}
                                id="standard-basic"
                                label=""
                              />
                            </p>
                          </div>
                          <div className="mt-5 pl-5 OrgNameContainer ">
                            <h3 className="font-normal text-o-form-label-color">
                              Status
                            </h3>
                            <Autocomplete
                              autoHighlight={true}
                              value={
                                AllTextFieldState && AllTextFieldState.deleted == true ? "INACTIVE" : "ACTIVE"
                              }
                              onChange={(e, v) =>
                                handleDropDownChange("status", v)
                              }
                              getOptionLabel={(option) =>
                                option ? option : ""
                              }
                              id="city-list-option"
                              onKeyDown={(e) => {
                                e.key === "Enter" && e.preventDefault();
                              }}
                              options={statuslist ? statuslist : []}
                              sx={{ width: 360 }}
                              renderInput={(params) => (
                                <TextField
                                  variant="standard"
                                  {...params}
                                  label=""
                                />
                              )}
                            />
                          </div>
                        </>
                      ) : (
                        ""
                      )}

                      <div className="mt-5  OrgNameContainer ">
                        <div className="progressContainer mt-5 flex justify-center items-center">
                          {createResponse.isError ||
                          (updateResponse.isError && showerror === true) ? (
                            <Alert severity="error" color="warning">
                              {createResponse.error &&
                              createResponse.error.data &&
                              AllTextFieldState.is_update === false
                                ? createResponse.error.data.message
                                : updateResponse.error &&
                                  updateResponse.error.data &&
                                  updateResponse.error.data.message}
                            </Alert>
                          ) : createResponse.isLoading ||
                            updateResponse.isLoading ? (
                            <CircularProgress />
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="mt-5 flex justify-center   towButtonContainer h-40">
                          <Button
                            onClick={hideForm}
                            type="submit"
                            variant="contained"
                            disableElevation
                            style={{
                              marginRight: "10px",
                              borderColor: "#0F4391",
                              width: "99px",
                              height: "39px",
                              borderRadius: "9999px",
                              backgroundColor: "#E8FAFF",
                              color: "#0F4391",
                              border: "1px solid #0F4391",
                            }}
                          >
                            CANCEL
                          </Button>

                          <Button
                            type="submit"
                            variant="contained"
                            disableElevation
                            style={{
                              borderColor: "#0F4391",
                              width: "99px",
                              height: "39px",
                              borderRadius: "9999px",
                              backgroundColor: "#E8FAFF",
                              color: "#0F4391",
                              border: "1px solid #0F4391",
                            }}
                          >
                            SAVE
                          </Button>
                        </div>
                      </div>
                    </form>
                  </>
                </div>
              </div>
            </Grid>
          ) : (
            ""
          )}
        </Grid>
      </div>
      <AdminCookieConsentBanner />
    </>
  );
};

export default Procedure;
