import { useState } from "react";
import { useGetActiveDistrictValueMutation } from "../../../services/adminLogIn";

const ProgramactivityLogic = () => {
  const [showerror, setErrorMessage] = useState(false);
  const [
    getDistrictValFun,
    { isLoading1, isSuccess1, isError1, ...districtlist },
  ] = useGetActiveDistrictValueMutation();

  const [AllTextFieldState, setTextFieldState] = useState({
    is_update: false,
    programActivityName: "",
    programActivityId: "",
    organizationIds: [],
    locationIds: [],
    coordinators: [],
    description: "",
    isDeleted: false,
    state: '',
    city: '',
    district: '',
    postalCode: "",
    latitude: "",
    description: "",
    longitude: "",
    endDateTime: "",
    startDateTime: "",
    programId: "",
    programName: {},
  });

  const [open, setOpen] = useState(false);
  const [dropOrgState, setDropOrgState] = useState({});
  const [dropLocState, setDropLocState] = useState({});
  const [dropactivityState, setDropactivityState] = useState({});
  const [openLocationFilterModal, setOpenLocationFilterModal] = useState(false);

  const handleLocationFilterModalOpen = () => {setOpenLocationFilterModal(true)};
  const handleLocationFilterModalClose = () => { setOpenLocationFilterModal(false); }
  const getDistrictValFun1 = (value) => {
    getDistrictValFun(value);
  };
  const [dropRoleState, setDropRoleState] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [isSearchEvent, setIsSearchEvent] = useState(false);
  const [status, setStatus] = useState("");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleFilterModalOpen = () => {
    setOpenFilterModal(true);
  };
  const handleFilterModalClose = () => {
    setOpenFilterModal(false);
  };

  const [filterData, setFilterData] = useState({
    classificationId: "",
    sampleName: "",
    filterStatus: "",
  });

  const [dropdownState, setDropdowntate] = useState({
    code: "",
    amount: "",
    repeats: "",
    shortDescription: "",
    longDescription: "",
    isActive: true,
  });

  const [isError, setError] = useState({
    isNameError: false,
    isOrganizationError: false,
    isLocationError: false,
    isProgramError: false,
  });

  // dropdown handler
  const handleDropDownChange = (e, v) => {
    const value = v;
    if (e === "organizationIds") {
      setError((prev) => ({
        ...prev,
        isOrganizationError: (isError.isOrganizationError = false),
      }));
      setTextFieldState((prev) => ({
        ...prev,
        [e]: value,
      }));
      setDropOrgState(value);
    }
    if (e === "locationIds") {
      setError((prev) => ({
        ...prev,
        isLocationError: (isError.isLocationError = false),
      }));
      setTextFieldState((prev) => ({
        ...prev,
        [e]: value,
      }));
      setDropLocState(value);
    }
    if (e === "programName") {
      setError((prev) => ({
        ...prev,
        isProgramError: (isError.isProgramError = false),
      }));
      setTextFieldState((prev) => ({
        ...prev,
        [e]: value,
      }));
    }
    if (e === "state") {
      getDistrictValFun1(value);
      setTextFieldState((prev) => ({
        ...prev,
        [e]: value,
      }));

      setTextFieldState((prev) => ({
        ...prev,
        ["district"]: "",
      }));
    }
    if (e === "district") { }
    setTextFieldState((prev) => ({
      ...prev,
      [e]: value,
    }));


    if (e === "status") {
      handleOpen();
    }
  };

  const halndleClickonProceed = () => {
    setTextFieldState((prev) => ({
      ...prev,
      ["isActive"]:
        AllTextFieldState.isActive === "ACTIVE" ? "ACTIVE" : "INACTIVE",
    }));
    setStatus(AllTextFieldState.isActive === "ACTIVE" ? "ACTIVE" : "INACTIVE");
    handleClose();
  };

  const resetErrorHandler = (e) => {
    const name = e.target.code;
    switch (name) {
      case "activityname":
        setError((prev) => ({
          ...prev,
          isNameError: (isError.isNameError = false),
        }));
        break;

      case "organizationName":
        setError((prev) => ({
          ...prev,
          isOrganizationError: (isError.isOrganizationError = false),
        }));
        break;

      case "locationName":
        setError((prev) => ({
          ...prev,
          isLocationError: (isError.isLocationError = false),
        }));
        break;
      case "firstName":
        setError((prev) => ({
          ...prev,
          isProgramError: (isError.isProgramError = false),
        }));
        break;
    }
  };

  const whenInputsChange = (e) => {
    const { name, value } = e.target;
    if (name === "activityname") {
      setError((prev) => ({
        ...prev,
        isNameError: (isError.isNameError = false),
      }));
      setTextFieldState((prev) => ({
        ...prev,
      }));
    }

    if (name === "postalCode") {
      if (value.length <= 8) {
        setTextFieldState((prev) => ({
          ...prev,
          [name]: value.replace(/[^0-9]/gi, ""),
        }));
      }
    } else {
      setTextFieldState((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  // exporting all handler and state and etc
  const exportAll = {
    whenInputsChange,
    handleDropDownChange,
    //    dropsampleState,
    setError,
    AllTextFieldState,
    isError,
    resetErrorHandler,
    setTextFieldState,
    dropdownState,
    setDropdowntate,
    open,
    handleClose,
    handleOpen,
    setOpen,
    halndleClickonProceed,
    setErrorMessage,
    handleFilterModalOpen,
    handleFilterModalClose,
    openFilterModal,
    showerror,
    filterData,
    isSearchEvent,
    setIsSearchEvent,
    getDistrictValFun,
    districtlist,
    getDistrictValFun1,
    handleLocationFilterModalOpen,
    handleLocationFilterModalClose,
    openLocationFilterModal
  };

  return exportAll;
};

export default ProgramactivityLogic;
