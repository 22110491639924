import { useState, useEffect, useRef } from "react";
import AdminHome from "../AdminHome";
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import TagLogic from "./strenthUomLogic";
import TagList from "./strenthUomList";
import filter from "../../../images/filter.png"
import { toasterFunction } from "../../../globalFunctions/getLocalStorageData";

import { 
    useCreateupdateMedicineStrengthUomMutation,
    useDeleteMedicineStrenthUomMutation,useGetMedicineStrenthUomListMutation,
 } from "../../../services/adminLogIn";
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import "../Organization/Mainhome.css"
import { Grid } from "@mui/material";
import InputAdornment from '@mui/material/InputAdornment';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Breadcrums from "../../global/breadcrums/Breadcrums"
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector}  from "react-redux";
import AdminCookieConsentBanner from "../home/AdminCookieConsentBanner";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
const StrenthUom  = (props) => {
    const deleteModalDesc = "Are you sure you want to delete the Healthcare Practitioner? This activity will delete the healthcare Practitioner from the master list which can't be resolved? Do you wish to continue?."
    const cancelModalDesc = "The changes you made can't be undone, are you sure you want to cancel it?";
  const [getMedicineSTrenthUomApi] =useGetMedicineStrenthUomListMutation();
  const [createupdateApi] =useCreateupdateMedicineStrengthUomMutation();    
  const [deleteApi] = useDeleteMedicineStrenthUomMutation();
 const [access, setAccess] =useState(JSON.parse(sessionStorage.getItem("access")));

  const [expand, setexpand] = useState({
    heading: "",
    icon: "",
    isSubListExpanded: false
  })
  const [expand1, setexpand1] = useState({
    empty: true
  });

  const [isNewData, setIsNewData] = useState(true)
  const [currentPage, setCurrentPage] = useState(1);
  const [calculateData, setTotalPageCalculate] = useState({
    number: "",
    numberOfElements: "",
    totalElements: "",
    showcount: ""
  });

  const { whenInputsChange, AllTextFieldState, isError, dropdownState, setDropdowntate,
    handleDropDownChange, resetErrorHandler, setTextFieldState, setDropdownState,
    open,
    handleClose,
    setErrorMessage,
    showerror,
    setError,
    halndleClickonProceed,
  } = TagLogic();
  const [statuslist] = useState(["ACTIVE", "INACTIVE"]);
  const [medicineroute] = useState([]);
//   const [tagTypeList] = useState(["Regular Tag", "Date Tag"]);
  const [totalPage, setTotalPage] = useState(0);
  const [searchVal, setsearchVal] = useState("");
  const [fullName, setFullName] = useState("");
  const [usageCount, setUsageount] = useState(0);
  const [isFormUpdate, setIsFormUpdata] = useState(false);
  const [isFormVisible, setFormVisible] = useState({
    isOpen: false,
    isClose: true
  });
  const [id, setId] = useState("");
  const [MedicinerouteList, setMedicinerouteList] = useState([]);
  const [bodysiteSystem, setbodysiteSystem] = useState([]);
  const [paginationdata, setPaginationData] = useState({
    string: "",
    page: 0,
    size: 20
  });
  const [modalOpen, setModalOpen] = useState(false);
  const [currentRow, setCurrentRow] = useState();
  const [cancelModal, setCancelModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

 
  const showFormActions = () => {
    if(access?.tagsview==true){
    setErrorMessage(false)
    setId("")
    if (!isFormVisible.isOpen) {
      setFormVisible((prev) => ({ ...prev, isOpen: isFormVisible.isOpen = true, isFormVisible, isClose: isFormVisible.isClose = false }));
      if (AllTextFieldState.is_update === false) {
        setId("")
      }
    }
  }
  else{
    toast(" No Permission", {
      position: "top-center",
      autoClose: 2000,
hideProgressBar: true,
      type: "error",
      closeOnClick: true,
      theme: "light",
    });
  }
  }
  const SubmitHandler = async (e) => {
    e.preventDefault();
    if (AllTextFieldState.strengthUOMCode === "") {
      setError((prev) => ({
        ...prev,
        isdurationCodeError: isError.isdurationCodeError = true,
        isdurationDescriptionError: isError.isdurationDescriptionError = true,
     

      }))

    } else if (AllTextFieldState.strengthUOMDescription == '') {
      setError((prev) => ({
        ...prev,
        isdurationCodeError: isError.isdurationCodeError = false,
        isdurationDescriptionError: isError.isdurationDescriptionError =true,
     

  
      }))

    } 
    
    else {

      setError((prev) => ({
        ...prev,
        isdurationCodeError: isError.isdurationCodeError = false,
        isdurationDescriptionError: isError.isdurationDescriptionError = false,
     

      
      }))
      var sendOrgDetails = {}
      if(AllTextFieldState.is_update){
         sendOrgDetails = {
            strengthUOMId: AllTextFieldState.strengthUOMId,
            strengthUOMCode: AllTextFieldState.strengthUOMCode,
            strengthUOMDescription: AllTextFieldState.strengthUOMDescription,
            active:AllTextFieldState.status
          }
      }
      else{
        sendOrgDetails = {
            strengthUOMCode: AllTextFieldState.strengthUOMCode,
            strengthUOMDescription: AllTextFieldState.strengthUOMDescription,
            active:true
          }
      }
     
      // if (AllTextFieldState.is_update === false) {
        const res = await createupdateApi(sendOrgDetails)
        if(res?.data?.createdBy){
          if(AllTextFieldState.status===false){
            const res =  await handleProceed(sendOrgDetails)
          }
          toasterFunction(AllTextFieldState.is_update === false ?" Medicine Strength UOM Created successfully":"Medicine Strength UOM Updated successfully","success")
          hideForm()
        
        }
        else{
          toasterFunction(res?.error?.data?.message,"error")

        
        }
       
      // }
      // else {
      //     sendOrgDetails.sampleTypeId = AllTextFieldState.sampleTypeId
      //     updatesampleFunc(sendOrgDetails)
      // }
      setErrorMessage(true)
    }

  }
  const hideForm = () => {
    setErrorMessage(false)

    setTextFieldState({
      is_update: false,
      strengthUOMCode: '',
      strengthUOMDescription: '',
     
      
    })
    if (isFormVisible.isOpen) {
      setFormVisible((prev) => ({ ...prev, isOpen: isFormVisible.isOpen = false, isFormVisible, isClose: isFormVisible.isClose = true }));
    }
    setError((prev) => ({
      ...prev,
      isdurationCodeError: isError.isdurationCodeError = false,
      isdurationDescriptionError: isError.isdurationDescriptionError = false,
   
     
    }))
    setsearchVal("")
    getMedicinerouteList()
  }

  const handleColorInput = (value) => {
    setTextFieldState((prev) => ({
      ...prev,
      ['color']: AllTextFieldState.color == value ? "" : value

    }))
    setError((prev) => ({
      ...prev,
      isColor: isError.isColor = false
    }))
  }
  

  
  const getMedicinerouteList = async () => {
    
    const medicineroute = await getMedicineSTrenthUomApi ({ string: "",page : 0,size:20 })
    if(medicineroute?.data?.content.length>0){
        setMedicinerouteList(medicineroute?.data?.content)
        setTotalPage(medicineroute?.data?.totalPages)
        setTotalPageCalculate({ numberOfElements: medicineroute?.data?.numberOfElements, number: medicineroute?.data?.number, totalElements: medicineroute?.data?.totalElements })
    }
}

const getMedicineDosagePage = async (page) => {
    const medicineroute = await getMedicineSTrenthUomApi({ string: "", page : page-1 , size: 20 });
    setCurrentPage(page)
    if (medicineroute?.data?.content) {
        setMedicinerouteList(medicineroute?.data?.content)
      setTotalPage(medicineroute?.data?.totalPages)
      setTotalPageCalculate({ numberOfElements: medicineroute?.data?.numberOfElements, number: medicineroute?.data?.number, totalElements: medicineroute?.data?.totalElements })
   
    }
  }

  const onSearchevent = async (value) => {
    setsearchVal(value);
    if(value.length!=0){
      const res = await getMedicineSTrenthUomApi({ string: value ,page : 0,size:20  });
      if (res?.data?.content) {
        setCurrentPage(1)
        setMedicinerouteList(res?.data?.content)
      }
      else{
        setMedicinerouteList([])
      }
    }
    else{
        getMedicinerouteList()
    }
   
  }
  const handleDelete =(item)=> {
    setModalOpen(true);
    setCurrentRow(item)
  }
  const handleModalClose = () => {
    setModalOpen(false);
  }
  const handleProceed = async (currentRow) => {
    
    const res = await deleteApi(currentRow.strengthUOMId);
//   if(res?.error?.data?.message){
//     toast(res?.error?.data?.message, {
//       position: "top-center",
//       autoClose: 2000,
// hideProgressBar: true,
//       type: "error",
//       closeOnClick: true,
//       theme: "light",
//     });
  
   
//   }
//   else{
//     toast("Deleted Successfully", {
//       position: "top-center",
//       autoClose: 2000,
// hideProgressBar: true,
//       type: "success",
//       closeOnClick: true,
//       theme: "light",
//     });
    
  // }
  // setModalOpen(false);
  // hideForm();

}

  useEffect(() => {
    setIsNewData(true);
    setCurrentPage(1);
    getMedicinerouteList()    
  }, []);



  return (
    <>
      <div className={props.expand1.empty === true ? "main1" : "main"}>
      <div className="breadCumContainer flex justify-start items-center h-9 w-full bg-o-color-one organ1" >
            <ol className="ml-8 flex " style={{ listStyleType: 'none' }}>
            <div className="flex mt-1" >
                <li className="w-28 h-thirtyPix rounded-mysix flex justify-center items-center font-medium bg-white m-r-f orgbox" ><span>Categories</span></li> 
              </div><i className="right"></i>
              <div className="flex mt-1" >
                <li className="w-28 h-thirtyPix rounded-mysix flex justify-center items-center font-medium bg-white m-r-f orgbox rows" ><span> Medicine Request </span></li>
              </div><i className="right"></i>
              <div className="flex mt-1" >
                <li className="w-32 pl-5 h-thirtyPix rounded-mysix flex justify-center items-center font-medium bg-white m-r-f orgbox3 rows breadback" ><span>Medicine Strength UOM</span></li>
              </div>
            </ol>
          </div>
  <ToastContainer />
        <Grid container mt={-2}>
          <Grid item xs={6}>
            <div
              className={
                isFormVisible.isOpen
                  ? "leftcontainer rounded-lg"
                  : "leftcontainer1 rounded-lg"
              }
            >
              <Grid className=" p-7 ">
                <TextField
                  label=""
                  onChange={(e) => onSearchevent(e.target.value)}
                  onKeyDown={(e) => {
                    e.key === "Enter" && e.preventDefault();
                  }}
                  sx={{ m: 1, width: "25ch" }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchOutlinedIcon />
                      </InputAdornment>
                    ),
                  }}
                  value={searchVal}
                />
              </Grid>
              <Grid className="editContainer rounded-radiusFourPix ">
                <Grid
                   disabled ={access?.useradd==false}
                  onClick={showFormActions}
                  className={isFormVisible.isOpen ? "addIconchange" : "addIcon"}
                >
                  <span className=" p-2 m-r-f ">Add</span>
                  <AddCircleOutlineIcon
                    style={{
                      fontSize: "14px",
                      color: "#545454",
                      marginBottom: "3px",
                      color: `${isFormVisible.isOpen ? "#ffffff" : "#545454"}`,
                    }}
                  />
                </Grid>
                {/* <Grid className="addIcon">
                  <span className="p-2 m-r-f">Filter</span>
                  <img
                    src={filter}
                    alt="filter.png"
                    style={{
                      position: "absolute",
                      left: "90%",
                      right: "20px",
                      top: "15px",
                      bottom: "95px",
                      width: "11px",
                      paddingTop: "3px",
                    }}
                  ></img>
                </Grid> */}

              </Grid>
              <>
                <TagList
                 MedicinerouteList={MedicinerouteList}
                setTotalPage={setTotalPage}
                totalPage={totalPage}
                setId={setId}
                id={id}
                showFormActions={showFormActions}
                setTextFieldState={setTextFieldState}
                tagsview = {access?.tagsview}
                tagsupdate = {access?.tagsupdate}
                bodysiteSystem={bodysiteSystem}
                getMedicineDosagePage={getMedicineDosagePage}
                calculateData={calculateData}
                currentPage={currentPage}
                handleDelete={handleDelete}

                />
                 
                 <Modal
                          open={modalOpen}
                          onClose={handleModalClose}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                        >
                          <Box sx={style} style = {{width: "400px"}}>
                            <Typography
                              id="modal-modal-title"
                              variant="h6"
                              component="h2"
                            >
                              <Alert severity="info">
                              {"Are you sure you want to delete the Medicine Duration? This activity will delete the Medicine Duration from the master list which can't be resolved? Do you wish to continue?"}
                              </Alert>
                            </Typography>
                            <Typography
                              id="modal-modal-description"
                              sx={{ mt: 2 }}
                            >
                              <div className="mt-10 flex justify-center items-center  towButtonContainer">
                                <Button
                                  type="submit"
                                  onClick={handleModalClose}
                                  variant="contained"
                                  disableElevation
                                  style={{
                                    marginRight: "10px",
                                    borderColor: "#0F4391",
                                    width: "99px",
                                    height: "39px",
                                    borderRadius: "9999px",
                                    backgroundColor: "#E8FAFF",
                                    color: "#0F4391",
                                    border: "1px solid #0F4391",
                                    marginBottom: "100px",
                                  }}
                                >
                                  {"CANCEL"}
                                </Button>

                                <Button
                                  type="submit"
                                  onClick={handleProceed}
                                  variant="contained"
                                  disableElevation
                                  style={{
                                    borderColor: "#0F4391",
                                    width: "99px",
                                    height: "39px",
                                    borderRadius: "9999px",
                                    backgroundColor: "#E8FAFF",
                                    color: "#0F4391",
                                    border: "1px solid #0F4391",
                                    marginBottom: "100px",
                                  }}
                                >
                                  {"PROCEED"}
                                </Button>
                              </div>
                            </Typography>
                          </Box>
                          </Modal>
              
              </>
            </div>
          </Grid>

          {isFormVisible.isOpen ? (
            <Grid item xs={6}>
              <div className="rightcontainer rounded-lg">
                <div className="overflow-y-auto o-form-container">
                  <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={style}>
                      <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                      >
                        <Alert severity="info">
                          {" "}
                          {AllTextFieldState.status === false
                            ? " Are you sure you want to reactivate the Medicine Duration UOM? "
                            : "Are you sure you want to Deactivate the Medicine Duration UOM?"}
                        </Alert>
                      </Typography>
                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {AllTextFieldState.status === false
                          ? "This activity will enable the Medicine Duration UOM and the user will be able to access it across all products.   Inactive- Are you sure you want to Deactivate the Medicine Duration UOM?"
                          : "This activity will disable the Medicine Duration UOM and the user will not able to access it. You can reactivate the Medicine Duration UOM at anytime?"}

                        <div className="mt-10 flex justify-center items-center  towButtonContainer">
                          <Button
                            type="submit"
                            onClick={handleClose}
                            variant="contained"
                            disableElevation
                            style={{
                              marginRight: "10px",
                              borderColor: "#0F4391",
                              width: "99px",
                              height: "39px",
                              borderRadius: "9999px",
                              backgroundColor: "#E8FAFF",
                              color: "#0F4391",
                              border: "1px solid #0F4391",
                              marginBottom: "100px",
                            }}
                          >
                            CANCEL
                          </Button>

                          <Button
                            type="submit"
                            onClick={halndleClickonProceed}
                            variant="contained"
                            disableElevation
                            style={{
                              borderColor: "#0F4391",
                              width: "99px",
                              height: "39px",
                              borderRadius: "9999px",
                              backgroundColor: "#E8FAFF",
                              color: "#0F4391",
                              border: "1px solid #0F4391",
                              marginBottom: "100px",
                            }}
                          >
                            PROCEED
                          </Button>
                        </div>
                      </Typography>
                    </Box>
                  </Modal>
                  <ToastContainer />
                  <>
                    <div className="font-normal text-o-form-label-color mainhead flex justify-between items-center pl-5 pr-1 h-10">
                      <h1>Medicine Strength UOM Overview</h1>
                    </div>

                    <form autoComplete="off" onSubmit={SubmitHandler}>
                      <div className=" pl-5 OrgNameContainer">
                        <h3
                          className="font-normal text-o-form-label-color"
                          id="Org"
                        >
                          Strength UOM Code
                          <span style={{ color: "red" }}> *</span>
                        </h3>
                        <TextField
                          onFocus={(e) => resetErrorHandler(e)}
                          autoComplete="OFF"
                          error={isError.isdurationCodeError === true}
                          helperText={
                            isError.isdurationCodeError
                              ? "Valid (strengthUOMCode) Required !"
                              : ""
                          }
                          onChange={(e) => whenInputsChange(e)}
                          name="strengthUOMCode"
                          value={AllTextFieldState.strengthUOMCode}
                          style={{ width: "360px" }}
                          id="standard-basic"
                          label=""
                          variant="standard"
                        />
                      </div>
                      <div className="mt-5 pl-5 OrgNameContainer">
                        <h3
                          className="font-normal text-o-form-label-color"
                          id="Org"
                        >
                          Description  <span style={{ color: "red" }}> *</span>
                        </h3>
                        <TextField
                          onFocus={(e) => resetErrorHandler(e)}
                          autoComplete="OFF"
                          rows={6}
                          multiline
                          error={isError.isdurationDescriptionError === true}
                              helperText={
                                isError.isdurationDescriptionError
                                  ? "Valid (strengthUOMDescription) Required !"
                                  : ""
                              }
                          onChange={(e) => whenInputsChange(e)}
                          name="strengthUOMDescription"
                          value={AllTextFieldState.strengthUOMDescription}
                          style={{ width: "360px" }}
                          id="standard-basic"
                          label=""
                          variant="outlined"
                        />
                      </div>
                     
                  
                      {AllTextFieldState.is_update === true ? (
                        <>
                          <div className="mt-5 pl-5 OrgNameContainer ">
                          <h3 className="font-normal text-o-form-label-color">
                            Status
                          </h3>
                          <Autocomplete
                            autoHighlight={true}
                            value={
                              AllTextFieldState &&
                              AllTextFieldState.status==true ? "Active" : "Inactive"
                            }
                            onChange={(e, v) =>
                              handleDropDownChange("status", v)
                            }
                            getOptionLabel={(option) => (option ? option : "")}
                            id="city-list-option"
                            onKeyDown={(e) => {
                              e.key === "Enter" && e.preventDefault();
                            }}
                            options={statuslist ? statuslist : []}
                            sx={{ width: 360 }}
                            renderInput={(params) => (
                              <TextField
                                variant="standard"
                                {...params}
                                label=""
                              />
                            )}
                          />
                        </div>
                      
                        </>
                      ) : (
                        ""
                      )}


                      <div className="mt-5  OrgNameContainer ">
                        {/* <div className="progressContainer mt-5 flex justify-center items-center">
                          {createResponse.isError ||
                            (updateResponse.isError && showerror === true) ? (
                            <Alert severity="error" color="warning">
                              {createResponse.error &&
                                createResponse.error.data &&
                                AllTextFieldState.is_update === false
                                ? createResponse.error.data.message
                                : updateResponse.error &&
                                updateResponse.error.data &&
                                updateResponse.error.data.message}
                            </Alert>
                          ) : createResponse.isLoading ||
                            updateResponse.isLoading ? (
                            <CircularProgress />
                          ) : (
                            ""
                          )}
                        </div> */}
                        <div className="mt-5 flex justify-center   towButtonContainer h-40">
                          <Button
                            onClick={hideForm}
                            type="submit"
                            variant="contained"
                            disableElevation
                            style={{
                              marginRight: "10px",
                              borderColor: "#0F4391",
                              width: "99px",
                              height: "39px",
                              borderRadius: "9999px",
                              backgroundColor: "#E8FAFF",
                              color: "#0F4391",
                              border: "1px solid #0F4391",
                            }}
                          >
                            CANCEL
                          </Button>

                          <Button
                            type="submit"
                            variant="contained"
                            disableElevation
                            onClick={SubmitHandler}
                            style={{
                              borderColor: "#0F4391",
                              width: "99px",
                              height: "39px",
                              borderRadius: "9999px",
                              backgroundColor: "#E8FAFF",
                              color: "#0F4391",
                              border: "1px solid #0F4391",
                            }}
                          >
                            SAVE
                          </Button>
                        </div>
                      </div>
                    </form>
                  </>
                </div>
              </div>
            </Grid>
          ) : (
            ""
          )}
        </Grid>
      </div>
      <AdminCookieConsentBanner />
    </>
  );

}

export default StrenthUom ;