import * as React from 'react';
import {useState , useEffect , useRef} from "react";
import { Grid } from '@mui/material';





const Breadcrums= (props)=>{
  const { hasFocus, value } = props;
    
 
          
return (
    <Grid className="" >
    
    <div className="breadCumContainer flex justify-start items-center h-9 w-full bg-o-color-one organ1" >
            <ol className="ml-8 flex " style={{ listStyleType: 'none' }}>
            <div className="flex mt-1" >
                <li className="w-28 h-thirtyPix rounded-mysix flex justify-center items-center font-medium bg-white m-r-f orgbox" ><span>{props.firsttab}</span></li> 
              </div><i className="right"></i>
              <div className="flex mt-1" >
                <li className="w-28 h-thirtyPix rounded-mysix flex justify-center items-center font-medium bg-white m-r-f orgbox rows" ><span>{props.secondtab}</span></li>
              </div><i className="right"></i>
              <div className="flex mt-1" >
                <li className="w-28 h-thirtyPix rounded-mysix flex justify-center items-center font-medium bg-white m-r-f orgbox rows breadback" ><span>{props.thirdtab}</span></li>
              </div>
            </ol>
          </div>
    

   
  

</Grid>
    
  );

}

export default Breadcrums;
