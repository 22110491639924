import React, { useEffect, useState } from "react";
import AdminHome from "../AdminHome";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import DeleteIcon from "@mui/icons-material/Delete";
import filter from "../../../images/filter.png";
import "../Organization/Mainhome.css";
import { Grid, Button } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import InputAdornment from "@mui/material/InputAdornment";
import useHealthcarePractitionerLogic from "./healthcarePractitionerlogic";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import Modal from "@mui/material/Modal";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "./healthcarePractitioner.css";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { toasterFunction } from "../../../globalFunctions/getLocalStorageData";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


import { LoggerFunction } from "../../../logger/logger";
import {
  useGetHealthCareListdataMutation,
  useGetActiveOrgValueMutation,
  useGetActiveLocationValueMutation,
  useGetRoleListMutation,
  useGetRoleByUserIdMutation,
  useUpdatePractitionerHealthcareMutation,
  useGetSpacializationListMutation,
  useGetPhotoByIdMutation,
  useGetSignatureByIdMutation,
  useCreateHealthcarePractitionerMutation,
  usePractitionerHealthcareSaveMutation,
  useHealthcarePractitionerDeleteMutation,
  useMobileValidationMutation,
} from "../../../services/adminLogIn";
import HealthcareList from "./healthcarePractitionerlist";
import { red } from "@mui/material/colors";
import Breadcrums from "../../global/breadcrums/Breadcrums";
import { useLocation } from "react-router-dom";
import AdminCookieConsentBanner from "../home/AdminCookieConsentBanner";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  height: 360,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const ServiceRequest = (props) => {
  const deleteModalDesc =
    "Are you sure you want to delete the Healthcare Practitioner? This activity will delete the healthcare Practitioner from the master list which can't be resolved? Do you wish to continue?.";
  const cancelModalDesc =
    "The changes you made can't be undone, are you sure you want to cancel it?";
  const [expand, setexpand] = useState({
    heading: "",
    icon: "",
    isSubListExpanded: false,
  });
  const [expand1, setexpand1] = useState({
    empty: true,
  });
  const [isFormVisible, setFormVisible] = useState({
    isOpen: false,
    isClose: true,
  });
  const [salutationlist, setSalutationList] = useState([
    "Miss.",
    "Mrs.",
    "Mr.",
    "Dr.",
  ]);
  const genderOptions = ["Male", "Female", "ransgender", "Others", "Unknown"];
  const [healthRecordsLoading, setHealthRecordsLoading] = useState(false);
  const [getUserRoles] = useGetRoleListMutation();
  const [getPhotoById] = useGetPhotoByIdMutation();
  const [mobileValidation] = useMobileValidationMutation();
  const [getSignatureById] = useGetSignatureByIdMutation();
  const [getSpecailizationList] = useGetSpacializationListMutation();
  const [getRoleByUserId] = useGetRoleByUserIdMutation();
  const [updatePractioner] = useUpdatePractitionerHealthcareMutation();
  const [getOrganizations] = useGetActiveOrgValueMutation();
  const [getActiveLocations] = useGetActiveLocationValueMutation();
  const [getHealthCareListdata] = useGetHealthCareListdataMutation();
  const [createHealthcarePractitioner] =
    useCreateHealthcarePractitionerMutation();
  const [savePractitioner] = usePractitionerHealthcareSaveMutation();
  const [healthcarePractitionerDelete] =
    useHealthcarePractitionerDeleteMutation();
  const [healthCareListData, setHealthCareListData] = useState([]);
  const [userRole, setUserRole] = useState([]);
  const [errorDisplay, setErrorDisplay] = useState("");
  const [showerror, setError] = useState(false);
  const [totalPage, setTotalPage] = useState(1);
  const [access, setAccess] = useState(
    JSON.parse(sessionStorage.getItem("access"))
  );

  const [calculateData, setTotalPageCalculate] = useState({
    number: "",
    numberOfElements: "",
    totalElements: "",
    showcount: "",
  });
  const [paginationdata, setPaginationData] = useState({
    string: "",
    page: 0,
  });
  const [searchValue, setSearchValue] = useState();
  const [organizations, setOrgs] = useState([]);
  const [locations, setLocs] = useState([]);
  const [specializationList, setSpecialization] = useState([]);
  const [userRoles, setUserRoles] = useState([]);
  const [Photo, setPhoto] = useState("");
  const [signature, setSignature] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [currentRow, setCurrentRow] = useState();
  const [cancelModal, setCancelModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const {
    healthCarePractitionerState,
    healthPractitionerErrors,
    handleInputChange,
    handleDropDownChange,
    setHealthCarePractitionerState,
    resetErrorHandler,
    setHealthPractitionerErrors,
    resetHealthPractitionerData,
    setMobileValidation,
    resetAllErrors,
  } = useHealthcarePractitionerLogic();

  const navigate = useNavigate();

  const location = useLocation();

  const showFormActions = () => {
    if (access?.healthcarepractitionerupdate == true) {
      if (!isFormVisible.isOpen) {
        setFormVisible((prev) => ({
          ...prev,
          isOpen: (isFormVisible.isOpen = true),
          isFormVisible,
          isClose: (isFormVisible.isClose = false),
        }));
      }
    } else {
      toast(" No Permission", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        type: "error",
        closeOnClick: true,
        theme: "light",
      });
    }
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const onChange = async (e, field) => {
    const file = e.target.files[0];
    const base64 = await convertToBase64(file);
    if (field === "photo") {
      setPhoto(base64.split(",")[1]);
      setHealthPractitionerErrors((prev) => ({ ...prev, isPhotoError: false }));
    }
    if (field === "signature") {
      setSignature(base64.split(",")[1]);
      setHealthPractitionerErrors((prev) => ({ ...prev, isSignError: false }));
    }
    setHealthCarePractitionerState((prev) => ({
      ...prev,
      [field]: base64.split(",")[1],
    }));
  };

  const bodySubmitHandler = async (e) => {
    //setHealthRecordsLoading(true);
    e.preventDefault();
    if (healthCarePractitionerState.firstName === "") {
      setHealthPractitionerErrors((prev) => ({
        ...prev,
        isFirstNameError: true,
        isLastNameError: true,
        isQualificationError: true,
        isRegNumberError: true,
        isPhotoError: true,
        isSignError: true,
        isSpecializationError: true,
        isDepartmentError: true,
        isMobileNumberError: true,
      }));
    } else if (healthCarePractitionerState.lastName === "") {
      setHealthPractitionerErrors((prev) => ({
        ...prev,
        isLastNameError: true,
        isQualificationError: true,
        isRegNumberError: true,
        isFirstNameError: false,
        isPhotoError: true,
        isSignError: true,
        isSpecializationError: true,
        isDepartmentError: true,
        isMobileNumberError: true,
      }));
    } else if (healthCarePractitionerState.qualification === "") {
      setHealthPractitionerErrors((prev) => ({
        ...prev,
        isQualificationError: true,
        isRegNumberError: true,
        isLastNameError: false,
        isFirstNameError: false,
        isPhotoError: true,
        isSignError: true,
        isSpecializationError: true,
        isDepartmentError: true,
        isMobileNumberError: true,
      }));
    } else if (healthCarePractitionerState.registrationNumber === "") {
      setHealthPractitionerErrors((prev) => ({
        ...prev,
        isRegNumberError: true,
        isQualificationError: false,
        isLastNameError: false,
        isFirstNameError: false,
        isPhotoError: true,
        isSignError: true,
        isSpecializationError: true,
        isDepartmentError: true,
        isMobileNumberError: true,
      }));
    } else if (
      healthCarePractitionerState.photo === "" ||
      healthCarePractitionerState.photo === null
    ) {
      setHealthPractitionerErrors((prev) => ({
        ...prev,
        isRegNumberError: false,
        isQualificationError: false,
        isLastNameError: false,
        isFirstNameError: false,
        isPhotoError: true,
        isSignError: true,
        isSpecializationError: true,
        isDepartmentError: true,
        isMobileNumberError: true,
      }));
    } else if (
      healthCarePractitionerState.signature === "" ||
      healthCarePractitionerState.signature === null
    ) {
      setHealthPractitionerErrors((prev) => ({
        ...prev,
        isRegNumberError: false,
        isQualificationError: false,
        isLastNameError: false,
        isFirstNameError: false,
        isPhotoError: false,
        isSignError: true,
        isSpecializationError: true,
        isDepartmentError: true,
        isMobileNumberError: true,
      }));
    } else if (healthCarePractitionerState.specializations === "") {
      setHealthPractitionerErrors((prev) => ({
        ...prev,
        isRegNumberError: false,
        isQualificationError: false,
        isLastNameError: false,
        isFirstNameError: false,
        isPhotoError: false,
        isSignError: false,
        isSpecializationError: true,
        isDepartmentError: true,
        isMobileNumberError: true,
      }));
    } else if (healthCarePractitionerState.department === "") {
      setHealthPractitionerErrors((prev) => ({
        ...prev,
        isRegNumberError: false,
        isQualificationError: false,
        isLastNameError: false,
        isFirstNameError: false,
        isPhotoError: false,
        isSignError: false,
        isSpecializationError: false,
        isDepartmentError: true,
        isMobileNumberError: true,
      }));
    } else if (
      healthCarePractitionerState.mobileNumber === "" ||
      healthCarePractitionerState.mobileNumber === null
    ) {
      setHealthPractitionerErrors((prev) => ({
        ...prev,
        isRegNumberError: false,
        isQualificationError: false,
        isLastNameError: false,
        isFirstNameError: false,
        isPhotoError: false,
        isSignError: false,
        isSpecializationError: false,
        isDepartmentError: false,
        isMobileNumberError: true,
      }));
    } else {
      setHealthPractitionerErrors((prev) => ({
        ...prev,
        isRegNumberError: false,
        isQualificationError: false,
        isLastNameError: false,
        isFirstNameError: false,
        isPhotoError: false,
        isSignError: false,
        isSpecializationError: false,
        isDepartmentError: false,
        isMobileNumberError: false,
      }));

      healthCarePractitionerState.organizations =
        healthCarePractitionerState.organizations.map(({ id, name }) => {
          return { id, name };
        });
      healthCarePractitionerState.locations =
        healthCarePractitionerState.locations.map(({ id, name }) => {
          return { id, name };
        });
      healthCarePractitionerState.specializations =
        healthCarePractitionerState.specializations.map(
          ({ specialization, subSpecialization }) => {
            return { specialization, subSpecialization };
          }
        );
        const uniqueSpecializations = Array.from(
          new Set(
            healthCarePractitionerState.specializations.map(
              (item) => item.specialization
            )
          )
        ).map((specialization) =>
          healthCarePractitionerState.specializations.find(
            (item) => item.specialization === specialization
          )
        );
      // healthCarePractitionerState.mobileNumber  =  healthCarePractitionerState.mobileNumber === ""? null :healthCarePractitionerState.mobileNumber;
      if (healthCarePractitionerState.is_update === false) {
        setHealthRecordsLoading(true);
        let res = await createHealthcarePractitioner(
          healthCarePractitionerState
        );
        setHealthRecordsLoading(false);
        if (res?.data) {
          toast("HealthCare Practitioner created successfully", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            type: "success",
            closeOnClick: true,
            theme: "light",
          });
          hideForm();
        } else {
          toast(res?.error?.data?.message, {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            type: "error",
            closeOnClick: true,
            theme: "light",
          });
        }
        
      } 
      else {
        setHealthRecordsLoading(true);
        let res = await updatePractioner(healthCarePractitionerState);
        setHealthRecordsLoading(false);
        if (res?.data?.id) {
          toast("HealthCare Practitioner updated successfully", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            type: "success",
            closeOnClick: true,
            theme: "light",
          });
          hideForm();
        } else {
          toast(res?.error?.data?.message, {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            type: "error",
            closeOnClick: true,
            theme: "light",
          });
        }
      }
      
    }
    
    let path = `/dashboard/healthcarePractitioner`;
    navigate(path);
  };

  const uniqueSpecializations = Array.from(new Set(healthCarePractitionerState.specializations.map(item => item.specialization)))
  .map(specialization => healthCarePractitionerState.specializations.find(item => item.specialization === specialization));





  const mobileNumberData = async () => {
    const res = await mobileValidation();
    res?.data.forEach((element) => {
      if (process.env.REACT_APP_ENV == element.key) {
        setMobileValidation(element);
      }
    });
  };

  const hideForm = () => {
    if (healthCarePractitionerState.isPractitioner) {
      navigate("/dashboard/user");
    } else if (isFormVisible.isOpen) {
      setSearchValue("");
      setFormVisible((prev) => ({
        ...prev,
        isOpen: (isFormVisible.isOpen = false),
        isFormVisible,
        isClose: (isFormVisible.isClose = true),
      }));
      resetHealthPractitionerData();
      resetAllErrors();
      getListData(paginationdata);
    }
  };

  const handlePaginationClick = (page) => {
    getListData({ string: "", page: page - 1 });
  };

  const showUpdateData = async (row) => {
    showFormActions();
    setHealthRecordsLoading(true);
    const roleList = await getRoleByUserId(row.userId);
    setUserRole(roleList?.data?.groups);
    const photoData = await getPhotoById(row.id);
    if (photoData?.data?.photo) setPhoto(photoData?.data?.photo);
    const signatureData = await getSignatureById(row.id);
    if (signatureData?.data?.photo) setSignature(signatureData?.data?.photo);
    setHealthCarePractitionerState((prev) => ({
      ...row,
      ["is_update"]: true,
      ["roles"]: roleList?.data?.groups,
      ["photo"]: photoData?.data?.photo,
      ["signature"]: signatureData?.data?.photo,
    }));
    setHealthRecordsLoading(false);
  };

  const getListData = async (data) => {
    const res = await getHealthCareListdata(data);
    setHealthCareListData(res?.data?.content);
    setTotalPage(res?.data?.totalPages);
    setTotalPageCalculate({
      numberOfElements: res?.data?.numberOfElements,
      number: res?.data?.number,
      totalElements: res?.data?.totalElements,
    });
  };

  const getMasterData = async () => {
    const roles = await getUserRoles();
    if (roles?.data) {
      setUserRoles(roles.data);
    }

    const organizations = await getOrganizations();
    if (organizations?.data?.content) {
      setOrgs(organizations.data.content);
    }

    const locations = await getActiveLocations();
    if (locations?.data?.content) {
      setLocs(locations.data.content);
    }

    const specializationList = await getSpecailizationList("");
    if (specializationList?.data?.content) {
      setSpecialization(specializationList.data.content);
    }
  };

  const handleDeleteRow = (item) => {
    setCurrentRow(item);
    setModalOpen(true);
    setDeleteModal(true);
  };

  const handleProceed = async () => {
    if (deleteModal) {
      const res = await healthcarePractitionerDelete(currentRow.id);
      if (res) {
        setModalOpen(false);
        getListData(paginationdata);
      }
    } else {
      setModalOpen(false);
      hideForm();
    }
  };

  const saveHealthCarePractitioner = async (stateObj) => {
    setHealthRecordsLoading(true);
    if (stateObj.roles.find((item) => item === "practitioner")) {
      const res = await savePractitioner(stateObj.userId);
      if (res) {
        setHealthCarePractitionerState((prev) => ({
          ...prev,
          ...res?.data?.content[0],
          is_update: true,
        }));
        setHealthRecordsLoading(false);
        setHealthRecordsLoading(true);
        const photoData = await getPhotoById(res?.data?.content[0]?.id);
        if (photoData?.data?.photo) setPhoto(photoData?.data?.photo);
        const signatureData = await getSignatureById(res?.data?.content[0]?.id);
        if (signatureData?.data?.photo)
          setSignature(signatureData?.data?.photo);
        setHealthCarePractitionerState((prev) => ({
          ...prev,
          ["is_update"]: true,
          ["photo"]: photoData?.data?.photo,
          ["signature"]: signatureData?.data?.photo,
        }));
      }
    } else {
      setHealthCarePractitionerState((prev) => ({
        ...prev,
        ["is_update"]: false,
      }));
    }
    setHealthRecordsLoading(false);
  };

  const handleCancelClick = () => {
    setModalOpen(true);
    setCancelModal(true);
    setDeleteModal(false);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setCancelModal(false);
    setDeleteModal(false);
  };

  useEffect(() => {
    setHealthRecordsLoading(true);
    if (location.state) {
      saveHealthCarePractitioner(location.state);
      showFormActions();
      setHealthCarePractitionerState({
        title: "",
        is_update: false,
        userId: location.state.userId,
        salutation: location.state.salutation,
        firstName: location.state.firstName,
        middleName: location.state.middleName,
        lastName: location.state.lastName,
        gender: location.state.gender,
        regNumber: "",
        designation: "",
        qualification: "",
        specializations: [],
        department: "",
        mobileNumber: location.state.mobileNumber,
        alternateNumber: "",
        emailId: location.state.email,
        dob: location.state.dob,
        roles: location.state.roles,
        organizations: location.state.organizations,
        locations: location.state.locations,
        bio: "",
        isPractitioner: location.state.isPractitioner,
      });
    }
    setHealthRecordsLoading(false);
  }, [location]);

  useEffect(() => {
    getListData(paginationdata);
    getMasterData();
    mobileNumberData();
  }, []);
  return (
    <div className="create-package">
      <div className={props.expand1.empty === true ? "main1" : "main"}>
        <div className="breadCumContainer flex justify-start items-center h-9 w-full bg-o-color-one organ1">
          <ol className="ml-8 flex " style={{ listStyleType: "none" }}>
            <div className="flex mt-1">
              <li className="w-28 h-thirtyPix rounded-mysix flex justify-center items-center font-medium bg-white m-r-f orgbox">
                <span>Categories</span>
              </li>
            </div>
            <i className="right"></i>
            <div className="flex mt-1">
              <li className="w-28 h-thirtyPix rounded-mysix flex justify-center items-center font-medium bg-white m-r-f orgbox rows">
                <span>Administration</span>
              </li>
            </div>
            <i className="right"></i>
            <div className="flex mt-1">
              <li className="w-28 h-thirtyPix rounded-mysix flex justify-center items-center font-medium bg-white m-r-f orgbox3 rows breadback">
                <span>Healthcare Practitioner</span>
              </li>
            </div>
          </ol>
        </div>
        <ToastContainer />
        <Grid container mt={-2}>
          <Grid item xs={6}>
            <div
              className={
                isFormVisible.isOpen
                  ? "leftcontainer rounded-lg"
                  : "leftcontainer1 rounded-lg"
              }
            >
              <Grid className="">
                <Grid className=" p-7 ">
                  <TextField
                    label=""
                    onChange={""}
                    onKeyDown={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                    sx={{ m: 1, width: "25ch" }}
                    value={searchValue}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchOutlinedIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid className="editContainer rounded-radiusFourPix ">
                  {/* <Grid
                      onClick={showFormActions}
                      className={
                        isFormVisible.isOpen ? "addIconchange" : "addIcon"
                      }
                    >
                      <span className=" p-2 m-r-f ">Add</span>
                      <AddCircleOutlineIcon
                        style={{
                          fontSize: "14px",
                          color: "#545454",
                          marginBottom: "3px",
                          color: `${
                            isFormVisible.isOpen ? "#ffffff" : "#545454"
                          }`,
                        }}
                      />
                    </Grid> */}

                  {/* <Grid className="Addaction">
                      <span className="p-2 m-r-f">Actions</span>
                      <AddCircleOutlineIcon
                        style={{
                          fontSize: "14px",
                          color: "#545454",
                          marginBottom: "3px",
                        }}
                      />
                    </Grid> */}

                  {/* <Grid className="addIcon">
                      <span className="p-2 m-r-f">Filter</span>
                      <img
                        src={filter}
                        alt="filter.png"
                        style={{
                          position: "absolute",
                          left: "143px",
                          right: "20px",
                          top: "15px",
                          bottom: "95px",
                          width: "11px",
                          paddingTop: "3px",
                        }}
                      ></img>
                    </Grid> */}
                </Grid>
              </Grid>
              <>
                <HealthcareList
                  setTotalPage={setTotalPage}
                  totalPage={totalPage}
                  calculateData={calculateData}
                  list={healthCareListData}
                  handlePaginationClick={handlePaginationClick}
                  showUpdateData={showUpdateData}
                  handleDeleteRow={handleDeleteRow}
                  healthcarepractitionerview={
                    access?.healthcarepractitionerview
                  }
                  healthcarepractitionerupdate={
                    access?.healthcarepractitionerupdate
                  }
                />
                <Modal
                  open={modalOpen}
                  onClose={handleModalClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style} style={{ width: "400px" }}>
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                    >
                      <Alert severity="info">
                        {deleteModal ? deleteModalDesc : cancelModalDesc}
                      </Alert>
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                      <div className="mt-10 flex justify-center items-center  towButtonContainer">
                        <Button
                          type="submit"
                          onClick={handleModalClose}
                          variant="contained"
                          disableElevation
                          style={{
                            marginRight: "10px",
                            borderColor: "#0F4391",
                            width: "99px",
                            height: "39px",
                            borderRadius: "9999px",
                            backgroundColor: "#E8FAFF",
                            color: "#0F4391",
                            border: "1px solid #0F4391",
                            marginBottom: "100px",
                          }}
                        >
                          {deleteModal ? "CANCEL" : "NO"}
                        </Button>

                        <Button
                          type="submit"
                          onClick={handleProceed}
                          variant="contained"
                          disableElevation
                          style={{
                            borderColor: "#0F4391",
                            width: "99px",
                            height: "39px",
                            borderRadius: "9999px",
                            backgroundColor: "#E8FAFF",
                            color: "#0F4391",
                            border: "1px solid #0F4391",
                            marginBottom: "100px",
                          }}
                        >
                          {deleteModal ? "PROCEED" : "YES"}
                        </Button>
                      </div>
                    </Typography>
                  </Box>
                </Modal>
              </>
            </div>
          </Grid>
          <ToastContainer />
          {isFormVisible.isOpen ? (
            <Grid item xs={6}>
              <div className="rightcontainer rounded-lg servicefullright">
                <div className="overflow-y-auto o-form-container">
                  {healthRecordsLoading ? (
                    <div style={{ position: "fixed", left: "50%", top: "50%" }}>
                      <CircularProgress />
                    </div>
                  ) : (
                    <>
                      <div className="font-normal text-o-form-label-color mainhead flex justify-between items-center pl-5 pr-1 h-10">
                        <h1 id="COLOR">Healthcare Practitioner Overview</h1>
                        {/* <div className="closeIconContainer" onClick={hideForm}>
                          <IconButton
                            color="warning"
                            aria-label="upload picture"
                            component="span"
                          >
                            <CloseIcon />
                          </IconButton>
                        </div> */}
                      </div>

                      <form autoComplete="off">
                        <div className="mt-5 pl-5 OrgNameContainer ">
                          <h3
                            className="font-normal text-o-form-label-color"
                            id="COLOR"
                          >
                            Salutation
                          </h3>
                          <Autocomplete
                            onKeyDown={(e) => {
                              e.key === "Enter" && e.preventDefault();
                            }}
                            value={
                              healthCarePractitionerState &&
                              healthCarePractitionerState.salutation
                            }
                            autoHighlight={true}
                            onChange={(e, v) =>
                              handleDropDownChange("salutation", v)
                            }
                            getOptionLabel={(option) => (option ? option : "")}
                            id="org-list-option"
                            options={salutationlist ? salutationlist : []}
                            sx={{ width: 360 }}
                            renderInput={(params) => (
                              <TextField
                                data-testid="salutation"
                                variant="standard"
                                sx={{ width: "890px" }}
                                {...params}
                                label=""
                              />
                            )}
                          />
                        </div>
                        <div className="mt-5 pl-5 OrgNameContainer">
                          <h3
                            className="font-normal text-o-form-label-color"
                            id="COLOR"
                          >
                            Title
                          </h3>
                          <TextField
                            onKeyDown={(e) => {
                              e.key === "Enter" && e.preventDefault();
                            }}
                            autoComplete="OFF"
                            onChange={(e) => handleInputChange(e)}
                            name="title"
                            value={healthCarePractitionerState.title}
                            style={{ width: "890px" }}
                            id="standard-basic"
                            label=""
                            variant="standard"
                            data-testid="title"
                          />
                        </div>

                        <div className="mt-5 pl-5 OrgNameContainer">
                          <h3
                            className="font-normal text-o-form-label-color"
                            id="COLOR"
                          >
                            First name <span style={{ color: "red" }}> *</span>
                          </h3>
                          <TextField
                            onKeyDown={(e) => {
                              e.key === "Enter" && e.preventDefault();
                            }}
                            onFocus={(e) => resetErrorHandler(e)}
                            autoComplete="OFF"
                            onChange={(e) => handleInputChange(e)}
                            name="firstName"
                            value={healthCarePractitionerState.firstName}
                            style={{ width: "890px" }}
                            id="standard-basic"
                            label=""
                            variant="standard"
                            data-testid="firstName"
                            error={
                              healthPractitionerErrors.isFirstNameError === true
                            }
                            helperText={
                              healthPractitionerErrors.isFirstNameError
                                ? "Required field, Please fill to proceed"
                                : ""
                            }
                          />
                        </div>
                        <div className="mt-5 pl-5 OrgNameContainer">
                          <h3
                            className="font-normal text-o-form-label-color"
                            id="COLOR"
                          >
                            Middle Name
                          </h3>
                          <TextField
                            onKeyDown={(e) => {
                              e.key === "Enter" && e.preventDefault();
                            }}
                            onFocus={(e) => resetErrorHandler(e)}
                            autoComplete="OFF"
                            onChange={(e) => handleInputChange(e)}
                            name="middleName"
                            value={healthCarePractitionerState.middleName}
                            style={{ width: "890px" }}
                            id="standard-basic"
                            label=""
                            variant="standard"
                            data-testid="middleName"
                          />
                        </div>
                        <div className="mt-5 pl-5 OrgNameContainer">
                          <h3
                            className="font-normal text-o-form-label-color"
                            id="COLOR"
                          >
                            Last Name <span style={{ color: "red" }}> *</span>
                          </h3>
                          <TextField
                            onKeyDown={(e) => {
                              e.key === "Enter" && e.preventDefault();
                            }}
                            onFocus={(e) => resetErrorHandler(e)}
                            autoComplete="OFF"
                            onChange={(e) => handleInputChange(e)}
                            name="lastName"
                            value={healthCarePractitionerState.lastName}
                            style={{ width: "890px" }}
                            id="standard-basic"
                            label=""
                            variant="standard"
                            data-testid="lastName"
                            error={
                              healthPractitionerErrors.isLastNameError === true
                            }
                            helperText={
                              healthPractitionerErrors.isLastNameError
                                ? "Required field, Please fill to proceed"
                                : ""
                            }
                          />
                        </div>
                        {/* <div className="mt-5 pl-5 OrgNameContainer">
                          <h3
                            className="font-normal text-o-form-label-color"
                            id="COLOR"
                          >
                            Date of Birth
                          </h3>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              inputFormat="DD/MM/YYYY"
                              maxDate={new Date()}
                              value={healthCarePractitionerState.dob}
                              onChange={(newValue) => {
                                setHealthCarePractitionerState((prev) => ({
                                  ...prev,
                                  dob: newValue,
                                }));
                              }}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                              variant="standard"
                            />
                          </LocalizationProvider>
                        </div> */}
                        <div className="mt-5 pl-5 OrgNameContainer">
                          <h3
                            className="font-normal text-o-form-label-color"
                            id="COLOR"
                          >
                            Email ID
                          </h3>
                          <TextField
                            onKeyDown={(e) => {
                              e.key === "Enter" && e.preventDefault();
                            }}
                            onFocus={(e) => resetErrorHandler(e)}
                            autoComplete="OFF"
                            onChange={(e) => handleInputChange(e)}
                            name="emailId"
                            value={healthCarePractitionerState.emailId}
                            disabled={healthCarePractitionerState.is_update}
                            style={{ width: "890px" }}
                            id="standard-basic"
                            label=""
                            variant="standard"
                            data-testid="emailId"
                          />
                        </div>
                        <div className="mt-5 pl-5 OrgNameContainer">
                          <h3
                            className="font-normal text-o-form-label-color"
                            id="COLOR"
                          >
                            Gender
                          </h3>
                          <Autocomplete
                            onKeyDown={(e) => {
                              e.key === "Enter" && e.preventDefault();
                            }}
                            value={
                              healthCarePractitionerState &&
                              healthCarePractitionerState.gender &&
                              healthCarePractitionerState.gender
                            }
                            autoHighlight={true}
                            onChange={(e, v) =>
                              handleDropDownChange("gender", v)
                            }
                            getOptionLabel={(option) => (option ? option : "")}
                            id="org-list-option"
                            options={genderOptions.length ? genderOptions : []}
                            sx={{ width: 360 }}
                            renderInput={(params) => (
                              <TextField
                                data-testid="gender"
                                variant="standard"
                                sx={{ width: "890px" }}
                                {...params}
                                label=""
                              />
                            )}
                          />
                        </div>
                        <div className="mt-5 pl-5 OrgNameContainer">
                          <h3
                            className="font-normal text-o-form-label-color"
                            id="COLOR"
                          >
                            Registration number{" "}
                            <span style={{ color: "red" }}> *</span>
                          </h3>
                          <TextField
                            onKeyDown={(e) => {
                              e.key === "Enter" && e.preventDefault();
                            }}
                            onFocus={(e) => resetErrorHandler(e)}
                            autoComplete="OFF"
                            onChange={(e) => handleInputChange(e)}
                            name="registrationNumber"
                            value={
                              healthCarePractitionerState.registrationNumber
                            }
                            style={{ width: "890px" }}
                            id="standard-basic"
                            label=""
                            variant="standard"
                            data-testid="registrationNumber"
                            error={
                              healthPractitionerErrors.isRegNumLimitExceeded ===
                                true ||
                              healthPractitionerErrors.isRegNumberError === true
                            }
                            helperText={
                              healthPractitionerErrors.isRegNumLimitExceeded
                                ? "Character Limit Exceeded"
                                : "" ||
                                  healthPractitionerErrors.isRegNumberError
                                ? "Required field, Please fill to proceed"
                                : ""
                            }
                          />
                        </div>
                        <div className="mt-5 pl-5 OrgNameContainer">
                          <h3
                            className="font-normal text-o-form-label-color"
                            id="COLOR"
                          >
                            Designation
                          </h3>
                          <TextField
                            onKeyDown={(e) => {
                              e.key === "Enter" && e.preventDefault();
                            }}
                            onFocus={(e) => resetErrorHandler(e)}
                            autoComplete="OFF"
                            onChange={(e) => handleInputChange(e)}
                            name="designation"
                            value={healthCarePractitionerState.designation}
                            style={{ width: "890px" }}
                            id="standard-basic"
                            label=""
                            variant="standard"
                            data-testid="designation"
                            error={
                              healthPractitionerErrors.isDesignationLimitExceeded ===
                              true
                            }
                            helperText={
                              healthPractitionerErrors.isDesignationLimitExceeded
                                ? "Character Limit Exceeded"
                                : ""
                            }
                          />
                        </div>
                        <div className="mt-5 pl-5 OrgNameContainer">
                          <h3
                            className="font-normal text-o-form-label-color"
                            id="COLOR"
                          >
                            Qualification{" "}
                            <span style={{ color: "red" }}> *</span>
                          </h3>
                          <TextField
                            rows={3}
                            multiline
                            onFocus={(e) => resetErrorHandler(e)}
                            autoComplete="OFF"
                            onChange={(e) => handleInputChange(e)}
                            name="qualification"
                            value={healthCarePractitionerState.qualification}
                            style={{ width: "890px" }}
                            id="standard-basic"
                            label=""
                            variant="outlined"
                            data-testid="qualification"
                            error={
                              healthPractitionerErrors.isQualificationLimitExceeded ===
                                true ||
                              healthPractitionerErrors.isQualificationError ===
                                true
                            }
                            helperText={
                              healthPractitionerErrors.isQualificationLimitExceeded
                                ? "Character Limit Exceeded"
                                : "" ||
                                  healthPractitionerErrors.isQualificationError
                                ? "Required field, Please fill to proceed"
                                : ""
                            }
                          />
                        </div>
                        <div className="mt-5 pl-5 OrgNameContainer ">
                          <h3
                            className="font-normal text-o-form-label-color"
                            id="COLOR"
                          >
                            Sub Specialization
                            <span style={{ color: "red" }}> *</span>
                          </h3>
                          <Autocomplete
                            onKeyDown={(e) => {
                              e.key === "Enter" && e.preventDefault();
                            }}
                            onFocus={(e) => resetErrorHandler(e)}
                            multiple
                            value={
                              healthCarePractitionerState &&
                              healthCarePractitionerState.specializations &&
                              healthCarePractitionerState.specializations
                            }
                            autoHighlight={true}
                            onChange={(e, v) =>
                              handleDropDownChange("specializations", v)
                            }
                            getOptionLabel={(option) =>
                              option.subSpecialization
                                ? option.subSpecialization
                                : ""
                            }
                            id="org-list-option"
                            data-testid="specializations"
                            options={
                              specializationList ? specializationList : []
                            }
                            sx={{ width: 360 }}
                            renderInput={(params) => (
                              <TextField
                                data-testid="roles"
                                name="specializations"
                                variant="standard"
                                sx={{ width: "890px" }}
                                {...params}
                                label=""
                                error={
                                  healthPractitionerErrors.isSpecializationError ===
                                  true
                                }
                                helperText={
                                  healthPractitionerErrors.isSpecializationError
                                    ? "Required field, Please fill to proceed"
                                    : ""
                                }
                              />
                            )}
                          />
                        </div>

                        <div className="mt-5 pl-5 OrgNameContainer ">
                          <h3
                            className="font-normal text-o-form-label-color"
                            id="COLOR"
                          >
                            Specialization
                          </h3>
                          <Autocomplete
                            disabled
                            onKeyDown={(e) => {
                              e.key === "Enter" && e.preventDefault();
                            }}
                            onFocus={(e) => resetErrorHandler(e)}
                            multiple
                            value={
                              healthCarePractitionerState &&
                              Array.from(
                                new Set(
                                  healthCarePractitionerState.specializations.map(
                                    (item) => item.specialization
                                  )
                                )
                              ).map((specialization) =>
                                healthCarePractitionerState.specializations.find(
                                  (item) =>
                                    item.specialization === specialization
                                )
                              )
                            }
                            autoHighlight={true}
                            onChange={(e, v) =>
                              handleDropDownChange("specializations", v)
                            }
                            getOptionLabel={(option) =>
                              option.specialization ? option.specialization : ""
                            }
                            id="org-list-option"
                            data-testid="specializations"
                            options={
                              specializationList ? specializationList : []
                            }
                            sx={{ width: 360 }}
                            renderInput={(params) => (
                              <TextField
                                data-testid="roles"
                                name="specializations"
                                variant="standard"
                                sx={{ width: "890px" }}
                                {...params}
                                label=""
                                error={
                                  healthPractitionerErrors.isSpecializationError ===
                                  true
                                }
                                helperText={
                                  healthPractitionerErrors.isSpecializationError
                                    ? "Required field, Please fill to proceed"
                                    : ""
                                }
                              />
                            )}
                          />
                        </div>
                        <div className="mt-5 pl-5 OrgNameContainer">
                          <h3
                            className="font-normal text-o-form-label-color"
                            id="COLOR"
                          >
                            Department <span style={{ color: "red" }}> *</span>
                          </h3>
                          <TextField
                            onKeyDown={(e) => {
                              e.key === "Enter" && e.preventDefault();
                            }}
                            onFocus={(e) => resetErrorHandler(e)}
                            autoComplete="OFF"
                            onChange={(e) => handleInputChange(e)}
                            name="department"
                            value={healthCarePractitionerState.department}
                            style={{ width: "890px" }}
                            id="standard-basic"
                            label=""
                            variant="standard"
                            data-testid="department"
                            error={
                              healthPractitionerErrors.isDepartmentLimitExceeded ===
                                true ||
                              healthPractitionerErrors.isDepartmentError ===
                                true
                            }
                            helperText={
                              healthPractitionerErrors.isDepartmentLimitExceeded
                                ? "Character Limit Exceeded"
                                : "" ||
                                  healthPractitionerErrors.isDepartmentError
                                ? "Required field, Please fill to proceed"
                                : ""
                            }
                          />
                        </div>
                        <div className="mt-5 pl-5 OrgNameContainer">
                          <h3
                            className="font-normal text-o-form-label-color"
                            id="COLOR"
                          >
                            Mobile Number
                            <span style={{ color: "red" }}> *</span>
                          </h3>
                          <TextField
                            onKeyDown={(e) => {
                              e.key === "Enter" && e.preventDefault();
                            }}
                            onFocus={(e) => resetErrorHandler(e)}
                            autoComplete="OFF"
                            onChange={(e) => handleInputChange(e)}
                            name="mobileNumber"
                            value={healthCarePractitionerState.mobileNumber}
                            style={{ width: "890px" }}
                            id="standard-basic"
                            label=""
                            variant="standard"
                            data-testid="mobileNumber"
                            error={
                              healthPractitionerErrors.isMobileNumberError ===
                              true
                            }
                            helperText={
                              healthPractitionerErrors.isMobileNumberError
                                ? "Required field, Please fill to proceed"
                                : ""
                            }
                          />
                        </div>
                        <div className="mt-5 pl-5 OrgNameContainer">
                          <h3
                            className="font-normal text-o-form-label-color"
                            id="COLOR"
                          >
                            Alternate Number
                          </h3>
                          <TextField
                            onKeyDown={(e) => {
                              e.key === "Enter" && e.preventDefault();
                            }}
                            onFocus={(e) => resetErrorHandler(e)}
                            autoComplete="OFF"
                            onChange={(e) => handleInputChange(e)}
                            name="alternateMobileNumber"
                            value={
                              healthCarePractitionerState.alternateMobileNumber
                            }
                            style={{ width: "890px" }}
                            id="standard-basic"
                            label=""
                            variant="standard"
                            type="number"
                            data-testid="alternateMobileNumber"
                          />
                        </div>

                        <div className="mt-5 pl-5 OrgNameContainer ">
                          <h3
                            className="font-normal text-o-form-label-color"
                            id="COLOR"
                          >
                            Roles
                          </h3>
                          <Autocomplete
                            onKeyDown={(e) => {
                              e.key === "Enter" && e.preventDefault();
                            }}
                            multiple
                            disabled
                            value={
                              healthCarePractitionerState &&
                              healthCarePractitionerState.roles &&
                              healthCarePractitionerState.roles
                            }
                            autoHighlight={true}
                            onChange={(e, v) =>
                              handleDropDownChange("roles", v)
                            }
                            getOptionLabel={(option) => (option ? option : "")}
                            id="org-list-option"
                            data-testid="roles"
                            options={userRoles.length ? userRoles : []}
                            sx={{ width: 360 }}
                            renderInput={(params) => (
                              <TextField
                                data-testid="roles"
                                variant="standard"
                                sx={{ width: "890px" }}
                                {...params}
                                label=""
                              />
                            )}
                          />
                        </div>

                        <div className="mt-5 pl-5 OrgNameContainer ">
                          <h3
                            className="font-normal text-o-form-label-color"
                            id="COLOR"
                          >
                            Organization Name{" "}
                          </h3>
                          <Autocomplete
                            multiple
                            onKeyDown={(e) => {
                              e.key === "Enter" && e.preventDefault();
                            }}
                            value={
                              healthCarePractitionerState &&
                              healthCarePractitionerState.organizations &&
                              healthCarePractitionerState.organizations
                            }
                            autoHighlight={true}
                            onChange={(e, v) =>
                              handleDropDownChange("organizations", v)
                            }
                            getOptionLabel={(option) =>
                              option.name ? option.name : ""
                            }
                            id="org-list-option"
                            data-testid="organizations"
                            options={organizations ? organizations : []}
                            sx={{ width: 360 }}
                            renderInput={(params) => (
                              <TextField
                                data-testid="roles"
                                variant="standard"
                                sx={{ width: "890px" }}
                                {...params}
                                label=""
                              />
                            )}
                          />
                        </div>

                        <div className="mt-5 pl-5 OrgNameContainer ">
                          <h3
                            className="font-normal text-o-form-label-color"
                            id="COLOR"
                          >
                            Locations
                          </h3>
                          <Autocomplete
                            multiple
                            onKeyDown={(e) => {
                              e.key === "Enter" && e.preventDefault();
                            }}
                            value={
                              healthCarePractitionerState &&
                              healthCarePractitionerState.locations &&
                              healthCarePractitionerState.locations
                            }
                            autoHighlight={true}
                            onChange={(e, v) =>
                              handleDropDownChange("locations", v)
                            }
                            getOptionLabel={(option) =>
                              option.name ? option.name : ""
                            }
                            id="org-list-option"
                            options={locations ? locations : []}
                            sx={{ width: 360 }}
                            renderInput={(params) => (
                              <TextField
                                data-testid="roles"
                                variant="standard"
                                sx={{ width: "890px" }}
                                {...params}
                                label=""
                              />
                            )}
                          />
                        </div>
                        <div className="mt-5 pl-5 OrgNameContainer">
                          <h3
                            className="font-normal text-o-form-label-color"
                            id="COLOR"
                          >
                            Bio
                          </h3>
                          <TextField
                            rows={3}
                            multiline
                            onFocus={(e) => resetErrorHandler(e)}
                            autoComplete="OFF"
                            onChange={(e) => handleInputChange(e)}
                            name="bio"
                            value={healthCarePractitionerState.bio}
                            style={{ width: "890px" }}
                            id="standard-basic"
                            label=""
                            variant="outlined"
                            data-testid="bio"
                            error={
                              healthPractitionerErrors.isBioLimitExceeded ===
                              true
                            }
                            helperText={
                              healthPractitionerErrors.isBioLimitExceeded
                                ? "Character Limit Exceeded"
                                : ""
                            }
                          />
                        </div>

                        <div className="mt-5 pl-5 OrgNameContainer">
                          <h3
                            className="font-normal text-o-form-label-color"
                            id="COLOR"
                          >
                            Photo <span style={{ color: "red" }}> *</span>
                          </h3>
                          <Button variant="contained" component="label">
                            {signature ? "Update Photo" : "Upload Photo"}
                            <input
                              onFocus={(e) => resetErrorHandler(e)}
                              type="file"
                              hidden
                              name="photo"
                              id="file"
                              accept=".jpg, .jpeg, .png"
                              data-testid="photo"
                              onChange={(e) => onChange(e, "photo")}
                            />
                          </Button>
                          <br />
                          {Photo && (
                            <img
                              className="upload-image"
                              src={`data:image;base64,${Photo}`}
                              width="200px"
                              height={"100px"}
                            />
                          )}
                        </div>
                        {healthPractitionerErrors.isPhotoError ? (
                          <div
                            className="locationPricingError"
                            style={{
                              color: "#d32f2f",
                              marginLeft: "20px",
                              fontSize: "0.80rem",
                            }}
                          >
                            Required field, Please fill to proceed
                          </div>
                        ) : null}

                        <div className="mt-5 pl-5 OrgNameContainer">
                          <h3
                            className="font-normal text-o-form-label-color"
                            id="COLOR"
                          >
                            Signature <span style={{ color: "red" }}> *</span>
                          </h3>
                          <Button variant="contained" component="label">
                            {signature
                              ? "Update Signature"
                              : "Upload Signature"}

                            <input
                              hidden
                              onFocus={(e) => resetErrorHandler(e)}
                              type="file"
                              name="signature"
                              id="file"
                              accept="image/*"
                              data-testid="signature"
                              onChange={(e) => onChange(e, "signature")}
                            />
                          </Button>
                          <br />
                          {signature && (
                            <img
                              className="upload-image"
                              src={`data:image;base64,${signature}`}
                              width="200px"
                              height={"100px"}
                            />
                          )}
                        </div>
                        {healthPractitionerErrors.isSignError ? (
                          <div
                            className="locationPricingError"
                            style={{
                              color: "#d32f2f",
                              marginLeft: "20px",
                              fontSize: "0.80rem",
                            }}
                          >
                            Required field, Please fill to proceed
                          </div>
                        ) : null}
                        <div className="mt-5 pl-5 OrgNameContainer ">
                          <div className="progressContainer mt-5 flex justify-center items-center">
                            {showerror === true ? (
                              <Alert severity="error" color="warning">
                                {errorDisplay}
                              </Alert>
                            ) : (
                              // (createOrgRes.isLoading || updateOrgRes.isLoading) ?
                              // <CircularProgress /> :
                              ""
                            )}
                          </div>
                          <div className="mt-9 flex justify-center items-center  towButtonContainer h-40">
                            <Button
                              onClick={handleCancelClick}
                              variant="contained"
                              disableElevation
                              style={{
                                marginRight: "10px",
                                borderColor: "#0F4391",
                                width: "99px",
                                height: "39px",
                                borderRadius: "9999px",
                                backgroundColor: "#E8FAFF",
                                color: "#0F4391",
                                border: "1px solid #0F4391",
                                marginBottom: "100px",
                              }}
                            >
                              CANCEL
                            </Button>
                            <Button
                              variant="contained"
                              disableElevation
                              onClick={bodySubmitHandler}
                              style={{
                                borderColor: "#0F4391",
                                width: "99px",
                                height: "39px",
                                borderRadius: "9999px",
                                backgroundColor: "#E8FAFF",
                                color: "#0F4391",
                                border: "1px solid #0F4391",
                                marginBottom: "100px",
                              }}
                            >
                              SAVE
                            </Button>
                          </div>
                        </div>
                      </form>
                    </>
                  )}
                </div>
              </div>
            </Grid>
          ) : null}
        </Grid>
      </div>
      <AdminCookieConsentBanner />
    </div>
    
  );
};

export default ServiceRequest;
