import { useState, useEffect, useRef } from "react";
import AdminHome from "../AdminHome";
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import TagLogic from "./medicineLogic";
import TagList from "./medicineList";
import filter from "../../../images/filter.png"
import {
  useGetTagListMutation, useCreateTagMasterMutation,
  useUpdatigsampleMutation, useGetTagByTagMasterNameMutation,
  useGetUserByUserIdMutation, useGetUsageCountTagMutation, useGetMedicinerouteMutation,
  useGetMedicinemasterMutation, useGetByConstantMutation,
  useGetMedicineStrengthUOMMutation,
  useGetMedicineSiteListMutation,
  useGetMedicinemastersearchMutation,
  useDeleteMedicineMasterMutation
} from "../../../services/adminLogIn";
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import "../Organization/Mainhome.css"
import { Grid } from "@mui/material";
import InputAdornment from '@mui/material/InputAdornment';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Breadcrums from "../../global/breadcrums/Breadcrums"
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { toasterFunction } from "../../../globalFunctions/getLocalStorageData";
import AdminCookieConsentBanner from "../home/AdminCookieConsentBanner";
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
const MedicineMaster = (props) => {


  const [createApi] = useCreateTagMasterMutation();
  const [updateApi] = useUpdatigsampleMutation();
  const [deleteApi] = useDeleteMedicineMasterMutation();
  const [getUserByUserId] = useGetUserByUserIdMutation();
  const [getUsageCountTag] = useGetUsageCountTagMutation();
  const [getTagByTagNameApi] = useGetTagByTagMasterNameMutation();
  const [getmedicineroute] = useGetMedicinerouteMutation();
  const [createApimedicinemaster] = useGetMedicinemasterMutation();
  const [getmedicinesearch] = useGetMedicinemastersearchMutation();
  const [getMedicineStrengthUOM] = useGetMedicineStrengthUOMMutation();
  const [getMedicineSiteList] = useGetMedicineSiteListMutation();
  const [getDrugFormList] = useGetByConstantMutation();
  const [access, setAccess] = useState(JSON.parse(sessionStorage.getItem("access")));

  const [expand, setexpand] = useState({
    heading: "",
    icon: "",
    isSubListExpanded: false
  });
  const [expand1, setexpand1] = useState({
    empty: true
  });
  const [getTagMasterList] = useGetTagListMutation();

  const [isNewData, setIsNewData] = useState(true)
  const [currentPage, setCurrentPage] = useState(1);
  const [calculateData, setTotalPageCalculate] = useState({
    number: "",
    numberOfElements: "",
    totalElements: "",
    showcount: ""
  });
  const [modalOpen, setModalOpen] = useState(false);
  const [currentRow, setCurrentRow] = useState();

  const handleDelete =(item)=> {
    setModalOpen(true);
    setCurrentRow(item)
  }
 
  const handleModalClose = () => {
    setModalOpen(false);
  }

  const handleProceed = async () => {
    
    const res = await deleteApi(currentRow.medicineMasterId);
    console.log(res)
  if(res?.error?.data?.message){
    toast(res?.error?.data?.message, {
      position: "top-center",
      autoClose: 2000,
hideProgressBar: true,
      type: "error",
      closeOnClick: true,
      theme: "light",
    });
  
   
  }
  else{
    toast("Medicine Deleted Successfully", {
      position: "top-center",
      autoClose: 2000,
hideProgressBar: true,
      type: "success",
      closeOnClick: true,
      theme: "light",
    });
    
  }
  setModalOpen(false);
  hideForm();

}
  const { whenInputsChange, AllTextFieldState, isError, dropdownState, setDropdowntate,
    handleDropDownChange, resetErrorHandler, setTextFieldState, setDropdownState,
    open,
    handleClose,
    setErrorMessage,
    showerror,
    setError,
    halndleClickonProceed, setIsCombDrug, isCombinationDrug, switchControlAction
  } = TagLogic();
  const [statuslist] = useState(["ACTIVE", "INACTIVE"]);
  const [medicineroute] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [searchVal, setsearchVal] = useState("");
  const [fullName, setFullName] = useState("");
  const [usageCount, setUsageount] = useState(0);
  const [isFormVisible, setFormVisible] = useState({
    isOpen: false,
    isClose: true
  });
  const [id, setId] = useState("");
  const [medicineList, setMedicineList] = useState([]);
  const [medicineRouteList, setMedicineRoute] = useState([]);
  const [strengthUOMList, setStrengthUOMList] = useState([]);
  const [siteList, setSiteList] = useState([]);
  const [drugFormList, setDrugFormList] = useState([]);
  const [paginationdata, setPaginationData] = useState({
    string: "",
    page: 0,
    size: 20
  });


  const showFormActions = () => {
    if (access?.tagsview == true) {
      setErrorMessage(false)
      setId("")
      if (!isFormVisible.isOpen) {
        setFormVisible((prev) => ({ ...prev, isOpen: isFormVisible.isOpen = true, isFormVisible, isClose: isFormVisible.isClose = false }));
        if (AllTextFieldState.is_update === false) {
          setId("")
        }
      }
    }
    else {
      toast(" No Permission", {
        position: "top-center",
        autoClose: 2000,
hideProgressBar: true,
        type: "error",
        closeOnClick: true,
        theme: "light",
      });
    }
  }

  const SubmitHandler = async (e) => {

    e.preventDefault();
    console.log(AllTextFieldState)
    if (AllTextFieldState.name === "") {
      setError((prev) => ({
        ...prev,
        isNameError: isError.isNameError = true,
        isGenericNameError: isError.isGenericNameError = true,
        isDrugForm: isError.isDrugForm = true,
        isRoute: isError.isRoute = true,
        isStrength: isError.isStrength = true,
        isDisplayStrength: isCombinationDrug==false ?  isError.isDisplayStrength = true : isError.isDisplayStrength = false,
      }))

    } else if (AllTextFieldState.genericName == '') {
      setError((prev) => ({
        ...prev,
        isNameError: isError.isNameError = false,
        isGenericNameError: isError.isGenericNameError = true,
        isDrugForm: isError.isDrugForm = true,
        isRoute: isError.isRoute = true,
        isStrength: isError.isStrength = true,
        isDisplayStrength: isCombinationDrug==false ?  isError.isDisplayStrength = true : isError.isDisplayStrength = false,

      }))

    } else if (AllTextFieldState.drugForm == '' || AllTextFieldState.drugForm == null) {
      setError((prev) => ({
        ...prev,
        isNameError: isError.isNameError = false,
        isGenericNameError: isError.isGenericNameError = false,
        isDrugForm: isError.isDrugForm = true,
        isRoute: isError.isRoute = true,
        isStrength: isError.isStrength = true,
        isDisplayStrength: isCombinationDrug==false ?  isError.isDisplayStrength = true : isError.isDisplayStrength = false,

      }))

    } else if (AllTextFieldState.route == '') {
      setError((prev) => ({
        ...prev,
        isNameError: isError.isNameError = false,
        isGenericNameError: isError.isGenericNameError = false,
        isDrugForm: isError.isDrugForm = false,
        isRoute: isError.isRoute = true,
        isStrength: isError.isStrength = true,
        isDisplayStrength: isCombinationDrug==false ?  isError.isDisplayStrength = true : isError.isDisplayStrength = false,

      }))

    }else if (AllTextFieldState.displayStrength == '') {
      setError((prev) => ({
        ...prev,
        isNameError: isError.isNameError = false,
        isGenericNameError: isError.isGenericNameError = false,
        isRoute: isError.isRoute = false,
        isDrugForm: isError.isDrugForm = false,
        isStrength: isError.isStrength = true,
        isDisplayStrength: isCombinationDrug==false ?  isError.isDisplayStrength = true : isError.isDisplayStrength = false,
      }))

    } else if (isCombinationDrug==false && AllTextFieldState.strengthUOM == '') {
      setError((prev) => ({
        ...prev,
        isNameError: isError.isNameError = false,
        isGenericNameError: isError.isGenericNameError = false,
        isRoute: isError.isRoute = false,
        isDrugForm: isError.isDrugForm = false,
        isStrength: isError.isStrength = false,
        isDisplayStrength: isError.isDisplayStrength = true,
      }))

    } 
    else {

      setError((prev) => ({
        ...prev,
        isNameError: isError.isNameError = false,
        isGenericNameError: isError.isGenericNameError = false,
        isRoute: isError.isRoute = false,
        isDrugForm: isError.isDrugForm = false,
        isStrength: isError.isStrength = false,
        isDisplayStrength: isError.isDisplayStrength = false,
      }))
      const sendOrgDetails = {
        name: AllTextFieldState.name,
        genericName: AllTextFieldState.genericName,
        drugForm: AllTextFieldState.drugForm,
        route: AllTextFieldState?.route?.join('/'),
        site: AllTextFieldState.site,
        displayStrength: AllTextFieldState.displayStrength,
        strengthUOM: AllTextFieldState.strengthUOM,
        "isActive": true
      }
      if (AllTextFieldState.is_update === false) {
        const res = await createApimedicinemaster(sendOrgDetails)
        if (res?.data?.medicineMasterId) {
          hideForm()
          toasterFunction("Medicine Master Created Successfully", "success")
        }
        else {
          toasterFunction(res?.error?.data?.message, "error")
        }

      }
      else {
        sendOrgDetails.medicineMasterId = AllTextFieldState.medicineMasterId
       
        const res = await createApimedicinemaster(sendOrgDetails)
        if (res?.data?.medicineMasterId) {
          hideForm()
          toasterFunction("Medicine Master Updated Successfully", "success")
        }
        else {
          toasterFunction(res?.error?.data?.message, "error")
        }
      }
      setErrorMessage(true)
    }

  }
  const hideForm = () => {
    setErrorMessage(false)
    setIsCombDrug(false)
    setTextFieldState({
      is_update: false,
      name: '',
      genericName: '',
      drugForm: '',
      route: [],
      strength: '',
      site: '',
      isActive: "ACTIVE"
    })
    if (isFormVisible.isOpen) {
      setFormVisible((prev) => ({ ...prev, isOpen: isFormVisible.isOpen = false, isFormVisible, isClose: isFormVisible.isClose = true }));
    }
    setError((prev) => ({
      ...prev,
      isNameError: false,
      isGenericNameError: false,
      isDrugForm: false,
      isRoute: false,
      isStrength: false,
      isDisplayStrength: false,
      isSite: false,
    }))
    setsearchVal("")
    getMedicineMasterListFunct()
  }



  const getUserByIdFunct = async (userId, tagId) => {
    const res = await getUserByUserId(userId)
    if (res?.data?.content.length > 0) {
      setFullName(res?.data?.content[0].fullName)
    }

    const usageRes = await getUsageCountTag(tagId)
    if (usageRes?.data?.content.length > 0) {
      setUsageount(usageRes?.data?.totalElements)
    }
  }

  const onSearchevent = async (value) => {
    setsearchVal(value);
    if (value.length != 0) {
      const res = await getmedicinesearch({ searchString: value, page: 0, size: 20 });
      if (res?.data?.content) {
        setCurrentPage(1)
        setMedicineList(res?.data?.content)

      }
      else {
        setMedicineList([])
      }
    }
    else {
      getMedicineMasterListFunct()
    }

  }
  useEffect(() => {
    // getListFunction({searchStr: "", status:"", page: 0});
    setIsNewData(true);
    setCurrentPage(1);
    getMedicineMasterList()
    getMedicineMasterListFunct()
  }, [])

  const getMedicineMasterListFunct = async () => {
    const medicineMaster = await getmedicinesearch({ searchString: "", page: 0, size: 20 });
    if (medicineMaster?.data?.content) {
      setMedicineList(medicineMaster?.data?.content)
      setTotalPage(medicineMaster?.data?.totalPages)
      setTotalPageCalculate({ numberOfElements: medicineMaster?.data?.numberOfElements, number: medicineMaster?.data?.number, totalElements: medicineMaster?.data?.totalElements })

    }
  }

  const getMedicineMasterListFunctByPage = async (page) => {
    const medicineMaster = await getmedicinesearch({ searchString: "", page: page-1, size: 20 });
    if (medicineMaster?.data?.content) {
      setMedicineList(medicineMaster?.data?.content)
      setTotalPage(medicineMaster?.data?.totalPages)
      setTotalPageCalculate({ numberOfElements: medicineMaster?.data?.numberOfElements, number: medicineMaster?.data?.number, totalElements: medicineMaster?.data?.totalElements })
    setCurrentPage(page)
    }
  }

  const getMedicineMasterList = async () => {
    let datatosend = {
      "string": "",
      "page": 0,
      "size": 500
    }

    const res = await getmedicineroute(datatosend)
    if (res?.data?.content.length > 0) {
      setMedicineRoute(res?.data?.content.map(value => (value.display
      )))
    }
    const drugres = await getDrugFormList("drugform_list1")
    if (drugres?.data?.length > 0) {
      setDrugFormList(drugres?.data)
    }

    const medicineMaster = await getmedicinesearch({ searchString: "", page: 0, size: 20 });
    if (medicineMaster?.data?.content) {
      setMedicineList(medicineMaster?.data?.content)
      setTotalPage(medicineMaster?.data?.totalPages)
      setTotalPageCalculate({ numberOfElements: medicineMaster?.data?.numberOfElements, number: medicineMaster?.data?.number, totalElements: medicineMaster?.data?.totalElements })

    }

    const strenthUOMRes = await getMedicineStrengthUOM(datatosend)
    if (strenthUOMRes?.data?.content.length > 0) {
      setStrengthUOMList(strenthUOMRes?.data?.content.map(value => (value.strengthUOMCode
      )))
    }
    const medicineSiteRes = await getMedicineSiteList(datatosend)
    if (medicineSiteRes?.data?.content.length > 0) {
      setSiteList(medicineSiteRes?.data?.content.map(value => (value.display
      )))
    }
  }
  return (
    <>
      <div className={props.expand1.empty === true ? "main1" : "main"}>
        <Breadcrums
          firsttab={"Categories"}
          secondtab="Medicine Request"
          thirdtab="Medicine Master"
        ></Breadcrums>
        <ToastContainer />
        <Grid container mt={-2}>
          <Grid item xs={6}>
            <div

              className={
                isFormVisible.isOpen
                  ? "leftcontainer rounded-lg"
                  : "leftcontainer1 rounded-lg"
              }
            >
              <Grid className=" p-7 ">
                <TextField
                  label=""
                  onChange={(e) => onSearchevent(e.target.value)}
                  onKeyDown={(e) => {
                    e.key === "Enter" && e.preventDefault();
                  }}
                  sx={{ m: 1, width: "25ch" }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchOutlinedIcon />
                      </InputAdornment>
                    ),
                  }}
                  value={searchVal}
                />
              </Grid>
              <Grid className="editContainer rounded-radiusFourPix ">
                <Grid
                  disabled={access?.useradd == false}
                  onClick={showFormActions}
                  className={isFormVisible.isOpen ? "addIconchange" : "addIcon"}
                  data-testid="open"
                >
                  <span className=" p-2 m-r-f ">Add</span>
                  <AddCircleOutlineIcon
                    style={{
                      fontSize: "14px",
                      color: "#545454",
                      marginBottom: "3px",
                      color: `${isFormVisible.isOpen ? "#ffffff" : "#545454"}`,
                    }}
                  />
                </Grid>
                {/* <Grid className="addIcon">
                  <span className="p-2 m-r-f">Filter</span>
                  <img
                    src={filter}
                    alt="filter.png"
                    style={{
                      position: "absolute",
                      left: "90%",
                      right: "20px",
                      top: "15px",
                      bottom: "95px",
                      width: "11px",
                      paddingTop: "3px",
                    }}
                  ></img>
                </Grid> */}

              </Grid>
              <>
                <TagList
                  medicineList={medicineList}
                  setTotalPage={setTotalPage}
                  totalPage={totalPage}
                  setId={setId}
                  id={id}
                  showFormActions={showFormActions}
                  setTextFieldState={setTextFieldState}
                  getUserByIdFunct={getUserByIdFunct}
                  setIsCombDrug={setIsCombDrug}
                  tagsview={access?.tagsview}
                  tagsupdate={access?.tagsupdate}
                  getMedicineMasterListFunctByPage={getMedicineMasterListFunctByPage}
                  calculateData={calculateData}
                  currentPage={currentPage}
                  handleDelete={handleDelete}

                />
                  <Modal
                          open={modalOpen}
                          onClose={handleModalClose}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                        >
                          <Box sx={style} style = {{width: "400px"}}>
                            <Typography
                              id="modal-modal-title"
                              variant="h6"
                              component="h2"
                            >
                              <Alert severity="info">
                              {"Are you sure you want to delete the Medicine Master? This activity will delete the Medicine Master from the master list which can't be resolved? Do you wish to continue?"}
                              </Alert>
                            </Typography>
                            <Typography
                              id="modal-modal-description"
                              sx={{ mt: 2 }}
                            >
                              <div className="mt-10 flex justify-center items-center  towButtonContainer">
                                <Button
                                  type="submit"
                                  onClick={handleModalClose}
                                  variant="contained"
                                  disableElevation
                                  style={{
                                    marginRight: "10px",
                                    borderColor: "#0F4391",
                                    width: "99px",
                                    height: "39px",
                                    borderRadius: "9999px",
                                    backgroundColor: "#E8FAFF",
                                    color: "#0F4391",
                                    border: "1px solid #0F4391",
                                    marginBottom: "100px",
                                  }}
                                >
                                  {"CANCEL"}
                                </Button>
                                <Button
                                  type="submit"
                                  onClick={handleProceed}
                                  variant="contained"
                                  disableElevation
                                  style={{
                                    borderColor: "#0F4391",
                                    width: "99px",
                                    height: "39px",
                                    borderRadius: "9999px",
                                    backgroundColor: "#E8FAFF",
                                    color: "#0F4391",
                                    border: "1px solid #0F4391",
                                    marginBottom: "100px",
                                  }}
                                >
                                  {"PROCEED"}
                                </Button>
                              </div>
                            </Typography>
                          </Box>
                          </Modal>
              </>
            </div>
          </Grid>

          {isFormVisible.isOpen ? (
            <Grid item xs={6}>
              <div className="rightcontainer rounded-lg">
                <div className="overflow-y-auto o-form-container">
                  <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={style}>
                      <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                      >
                        <Alert severity="info">
                          {" "}
                          {AllTextFieldState.status === "INACTIVE"
                            ? "Are you sure you want to reactivate  the Sample ?"
                            : "Are you sure you want to Deactivate the Sample ?"}
                        </Alert>
                      </Typography>
                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {AllTextFieldState.status === "INACTIVE"
                          ? "This activity will enable the Sample and user will be able to access it across all products."
                          : "This activity disable the Sample and user will not able to access it. You can reactivate the Sample at anytime?"}

                        <div className="mt-10 flex justify-center items-center  towButtonContainer">
                          <Button
                            type="submit"
                            onClick={handleClose}
                            variant="contained"
                            disableElevation
                            style={{
                              marginRight: "10px",
                              borderColor: "#0F4391",
                              width: "99px",
                              height: "39px",
                              borderRadius: "9999px",
                              backgroundColor: "#E8FAFF",
                              color: "#0F4391",
                              border: "1px solid #0F4391",
                              marginBottom: "100px",
                            }}
                          >
                            CANCEL
                          </Button>

                          <Button
                            type="submit"
                            onClick={halndleClickonProceed}
                            variant="contained"
                            disableElevation
                            style={{
                              borderColor: "#0F4391",
                              width: "99px",
                              height: "39px",
                              borderRadius: "9999px",
                              backgroundColor: "#E8FAFF",
                              color: "#0F4391",
                              border: "1px solid #0F4391",
                              marginBottom: "100px",
                            }}
                          >
                            PROCEED
                          </Button>
                        </div>
                      </Typography>
                    </Box>
                  </Modal>
                  <ToastContainer />
                  <>
                    <div className="font-normal text-o-form-label-color mainhead flex justify-between items-center pl-5 pr-1 h-10">
                      <h1>Medicine Master Overview</h1>
                    </div>

                    <form autoComplete="off" onSubmit={SubmitHandler}>
                      <div className=" pl-5 OrgNameContainer">
                        <h3
                          className="font-normal text-o-form-label-color"
                          id="Org"
                        >
                          Brand Name
                          <span style={{ color: "red" }}> *</span>
                        </h3>
                        <TextField
                          data-testid = "brandName"
                          onFocus={(e) => resetErrorHandler(e)}
                          autoComplete="OFF"
                          error={isError.isNameError === true}
                          disabled={AllTextFieldState.is_update}
                          helperText={
                            AllTextFieldState.is_update===false  && isError.isNameError
                              ? "Valid (Name) Required !"
                              : ""
                          }
                          onChange={(e) => whenInputsChange(e)}
                          name="name"
                          value={AllTextFieldState.name}
                          style={{ width: "360px" }}
                          id="standard-basic"
                          label=""
                          variant="standard"
                        />
                      </div>

                      <div className="mt-5 pl-5 OrgNameContainer">
                        <h3
                          className="font-normal text-o-form-label-color"
                          id="Org"
                        >
                          Generic Name
                          <span style={{ color: "red" }}> *</span>
                        </h3>
                        <TextField
                          data-testid = "genericName"
                          onFocus={(e) => resetErrorHandler(e)}
                          autoComplete="OFF"
                          disabled={AllTextFieldState.is_update}
                          error={isError.isGenericNameError === true}
                          helperText={
                            AllTextFieldState.is_update===false  && isError.isGenericNameError
                              ? "Valid (Generic Name) Required !"
                              : ""
                          }
                          onChange={(e) => whenInputsChange(e)}
                          name="genericName"
                          value={AllTextFieldState.genericName}
                          style={{ width: "360px" }}
                          id="standard-basic"
                          label=""
                          variant="standard"
                        />
                      </div>

                      <div className="mt-5 pl-5 OrgNameContainer ">
                        <h3 className="font-normal text-o-form-label-color">
                          Drug Form <span style={{ color: "red" }}> *</span>
                        </h3>
                        <Autocomplete
                          autoHighlight={true}
                          value={
                            AllTextFieldState &&
                            AllTextFieldState.drugForm
                          }
                          onChange={(e, v) =>
                            handleDropDownChange("drugForm", v)
                          }
                          getOptionLabel={(option) => (option ? option : "")}

                          id="city-list-option"
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          options={drugFormList ? drugFormList : []}
                          sx={{ width: 360 }}
                          renderInput={(params) => (
                            <TextField
                              data-testid = "drugForm"
                              variant="standard"
                              {...params}
                              label=""
                              error={isError.isDrugForm === true}
                              helperText={
                                isError.isDrugForm
                                  ? "Valid (Drug Form) Required !"
                                  : ""
                              }

                            />
                          )}
                        />
                      </div>

                      <div className="mt-5 pl-5 OrgNameContainer ">
                        <h3 className="font-normal text-o-form-label-color">
                          Route <span style={{ color: "red" }}> *</span>
                        </h3>
                        <Autocomplete
                        multiple
                          autoHighlight={true}
                          value={
                            AllTextFieldState &&
                            AllTextFieldState.route
                          }
                          onChange={(e, v) =>
                            handleDropDownChange("route", v)
                          }
                          getOptionLabel={(option) => (option ? option : "")}

                          id="city-list-option"
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          options={medicineRouteList ? medicineRouteList : []}
                          sx={{ width: 360 }}
                          renderInput={(params) => (
                            <TextField
                              data-testid = "route"
                              variant="standard"
                              {...params}
                              label=""
                              error={isError.isRoute === true}
                              helperText={
                                isError.isRoute
                                  ? "Valid (Route) Required !"
                                  : ""
                              }

                            />
                          )}
                        />
                      </div>

                    {!AllTextFieldState.is_update && 
                      <div className="mt-5 pl-5 OrgNameContainer">
                        <h3
                          className="font-normal text-o-form-label-color"
                          id="Org"
                        >
                          <FormControlLabel onChange={switchControlAction} control={<Switch checked={isCombinationDrug} />} label="Combination Drug" />
                        </h3>


                      </div>
                    }
                      <div className="mt-5 pl-5 OrgNameContainer">
                        <h3
                          className="font-normal text-o-form-label-color"
                          id="Org"
                        >
                          Strength<span style={{ color: "red" }}> *</span>
                        </h3>
                        <TextField
                          data-testid = "strength"
                          onFocus={(e) => resetErrorHandler(e)}
                          autoComplete="OFF"
                          error={isError.isStrength === true}
                          disabled={AllTextFieldState.is_update}
                          helperText={
                          AllTextFieldState.is_update===false  && isError.isStrength
                              ? "Valid (Strength) Required !"
                              : ""
                          }
                          onChange={(e) => whenInputsChange(e)}
                          name="displayStrength"
                          value={AllTextFieldState.displayStrength}
                          style={{ width: "360px" }}
                          id="standard-basic"
                          label=""
                          variant="standard"
                        />


                      </div>
                      {!isCombinationDrug &&
                        <div className="mt-5 pl-5 OrgNameContainer ">
                          <h3 className="font-normal text-o-form-label-color">
                            Strength UOM <span style={{ color: "red" }}> *</span>
                          </h3>
                          <Autocomplete
                            autoHighlight={true}
                            disabled={AllTextFieldState.is_update}
                            value={
                              AllTextFieldState &&
                              AllTextFieldState.strengthUOM
                            }
                            onChange={(e, v) =>
                              handleDropDownChange("strengthUOM", v)
                            }
                            getOptionLabel={(option) => (option ? option : "")}

                            id="city-list-option"
                            onKeyDown={(e) => {
                              e.key === "Enter" && e.preventDefault();
                            }}
                            options={strengthUOMList ? strengthUOMList : []}
                            sx={{ width: 360 }}
                            renderInput={(params) => (
                              <TextField
                              data-testid = "strengthUOM"
                                variant="standard"
                                {...params}
                                label=""
                                error={isError.isDisplayStrength === true}
                                helperText={
                                  AllTextFieldState.is_update===false  && isError.isDisplayStrength
                                    ? "Valid (strengthUOM) Required !"
                                    : ""
                                }

                              />
                            )}
                          />
                        </div>
                      }
                      <div className="mt-5 pl-5 OrgNameContainer">
                        <h3
                          className="font-normal text-o-form-label-color"
                          id="Org"
                        >
                          Site
                        </h3>
                        <Autocomplete
                          autoHighlight={true}
                          value={
                            AllTextFieldState &&
                            AllTextFieldState.site
                          }
                          onChange={(e, v) =>
                            handleDropDownChange("site", v)
                          }
                          getOptionLabel={(option) => (option ? option : "")}

                          id="city-list-option"
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          options={siteList ? siteList : []}
                          sx={{ width: 360 }}
                          renderInput={(params) => (
                            <TextField
                              data-testid = "site"
                              variant="standard"
                              {...params}
                              label=""
                            />
                          )}
                        />
                      </div>


                      {AllTextFieldState.is_update === true ? (
                        <>

                          {/* <div className="mt-5 pl-5 OrgNameContainer ">
                          <h3 className="font-normal text-o-form-label-color">
                            Status
                          </h3>
                          <Autocomplete
                            autoHighlight={true}
                            value={
                              AllTextFieldState &&
                              AllTextFieldState.status
                            }
                            onChange={(e, v) =>
                              handleDropDownChange("status", v)
                            }
                            getOptionLabel={(option) => (option ? option : "")}
                            id="city-list-option"
                            onKeyDown={(e) => {
                              e.key === "Enter" && e.preventDefault();
                            }}
                            options={statuslist ? statuslist : []}
                            sx={{ width: 360 }}
                            renderInput={(params) => (
                              <TextField
                                variant="standard"
                                {...params}
                                label=""
                              />
                            )}
                          />
                        </div> */}
                        </>
                      ) : (
                        ""
                      )}


                      <div className="mt-5  OrgNameContainer ">
                        {/* <div className="progressContainer mt-5 flex justify-center items-center">
                          {createResponse.isError ||
                            (updateResponse.isError && showerror === true) ? (
                            <Alert severity="error" color="warning">
                              {createResponse.error &&
                                createResponse.error.data &&
                                AllTextFieldState.is_update === false
                                ? createResponse.error.data.message
                                : updateResponse.error &&
                                updateResponse.error.data &&
                                updateResponse.error.data.message}
                            </Alert>
                          ) : createResponse.isLoading ||
                            updateResponse.isLoading ? (
                            <CircularProgress />
                          ) : (
                            ""
                          )}
                        </div> */}
                        <div className="mt-5 flex justify-center   towButtonContainer h-40">
                          <Button
                            onClick={hideForm}
                            type="submit"
                            variant="contained"
                            disableElevation
                            style={{
                              marginRight: "10px",
                              borderColor: "#0F4391",
                              width: "99px",
                              height: "39px",
                              borderRadius: "9999px",
                              backgroundColor: "#E8FAFF",
                              color: "#0F4391",
                              border: "1px solid #0F4391",
                            }}
                          >
                            CANCEL
                          </Button>

                          <Button
                            type="submit"
                            variant="contained"
                            disableElevation
                            onClick={SubmitHandler}
                            style={{
                              borderColor: "#0F4391",
                              width: "99px",
                              height: "39px",
                              borderRadius: "9999px",
                              backgroundColor: "#E8FAFF",
                              color: "#0F4391",
                              border: "1px solid #0F4391",
                            }}
                          >
                            SAVE
                          </Button>
                        </div>
                      </div>
                    </form>
                  </>
                </div>
              </div>
            </Grid>
          ) : (
            ""
          )}
        </Grid>
      </div>
      <AdminCookieConsentBanner />
    </>
  );

}

export default MedicineMaster;