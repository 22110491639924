import * as React from "react";
import { useState, useEffect, useRef } from "react";
import { Grid } from "@mui/material";
import "../table.css";
import Pagination from "@mui/material/Pagination";
import "react-toastify/dist/ReactToastify.css";
import Stack from "@mui/material/Stack";

const MessageTemplateList = (props) => {
  const {
    hasFocus,
    value,
    handlePageChange,
    id,
    setId,
    handleRowClick,
    currentPage,
    setCurrentPage
  } = props;



  const handleClick = (row, i) => {
      let data = {};
      data.is_update = true;
      data.value = row.value;
      handleRowClick(row);
      setId(row.templateId);
    
  };

  const handleOnChange = (event, page) => {
    setCurrentPage(page);
    handlePageChange(page);
  };

  const itemsPerPage = 20;
  const data = Array.isArray(props.data) ? props.data : [];
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentData = data.slice(startIndex, endIndex);

  return (
    <Grid className="">
      <div class="scrollit">
        {props && props?.data && props?.data?.length > 0 ? (
          <table
            className="b "
            style={{
              width: "90%",
              marginLeft: "20px",
              tableLayout: "fixed",
              height: "20vh",
            }}
          >
            <thead style={{ position: "sticky", zIndex: "1", top: "0" }}>
              <tr>
                <th className=" m-r-f" style={{ width: '50px' }}><input type="checkbox" id="" name="vehicle1" value="Bike" /></th>
                <th className=" m-r-f">Template Id</th>
                <th className=" m-r-f">Template Name</th>
                <th className=" m-r-f">Status</th>
              </tr>
            </thead>
            <tbody>
              {currentData.map((item, i) => (
                <tr
                  key={i}
                  className="tablebor"
                  style={{
                    cursor: "pointer",
                    backgroundColor:
                      props?.id === item.templateId ? "rgb(15, 67, 145)" : "",
                    color: props?.id === item.templateId ? "white" : "",
                  }}
                >
                  <td><input type="checkbox" /></td>
                  <td className="  m-r-f" onClick={(event) => handleClick(item)}><a>{item.templateId}</a></td>
                  <td className="  m-r-f" onClick={(event) => handleClick(item)}><a>{item.templateName}</a></td>
                  <td className=" m-r-f" onClick={(event) => handleClick(item)}>
                    <span className={item.status === "ACTIVE" ? "dotg" : "dotr"}></span>{" "}
                    {item.status === "ACTIVE" ? " Active" : " Inactive"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className="record">No Records Found</div>
        )}
      </div>
      {props && props.data && props.data.length > 0 && (
        <div className="pagesam pt-1 m-1">
          <Stack spacing={2}>
            <Pagination
              count={Math.ceil(props.data.length / itemsPerPage)}
              color="primary"
              page={currentPage}
              onChange={handleOnChange}
            />
          </Stack>
          <div className="pagenum">
            <span className="ml-2">
              {startIndex + 1}-{Math.min(endIndex, props.data.length)} of{" "}
              {props.data.length}
            </span>
          </div>
        </div>
      )}
    </Grid>
  );
};

export default MessageTemplateList;
