import * as React from 'react';
import { useState, useEffect, useRef } from "react";
import { Grid } from '@mui/material';
import "../table.css"
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';



const TaskResolutionlist = (props) => {
    const handleClick = (row) => {
        let data = {};
        data.is_update = true;
        data.taskResolution = row.taskResolution;
        data.taskType=row.taskType;
        data.taskResolutionDetails = row.taskResolutionDetails;
        data.status = row.status === "DELETED" ? "INACTIVE" : "ACTIVE";
        props.setId(row.taskResolutionId);
        props.setTextFieldState({
          ...data,
          is_update: true,
          lastModifiedTime: row.lastModifiedTime,
        });
        props.getUserByIdFunct(row.lastModifiedBy, row.taskResolutionId);
        props.showFormActions();
      };
    
      // const handleOnChange = (event, page) => {
      //   props.getPageData(page);
      // };
      const handleOnChange = (event, page) => {
        props.getTsakresolutionPage(page)
        props.getPageData(page);
    
      };
    
      return (
        <Grid className="">
          <div class="scrollit">
            {props.taskresolutionview===true && props.taskList.length > 0 ? (
              <table
                className="b "
                style={{
                  width: "90%",
                  marginLeft: "20px",
                  tableLayout: "fixed",
                  height: "20vh",
                }}
              >
                <thead style={{ position: "sticky", zIndex: "1", top: "0" }}>
                  <tr>
                    <th className=" m-r-f" style={{ width: "50px" }}>
                      <input type="checkbox" id="" name="vehicle1" value="Bike" />
                    </th>
                    <th className=" m-r-f">Task Resolution Name</th>
                    <th className=" m-r-f">Usage</th>
                    <th className=" m-r-f">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {props.taskList &&
                    props.taskList.length > 0 &&
                    props.taskList.map((item, i) => {
                      return (
                        <tr
                          key={i}
                          className="tablebor"
                          style={{
                            cursor: "pointer",
                            backgroundColor:
                              props.id === item.taskResolutionId
                                ? "rgb(15, 67, 145)"
                                : "",
                            color: props.id === item.taskResolutionId ? "white" : "",
                          }}
                        >
                          <td>
                            <input type="checkbox" id={i} />
                          </td>
                          <td
                            className="  m-r-f"
                             onClick={(event) => handleClick(item)}
                            style={{ cursor: "pointer" }}
                          >
                            <a>{item.taskResolution}</a>
                          </td>
                          <td
                            className="  m-r-f"
                             onClick={(event) => handleClick(item)}
                          >
                            {item.taskResolutionDetails}
                          </td>
                          <td
                            className=" m-r-f"
                             onClick={(event) => handleClick(item)}
                          >
                            <span
                              className={item.status === "ACTIVE" ? "dotg" : "dotr"}
                            ></span>{" "}
                            {item.status === "ACTIVE" ? " Active" : " Inactive"}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            ) : (
              <div className="record">No Records Found</div>
            )}
          </div>
          { props.taskresolutionview===true && props.taskList && props.taskList && props.taskList.length>0 &&
          <div className="pagesam pt-1 m-1">
            <Stack spacing={2}>
          <Pagination count={props?.totalPage} color="primary" page={props.currentPage} onChange={(e, page) => handleOnChange(e, page)} />
        </Stack>
        <div className='pagenum'>
          <span className='ml-2'>{props?.calculateData?.number * 20 + 1}-{props?.calculateData?.number * 20 + props?.calculateData?.numberOfElements} of {props?.calculateData?.totalElements}</span>
        </div>
          </div>
          }
        </Grid>
      );
    };
   


export default TaskResolutionlist;
