import { useState, useEffect} from "react";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import TextField from "@mui/material/TextField";
import HeaderFooterLogic from "./headerFooterLogic";
import HeaderFooterList from "./headerFooterList";
import Autocomplete from "@mui/material/Autocomplete";
import { Button } from "@mui/material";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Alert from '@mui/material/Alert';

import {
  useFilterLocationDataMutation,
  useGetencountertypeslistMutation,
  useGetHeaderFooterListMutation,
  useGetLocationsByIdMutation,
  useCreateHeaderFooterMutation,
  useDeleteheaderfooterMutation
} from "../../../services/adminLogIn";
import filter from "../../../images/filter.png";
import "../Organization/Mainhome.css";
import CircularProgress from "@mui/material/CircularProgress";
import { Grid } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import Breadcrums from "../../global/breadcrums/Breadcrums";
import { toasterFunction } from "../../../globalFunctions/getLocalStorageData";
import { ToastContainer, toast } from "react-toastify";
import AdminCookieConsentBanner from "../home/AdminCookieConsentBanner";
import "react-toastify/dist/ReactToastify.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)", 
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const HeaderFooter = (props) => {
  const [getLocationList] = useFilterLocationDataMutation();
  const [createHeaderFooter] = useCreateHeaderFooterMutation();
  const [deleteApi] =useDeleteheaderfooterMutation ();
  const [getEncounterTypeListData] = useGetencountertypeslistMutation();
  const [getHeaderFooterList] = useGetHeaderFooterListMutation();
  const [getlocationdetailbyids] =  useGetLocationsByIdMutation();
  const [access, setAccess] = useState(
    JSON.parse(sessionStorage.getItem("access"))
  );
  const [data1, setdata1] = useState({});
  const [data3, setdata3] = useState([]);
  const [isProfilePhotoAction, setIsProfilePhotoAction] = useState(false);
  const [isNewData, setIsNewData] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [open, setOpen] = useState(false);
  const [indexvalue, setIndex] = useState(null);
  const [locationMultiSelectOptions, setLocationMultiSelectOptions] = useState(
    []
  );
  const [headerFooterData, setHedaerFooterData] = useState([]);
  const [searchVal, setsearchVal] = useState("");
  const [sampleReqRecordsLoading, setSampleReqRecordsLoading] = useState(false);


  const [calculateData, setTotalPageCalculate] = useState({
    number: "",
    numberOfElements: "",
    totalElements: "",
    showcount: "",
  });

  const [paginationdata, setPaginationData] = useState({
    string: "",
    page: 0,
    size: 20,
  });
  const [isImagePreview, setIsImagePreview] = useState(false);
  const [isSelected, setIselected] = useState();
  const {
    setDropLocState,
    dropLocState,
    AllTextFieldState,
    setAllTextFieldState,
    whenInputsChange,
    handleDropDownChange,
    headerFooterErrorstates,
    setHeaderFooterErrorStates,
  } = HeaderFooterLogic();
  const [isFormVisible, setFormVisible] = useState({
    isOpen: false,
    isClose: true,
  });
  const [id, setId] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [currentRow, setCurrentRow] = useState();


  const handleDelete =(item)=> {
    setModalOpen(true);
    setCurrentRow(item)
  }
  const handleModalClose = () => {
    setModalOpen(false);
  }
  const handleProceed = async () => { 
    const res = await deleteApi(currentRow.reportTypeId);
    if(res?.error?.data?.message){
      toast(res?.error?.data?.message, {
        position: "top-center",
        autoClose: 2000,
  hideProgressBar: true,
        type: "error",
        closeOnClick: true,
        theme: "light",
      });
    
     
    }
    else{
      toast("Header and footer Deleted Successfully", {
        position: "top-center",
        autoClose: 2000,
  hideProgressBar: true,
        type: "success",
        closeOnClick: true,
        theme: "light",
      });
      
    }
  setModalOpen(false);
  hideForm();

}
  const showFormActions = () => {
    if (access?.sampleadd == true) {
      setId("");
      if (!isFormVisible.isOpen) {
        setFormVisible((prev) => ({
          ...prev,
          isOpen: (isFormVisible.isOpen = true),
          isFormVisible,
          isClose: (isFormVisible.isClose = false),
        }));
        if (AllTextFieldState.is_update === false) {
          setId("");
        }
      }
    } else {
      toasterFunction(" No Permission", "error");
    }
  };
  const callListLocDropdownData = async (value) => {
    const payload = {
      locName: value,
      partOf: "",
      managingOrg: "",
      state: "",
      city: "",
      status: "",
      page: 0,
      size: 100,
    };
    const res = await getLocationList(payload);
    if (res?.data?.content) setdata1(res);
    
  };

  const handleLocDropDownSearch = (e, value) => {
    callListLocDropdownData(value);
  };
  const getEncounterTypeList = async (encounterTypeName) => {
    setSampleReqRecordsLoading(true)
    const res = await getEncounterTypeListData(encounterTypeName);
    if (res?.data) setdata3(res);
    setSampleReqRecordsLoading(false)
  };
  const headerFooterList = async (data) => {
    const res = await getHeaderFooterList(data);
    if(res?.data) setHedaerFooterData(res?.data);
    setTotalPage(res?.data?.totalPages)
      setTotalPageCalculate({ numberOfElements: res?.data?.numberOfElements, number: res?.data?.number, totalElements: res?.data?.totalElements })
  }

  const handlePageChange = (page) => {
    const paginationData = {
        string: "",
        page: page-1,
        size: 20
    }
    headerFooterList(paginationData);
  }
  

  const handleClose = (item, index) => {
    setOpen(false);
    setIndex(item);
  };
  const hideForm = () => {
    setAllTextFieldState({
      is_update:false,
      isActive:true,
      encounterTypeId: [],
      locationIds:[],
      headerImgUrl: "",
      footerImgUrl: "",
      documentProperties: "",
      documentTemplate: "",
      participantRoles: "",
     
        
      });
      if (isFormVisible.isOpen) {
        setFormVisible((prev) => ({
          ...prev,
          isOpen: (isFormVisible.isOpen = false),
          isFormVisible,
          isClose: (isFormVisible.isClose = true),
        }));
      }
      setsearchVal("")
      setCurrentPage(1)
      setId("")
      headerFooterList({
        string: "",
        page: 0,
        size: 20
    })
    setIsImagePreview(false);
    setHeaderFooterErrorStates({
      locationError: false,
      encounterTypeError: false,
      imageUrlError: false,
      ispline1Error: false,
      ispline2Error: false,
      ispline3Error: false,
  })
  };
 
  const [totalPage, setTotalPage] = useState(0);

  const handleRowClick = async(item) => {
    setIsImagePreview(false);
    setSampleReqRecordsLoading(true)
    setIselected(id);
    const locresult = await getlocationdetailbyids(item.locationIds);

    let data1 = item?.documentProperties?.footerText

    let data2 = data1.replace("fonts/Montserrat-SemiBold.ttf~10~20~THIS IS A COMPUTER GENERATED DOCUMENT~BOTTOM~1~0~0~10#@#fonts/Montserrat-Regular.ttf~9~15~","")
    let data4 =data2.split("#@#fonts/Montserrat-Regular.ttf~9~15~")
    const leftParts = data4.map(item => {
      const [leftPart] = item.split("#@@#");
      return leftPart.trim();
    });
   
      setAllTextFieldState((prev) => ({
        ...prev,
        ["locationIds"]:locresult && locresult?.data &&  locresult?.data?.length!=0 ? [...locresult?.data?.map(({name, id}) => ({name, id}))] : [],
        ["encounterTypes"]:{...data3?.data?.find((obj) => obj?.encounterId === item?.encounterTypeId)},
        ["imageUrl"]:item?.headerImgUrl,
        ["reportTypeId"]:item?.reportTypeId,
        ["pline1"]:leftParts[0],
        ["pline2"]:leftParts[1],
        ["pline3"]:"health@karkinos.in | Ph:82684 82684| www.karkinos.in",
        ["is_update"]:true


    }))

    setFormVisible((prev) => ({
      ...prev,
      isOpen: (isFormVisible.isOpen = true),
      isFormVisible,
      isClose: (isFormVisible.isClose = false),
    }));
    setSampleReqRecordsLoading(false)
  }
  const showImagePreview = () => {
    setIsImagePreview(true);
  }

  const orgSubmitHandler = async (e) => {
    e.preventDefault();
        const sendHeaderDetails = {
                is_update:false,
                isActive: true,
                locationIds: AllTextFieldState.locationIds.map((item) => item.id),
                encounterTypeId:AllTextFieldState?.encounterTypes?.encounterId,
                footerImgUrl: AllTextFieldState.footerImgUrl,
                headerImgUrl: AllTextFieldState.imageUrl,
                pline1: AllTextFieldState.pline1,
                pline2: AllTextFieldState.pline2,
                pline3: "health@karkinos.in | Ph:82684 82684| www.karkinos.in",
                kline1: AllTextFieldState.kline7,
                kline2: AllTextFieldState.kline8,
                kline3: AllTextFieldState.kline9,
                documentProperties:AllTextFieldState.documentProperties,
                documentTemplate: AllTextFieldState.documentTemplate,
                participantRoles: AllTextFieldState.participantRoles,
               
            }
            const validUrl = /^(ftp|http|https):\/\/[^ "]+$/.test(sendHeaderDetails.headerImgUrl);
            if (
              sendHeaderDetails.locationIds.length === 0 &&
              (sendHeaderDetails.encounterTypeId === "" ||
                sendHeaderDetails.encounterTypeId === undefined) &&
                (sendHeaderDetails.pline1 === "" ||
                sendHeaderDetails.plin1 === undefined) &&
                (sendHeaderDetails.pline2 === "" ||
                sendHeaderDetails.pline2 === undefined) &&
                (sendHeaderDetails.pline3 === "" ||
                sendHeaderDetails.pline3 === undefined) &&
              (sendHeaderDetails.imageUrl === "" ||
                sendHeaderDetails.imageUrl === undefined)
            ) {
              setHeaderFooterErrorStates({
                locationError: true,
                encounterTypeError: true,
                imageUrlError: true,
                ispline1Error: true,
                ispline2Error: true,
                ispline3Error: true
              });
            } else if (sendHeaderDetails.locationIds.length === 0) {
              setHeaderFooterErrorStates((prev) => ({ ...prev, locationError: true }));
            } else if (
              sendHeaderDetails.encounterTypeId === "" ||
              sendHeaderDetails.encounterTypeId === undefined
            ) {
              setHeaderFooterErrorStates((prev) => ({
                ...prev,
                encounterTypeError: true,
              }));
              
            }
            else if (
              sendHeaderDetails.pline1 === "" ||
              sendHeaderDetails.pline1 === undefined
            ) {
              setHeaderFooterErrorStates((prev) => ({
                ...prev,
                ispline1Error: true,
              }));
            }
            else if (
              sendHeaderDetails.pline2 === "" ||
              sendHeaderDetails.pline2 === undefined
            ) {
              setHeaderFooterErrorStates((prev) => ({
                ...prev,
                ispline2Error: true,
              }));
            }
            else if (
              sendHeaderDetails.pline3 === "" ||
              sendHeaderDetails.pline3 === undefined
            ) {
              setHeaderFooterErrorStates((prev) => ({
                ...prev,
                ispline3Error: true,
              }));
            } else if (
              sendHeaderDetails.headerImgUrl === "" ||
              sendHeaderDetails.headerImgUrl === undefined ||
              !validUrl
            ) {
              setHeaderFooterErrorStates((prev) => ({ ...prev, imageUrlError: true }));
            } else {
 
            
        if (AllTextFieldState.is_update === false) {
           const res =  await createHeaderFooter(sendHeaderDetails)
           if(res?.data?.reportTypeId){
            toasterFunction("Created successfully", "success");
            hideForm()
           }
           else{
            toasterFunction("Something went wrong", "error");
           }
           
           
          
        }
        else {
          const sendHeaderDetailsForUpdate = {
            is_update:true,
            isActive: true,
            locationIds: AllTextFieldState.locationIds.map((item) => item.id),
            encounterTypeId:AllTextFieldState?.encounterTypes?.encounterId,
            footerImgUrl: AllTextFieldState.footerImgUrl,
            headerImgUrl: AllTextFieldState.imageUrl,
            pline1: AllTextFieldState.pline1,
            pline2: AllTextFieldState.pline2,
            pline3: "health@karkinos.in | Ph:82684 82684| www.karkinos.in",
            kline1: AllTextFieldState.kline7,
            kline2: AllTextFieldState.kline8,
            kline3: AllTextFieldState.kline9,
            documentProperties:AllTextFieldState.documentProperties,
            documentTemplate: AllTextFieldState.documentTemplate,
            participantRoles: AllTextFieldState.participantRoles,
            reportTypeId: AllTextFieldState.reportTypeId
           
        }
          const res = await createHeaderFooter(sendHeaderDetailsForUpdate)
          if(res?.data?.reportTypeId){
            toasterFunction("Update successfully", "success");
           }
           else{
            toasterFunction("Something went wrong", "error");
           }
        }
      
        hideForm()
      }
}
const onSearchevent = async (value) => {
 
  setsearchVal(value);
  
    if(value.length != 0){
    const res = await getHeaderFooterList({ string: value.toUpperCase(),page : 0,size:20  });
    if (res?.data) {
      setCurrentPage(1)
      setHedaerFooterData(res?.data)
    }
    else{
      setHedaerFooterData([])
    }
  }
  else{
    headerFooterList()
  }
 
}


  useEffect(() => {
    callListLocDropdownData("");
    getEncounterTypeList("");
    headerFooterList(paginationdata);
  }, []);

  return (
    <>
       {sampleReqRecordsLoading ? (
        <div style={{ position: "fixed", left: "50%", top: "50%" }}>
          <CircularProgress />
        </div>
      ) : (
      <div className={props.expand1.empty === true ? "main1" : "main"}>
         <div className="breadCumContainer flex justify-start items-center h-9 w-full bg-o-color-one organ1">
          <ol className="ml-8 flex " style={{ listStyleType: "none" }}>
            <div className="flex mt-1">
              <li className="w-28 h-thirtyPix rounded-mysix flex justify-center items-center font-medium bg-white m-r-f orgbox">
                <span>Categories</span>
              </li>
            </div>
            <i className="right"></i>
            <div className="flex mt-1">
              <li className="w-28 h-thirtyPix rounded-mysix flex justify-center items-center font-medium bg-white m-r-f orgbox rows">
                <span>Clinical</span>
              </li>
            </div>
            <i className="right"></i>
            <div className="flex mt-1">
              <li className="w-32 h-thirtyPix rounded-mysix flex justify-center items-center font-medium bg-white m-r-f orgbox rows breadback">
                <span>Header and footer</span>
              </li>
            </div>
          </ol>
        </div>
       <ToastContainer/>
        <Grid container mt={-2}>
          <Grid item xs={6}>
            <div
              className={
                isFormVisible.isOpen
                  ? "leftcontainer rounded-lg"
                  : "leftcontainer1 rounded-lg"
              }
            >
              <Grid className=" p-7 ">
                <TextField
                  label=""
                  onChange={(e) => onSearchevent(e.target.value)}
                  onKeyDown={(e) => {
                    e.key === "Enter" && e.preventDefault();
                  }}
                  sx={{ m: 1, width: "25ch" }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchOutlinedIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid className="editContainer rounded-radiusFourPix ">
                <Grid
                  disabled={access?.sampleadd == false}
                  onClick={showFormActions}
                  className={isFormVisible.isOpen ? "addIconchange" : "addIcon"}
                >
                  <span className=" p-2 m-r-f ">Add</span>
                  <AddCircleOutlineIcon
                    style={{
                      fontSize: "14px",
                      color: "#545454",
                      marginBottom: "3px",
                      color: `${isFormVisible.isOpen ? "#ffffff" : "#545454"}`,
                    }}
                  />
                </Grid>

                {/* <Grid className="Addaction">
                    <span className="p-2 m-r-f">Actions</span>
                    <AddCircleOutlineIcon style={{ fontSize: '14px', color: '#545454', 'marginBottom': '3px' }} />

                  </Grid> */}
                <Grid className="addIcon">
                  <span className="p-2 m-r-f">Filter</span>
                  <img
                    src={filter}
                    alt="filter.png"
                    style={{
                      position: "absolute",
                      left: "90%",
                      right: "20px",
                      top: "15px",
                      bottom: "95px",
                      width: "11px",
                      paddingTop: "3px",
                    }}
                  ></img>
                </Grid>
              </Grid>
              <>
                <HeaderFooterList
                  setTotalPage={setTotalPage}
                  totalPage={totalPage}
                  setId={setId}
                  id={id}
                  showFormActions={showFormActions}
                  locationMultiSelectOptions={locationMultiSelectOptions}
                  calculateData={calculateData}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  sampleview={access?.sampleview}
                  sampleupdate={access?.sampleupdate}
                  data={headerFooterData}
                  handleRowClick={handleRowClick}
                  handlePageChange={handlePageChange}
                  handleDelete={handleDelete}
                  isSelected={isSelected}
                />
              </>
              
                  <Modal
                          open={modalOpen}
                          onClose={handleModalClose}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                        >
                          <Box sx={style} style = {{width: "400px"}}>
                            <Typography
                              id="modal-modal-title"
                              variant="h6"
                              component="h2"
                            >
                              <Alert severity="info">
                              {"Are you sure you want to delete the Header and Footer? This activity will delete the headerand footer from the master list which can't be resolved? Do you wish to continue?"}
                              </Alert>
                            </Typography>
                            <Typography
                              id="modal-modal-description"
                              sx={{ mt: 2 }}
                            >
                              <div className="mt-10 flex justify-center items-center  towButtonContainer">
                                <Button
                                  type="submit"
                                  onClick={handleModalClose}
                                  variant="contained"
                                  disableElevation
                                  style={{
                                    marginRight: "10px",
                                    borderColor: "#0F4391",
                                    width: "99px",
                                    height: "39px",
                                    borderRadius: "9999px",
                                    backgroundColor: "#E8FAFF",
                                    color: "#0F4391",
                                    border: "1px solid #0F4391",
                                    marginBottom: "100px",
                                  }}
                                >
                                  {"CANCEL"}
                                </Button>

                                <Button
                                  type="submit"
                                  onClick={handleProceed}
                                  variant="contained"
                                  disableElevation
                                  style={{
                                    borderColor: "#0F4391",
                                    width: "99px",
                                    height: "39px",
                                    borderRadius: "9999px",
                                    backgroundColor: "#E8FAFF",
                                    color: "#0F4391",
                                    border: "1px solid #0F4391",
                                    marginBottom: "100px",
                                  }}
                                >
                                  {"PROCEED"}
                                </Button>
                              </div>
                            </Typography>
                          </Box>
                          </Modal>
              
            </div>
          </Grid>

          {isFormVisible.isOpen ? (
            <Grid item xs={6}>
              <div className="rightcontainer rounded-lg">
                <div className="overflow-y-auto o-form-container">
                  <>
                    <div className="font-normal text-o-form-label-color mainhead flex justify-between items-center pl-5 pr-1 h-10">
                      <h1>Header and Footer Overview</h1>
                    </div>

                    <form autoComplete="off" onSubmit={orgSubmitHandler}>
                      <div className="mt-5 pl-5 OrgNameContainer ">
                        <h3 className="font-normal text-o-form-label-color">
                          Location Name <span style={{ color: "red" }}> *</span>
                        </h3>
                        <Autocomplete
                          multiple
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          value={
                            AllTextFieldState&&
                            AllTextFieldState.locationIds &&
                            AllTextFieldState.locationIds
                          }
                          autoHighlight={true}
                          onChange={(e, v) =>
                            handleDropDownChange("locname", v, e)
                          }
                          onInputChange={(e, v, value) =>
                            handleLocDropDownSearch(e, v, value)
                          }
                          getOptionLabel={(option) =>
                            option.name ? option.name : ""
                          }
                          id="loc-list-option"
                          options={
                            data1 &&
                            data1?.data &&
                            data1?.data?.content &&
                            data1?.data?.content?.length > 0
                              ? data1?.data?.content
                              : []
                          }
                          sx={{ width: 300 }}
                          renderInput={(params) => (
                            <TextField
                              variant="standard"
                              error={
                                headerFooterErrorstates.locationError === true
                              }
                              helperText={
                                headerFooterErrorstates.locationError
                                  ? "Valid (Location) Required !"
                                  : ""
                              }
                              sx={{ width: 358 }}
                              {...params}
                              label=" "
                            />
                          )}
                        />
                      </div>

                      <div className="mt-5 pl-5 OrgNameContainer ">
                        <h3 className="font-normal text-o-form-label-color">
                          Encounter Types{" "}
                          <span style={{ color: "red" }}> *</span>
                        </h3>
                        <Autocomplete
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          value={
                            AllTextFieldState&&
                            AllTextFieldState?.encounterTypes &&
                            AllTextFieldState?.encounterTypes
                          }
                          autoHighlight={true}
                          onChange={(e, v) =>
                            handleDropDownChange("encname", v, e)
                          }
                          getOptionLabel={(option) =>
                            option.name ? option.name : ""
                          }
                          id="loc-list-option"
                          options={
                            data3 && data3?.data && data3?.data?.length > 0
                              ? data3?.data
                              : []
                          }
                          sx={{ width: 300 }}
                          renderInput={(params) => (
                            <TextField
                              variant="standard"
                              error={
                                headerFooterErrorstates.encounterTypeError === true
                              }
                              helperText={
                                headerFooterErrorstates.encounterTypeError
                                  ? "Valid (Encounter Type) Required !"
                                  : ""
                              }
                              sx={{ width: 358 }}
                              {...params}
                              label=" "
                            />
                          )}
                        />
                      </div>
                      <div className="mt-5 pl-5 OrgNameContainer">
                          <h3
                            className="font-normal text-o-form-label-color"
                            id="COLOR"
                          >
                            Image url<span style={{ color: "red" }}> *</span>
                            {!headerFooterErrorstates.imageUrlError &&
                            AllTextFieldState.imageUrl ? (
                              <Button
                                onClick={showImagePreview}
                                variant="contained"
                                disableElevation
                                style={{
                                  marginRight: "10px",
                                  borderColor: "#0F4391",
                                  width: "99px",
                                  height: "30px",
                                  borderRadius: "9999px",
                                  backgroundColor: "#E8FAFF",
                                  color: "#0F4391",
                                  border: "1px solid #0F4391",
                                 marginLeft:"10px"
                                }}
                              >
                                Preview
                              </Button>
                            ) : null}
                          </h3>
                          <TextField
                            type="url"
                            onKeyDown={(e) => {
                              e.key === "Enter" && e.preventDefault();
                            }}
                            autoComplete="OFF"
                            onChange={(e) => whenInputsChange(e)}
                            name="imageUrl"
                            value={AllTextFieldState.imageUrl}
                            style={{ width: "360px" }}
                            id="standard-basic"
                            label=""
                            variant="standard"
                            error={headerFooterErrorstates.imageUrlError}
                            helperText={
                              headerFooterErrorstates.imageUrlError
                                ? "Valid (Image Url) Required !"
                                : ""
                            }
                          />
                          {isImagePreview ? (
                            <img
                              src={AllTextFieldState.imageUrl}
                              alt="image-preview"
                              style = {{marginTop:"20px", marginBottom:"20px", width:"90%"}}
                            />
                          ) : null}
                        </div>

            
                      <div className="mt-5 pl-5 OrgNameContainer">
                        <h3
                          className="font-normal text-o-form-label-color b"
                          id="COLOR"
                        >
                          Footer Details :
                        </h3>
                        <h3
                          className="font-normal text-o-form-label-color mt-2"
                          id="COLOR"
                        >
                          Partner Details <span style={{ color: "red" }}> *</span>
                        </h3>
                        <TextField
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          autoComplete="OFF"
                          name="pline1"
                          onChange={(e) => whenInputsChange(e)}
                          value={AllTextFieldState.pline1}
                          style={{ width: "360px", marginTop: "10px" }}
                          id="standard-basic"
                          label=""
                          variant="standard"
                          error={
                            headerFooterErrorstates.ispline1Error === true
                          }
                          helperText={
                            headerFooterErrorstates.ispline1Error
                              ? "Valid (Encounter Type) Required !"
                              : ""
                          }
                        />
                        <TextField
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          autoComplete="OFF"
                          name="pline2"
                          onChange={(e) => whenInputsChange(e)}
                          value={AllTextFieldState.pline2}
                          style={{ width: "360px", marginTop: "10px" }}
                          id="standard-basic"
                          label=""
                          variant="standard"
                          error={
                            headerFooterErrorstates.ispline2Error === true
                          }
                          helperText={
                            headerFooterErrorstates.ispline2Error
                              ? "Valid (Encounter Type) Required !"
                              : ""
                          }
                        />
                        <TextField
                        disabled
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          autoComplete="OFF"
                          name="pline3"
                          onChange={(e) => whenInputsChange(e)}
                          value={"health@karkinos.in | Ph:82684 82684| www.karkinos.in"}
                          style={{ width: "360px", marginTop: "10px" }}
                          id="standard-basic"
                          label=""
                          variant="standard"
                          error={
                            headerFooterErrorstates.ispline3Error === true
                          }
                          helperText={
                            headerFooterErrorstates.ispline3Error
                              ? "Valid (Encounter Type) Required !"
                              : ""
                          }
                        />
                        <TextField
                          disabled
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          autoComplete="OFF"
                          name="pline4"
                          value={AllTextFieldState.pline4}
                          style={{ width: "360px", marginTop: "10px" }}
                          id="standard-basic"
                          label=""
                          variant="standard"
                        />
                        <TextField
                          disabled
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          autoComplete="OFF"
                          name="pline5"
                          value={AllTextFieldState.pline5}
                          style={{ width: "360px", marginTop: "10px" }}
                          id="standard-basic"
                          label=""
                          variant="standard"
                        />
                        <TextField
                          disabled
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          autoComplete="OFF"
                          name="pline6"
                          value={AllTextFieldState.pline6}
                          style={{ width: "360px", marginTop: "10px" }}
                          id="standard-basic"
                          label=""
                          variant="standard"
                        />
                             <h3
                          className="font-normal text-o-form-label-color mt-2"
                          id="COLOR"
                        >
                          Karkinos Details 
                        </h3>
                        <TextField
                        disabled
                        onChange={(e) => whenInputsChange(e)}
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          autoComplete="OFF"
                          name="kline7"
                          value={"Karkinos Healthcare Private Limited"}
                          style={{ width: "700px", marginTop: "10px" }}
                          id="standard-basic"
                          label=""
                          variant="standard"
                        />
                        <TextField
                        disabled
                        onChange={(e) => whenInputsChange(e)}
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          autoComplete="OFF"
                          name="kline8"
                          value={"GST Registration Number - 14AAICK8055A1ZS | PAN - AAICK8055A. CIN - U85100MN2021PTCO14321"}
                          style={{ width: "700px", marginTop: "10px" }}
                          id="standard-basic"
                          label=""
                          variant="standard"
                        />
                        <TextField
                        disabled
                        onChange={(e) => whenInputsChange(e)}
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          autoComplete="OFF"
                          name="kline9"
                          value={"8268482684 | health@karkinos.in | www.karkinos.in"}
                          style={{ width: "700px", marginTop: "10px" }}
                          id="standard-basic"
                          label=""
                          variant="standard"
                        />
                      </div>
                      <div className="mt-9 flex justify-center items-center  towButtonContainer h-40">
                        <Button
                          onClick={hideForm}
                          type="submit"
                          variant="contained"
                          disableElevation
                          style={{
                            marginRight: "10px",
                            borderColor: "#0F4391",
                            width: "99px",
                            height: "39px",
                            borderRadius: "9999px",
                            backgroundColor: "#E8FAFF",
                            color: "#0F4391",
                            border: "1px solid #0F4391",
                            marginBottom: "100px",
                          }}
                        >
                          CANCEL
                        </Button>
                        <Button
                          type="submit"
                          variant="contained"
                          disableElevation
                          style={{
                            borderColor: "#0F4391",
                            width: "99px",
                            height: "39px",
                            borderRadius: "9999px",
                            backgroundColor: "#E8FAFF",
                            color: "#0F4391",
                            border: "1px solid #0F4391",
                            marginBottom: "100px",
                          }}
                        >
                          SAVE
                        </Button>
                      </div>
                    </form>
                  </>
                </div>
              </div>
            </Grid>
          ) : (
            ""
          )}
        </Grid>
      </div>
      )}
      <AdminCookieConsentBanner />
    </>
  );
};

export default HeaderFooter;
