// AdminCookieConsentBanner.js

import React, { useState, useEffect } from "react";
import "./home.css";
import Button from "@mui/material/Button";

const AdminCookieConsentBanner = () => {
  const [showBanner, setShowBanner] = useState(true);

  // Check if the user has already consented to cookies
  useEffect(() => {
    const userConsent = localStorage.getItem("cookieConsent");
    console.log(userConsent)
    if (userConsent === null || isConsentExpired(userConsent)) {
      setShowBanner(true);
    } else {
      setShowBanner(false);
    }
  }, []);

  // Function to check if consent has expired
  const isConsentExpired = (consentDate) => {
    const consentTimestamp = new Date(consentDate).getTime();
    const currentTimestamp = new Date().getTime();
    const thirtyDaysInMilliseconds = 30 * 24 * 60 * 60 * 1000; // 30 days in milliseconds
    return currentTimestamp - consentTimestamp > thirtyDaysInMilliseconds;
  };

  const handleAcceptCookies = () => {
    // Set a flag in localStorage to indicate that the user has accepted cookies
    localStorage.setItem("cookieConsent", new Date().toISOString());
    setShowBanner(false);
  };

  const handleDeclineCookies = () => {
    // Handle the case when the user declines cookies (e.g., show a message or take appropriate action)
    // For simplicity, we'll just close the banner in this example
    localStorage.removeItem("cookieConsent");
    setShowBanner(false);
  };

  if (!showBanner) {
    return null; // Do not render the banner if the user has already consented
  }

  return (
    <div className="cookie-consent-banner">
      <p className="cookie-text">
        This website uses cookies to enhance your experience.
      </p>
      <div className="button-container">
        <Button
          type="submit"
          variant="contained"
          disableElevation
          onClick={handleAcceptCookies}
          style={{
            borderColor: "#0F4391",
            marginRight: "10px",
            width: "99px",
            height: "39px",
            borderRadius: "9999px",
            backgroundColor: "#E8FAFF",
            color: "#0F4391",
            border: "1px solid #0F4391",
          }}
        >
          Accept
        </Button>
        <Button
          type="submit"
          variant="contained"
          disableElevation
          onClick={handleDeclineCookies}
          style={{
            borderColor: "#0F4391",
            width: "99px",
            height: "39px",
            borderRadius: "9999px",
            backgroundColor: "#E8FAFF",
            color: "#0F4391",
            border: "1px solid #0F4391",
          }}
        >
          Decline
        </Button>
      </div>
    </div>
  );
};

export default AdminCookieConsentBanner;
