import { useState, useEffect, useRef } from "react";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import bodysitelogic from "./samplelogic";
import Sampletable from "./sampletable";
import {
  useGetActiveStateValueMutation,
  useGetsampleclssListMutation,
  useGetsamplematchingListMutation,
  useFilterSampleDataMutation,
} from "../../../services/adminLogIn";
import Autocomplete from "@mui/material/Autocomplete";
import filter from "../../../images/filter.png";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import "../Organization/Mainhome.css";
import { Grid } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Breadcrums from "../../global/breadcrums/Breadcrums";
import { toasterFunction } from "../../../globalFunctions/getLocalStorageData";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminCookieConsentBanner from "../home/AdminCookieConsentBanner";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const Sample = (props) => {
  const [getActiveValFun, { isLoading, isSuccess, isError, ...data }] =
    useGetsamplematchingListMutation();
  const [getStateValFun, { isLoading1, isSuccess1, isError1, ...data1 }] =
    useGetActiveStateValueMutation();
  const [
    getsamplelistfunc,
    { isLoading2, isSuccess2, isError2, ...sampleclsslist },
  ] = useGetsampleclssListMutation();
  const [filterSampleData] = useFilterSampleDataMutation();
  const [filterResults, setFilterResults] = useState();

  const [expand, setexpand] = useState({
    heading: "",
    icon: "",
    isSubListExpanded: false,
  });
  const [expand1, setexpand1] = useState({
    empty: true,
  });

  const filterStatusOptions = ["ACTIVE", "INACTIVE"];

  const [sampageList, setsampageList] = useState([]);
  const [access, setAccess] = useState(
    JSON.parse(sessionStorage.getItem("access"))
  );
  const [isNewData, setIsNewData] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [calculateData, setTotalPageCalculate] = useState({
    number: "",
    numberOfElements: "",
    totalElements: "",
    showcount: "",
  });

  const getsampageList = async (page) => {
    setIsNewData(false);
    setCurrentPage(page);
    if (isSearchEvent) {
      handleFilterData(page - 1);
    } else {
      let payload = {};
      payload.string = "";
      payload.page = page - 1;
      if (searchVal) {
        payload.string = searchVal;
      }
      setPaginationData({ ...payload });
      setIsSearchEvent(false);
      const res = await getActiveValFun(payload);
      setsampageList(res?.data?.content);
      setTotalPageCalculate({
        numberOfElements: res?.data?.numberOfElements,
        number: res?.data?.number,
        totalElements: res?.data?.totalElements,
      });
    }
  };

  const {
    bodySubmitHandler,
    whenInputsChange,
    orgState,
    bodyAllTextFieldState,
    isOrgError,
    dropsampleidState,
    setDropsampleidState,
    handleDropDownChange,
    createBodyRes,
    updatesampleRes,
    districtlist,
    resetErrorHandler,
    setbodyTextFieldState,
    setDropbodyState,
    open,
    openFilterModal,
    handleClose,
    setErrorMessage,
    showerror,
    handleOpen,
    handleFilterModalOpen,
    handleFilterModalClose,
    setOpen,
    halndleClickonProceed,
    filterData,
    isSearchEvent,
    resetFilterData,
    handleFilterInputChange,
    handleFilterValueChange,
    setIsSearchEvent,
  } = bodysitelogic();
  const [statuslist] = useState(["ACTIVE", "INACTIVE"]);
  const [isFormVisible, setFormVisible] = useState({
    isOpen: false,
    isClose: true,
  });
  // const [sampleclsslist, setSampleList] = useState(["BIO-BANKING", "Laboratory"]);
  const [id, setId] = useState("");
  const [paginationdata, setPaginationData] = useState({
    string: "",
    page: 0,
  });

  const showFormActions = () => {
    console.log(access);
    if (access?.sampleadd == true) {
      setErrorMessage(false);
      setId("");
      if (!isFormVisible.isOpen) {
        setFormVisible((prev) => ({
          ...prev,
          isOpen: (isFormVisible.isOpen = true),
          isFormVisible,
          isClose: (isFormVisible.isClose = false),
        }));
        if (bodyAllTextFieldState.is_update === false) {
          setId("");
        }
      }
    } else {
      toasterFunction(" No Permission", "error");
    }
  };

  const hideForm = () => {
    setErrorMessage(false);
    setDropsampleidState({
      sampleClassificationId: "",
      sampleClassificationName: "",
    });
    setbodyTextFieldState({
      is_update: false,
      sampleTypeId: "",
      sampleTypeName: "",
      sampleClassificationId: "",
      description: "",
      quantity: "",
      status: "",
    });
    if (isFormVisible.isOpen) {
      setFormVisible((prev) => ({
        ...prev,
        isOpen: (isFormVisible.isOpen = false),
        isFormVisible,
        isClose: (isFormVisible.isClose = true),
      }));
    }
    setsearchVal("");
  };

  const bredStyle = {
    fontSize: "12px",
  };

  const handleFilterData = async (page) => {
    if (page === 0) {
      setIsNewData(true);
    }
    const reqBody = {
      sampleTypeName: filterData.sampleName,
      sampleClassificationId:
        filterData.classificationId.sampleClassificationId,
      //"status": filterData.filterStatus==="ACTIVE"?"ACTIVE":"DELETED"
      status:
        filterData.filterStatus === "ACTIVE"
          ? "ACTIVE"
          : filterData.filterStatus === "INACTIVE"
          ? "DELETED"
          : "",
      page: page ? page : 0,
    };
    const res = await filterSampleData(reqBody);
    console.log(res);
    setFilterResults(res);
    handleFilterModalClose();
    setIsSearchEvent(true);
  };

  const handleFilterCancel = () => {
    handleFilterData(0);
    handleFilterModalClose();
    setIsSearchEvent(true);
  };

  useEffect(() => {
    getActiveValFun(paginationdata);
    getsamplelistfunc();
  }, []);

  useEffect(() => {
    if (createBodyRes.isSuccess) {
      setbodyTextFieldState({
        is_update: false,
        sampleTypeId: "",
        sampleTypeName: "",
        sampleclsslist: "",
        quantity: "",
        description: "",
        status: "",
      });
      hideForm();
      getActiveValFun(paginationdata);
    }
  }, [createBodyRes.isSuccess]);

  useEffect(() => {
    if (updatesampleRes.isSuccess) {
      getActiveValFun(paginationdata);
      hideForm();
    }
  }, [updatesampleRes.isSuccess]);
  //ask //
  const onSearchevent = (value) => {
    setsearchVal(value);
    setIsSearchEvent(false);
    let payload = {};
    payload.string = value;
    payload.page = 0;
    getActiveValFun(payload);
    setIsNewData(true);
  };
  const [totalPage, setTotalPage] = useState(0);
  const [searchVal, setsearchVal] = useState("");

  return (
    <>
      <div className={props.expand1.empty === true ? "main1" : "main"}>
        <Breadcrums
          firsttab={"Categories"}
          secondtab="Services"
          thirdtab="Sample"
        ></Breadcrums>
        <ToastContainer />
        <Grid container mt={-2}>
          <Grid item xs={6}>
            <div
              className={
                isFormVisible.isOpen
                  ? "leftcontainer rounded-lg"
                  : "leftcontainer1 rounded-lg"
              }
            >
              <Grid className=" p-7 ">
                <TextField
                  label=""
                  onChange={(e) => onSearchevent(e.target.value)}
                  onKeyDown={(e) => {
                    e.key === "Enter" && e.preventDefault();
                  }}
                  sx={{ m: 1, width: "25ch" }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchOutlinedIcon />
                      </InputAdornment>
                    ),
                  }}
                  value={searchVal}
                />
              </Grid>
              <Grid className="editContainer rounded-radiusFourPix ">
                <Grid
                  disabled={access?.sampleadd == false}
                  onClick={showFormActions}
                  className={isFormVisible.isOpen ? "addIconchange" : "addIcon"}
                >
                  <span className=" p-2 m-r-f ">Add</span>
                  <AddCircleOutlineIcon
                    style={{
                      fontSize: "14px",
                      color: "#545454",
                      marginBottom: "3px",
                      color: `${isFormVisible.isOpen ? "#ffffff" : "#545454"}`,
                    }}
                  />
                </Grid>

                {/* <Grid className="Addaction">
                    <span className="p-2 m-r-f">Actions</span>
                    <AddCircleOutlineIcon style={{ fontSize: '14px', color: '#545454', 'marginBottom': '3px' }} />

                  </Grid> */}
                <Grid onClick={handleFilterModalOpen} className="addIcon">
                  <span className="p-2 m-r-f">Filter</span>
                  <img
                    src={filter}
                    alt="filter.png"
                    style={{
                      position: "absolute",
                      left: "90%",
                      right: "20px",
                      top: "15px",
                      bottom: "95px",
                      width: "11px",
                      paddingTop: "3px",
                    }}
                  ></img>
                </Grid>
                <Modal
                  open={openFilterModal}
                  // onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box
                    sx={style}
                    style={{
                      width: "450px",
                      padding: "50px",
                      borderColor: "white",
                    }}
                  >
                    <Typography
                      className="filtermain"
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                      style={{
                        fontFamily: "MontserratRegular",
                        marginTop: "5px",
                      }}
                    >
                      Filter by
                    </Typography>

                    <div className="OrgNameContainer">
                      <h3
                        className="font-normal text-o-form-label-color"
                        id="Org"
                      >
                        Sample Name
                      </h3>
                      <TextField
                        onFocus={(e) => resetErrorHandler(e)}
                        autoComplete="OFF"
                        onChange={(e) => handleFilterInputChange(e)}
                        name="sampleName"
                        value={filterData.sampleName}
                        style={{ width: "360px" }}
                        id="standard-basic"
                        label=""
                        variant="standard"
                      />
                    </div>
                    <div className="mt-5 OrgNameContainer ">
                      <h3 className="font-normal text-o-form-label-color">
                        SampleClassificationID
                      </h3>
                      <Autocomplete
                        value={filterData.classificationId}
                        autoHighlight={true}
                        disableClearable
                        onChange={(e, v) =>
                          handleFilterValueChange("classificationId", v)
                        }
                        getOptionLabel={(option) =>
                          option ? option.sampleClassificationName : ""
                        }
                        id="org-list-option"
                        options={
                          sampleclsslist &&
                          sampleclsslist.data &&
                          sampleclsslist.data.content
                            ? sampleclsslist.data.content
                            : []
                        }
                        sx={{ width: 360 }}
                        renderInput={(params) => (
                          <TextField
                            variant="standard"
                            onFocus={(e) => resetErrorHandler(e)}
                            autoComplete="OFF"
                            {...params}
                            label=""
                          />
                        )}
                      />
                    </div>
                    <div className="mt-5 OrgNameContainer ">
                      <h3 className="font-normal text-o-form-label-color">
                        Status
                      </h3>
                      <Autocomplete
                        value={filterData.filterStatus}
                        autoHighlight={true}
                        disableClearable
                        onChange={(e, v) =>
                          handleFilterValueChange("filterStatus", v)
                        }
                        getOptionLabel={(option) => (option ? option : "")}
                        id="org-list-option"
                        options={filterStatusOptions}
                        sx={{ width: 360 }}
                        renderInput={(params) => (
                          <TextField
                            variant="standard"
                            onFocus={(e) => resetErrorHandler(e)}
                            autoComplete="OFF"
                            {...params}
                            label=""
                          />
                        )}
                      />
                    </div>

                    <Button
                      variant="contained"
                      disableElevation
                      onClick={handleFilterCancel}
                      style={{
                        marginRight: "10px",
                        top: "30px",
                        borderColor: "#0F4391",
                        width: "99px",
                        height: "39px",
                        borderRadius: "9999px",
                        backgroundColor: "#E8FAFF",
                        color: "#0F4391",
                        border: "1px solid #0F4391",
                        marginBottom: "5px",
                      }}
                    >
                      CANCEL
                    </Button>

                    <Button
                      variant="contained"
                      disableElevation
                      onClick={resetFilterData}
                      style={{
                        marginRight: "10px",
                        top: "30px",
                        borderColor: "#0F4391",
                        width: "99px",
                        height: "39px",
                        borderRadius: "9999px",
                        backgroundColor: "#E8FAFF",
                        color: "#0F4391",
                        border: "1px solid #0F4391",
                        marginBottom: "5px",
                      }}
                    >
                      Reset
                    </Button>
                    <Button
                      variant="contained"
                      disableElevation
                      disabled={
                        filterData.filterStatus === "" &&
                        filterData.classificationId === "" &&
                        filterData.sampleName === ""
                      }
                      onClick={() => handleFilterData(0)}
                      style={{
                        top: "30px",
                        borderColor: "#0F4391",
                        width: "99px",
                        height: "39px",
                        borderRadius: "9999px",
                        backgroundColor: "#E8FAFF",
                        color: "#0F4391",
                        border: "1px solid #0F4391",
                        marginBottom: "5px",
                      }}
                    >
                      Search
                    </Button>
                  </Box>
                </Modal>

                {/* <Grid className="addIcon">
                    <span className="p-2 m-r-f">Filter</span>
                    <img src={filter} alt="filter.png" style={{ position: 'absolute',  left: '85%', right: '20%', top: '29%', bottom: '92%', 'width': '11px', paddingTop: '3px' }} ></img>

                  </Grid> */}
              </Grid>
              <>
                <Sampletable
                  setErrorMessage={setErrorMessage}
                  setTotalPage={setTotalPage}
                  totalPage={totalPage}
                  setId={setId}
                  id={id}
                  getsampageList={getsampageList}
                  data={isSearchEvent ? filterResults?.data : data.data}
                  sampleclsslist={sampleclsslist}
                  showFormActions={showFormActions}
                  setbodyTextFieldState={setbodyTextFieldState}
                  setDropbodyState={setDropbodyState}
                  setDropsampleidState={setDropsampleidState}
                  calculateData={calculateData}
                  currentPage={isNewData ? 1 : currentPage}
                  sampleview={access?.sampleview}
                  sampleupdate={access?.sampleupdate}
                />
              </>
            </div>
          </Grid>

          {isFormVisible.isOpen ? (
            <Grid item xs={6}>
              <div className="rightcontainer rounded-lg">
                <div className="overflow-y-auto o-form-container">
                  <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={style}>
                      <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                      >
                        <Alert severity="info">
                          {" "}
                          {bodyAllTextFieldState.status === "INACTIVE"
                            ? "Are you sure you want to reactivate  the Sample ?"
                            : "Are you sure you want to Deactivate the Sample ?"}
                        </Alert>
                      </Typography>
                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {bodyAllTextFieldState.status === "INACTIVE"
                          ? "This activity will enable the Sample and user will be able to access it across all products."
                          : "This activity disable the Sample and user will not able to access it. You can reactivate the Sample at anytime?"}

                        <div className="mt-10 flex justify-center items-center  towButtonContainer">
                          <Button
                            type="submit"
                            onClick={handleClose}
                            variant="contained"
                            disableElevation
                            style={{
                              marginRight: "10px",
                              borderColor: "#0F4391",
                              width: "99px",
                              height: "39px",
                              borderRadius: "9999px",
                              backgroundColor: "#E8FAFF",
                              color: "#0F4391",
                              border: "1px solid #0F4391",
                              marginBottom: "100px",
                            }}
                          >
                            CANCEL
                          </Button>

                          <Button
                            type="submit"
                            onClick={halndleClickonProceed}
                            variant="contained"
                            disableElevation
                            style={{
                              borderColor: "#0F4391",
                              width: "99px",
                              height: "39px",
                              borderRadius: "9999px",
                              backgroundColor: "#E8FAFF",
                              color: "#0F4391",
                              border: "1px solid #0F4391",
                              marginBottom: "100px",
                            }}
                          >
                            PROCEED
                          </Button>
                        </div>
                      </Typography>
                    </Box>
                  </Modal>
                  <ToastContainer />
                  <>
                    <div className="font-normal text-o-form-label-color mainhead flex justify-between items-center pl-5 pr-1 h-10">
                      <h1>Sample Overview</h1>
                    </div>

                    <form autoComplete="off" onSubmit={bodySubmitHandler}>
                      <div className=" pl-5 OrgNameContainer">
                        <h3
                          className="font-normal text-o-form-label-color"
                          id="Org"
                        >
                          Sample Type Name
                          <span style={{ color: "red" }}> *</span>
                        </h3>
                        <TextField
                          onFocus={(e) => resetErrorHandler(e)}
                          autoComplete="OFF"
                          error={isOrgError.isNameError === true}
                          helperText={
                            isOrgError.isNameError
                              ? "Valid (Name) Required !"
                              : ""
                          }
                          onChange={(e) => whenInputsChange(e)}
                          name="sampleTypeName"
                          value={bodyAllTextFieldState.sampleTypeName}
                          style={{ width: "360px" }}
                          id="standard-basic"
                          label=""
                          variant="standard"
                        />
                      </div>

                      <div className="mt-5 pl-5 OrgNameContainer ">
                        <h3 className="font-normal text-o-form-label-color">
                          Sample Classificationld{" "}
                          <span style={{ color: "red" }}> *</span>
                        </h3>
                        <Autocomplete
                          value={dropsampleidState}
                          autoHighlight={true}
                          disableClearable
                          onChange={(e, v) =>
                            handleDropDownChange("sampleClassificationId", v)
                          }
                          getOptionLabel={(option) =>
                            option ? option.sampleClassificationName : ""
                          }
                          id="org-list-option"
                          options={
                            sampleclsslist &&
                            sampleclsslist.data &&
                            sampleclsslist.data.content
                              ? sampleclsslist.data.content
                              : []
                          }
                          sx={{ width: 360 }}
                          renderInput={(params) => (
                            <TextField
                              variant="standard"
                              onFocus={(e) => resetErrorHandler(e)}
                              autoComplete="OFF"
                              error={isOrgError.issampleError === true}
                              helperText={
                                isOrgError.issampleError
                                  ? "Valid (Sample Classificationld) Required !"
                                  : ""
                              }
                              {...params}
                              label=""
                            />
                          )}
                        />
                      </div>

                      <div className="mt-5 pl-5 OrgNameContainer ">
                        <h3 className="font-normal text-o-form-label-color">
                          Quantity
                        </h3>
                        <TextField
                          onChange={(e) => whenInputsChange(e)}
                          name="quantity"
                          value={bodyAllTextFieldState.quantity}
                          style={{ width: "360px" }}
                          id="standard-basic"
                          label=""
                          variant="standard"
                        />
                      </div>
                      <div className="mt-5 pl-5 OrgNameContainer ">
                        <h3 className="font-normal text-o-form-label-color">
                          Description
                        </h3>
                        <TextField
                          onChange={(e) => whenInputsChange(e)}
                          name="description"
                          value={bodyAllTextFieldState.description}
                          style={{ width: "360px" }}
                          id="standard-basic"
                          label=""
                          multiline
                          rows={4}
                          defaultValue=""
                          variant="standard"
                        />
                      </div>
                      {bodyAllTextFieldState.is_update === true ? (
                        <div className="mt-5 pl-5 OrgNameContainer ">
                          <h3 className="font-normal text-o-form-label-color">
                            Status
                          </h3>
                          <Autocomplete
                            autoHighlight={true}
                            value={
                              bodyAllTextFieldState &&
                              bodyAllTextFieldState.status
                            }
                            onChange={(e, v) =>
                              handleDropDownChange("status", v)
                            }
                            getOptionLabel={(option) => (option ? option : "")}
                            id="city-list-option"
                            onKeyDown={(e) => {
                              e.key === "Enter" && e.preventDefault();
                            }}
                            options={statuslist ? statuslist : []}
                            sx={{ width: 360 }}
                            renderInput={(params) => (
                              <TextField
                                variant="standard"
                                {...params}
                                label=""
                              />
                            )}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="mt-5  OrgNameContainer ">
                        <div className="progressContainer mt-5 flex justify-center items-center">
                          {createBodyRes.isError ||
                          (updatesampleRes.isError && showerror === true) ? (
                            <Alert severity="error" color="warning">
                              {createBodyRes.error &&
                              createBodyRes.error.data &&
                              bodyAllTextFieldState.is_update === false
                                ? createBodyRes.error.data.message
                                : updatesampleRes.error &&
                                  updatesampleRes.error.data &&
                                  updatesampleRes.error.data.message}
                            </Alert>
                          ) : createBodyRes.isLoading ||
                            updatesampleRes.isLoading ? (
                            <CircularProgress />
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="mt-5 flex justify-center   towButtonContainer h-40">
                          <Button
                            onClick={hideForm}
                            type="submit"
                            variant="contained"
                            disableElevation
                            style={{
                              marginRight: "10px",
                              borderColor: "#0F4391",
                              width: "99px",
                              height: "39px",
                              borderRadius: "9999px",
                              backgroundColor: "#E8FAFF",
                              color: "#0F4391",
                              border: "1px solid #0F4391",
                            }}
                          >
                            CANCEL
                          </Button>

                          <Button
                            type="submit"
                            variant="contained"
                            disableElevation
                            style={{
                              borderColor: "#0F4391",
                              width: "99px",
                              height: "39px",
                              borderRadius: "9999px",
                              backgroundColor: "#E8FAFF",
                              color: "#0F4391",
                              border: "1px solid #0F4391",
                            }}
                          >
                            SAVE
                          </Button>
                        </div>
                      </div>
                    </form>
                  </>
                </div>
              </div>
            </Grid>
          ) : (
            ""
          )}
        </Grid>
      </div>
      <AdminCookieConsentBanner />
    </>
  );
};

export default Sample;
