import {createSlice} from "@reduxjs/toolkit";
import {getTokensFromStorage}  from "../globalFunctions/getLocalStorageData";
const state = {
    isAdminLogged:false,
    token:null,
    profileName: "KDS",
    
}

export const adminLogSlice = createSlice({
    name:'adminLog',
    initialState:state,
    reducers:{
        checkIfAdminLoggedIn:(state , action)=>{
           state.isAdminLogged = action.payload;
        },
        checkRoleAccess:(state , action)=>{
            state.role_access = action.payload;
         },
         setProfileName: (state, action) => {
            state.profileName = action.payload;
         }


     
    }
})

export const {checkIfAdminLoggedIn,checkRoleAccess, setProfileName} =  adminLogSlice.actions;
export default adminLogSlice.reducer;