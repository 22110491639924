import { LocalConvenienceStoreOutlined } from "@mui/icons-material";
import {useState} from "react";
import {useCreatingOrganizationMutation,useGetActiveDistrictValueMutation,useUpdatingOrganizationMutation} from "../../../services/adminLogIn";

const useOrgHook = ()=>{

      // services requests hooks
      const isEmail = (email) => {
        return String(email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
      };
      const [createOrgFunc , createOrgRes] = useCreatingOrganizationMutation();
      const [updateOrgFunc , updateOrgRes] = useUpdatingOrganizationMutation();
      const [getDistrictValFun ,  {isLoading1 , isSuccess1 , isError1 ,...districtlist}] =  useGetActiveDistrictValueMutation();
      const [open, setOpen] = useState(false);
      const handleOpen = () => setOpen(true);
      const handleClose = () => setOpen(false);

    // making state for for organisation form
    const [orgState , setOrgState] = useState();

   // making state for all org text fileds
   
    const [orgAllTextFieldState , setOrgTextFieldState] = useState({
        is_update:false,
        name:'',
        level:'',
        id:'',
        address:'',
        city:'',
        state:'',
        postalCode:'',
        country:'',
        phoneNumber:'',
        emailId:'',
        active:"true"
      
    });
    
  // making separate state for dropdown
  const [dropOrgState , setDropOrgState] = useState({
    id:''
  });  

  // making state for error handling for org-form


  const [isOrgError , setError] = useState({
     isNameError:'',
     isIdError:'',
     isDropDownError:false,
     isAddressError:false,
     isCityError:'',
     isStateError:'',
     isPostalCodeError:'',
     isCountryError:'',
     isPhoneError:'',
     isEmailError:''
});  

const [proccedVal , setProceedVal] = useState(false);
const [proccedVal1 , setProceedVal1] = useState("");
const halndleClickonProceed = ()=>{
    setProceedVal(true)
    setOrgTextFieldState((prev)=>({
        ...prev ,
        ["active"]: proccedVal1==="Active" ? true : false

     }))
    handleClose()
  }
// dropdown handler 
const [showerror, setErrorMessage] = useState(false);
  const handleDropDownChange = (e , v)=>{
      const value = v;
      if(e==='partof'){
      setDropOrgState(value);
      }
      if(e!='partof' && "status"){
          if(e==="state"){
            getDistrictValFun1(value)
            setOrgTextFieldState((prev) => ({
                ...prev,
                ['city']: ''
            }))
          }
        setOrgTextFieldState((prev)=>({
            ...prev ,
            [e]:value
    
         }))
         
      }
      if(e==="active"){
        setProceedVal1(value)
         handleOpen()
      }
      
  }
  const getDistrictValFun1 = (value)=>{
    getDistrictValFun(value)
  }
  // onfoucs reset error warning

  const resetErrorHandler = (e)=>{
      const name = e.target.name;
      switch(name){
          case "name":
            setError((prev)=>({
                ...prev , 
                isNameError:isOrgError.isNameError = false
            })) 
           break;
          
           
           case "address":
            setError((prev)=>({
                ...prev , 
                isAddressError:isOrgError.isAddressError = false
            })) 
           break;   

           case "city":
            setError((prev)=>({
                ...prev , 
                isCityError:isOrgError.isCityError = false
            })) 
           break;   

           case "state":
            setError((prev)=>({
                ...prev , 
                isStateError:isOrgError.isStateError = false
            })) 
           break;   

           case "postalCode":
            setError((prev)=>({
                ...prev , 
                isPostalCodeError:isOrgError.isPostalCodeError = false
            })) 
           break;   

           case "country":
            setError((prev)=>({
                ...prev , 
                isCountryError:isOrgError.isCountryError = false
            })) 
           break;   

           case "phoneNumber":
            setError((prev)=>({
                ...prev , 
                isPhoneNumberError:isOrgError.isPhoneNumberError = false
            })) 
           break;   

           case "emailId":
            setError((prev)=>({
                ...prev , 
                isEmailError:isOrgError.isEmailError = false
            })) 
           break;   

       }
            
  }
    

   // form on submit handleer 
   const orgSubmitHandler = (e)=>{
        e.preventDefault();
        if(orgAllTextFieldState.name === ""){
            setError((prev)=>({
                ...prev , 
                isNameError:isOrgError.isNameError = true
            }))
           
        }
        // else if(dropOrgState === "undefined" || dropOrgState === null){
              
        //     setError((prev)=>({
        //         ...prev , 
        //         isNameError:isOrgError.isNameError = false,
        //         isIdError:isOrgError.isIdError = false,
        //         isDropDownError:isOrgError.isDropDownError = true
        //     }))
            
        // }else if(Object.keys(dropOrgState).length === 0 ){
              
        //     setError((prev)=>({
        //         ...prev , 
        //         isNameError:isOrgError.isNameError = false,
        //         isIdError:isOrgError.isIdError = false,
        //         isDropDownError:isOrgError.isDropDownError = true
        //     }))
           
        // }
        
        // else if(orgAllTextFieldState.address === ""){
           
        //     setError((prev)=>({
        //         ...prev , 
        //         isNameError:isOrgError.isNameError = false,
        //         isIdError:isOrgError.isIdError = false,
        //         isDropDownError:isOrgError.isDropDownError = false,
        //         isAddressError:isOrgError.isAddressError = true
        //     }))
        // }
        // else if(orgAllTextFieldState.city === ""){
        //     setError((prev)=>({
        //         ...prev , 
        //         isNameError:isOrgError.isNameError = false,
        //         isIdError:isOrgError.isIdError = false,
        //         isDropDownError:isOrgError.isDropDownError = false,
        //         isAddressError:isOrgError.isAddressError = false,
        //         isCityError:isOrgError.isCityError = true
        //     }))
        // }else if(orgAllTextFieldState.state === ""){
        //     setError((prev)=>({
        //         ...prev , 
        //         isNameError:isOrgError.isNameError = false,
        //         isIdError:isOrgError.isIdError = false,
        //         isDropDownError:isOrgError.isDropDownError = false,
        //         isAddressError:isOrgError.isAddressError = false,
        //         isCityError:isOrgError.isCityError = false,
        //         isStateError:isOrgError.isStateError = true,
        //     }))
        // }
        // else if(orgAllTextFieldState.postalCode === ""){
        //     setError((prev)=>({
        //         ...prev , 
        //         isNameError:isOrgError.isNameError = false,
        //         isIdError:isOrgError.isIdError = false,
        //         isDropDownError:isOrgError.isDropDownError = false,
        //         isAddressError:isOrgError.isAddressError = false,
        //         isCityError:isOrgError.isCityError = false,
        //         isStateError:isOrgError.isStateError = false,
        //         isPostalCodeError:isOrgError.isPostalCodeError = true,
        //     }))
        // }
        // else if(orgAllTextFieldState.country === ""){
        //     setError((prev)=>({
        //         ...prev , 
        //         isNameError:isOrgError.isNameError = false,
        //         isIdError:isOrgError.isIdError = false,
        //         isDropDownError:isOrgError.isDropDownError = false,
        //         isAddressError:isOrgError.isAddressError = false,
        //         isCityError:isOrgError.isCityError = false,
        //         isStateError:isOrgError.isStateError = false,
        //         isPostalCodeError:isOrgError.isPostalCodeError = false,
        //         isCountryError:isOrgError.isCountryError = true,

        //     }))
        // }
       else if((orgAllTextFieldState.emailId != "") && orgAllTextFieldState.emailId != null &&!isEmail(orgAllTextFieldState.emailId)){
            setError((prev)=>({
                ...prev , 
                isNameError:isOrgError.isNameError = false,
                isPhoneError:isOrgError.isPhoneError = false,
                isEmailError:isOrgError.isEmailError = true,

            }))
        }else{

             const finalRef =  dropOrgState.id;

            setError((prev)=>({
                ...prev , 
                isNameError:isOrgError.isNameError = false,
                isPhoneError:isOrgError.isPhoneError = false,
                isEmailError:isOrgError.isEmailError = false,

            }))
          
            const sendOrgDetails = {
                resourceType:"Organization",
                id: orgAllTextFieldState.is_update===false ? "ORG-"+orgAllTextFieldState.name.split(' ').join('-').toUpperCase().replace(/[^a-zA-Z0-9 ]/g, '-'):orgAllTextFieldState.id,
                identifier:[{
                    system:"http://karkinos.com",
                    value:orgAllTextFieldState.name,
                }],
               
                active:orgAllTextFieldState.is_update===false ? true:orgAllTextFieldState.active,
                name:orgAllTextFieldState.name,
                level:orgAllTextFieldState.level ? orgAllTextFieldState.level :null,
                type:[ {
                    "coding": [
                        {
                            "code": "405608006",
                            "display": "Karkinos Medical Center"
                        },
                        {
                            "code": "prov",
                            "display": "Karkinos Medical Center"
                        }
                    ]
                }],
                address:[{
                    use:"HOME",
                    city:orgAllTextFieldState.city.name,
                    state:orgAllTextFieldState.state.name,
                    postalCode:orgAllTextFieldState.postalCode,
                    countryISOCode: orgAllTextFieldState.country.isoCode
                }],
                telecom:[{
                    system:"PHONE",
                    value:orgAllTextFieldState.phoneNumber,
                  },
                  {
                      system:"EMAIL",
                      value:orgAllTextFieldState.emailId
                  }
               ],
                contact:[ {
                    "name": {
                        "text": "Sandeep Gayke",
                        "given": [
                            "Sandeep"
                        ]
                    }
                }],
              
                partOf:{
                    
                    type:"Organization",
                    identifier:{
                        system:"http://karkinos.com",
                        value: dropOrgState.id!='' && dropOrgState.id!=null ? dropOrgState.id : ''
                    },
                    reference:dropOrgState.id!='' && dropOrgState.id!=null ? "Organization/"+dropOrgState.id : '',
                    getreference:dropOrgState.id!='' && dropOrgState.id!=null ? dropOrgState.id : '',
                }
            }
        if(orgAllTextFieldState.is_update===false)  createOrgFunc(sendOrgDetails)
        else updateOrgFunc(sendOrgDetails)
        setErrorMessage(true)
        // end of else  
        }
           
   } 
  
   // getting inputs value onChange
   

   const whenInputsChange = (e)=>{
       const {name , value} = e.target;
       if(name==="postalCode"){
        if(value.length<=8){
            setOrgTextFieldState((prev)=>({
                ...prev ,
                [name]:value.replace(/[^0-9]/gi, "")
     
             }))
        }
       }
        else if (name==="name"){
        if (value.length<=150){
            setOrgTextFieldState((prev)=>({
                ...prev ,
                [name]:value
     
             }))

        }
       }
       else if (name==="level"){
        
            setOrgTextFieldState((prev)=>({
                ...prev ,
                [name]:value
     
             }))

        
       }
    else if (name==="phoneNumber"){
        {
            setOrgTextFieldState((prev)=>({
                ...prev ,
                [name]:value.replace(/[^0-9]/gi,"")
     
             }))
        }

    }
       else{
        setOrgTextFieldState((prev)=>({
            ...prev ,
            [name]:value
 
         }))
       }
 
   }
   // exporting all handler and state and etc
   const exportAll = {
       orgSubmitHandler,
       whenInputsChange,
       orgState,
       dropOrgState,
       orgAllTextFieldState,
       isOrgError,
       handleDropDownChange,
       createOrgRes,
       updateOrgRes,
       resetErrorHandler,
       setOrgTextFieldState,
       setDropOrgState  ,
       getDistrictValFun1,
       open,
       handleClose,
       handleOpen,
       setOpen,
       districtlist,
       halndleClickonProceed,proccedVal,
       setErrorMessage,
       showerror
       
   }

   return exportAll;
}

export default useOrgHook;



