import { useState } from "react";
import { useCreateTaskTypeMutation, useUpdatigtasktypeMutation ,useDeleteTaskTypeCustomStatusMutation,useGetCustomTaskListMutation} from "../../../services/adminLogIn";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toasterFunction } from "../../../globalFunctions/getLocalStorageData";


const TaskTypes = () => {
  const [createTaskType, createBodyRes] = useCreateTaskTypeMutation();
  const [deletCustomStatus ] = useDeleteTaskTypeCustomStatusMutation();
  const [getCustomStatusListData] = useGetCustomTaskListMutation();
  const [updatigtasktype] = useUpdatigtasktypeMutation();
  const encounterTypesFormData = {
    encounterType: '',
  };
  const [encounterFormFields, setEncounterFormFields] = useState(encounterTypesFormData);
  const [encounterSystemStates, setEncounterSystemStates] = useState([]);
  const [encounterCustomStates, setEncounterCustomStates] = useState([]);
  const [showerror, setErrorMessage] = useState(false);
  const [taskSubmitStatus, setTaskSubmitStatus] = useState(false);
  const [showCustomFiedlError, setShowCustomFieldError] = useState(false);
  const [customStateList, setCustomStateList] = useState([]);

  const [bodyAllTextFieldState, setbodyTextFieldState] = useState({
    is_update: false,
    taskTypeId: "",
    taskTypeName: "",
    usage: "",
    usageCount: "",
    status: "",
  });
  const [open, setOpen] = useState(false);
  const [isSearchEvent, setIsSearchEvent] = useState(false);
  const [status, setStatus] = useState("");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [dropsampleidState, setDropsampleidState] = useState({
    sampleClassificationId: "",
    sampleClassificationName: "",
  });

  const [isOrgError, setError] = useState({
    isNameError: "",
    issampleError: "",
    isCustomError: "",
  });

  // dropdown handler
  const handleDropDownChange = (e, v) => {
    const value = v;
    if (e === "status") {
      handleOpen();
    }
  };
  
  const halndleClickonProceed = () => {
    setbodyTextFieldState((prev) => ({
      ...prev,
      ["status"]:
        bodyAllTextFieldState.status === "ACTIVE" ? "INACTIVE" : "ACTIVE",
    }));
    setStatus(bodyAllTextFieldState.status === "ACTIVE" ? "DELETED" : "ACTIVE");
    handleClose();
  };
  const getDistrictValFun1 = (value) => {
    //   getDistrictValFun(value)
  };

  const resetErrorHandler = (e) => {
    const name = e.target.name;
    switch (name) {
      case "taskTypeName":
        setError((prev) => ({
          ...prev,
          isNameError: (isOrgError.isNameError = false),
        }));
        break;
    }
  };

  const handleInputChange = (e, i) => {
    const { name, value } = e.target;
    console.log(value)
    console.log(name)
    console.log(i)
    if(name === "encounterType") {
        setEncounterFormFields((prev) => ({
            ...prev,
            [name]: value,
          }));
    };
    if (name === "customState" ) {
        let arr = [...encounterCustomStates];
        console.log(encounterCustomStates)
        arr[i].value = value;
        arr[i].id = i;
        arr[i].isDisabled = false;
        setEncounterCustomStates([...arr]);
        setShowCustomFieldError(false);
        const systemArr = encounterSystemStates;
        systemArr[i].isDisabled = false;
        setEncounterSystemStates(systemArr);
    }
    if (name === "isdefault" ) {
    let isdefaultData =  encounterSystemStates.find(data => data.isDefault===true)
      let arr = [...encounterCustomStates];
      arr[i].isDisabled = false;

    
     
      setEncounterCustomStates([...arr]);
      setShowCustomFieldError(false);
      const systemArr = encounterSystemStates;
      if(!systemArr[i].isDefault===true){
        systemArr.forEach((element) => {
          if (isdefaultData && element.id === isdefaultData.id) {
            element.isDefault = false;
          }
        });
      }
      
      systemArr[i].isDisabled = false;
      systemArr[i].isDefault = !systemArr[i].isDefault;
      setEncounterSystemStates(systemArr);
      console.log(systemArr)
  }
  };

  const handleDropdownStates = async(field, i, v) => {
    const value = v;
        if (field === "systemStates") {
        encounterSystemStates.forEach((element, index) => {
          if (i === index) {
            element.customTaskStatus = value?.customTaskStatus;
            element.customTaskStatusId = value?.customTaskStatusId;
            element.taskStatus = value?.taskStatus;
            element.taskTypeId = "";
            element.taskTypeCustomTaskStatusMapId = "";
            element.ordering = i+1;
            element.isDisabled = false;
          }
        });
        await removeCustomStateListWithId(value?.customTaskStatusId)
        setEncounterSystemStates([...encounterSystemStates]);
        setShowCustomFieldError(false);
        const customArr = encounterCustomStates;
        // customArr[i].isDisabled = false;
        setEncounterCustomStates(customArr);
      }
    
  };

  const removeCustomStateListWithId = async (customTaskStatusId) => {
    let res =  customStateList.filter((obj) => obj.customTaskStatusId !== customTaskStatusId);
    setCustomStateList(res)
  }

  const addRow = () => {
    const systemStateRow = {
      customTaskStatus: '',
      customTaskStatusId: '',
      taskStatus: '',
        isDisabled: false,
        isChecked: false,
        isNew:false,
        isDefault: false,
       
    }
    const customStateRow = {
        value: '',
        id: '',
        isChecked: false,
        isNew:false,
        isDefault: false,
    }
    setEncounterSystemStates((prev) => [...prev, systemStateRow]);
    setEncounterCustomStates((prev) => [...prev, customStateRow]);
  };

  const addRowByIndex = (i) => {
    const systemStateRow = {
      customTaskStatus: '',
      customTaskStatusId: '',
      taskStatus: '',
        isDisabled: false,
        isChecked: false,
        isNew:false,
        isDefault: false,
       
    }
    const customStateRow = {
        value: '',
        id: '',
        isChecked: false,
        isNew:false,
        isDefault: false,
    }

    encounterSystemStates.splice(i+1,0,systemStateRow)
    setEncounterSystemStates([...encounterSystemStates]);
    setEncounterCustomStates((prev) => [...prev, customStateRow]);
  };

  const deleteRow = async(i,row) => {
    const systemStatesArr = encounterSystemStates.filter((item, index) => index !== i);
    setEncounterSystemStates([...systemStatesArr]);
    setShowCustomFieldError(false);
    await deletCustomStatus(row.taskTypeCustomTaskStatusMapId) 
    const customstateList = await getCustomStatusListData({ "customTaskStatus": "", "page": 0, "size": 499 });
    setCustomStateList(customstateList?.data?.content)
  };

 
  const whenInputsChange = (e) => {
    const { name, value } = e.target;
    if (name === "taskTypeName" || name === "usage" || name === "usageCount") {
      setbodyTextFieldState((prev) => ({
        ...prev,
        [name]: value,
      }));
    } else {
      setbodyTextFieldState((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };
  // exporting all handler and state and etc
  const exportAll = {
    whenInputsChange,
    handleDropDownChange,
    bodyAllTextFieldState,
    isOrgError,
    createBodyRes,
    resetErrorHandler,
    setbodyTextFieldState,
    getDistrictValFun1,
    dropsampleidState,
    setDropsampleidState,
    open,
    handleClose,
    handleOpen,
    setOpen,
    halndleClickonProceed,
    setErrorMessage,
    showerror,
    isSearchEvent,
    taskSubmitStatus,
    setTaskSubmitStatus,
    setIsSearchEvent,
    setTaskSubmitStatus,
    createTaskType,
    updatigtasktype,
    setError,
    encounterFormFields,
    encounterSystemStates,
    encounterCustomStates,
    addRow,
    addRowByIndex,
    deleteRow,
    handleDropdownStates,
    setEncounterSystemStates,
    setEncounterCustomStates,
    setCustomStateList,
    customStateList,
    removeCustomStateListWithId,
    getCustomStatusListData

    //    districtlist
  };

  return exportAll;
};

export default TaskTypes;
