import React, { useEffect, useState, useRef } from "react";
import AdminHome from "../AdminHome";
import { Button, Grid } from "@mui/material";
import "../Organization/Mainhome.css";
import RefreshIcon from "@mui/icons-material/Refresh";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import {
  useGetpatientstatuslistMutation,
  useGetpatientpaginationMutation,
  useCreatePatientImportMutation,
  useCreateFetchDataMutation,
} from "../../../services/adminLogIn";
import { downloadExcel } from "react-export-table-to-excel";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Breadcrums from "../../global/breadcrums/Breadcrums"
import AdminCookieConsentBanner from "../home/AdminCookieConsentBanner";



const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const PatientStatusList = (props) => {
  const tableRef = useRef(null);
  const [getpatientlistfunc] = useGetpatientstatuslistMutation();
  const [getpatientpaginationfunc] = useGetpatientpaginationMutation();
  const [createPatientImport] = useCreatePatientImportMutation();
  const [fetchData] = useCreateFetchDataMutation();

  const [expand, setexpand] = useState({
    heading: "",
    icon: "",
    isSubListExpanded: false,
  });
  const [expand1, setexpand1] = useState({empty: true,});
 
  const [logList, setLogList] = useState([]);
  const [patientImport, setpatientImport] = useState();

  const getpatientImport = async (page) => {
    const res = await getpatientpaginationfunc(page - 1);

    setLogList(res?.data);
  };
 
  const downloadCreatedData = async (jobId) => {
    var patientListDownload = [];
    var header = [];
    const res = await fetchData({ jobId });
    res?.data?.content.forEach((element) => {
      console.log(element)
      let object = {};
      object.LocationId = element?.location;
      object.OrganizationID = element?.organization;
      object.SourceApp = element?.sourceAppName;
      object.Salutation = element?.salutation;
      object.First_Name = element?.firstName;
      object.Middle_Name = element?.middleName;
      object.Last_Name = element?.lastName;
      object.Age = element?.age?.years;
      object.DOB = element?.dob;
      object.dobType = element?.dobType
      object.Gender = element?.gender;
      object.Contact_details = element?.mobile;
      object.Alternate_Number = element?.extension?.alternateNumber;
      object.Email = element?.email;
      object.Blood_Group = element?.bloodGroup;
      object.Marital_Status = element?.maritalStatus;
      object.ID_Proof_Type = element?.identityProofs[0]?.type;
      object.ID_Proof_NO = Number(element?.identityProofs[0]?.value);
      object.Occupation = element?.occupation;
      object.Fathers_Name = element?.fatherName;
      object.Spouse_Name = element?.spouseName;

      object.Communication_Address_House_No =
        element?.temporaryAddressDetails?.houseNumber;
      object.Communication_House_Name =
        element?.temporaryAddressDetails?.houseName;
      object.Communication_Ward_No = element?.temporaryAddressDetails?.ward;
      object.Communication_Pincode = element?.temporaryAddressDetails?.pinCode;
      object.Communication_State = element?.temporaryAddressDetails?.state;
      object.Communication_District = element?.temporaryAddressDetails?.district;
      object.Communication_Country = element?.temporaryAddressDetails?.extension?.country;
      object.Communication_TalukPanchayat = element?.temporaryAddressDetails?.panchayat;
      object.Communication_CityVillageTown = element?.temporaryAddressDetails?.villageName;
      object.Communication_Duration_of_stay = element?.temporaryAddressDetails?.extension?.durationText;
      object.Communication_Street = element?.temporaryAddressDetails?.extension?.street;
      object.Communication_Locality = element?.temporaryAddressDetails?.extension?.locality;

      object.Permanent_Address_House_No =
        element?.permanentAddressDetails?.houseNumber;
      object.Permanent_House_Name = element?.permanentAddressDetails?.houseName;
      object.Permanent_Ward_No = element?.permanentAddressDetails?.ward;
      object.Permanent_Pincode = element?.permanentAddressDetails?.pinCode;
      object.Permanent_State = element?.permanentAddressDetails?.state;
      object.Permanent_District = element?.permanentAddressDetails?.district;
      object.Parmanent_Country = element?.permanentAddressDetails?.extension?.country;
      object.Parmanent_Street = element?.permanentAddressDetails?.extension?.street;
      object.Parmanent_Locality = element?.permanentAddressDetails?.extension?.locality;
      object.Parmanent_Duration_of_stay = element?.permanentAddressDetails?.extension?.durationText;
      object.Parmanent_TalukPanchayat = element?.permanentAddressDetails?.panchayat;
      object.Parmanent_CityVillageTown = element?.permanentAddressDetails?.villageName;

      object.Education = element?.extension?.education;
      object.Emergency_Contact_Person_Name =
        element?.emergencyContactPersonName;
      object.Emergency_Contact_Person_Number = element?.emergencyContactNumber;
      object.General_Consent = element?.consentModels;
      object.Whatsapp_Consent = element?.enableWhatsAppNotification;
      object.status =  element?.status
      object.khid = element?.khid
      
      // object.alternateNumberType = element?.extension?.alternateNumberType

      // object.isAlternateNumberVerified = element?.extension?.isAlternateNumberVerified
      // object.primaryNumberType = element?.extension?.primaryNumberType
      // object.permanentAddressDetails_address = element?.permanentAddressDetails.address
      object.reason = element?.reason

     
      // object.temporaryAddressDetails_address = element?.temporaryAddressDetails.address

      header = Object.keys(object);
      patientListDownload.push(object);
    });
    downloadExcel({
      fileName: "patient_sheet",
      sheet: "excel",
      tablePayload: {
        header,
        // accept two different data structures
        body: patientListDownload,
      },
    });
  };

 
  const getLogList = async () => {
    const res = await getpatientlistfunc();
    setLogList(res?.data);
  };
  const [pageNumber, setPageNumber] = useState(1);
  const handleOnChange = (e, page) => {
    setPageNumber(page);
    getpatientImport(page);
  };

  useEffect(() => {
    getpatientImport(1);
  }, []);

  return (
    <div className="create-package">
        <div
          className={props.expand1.empty === true ? "main1" : "main "}
          style={{ backgroundColor: "white" }}
        >
          <div className="breadCumContainer flex justify-start items-center h-9 w-full bg-o-color-one organ1">
            <ol className="ml-8 flex " style={{ listStyleType: "none" }}>
              <div className="flex mt-1">
                <li className="w-28 h-thirtyPix rounded-mysix flex justify-center items-center font-medium bg-white m-r-f orgbox">
                  <span>Bulk import jobs</span>
                </li>
              </div>
              <i className="right"></i>

              <div className="flex mt-1">
                <li className="w-28 h-thirtyPix rounded-mysix flex justify-center items-center font-medium bg-white m-r-f  rows breadback">
                  <span>View import logs</span>
                </li>
              </div>
            </ol>
          </div>

{/* <Breadcrums firsttab = {"Bulk import jobs"} secondtab= "View import logs"></Breadcrums> */}

         
          <div className="leftcontainer1 rounded-lg">
          <div className="m-5">
              <Button
                variant="outlined"
                onClick={() => getpatientImport(1)}
                startIcon={<RefreshIcon />}
              >
                Refresh
              </Button>
            </div>
            <div className="scrollit">
            <table
              className="a"
              style={{ width: "98%", marginLeft: "20px", height: "20%" }}
            >
              <thead>
                <tr className="m-b-f">
                  <th className=" m-r-f">File Name</th>
                  <th className=" m-r-f">Submitted By</th>
                  <th className=" m-r-f">Submit Time</th>
                  <th className=" m-r-f">Success</th>
                  <th className=" m-r-f">Failed</th>
                  <th className=" m-r-f">Status</th>
                  <th className=" m-r-f"></th>
                </tr>
              {logList?.content &&
                  logList?.content?.length > 0 &&
                  logList?.content?.map((item, i) => {
                    return (
                      <tr>
                        <td className="curser">
                          <a>{item?.filename!=undefined ? item?.filename : "patientimport_Unknownfile"}</a>
                        </td>
                        <td>
                          <a>{item.createdByName}</a>
                        </td>
                        <td>
                          <a>
                            {new Date(item.createdOn).toLocaleString("en-US")}
                          </a>
                        </td>
                        <td>
                          <a>{item.successCount ? item.successCount : 0}</a>
                        </td>
                        <td>{item.failureCount ? item.failureCount : 0}</td>
                        <td>
                          <a>{item.jobStatus}</a>
                        </td>
                        <td>
                          {/* {item.jobStatus.toLowerCase() ==
                          "validation completed"  ?  null : ( */}
                            <DownloadForOfflineIcon
                              onClick={() => downloadCreatedData(item._id)}
                            />
                          {/* )} */}
                        </td>
                      </tr>
                    );
                  })}
              </thead>
            </table>
            
          </div>
          </div>
          <div className="page">
              <Stack spacing={2}>
                <Pagination
                  count={logList?.totalPages}
                  color="primary"
                  onChange={(e, page) => handleOnChange(e, page)}
                />
              </Stack>
            </div>
        </div>
        <AdminCookieConsentBanner />
    </div>
  );
};

export default PatientStatusList;
