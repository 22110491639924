
import { useState } from "react";
import {useGetActiveDistrictValueMutation} from "../../../services/adminLogIn";



const ProgramNameLogic = () => {
    const [showerror, setErrorMessage] = useState(false);
    const [getDistrictValFun, { isLoading1, isSuccess1, isError1, ...districtlist }] = useGetActiveDistrictValueMutation();

    const [AllTextFieldState, setTextFieldState] = useState({
        is_update : false,
        programName : "",
        startDate : "",
        endDate : "",
        organizations: [],
        locations: [],
        state: '',
        city: '',
        district : '',
        postalCode: "",
        latitude: "",
        description: "",
        longitude: "",
        isDeleted: false,
        lastModifiedTime :""

       
    });

    const [open, setOpen] = useState(false);
    const [dropOrgState, setDropOrgState] = useState({

    });
    const [dropLocState, setDropLocState] = useState({

    });
    const getDistrictValFun1 = (value) => {
        getDistrictValFun(value)

    }
    const [dropRoleState, setDropRoleState] = useState([]);
    const [roleList, setRoleList] = useState([]);
    const [openFilterModal, setOpenFilterModal] = useState(false);
    const [isSearchEvent, setIsSearchEvent] = useState(false);
    const [status, setStatus] = useState("");
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleFilterModalOpen = () => { setOpenFilterModal(true); }
    const handleFilterModalClose = () => { setOpenFilterModal(false); }

    const [filterData, setFilterData] = useState({ classificationId: "", sampleName: "", filterStatus: "" });


    const [dropdownState, setDropdowntate] = useState({
        code: '',
        amount: '',
        repeats:'',
        shortDescription:'',
        longDescription:'',
        isActive: true
    });

    const [isError, setError] = useState({
        isNameError: false,
        isOrganizationError: false,
        isLocationError: false
       
    });

    

    // dropdown handler 
    const handleDropDownChange = (e, v) => {
        
        const value = v;
        if (e === 'orgname') {
            setError((prev) => ({
                ...prev,
                isOrganizationError: isError.isOrganizationError = false
            }))
            AllTextFieldState.organizations = value
            setDropOrgState(value);
            setTextFieldState(AllTextFieldState)
        }
        if (e === "locname") {
            setError((prev) => ({
                ...prev,
                isLocationError: isError.isLocationError = false
            }))
            AllTextFieldState.locations = value;
            setDropLocState(value);
            setTextFieldState(AllTextFieldState);
        }
        if (e === "state") {
            getDistrictValFun1(value)
            setTextFieldState((prev) => ({
                ...prev,
                [e]: value
            }))
            setTextFieldState((prev) => ({
                ...prev,
                ['city']: ''
            }))
        }
        if (e === 'gender') {
            AllTextFieldState.gender = value
            setTextFieldState((prev) => ({
                ...prev,
                ['gender']: value

            }))
        }
        if (e === 'salutation') {
            AllTextFieldState.salutation = value
            setTextFieldState((prev) => ({
                ...prev,
                ['salutation']: value

            }))
        }
        if (e === 'status') {
            handleOpen()

        }
        else {
            setTextFieldState({ ...AllTextFieldState, [e]: value })
        }

    }

    const halndleClickonProceed = () => {
        setTextFieldState((prev) => ({
            ...prev,
            ["isDeleted"]: !AllTextFieldState.isDeleted  

        }))
        setStatus(AllTextFieldState.isDeleted  ? "ACTIVE" : "INACTIVE")
        handleClose()
    }

    const resetErrorHandler = (e) => {
        const name = e.target.code;
        switch (name) {
            case "firstName":
                setError((prev) => ({
                    ...prev,
                    isNameError: isError.isNameError = false
                }))
                break;
    
            case "organizations":
                setError((prev) => ({
                    ...prev,
                    isOrganizationError: isError.isOrganizationError = false
                }))
                break;
           
                case "locations":
                    setError((prev) => ({
                        ...prev,
                        isLocationError: isError.isLocationError = false
                    }))
                    break;
                    case "templateName":
                        setError((prev) => ({
                            ...prev,
                            istemplateNameError: isError.istemplateNameError = false
                        }))
                        break;
                        case "body":
                            setError((prev) => ({
                                ...prev,
                                isbodyError: isError.isbodyError = false
                            }))
                            break;
    
        }
    }  
   
    const whenInputsChange = (e) => {
        const { name, value } = e.target;
        if (name === "firstName") {
            setError((prev) => ({
                ...prev,
                isNameError: isError.isNameError = false
            }))
            setTextFieldState((prev) => ({
            ...prev,
           
           
            }));
        }
      
        if (name === "postalCode") {
            if (value.length <= 8) {
                setTextFieldState((prev) => ({
                    ...prev,
                    [name]: value.replace(/[^0-9]/gi, "")

                }))
            }

        }
        else {
            setTextFieldState((prev) => ({
                ...prev,
                [name]: value

            }))
        }




    }
    
    // exporting all handler and state and etc
    const exportAll = {
        whenInputsChange,
        handleDropDownChange,
        //    dropsampleState,
        setError,
        AllTextFieldState,
        isError,
        resetErrorHandler,
        setTextFieldState,
        dropdownState,
        setDropdowntate,
        open,
        handleClose,
        handleOpen,
        setOpen,
        halndleClickonProceed,
        setErrorMessage,
        handleFilterModalOpen,
        handleFilterModalClose,
        openFilterModal,
        showerror,
        filterData,
        isSearchEvent,
        setIsSearchEvent,
        getDistrictValFun,
        districtlist,
        getDistrictValFun1

    }

    return exportAll;
}

export default ProgramNameLogic;
