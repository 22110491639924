import { useState } from "react";
import { useCreateUpdateCustomTaskMutation, useUpdatigtasktypeMutation } from "../../../services/adminLogIn";
import "react-toastify/dist/ReactToastify.css";


const TaskStatusLogic = () => {
  const [createUpdateCustomTaskStatus] = useCreateUpdateCustomTaskMutation();
  const [updatigtasktype] = useUpdatigtasktypeMutation();

  const [showerror, setErrorMessage] = useState(false);
  const [taskSubmitStatus, setTaskSubmitStatus] = useState(false);

  const [allTextFieldState, setAllTextFieldState] = useState({
    is_update: false,
    customTaskStatusId: "",
    customTaskStatus: "",
    taskStatus: "",
    status: "",
  });
  const [open, setOpen] = useState(false);
  const [isSearchEvent, setIsSearchEvent] = useState(false);
  const [status, setStatus] = useState("");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
 

  const [isError, setError] = useState({
    isCustomTaskStatusError: "",
    isTaskStatusError: "",
  });

  // dropdown handler
  const handleDropDownChange = (e, v) => {
    if (e === "status") {
      handleOpen();
    }
    if (e === "taskStatus") {
          setAllTextFieldState((prev) => ({
              ...prev,
              [e]: v,

          }))
      }
  };
  
  const halndleClickonProceed = () => {
    setAllTextFieldState((prev) => ({
      ...prev,
      ["status"]:allTextFieldState.status === "ACTIVE" ? "INACTIVE" : "ACTIVE",
    }));
    setStatus(allTextFieldState.status === "ACTIVE" ? "DELETED" : "ACTIVE");
    handleClose();
  };


  const resetErrorHandler = (e) => {
    const name = e.target.name;
    switch (name) {
      case "customTaskStatus":
        setError((prev) => ({
          ...prev,
          isNameError: (isError.isNameError = false),
        }));
        break;
    }
  };

  
  const whenInputsChange = (e) => {
    const { name, value } = e.target;
      setAllTextFieldState((prev) => ({
        ...prev,
        [name]: value,
      }));
    
  };
  // exporting all handler and state and etc
  const exportAll = {
    whenInputsChange,
    handleDropDownChange,
    allTextFieldState,
    isError,
    resetErrorHandler,
    setAllTextFieldState,
    open,
    handleClose,
    handleOpen,
    setOpen,
    halndleClickonProceed,
    setErrorMessage,
    showerror,
    isSearchEvent,
    taskSubmitStatus,
    setIsSearchEvent,
    setTaskSubmitStatus,
    createUpdateCustomTaskStatus,
    updatigtasktype,
    setError
    //    districtlist
  };

  return exportAll;
};

export default TaskStatusLogic;
