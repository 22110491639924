import { useState, useEffect, useRef } from "react";
import AdminHome from "../AdminHome";
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import FilterListIcon from '@mui/icons-material/FilterList';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Button from '@mui/material/Button';
import useOrgHook from "./OrgCusHook";
import EnhancedTable from "./Organtable";
import { useGetActiveOrgValueMutation, useGetActiveStateValueMutation, useGetActiveDistrictValueMutation } from "../../../services/adminLogIn";
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import { createStyles, makeStyles } from '@mui/styles';
import Autocomplete from '@mui/material/Autocomplete';
import "./Organ.css"
import { Grid, Paper } from "@mui/material";
const useStyles = makeStyles(theme => ({
  menuPaper: {
    maxHeight: 200
  }
}))



// /organization/active/{active}
// /organization/all
// organization/{{organization-id}}

const Organ = (props) => {
  const classes = useStyles();



  const [getActiveValFun, { isLoading, isSuccess, isError, data }] = useGetActiveOrgValueMutation();
  const [getStateValFun, { isLoading1, isSuccess1, isError1, ...data1 }] = useGetActiveStateValueMutation();
  // const [getDistrictValFun ,  {isLoading2 , isSuccess2 , isError2 ,...districtlist}] =  useGetActiveDistrictValueMutation();





  const { orgSubmitHandler, whenInputsChange, orgState, dropOrgState, orgAllTextFieldState, isOrgError,
    handleDropDownChange, createOrgRes, updateOrgRes, districtlist, resetErrorHandler, setOrgTextFieldState, setDropOrgState, getDistrictValFun1 } = useOrgHook();


  const [isFormVisible, setFormVisible] = useState({
    isOpen: false,
    isClose: true
  });

  const showFormActions = () => {
    if (!isFormVisible.isOpen) {
      setFormVisible((prev) => ({ ...prev, isOpen: isFormVisible.isOpen = true, isFormVisible, isClose: isFormVisible.isClose = false }));
    }
  }

  const hideForm = () => {
    setOrgTextFieldState({})
    if (isFormVisible.isOpen) {
      setFormVisible((prev) => ({ ...prev, isOpen: isFormVisible.isOpen = false, isFormVisible, isClose: isFormVisible.isClose = true }));

    }
  }

  const bredStyle = {
    fontSize: '12px'
  }





  useEffect(() => {
    getActiveValFun();
    getStateValFun();
    if (orgAllTextFieldState.state && orgAllTextFieldState.state.name != undefined) {
      getDistrictValFun1(orgAllTextFieldState.state)
    }
   
    //  getDistrictValFun();
  }, [])

  useEffect(() => {
    let data = {}
    data = orgAllTextFieldState.state
    getDistrictValFun1(data)
  }, [orgAllTextFieldState.id])

  useEffect(() => {
    if (createOrgRes.isSuccess) {
      setOrgTextFieldState({
        is_update: false,
        name: '',
        id: '',
        address: '',
        postalCode: '',
        country: '',
        phoneNumber: '',
        emailId: '',
        state: {},
        city: {},
      });

      setDropOrgState({});

      getActiveValFun();
    }

  }, [createOrgRes.isSuccess])

  useEffect(() => {
    if (updateOrgRes.isSuccess) {
     getActiveValFun();
     
    }

  }, [updateOrgRes.isSuccess])

  
  return (
    <>
      <AdminHome>
      
      </AdminHome>
    </>
  )
}

export default Organ;