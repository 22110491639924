import CryptoJS from "crypto-js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


export const getAdminActualValue = () => {

  if (sessionStorage.getItem("muik") === null || sessionStorage.getItem("muik") === "undefined" || sessionStorage.getItem("BxqArt") === null || sessionStorage.getItem("BxqArt") === "undefined") {

  } else {
    const getAdminIDFromLocalStorage = sessionStorage.getItem("muik");
    const getAdminDataFromLocalStorage = sessionStorage.getItem("BxqArt");
    const decodeAdminDataSemi = CryptoJS.AES.decrypt(getAdminDataFromLocalStorage, getAdminIDFromLocalStorage && getAdminIDFromLocalStorage)
    const actualAdminValueJson = decodeAdminDataSemi.toString(CryptoJS.enc.Utf8);
    const parseAdminValue = JSON.parse(actualAdminValueJson);
    return parseAdminValue;
  }
}

export const getTokensFromStorage = () => {
  const accessToken = sessionStorage.getItem("zExT1_a");
  const refreshToken = sessionStorage.getItem("e_Aophg");
  const resetToeknFlag = sessionStorage.getItem("resetTokenFlag");
  return [accessToken, refreshToken,resetToeknFlag];
}

export const decryptAdminId = () => {
  const adminId = sessionStorage.getItem("muik");
  const decAId = CryptoJS.AES.decrypt(adminId, process.env.REACT_APP_SECX_ISKEY.toString());
  const readAID = decAId.toString(CryptoJS.enc.Utf8);
  return readAID;

}

export const toasterFunction = (message,type) => {
  
  toast(message, {
    position: "top-center",
    autoClose: 2000,
hideProgressBar: true,
    type: type,
    closeOnClick: true,
    theme: "light",
  });
  return <>
  <ToastContainer></ToastContainer>
  </>;

}