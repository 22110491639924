import * as React from "react";
import { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Link from "@mui/material/Link";
import { DataGrid } from "@mui/x-data-grid";
import autoprefixer from "autoprefixer";
import { useGetencountertypeslistMutation } from "../../../services/adminLogIn";
import "../Organization/Mainhome.css";
import { Grid } from "@mui/material";
import "../table.css";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

const EncounterTypeList = (props) => {
  const { hasFocus, value, handelRowClick } = props;

  const handleClick = (row) => {

  };
  // const [data, setData] = useState([]);
  // useEffect(()=>{
  //   setData(props.content)
  //   // getActiveValFun();
  // },[])
  const handleOnChange = (event, page) => {
    props.getEncounterTypeList(page);
  };

  return (
    <Grid className="">
      <div class="scrollit">
        {props.userupdate===true &&
        props.encountertypeslist &&
        props?.encountertypeslist?.data?.length > 0 ? (
          <table
            style={{
              width: "90%",
              marginLeft: "20px",
              tableLayout: "fixed",
              height: "20vh",
            }}
          >
            <thead>
              <tr>
                <th className=" m-r-f" style={{ width: "50px" }}>
                  <input type="checkbox" id="" name="vehicle1" value="Bike" />
                </th>
                <th className=" m-r-f">Encounter Types</th>
                <th className=" m-r-f">Last Modified by</th>
                <th className=" m-r-f">Last Modified Date & Time</th>
                <th className=" m-r-f">Status</th>
              </tr>
            </thead>
            <tbody style={{ color: "#000000" }}>
              {props &&
                props.encountertypeslist &&
                props?.encountertypeslist?.data?.length > 0 &&
                props.encountertypeslist?.data.map((item, i) => {
                  return (
                    <tr
                      key={i}
                      style={{
                        cursor: "pointer",
                        color: "#000"
                      }}
                    >
                      <td>
                        <input type="checkbox" id={i} />
                      </td>
                      <td
                        className=" m-r-f"
                        onClick={(event) => handelRowClick(item)}
                      >
                        {item.name}
                      </td>
                      <td
                        className=" m-r-f"
                        onClick={(event) => handelRowClick(item)}
                      >
                        {item.lastModifiedByName}
                      </td>
                      <td
                        className=" m-r-f"
                      >
                        {new Date(item.lastModifiedTime).toLocaleString("en-US")}
                      </td>
                      <td
                        className=" m-r-f"
                      >
                        <span
                          className={item.status === "ACTIVE" ? "dotg" : "dotr"}
                        ></span>{" "}
                        {item.status === "ACTIVE" ? " Active" : " Inactive"}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        ) : (
          <div className="record">No Records Found</div>
        )}
      </div>
  
    </Grid>
  );
};

export default EncounterTypeList;
