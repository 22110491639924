import { useState } from "react";
import { useGetCreatingReportCategoryMutation } from "../../../services/adminLogIn";

const CreateRolesLogic = () => {
       const [showerror, setErrorMessage] = useState(false);
    const [AllTextFieldState, setAllTextFieldState] = useState({
        is_update: false,
        role: ''
    });
    
    const [open, setOpen] = useState(false);
    const [isSearchEvent, setIsSearchEvent] = useState(false);
     const [dropsampleidState, setDropsampleidState] = useState({
        sampleClassificationId: '', sampleClassificationName: ''
    });

    const [isOrgError, setError] = useState({
        isroleError: '',
    });


    const getDistrictValFun1 = (value) => {
        //   getDistrictValFun(value)
    }

    const resetErrorHandler = (e) => {
        const name = e.target.name;
        switch (name) {
            case "role":
                setError((prev) => ({
                    ...prev,
                    isroleError: isOrgError.isroleError = false
                }))
                break;
        }
    }

    // form on submit handleer 
  
    const whenInputsChange = (e) => {
        const { name, value } = e.target;
        if (name === "role") {
            setAllTextFieldState((prev) => ({
                ...prev,
                [name]: value.toLowerCase().replace(/[^a-z0-9-_]/gi, "")

            }))
           
        }
           
        }
       
    // exporting all handler and state and etc
    const exportAll = {
        whenInputsChange,
        setError,
        AllTextFieldState,
        isOrgError,
        resetErrorHandler,
        setAllTextFieldState,
        getDistrictValFun1,
        dropsampleidState,
        setDropsampleidState,
        open,
        setOpen,
        setErrorMessage,
        showerror,
        isSearchEvent,
        setIsSearchEvent,
        //    districtlist

    }

    return exportAll;
}

export default CreateRolesLogic;
