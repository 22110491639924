import { useState, useEffect, useRef } from "react";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import TextField from "@mui/material/TextField";
import CreateRolesLogic from "./createRolesLogic";
import CreateRolesList from "./createRolesList";
import { Button } from "@mui/material";
import {
  useCreateroledataMutation,
  useGetRoleListMutation,
} from "../../../services/adminLogIn";
import filter from "../../../images/filter.png";
import "../Organization/Mainhome.css";
import { Grid } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import Breadcrums from "../../global/breadcrums/Breadcrums";
import { toasterFunction } from "../../../globalFunctions/getLocalStorageData";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Tooltip from "@mui/material/Tooltip";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import { width } from "@mui/system";
import CircularProgress from "@mui/material/CircularProgress";
import AdminCookieConsentBanner from "../home/AdminCookieConsentBanner";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const CreateRoles = (props) => {
  const [getCreateRolesList] = useGetRoleListMutation();
  const [createroledata] = useCreateroledataMutation();
  const [roledataList, setroledataList] = useState([]);
  const [sampleReqRecordsLoading, setSampleReqRecordsLoading] = useState(false);
  const [access, setAccess] = useState(
    JSON.parse(sessionStorage.getItem("access"))
  );
  const [searchVal, setSearchVal] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [selectedPage, setSelectedPage] = useState(1);

  const creatroleList = async () => {
    setSampleReqRecordsLoading(true);
    let res = await getCreateRolesList();
    setSampleReqRecordsLoading(false);
    if (res?.data?.length != 0) {
      let finalresult = [...res.data].sort();
      setroledataList(finalresult);
    }
  };
  const {
    setError,
    whenInputsChange,
    AllTextFieldState,
    isOrgError,
    resetErrorHandler,
    setAllTextFieldState,
    setErrorMessage,
  } = CreateRolesLogic();
  const [isFormVisible, setFormVisible] = useState({
    isOpen: false,
    isClose: true,
  });
  const [id, setId] = useState("");

  const showFormActions = () => {
    if (access?.sampleadd == true) {
      setErrorMessage(false);
      setId("");
      if (!isFormVisible.isOpen) {
        setFormVisible((prev) => ({
          ...prev,
          isOpen: (isFormVisible.isOpen = true),
          isFormVisible,
          isClose: (isFormVisible.isClose = false),
        }));
        if (AllTextFieldState.is_update === false) {
          setId("");
        }
      }
    } else {
      toasterFunction(" No Permission", "error");
    }
  };

  const hideForm = () => {
    setErrorMessage(false);
    setAllTextFieldState({
      is_update: false,
      role: "",
    });
    if (isFormVisible.isOpen) {
      setFormVisible((prev) => ({
        ...prev,
        isOpen: (isFormVisible.isOpen = false),
        isFormVisible,
        isClose: (isFormVisible.isClose = true),
      }));
    }
    setError((prev) => ({
      ...prev,
      isroleError: (isOrgError.isroleError = false),
    }));
    setSearchVal("");
    setSelectedPage(1)
    creatroleList();
    setId("");
  };

  useEffect(() => {
    creatroleList();
  }, []);

  const bodySubmitHandler = async (e) => {
    e.preventDefault();
    console.log(AllTextFieldState.role);
    if (AllTextFieldState.role === "") {
      setError((prev) => ({
        ...prev,
        isroleError: (isOrgError.isroleError = true),
      }));
    } else {
      setError((prev) => ({
        ...prev,
        isroleError: (isOrgError.isroleError = false),
      }));
      const sendOrgDetails = {
        role: AllTextFieldState.role,
      };
      // if (AllTextFieldState.is_update === false) {

      const res = await createroledata(sendOrgDetails);
      if (res?.data) {
        hideForm();
        toasterFunction(
          res?.data ? "Created successfully" : "Update successfully",
          "success"
        );
      } else {
        toasterFunction(res?.error?.data?.message, "error");
      }

      setErrorMessage(true);
    }
  };

  const onSearchevent = (value) => {
    setSearchVal(value);
  };
  const [totalPage, setTotalPage] = useState(0);

  useEffect(() => {
    if (searchVal) {
      const filteredResults = roledataList.filter((item) =>
        item.toLowerCase().includes(searchVal.toLowerCase())
      );
      setSearchResults(filteredResults);
    } else {
      setSearchResults([]); // Reset the search results if the searchVal is empty
    }
  }, [searchVal]);

  return (
    <>
      {sampleReqRecordsLoading ? (
        <div style={{ position: "fixed", left: "50%", top: "50%" }}>
          <CircularProgress />
        </div>
      ) : (
        <div className={props.expand1.empty === true ? "main1" : "main"}>
          <Breadcrums
            firsttab={"Categories"}
            secondtab="Administration"
            thirdtab="Create Roles"
          ></Breadcrums>
          <ToastContainer />
          <Grid container mt={-2}>
            <Grid item xs={6}>
              <div
                className={
                  isFormVisible.isOpen
                    ? "leftcontainer rounded-lg"
                    : "leftcontainer1 rounded-lg"
                }
              >
                <Grid className=" p-7 ">
                  <TextField
                    label=""
                    onChange={(e) => onSearchevent(e.target.value)}
                    onKeyDown={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                    sx={{ m: 1, width: "25ch" }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchOutlinedIcon />
                        </InputAdornment>
                      ),
                    }}
                    value={searchVal}
                  />
                </Grid>
                <Grid className="editContainer rounded-radiusFourPix ">
                  <Grid
                    disabled={access?.sampleadd == false}
                    onClick={showFormActions}
                    className={
                      isFormVisible.isOpen ? "addIconchange" : "addIcon"
                    }
                  >
                    <span className=" p-2 m-r-f ">Add</span>
                    <AddCircleOutlineIcon
                      style={{
                        fontSize: "14px",
                        color: "#545454",
                        marginBottom: "3px",
                        color: `${
                          isFormVisible.isOpen ? "#ffffff" : "#545454"
                        }`,
                      }}
                    />
                  </Grid>
                  <Grid className="addIcon">
                    <span className="p-2 m-r-f">Filter</span>
                    <img
                      src={filter}
                      alt="filter.png"
                      style={{
                        position: "absolute",
                        left: "90%",
                        right: "20px",
                        top: "15px",
                        bottom: "95px",
                        width: "11px",
                        paddingTop: "3px",
                      }}
                    ></img>
                  </Grid>
                </Grid>
                <>
                  <CreateRolesList
                    setErrorMessage={setErrorMessage}
                    setTotalPage={setTotalPage}
                    totalPage={totalPage}
                    setId={setId}
                    selectedPage= {selectedPage}
                    setSelectedPage = {setSelectedPage}
                    id={id}
                    roledataList={searchVal ? searchResults : roledataList}
                    creatroleList={creatroleList}
                    showFormActions={showFormActions}
                    setAllTextFieldState={setAllTextFieldState}
                    sampleview={access?.sampleview}
                    sampleupdate={access?.sampleupdate}
                  />
                </>
              </div>
            </Grid>
            {isFormVisible.isOpen ? (
              <Grid item xs={6}>
                <div className="rightcontainer rounded-lg">
                  <div className="overflow-y-auto o-form-container">
                    <>
                      <div className="font-normal text-o-form-label-color flex justify-between items-center pl-5 pr-1 h-10 mainhead">
                        <h1>Create Role</h1>
                      </div>

                      <form autoComplete="off" onSubmit={bodySubmitHandler}>
                        <div className=" pl-5 OrgNameContainer">
                          <h3
                            className="text-o-form-label-color font-normal "
                            id="Org"
                          >
                            Role Name
                            <span style={{ color: "red" }}> *</span>
                          </h3>

                          <TextField
                            onFocus={(e) => resetErrorHandler(e)}
                            autoComplete="OFF"
                            error={isOrgError.isroleError === true}
                            helperText={
                              isOrgError.isroleError
                                ? "Valid (role) Required !"
                                : ""
                            }
                            onChange={(e) => whenInputsChange(e)}
                            name="role"
                            value={AllTextFieldState.role}
                            style={{ width: "360px" }}
                            id="standard-basic"
                            label=""
                            variant="standard"
                          />

                          <Tooltip
                            title={
                              "Please enter lower-case letters. Number, '-' , '_' and space are not allowed. "
                            }
                            followCursor
                          >
                            <div>
                              <span className="round">i</span>
                              <CircleOutlinedIcon
                                style={{
                                  color: "#0F4391",
                                  position: "relative",
                                  bottom: "54px",
                                  left: "83px",
                                  height: "17px",
                                  width: "17px",
                                }}
                              />
                            </div>
                          </Tooltip>

                          <div className="mt-10 flex justify-center   towButtonContainer h-50">
                            <Button
                              onClick={hideForm}
                              type="submit"
                              variant="contained"
                              disableElevation
                              style={{
                                marginRight: "10px",
                                borderColor: "#0F4391",
                                width: "99px",
                                height: "39px",
                                borderRadius: "9999px",
                                backgroundColor: "#E8FAFF",
                                color: "#0F4391",
                                border: "1px solid #0F4391",
                              }}
                            >
                              CANCEL
                            </Button>

                            <Button
                              type="submit"
                              variant="contained"
                              disableElevation
                              style={{
                                borderColor: "#0F4391",
                                width: "99px",
                                height: "39px",
                                borderRadius: "9999px",
                                backgroundColor: "#E8FAFF",
                                color: "#0F4391",
                                border: "1px solid #0F4391",
                              }}
                            >
                              SAVE
                            </Button>
                          </div>
                        </div>
                      </form>
                    </>
                  </div>
                </div>
              </Grid>
            ) : (
              ""
            )}
          </Grid>
        </div>
        
      )}
      <AdminCookieConsentBanner />
    </>
  );
};

export default CreateRoles;
