import { useState, useEffect } from "react";
import { useGetActiveLocValueMutation } from "../../../services/adminLogIn";
import { LoggerFunction } from "../../../logger/logger";
const serviceFormdata = {
  is_update: false,
  name: "",
  serviceRequestMasterId: "",
  description: "",
  // sample: "",
  subject: "",
  serviceRequestCategoryId: "",
  supportinglocations: [],
  supportingLocationsForTable: [],
  instructionToPerformer: [],
  specialInstructionsToPerformer: "",
  reasonCode: "",
  reasonDescription: "",
  specimen: "",
  quantity: {
    value: "",
    comparator: "<",
    unit: "ml",
  },
  supportingOrganisations: [],
  laterality: [],
  status: "ACTIVE",
};

const createPackageErrorInitState = {
  isNameError: "",
  isDescriptionError: "",
  isLocPriceTextValError: "",
  isDropLocError: "",
  isSpecimen: "",
  isServiceRequestCategoryId: "",
  isSupportingOrg: "",
  isSupportingLocations: "",
  locationAndPriceingError: "",
};

const useSamplelogic = () => {
  const [serviceFormFields, setServiceFormFields] = useState(serviceFormdata);
  const [createServiceError, setCreatePackageError] = useState(
    createPackageErrorInitState
  );
  const [locpriceList, setLocPriceList] = useState([]);
  const [price, setLocPrice] = useState([]);
  const [procedureCode, setProcedureCode] = useState([]);
  const [bodysiteCode, setBodysiteCode] = useState([]);
  const [getActiveValFun] = useGetActiveLocValueMutation();
  const [locPriceData, setLocPriceData] = useState();
  const [locMasterValues, setLocMasterValues] = useState([]);
  const [spacimenname, setSpecimenName] = useState({});
  const [showerror, setErrorMessage] = useState(false);
  const [open, setOpen] = useState(false);
  const [statusChange, setStatusChange] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const handleFilterModalOpen = () => {
    setOpenFilterModal(true);
  };
  const handleFilterModalClose = () => {
    setOpenFilterModal(false);
    // resetFilterData();
  };
  const [filterData, setFilterData] = useState({
    serReqCategory: "",
    serReqName: "",
    filterStatus: "",
    sample: "",
    modality: "",
    laterality: "",
    supportingOrganization: "",
    supportingLoc: "",
  });
  const [isSearchEvent, setIsSearchEvent] = useState(false);
  const checkAlphaNumericAndSpecialChars = (value) => {
    const regex = new RegExp("^(?!d+$)(?:[a-zA-Z0-9][a-zA-Z0-9 @&$]*)?$");
    return regex.test(value);
  };
  const handleInputChange = (e, i) => {
    const { name, value } = e.target;
    if (name === "name") {
      setServiceFormFields((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
    if (name === "description" || name === "reasonDescription") {
      const isNameValidstring = checkAlphaNumericAndSpecialChars(value);
      if (value.length <= 250) {
        setServiceFormFields((prev) => ({
          ...prev,
          [name]: value,
        }));
      } else {
        setServiceFormFields((prev) => ({
          ...prev,
          [name]: value.substring(0, 250),
        }));
      }
    }
    if (name === "price") {
      if (value.length <= 6) {
        const allowOnlyNumbers = new RegExp("^[0-9]*$");
        const isPriceValid = allowOnlyNumbers.test(value);
        if (isPriceValid) {
          let arr = [...locpriceList];
          var newdata = arr.map((item, index) => {
            var temp = Object.assign({}, item);
            if (index === i) {
              temp["amount"] = value;
            }
            return temp;
          });
          setLocPriceList([...newdata]);
          if (newdata.length) {
            setCreatePackageError((prev) => ({
              ...prev,
              locationAndPriceingError:
                (createServiceError.locationAndPriceingError = false),
            }));
          }
        }
        // arr[i].system = value;
      }
    }
    if (name === "subject") {
      setServiceFormFields((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
    // if (name === "description") {

    //     setServiceFormFields((prev) => ({
    //       ...prev,
    //       [name]: value,
    //     }));

    // }
    if (name === "instructionToPerformer") {
      if (value.length <= 150) {
        let data = [];
        data.push(value);

        setServiceFormFields((prev) => ({
          ...prev,
          [name]: data,
        }));
      }
    }
    if (name === "specialInstructionsToPerformer") {
      setServiceFormFields((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
    if (name === "reasonCode") {
      setServiceFormFields((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
    // if (name === "reasonDescription") {

    //   setServiceFormFields((prev) => ({
    //     ...prev,
    //     [name]: value,
    //   }));

    // }
    if (name === "quantity") {
      let quantity = {};
      quantity.value = value.replace(/[^0-9]/gi, "");
      quantity.comparator = "<";
      quantity.unit = "ml";
      setServiceFormFields((prev) => ({
        ...prev,
        ["quantity"]: { ...quantity },
      }));
    }
  };

  const handleProceed = () => {
    setStatusChange(true);
    handleClose();
  };
  const handleDropDownChange = (field, e) => {
    if (field === "modality") {
      setServiceFormFields((prev) => ({
        ...prev,
        [field]: e,
      }));
    }
    if (field === "laterality") {
      setServiceFormFields((prev) => ({
        ...prev,
        [field]: e,
      }));
    }
    if (field === "modality") {
      setServiceFormFields((prev) => ({
        ...prev,
        [field]: e,
      }));
    }
  };

  const handleInputChangeProc = (e, field, i) => {
    const { name, value } = e.target;
    if (field === "proc") {
      const arr = [...procedureCode];

      var newdata = arr.map((item, index) => {
        var temp = Object.assign({}, item);
        if (index === i) {
          temp[name] = value;
          temp["id"] = i;
        }
        return temp;
      });
      // arr[i].system = value;

      setProcedureCode([...newdata]);

      //   }
    }
    if (field === "body") {
      let arr = [...bodysiteCode];
      var newdata = arr.map((item, index) => {
        var temp = Object.assign({}, item);
        if (index === i) {
          temp[name] = value;
          temp["id"] = i;
        }
        return temp;
      });
      setBodysiteCode([...newdata]);

      //   }
    }
  };

  const handleDropDownPriceLoc = (field, i, v) => {
    const value = v;

    if (field === "serviceRequestCategoryId") {
      setServiceFormFields((prev) => ({
        ...prev,
        [field]: value,
      }));
      if (value.length) {
        setCreatePackageError((prev) => ({
          ...prev,
          isServiceRequestCategoryId: false,
        }));
      }
    }
    if (field === "specimen") {
      setServiceFormFields((prev) => ({
        ...prev,
        [field]: value,
      }));
      setCreatePackageError((prev) => ({ ...prev, isSpecimen: false }));
      setSpecimenName(value);
    }
    if (field === "loc") {
      // const arr = locPriceData.filter((item) => item.name.trim() === value.trim())
      let arr = [...locpriceList];
      console.log(value);

      var newdata = arr.map((item, index) => {
        var temp = Object.assign({}, item);
        if (index === i) {
          temp["locationId"] = value.id;
          temp["name"] = value.name;
          temp["amount"] = item.amount ? item.amount : "";
        }
        return temp;
      });
      setLocPriceList([...newdata]);
      // locpriceList.forEach((element, index) => {
      //   if (i === index) {
      //     element.locationId = value;
      //     element.amount = "";
      //     element.name = value;
      //     setLocMasterValues([...locMasterValues, value]);
      //   }
      // });
      if (value) {
        const arrLoc = serviceFormFields.supportingLocationsForTable.filter(
          (item) => item.id !== value.id
        );
        setServiceFormFields((prev) => ({
          ...prev,
          supportingLocationsForTable: arrLoc,
        }));
      } else {
        const arrOptions = serviceFormFields.supportinglocations.filter(
          (item) => item.id.trim() === locpriceList[i].id.trim()
        );
        setServiceFormFields((prev) => ({
          ...prev,
          supportingLocationsForTable: [
            ...serviceFormFields.supportingLocationsForTable,
            ...arrOptions,
          ],
        }));
      }
    }

    if (field === "locations") {
      setServiceFormFields((prev) => ({
        ...prev,
        supportinglocations: value,
        supportingLocationsForTable: [
          ...serviceFormFields.supportingLocationsForTable,
          value[value.length - 1],
        ],
      }));
      if (value.length) {
        setCreatePackageError((prev) => ({
          ...prev,
          isSupportingLocations: false,
        }));
      }
    }

    if (field === "organization") {
      setServiceFormFields((prev) => ({
        ...prev,
        supportingOrganisations: value,
      }));
      if (value.length) {
        setCreatePackageError((prev) => ({ ...prev, isSupportingOrg: false }));
      }
    }

    if (field === "status") {
      handleOpen();
      setServiceFormFields((prev) => ({
        ...prev,
        [field]: value === "ACTIVE" ? "ACTIVE" : "INACTIVE",
      }));
    }
  };

  const addRow = (value) => {
    if (value === "loc") {
      const data = {
        locationId: "",
        amount: "",
        name: "",
        currency: "INR",
        etaDetails: {
          value: "4",
          units: "HOURS",
        },
      };
      setLocPriceList([...locpriceList, data]);
      const priceObj = {
        id: "",
        value: "",
      };
      setLocPrice([...price, priceObj]);
    }
    if (value === "procedure") {
      const data = {
        system: "",
        code: "",
        description: "",
        url: "",
      };
      setProcedureCode([...procedureCode, data]);
    }
    if (value === "body") {
      const data = {
        system: "",
        code: "",
        description: "",
        site: "",
        url: "",
      };
      setBodysiteCode([...bodysiteCode, data]);
    }
  };

  const deleteRow = (i, field) => {
    if (field === "loc") {
      const arr = locpriceList.filter((item, index) => index !== i);
      const priceArr = price.filter((item, index) => index !== i);
      setLocPriceList(arr);
      setLocPrice(priceArr);
      const arrOptions = serviceFormFields.supportinglocations.filter(
        (item) => item.trim() === locpriceList[i].name.trim()
      );
      setServiceFormFields((prev) => ({
        ...prev,
        supportingLocationsForTable: [
          ...serviceFormFields.supportingLocationsForTable,
          ...arrOptions,
        ],
      }));
    }
    if (field === "body") {
      const arr = bodysiteCode.filter((item, index) => index !== i);
      setBodysiteCode(arr);
    }
    if (field === "proc") {
      const arr = procedureCode.filter((item, index) => index !== i);
      setProcedureCode(arr);
    }
  };

  const resetErrorHandler = (e) => {
    const { name } = e.target;
    if (name === "name") {
      setCreatePackageError((prev) => ({ ...prev, isNameError: false }));
    }
    if (name === "serviceRequestCategoryId") {
      setCreatePackageError((prev) => ({
        ...prev,
        isServiceRequestCategoryId: false,
      }));
    }
    if (name === "specimen") {
      setCreatePackageError((prev) => ({ ...prev, isSpecimen: false }));
    }
    if (name === "isSupportingOrg") {
      setCreatePackageError((prev) => ({ ...prev, isSupportingOrg: false }));
    }
    if (name === "isSupportingLocations") {
      setCreatePackageError((prev) => ({
        ...prev,
        isSupportingLocations: false,
      }));
    }
  };

  const getPackageTransformedData = () => {
    serviceFormFields.serviceRequestMasterId =
      serviceFormFields.is_update === false
        ? "SERVICE-" +
          serviceFormFields.name
            .split(" ")
            .join("-")
            .toUpperCase()
            .replace(/[^a-zA-Z0-9 ]/g, "-")
        : serviceFormFields.serviceRequestMasterId;

    const data = serviceFormFields;

    return {
      ...data,
      locationSpecificDetails: locpriceList,
      procedureCode: procedureCode,
      bodySiteCode: bodysiteCode,
    };
  };

  const geServicereqData = async () => {
    const locRes = await getActiveValFun();
    var log = await LoggerFunction();
    if (locRes?.data?.content)
      log.info({ data: "Data get successfully", message: locRes.data.content });
    if (locRes?.error) log.error(locRes?.error);
    if (locRes?.data?.content) {
      setLocPriceData(locRes.data.content);
    }
  };
  const handleFilterInputChange = (e) => {
    setFilterData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleFilterValueChange = (name, value) => {
    setFilterData((prev) => ({ ...prev, [name]: value }));
  };

  const resetFilterData = () => {
    setFilterData({
      serReqCategory: "",
      serReqName: "",
      filterStatus: "",
      sample: "",
      modality: "",
      laterality: "",
      supportingOrganization: "",
      supportingLoc: "",
    });
  };

  useEffect(() => {
    geServicereqData();
  }, []);

  const exportAll = {
    serviceFormFields,
    createServiceError,
    handleInputChange,
    procedureCode,
    resetErrorHandler,
    addRow,
    setServiceFormFields,
    deleteRow,
    handleDropDownPriceLoc,
    locpriceList,
    getPackageTransformedData,
    locMasterValues,
    price,
    handleInputChangeProc,
    handleFilterModalOpen,
    handleFilterModalClose,
    openFilterModal,
    bodysiteCode,
    handleDropDownChange,
    setErrorMessage,
    setBodysiteCode,
    setProcedureCode,
    setLocPriceList,
    showerror,
    handleOpen,
    handleClose,
    handleProceed,
    statusChange,
    setStatusChange,
    filterData,
    isSearchEvent,
    resetFilterData,
    handleFilterInputChange,
    handleFilterValueChange,
    setIsSearchEvent,
    open,
    serviceFormdata,
    setCreatePackageError,
    spacimenname,
    setSpecimenName,
  };

  return exportAll;
};

export default useSamplelogic;
