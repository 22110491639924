import  {useState , useEffect} from "react";
import logo from "../../../images/logo.svg";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import useLogicHook from "./logicHook";
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import {Link, NavLink , Navigate , useNavigate}  from "react-router-dom"; 



const ChangePassword = ()=>{
    const {  userInput , resetErrorMsg , handleChangePasswordForm , isError , userDetails , adminChangePasswordResponse } = useLogicHook();

    
    
    return(
         <>
                 {/* root-container-for-form-start */}
          <div className="md:w-2/3 lg:w-1/2 w-mywidth mx-auto my-5 h-custom-height-2">
              {/* container for logo start */}
             <div className="logocontainer  flex justify-center items-center mx-auto mt-5 hus w-6/12  h-14">
                  <figure>
                     <img src={logo} alt="karkinos Logo" />
                 </figure>
             </div>
              {/* container for logo end */}
                
               {/*  sub-first container for form  start */}
             <div className="formcontainer w-4/5 sm:w-3/4 mx-auto my-10 h-2/ ">
                    {/* sign in heading container  start*/}
                   <div className="heading text-xl font-light flex justify-center ">
                        <span className="font-medium text-gray-500">Change Password</span>
                   </div>

                   <div className="warningContainer text-base justify-start items-center pt-5">
                   <span className="font-medium text-gray text-sm block text-center">Strong password required Enter 8-16 characters. Do Not</span>
                   <span className="font-medium text-gray text-sm block text-center">include common words or names. Combine uppercase </span>
                   <span className="font-medium text-gray text-sm block text-center"> letters , lowercase letters, numbers, & symbols</span>

                   </div>
                    {/* sign in heading container  end*/}

                   {/* second container for form start  */}
                <div className={`inputcontainer w-mywidth  sm:w-3/4 mx-auto my-6  border border-solid border-bordercus rounded-myrounded  `}>
                       
                       {/* main-form start */}

                     <form onSubmit={(e)=>handleChangePasswordForm(e)} autoComplete="off">  
                        {/* container-for-user-email-start                     */}
                        <div className="emailcontainer w-mywidth-2 m-4  h-16">
                            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                                <TextField  onFocus={(e)=>resetErrorMsg(e)} error={isError.userPasswordError === true} helperText={isError.userPasswordError ? "Valid Password is Required!" : ""} onChange={(e)=> userInput(e)} name="password" value={userDetails.password} autoComplete="off" type="text" id="password" label="New Password" variant="standard" style={{width:'100%'}} />
                            </Box>
                        </div>
                        {/* container-for-user-email-end                     */}
                              
                             {/* container-for-user-password-start */}

                            <div className="emailcontainer w-mywidth-2 m-4  h-16">

                                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                                    <TextField  onFocus={(e)=>resetErrorMsg(e)} error={isError.userConfirmPasswordError === true} helperText={isError.userConfirmPasswordError ? "Password Not Matching!" : ""} onChange={(e)=> userInput(e)} name="confirmPassword" value={userDetails.confirmPassword} autoComplete="off" type="password" id="confirmPassword" label="Confirm Password" variant="standard" style={{width:'100%'}} />
                                </Box>
                            </div>

                            {/* container-for-user-password-end */}
                              
                             {/* container-for-sing-in-button-start  */}
                              
                               <div className="progressBar pb-3 flex justify-center items-center" >
                                    {
                                        adminChangePasswordResponse.isError ?
                                        <Alert severity="error" color="warning">
                                            Opps... Some Error Occured  
                                        </Alert>
                                        : adminChangePasswordResponse.isLoading ?
                                        <CircularProgress />
                                        : 
                                         
                                        adminChangePasswordResponse.isSuccess ?
                                        <Navigate to="/dashboard"  state={""}   />
                                        :null 

 
                                        
                                    }
                                 
                               </div>


                            <div className="buttoncontainer m-3 w-1/2 mx-auto rounded-full  h-11">
                                   
                                    <Button type="submit" variant="contained" disableElevation style={{borderColor: '#0F4391' ,  width:'100%' , height:'100%' , borderRadius:'9999px'  , backgroundColor:'#E8FAFF' , color:'#0F4391' }}>
                                        SUBMIT
                                    </Button> 
                                   
                            </div>
                             {/* container-for-sing-in-button-end */}
                             
                             

                        </form>
                        {/* main-form end */}

                </div>
                  {/* second container for form start  */}
                  
               {/* container-for-forgot-password-start */}
               <div className="linkcontainer text-center hover:text-blue-500  mx-auto my-5 w-1/2 ">
                                <NavLink to="/dashboard" style={{textDecoration:'underline'}}>
                                  Go to dashboard
                                </NavLink>
                                
                            </div>
                            {/* container-for-forgot-password-end */}
                       
                        
             </div>
              {/*  sub-first container for form  end */}
             
               
             

          </div>
           {/* root-container-for-form-end */}
        </>

       
     )

     
}

export default ChangePassword;



