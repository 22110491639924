import React from "react";
import ReactDom from "react-dom";
import App from "./app/App";
import "./index.css";
import sws from "./sws";
import {Provider} from "react-redux";
import {store} from "./store/store";
import "./fonts/Montserrat-Bold.ttf";
import "./app/app.css";

const rootId = document.getElementById("root");
const render = ReactDom.render;
const displayMain = render;
const rootApp = 
<Provider store={store}><App/></Provider> ;
displayMain(rootApp , rootId);
// sws(); 



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
