import * as React from "react";
import { useState } from "react";
import { Grid } from "@mui/material";
import "../table.css";
import "react-toastify/dist/ReactToastify.css";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

const CreateRolesList = (props) => {
  const { roledataList, selectedPage, setSelectedPage } = props;

  const handleClick = (row, i) => {
    // if (props.sampleupdate) {
    //   let data = {};
    //   data.is_update = true;
    //   props.setAllTextFieldState({ "role": row, is_update: true });
    //   props.showFormActions();
    //   props.setErrorMessage(false);
    //   props.setId(i);
    // }
  };

  const itemsPerPage = 20;
  const totalRoles = roledataList?.length;
  const totalpages = Math.ceil(totalRoles / itemsPerPage);

  const handlePageChange = (event, page) => {
    setSelectedPage(page);
  };

  const start = (selectedPage - 1) * itemsPerPage;
  const end = Math.min(start + itemsPerPage, totalRoles);

  return (
    <Grid className="">
      <div className="scrollit">
        {totalRoles > 0 ? (
          <table
            className="b "
            style={{
              width: "60%",
              marginLeft: "20px",
              tableLayout: "fixed",
              height: "20vh",
            }}
          >
            <thead style={{ position: "sticky", zIndex: "1", top: "0" }}>
              <tr>
                <th className="m-r-f">Role Name</th>
              </tr>
            </thead>
            <tbody>
              {roledataList?.slice(start, end).map((item, i) => {
                const rowIndex = start + i;
                return (
                  <tr
                    key={rowIndex}
                    className="tablebor"
                    style={{
                      cursor: "pointer",
                      backgroundColor: props.id === rowIndex ? "rgb(15, 67, 145)" : "",
                      color: props.id === rowIndex ? "white" : "",
                    }}
                  >
                    <td
                      className="m-r-f"
                      onClick={(event) => handleClick(item, rowIndex)}
                      style={{ cursor: "pointer" }}
                    >
                      <a>{item}</a>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <div className="record">No Records Found</div>
        )}
      </div>
      {totalRoles > 0 && (
        <div className="pagesam pt-1 m-1">
          <Stack spacing={2}>
            <Pagination
              count={totalpages}
              color="primary"
              page={selectedPage}
              onChange={handlePageChange}
            />
          </Stack>
          <div className="pagenum">
            <span className="ml-2">
              {start + 1} - {end} of {totalRoles}
            </span>
          </div>
        </div>
      )}
    </Grid>
  );
};

export default CreateRolesList;
