import {configureStore} from "@reduxjs/toolkit";
import {setupListeners} from "@reduxjs/toolkit/query";
import {adminLogInRequest} from "../services/adminLogIn";
import adminLogReducer from "../features/adminLogged";

export const store = configureStore({
    reducer:{
        [adminLogInRequest.reducerPath]:adminLogInRequest.reducer,
        adminLog:adminLogReducer

    },

    middleware:(getDefaultMiddleware)=>
     getDefaultMiddleware().concat(adminLogInRequest.middleware)
})

setupListeners(store.dispatch);