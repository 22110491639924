// import { LocalConvenienceStoreOutlined } from "@mui/icons-material";
import { useState } from "react";
// import isEmail from 'validator/es/lib/isEmail';
// import isMobilePhone from 'validator/es/lib/isMobilePhone';
import { useCreateTagMasterMutation, useCreatingSampleMutation, useUpdatigsampleMutation } from "../../../services/adminLogIn";

const MedicineFrequencyLogic = () => {


    const [showerror, setErrorMessage] = useState(false);

    const [AllTextFieldState, setTextFieldState] = useState({
        is_update: false,
        code: '',
        amount: '',
        repeats:'',
        shortDescription:'',
        longDescription:'',
        isActive: "ACTIVE"
    });


    const [open, setOpen] = useState(false);
    const [openFilterModal, setOpenFilterModal] = useState(false);
    const [isSearchEvent, setIsSearchEvent] = useState(false);
    const [status, setStatus] = useState("");
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleFilterModalOpen = () => { setOpenFilterModal(true); }
    const handleFilterModalClose = () => { setOpenFilterModal(false); }

    const [filterData, setFilterData] = useState({ classificationId: "", sampleName: "", filterStatus: "" });


    const [dropdownState, setDropdowntate] = useState({
        code: '',
        amount: '',
        repeats:'',
        shortDescription:'',
        longDescription:'',
        isActive: true
    });

    const [isError, setError] = useState({
        iscodeError: false,
        isamountError: false,
        isrepeatsError: false,
        isshortDescriptionError: false,
        islongDescriptionError: false,
       
    });

    

    // dropdown handler 
    const handleDropDownChange = (e, v) => {
        const value = v;
      
        setError((prev) => ({
            ...prev,
            isrepeatsError: isError.isrepeatsError = false,
          }))
            setTextFieldState((prev) => ({
                ...prev,
                [e]: value,
            }))
            
        

    }

    const halndleClickonProceed = () => {
        setTextFieldState((prev) => ({
            ...prev,
            ["isActive"]: AllTextFieldState.isActive === "ACTIVE" ? "ACTIVE" : "INACTIVE"

        }))
        setStatus(AllTextFieldState.isActive === "ACTIVE" ? "ACTIVE" : "INACTIVE")
        handleClose()
    }


    const resetErrorHandler = (e) => {
        const name = e.target.code;
        switch (name) {
            case "code":
                setError((prev) => ({
                    ...prev,
                    iscodeError: isError.iscodeError = false
                }))
                break;

            case "amount":
                setError((prev) => ({
                    ...prev,
                    isamountError: isError.isamountError = false
                }))
                break;
           
                case "repeats":
                    setError((prev) => ({
                        ...prev,
                        isrepeatsError: isError.isrepeatsError = false
                    }))
                    break;
                    case "shortDescription":
                        setError((prev) => ({
                            ...prev,
                            isshortDescriptionError: isError.isshortDescriptionError = false
                        }))
                        break;
                        case "longDescription":
                            setError((prev) => ({
                                ...prev,
                                islongDescriptionError: isError.islongDescriptionError = false
                            }))
                            break;

        }
    }

   
    const whenInputsChange = (e) => {
        const { name, value } = e.target;
      
         if (name === "amount") {
            setError((prev) => ({
                ...prev,
                isamountError: isError.isamountError = false
            }))
                setTextFieldState((prev) => ({
                ...prev,
                [name]: value.replace(/[^0-9]/gi, "")

            }))
        
        }
       else if (name === "longDescription") {
        setError((prev) => ({
            ...prev,
            islongDescriptionError: isError.islongDescriptionError = false
        }))
       
            if (value.length <= 250) {
                setTextFieldState((prev) => ({
                ...prev,
                [name]: value

            }))
        }
        }
       else if (name === "code") {
        setError((prev) => ({
            ...prev,
            iscodeError: isError.iscodeError = false
        }))
            if (value.length <= 150) {
                setTextFieldState((prev) => ({
                ...prev,
                [name]: value

            }))
        }
        }
       else if (name === "shortDescription") {
        setError((prev) => ({
            ...prev,
            isshortDescriptionError: isError.isshortDescriptionError = false
        }))
            if (value.length <= 150) {
                setTextFieldState((prev) => ({
                ...prev,
                [name]: value

            }))
        }
        }
        else {
            setTextFieldState((prev) => ({
                ...prev,
                [name]: value

            }))
        }




    }
    
    // exporting all handler and state and etc
    const exportAll = {
        whenInputsChange,
        handleDropDownChange,
        //    dropsampleState,
        setError,
        AllTextFieldState,
        isError,
        resetErrorHandler,
        setTextFieldState,
        //    setDropsampleState,
        dropdownState,
        setDropdowntate,
        open,
        handleClose,
        handleOpen,
        setOpen,
        halndleClickonProceed,
        setErrorMessage,
        handleFilterModalOpen,
        handleFilterModalClose,
        openFilterModal,
        showerror,
        filterData,
        isSearchEvent,
        setIsSearchEvent,
        //    districtlist

    }

    return exportAll;
}

export default MedicineFrequencyLogic;
