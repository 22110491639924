import * as React from 'react';
import { useState, useEffect, useRef } from "react";
import { Grid } from '@mui/material';
import "../table.css"
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

const MedicinefrequencyList = (props) => {
  
  const { hasFocus, value } = props;
  const [id, setId] = useState(0);


  const handleClick = (row) => {
    props.setId(row.code)  
    setId(row.code)
    // props.getUserByIdFunct(row.lastModifiedBy,row.tagMasterId)
    props.showFormActions()
    props.setTextFieldState({...row , 'is_update':true})
   
  }

  const handleOnChange = (event, page) => {
    props.getMedicineDosagePage(page)

  }; 


  return (
    <Grid className="" >
      <div class="scrollit">
      { props.tagsview===true && props.MedicineFrequencyList && props.MedicineFrequencyList.length > 0  ?
    
          <table className='b ' style={{ width: '90%', marginLeft: '20px', tableLayout: "fixed", height: "20vh" }}>
            <thead style={{ position: 'sticky', zIndex: '1', top: '0' }}>
              <tr>
                <th className=" m-r-f" style={{ width: '50px' }}><input type="checkbox" id="" name="vehicle1" value="Bike" /></th>
                <th className=" m-r-f">Code</th>
                <th className=" m-r-f">Amount</th>
                <th className=" m-r-f">Repeats</th>
                <th className=" m-r-f">Short Description</th>
                <th className=" m-r-f">Status</th>


              </tr>
            </thead>
            <tbody>

              {props.MedicineFrequencyList&& props.MedicineFrequencyList && props.MedicineFrequencyList.length > 0 && props.MedicineFrequencyList.map((item, i) => {
                return (
                  //
                  <tr key={i} className="tablebor"  style={{ cursor: 'pointer', backgroundColor: id === item.code ? "rgb(15, 67, 145)" : "", color: id === item.code ? "white" : "" }} >
                    <td  >< input type="checkbox" id={i} /></td>
                    <td className="  m-r-f" onClick={event => handleClick(item)} ><a>{item.code}</a></td>
                    <td className="  m-r-f" onClick={event => handleClick(item)} ><a>{item.amount}</a></td>
                    <td className="  m-r-f" onClick={event => handleClick(item)} ><a>{item.repeats}</a></td>
                    <td className=" m-r-f" onClick={event => handleClick(item)}><a>{item.shortDescription}</a></td>
                    <td
                        className=" m-r-f"
                        style={{ paddingLeft: "" }}
                        onClick={(e) => handleClick(e, item)}
                      >
                        {item.isActive}
                        <span
                          className={item.isActive ? "dotg" : "dotr"}
                        ></span>{" "}
                        {item.isActive ? " Active" : " Inactive"}
                      </td>

                  </tr>

                )
              })
            }
              
            </tbody>

          </table>
          : <div className='record'>
            No Records Found
          </div>}
      
      </div>
      
      {props.tagsview===true && props.MedicineFrequencyList&& props.MedicineFrequencyList && props.MedicineFrequencyList.length>0 &&
      <div className="pagesam pt-1 m-1">
        
        <Stack spacing={2}>
          <Pagination count={props?.totalPage} color="primary" page={props.currentPage} onChange={(e, page) => handleOnChange(e, page)} />
        </Stack>
        <div className='pagenum'>
          <span className='ml-2'>{props?.calculateData?.number * 20 + 1}-{props?.calculateData?.number * 20 + props?.calculateData?.numberOfElements} of {props?.calculateData?.totalElements}</span>
        </div>

       
      </div>
        }
    </Grid>

  );

}

export default MedicinefrequencyList
