import * as React from "react";
import { useState, useEffect, useRef } from "react";
import { Grid } from "@mui/material";
import "../table.css";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

const TaskStatusList = (props) => {
  const handleClick = (row) => {
    let data = {};
    data.is_update = true;
    data.customTaskStatusId=row.customTaskStatusId;
    data.customTaskStatus = row.customTaskStatus;
    data.taskStatus = row.taskStatus;
    data.status = row.status 
    props.setId(row.customTaskStatusId);
    props.setAllTextFieldState({
      ...data,
      is_update: true,
      lastModifiedTime: row.lastModifiedTime,
    });
    props.getUserByIdFunct(row.lastModifiedBy, row.createdBy);
    props.showFormActions();
  };

  const handleOnChange = (event, page) => {
    console.log(page,"ddddddddddddddddd")
    // props.getTsaktypePage(page)
    props.getPageData("",page);

  };

  return (
    <Grid className="">
      <div class="scrollit">
        {props.tasktypeview===true && props.data && props.data.length > 0 ? (
          <table
            className="b "
            style={{
              width: "90%",
              marginLeft: "20px",
              tableLayout: "fixed",
              height: "20vh",
            }}
          >
            <thead style={{ position: "sticky", zIndex: "1", top: "0" }}>
              <tr>
                <th className=" m-r-f" style={{ width: "50px" }}>
                  <input type="checkbox" id="" name="vehicle1" value="Bike" />
                </th>
                <th className=" m-r-f">Custom task status</th>
                <th className=" m-r-f">System task status</th>
                <th className=" m-r-f">Status</th>
              </tr>
            </thead>
            <tbody>
              {props.data &&
                props.data.length > 0 &&
                props.data.map((item, i) => {
                  return (
                    <tr
                      key={i}
                      className="tablebor"
                      style={{
                        cursor: "pointer",
                        backgroundColor:
                          props.id === item.customTaskStatusId
                            ? "rgb(15, 67, 145)"
                            : "",
                        color: props.id === item.customTaskStatusId ? "white" : "",
                      }}
                    >
                      <td>
                        <input type="checkbox" id={i} />
                      </td>
                      <td
                        className="  m-r-f"
                        onClick={(event) => handleClick(item)}
                        style={{ cursor: "pointer" }}
                      >
                        <a>{item.customTaskStatus}</a>
                      </td>
                      <td
                        className="  m-r-f"
                        onClick={(event) => handleClick(item)}
                      >
                        {item.taskStatus}
                      </td>
                      <td
                        className=" m-r-f"
                        onClick={(event) => handleClick(item)}
                      >
                        <span
                          className={item.status === "ACTIVE" ? "dotg" : "dotr"}
                        ></span>{" "}
                        {item.status === "ACTIVE" ? " Active" : " Inactive"}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        ) : (
          <div className="record">No Records Found</div>
        )}
      </div>
      {props.tasktypeview===true && props.data && props.data && props.data.length>0 &&
      
      <div className="pagesam pt-1 m-1">
      <Stack spacing={2}>
    <Pagination count={props?.totalPage} color="primary" page={props.currentPage} onChange={(e, page) => handleOnChange(e, page)} />
  </Stack>
  <div className='pagenum'>
    <span className='ml-2'>{props?.calculateData?.number * 20 + 1}-{props?.calculateData?.number * 20 + props?.calculateData?.numberOfElements} of {props?.calculateData?.totalElements}</span>
  </div>
      </div>

      }
    </Grid>
  );
};

export default TaskStatusList;
