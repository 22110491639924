import { logger, consoleTransport  } from "react-native-logs";
import { getTokensFromStorage} from "../globalFunctions/getLocalStorageData";




export const LoggerFunction = async ()=>{

  const tok =  getTokensFromStorage();

  var customTransport = function (props) {
    fetch(process.env.REACT_APP_API_URL+'/edge/log', {
      method: 'POST',
      body: props,
      headers:{
        "Content-Type":"application/json",
        "Accept":"application/json",
        "Authorization":"Bearer " + tok[0]
    }
    })
    .then((response) => response.json())
    .then((result) => {
      console.log('Success:', result);
    })
    .catch((error) => {
      console.error('Error:', error);
    });
   
};

   
    const defaultConfig = {
        levels: {
          debug: 0,
          info: 1,
          warn: 2,
          error: 3,
        },
        severity: process.env.severity,
        transport: ( process.env.REACT_APP_ENV==="dev") ? consoleTransport : customTransport,
       
        async: true,
        dateFormat: "time",
        printLevel: true,
        printDate: true,
        enabled: true,
      };
    
    
      
      var log = logger.createLogger(defaultConfig);
    
      return log
  

   }