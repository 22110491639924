import { useState, useEffect, useRef } from "react";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import TextField from "@mui/material/TextField";
import JobsLogic from "./jobsLogic";
import Jobslist from "./jobslist";
import { Button } from "@mui/material";
import { } from "../../../services/adminLogIn";
import filter from "../../../images/filter.png";
import "../Organization/Mainhome.css";
import { Grid } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import Breadcrums from "../../global/breadcrums/Breadcrums";
import { toasterFunction } from "../../../globalFunctions/getLocalStorageData";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Autocomplete from '@mui/material/Autocomplete';
import { useGetJobdatalistMutation, useCreatejobsdataMutation } from "../../../services/adminLogIn";
import Tooltip from '@mui/material/Tooltip';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Alert from '@mui/material/Alert';
import AdminCookieConsentBanner from "../home/AdminCookieConsentBanner";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const Jobs = (props) => {
  const [getJobdatalist] = useGetJobdatalistMutation();
  const [createjobsdata] = useCreatejobsdataMutation();
  const [jobdataList, setjobdataList] = useState([]);
  const [access, setAccess] = useState(
    JSON.parse(sessionStorage.getItem("access"))
  );

  const {
    setError,
    whenInputsChange,
    AllTextFieldState,
    isOrgError,
    resetErrorHandler,
    setAllTextFieldState,
    setErrorMessage,
    handleDropDownChange,
    handleClose,
    jobConfiguration,
    halndleClickonProceed,
    open,


  } = JobsLogic();
  const [isFormVisible, setFormVisible] = useState({
    isOpen: false,
    isClose: true,
  });
  const [id, setId] = useState("");
  const [statuslist] = useState(["ACTIVE", "INACTIVE"]);
  const [isNewData, setIsNewData] = useState(true)
  const [currentPage, setCurrentPage] = useState(1);
  const [calculateData, setTotalPageCalculate] = useState({
    number: "",
    numberOfElements: "",
    totalElements: "",
    showcount: ""
  });
  const creatjobList = async () => {
    const res = await getJobdatalist({ string: "",page : 0,size:20 })
    setjobdataList(res)
    setTotalPage(res?.data?.totalPages)
    setTotalPageCalculate({ numberOfElements: res?.data?.numberOfElements, number: res?.data?.number, totalElements: res?.data?.totalElements })
  }
  const getjobdatalistPage = async (page) => {
    const res = await getJobdatalist({ string: "", page : page-1 , size: 20 });
    setCurrentPage(page)
    if (res?.data?.content) {
      setjobdataList(res)
      setTotalPage(res?.data?.totalPages)
      setTotalPageCalculate({ numberOfElements: res?.data?.numberOfElements, number: res?.data?.number, totalElements: res?.data?.totalElements })
   
    }
  }
  const showFormActions = () => {
    if (access?.sampleadd == true) {
      setErrorMessage(false);
      setId("");
      if (!isFormVisible.isOpen) {
        setFormVisible((prev) => ({
          ...prev,
          isOpen: (isFormVisible.isOpen = true),
          isFormVisible,
          isClose: (isFormVisible.isClose = false),
        }));
        if (AllTextFieldState.is_update === false) {
          setId("");
        }
      }
    } else {
      toasterFunction(" No Permission", "error");
    }
  };
  const hideForm = () => {
    setErrorMessage(false);

    setAllTextFieldState({
      is_update: false,
      job_name: "",
      jobConfiguration:"",
      isActive:'',
    });
    if (isFormVisible.isOpen) {
      setFormVisible((prev) => ({
        ...prev,
        isOpen: (isFormVisible.isOpen = false),
        isFormVisible,
        isClose: (isFormVisible.isClose = true),
      }));
    }
    setError((prev) => ({
      ...prev,
      isjob_nameError: isOrgError.isjob_nameError = false,
    }))

    setsearchVal("");
    setId("");
    creatjobList()
  };
  useEffect(() => {
    setIsNewData(true);
    setCurrentPage(1);
    creatjobList()
  }, []);
 
  const bodySubmitHandler = async (e) => {
    e.preventDefault();
    if (AllTextFieldState.job_name === "") {
      setError((prev) => ({
        ...prev,
        isjob_nameError: isOrgError.isjob_nameError = true,
      }))
    }
   
    else {

      setError((prev) => ({
        ...prev,
        isJobError: isOrgError.isJobError = false,
       
      }))
      const sendOrgDetails = {
        job_name: AllTextFieldState.job_name,
        jobConfiguration: jobConfiguration,
        isActive:AllTextFieldState.status
       
      }

      const res = await createjobsdata(sendOrgDetails)
      if (res?.data) {
        hideForm()
        toasterFunction(res?.data ? "Created successfully" : "Update successfully", "success")
      }
      else {
        toasterFunction(res?.error?.data?.message, "error")
      }
      setErrorMessage(true)
    
    }
  }
  

  const [totalPage, setTotalPage] = useState(0);
  const [searchVal, setsearchVal] = useState("");
 
  const onSearchevent = async (value) => {
    setsearchVal(value);
    if(value.length!=0){
      const res = await getJobdatalist({ string: value ,page : 0,size:20  });
      if (res) {
        setCurrentPage(1)
        setjobdataList(res)
      }
      else{
        setjobdataList([])
      }
    }
    else{
      creatjobList()
    }
   
  }



  return (
    <>
      <div className={props.expand1.empty === true ? "main1" : "main"}>
      <div className="breadCumContainer flex justify-start items-center h-9 w-full bg-o-color-one organ1">
          <ol className="ml-8 flex " style={{ listStyleType: "none" }}>
            <div className="flex mt-1">
              <li className="w-28 h-thirtyPix rounded-mysix flex justify-center items-center font-medium bg-white m-r-f orgbox">
                <span>Jobs</span>
              </li>
            </div>
            <i className="right"></i>
            <div className="flex mt-1">
              <li
                className="w-40 h-thirtyPix rounded-mysix flex justify-center items-center font-medium bg-white m-r-f orgbox3 rows breadback"
                style={{ padding: "0px" }}
              >
                <span>Data Sync Jobs</span>
              </li>
            </div>
          </ol>
        </div>
        <ToastContainer />
        <Grid container mt={-2}>
          <Grid item xs={6}>
            <div
              className={
                isFormVisible.isOpen
                  ? "leftcontainer rounded-lg"
                  : "leftcontainer1 rounded-lg"
              }
            >
              <Grid className=" p-7 ">
                <TextField
                  label=""
                  onChange={(e) => onSearchevent(e.target.value)}
                  onKeyDown={(e) => {
                    e.key === "Enter" && e.preventDefault();
                  }}
                  sx={{ m: 1, width: "25ch" }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchOutlinedIcon />
                      </InputAdornment>
                    ),
                  }}
                  value={searchVal}
                />
              </Grid>
              <Grid className="editContainer rounded-radiusFourPix ">
                <Grid
                  disabled={access?.sampleadd == false}
                  onClick={showFormActions}
                  className={isFormVisible.isOpen ? "addIconchange" : "addIcon"}
                >
                  <span className=" p-2 m-r-f ">Add</span>
                  <AddCircleOutlineIcon
                    style={{
                      fontSize: "14px",
                      color: "#545454",
                      marginBottom: "3px",
                      color: `${isFormVisible.isOpen ? "#ffffff" : "#545454"}`,
                    }}
                  />
                </Grid>
                <Grid className="addIcon">
                  <span className="p-2 m-r-f">Filter</span>
                  <img
                    src={filter}
                    alt="filter.png"
                    style={{
                      position: "absolute",
                      left: "90%",
                      right: "20px",
                      top: "15px",
                      bottom: "95px",
                      width: "11px",
                      paddingTop: "3px",
                    }}
                  ></img>
                </Grid>
              </Grid>
              <>
                <Jobslist
                  setErrorMessage={setErrorMessage}
                  setTotalPage={setTotalPage}
                  totalPage={totalPage}
                  jobdataList={jobdataList}
                  getjobdatalistPage={getjobdatalistPage}
                  setId={setId}
                  id={id}
                  calculateData={calculateData}
                  currentPage={currentPage}
                  showFormActions={showFormActions}
                  setAllTextFieldState={setAllTextFieldState}
                  sampleview={access?.sampleview}
                  sampleupdate={access?.sampleupdate}

                />
                 <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={style}>
                      <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                      >
                        <Alert severity="info">
                          {" "}
                          {AllTextFieldState.status === false
                            ? " Are you sure you want to reactivate the Data Sync Jobs?"
                            : "Are you sure you want to Deactivate the Data Sync Jobs?"}
                        </Alert>
                      </Typography>
                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {AllTextFieldState.status === false
                          ? "This activity will enable the Data Sync Jobs and user will be able to access it across all products."
                          : "This activity disable the Data Sync Jobs and user will not able to access it. You can reactivate the Data Sync Jobs at anytime?"}

                        <div className="mt-10 flex justify-center items-center  towButtonContainer">
                          <Button
                            type="submit"
                            onClick={handleClose}
                            variant="contained"
                            disableElevation
                            style={{
                              marginRight: "10px",
                              borderColor: "#0F4391",
                              width: "99px",
                              height: "39px",
                              borderRadius: "9999px",
                              backgroundColor: "#E8FAFF",
                              color: "#0F4391",
                              border: "1px solid #0F4391",
                              marginBottom: "100px",
                            }}
                          >
                            CANCEL
                          </Button>

                          <Button
                            type="submit"
                            onClick={halndleClickonProceed}
                            variant="contained"
                            disableElevation
                            style={{
                              borderColor: "#0F4391",
                              width: "99px",
                              height: "39px",
                              borderRadius: "9999px",
                              backgroundColor: "#E8FAFF",
                              color: "#0F4391",
                              border: "1px solid #0F4391",
                              marginBottom: "100px",
                            }}
                          >
                            PROCEED
                          </Button>
                        </div>
                      </Typography>
                    </Box>
                  </Modal>
              </>
            </div>
          </Grid>
          {isFormVisible.isOpen ? (
            <Grid item xs={6}>
              <div className="rightcontainer rounded-lg">
                <div className="overflow-y-auto o-form-container">
                  <>
                    <div className="font-normal text-o-form-label-color mainhead flex justify-between items-center pl-5 pr-1 h-10">
                      <h1>Jobs Overview</h1>
                    </div>

                    <form autoComplete="off" onSubmit={bodySubmitHandler}>
                      <div className=" pl-5 OrgNameContainer">

                        <h3
                          className="font-normal text-o-form-label-color"
                          id="Org"
                        >
                          Job Name
                          <span style={{ color: "red" }}> *</span>
                        </h3>

                        <TextField
                          onFocus={(e) => resetErrorHandler(e)}
                          autoComplete="OFF"
                          onChange={(e) => whenInputsChange(e)}
                          name="job_name"
                          error={isOrgError.isjob_nameError === true}
                          helperText={
                            isOrgError.isjob_nameError
                              ? "Valid (jobname) Required !"
                              : ""
                          }
                          value={AllTextFieldState.job_name}
                          style={{ width: "360px" }}
                          id="standard-basic"
                          label=""
                          variant="standard"
                        />

                      <Tooltip
                        title={
                         "Please enter uppercase letter and '_'. Space are not allowed."
                        }
                        followCursor>
                      <div>
                        <span className="round">i</span> 
                     <CircleOutlinedIcon
                     style={{ color:"#0F4391",
                      position: "relative",
                      bottom:"54px",
                      left: "83px",
                      height:"17px",
                      width:"17px",
                     
                    }}
                       />
                     </div>
                        </Tooltip> 

                        <h3
                          className=" mt-5 font-normal text-o-form-label-color"
                          id="Org"
                        >
                          Job configuration Details
                          
                        </h3>

                        <TextField
                          rows={8}
                          multiline
                          disabled={true}
                          onFocus={(e) => resetErrorHandler(e)}
                          autoComplete="OFF"
                          onChange={(e) => whenInputsChange(e)}
                          name="jobConfiguration"
                          value={JSON.stringify(jobConfiguration)}
                          style={{ width: "360px" }}
                          id="standard-basic"
                          label=""
                          variant="outlined"
                        />
                       <div className="mt-5 pl-1 OrgNameContainer ">
                          <h3 className="font-normal text-o-form-label-color">
                            Status
                          </h3>
                          <Autocomplete
                            autoHighlight={true}
                            value={
                              AllTextFieldState &&
                              AllTextFieldState.status
                            }
                            onChange={(e, v) =>
                              handleDropDownChange("status", v)
                            }
                            getOptionLabel={(option) => (option ? option : "")}
                            id="city-list-option"
                            onKeyDown={(e) => {
                              e.key === "Enter" && e.preventDefault();
                            }}
                            options={statuslist ? statuslist : []}
                            sx={{ width: 360 }}
                            renderInput={(params) => (
                              <TextField
                                variant="standard"
                                {...params}
                                label=""
                              />
                            )}
                          />
                        </div>

                        <div className="mt-5 flex justify-center   towButtonContainer h-40">
                          <Button
                            onClick={hideForm}
                            type="submit"
                            variant="contained"
                            disableElevation
                            style={{
                              marginRight: "10px",
                              borderColor: "#0F4391",
                              width: "99px",
                              height: "39px",
                              borderRadius: "9999px",
                              backgroundColor: "#E8FAFF",
                              color: "#0F4391",
                              border: "1px solid #0F4391",
                            }}
                          >
                            CANCEL
                          </Button>

                          <Button
                            type="submit"
                            variant="contained"
                            disableElevation
                            style={{
                              borderColor: "#0F4391",
                              width: "99px",
                              height: "39px",
                              borderRadius: "9999px",
                              backgroundColor: "#E8FAFF",
                              color: "#0F4391",
                              border: "1px solid #0F4391",
                            }}
                          >
                            SAVE
                          </Button>
                        </div>
                      </div>
                    </form>
                  </>
                </div>
              </div>
            </Grid>
          ) : (
            ""
          )}
        </Grid>
      </div>
      <AdminCookieConsentBanner />
    </>
  );
};

export default Jobs;
