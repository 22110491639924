import { useState, useEffect, useRef } from "react";
import AdminHome from "../AdminHome";
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import MedicineFrequencyLogic from "./MedicineFrequencyLogic";
import MedicinefrequencyList from "./MedicineFrequencyList"
import filter from "../../../images/filter.png"
import { 
   useGetTagByTagMasterNameMutation,
   useGetUserByUserIdMutation ,useGetUsageCountTagMutation,useCreateMedicineFrequencyMutation,useGetMedicineFrequencymasterMutation,
   useGetMedicineFrequencyByCodeMutation,
   } from "../../../services/adminLogIn";
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import "../Organization/Mainhome.css"
import { Grid } from "@mui/material";
import InputAdornment from '@mui/material/InputAdornment';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Breadcrums from "../../global/breadcrums/Breadcrums"
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector}  from "react-redux";
import { toasterFunction } from "../../../globalFunctions/getLocalStorageData";
import AdminCookieConsentBanner from "../home/AdminCookieConsentBanner";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
const MedicineFrequency  = (props) => {
  const [getUserByUserId] = useGetUserByUserIdMutation();
  const [getUsageCountTag] = useGetUsageCountTagMutation();
  const [getMedicineFrequencymaster] =useGetMedicineFrequencymasterMutation();
  const [createMedicineFrequency] =useCreateMedicineFrequencyMutation(); 
  const [getMedicineFrequencyByCode] = useGetMedicineFrequencyByCodeMutation();

  const [access, setAccess] =useState(JSON.parse(sessionStorage.getItem("access")));

  const [expand, setexpand] = useState({
    heading: "",
    icon: "",
    isSubListExpanded: false
  })
  const [expand1, setexpand1] = useState({
    empty: true
  });
  const [isNewData, setIsNewData] = useState(true)
  const [currentPage, setCurrentPage] = useState(1);
  const [calculateData, setTotalPageCalculate] = useState({
    number: "",
    numberOfElements: "",
    totalElements: "",
    showcount: ""
  });


  const { whenInputsChange, AllTextFieldState, isError, dropdownState, setDropdowntate,
    handleDropDownChange, resetErrorHandler, setTextFieldState, setDropdownState,
    open,
    handleClose,
    setErrorMessage,
    showerror,
    setError,
    halndleClickonProceed,
  } = MedicineFrequencyLogic();
  const [statuslist] = useState(["ACTIVE", "INACTIVE"]);
  const [dropdowns] = useState(["Yes", "No"]);
  const [medicinefrequency] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [searchVal, setsearchVal] = useState("");
  const [fullName, setFullName] = useState("");
  const [usageCount, setUsageount] = useState(0);
  const [isFormUpdate, setIsFormUpdata] = useState(false);
  const [isFormVisible, setFormVisible] = useState({
    isOpen: false,
    isClose: true
  });
  const [id, setId] = useState("");
  const [MedicineFrequencyList, setMedicineFrequencyList] = useState([]);
  const [paginationdata, setPaginationData] = useState({
    string: "",
    page: 0,
    size: 20
  });
  const [modalOpen, setModalOpen] = useState(false);
  const [currentRow, setCurrentRow] = useState();

  const handleDelete =(item)=> {
    setModalOpen(true);
    setCurrentRow(item)
  }
  const handleModalClose = () => {
    setModalOpen(false);
  }


 
  const showFormActions = () => {
    if(access?.tagsview==true){
    setErrorMessage(false)
    setId("")
    if (!isFormVisible.isOpen) {
      setFormVisible((prev) => ({ ...prev, isOpen: isFormVisible.isOpen = true, isFormVisible, isClose: isFormVisible.isClose = false }));
      if (AllTextFieldState.is_update === false) {
        setId("")
      }
    }
  }
  else{
    toast(" No Permission", {
      position: "top-center",
      autoClose: 2000,
hideProgressBar: true,
      type: "error",
      closeOnClick: true,
      theme: "light",
    });
  }
  }
  const SubmitHandler = async (e) => {
    e.preventDefault();
    console.log(AllTextFieldState.repeats)
    if (AllTextFieldState.code === "") {
      setError((prev) => ({
        ...prev,
        iscodeError: isError.iscodeError = true,
        isamountError: isError.isamountError = true,
        isrepeatsError: isError.isrepeatsError = true,
        isshortDescriptionError: isError.isshortDescriptionError = true,
        islongDescriptionError: isError.islongDescriptionError = true,

      }))

    }    else if (AllTextFieldState.amount == '') {
      setError((prev) => ({
        ...prev,
        iscodeError: isError.iscodeError = false,
        isamountError: isError.isamountError = true,
        isrepeatsError: isError.isrepeatsError = true,
        isshortDescriptionError: isError.isshortDescriptionError = true,
        islongDescriptionError: isError.islongDescriptionError = true,

  
      }))

    }   else if (AllTextFieldState.repeats == '') {
        setError((prev) => ({
          ...prev,
          iscodeError: isError.iscodeError = false,
          isamountError: isError.isamountError =false ,
          isrepeatsError: isError.isrepeatsError = true,
          isshortDescriptionError: isError.isshortDescriptionError = true,
          islongDescriptionError: isError.islongDescriptionError = true,

    
        }))
  
      } else if (AllTextFieldState.shortDescription == '') {
      setError((prev) => ({
        ...prev,
        iscodeError: isError.iscodeError = false,
        isamountError: isError.isamountError = false,
        isrepeatsError: isError.isrepeatsError = false,
        isshortDescriptionError: isError.isshortDescriptionError = true,
        islongDescriptionError: isError.islongDescriptionError = true,

      }))

    } else if (AllTextFieldState.longDescription == '') {
        setError((prev) => ({
          ...prev,
          iscodeError: isError.iscodeError = false,
          isamountError: isError.isamountError = false,
          isrepeatsError: isError.isrepeatsError = false,
          isshortDescriptionError: isError.isshortDescriptionError = false,
          islongDescriptionError: isError.islongDescriptionError = true,
  
        }))
  
      } 
 
    else {

      setError((prev) => ({
        ...prev,
        iscodeError: isError.iscodeError = false,
        isamountError: isError.isamountError = false,
        isrepeatsError: isError.isrepeatsError = false,
        isshortDescriptionError: isError.isshortDescriptionError = false,
        islongDescriptionError: isError.islongDescriptionError = false,

      
      }))
      const sendOrgDetails = {
        // sampleTypeId: AllTextFieldState.sampleTypeId,
        // SampleTypeMasterId: AllTextFieldState.is_update === false ? "SAM-" + AllTextFieldState.sampleTypeName.split(' ').join('-').toUpperCase().replace(/[^a-zA-Z0-9 ]/g, '-') : AllTextFieldState.sampleTypeName,
        // MedicineDosageid: AllTextFieldState.name.toUpperCase()
        // .replace(" ", "-"),
        code: AllTextFieldState.code,
        amount: AllTextFieldState.amount,
        repeats: AllTextFieldState.repeats,
        shortDescription: AllTextFieldState.shortDescription,
        longDescription:AllTextFieldState.longDescription,
      
        "isActive": AllTextFieldState.isActive === "INACTIVE" ? false :AllTextFieldState.isActive === "ACTIVE" ? true : "",
      }
      console.log(sendOrgDetails)
      // if (AllTextFieldState.is_update === false) {
        const callByCode =  await getMedicineFrequencyByCode(sendOrgDetails)
     
        const res = await createMedicineFrequency(sendOrgDetails)
        if(res?.data?.createdBy){
          hideForm()
          toasterFunction(callByCode?.data?.code?"Medicine Frequency Updated successfully":"Medicine Frequency Created successfully", "success")
        }
        else{
          toasterFunction(res?.error?.data?.message, "error")
        }
       
      // }
      // else {
      //     sendOrgDetails.sampleTypeId = AllTextFieldState.sampleTypeId
      //     updatesampleFunc(sendOrgDetails)
      // }
      setErrorMessage(true)
    }

  }
  const hideForm = () => {
    setErrorMessage(false)

    setTextFieldState({
      is_update: false,
      code: '',
      amount:'',
      repeats: '',
      shortDescription:'',
      longDescription:'',
      
    })
    if (isFormVisible.isOpen) {
      setFormVisible((prev) => ({ ...prev, isOpen: isFormVisible.isOpen = false, isFormVisible, isClose: isFormVisible.isClose = true }));
    }
    setError((prev) => ({
      ...prev,
      iscodeError: isError.iscodeError = false,
      isamountError: isError.isamountError = false,
      isrepeatsError: isError.isrepeatsError = false,
      isshortDescriptionError: isError.isshortDescriptionError = false,
      islongDescriptionError: isError.islongDescriptionError = false,
    }))
    setsearchVal("")
    getMedicineFrequencyList()
  }

  const handleColorInput = (value) => {
    setTextFieldState((prev) => ({
      ...prev,
      ['color']: AllTextFieldState.color == value ? "" : value

    }))
    setError((prev) => ({
      ...prev,
      isColor: isError.isColor = false
    }))
  }
  
  const getUserByIdFunct = async (userId,tagId) => {
    const res = await getUserByUserId(userId)
    if(res?.data?.content.length>0){
        setFullName(res?.data?.content[0].fullName)
    }

    const  usageRes =await getUsageCountTag(tagId)
    if(usageRes?.data?.content.length>0){
      setUsageount(usageRes?.data?.totalElements)
  }
  }
  
  const getMedicineFrequencyList = async () => {
    
    const medicinefrequency = await getMedicineFrequencymaster ({ string: "",page : 0,size:20 })
    console.log(medicinefrequency);
    if(medicinefrequency?.data?.content.length>0){
      setMedicineFrequencyList(medicinefrequency?.data?.content)
        setTotalPage(medicinefrequency?.data?.totalPages)
        setTotalPageCalculate({ numberOfElements: medicinefrequency?.data?.numberOfElements, number: medicinefrequency?.data?.number, totalElements: medicinefrequency?.data?.totalElements })
    }
}

const getMedicineDosagePage = async (page) => {
    const medicinefrequency = await getMedicineFrequencymaster({ string: "", page : page-1 , size: 20 });
    setCurrentPage(page)
    if (medicinefrequency?.data?.content) {
      setMedicineFrequencyList(medicinefrequency?.data?.content)
      setTotalPage(medicinefrequency?.data?.totalPages)
      setTotalPageCalculate({ numberOfElements: medicinefrequency?.data?.numberOfElements, number: medicinefrequency?.data?.number, totalElements: medicinefrequency?.data?.totalElements })
   
    }
  }

  const onSearchevent = async (value) => {
    setsearchVal(value);
    if(value.length!=0){
      const res = await getMedicineFrequencymaster({ string: value ,page : 0,size:20  });
      if (res?.data?.content) {
        setCurrentPage(1)
        setMedicineFrequencyList(res?.data?.content)
      }
      else{
        setMedicineFrequencyList([])
      }
    }
    else{
        getMedicineFrequencyList()
    }
   
  }
  useEffect(() => {
    setIsNewData(true);
    setCurrentPage(1);
    getMedicineFrequencyList()    
 
  }, []);



  return (
    <>
      <div className={props.expand1.empty === true ? "main1" : "main"}>
      <Breadcrums
          firsttab={"Categories"}
          secondtab=" Medicine Request "
          thirdtab="Medicine Frequency"
        ></Breadcrums>
  <ToastContainer />
        <Grid container mt={-2}>
          <Grid item xs={6}>
            <div
              className={
                isFormVisible.isOpen
                  ? "leftcontainer rounded-lg"
                  : "leftcontainer1 rounded-lg"
              }
            >
              <Grid className=" p-7 ">
                <TextField
                  label=""
                  onChange={(e) => onSearchevent(e.target.value)}
                  onKeyDown={(e) => {
                    e.key === "Enter" && e.preventDefault();
                  }}
                  sx={{ m: 1, width: "25ch" }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchOutlinedIcon />
                      </InputAdornment>
                    ),
                  }}
                  value={searchVal}
                />
              </Grid>
              <Grid className="editContainer rounded-radiusFourPix ">
                <Grid
                   disabled ={access?.useradd==false}
                  onClick={showFormActions}
                  className={isFormVisible.isOpen ? "addIconchange" : "addIcon"}
                >
                  <span className=" p-2 m-r-f ">Add</span>
                  <AddCircleOutlineIcon
                    style={{
                      fontSize: "14px",
                      color: "#545454",
                      marginBottom: "3px",
                      color: `${isFormVisible.isOpen ? "#ffffff" : "#545454"}`,
                    }}
                  />
                </Grid>
                {/* <Grid className="addIcon">
                  <span className="p-2 m-r-f">Filter</span>
                  <img
                    src={filter}
                    alt="filter.png"
                    style={{
                      position: "absolute",
                      left: "90%",
                      right: "20px",
                      top: "15px",
                      bottom: "95px",
                      width: "11px",
                      paddingTop: "3px",
                    }}
                  ></img>
                </Grid> */}

              </Grid>
              <>
                <MedicinefrequencyList
                 MedicineFrequencyList={MedicineFrequencyList}
                setTotalPage={setTotalPage}
                totalPage={totalPage}
                setId={setId}
                id={id}
                showFormActions={showFormActions}
                setTextFieldState={setTextFieldState}
                getUserByIdFunct={getUserByIdFunct}
                tagsview = {access?.tagsview}
                tagsupdate = {access?.tagsupdate}
                getMedicineDosagePage={getMedicineDosagePage}
                calculateData={calculateData}
                currentPage={currentPage}
                handleDelete={handleDelete}
                />
                
              </>
            </div>
          </Grid>

          {isFormVisible.isOpen ? (
            <Grid item xs={6}>
              <div className="rightcontainer rounded-lg">
                <div className="overflow-y-auto o-form-container">
                  <Modal
                    open={open}
                    onClose={hideForm}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={style}>
                      <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                      >
                        <Alert severity="info">
                          {" "}
                          {AllTextFieldState.status === "INACTIVE"
                            ? "Are you sure you want to reactivate  the Sample ?"
                            : "Are you sure you want to Deactivate the Sample ?"}
                        </Alert>
                      </Typography>
                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {AllTextFieldState.status === "INACTIVE"
                          ? "This activity will enable the Sample and user will be able to access it across all products."
                          : "This activity disable the Sample and user will not able to access it. You can reactivate the Sample at anytime?"}

                        <div className="mt-10 flex justify-center items-center  towButtonContainer">
                          <Button
                            type="submit"
                            onClick={handleClose}
                            variant="contained"
                            disableElevation
                            style={{
                              marginRight: "10px",
                              borderColor: "#0F4391",
                              width: "99px",
                              height: "39px",
                              borderRadius: "9999px",
                              backgroundColor: "#E8FAFF",
                              color: "#0F4391",
                              border: "1px solid #0F4391",
                              marginBottom: "100px",
                            }}
                          >
                            CANCEL
                          </Button>

                          <Button
                            type="submit"
                            onClick={halndleClickonProceed}
                            variant="contained"
                            disableElevation
                            style={{
                              borderColor: "#0F4391",
                              width: "99px",
                              height: "39px",
                              borderRadius: "9999px",
                              backgroundColor: "#E8FAFF",
                              color: "#0F4391",
                              border: "1px solid #0F4391",
                              marginBottom: "100px",
                            }}
                          >
                            PROCEED
                          </Button>
                        </div>
                      </Typography>
                    </Box>
                  </Modal>
                  <ToastContainer />
                  <>
                    <div className="font-normal text-o-form-label-color mainhead  flex justify-between items-center pl-5 pr-1 h-10">
                      <h1>Medicine Frequency Overview</h1>
                    </div>

                    <form autoComplete="off" onSubmit={SubmitHandler}>
                      <div className=" pl-5 OrgNameContainer">
                        <h3
                          className="font-normal text-o-form-label-color"
                          id="Org"
                        >
                         Code
                          <span style={{ color: "red" }}> *</span>
                        </h3>
                        <TextField
                          onFocus={(e) => resetErrorHandler(e)}
                          disabled={AllTextFieldState.is_update}
                          autoComplete="OFF"
                          error={isError.iscodeError === true}
                          helperText={
                            isError.iscodeError
                              ? "Valid (code) Required !"
                              : ""
                          }
                          onChange={(e) => whenInputsChange(e)}
                          name="code"
                          value={AllTextFieldState.code}
                          style={{ width: "360px" }}
                          id="standard-basic"
                          label=""
                          variant="standard"
                        />
                      </div>

                     
                      <div className="mt-5 pl-5 OrgNameContainer">
                        <h3
                          className="font-normal text-o-form-label-color"
                          id="Org"
                        >
                         Amount 
                          <span style={{ color: "red" }}> *</span>
                        </h3>
                        <TextField
                          onFocus={(e) => resetErrorHandler(e)}
                          autoComplete="OFF"
                          error={isError.isamountError === true}
                          helperText={
                            isError.isamountError
                              ? "Valid (amount) Required !"
                              : ""
                          }
                          onChange={(e) => whenInputsChange(e)}
                          name="amount"
                          value={AllTextFieldState.amount}
                          style={{ width: "360px" }}
                          id="standard-basic"
                          label=""
                          variant="standard"
                        />
                      </div>
                     
                       <div className="mt-5 pl-5 OrgNameContainer ">
                          <h3
                            className="font-normal text-o-form-label-color "
                            id="color"
                          >
                            Repeats <span style={{ color: "red" }}> *</span>
                          </h3>
                          <Autocomplete
                            data-testid="partof"
                            value={AllTextFieldState.repeats}
                            onKeyDown={(e) => {
                              e.key === "Enter" && e.preventDefault();
                            }}
                            name="repeats"
                            autoHighlight={true}
                            onChange={(e, v) =>
                              handleDropDownChange("repeats", v)
                            }
                            disableClearable
                            getOptionLabel={(option) => (option ? option : "")}
                            id="loc-list-option"
                            options={dropdowns ? dropdowns : []}
                            sx={{ width: 360 }}
                            disabled={isFormUpdate}
                            renderInput={(params) => (
                              <TextField
                                variant="standard"
                                {...params}
                                label=""
                                onFocus={(e) => resetErrorHandler(e)}
                                autoComplete="OFF"
                                error={
                                    isError.isrepeatsError === true
                                  }
                                  helperText={
                                    isError.isrepeatsError
                                      ? "Valid (repeats) Required !"
                                      : ""
                                  }
                                disabled={isFormUpdate}
                              />
                            )}
                          />
                        </div>

                     

                      <div className="mt-5 pl-5 OrgNameContainer">
                        <h3
                          className="font-normal text-o-form-label-color"
                          id="Org"
                        >
                          Short Description  <span style={{ color: "red" }}> *</span>
                        </h3>
                        <TextField
                          onFocus={(e) => resetErrorHandler(e)}
                          autoComplete="OFF"
                         
                          error={isError.isshortDescriptionError === true}
                              helperText={
                                isError.isshortDescriptionError
                                  ? "Valid (shortDescription) Required !"
                                  : ""
                              }
                          onChange={(e) => whenInputsChange(e)}
                          name="shortDescription"
                          value={AllTextFieldState.shortDescription}
                          style={{ width: "360px" }}
                          id="standard-basic"
                          label=""
                          multiline
                          rowsMax="3"
                          variant="standard"
                        />
                      </div>
                     
                      <div className="mt-5 pl-5 OrgNameContainer">
                        <h3
                          className="font-normal text-o-form-label-color"
                          id="Org"
                        >
                          Long Description  <span style={{ color: "red" }}> *</span>
                        </h3>
                        <TextField
                          onFocus={(e) => resetErrorHandler(e)}
                          autoComplete="OFF"
                         
                          error={isError.islongDescriptionError === true}
                              helperText={
                                isError.islongDescriptionError
                                  ? "Valid (longDescription) Required !"
                                  : ""
                              }
                          onChange={(e) => whenInputsChange(e)}
                          name="longDescription"
                          value={AllTextFieldState.longDescription}
                          style={{ width: "360px" }}
                          id="standard-basic"
                          label=""
                          multiline
                          rows={4}
                          variant="outlined"
                        />
                      </div>
                      {AllTextFieldState.is_update === true ? (
                        <>
{/*                          
                        <div className="mt-5 pl-5 OrgNameContainer ">
                          <h3 className="font-normal text-o-form-label-color">
                            Status
                          </h3>
                          <Autocomplete
                            autoHighlight={true}
                            value={
                              AllTextFieldState &&
                              AllTextFieldState.status
                            }
                            onChange={(e, v) =>
                              handleDropDownChange("status", v)
                            }
                            getOptionLabel={(option) => (option ? option : "")}
                            id="city-list-option"
                            onKeyDown={(e) => {
                              e.key === "Enter" && e.preventDefault();
                            }}
                            options={statuslist ? statuslist : []}
                            sx={{ width: 360 }}
                            renderInput={(params) => (
                              <TextField
                                variant="standard"
                                {...params}
                                label=""
                              />
                            )}
                          />
                        </div> */}
                        </>
                      ) : (
                        ""
                      )}


                      <div className="mt-5  OrgNameContainer ">
                        {/* <div className="progressContainer mt-5 flex justify-center items-center">
                          {createResponse.isError ||
                            (updateResponse.isError && showerror === true) ? (
                            <Alert severity="error" color="warning">
                              {createResponse.error &&
                                createResponse.error.data &&
                                AllTextFieldState.is_update === false
                                ? createResponse.error.data.message
                                : updateResponse.error &&
                                updateResponse.error.data &&
                                updateResponse.error.data.message}
                            </Alert>
                          ) : createResponse.isLoading ||
                            updateResponse.isLoading ? (
                            <CircularProgress />
                          ) : (
                            ""
                          )}
                        </div> */}
                        <div className="mt-5 flex justify-center   towButtonContainer h-40">
                          <Button
                            onClick={hideForm}
                            type="submit"
                            variant="contained"
                            disableElevation
                            style={{
                              marginRight: "10px",
                              borderColor: "#0F4391",
                              width: "99px",
                              height: "39px",
                              borderRadius: "9999px",
                              backgroundColor: "#E8FAFF",
                              color: "#0F4391",
                              border: "1px solid #0F4391",
                            }}
                          >
                            CANCEL
                          </Button>

                          <Button
                            type="submit"
                            variant="contained"
                            disableElevation
                            onClick={SubmitHandler}
                            style={{
                              borderColor: "#0F4391",
                              width: "99px",
                              height: "39px",
                              borderRadius: "9999px",
                              backgroundColor: "#E8FAFF",
                              color: "#0F4391",
                              border: "1px solid #0F4391",
                            }}
                          >
                            SAVE
                          </Button>
                        </div>
                      </div>
                    </form>
                  </>
                </div>
              </div>
            </Grid>
          ) : (
            ""
          )}
        </Grid>
      </div>
      <AdminCookieConsentBanner />
    </>
  );

}

export default MedicineFrequency;


