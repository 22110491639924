import { LocalConvenienceStoreOutlined } from "@mui/icons-material";
import { useState } from "react";
import {
    useCreatingUserMutation, useGetActiveDistrictValueMutation,
    useUpdatingUserMutation, useUpdateUserRoleMutation, useSoftDeleteUserMutation
} from "../../../services/adminLogIn";
import { v4 as uuidv4 } from 'uuid';
const useUserHook = () => {
    const isEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };
    // services requests hooks
    const [createUserFunc, createUserRes] = useCreatingUserMutation();
    const [updateUserFunc, updateUserRes] = useUpdatingUserMutation();
    const [updateUserRoleFunc] = useUpdateUserRoleMutation();
    const [getDistrictValFun, { isLoading1, isSuccess1, isError1, ...districtlist }] = useGetActiveDistrictValueMutation();
    const [softDeleteUser] = useSoftDeleteUserMutation();

    // making state for for organisation form
    const [orgState, setOrgState] = useState();
    const [showerror, setErrorMessage] = useState(false);

    // making state for all org text fileds

    const [userAllTextFieldState, setUserTextFieldState] = useState({
        userId: '',
        userName: '',
        is_update: false,
        deleted: false,
        isOpenemty: true,
        salutation: '',
        firstName: '',
        middleName: '',
        lastName: '',
        gender: '',
        mobileNumber: null,
        email: '',
        dob: '',
        roles: [],
        organizations: [],
        locations: [],
        photo : '',
        profilePhoto: '',
        imageType: '',
        imageUrl: '',
        // lastModifiedTime:'',
        // lastModifiedBy:'',
    });

    // making separate state for dropdown
    const [dropOrgState, setDropOrgState] = useState({

    });
    const [open, setOpen] = useState(false);
    const [openUserFilterModal, setOpenUserFilterModal] = useState(false);
    const [status, setStatus] = useState("");
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleUserFilterModalOpen = () => setOpenUserFilterModal(true);
    const handleUserFilterModalClose = () => { setOpenUserFilterModal(false); }
    const [filterData, setFilterData] = useState({
        firstName: "",
        lastName: "",
        emailId: "",
        role: [],
        organizationName: [],
        locationName: [],
        status: "",
        page: 0,
    });


    const [dropLocState, setDropLocState] = useState({

    });
    const [dropRoleState, setDropRoleState] = useState([]);
    const [roleList, setRoleList] = useState([]);
    // making state for error handling for org-form


    const [isUserError, setError] = useState({
        isNameError: '',
        isfNameError: '',
        islNameError: '',
        isSalutationError: '',
        isGenderError: '',
        isDobError: '',
        isDropDownError: false,
        isDropLocError: false,
        isPhoneError: '',
        isEmailError: ''
    });



    // dropdown handler 
    const handleDropDownChange = (e, v) => {
        
        const value = v;
        if (e === 'orgname') {
            userAllTextFieldState.organizations = value
            setDropOrgState(value);
            setUserTextFieldState(userAllTextFieldState)
        }
        if (e === 'locname') {
            userAllTextFieldState.locations = value
            setDropLocState(value);
            setUserTextFieldState(userAllTextFieldState)
        }
        if (e === 'role') {
            if(userAllTextFieldState.roles.find((item) => item === "practitioner")){
                if(value.find((item) => item === "practitioner")){
                    userAllTextFieldState.roles = value
                    setDropRoleState(value);
                    setUserTextFieldState(userAllTextFieldState)
                }
                else{
                    alert("You cannot remove practitioner role !")
                }
            }
            else{
                userAllTextFieldState.roles = value
                setDropRoleState(value);
                setUserTextFieldState(userAllTextFieldState)
            }
           
        }
        if (e === 'gender') {
            userAllTextFieldState.gender = value
            setUserTextFieldState((prev) => ({
                ...prev,
                ['gender']: value

            }))
        }
        if (e === 'salutation') {
            userAllTextFieldState.salutation = value
            setUserTextFieldState((prev) => ({
                ...prev,
                ['salutation']: value

            }))
        }
        if (e === 'status') {
            handleOpen()

        }

    }

    // onfoucs reset error warning

    const resetErrorHandler = (e) => {
        const name = e.target.name;
        switch (name) {
            case "firstName":
                setError((prev) => ({
                    ...prev,
                    isfNameError: isUserError.isfNameError = false
                }))
                break;

            case "lastName":
                setError((prev) => ({
                    ...prev,
                    islNameError: isUserError.islNameError = false
                }))
                break;



            case "email":
                setError((prev) => ({
                    ...prev,
                    isEmailError: isUserError.isEmailError = false
                }))
                break;

        }

    }


    // form on submit handleer 
    const orgSubmitHandler = async (e) => {
        e.preventDefault();

        if (userAllTextFieldState.firstName === "") {
            setError((prev) => ({
                ...prev,
                isfNameError: isUserError.isfNameError = true
            }))

        } else if (userAllTextFieldState.id === "") {
            setError((prev) => ({
                ...prev,
                isfNameError: isUserError.isfNameError = false,
                islNameError: isUserError.islNameError = true
            }))
        }

        else if (!isEmail(userAllTextFieldState.email)) {
            setError((prev) => ({
                ...prev,
                isfNameError: isUserError.isfNameError = false,
                islNameError: isUserError.islNameError = false,
                islNameError: isUserError.islNameError = false,
                isEmailError: isUserError.isEmailError = true,

            }))
        } else {
            const finalRef = dropOrgState.id;


            setError((prev) => ({
                ...prev,
                isfNameError: isUserError.isfNameError = false,
                islNameError: isUserError.islNameError = false,
                islNameError: isUserError.islNameError = false,
                isEmailError: isUserError.isEmailError = false,


            }))


            var no = uuidv4();
            const sendUserDetails = {

                active: true,
                userId: userAllTextFieldState.userId,
                userName: userAllTextFieldState.email,
                salutation: userAllTextFieldState.salutation,
                firstName: userAllTextFieldState.firstName,
                middleName: userAllTextFieldState.middleName,
                lastName: userAllTextFieldState.lastName,
                gender: userAllTextFieldState.gender,
                mobileNumber: userAllTextFieldState.mobileNumber,
                email: userAllTextFieldState.email,
                dob: userAllTextFieldState.dob,
                roles: userAllTextFieldState.roles,
                "organizations": userAllTextFieldState.organizations,
                "locations": userAllTextFieldState.locations,
                "userAttributes": {
                    "mdmUserId": no.toString()
                },
                profilePhoto:userAllTextFieldState.profilePhoto,
                imageType: userAllTextFieldState.imageType,
                imageUrl:userAllTextFieldState.imageUrl,

            }

            if (userAllTextFieldState.is_update === false) {
                 await createUserFunc(sendUserDetails)
            }
            else {
                const sendUserRole = {
                    userId: userAllTextFieldState?.userId,
                    roles: roleList.filter(val => !userAllTextFieldState.roles.includes(val)),
                }
                await updateUserRoleFunc(sendUserRole)
                await updateUserFunc(sendUserDetails)
                if (userAllTextFieldState.deleted === true) {
                    await softDeleteUser(sendUserRole)
                }

            }
            setErrorMessage(true)

            // end of else  
        }
    }
    const halndleClickonProceed = () => {
        // userAllTextFieldState.deleted = !userAllTextFieldState.deleted
        setUserTextFieldState((prev) => ({
            ...prev,
            ['deleted']: !userAllTextFieldState.deleted

        }))
        setStatus(userAllTextFieldState.deleted === "true" ? "Inactive" : "Active")
        handleClose()
    }
    // getting inputs value onChange
    const handleUserFilterInputChange = (e) => {
        setFilterData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    }

    const handleUserFilterValueChange = (name, value) => {
        setFilterData((prev) => ({ ...prev, [name]: value }));
    }
    const [currentPage, setCurrentPage] = useState(1);
    const resetUserFilterData = () => {
        setFilterData({ firstName: "", lastName: "", emailId: "", role: [], organizationName: [], locationName: [], status: "",page:0 });
        setCurrentPage(1)
    }

    const whenInputsChange = (e) => {
        const { name, value } = e.target;
        if (name === "firstName" || name === "lastName" || name === "middleName") {
            if (name === "middleName") {
                if (value.length <= 25) {
                    setUserTextFieldState((prev) => ({
                        ...prev,
                        [name]: value.replace(/[^A-Za-z ]/gi, "")

                    }))
                }
            }
            
           
            else {
                if (value.length <= 150) {
                    setUserTextFieldState((prev) => ({
                        ...prev,
                        [name]: value.replace(/[^A-Za-z ]/gi, "")

                    }))
                }
            }
            
        }
        else if (name === "mobileNumber") {
            if (value.length <= 10) {
            setUserTextFieldState((prev) => ({
                ...prev,
                [name]: value.replace(/[^0-9]/gi, "")

            }))
        }
        }
        else {
            setUserTextFieldState((prev) => ({
                ...prev,
                [name]: value

            }))
        }




    }




    // exporting all handler and state and etc
    const exportAll = {
        orgSubmitHandler,
        whenInputsChange,
        orgState,
        dropOrgState,
        userAllTextFieldState,
        isUserError, 
        handleDropDownChange,
        createUserRes,
        updateUserRes,
        resetErrorHandler,
        setUserTextFieldState,
        setDropOrgState,
        setDropLocState,
        handleClose,
        setStatus,
        handleOpen,
        districtlist,
        filterData,
        open,
        halndleClickonProceed,
        handleUserFilterModalOpen,
        handleUserFilterModalClose,
        openUserFilterModal,
        resetUserFilterData,
        handleUserFilterInputChange,
        handleUserFilterValueChange,
        showerror, setErrorMessage, setRoleList,setCurrentPage,currentPage

    }

    return exportAll;
}

export default useUserHook;



