import { useState, useEffect, useRef } from "react";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import TextField from "@mui/material/TextField";
import ReportCategoryLogic from "./ReportCategoryLogic";
import ReportCategoryList from "./ReportCategoryList";
import {Button } from "@mui/material";
import { useGetReportCategoryListMutation ,useGetCreatingReportCategoryMutation} from "../../../services/adminLogIn";
import filter from "../../../images/filter.png";
import "../Organization/Mainhome.css";
import { Grid } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import Breadcrums from "../../global/breadcrums/Breadcrums";
import { toasterFunction } from "../../../globalFunctions/getLocalStorageData";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { findNonSerializableValue } from "@reduxjs/toolkit";
import AdminCookieConsentBanner from "../home/AdminCookieConsentBanner";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const ReportCategory = (props) => {
  const [getreportCategoryFun] = useGetReportCategoryListMutation();
  const [createRepostMasterData] = useGetCreatingReportCategoryMutation();

  const [reportCategory, setReportacategory] = useState({});
  const [categoryList, setcategoryList] = useState([]);
  const [categoryListData, setcategoryListData] = useState([]);
  const [access, setAccess] = useState(
    JSON.parse(sessionStorage.getItem("access"))
  );
  const [isNewData, setIsNewData] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [open, setOpen] = useState(false);
  const [indexvalue, setIndex] = useState(null);
  const [selectedPage, setSelectedPage] = useState(1);

  const [calculateData, setTotalPageCalculate] = useState({
    number: "",
    numberOfElements: "",
    totalElements: "",
    showcount: "",
  });

  const reportCategoryList = async () => {
      const res = await getreportCategoryFun();
       setReportacategory(res?.data[0])
       const reportCategoryData = res?.data[0]
      if (reportCategoryData) {
        const categoryValue = reportCategoryData.value;
        const parsedCategoryValue = JSON.parse(categoryValue);
        setcategoryListData(parsedCategoryValue)
        if (Array.isArray(parsedCategoryValue.category)) {
            const sortedCategories = parsedCategoryValue.category.sort();
            setcategoryList(sortedCategories);
      }
  };
}
  const {
    setError,
    whenInputsChange,
    AllTextFieldState,
    isOrgError,
    resetErrorHandler,
    setAllTextFieldState,
    setErrorMessage,
    setIsSearchEvent,
  } = ReportCategoryLogic();
  const [isFormVisible, setFormVisible] = useState({
    isOpen: false,
    isClose: true,
  });
  const [id, setId] = useState("");
  

  const showFormActions = () => {
    if (access?.sampleadd == true) {
      setErrorMessage(false);
      setId("");
      if (!isFormVisible.isOpen) {
        setFormVisible((prev) => ({
          ...prev,
          isOpen: (isFormVisible.isOpen = true),
          isFormVisible,
          isClose: (isFormVisible.isClose = false),
        }));
        if (AllTextFieldState.is_update === false) {
          setId("");
        }
      }
    } else {
      toasterFunction(" No Permission", "error");
    }
  };

  const handleDelete = (item,index) => {
    setOpen(true)
    setIndex(item)
  }

  const handleClose = (item,index) => {
    setOpen(false)
    setIndex(item)
  }

  const handleProceed = async () => {
    
      const upd_obj = {}
      upd_obj.category = categoryList.filter(item => item !== indexvalue)
      let detail =  upd_obj
      var sendOrgDetails = {
        createdBy : reportCategory.createdBy,
        createdTime : reportCategory.createdTime,
        key : reportCategory.key,
        locale : reportCategory.locale,
        value : JSON.stringify(detail),
        // value : "{\"category\":[\"Alternative therapies report\",\"Other form of therapy\",\"Biopsy\",\"BRCA 1, BRCA2, Genome test\",\"Bronchoscopic Lavage\",\"Bronchoscopy\",\"Chemo (Doses and no.of cycles)\",\"Chemo (Drug name,Doses and no.of cycles)\",\"Colposcopic biopsy\",\"Cone biopsy (LEEP)\",\"CT Scan\",\"Exfoliative Cytology\",\"FNAC\",\"FOBT OR FIT\",\"Hormonal therapy\",\"Hormone Receptor Test\",\"Incisional Biopsy\",\"Mammogram\",\"MRI\",\"Oncotype Dx / Mamaprint or any other marker test \",\"Other catagory\",\"Pap Smear\",\"PET\",\"Polypectomy\",\"Proctoscopy\",\"Radiation (No.of cycles) Report\",\"TC Report\",\"Screening Report\",\"HPV report\",\"RA Report\",\"Blood test reports\",\"Chemotherapy Discharge Summary\",\"Radiotherapy Discharge Summary\",\"Surgery Discharge Summary\",\"Consent\",\"Spiral CT\",\"Sputum cytology\",\"Surgery\",\"UG\",\"USG\",\"X ray Mandible / OPG \",\"Xray Chest\",\"X Rays\",\"Immunohistochemistry\",\"Histopathology\",\"Discharge Summaries\",\"Surgical Summary\",\"Radiology Reports/Imaging\",\"Chemotherapy protocol\",\"Haematology report\",\"Biochemistry report\",\"Colonoscopy report\",\"Colposcopy Images\",\"Sigmoidoscopy report\", \"Pathology Reports\", \"Lab Report\", \"Oral cavity images\", \"Vision testing report\", \"ABDM\",\"Clinical Summary\",\"KOPS Report\",\"Prescription\"]}",
         
      }
      let result =  await createRepostMasterData(sendOrgDetails)
      if(result?.data?.masterDataKeySetId){
        toasterFunction("Deleted Successfully", "success");
        hideForm()
        handleClose()
      }
      else{
        toasterFunction("Something went wrong", "error");
      }
      reportCategoryList()
  };
  const hideForm = () => {
    setErrorMessage(false);
   
    setAllTextFieldState({
      is_update: false,
      reportCategoryName: "",
    });
    if (isFormVisible.isOpen) {
      setFormVisible((prev) => ({
        ...prev,
        isOpen: (isFormVisible.isOpen = false),
        isFormVisible,
        isClose: (isFormVisible.isClose = true),
      }));
    }
    setsearchVal("");
    setSelectedPage(1)
    reportCategoryList()
    setId("");
  };

  useEffect(() => {
    reportCategoryList()
  }, []);


  const bodySubmitHandler = async(e) => {
    
    e.preventDefault();
    if (AllTextFieldState.reportCategoryName == "") {
        setError((prev) => ({
            ...prev,
            isNameError: isOrgError.isNameError = true,
            // issampleError:isOrgError.issampleError = true
        }))

    } 
    else {
        setError((prev) => ({
            ...prev,
            isNameError: isOrgError.isNameError = false,
        }))
       
        if (AllTextFieldState.is_update === false) {
          let duplicate =  categoryList?.includes(AllTextFieldState.reportCategoryName)
              if(duplicate === true){
                toasterFunction(AllTextFieldState.reportCategoryName +" already exist", "error")
              }
              else{
                categoryList?.push(AllTextFieldState.reportCategoryName)
                let detail =  categoryList
                var jsonString = JSON.stringify(detail);

                var jsonObject = 
                  `{\"category\":${jsonString}}`
            
                var sendOrgDetails = {
                  createdBy : reportCategory.createdBy,
                  createdTime : reportCategory.createdTime,
                  key : reportCategory.key,
                  locale : reportCategory.locale,
                  value : jsonObject,   
                }
                let result =  await createRepostMasterData(sendOrgDetails)
                if(result?.data?.masterDataKeySetId){
                  toasterFunction("Created Successfully", "success");
                  hideForm()
                }
                else{
                  toasterFunction("Something went wrong", "error");
                }
               }
          
        }
        else {
          const upd_obj = {}
          upd_obj.category = categoryList?.map((element,i) => {
              if(i==id)  element = AllTextFieldState.reportCategoryName
              return element
           });
          let detail =  upd_obj
          var sendOrgDetails = {
            createdBy : reportCategory.createdBy,
            createdTime : reportCategory.createdTime,
            key : reportCategory.key,
            locale : reportCategory.locale,
            value : JSON.stringify(detail),
            // value : "{\"category\":[\"Alternative therapies report\",\"Other form of therapy\",\"Biopsy\",\"BRCA 1, BRCA2, Genome test\",\"Bronchoscopic Lavage\",\"Bronchoscopy\",\"Chemo (Doses and no.of cycles)\",\"Chemo (Drug name,Doses and no.of cycles)\",\"Colposcopic biopsy\",\"Cone biopsy (LEEP)\",\"CT Scan\",\"Exfoliative Cytology\",\"FNAC\",\"FOBT OR FIT\",\"Hormonal therapy\",\"Hormone Receptor Test\",\"Incisional Biopsy\",\"Mammogram\",\"MRI\",\"Oncotype Dx / Mamaprint or any other marker test \",\"Other catagory\",\"Pap Smear\",\"PET\",\"Polypectomy\",\"Proctoscopy\",\"Radiation (No.of cycles) Report\",\"TC Report\",\"Screening Report\",\"HPV report\",\"RA Report\",\"Blood test reports\",\"Chemotherapy Discharge Summary\",\"Radiotherapy Discharge Summary\",\"Surgery Discharge Summary\",\"Consent\",\"Spiral CT\",\"Sputum cytology\",\"Surgery\",\"UG\",\"USG\",\"X ray Mandible / OPG \",\"Xray Chest\",\"X Rays\",\"Immunohistochemistry\",\"Histopathology\",\"Discharge Summaries\",\"Surgical Summary\",\"Radiology Reports/Imaging\",\"Chemotherapy protocol\",\"Haematology report\",\"Biochemistry report\",\"Colonoscopy report\",\"Colposcopy Images\",\"Sigmoidoscopy report\", \"Pathology Reports\", \"Lab Report\", \"Oral cavity images\", \"Vision testing report\", \"ABDM\",\"Clinical Summary\",\"KOPS Report\",\"Prescription\"]}",
             
          }
          let result =  await createRepostMasterData(sendOrgDetails)
          if(result?.data?.masterDataKeySetId){
            toasterFunction("Updated Successfully", "success");
            hideForm()
          }
          else{
            toasterFunction("Something went wrong", "error");
          }
        }
        setErrorMessage(true)
    }

}

  

  //ask //
  const onSearchevent = (value) => {
    setsearchVal(value);
  };
  const [totalPage, setTotalPage] = useState(0);
  const [searchVal, setsearchVal] = useState("");
  const [searchResultsObj, setSearchResultsObj] = useState({ searchResults: null });

  useEffect(() => {
    if (searchVal) {
      const result = categoryList.filter((item) =>
      item.toLowerCase().includes(searchVal.toLowerCase())
    );
      const searchResultsObj = {
        searchResults: result,
      };
      setSearchResultsObj(searchResultsObj?.searchResults);
    }
  }, [searchVal, categoryList]);

  

  return (
    <>
      <div className={props.expand1.empty === true ? "main1" : "main"}>
      <div className="breadCumContainer flex justify-start items-center h-9 w-full bg-o-color-one organ1">
          <ol className="ml-8 flex " style={{ listStyleType: "none" }}>
            <div className="flex mt-1">
              <li className="w-28 h-thirtyPix rounded-mysix flex justify-center items-center font-medium bg-white m-r-f orgbox">
                <span>Categories</span>
              </li>
            </div>
            <i className="right"></i>
            <div className="flex mt-1">
              <li className="w-28 h-thirtyPix rounded-mysix flex justify-center items-center font-medium bg-white m-r-f orgbox rows">
                <span>Clinical</span>
              </li>
            </div>
            <i className="right"></i>
            <div className="flex mt-1">
              <li className="w-40 h-thirtyPix rounded-mysix flex justify-center items-center font-medium bg-white m-r-f orgbox3 rows breadback" style ={{padding:"0px"}}>
                <span>Report Category</span>
              </li>
            </div>
          </ol>
        </div>
<ToastContainer/>
        <Grid container mt={-2}>
          <Grid item xs={6}>
            <div
              className={
                isFormVisible.isOpen
                  ? "leftcontainer rounded-lg"
                  : "leftcontainer1 rounded-lg"
              }
            >
              <Grid className=" p-7 ">
                <TextField
                  label=""
                  onChange={(e) => onSearchevent(e.target.value)}
                  onKeyDown={(e) => {
                    e.key === "Enter" && e.preventDefault();
                  }}
                  sx={{ m: 1, width: "25ch" }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchOutlinedIcon />
                      </InputAdornment>
                    ),
                  }}
                  value={searchVal}
                />
              </Grid>
              <Grid className="editContainer rounded-radiusFourPix ">
                <Grid
                  disabled={access?.sampleadd == false}
                  onClick={showFormActions}
                  className={isFormVisible.isOpen ? "addIconchange" : "addIcon"}
                >
                  <span className=" p-2 m-r-f ">Add</span>
                  <AddCircleOutlineIcon
                    style={{
                      fontSize: "14px",
                      color: "#545454",
                      marginBottom: "3px",
                      color: `${isFormVisible.isOpen ? "#ffffff" : "#545454"}`,
                    }}
                  />
                </Grid>

                {/* <Grid className="Addaction">
                    <span className="p-2 m-r-f">Actions</span>
                    <AddCircleOutlineIcon style={{ fontSize: '14px', color: '#545454', 'marginBottom': '3px' }} />

                  </Grid> */}
                <Grid className="addIcon">
                  <span className="p-2 m-r-f">Filter</span>
                  <img
                    src={filter}
                    alt="filter.png"
                    style={{
                      position: "absolute",
                      left: "90%",
                      right: "20px",
                      top: "15px",
                      bottom: "95px",
                      width: "11px",
                      paddingTop: "3px",
                    }}
                  ></img>
                </Grid>
              </Grid>
              <>
                <ReportCategoryList
                  setErrorMessage={setErrorMessage}
                  setTotalPage={setTotalPage}
                  totalPage={totalPage}
                  setId={setId}
                  selectedPage = {selectedPage}
                  setSelectedPage = {setSelectedPage}
                  id={id}
                  categoryList={searchVal ? searchResultsObj : categoryList}
                  reportCategoryList={reportCategoryList}
                  showFormActions={showFormActions}
                  setAllTextFieldState={setAllTextFieldState}
                  calculateData={calculateData}
                  currentPage={isNewData ? 1 : currentPage}
                  sampleview={access?.sampleview}
                  sampleupdate={access?.sampleupdate}
                  handleDelete={handleDelete}
                />
              </>
            </div>
          </Grid>

          <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <Box sx={style}>
                          <Typography
                            id="modal-modal-title"
                            variant="h6"
                            component="h2"
                          >
                            <Alert severity="info">
                              Are you sure you want to delete this ReportCategory
                              state?
                            </Alert>
                          </Typography>
                          <Typography
                            id="modal-modal-description"
                            sx={{ mt: 2 }}
                          >
                            <div className="mt-10 flex justify-center items-center  towButtonContainer">
                              <Button
                                type="submit"
                                onClick={handleClose}
                                variant="contained"
                                disableElevation
                                style={{
                                  marginRight: "10px",
                                  borderColor: "#0F4391",
                                  width: "99px",
                                  height: "39px",
                                  borderRadius: "9999px",
                                  backgroundColor: "#E8FAFF",
                                  color: "#0F4391",
                                  border: "1px solid #0F4391",
                                  marginBottom: "100px",
                                }}
                              >
                                CANCEL
                              </Button>

                              <Button
                                type="submit"
                                onClick={handleProceed}
                                variant="contained"
                                disableElevation
                                style={{
                                  borderColor: "#0F4391",
                                  width: "99px",
                                  height: "39px",
                                  borderRadius: "9999px",
                                  backgroundColor: "#E8FAFF",
                                  color: "#0F4391",
                                  border: "1px solid #0F4391",
                                  marginBottom: "100px",
                                }}
                              >
                                PROCEED
                              </Button>
                            </div>
                          </Typography>
                        </Box>
                      </Modal>

          {isFormVisible.isOpen ? (
            <Grid item xs={6}>
              <div className="rightcontainer rounded-lg">
                <div className="overflow-y-auto o-form-container">
                  <>
                    <div className="font-normal text-o-form-label-color mainhead flex justify-between items-center pl-5 pr-1 h-10">
                      <h1>Report Category Overview</h1>
                    </div>

                    <form autoComplete="off" onSubmit={bodySubmitHandler}>
                      <div className=" pl-5 OrgNameContainer">
                        <h3
                          className="font-normal text-o-form-label-color"
                          id="Org"
                        >
                          Report Category Name
                          <span style={{ color: "red" }}> *</span>
                        </h3>
                        <TextField
                          onFocus={(e) => resetErrorHandler(e)}
                          autoComplete="OFF"
                          error={isOrgError.isNameError === true}
                          helperText={
                            isOrgError.isNameError
                              ? "Valid (Name) Required !"
                              : ""
                          }
                          onChange={(e) => whenInputsChange(e)}
                          name="reportCategoryName"
                          value={AllTextFieldState.reportCategoryName}
                          style={{ width: "360px" }}
                          id="standard-basic"
                          label=""
                          variant="standard"
                        /> 
                                      <div className="mt-5 flex justify-center   towButtonContainer h-40">
                          <Button
                            onClick={hideForm}
                            type="submit"
                            variant="contained"
                            disableElevation
                            style={{
                              marginRight: "10px",
                              borderColor: "#0F4391",
                              width: "99px",
                              height: "39px",
                              borderRadius: "9999px",
                              backgroundColor: "#E8FAFF",
                              color: "#0F4391",
                              border: "1px solid #0F4391",
                            }}
                          >
                            CANCEL
                          </Button>

                          <Button
                            type="submit"
                            variant="contained"
                            disableElevation
                            style={{
                              borderColor: "#0F4391",
                              width: "99px",
                              height: "39px",
                              borderRadius: "9999px",
                              backgroundColor: "#E8FAFF",
                              color: "#0F4391",
                              border: "1px solid #0F4391",
                            }}
                          >
                            SAVE
                          </Button>
                        </div>
                      </div>
                    </form>
                  </>
                </div>
              </div>
            </Grid>
          ) : (
            ""
          )}
        </Grid>
      </div>
      <AdminCookieConsentBanner />
    </>
  );
};

export default ReportCategory;
