import * as React from 'react';
import { useState, useEffect, useRef } from "react";
import { Grid } from '@mui/material';
import "../table.css"
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';



const ProcedureList = (props) => {
    const handleClick = (row) => {
        let data = {};
        data.is_update = true;
        data.procedureMasterId = row.procedureMasterId;
        data.name = row.name;
        let classification = {}
        props.classificationList.forEach(element => {
          if (element.classificationId === row.classificationId) {
            classification.classificationId = element.classificationId
            classification.classification = element.classification
          }
        });
        let category = {}
        props.categoryList.forEach(element => {
            if (element.procedureCategoryId === row.procedureCategoryId) {
              category.procedureCategoryId = element.procedureCategoryId
              category.procedureCategory = element.procedureCategory
            }
          });
        data.procedureCategoryId = category;
        data.classificationId = classification;
        data.terminology=row.terminology;
        data.code=row.code;
        data.deleted = row.deleted;
        props.setId(row.procedureMasterId);
        props.setTextFieldState({
          ...data,
          is_update: true,
          lastModifiedTime: row.lastModifiedTime,
        });
        props.getUserByIdFunct(row.lastModifiedBy, row.createdBy);
        props.showFormActions();
      };
    
      // const handleOnChange = (event, page) => {
      //   props.getPageData(page);
      // };
      const handleOnChange = (event, page) => {
        props.getProcedurePage(page)
        props.getPageData(page);
    
      };
    
      return (
        <Grid className="">
          <div class="scrollit">
            {props.taskresolutionview===true && props.procedureList.length > 0 ? (
              <table
                className="b "
                style={{
                  width: "90%",
                  marginLeft: "20px",
                  tableLayout: "fixed",
                  height: "20vh",
                }}
              >
                <thead style={{ position: "sticky", zIndex: "1", top: "0" }}>
                  <tr>
                    <th className=" m-r-f" style={{ width: "50px" }}>
                      <input type="checkbox" id="" name="vehicle1" value="Bike" />
                    </th>
                    <th className=" m-r-f">Procedure Name</th>
                    <th className=" m-r-f">System</th>
                    <th className=" m-r-f">Code</th>
                    <th className=" m-r-f">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {props.procedureList &&
                    props.procedureList.length > 0 &&
                    props.procedureList.map((item, i) => {
                      return (
                        <tr
                          key={i}
                          className="tablebor"
                          style={{
                            cursor: "pointer",
                            backgroundColor:
                              props.id === item.procedureCategoryId
                                ? "rgb(15, 67, 145)"
                                : "",
                            color: props.id === item.procedureCategoryId ? "white" : "",
                          }}
                        >
                          <td>
                            <input type="checkbox" id={i} />
                          </td>
                          <td
                            className="  m-r-f"
                             onClick={(event) => handleClick(item)}
                            style={{ cursor: "pointer" }}
                          >
                            <a>{item.name}</a>
                          </td>
                          <td
                            className="  m-r-f"
                             onClick={(event) => handleClick(item)}
                          >
                            {item.terminology}
                          </td>
                          <td
                            className="  m-r-f"
                             onClick={(event) => handleClick(item)}
                          >
                            {item.code}
                          </td>
                          <td
                            className=" m-r-f"
                             onClick={(event) => handleClick(item)}
                          >
                            <span
                              className={item.deleted === false ? "dotg" : "dotr"}
                            ></span>{" "}
                            {item.deleted === false ? " Active" : " Inactive"}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            ) : (
              <div className="record">No Records Found</div>
            )}
          </div>
          {/* { props.taskresolutionview===true && props.procedureList && props.procedureList && props.procedureList.length>0 &&
          <div className="pagesam pt-1 m-1">
            <Stack spacing={2}>
          <Pagination count={props?.totalPage} color="primary" page={props.currentPage} onChange={(e, page) => handleOnChange(e, page)} />
        </Stack>
        <div className='pagenum'>
          <span className='ml-2'>{props?.calculateData?.number * 20 + 1}-{props?.calculateData?.number * 20 + props?.calculateData?.numberOfElements} of {props?.calculateData?.totalElements}</span>
        </div>
          </div>
          } */}
        </Grid>
      );
    };
   


export default ProcedureList;
