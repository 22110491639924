import * as React from "react";
import { Grid } from "@mui/material";
import { useRef } from "react";
import "../table.css";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const PackageTable = (props) => {
  const { handleRowClick, packageList, setId, id } = props;
  const anchorElements = useRef();

  const handleClick = (e, item) => {
    if (props.packageupdate == true) {
      handleRowClick(item);
      setId(item.packageId)
      anchorElements.current.style.color = 'blue';
    } else {
      toast(" you didnt have permission", {
        position: "top-center",
        autoClose: 2000,
hideProgressBar: true,
        type: "error",
        closeOnClick: true,
        theme: "light",
      });
    }
  }
  const handleOnChange = (event, page) => {
    props.getpackagepageList(page)
  };
  console.log(packageList);
  return (
    <Grid className="">
      <div class="scrollit">
        {props && props.packageview && props.packageList && props.packageList.length > 0 ? (
          <table

            style={{ width: "90%", marginLeft: "20px", height: "20vh" }}
          >
            <thead>
              <tr>
                <th className=" m-r-f" style={{ width: "50px" }}>
                  <input type="checkbox" id="" name="vehicle1" value="Bike" />
                </th>
                <th className=" m-r-f">Name</th>
                <th className=" m-r-f">Description</th>
                <th className=" m-r-f">Status</th>
              </tr>
            </thead>

            <tbody style={{ color: "#000000" }}>
              {packageList.length > 0 &&
                packageList.map((item,i ) => {
                  return (
                    <tr key={i} className="" style={{ cursor: 'pointer', backgroundColor: (id && id === item?.packageId) ? "rgb(15, 67, 145)" :  "white", color: id === item.packageId ? "white" : "" }}>
                      <td>
                        <input type="checkbox" id={i} />
                      </td>
                      <td
                        className=" m-r-f"
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={(e) => handleClick(e, item)}
                      >

                        <a className={`column-anchor`} ref={anchorElements} >
                          {item.name}
                        </a>
                      </td>
                      <td className=" m-r-f" style={{ paddingLeft: "" }} onClick={(e) => handleClick(e, item)}>
                        {item.description}
                      </td>
                      <td className=" m-r-f" style={{ paddingLeft: "" }} onClick={(e) => handleClick(e, item)}>
                        <span
                          className={item.status === "ACTIVE" ? "dotg" : "dotr"}
                        ></span>{" "}
                        {item.status === "ACTIVE" ? " Active" : " Inactive"}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        ) : (
          <div className="record">No Records Found</div>
        )}
      </div>

      {props && props.packageList && props.packageList.length > 0 &&
        <div className="pagesam pt-1 m-1">
          <Stack spacing={2}>
            <Pagination count={props.totalPage} color="primary" onChange={(e, page) => handleOnChange(e, page)} page={props.currentPage} />
          </Stack>
          <div className='pagenum'>
            <span className='ml-2'>{props.calculateData.number * 20 + 1}-{props.calculateData.number * 20 + props.calculateData.numberOfElements} of {props.calculateData.totalElements}</span>
          </div>
        </div>
      }

    </Grid>
  );
};

export default PackageTable;
