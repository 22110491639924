import {useState} from "react";
const useHeaderLogic = ()=>{
    const [drawerState , setDrawerState] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);


    const drawerStyleForOpen = {
       
        width:`calc(100% - 240px)`,
        marginLeft:'240px'
          
   }

    const openDrawer = ()=>{
        if(!drawerState){
        setDrawerState(true);
        
        }else{
        setDrawerState(false);
        

        }
    
    }

        const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
        };
        
        const handleClose = () => {
            setAnchorEl(null);
        };

        const menuPropFun = {
            elevation: 0,
            sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
                },
                '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
                },
            },
            }
        

      
     const sendingLogicBackToHeaderComp = {
        drawerState,
        setDrawerState,
        anchorEl,
        setAnchorEl,
        open,
        drawerStyleForOpen,
        openDrawer,
        handleClick,
        handleClose,
        menuPropFun   
     }   

     return sendingLogicBackToHeaderComp; 

}

export default useHeaderLogic;