import { useState } from "react";
import { useGetCreatingReportCategoryMutation } from "../../../services/adminLogIn";

const Sample = () => {

    const [showerror, setErrorMessage] = useState(false);
    const [AllTextFieldState, setAllTextFieldState] = useState({
        is_update: false,
        sampleTypeId: '',
        reportCategoryName: '',
      

    });
    const [open, setOpen] = useState(false);
    const [openFilterModal, setOpenFilterModal] = useState(false);
    const [isSearchEvent, setIsSearchEvent] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleFilterModalOpen = () => { setOpenFilterModal(true); }
    const handleFilterModalClose = () => { setOpenFilterModal(false); }
   const [dropsampleidState, setDropsampleidState] = useState({
        sampleClassificationId: '', sampleClassificationName: ''
    });

    const [isOrgError, setError] = useState({
        isNameError: '', issampleError: ''
    });


    const getDistrictValFun1 = (value) => {
        //   getDistrictValFun(value)
    }

    const resetErrorHandler = (e) => {
        const name = e.target.name;
        switch (name) {
            case "reportCategoryName":
                setError((prev) => ({
                    ...prev,
                    isNameError: isOrgError.isNameError = false
                }))
                break;
        }
    }

    // form on submit handleer 
  
    const whenInputsChange = (e) => {
        const { name, value } = e.target;
        if (name === "reportCategoryName") {
            setAllTextFieldState((prev) => ({
                ...prev,
                [name]: value

            }))
        }
       
        else {
            setAllTextFieldState((prev) => ({
                ...prev,
                [name]: value

            }))
        }

    }
  
    // exporting all handler and state and etc
    const exportAll = {
        whenInputsChange,
        setError,
        AllTextFieldState,
        isOrgError,
        resetErrorHandler,
        setAllTextFieldState,
        getDistrictValFun1,
        dropsampleidState,
        setDropsampleidState,
        open,
        handleClose,
        handleOpen,
        setOpen,
        setErrorMessage,
        handleFilterModalOpen,
        handleFilterModalClose,
        openFilterModal,
        showerror,
        isSearchEvent,
        setIsSearchEvent,
        //    districtlist

    }

    return exportAll;
}

export default Sample;
