import * as React from "react";
import { useState, useEffect, useRef } from "react";
import { Grid } from "@mui/material";
import "../table.css";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

const TagList = (props) => {
  const { hasFocus, value, tagList, selectedPage, setSelectedPage } = props;

  const handleClick = (row) => {
    console.log(row)
    props.setId(row.tagMasterId)
    props.setTextFieldState({...row , 'is_update':true,'lastModifiedTime':row.lastModifiedTime})
    props.getUserByIdFunct(row.lastModifiedBy,row.tagMasterId)
    props.showFormActions()
  }

  const itemsPerPage = 20;
  const totalTags = tagList?.length;
  const totalpages = Math.ceil(totalTags / itemsPerPage);

  const handlePageChange = (event, page) => {
    setSelectedPage(page);
  };

  const start = (selectedPage - 1) * itemsPerPage;
  const end = Math.min(start + itemsPerPage, totalTags);

  return (
    <Grid className="">
      <div class="scrollit">
        {totalTags > 0 ? (
          <table
            className="b "
            style={{
              width: "90%",
              marginLeft: "20px",
              tableLayout: "fixed",
              height: "20vh",
            }}
          >
            <thead style={{ position: "sticky", zIndex: "1", top: "0" }}>
              <tr>
                <th className=" m-r-f" style={{ width: "50px" }}>
                  <input type="checkbox" id="" name="vehicle1" value="Bike" />
                </th>
                <th className=" m-r-f">Tag Name</th>
                <th className=" m-r-f">Tag Type</th>
                <th className=" m-r-f">Status</th>
              </tr>
            </thead>
            <tbody>
              {tagList?.slice(start, end).map((item, i) => {
                const rowIndex = start + i;
                return (
                  //
                  <tr key={i} className="tablebor">
                    <td>
                      <input type="checkbox" id={i} />
                    </td>
                    <td
                      className="  m-r-f"
                      onClick={(event) => handleClick(item)}
                      style={{
                        cursor: "pointer",
                        backgroundColor:
                          props.id === item.tagMasterId
                            ? "rgb(15, 67, 145)"
                            : "",
                        color: props.id === item.tagMasterId ? "white" : "",
                      }}
                    >
                      <a>{item.tagMasterName}</a>
                    </td>
                    <td
                      className="  m-r-f"
                      onClick={(event) => handleClick(item)}
                    >
                      {item.tagMasterType == "GENERIC"
                        ? "Regular Tag"
                        : "Date Tag"}
                    </td>
                    <td
                      className=" m-r-f"
                      onClick={(event) => handleClick(item)}
                    >
                      <span
                        className={item.status === "ACTIVE" ? "dotg" : "dotr"}
                      ></span>{" "}
                      {item.status === "ACTIVE" ? " Active" : " Inactive"}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <div className="record">No Records Found</div>
        )}
      </div>

      {totalTags > 0 && (
        <div className="pagesam pt-1 m-1">
          <Stack spacing={2}>
            <Pagination
              count={totalpages}
              color="primary"
              page={selectedPage}
              onChange={handlePageChange}
            />
          </Stack>
          <div className="pagenum">
            <span className="ml-2">
              {start + 1} - {end} of {totalTags}
            </span>
          </div>
        </div>
      )}
    </Grid>
  );
};

export default TagList;
