import * as React from "react";
import { Grid, Tooltip } from "@mui/material";
import { useState, useEffect, useRef } from "react";
import "../table.css";

const FailedDataSyncJobsList = (props) => {
  const { jobNameList, jobNameAndFailureCountList, id, name, handleProcess } =
    props;
  const [access, setAccess] = useState(
    JSON.parse(sessionStorage.getItem("access"))
  );
  return (
    <Grid className="">
      <div class="scrollit">
        {jobNameList && jobNameList.length > 0 ? (
          <table style={{ width: "90%", marginLeft: "20px", height: "20vh" }}>
            <thead>
              <tr>
                <th className=" m-r-f" style={{ width: "50px" }}>
                  <input type="checkbox" id="" name="vehicle1" value="Bike" />
                </th>
                <th className=" m-r-f">Job Name</th>
                <th className=" m-r-f">Failure Count</th>
                <th className=" m-r-f">Process</th>
              </tr>
            </thead>

            <tbody style={{ color: "#000000" }}>
              {jobNameList.length > 0 &&
                jobNameList.map((item, i) => {
                  return (
                    <tr key={i} className="">
                      <td>
                        <input type="checkbox" id={i} />
                      </td>
                      <td className=" m-r-f">{item[0]}</td>
                      <td className=" m-r-f" style={{ paddingLeft: "" }}>
                        {item[1]}
                      </td>
                      <Tooltip
                        title={
                          access?.faileddatasyncjobsprocess == true ? "" : "No access"
                        }
                        followCursor
                      >
                        <td
                          className=" m-r-f"
                          style={{
                            paddingLeft: "",
                            color: "blue",
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            access?.faileddatasyncjobsprocess == true
                              ? handleProcess(item[0])
                              : ""
                          }
                        >
                          Process
                        </td>
                      </Tooltip>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        ) : (
          <div className="record">No Records Found</div>
        )}
      </div>
      {/* <div className="pagesam pt-1 m-1">
        {props.userview===true && props.bodySitesList && props.bodySitesList.length > 0 && ( 
          <Stack spacing={2}>
          <Pagination count={totalPages} color="primary"onChange={(e,page) => handleOnChange(e,page)}/>
          </Stack>
        )} 
      </div> */}
    </Grid>
  );
};

export default FailedDataSyncJobsList;
