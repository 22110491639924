import * as React from "react";
import { Grid } from "@mui/material";
import { useState } from "react";
import "../table.css";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

const BodySiteList = (props) => {
  const { handleRowClick, bodySitesList, name, totalPages, getBodySitesListData } = props;
  

  const handleClick = (e, item) => {
    handleRowClick(item);
  };
  const handleOnChange = (event, page) => {
    getBodySitesListData(page);
  };

  return (
    <Grid className="">
      <div class="scrollit">
        { props.userview===true && bodySitesList && bodySitesList.length > 0 ? (
          <table style={{ width: "90%", marginLeft: "20px", height: "20vh" }}>
            <thead>
              <tr>
                <th className=" m-r-f" style={{ width: "50px" }}>
                  <input type="checkbox" id="" name="vehicle1" value="Bike" />
                </th>
                <th className=" m-r-f">Body Site Name</th>
                <th className=" m-r-f">System</th>
                <th className=" m-r-f">Code</th>
                <th className=" m-r-f">Status</th>
              </tr>
            </thead>

            <tbody style={{ color: "#000000" }}>
              {bodySitesList.length > 0 &&
                bodySitesList.map((item, i) => {
                  return (
                    <tr
                      key={i}
                      className=""
                      style={{
                        cursor: "pointer",
                        backgroundColor:
                          name && name === item.code
                            ? "rgb(15, 67, 145)"
                            : "white",
                        color:
                          name && name === item.code
                            ? "white"
                            : "",
                      }}
                    >
                      <td>
                        <input type="checkbox" id={i} />
                      </td>
                      <td
                        className=" m-r-f"
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={(e) => handleClick(e, item)}
                      >
                        {item.site}
                      </td>
                      <td
                        className=" m-r-f"
                        style={{ paddingLeft: "" }}
                        onClick={(e) => handleClick(e, item)}
                      >
                        {item.system}
                      </td>
                      <td
                        className=" m-r-f"
                        style={{ paddingLeft: "" }}
                        onClick={(e) => handleClick(e, item)}
                      >
                        {item.code}
                      </td>
                      <td
                        className=" m-r-f"
                        style={{ paddingLeft: "" }}
                        onClick={(e) => handleClick(e, item)}
                      >
                        {item.active}
                        <span
                          className={item.active ? "dotg" : "dotr"}
                        ></span>{" "}
                        {item.active ? " Active" : " Inactive"}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        ) : (
          <div className="record">No Records Found</div>
        )}
      </div>
        {props.userview===true && props.bodySitesList && props.bodySitesList.length > 0 && ( 
      <div className="pagesam pt-1 m-1">
          <Stack spacing={2}>
          <Pagination count={totalPages} color="primary"onChange={(e,page) => handleOnChange(e,page)}/>
          </Stack>
           <div className='pagenum'>
           <span className='ml-2'>{props?.calculateData?.number * 20 + 1}-{props?.calculateData?.number * 20 + props?.calculateData?.numberOfElements} of {props?.calculateData?.totalElements}</span>
         </div>
      </div>
        )} 
    </Grid>
  );
};

export default BodySiteList;
