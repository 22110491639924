import * as React from "react";
import { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Link from "@mui/material/Link";
import { DataGrid } from "@mui/x-data-grid";
import autoprefixer from "autoprefixer";
import {
  useGetActiveOrgValueMutation,
  useGetActiveDistrictValueMutation,
} from "../../../services/adminLogIn";
import SamplereqHook from "./samplereqlogic";
import "../Organization/Mainhome.css";
import { Grid } from "@mui/material";
import "../table.css";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ServiceReqList = (props) => {
  const { hasFocus, value } = props;

  const handleClick = (row) => {
    if (props.servicerequestupdate == true) {
      props.setServiceFormFields({});
      props.setLocPriceList([]);
      props.setBodysiteCode([]);
      props.setProcedureCode([]);
      props.setSpecimenName({});
      let data = {};
      data.is_update = true;
      data.name = row.name; 
      data.specimen = row.specimen;
      data.bodySiteCode = row.bodySiteCode != null ? row.bodySiteCode : [];
      data.locationSpecificDetails =
        row.locationSpecificDetails != null ? row.locationSpecificDetails : [];
      data.laterality = row.laterality != null ? row.laterality : [];
      data.procedureCode = row.procedureCode != null ? row.procedureCode : [];
      data.supportingOrganisations = props.orgDataOptions.filter((element) =>
        row?.supportingOrganisations?.includes(element.id)
      );
      data.supportinglocations = props.locationMultiSelectOptions.filter(
        (element) => row?.supportinglocations?.includes(element.id)
      );
      let loc = data.supportinglocations.map(function (row) {
        return { id: row.id, name: row.name };
      });
      data.supportingLocationsForTable = loc.filter(
        (elem) =>
          !row?.locationSpecificDetails.find(
            ({ locationId }) =>
              elem.name === locationId || elem.id === locationId
          )
      );
      props.sampleList.forEach((element) => {
        if (element.sampleTypeId == row.specimen) {
          props.setSpecimenName({ ...element });
        }
      });
      // const locationPriceListArr = data.locationSpecificDetails.filter((item) => data?.supportinglocations.find(({ id,name }) => item.locationId === name || item.locationId===id));
      let locDetails = [];
      data.locationSpecificDetails.map((item) => {
        const locItem = data.supportinglocations.filter(
          (ele) => item?.locationId == ele?.id
        );
        const returnObj = {
          ...item,
          ["name"]: locItem[0]?.name,
          ["locationId"]: locItem[0]?.id,
        };
        locDetails.push(returnObj);
      });

      // console.log(data.supportingLocationsForTable)
      console.log(locDetails);
      props.setLocPriceList(locDetails);
      props.setBodysiteCode(row.bodySiteCode != null ? row.bodySiteCode : []);
      props.setProcedureCode(
        row.procedureCode != null ? row.procedureCode : []
      );
      data.description = row.description != null ? row.description : "";
      data.instructionToPerformer =
        row.instructionToPerformer != null ? row.instructionToPerformer : [];
      data.modality = row.modality != null ? row.modality : "";
      data.quantity = row.quantity != null ? row.quantity : "";
      data.description = row.description != null ? row.description : "";
      data.reasonCode = row.reasonCode != null ? row.reasonCode : "";
      data.reasonDescription =
        row.reasonDescription != null ? row.reasonDescription : "";
      data.serviceRequestCategoryId =
        row.serviceRequestCategoryId != null
          ? row.serviceRequestCategoryId
          : "";
      data.specialInstructionsToPerformer =
        row.specialInstructionsToPerformer != null
          ? row.specialInstructionsToPerformer
          : "";
      data.subject = row.subject != null ? row.subject : "";

      data.serviceRequestMasterId = row.serviceRequestMasterId;
      // data.specimen = row.specimen
      // data.staticValues = row.staticValues
      data.status = row.status === "ACTIVE" ? "ACTIVE" : "INACTIVE";
      props.setServiceFormFields({ ...data });
      props.showFormActions();
      props.setId(row.serviceRequestMasterId);
    } else {
      toast(" you didnt have permission", {
        position: "top-center",
        autoClose: 2000,
hideProgressBar: true,
        type: "error",
        closeOnClick: true,
        theme: "light",
      });
    }
  };

  // const [data, setData] = useState([]);
  // useEffect(()=>{
  //   setData(props.content)
  //   // getActiveValFun();
  // },[])
  const handleOnChange = (event, page) => {
    props.getservicepageList(page);
  };

  return (
    <Grid className="">
      <div class="scrollit">
        {props &&
        props.servicerequestview === true &&
        props.serviceReqList &&
        props.serviceReqList.length > 0 ? (
          <table
            style={{
              width: "90%",
              marginLeft: "20px",
              tableLayout: "fixed",
              height: "20vh",
            }}
          >
            <thead>
              <tr>
                <th className=" m-r-f" style={{ width: "50px" }}>
                  <input type="checkbox" id="" name="vehicle1" value="Bike" />
                </th>
                <th className=" m-r-f">Service Request Name</th>
                <th className=" m-r-f">Service Request Category</th>
                <th className=" m-r-f">Status</th>
              </tr>
            </thead>
            <tbody style={{ color: "#000000" }}>
              {props &&
                props.serviceReqList &&
                props.serviceReqList.length > 0 &&
                props.serviceReqList.map((item, i) => {
                  return (
                    <tr
                      key={i}
                      style={{
                        cursor: "pointer",
                        backgroundColor:
                          props.id === item.serviceRequestMasterId
                            ? "rgb(15, 67, 145)"
                            : "",
                        color:
                          props.id === item.serviceRequestMasterId
                            ? "white"
                            : "",
                      }}
                    >
                      <td>
                        <input type="checkbox" id={i} />
                      </td>
                      <td
                        className=" m-r-f"
                        onClick={(event) => handleClick(item)}
                      >
                        {item.name}
                      </td>
                      <td
                        className=" m-r-f"
                        onClick={(event) => handleClick(item)}
                      >
                        {item.serviceRequestCategoryId}
                      </td>
                      <td
                        className=" m-r-f"
                        onClick={(event) => handleClick(item)}
                      >
                        <span
                          className={item.status === "ACTIVE" ? "dotg" : "dotr"}
                        ></span>{" "}
                        {item.status === "ACTIVE" ? " Active" : " Inactive"}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        ) : (
          <div className="record">No Records Found</div>
        )}
      </div>

      {props && props.serviceReqList && props.serviceReqList.length > 0 && (
        <div className="pagesam pt-1 m-1">
          <Stack spacing={2}>
            <Pagination
              count={props.totalPage}
              color="primary"
              page={props.currentPage}
              onChange={(e, page) => handleOnChange(e, page)}
            />
          </Stack>
          <div className="pagenum">
            <span className="ml-2">
              {props.calculateData.number * 20 + 1}-
              {props.calculateData.number * 20 +
                props.calculateData.numberOfElements}{" "}
              of {props.calculateData.totalElements}
            </span>
          </div>
        </div>
      )}
    </Grid>
  );
};

export default ServiceReqList;
