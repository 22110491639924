import { useState } from "react";
import { useGetCreatingReportCategoryMutation } from "../../../services/adminLogIn";

const JobsLogic = () => {
       const [showerror, setErrorMessage] = useState(false);
       const [status, setStatus] = useState("");
       const [jobConfiguration, setJobConfiguration] = useState({
        mappings: [
          {
            childDefinition: null,
            targetFieldType: "STRING",
            srcDocumentFieldType: "ID",
            targetIndexSourceFieldNameMap: {
              1: "_id"
            }
          },
          {
            childDefinition: null,
            targetFieldType: "OBJECT",
            srcDocumentFieldType: "PGOBJECT",
            targetIndexSourceFieldNameMap: {
              2: "emailMessageVO"
            }
          },
          {
            targetFieldStr: "(enumStr == null) ? null : com.karkinos.notification.vo.EnumUtilClass.STATUS.valueOf(enumStr)",
            childDefinition: null,
            targetFieldType: "ENUM_STRING",
            srcDocumentFieldType: "ENUM_STRING",
            targetIndexSourceFieldNameMap: {
              3: "status"
            }
          },
          {
            childDefinition: null,
            targetFieldType: "STRING",
            srcDocumentFieldType: "STRING",
            targetIndexSourceFieldNameMap: {
              5: "details",
              8: "createdBy",
              10: "lastModifiedBy"
            }
          },
          {
            childDefinition: null,
            targetFieldType: "BOOLEAN",
            srcDocumentFieldType: "BOOLEAN",
            targetIndexSourceFieldNameMap: {
              4: "priority",
              6: "isSendNow"
            }
          },
          {
            childDefinition: null,
            targetFieldType: "INTEGER",
            srcDocumentFieldType: "INTEGER",
            targetIndexSourceFieldNameMap: {
              7: "retryCount"
            }
          },
          {
            childDefinition: null,
            targetFieldType: "TIMESTAMP",
            srcDocumentFieldType: "TIMESTAMP",
            targetIndexSourceFieldNameMap: {
              9: "createdOn",
              11: "lastModifiedOn"
            }
          }
        ],
        insertQuery: "insert into public.email(id, email_message_vo, email_status, priority, details, is_send_now, retry_count, created_by, created_on, last_modified_by, last_modified_on)values(?,?,?,?,?,?,?,?,?,?,?)on conflict(id) do update set email_message_vo=EXCLUDED.email_message_vo, email_status=EXCLUDED.email_status, priority=EXCLUDED.priority, details=EXCLUDED.details, is_send_now=EXCLUDED.is_send_now, retry_count=EXCLUDED.retry_count, created_by=EXCLUDED.created_by, created_on=EXCLUDED.created_on, last_modified_by=EXCLUDED.last_modified_by, last_modified_on=EXCLUDED.last_modified_on where EXCLUDED.last_modified_on >= public.email.last_modified_on",
        deleteQuery: "delete from public.email where id = ?",
        deleteMappings: [
          {
            childDefinition: null,
            targetFieldType: "STRING",
            srcDocumentFieldType: "STRING",
            targetIndexSourceFieldNameMap: {
              1: "_id"
            }
          }
        ],
        targetTemplate: "unifiedSearchJDBCTemplate",
        srcMongoTemplateName: "notificationMongoTemplate",
        srcMongoCollectionName: "email",
        srcMongoQueryFilterStr: "if (lastRunTimestamp == null){filter = com.mongodb.client.model.Filters.lt(\"createdOn\", java.time.Instant.now());}else{/* else get all active patients lastModifiedOn > lastRunTimestamp */filter = com.mongodb.client.model.Filters.gt(\"lastModifiedOn\", lastRunTimestamp);return filter;}"
      });
    const [AllTextFieldState, setAllTextFieldState] = useState({
        is_update: false,
        job_name: '',
        isActive: "ACTIVE"

    });
  
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [isSearchEvent, setIsSearchEvent] = useState(false);
     const [dropsampleidState, setDropsampleidState] = useState({
        sampleClassificationId: '', sampleClassificationName: ''
    });

    const [isOrgError, setError] = useState({
    
    });

       const resetErrorHandler = (e) => {
        const name = e.target.name;
        switch (name) {
            case "job_name":
                setError((prev) => ({
                    ...prev,
                    isjob_nameError: isOrgError.isjob_nameError = false
                }))
                break;
        }
        
    }
    const handleDropDownChange = (e) => {
     
          if (e === "status") {
          handleOpen()
      }

  }
    const halndleClickonProceed = () => {
      setAllTextFieldState((prev) => ({
          ...prev,
          ["status"]: AllTextFieldState.status === true ? false : true

      }))
      setStatus(AllTextFieldState.status === true ? false : true)
      handleClose()
  }
    // form on submit handleer 
  
    const whenInputsChange = (e) => {
        const { name, value } = e.target;
        if (name === "job_name") {
            setAllTextFieldState((prev) => ({
                ...prev,
                [name]: value.replace(/[^A-Za-z'_]/g, "").toUpperCase()

            }))
           
        }
        if (name === "jobConfiguration") {
            setAllTextFieldState((prev) => ({
                ...prev,
                [name]: value

            }))
           
        }  
        }
       
    // exporting all handler and state and etc
    const exportAll = {
        whenInputsChange,
        setError,
        AllTextFieldState,
        isOrgError,
        resetErrorHandler,
        setAllTextFieldState,
        dropsampleidState,
        setDropsampleidState,
        open,
        setOpen,
        setErrorMessage,
        showerror,
        isSearchEvent,
        setIsSearchEvent,
        jobConfiguration,
        halndleClickonProceed,
        handleClose,
        handleOpen,
        handleDropDownChange,
      
    }

    return exportAll;
}

export default JobsLogic;
