// import { LocalConvenienceStoreOutlined } from "@mui/icons-material";
import { useState } from "react";
// import isEmail from 'validator/es/lib/isEmail';
// import isMobilePhone from 'validator/es/lib/isMobilePhone';
import { useCreateTagMasterMutation, useCreatingSampleMutation, useUpdatigsampleMutation } from "../../../services/adminLogIn";

const MedicineDosageLogic = () => {


    const [showerror, setErrorMessage] = useState(false);

    const [AllTextFieldState, setTextFieldState] = useState({
        is_update: false,
        code: '',
        display: '',
        system:'',
        isActive: "ACTIVE"
    });


    const [open, setOpen] = useState(false);
    const [openFilterModal, setOpenFilterModal] = useState(false);
    const [isSearchEvent, setIsSearchEvent] = useState(false);
    const [status, setStatus] = useState("");
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleFilterModalOpen = () => { setOpenFilterModal(true); }
    const handleFilterModalClose = () => { setOpenFilterModal(false); }

    const [filterData, setFilterData] = useState({ classificationId: "", sampleName: "", filterStatus: "" });


    const [dropdownState, setDropdowntate] = useState({
        code: '',
        display:'',
        system:'',
        isActive: true
    });

    const [isError, setError] = useState({
        iscodeError: false,
        isdisplayError: false,
        issystemError: false,
       
    });

    // dropdown handler 
    const handleDropDownChange = (e, v) => {
        const value = v;
      
      
            setTextFieldState((prev) => ({
                ...prev,
                [e]: value,
            }))
            setError((prev) => ({
                ...prev,
                issystemError: isError.issystemError = false
            }))
        

    }

    const halndleClickonProceed = () => {
        setTextFieldState((prev) => ({
            ...prev,
            ["isActive"]: AllTextFieldState.isActive === "ACTIVE" ? "ACTIVE" : "INACTIVE"

        }))
        setStatus(AllTextFieldState.isActive === "ACTIVE" ? "ACTIVE" : "INACTIVE")
        handleClose()
    }


    const resetErrorHandler = (e) => {
        const name = e.target.code;
        switch (name) {
            case "code":
                setError((prev) => ({
                    ...prev,
                    iscodeError: isError.iscodeError = false
                }))
                break;

            case "display":
                setError((prev) => ({
                    ...prev,
                    isdisplayError: isError.isdisplayError = false
                }))
                break;
           
                case "system":
                    setError((prev) => ({
                        ...prev,
                        issystemError: isError.issystemError = false
                    }))
                    break;
           

        }
    }

    // form on submit handleer 

    const whenInputsChange = (e) => {
        const { name, value } = e.target;
        switch (name) {
            case "code":
                setError((prev) => ({
                    ...prev,
                    iscodeError: isError.iscodeError = false
                }))
                break;

            case "display":
                setError((prev) => ({
                    ...prev,
                    isdisplayError: isError.isdisplayError = false
                }))
                break;
            case "system":
                setError((prev) => ({
                    ...prev,
                    issystemForm: isError.issystemForm = false
                    // issystemError: isError.issystemError = false
                }))
                break;
           


        }
        setTextFieldState((prev) => ({
            ...prev,
            [name]: value

        }))


    }
    // exporting all handler and state and etc
    const exportAll = {
        whenInputsChange,
        handleDropDownChange,
        //    dropsampleState,
        setError,
        AllTextFieldState,
        isError,
        resetErrorHandler,
        setTextFieldState,
        //    setDropsampleState,
        dropdownState,
        setDropdowntate,
        open,
        handleClose,
        handleOpen,
        setOpen,
        halndleClickonProceed,
        setErrorMessage,
        handleFilterModalOpen,
        handleFilterModalClose,
        openFilterModal,
        showerror,
        filterData,
        isSearchEvent,
        setIsSearchEvent,
        //    districtlist

    }

    return exportAll;
}

export default MedicineDosageLogic;
