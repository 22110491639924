// here we are defining the all function realted to auth login
  import { useState } from "react";  
  import { useAdminLogInMutation ,useGetByConstantMutation} from "../../services/adminLogIn";
  import { useDispatch } from "react-redux";
  import { setProfileName } from "../../features/adminLogged";
  // import {isEmail} from 'validator/es/lib/isEmail';


const useCustomHook =  ()=>{
 
  const [adminLogInFunction , adminLogInResponse] = useAdminLogInMutation();
  const [getByConstant ] = useAdminLogInMutation();
  const [isAdminLoggedSuccess , setAdminLogged] = useState(false);
  const [isInputEmpty , setInputEmpty] = useState(true);
  const dispatch = useDispatch();
  
  
  
  
    
  

  // const navigate = useNavigate();

    const [userDetails , setUserDetails] = useState({
        userName:'',
        password:'',
        profileName: 'KDS',
    });
    const [isError , setError] = useState({
         userEmailError:'',
         userPasswordError:''
    });  

    const isEmail = (email) => {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };
      
       // getting user input start onChange  
     const  userInput = (e) =>{
           const {name , value} = e.target;
           setUserDetails({...userDetails , [name]:value})
             
           if(name === "userName"){
            if(userDetails.password.length > 0 && value.length > 0){
              setInputEmpty(false)
            }else{
              setInputEmpty(true)
            }
         
         }
             if(name === "password"){
                if(userDetails.userName.length > 0 && value.length > 0){
                  setInputEmpty(false)
                }else{
                  setInputEmpty(true)
                }
             
             }
      
            // if(userDetails.password.length > 0 && userDetails.userName.length > 0 ){
            //   setInputEmpty(false)
            // }else{
            //   setInputEmpty(true)
            // }
                 
            
            
             
           
            
        } 

        const userSelectProfile = (value) => {
          setUserDetails((prev) => ({...prev, profileName: value}));
          dispatch(setProfileName(value));
        }

        // resetting erro onFocus
     const  resetErrorMsg =  (e)=>{
            const {name} = e.target;
          if(name === "userName"){
            

               if(isError.userEmailError){
                 setError((prev)=> ({...prev , userEmailError : prev.userEmailError = false}));
               }
             
          }else if(name === "password"){
            

             if(isError.userPasswordError){
                 setError((prev)=> ({...prev , userPasswordError : prev.userPasswordError = false}));
               }
          }
       }

       

        // when user Click On sign in button
      const handleLoginForm =  async(e)=>{
        e.preventDefault();
  
        
        if(!isEmail(userDetails.userName)){
            console.log("Error in email");
            setError((prev)=> ({...prev , userEmailError : prev.userEmailError = true}));
           return false;
          
        }else if(userDetails.password === "" || userDetails.password.length < 6){
          console.log("Error in Password");
           setError((prev)=> ({...prev , userEmailError : prev.userEmailError = false , userPasswordError : prev.userPasswordError = true}));
           return false;
        }
        else{ 
          setError((prev)=> ({...prev , userEmailError : prev.userEmailError = false , userPasswordError : prev.userPasswordError = false}));
  

          try {
            const dataIsOk = userDetails;
            adminLogInFunction(dataIsOk);
          } catch (e) {
            console.log(e);
        }
         
          
            

            // navigate("dashboard" ,{replace:true}); 
        }
        

       
      
        
}

  

        const authAction = {
            userInput,
            resetErrorMsg,
            handleLoginForm,
            isError,
            userDetails,
            adminLogInResponse,
            isInputEmpty,
            userSelectProfile,

        }

        return authAction;

}

export default useCustomHook;

