
import  {useState , useEffect} from "react";
import logo from "../../../images/logo.svg";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import {Link, NavLink , Navigate , useNavigate}  from "react-router-dom"; 
import useForgotPasswordHook from "./fPLogic";



const ForgotPassword = () => {
  
  const {  userInput , resetErrorMsg , handleForgotPasswordForm , isError , loginInfo, adminForgotPasswordResponse } = useForgotPasswordHook();
    
   const y = ["a" ,"b" , "c"];

   


  return (
    <>
    {/* root-container-for-form-start */}
<div className="md:w-2/3 lg:w-1/2 w-mywidth mx-auto my-5 h-custom-height-2">
 {/* container for logo start */}
<div className="logocontainer  flex justify-center items-center mx-auto mt-5 hus w-6/12  h-14">
     <figure>
        <img src={logo} alt="karkinos Logo" />
    </figure>
</div>
 {/* container for logo end */}
   
  {/*  sub-first container for form  start */}
<div className="formcontainer w-4/5 sm:w-3/4 mx-auto my-10 h-2/ ">
       {/* sign in heading container  start*/}
      <div className="heading text-xl font-light flex justify-center ">
           <span className="font-medium text-gray-500">Forgot Password</span>
      </div>

      
       {/* sign in heading container  end*/}

      {/* second container for form start  */}
   <div className={`inputcontainer w-mywidth  sm:w-3/4 mx-auto my-6  border border-solid border-bordercus rounded-myrounded `}>
          
          {/* main-form start */}

        <form onSubmit={(e)=>handleForgotPasswordForm(e)} autoComplete="off">  
           {/* container-for-user-email-start                     */}
           <div className="emailcontainer w-mywidth-2 m-4  h-16 pt-4">
               <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                   <TextField  onFocus={(e)=>resetErrorMsg(e)} error={isError.userNameError === true} helperText={isError.userNameError ? "Valid E-mail is Required!" : ""} onChange={(e)=> userInput(e)} name="userName" value={loginInfo.userName} autoComplete="off" type="text" id="userName" label="Email ID" variant="standard" style={{width:'100%'}} />
               </Box>
           </div>
           {/* container-for-user-email-end                     */}
                   
                <div className="warningContainer text-base justify-start items-center pt-5 m-2">
                   <span className="font-medium text-gray text-sm block text-center"> An email with the 'password reset' link </span>
                   <span className="font-medium text-gray text-sm block text-center">would be sent by the admin to your email ID </span>
                 
                   </div>
               
                 
                {/* container-for-sing-in-button-start  */}
                 
                  <div className="progressBar  flex justify-center items-center mt-4" >
                       {
                        adminForgotPasswordResponse.isError ?
                           <Alert severity="error" color="warning">
                               Opps... Some Error Occured  
                           </Alert>
                           : adminForgotPasswordResponse.isLoading ?
                           <CircularProgress />
                           : 
                            
                           adminForgotPasswordResponse.isSuccess ?
                           <Alert  severity="success" color="success">
                                 E-mail Sent Successfully !
                           </Alert>
                           :null 


                           
                       }
                    
                  </div>


               <div className="buttoncontainer m-3 w-1/2 mx-auto rounded-full  h-11 my-5 ">
                      
                       <Button type="submit" variant="contained" disableElevation style={{borderColor: '#0F4391' ,  width:'100%' , height:'100%' , borderRadius:'9999px'  , backgroundColor:'#E8FAFF' , color:'#0F4391' }}>
                           SUBMIT
                       </Button> 
                      
               </div>
                {/* container-for-sing-in-button-end */}
                
                

           </form>
           {/* main-form end */}

   </div>
     {/* second container for form start  */}
     
  {/* container-for-forgot-password-start */}
  <div className="linkcontainer text-center hover:text-blue-500  mx-auto my-5 w-1/2 ">
                   <NavLink to="/" style={{textDecoration:'underline'}}>
                      Sign In
                   </NavLink>
                   
               </div>
               {/* container-for-forgot-password-end */}
          
           
</div>
 {/*  sub-first container for form  end */}

  


</div>
{/* root-container-for-form-end */}
</>
  )
}

export default ForgotPassword;






