// here we are defining the all function realted to auth login
import { useState } from "react";  
import { useNavigate } from "react-router-dom";
import isEmail from 'validator/es/lib/isEmail';
import { useAdminForgotPasswordMutation, useForgotPasswordV2Mutation } from "../../../services/adminLogIn";
import {decryptAdminId} from "../../../globalFunctions/getLocalStorageData";
import { useSelector } from "react-redux";


const useForgotPasswordHook =  ()=>{

const [adminForgotPasswordFunction , adminForgotPasswordResponse] = useAdminForgotPasswordMutation();
const [forgotPasswordV2] = useForgotPasswordV2Mutation();
const profileName = useSelector((state) => state.adminLog.profileName);



  




  const [loginInfo , setLoginInfo] = useState({
      userName:'',
      
  });
  const [isError , setError] = useState({
       userNameError:'',
     
  });  


    
     // getting user input start onChange  
   const  userInput = (e) =>{
          

         const {name , value} = e.target;
          setLoginInfo({...loginInfo , [name]:value});
      } 

      // resetting erro onFocus
   const  resetErrorMsg =  (e)=>{
          const {name} = e.target;
        if(name === "userName"){
          

             if(isError.userNameError){
               setError((prev)=> ({...prev ,  userNameError : prev.userNameError = false}));
             }
           
        }
     }

     

      // when user Click On sign in button
    const handleForgotPasswordForm =  (e)=>{
      e.preventDefault();

      
      if(!isEmail(loginInfo.userName)){
          console.log("Error in Email");
          setError((prev)=> ({...prev , userNameError : prev.userNameError= true}));
         return false;
        
      } else{
        setError((prev)=> ({...prev ,  userNameError : prev.userNameError = false }));

          const dataIsOk = loginInfo;
          console.log(dataIsOk);
          if(profileName === "KDS") {
            adminForgotPasswordFunction(loginInfo.userName.toString());
          }
          else if(profileName === "KCLOUD") {
            forgotPasswordV2({userName: loginInfo.userName.toString(), profileName});
          }

          
         // adminForgotPasswordFunction({username: loginInfo.userName.toString(), profileName: userDetails.profileName});
        //   const adminId = decryptAdminId();

        //   const newPasswordWithAdminId = {
        //     password:loginInfo.userName,
        //     userId:adminId && adminId  
        //   }

         
          
      
       
        
          

          // navigate("dashboard" ,{replace:true}); 
      }
      

     
    
      
}



      const forgotPasswordAction = {
          userInput,
          resetErrorMsg,
          handleForgotPasswordForm,
          isError,
          loginInfo,
          adminForgotPasswordResponse

      }

      return forgotPasswordAction;

}

export default  useForgotPasswordHook;

