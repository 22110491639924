import React, { useEffect, useState, useRef } from "react";
import AdminHome from "../AdminHome";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import TextField from "@mui/material/TextField";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CircularProgress from "@mui/material/CircularProgress";
import filter from "../../../images/filter.png";
import "../Organization/Mainhome.css";
import { Grid, Button, MenuItem } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import InputAdornment from "@mui/material/InputAdornment";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { LoggerFunction } from "../../../logger/logger";
import { useSelector } from "react-redux";
import Delete from "../../global/icons/delete.png";

import {
  useGetencountertypeslistMutation,
  useGetEncounterTypeDataMutation,
  useGetSystemStatesMutation,
  useDeleteSystemStateMutation,
  useUpdateSystemStatesMutation,
  useGetEncounterTypeNameMutation,
} from "../../../services/adminLogIn";
import useCreateEncounterTypesHook from "./encountertypeslogic";
import EncounterTypeList from "./encountertypeslist";
import { red } from "@mui/material/colors";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getTokensFromStorage } from "../../../globalFunctions/getLocalStorageData";
import { debounce } from "lodash";
import Breadcrums from "../../global/breadcrums/Breadcrums";
import AdminCookieConsentBanner from "../home/AdminCookieConsentBanner";

const tok = getTokensFromStorage();
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const colorPalette = [
  {
    color: "#FFD53E4B",
    className: "cirActive1a",
  },
  {
    color: "#FFD72E68",
    className: "cirActive2a",
  },
  {
    color: "#FFB545C8",
    className: "cirActive3a",
  },
  {
    color: "#FF8360C4",
    className: "cirActive4a",
  },
  {
    color: "#FF626ECA",
    className: "cirActive5a",
  },
  {
    color: "#FF2379C0",
    className: "cirActive6a",
  },
  {
    color: "#FF147FB2",
    className: "cirActive7a",
  },
  {
    color: "#FF078296",
    className: "cirActive8a",
  },
  {
    color: "#FF0A857A",
    className: "cirActive9a",
  },
  {
    color: "#FF07870C",
    className: "cirActive10a",
  },
  {
    color: "#FF727B02",
    className: "cirActive11a",
  },
  {
    color: "#FF827503",
    className: "cirActive12a",
  },
  {
    color: "#FFA76502",
    className: "cirActive13a",
  },
  {
    color: "#FFC44F2A",
    className: "cirActive14a",
  },
 
]

const EncounterTypes = (props) => {
  const [expand, setexpand] = useState({
    heading: "",
    icon: "",
    isSubListExpanded: false,
  });
  const [expand1, setexpand1] = useState({
    empty: true,
  });
  const [isFormVisible, setFormVisible] = useState({
    isOpen: false,
    isClose: true,
  });
  const colorPaletteRef = useRef([]);
  const colorPaletteLabeRef = useRef([]);
  const [encounterType, setEncounterType] = useState("");
  const [encounterTypeId, setEncounterTypeId] = useState("");
  const [getEncounterTypeListData] = useGetencountertypeslistMutation();
  const [getEncounterTypeData] = useGetEncounterTypeDataMutation();
  const [getSystemStates] = useGetSystemStatesMutation();
  const [deleteSystemState] = useDeleteSystemStateMutation();
  const [updateSystemStates] = useUpdateSystemStatesMutation();
  const [getEncounterTypeName] = useGetEncounterTypeNameMutation();
  const [encounterListData, setEncounterListData] = useState([]);
  const [systemStates, setSystemStates] = useState([]);
  const [color, setcolor] = useState([]);
  const [open, setOpen] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState();
  const [deleteId, setDeleteId] = useState();
  const [rowItem, setRowItem] = useState();
  const [recordsLoading, setRecordsLoading] = useState(true);
  const [access, setAccess] = useState(
    JSON.parse(sessionStorage.getItem("access"))
  );
  const [searchValue, setSearchValue] = useState();
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [selectedColor, setSelectedColor] = useState("");
  const [selectedIndex, setSelectedIndex] = useState();
  const [isColorUpdate, setIsColorUpdate] = useState(false);

  const {
    handleInputChange,
    resetErrorHandler,
    addRow,
    handleDropdownStates, 
    deleteRow,
    setEncounterSystemStates,
    setEncounterCustomStates,
    setShowCustomFieldError,
    showCustomFiedlError,
    encounterFormFields,
    encounterSystemStates,
    encounterCustomStates,
    AllTextFieldState,
    setTextFieldState,
  } = useCreateEncounterTypesHook();

  const showFormActions = () => {
    if (access?.userupdate == true) {
      if (!isFormVisible.isOpen) {
        setFormVisible((prev) => ({
          ...prev,
          isOpen: (isFormVisible.isOpen = true),
          isFormVisible,
          isClose: (isFormVisible.isClose = false),
        }));
      }
    } else {
      toast(" No Permission", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        type: "error",
        closeOnClick: true,
        theme: "light",
      });
    }
  };
  const handelRowClick = async (item) => {
    setRowItem(item);
    const res = await getEncounterTypeData(item.encounterId);
    if (res) {
      showFormActions();
      setEncounterType(item.name);
      setEncounterTypeId(item.encounterId);
      let systemStates = [];
      let customStates = [];
      res.data.forEach((ele) => {
        systemStates.push({
          name: ele.systemStateId,
          id: ele.id,
          isDisabled: true,
          isDefault: ele.isDefault,
          extension: ele.extension ? ele.extension : null,
        });
        customStates.push({ value: ele.state, isDisabled: true });
      });
      setEncounterCustomStates([...customStates]);
      setEncounterSystemStates([...systemStates]);
    }

    const encounterTypeName = await getEncounterTypeName(item.name);
  };
  const handleCloseIconClick = (i, id) => {
    if (id === "") {
      deleteRow(i);
    }
  };
  const getEncounterTypeList = async (encounterTypeName) => {
    const listData = await getEncounterTypeListData(encounterTypeName);
    setEncounterListData(listData);
    setRecordsLoading(false);
  };

  const getSystemStatesData = async () => {
    const systemStates = await getSystemStates();
    if (systemStates) {
      const systemStatesArr = systemStates.data.map(
        (item) => item.systemStateId
      );
      setSystemStates(systemStatesArr);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setShowCustomFieldError(false)
  };

  const handleProceed = async () => {
    const res = await deleteSystemState(deleteId);
    if (res?.error) {
      // deleteRow(deleteIndex);
      toast(" Somethong went wrong!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        type: "error",
        closeOnClick: true,
        theme: "light",
      });
      setOpen(false);
    } else {
      toast(" successfully Deleted!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        type: "success",
        closeOnClick: true,
        theme: "light",
      });
      deleteRow(deleteIndex);
      setOpen(false);
      setRowItem((prev) => ({ ...prev, lastModifiedTime: new Date() }));
    }
  };

  const handleDeleteRow = (i, id) => {
    if (id) {
      setOpen(true);
      setDeleteIndex(i);
      setDeleteId(id);
    }
  };

  const handleCheckboxChange = async (isActive, i, item) => {
    const systemStates = [...encounterSystemStates];
    const customStates = [...encounterCustomStates];
    systemStates[i].isChecked = true;
    customStates[i].isChecked = true;
    systemStates[i].isNew = true;
    customStates[i].isNew = true;
    setEncounterCustomStates(systemStates);
    setEncounterCustomStates(customStates);
    if (
      isActive &&
      encounterCustomStates[i].value &&
      encounterSystemStates[i].name
    ) {
      setShowCustomFieldError(false);
      let extentionsave = {
        additionalProp1: AllTextFieldState.extension,
      };
      const dataToSend = {
        // version: 0,
        referenceId: encounterTypeId,
        referenceTypeId: "ENCOUNTER_TYPE",
        state: encounterCustomStates[i].value,
        systemStateId: encounterSystemStates[i].name,
        isDefault: encounterSystemStates[i].isDefault,
        extension: {
          color: AllTextFieldState.extension,
         
        },

        status: "ACTIVE",
      };
      const res = await updateSystemStates(dataToSend);
      if (res?.error) {
        toast(res?.error?.data?.message, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          type: "error",
          closeOnClick: true,
          theme: "light",
        });
      } else {
        toast(encounterCustomStates[i].value + " successfully Saved!", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          type: "success",
          closeOnClick: true,
          theme: "light",
        });
        if (item.id === "") {
          systemStates[i].id = encounterCustomStates[i].value.toUpperCase();
        }
        systemStates[i].isDisabled = true;
        customStates[i].isDisabled = true;
        systemStates[i].isChecked = false;
        customStates[i].isChecked = false;
        systemStates[i].isNew = false;
        customStates[i].isNew = false;
        setEncounterCustomStates(systemStates);
        setEncounterCustomStates(customStates);
        let currentName =
          tok && tok[0] && JSON.parse(atob(tok[0].split(".")[1]));
        setRowItem((prev) => ({
          ...prev,
          lastModifiedByName: currentName?.name,
        }));
        setRowItem((prev) => ({ ...prev, lastModifiedTime: new Date() }));
      }
    } else {
      systemStates[i].isChecked = false;
      customStates[i].isChecked = false;
      systemStates[i].isNew = false;
      customStates[i].isNew = false;
      setEncounterCustomStates(systemStates);
      setEncounterCustomStates(customStates);
      setShowCustomFieldError(true);
    }
  };

  const handleFilterModalClose = () => {
    setOpenFilterModal(false);
  };

  const handleFilterModalOpen = (val, task) => {
    if(val !== undefined) {
      setSelectedIndex(val);
      if(task === "update") {
        setIsColorUpdate(true);
      }
      else {
        setIsColorUpdate(false);
      }
    }
    setOpenFilterModal(true);
    setSelectedColor("");
    setTextFieldState((prev) => ({
      ...prev,
      ["extension"]: "",
    }));
  };
  const handleColorInput = (value) => {
    setSelectedColor(value);
    setTextFieldState((prev) => ({
      ...prev,
      ["extension"]: "",
    }));
  };

  const getClassNameFromColorPalette = (color) => {
    const colorPaletteItem = colorPalette.find((item) => item.color === color);
    return colorPaletteItem.className;
  }
  const handleColorPaleteModal = () => {
    colorPaletteRef.current[selectedIndex].innerHTML = `<i class=${getClassNameFromColorPalette(selectedColor)} >`;
    if(!isColorUpdate) {
      colorPaletteLabeRef.current[selectedIndex].className = "color-palette-label-hide";
    }
    console.log(AllTextFieldState);
    setTextFieldState((prev) => ({
      ...prev,
      ["extension"]: AllTextFieldState.extension === selectedColor ? "" : selectedColor,
    }));
    handleFilterModalClose();
    const systemArr = [...encounterSystemStates];
    const customStatesArr = [...encounterCustomStates];
    systemArr[selectedIndex].isDisabled = false;
    customStatesArr[selectedIndex].isDisabled = false;
    setEncounterSystemStates(systemArr);
    setEncounterCustomStates(customStatesArr);
  }
  const refreshForm = async () => {
    if (encounterCustomStates.length && encounterSystemStates.length) {
      const isCustomStateValue = encounterCustomStates.find((item) => item.value === "" || item.value === undefined);
      const isSystemStateName = encounterSystemStates.find((item) => item.name === "" || item.name === undefined);
      if(isCustomStateValue || isSystemStateName) {
        setShowCustomFieldError(true);
      }
      else {
        setShowCustomFieldError(false);
        hideForm();
        setRecordsLoading(true);
        const listData = await getEncounterTypeListData("");
        setEncounterListData(listData);
        if (listData) {
          setRecordsLoading(false);
        }
      }
    }
  };

  useEffect(() => {
    getEncounterTypeList("");
    getSystemStatesData();
  }, []);

  const searchuserevent = (value) => {
    setSearchValue(value);
    getEncounterTypeList(value);
  };

  const hideForm = () => {
    if (isFormVisible.isOpen) {
      setSearchValue("");
      setShowCustomFieldError(false);
      setFormVisible((prev) => ({
        ...prev,
        isOpen: (isFormVisible.isOpen = false),
        isFormVisible,
        isClose: (isFormVisible.isClose = true),
      }));
    }
  };
  return (
    <div className="create-package">
      {recordsLoading ? (
        <div style={{ position: "fixed", left: "50%", top: "50%" }}>
          <CircularProgress />
        </div>
      ) : (
        <div className={props.expand1.empty === true ? "main1" : "main"}>
          <Breadcrums
            firsttab={"Categories"}
            secondtab="Clinical"
            thirdtab="Encounter Types"
          ></Breadcrums>

          <Grid container mt={-2}>
            <Grid item xs={6}>
              <div
                className={
                  isFormVisible.isOpen
                    ? "leftcontainer rounded-lg"
                    : "leftcontainer1 rounded-lg"
                }
              >
                <Grid className="">
                  <Grid className=" p-7 ">
                    <TextField
                      label=""
                      onChange={(e) => searchuserevent(e.target.value)}
                      onKeyDown={(e) => {
                        e.key === "Enter" && e.preventDefault();
                      }}
                      sx={{ m: 1, width: "25ch" }}
                      value={searchValue}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchOutlinedIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>

                  <Grid className="editContainer rounded-radiusFourPix ">
                    {/* <Grid
                    onClick={showFormActions}
                    className={
                      isFormVisible.isOpen ? "addIconchange" : "addIcon"
                    }
                  >
                    <span className=" p-2 m-r-f ">Add</span>
                    <AddCircleOutlineIcon
                      style={{
                        fontSize: "14px",
                        color: "#545454",
                        marginBottom: "3px",
                        color: `${
                          isFormVisible.isOpen ? "#ffffff" : "#545454"
                        }`,
                      }}
                    />
                  </Grid> */}

                    {/* <Grid className="Addaction">
                    <span className="p-2 m-r-f">Actions</span>
                    <AddCircleOutlineIcon
                      style={{
                        fontSize: "14px",
                        color: "#545454",
                        marginBottom: "3px",
                      }}
                    />
                  </Grid> */}

                    {/* <Grid className="addIcon">
                    <span className="p-2 m-r-f">Filter</span>
                    <img
                      src={filter}
                      alt="filter.png"
                      style={{
                        position: "absolute",
                        left: "235px",
                        right: "20px",
                        top: "15px",
                        bottom: "95px",
                        width: "11px",
                        paddingTop: "3px",
                      }}
                    ></img>
                  </Grid> */}
                  </Grid>
                </Grid>
                <>
                  <EncounterTypeList
                    // setErrorMessage={setErrorMessage}
                    // setSpecimenName={setSpecimenName}
                    // sampleList={sampleList}
                    // setBodysiteCode={setBodysiteCode}
                    // setProcedureCode={setProcedureCode}
                    // setLocPriceList={setLocPriceList}
                    // setTotalPage={setTotalPage} totalPage={totalPage}
                    // setId={setId}
                    // id={id}
                    encountertypeslist={encounterListData}
                    handelRowClick={handelRowClick}
                    userview={access?.userview}
                    userupdate={access?.userupdate}
                    // serviceReqList={[...serviceReqList]}
                    // showFormActions={showFormActions}
                    // setServiceFormFields={setServiceFormFields}
                  />
                </>
              </div>
            </Grid>
            {isFormVisible.isOpen ? (
              <Grid item xs={6}>
                <div className="rightcontainer rounded-lg servicefullright">
                  <div className="overflow-y-auto o-form-container">
                    <>
                      <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <Box sx={style}>
                          <Typography
                            id="modal-modal-title"
                            variant="h6"
                            component="h2"
                          >
                            <Alert severity="info">
                              Are you sure you want to delete this encounter
                              state? This action cannot be undone.
                            </Alert>
                          </Typography>
                          <Typography
                            id="modal-modal-description"
                            sx={{ mt: 2 }}
                          >
                            <div className="mt-10 flex justify-center items-center  towButtonContainer">
                              <Button
                                type="submit"
                                onClick={handleClose}
                                variant="contained"
                                disableElevation
                                style={{
                                  marginRight: "10px",
                                  borderColor: "#0F4391",
                                  width: "99px",
                                  height: "39px",
                                  borderRadius: "9999px",
                                  backgroundColor: "#E8FAFF",
                                  color: "#0F4391",
                                  border: "1px solid #0F4391",
                                  marginBottom: "100px",
                                }}
                              >
                                CANCEL
                              </Button>

                              <Button
                                type="submit"
                                onClick={handleProceed}
                                variant="contained"
                                disableElevation
                                style={{
                                  borderColor: "#0F4391",
                                  width: "99px",
                                  height: "39px",
                                  borderRadius: "9999px",
                                  backgroundColor: "#E8FAFF",
                                  color: "#0F4391",
                                  border: "1px solid #0F4391",
                                  marginBottom: "100px",
                                }}
                              >
                                PROCEED
                              </Button>
                            </div>
                          </Typography>
                        </Box>
                      </Modal>
                      <ToastContainer />
                      <>
                        <div className="font-normal text-o-form-label-color mainhead flex justify-between items-center pl-5 pr-1 h-10">
                          <h1 id="COLOR">Overview</h1>
                        </div>
                        <div className="mt-5 pl-5 OrgNameContainer">
                          <h3 className="font-normal text-o-form-label-color">
                            Encounter Type
                          </h3>
                          <TextField
                            disabled
                            variant="standard"
                            autoComplete="OFF"
                            name="encounterType"
                            value={encounterType}
                            sx={{ width: 200 }}
                            id="standard-basic"
                            label=""
                          />
                        </div>
                        <form autoComplete="off">
                          <div className="mt-5 pl-5 OrgNameContainer ">
                            <table
                              className="a tableborder"
                              // style={{ width: "95%" }}
                            >
                              <thead className="a tableborder">
                                <tr>
                                  <td
                                    className="a m-r-f tableborder"
                                    style={{ width: "" }}
                                  >
                                    Name of Custom States
                                  </td>
                                  <td
                                    className="a m-r-f tableborder"
                                    style={{ width: "" }}
                                  >
                                    Name of System States
                                  </td>
                                  <td
                                    className="a m-r-f tableborder"
                                    style={{ width: "" }}
                                  >
                                    Is Default
                                  </td>
                                  <td
                                    className="a m-r-f tableborder"
                                    style={{ width: "" }}
                                  >
                                    Colour Field
                                  </td>
                                  <td
                                    className="a m-r-f tableborder"
                                    style={{ width: "" }}
                                  >
                                    Action
                                  </td>
                                </tr>
                              </thead>
                              <tbody>
                                {encounterSystemStates &&
                                  encounterSystemStates.length > 0 &&
                                  encounterSystemStates.map((item, i) => {
                                    return (
                                      <tr key={i} className="tableborder a">
                                        <td
                                          className=" m-r-f tableborder a"
                                          style={{
                                            width: "200px",
                                            paddingLeft: "",
                                            height: "5px",
                                          }}
                                        >
                                          <TextField
                                            onFocus={(e) =>
                                              resetErrorHandler(e)
                                            }
                                            onKeyDown={(e) => {
                                              e.key === "Enter" &&
                                                e.preventDefault();
                                            }}
                                            autoComplete="OFF"
                                            onChange={(e) =>
                                              handleInputChange(e, i)
                                            }
                                            name="customState"
                                            value={
                                              encounterCustomStates &&
                                              encounterCustomStates[i] &&
                                              encounterCustomStates[i].value
                                            }
                                            style={{ width: "150px" }}
                                            id="standard-basic"
                                            label=""
                                            variant="standard"
                                          />
                                        </td>
                                        <td
                                          className=" m-r-f tableborder a"
                                          style={{
                                            width: "200px",
                                          }}
                                        >
                                          <Autocomplete
                                            onKeyDown={(e) => {
                                              e.key === "Enter" &&
                                                e.preventDefault();
                                            }}
                                            value={item.name}
                                            autoHighlight={true}
                                            onChange={(e, v) =>
                                              handleDropdownStates(
                                                "systemStates",
                                                i,
                                                v
                                              )
                                            }
                                            getOptionLabel={(option) =>
                                              option ? option : ""
                                            }
                                            id="org-list-option"
                                            options={
                                              systemStates ? systemStates : []
                                            }
                                            sx={{ width: 300 }}
                                            renderInput={(params) => (
                                              <TextField
                                                variant="standard"
                                                {...params}
                                                label=""
                                              />
                                            )}
                                          />
                                        </td>
                                        <td
                                          className="tableborder"
                                          style={{
                                            width: "100px",
                                          }}
                                          onKeyDown={(e) => {
                                            e.key === "Enter" &&
                                              e.preventDefault();
                                          }}
                                        >
                                          <input
                                            type="checkbox"
                                            onChange={(e) =>
                                              handleInputChange(e, i)
                                            }
                                            id="isdefault"
                                            name="isdefault"
                                            disabled={
                                              encounterSystemStates &&
                                              encounterSystemStates[i] &&
                                              encounterSystemStates[i].isDefault
                                            }
                                            checked={
                                              encounterSystemStates &&
                                              encounterSystemStates[i] &&
                                              encounterSystemStates[i].isDefault
                                            }
                                          ></input>
                                        </td>
                                        <h3 className="font-normal text-o-form-label-color mt-7 pl-1">
                                          {!item.extension?.color ? (
                                            <>
                                              <span
                                                className="cursor-pointer "
                                                onClick={() =>
                                                  handleFilterModalOpen(i)
                                                }
                                                ref={(ele) =>
                                                  (colorPaletteLabeRef.current[
                                                    i
                                                  ] = ele)
                                                }
                                              >
                                                Select Colour
                                              </span>
                                              <div
                                                className={`color-palette-column${i}`}
                                                ref={(ele) =>
                                                  (colorPaletteRef.current[i] =
                                                    ele)
                                                }
                                                onClick={() =>
                                                  handleFilterModalOpen(i, "select")
                                                }
                                              ></div>
                                            </>
                                          ) : (
                                            <span
                                              className={`color-palette-column${i}`}
                                              ref={(ele) =>
                                                (colorPaletteRef.current[i] =
                                                  ele)
                                              }
                                              onClick={() =>
                                                handleFilterModalOpen(i, "update")
                                              }
                                            >
                                              <i
                                                className={getClassNameFromColorPalette(
                                                  item.extension.color
                                                )}
                                              />
                                            </span>
                                          )}
                                        </h3>

                                        <Modal
                                          open={openFilterModal}
                                          // onClose={handleClose}
                                          aria-labelledby="modal-modal-title"
                                          aria-describedby="modal-modal-description"
                                        >
                                          <Box
                                            sx={style}
                                            style={{
                                              width: "550px",
                                              padding: "50px",
                                              borderColor: "white",
                                            }}
                                          >
                                            <Typography
                                              className="filtermain"
                                              id="modal-modal-title"
                                              variant="h6"
                                              component="h2"
                                              style={{
                                                fontFamily: "MontserratRegular",
                                                marginTop: "5px",
                                              }}
                                            >
                                              Choose Colour
                                            </Typography>
                                            <div className="pl-8">
                                              <i
                                                className={
                                                  selectedColor === "#FFD53E4B"
                                                    ? "cirActive1"
                                                    : "cir1"
                                                }
                                                onClick={(e, v) =>
                                                  handleColorInput("#FFD53E4B")
                                                }
                                              ></i>
                                              <i
                                                className={
                                                  selectedColor === "#FFD72E68"
                                                    ? "cirActive2"
                                                    : "cir2"
                                                }
                                                onClick={(e, v) =>
                                                  handleColorInput("#FFD72E68")
                                                }
                                              ></i>
                                              <i
                                                className={
                                                  selectedColor === "#FFB545C8"
                                                    ? "cirActive3"
                                                    : "cir3"
                                                }
                                                onClick={(e, v) =>
                                                  handleColorInput("#FFB545C8")
                                                }
                                              ></i>
                                              <i
                                                className={
                                                  selectedColor === "#FF8360C4"
                                                    ? "cirActive4"
                                                    : "cir4"
                                                }
                                                onClick={(e, v) =>
                                                  handleColorInput("#FF8360C4")
                                                }
                                              ></i>
                                              <i
                                                className={
                                                  selectedColor === "#FF626ECA"
                                                    ? "cirActive5"
                                                    : "cir5"
                                                }
                                                onClick={(e, v) =>
                                                  handleColorInput("#FF626ECA")
                                                }
                                              ></i>
                                              <i
                                                className={
                                                  selectedColor === "#FF2379C0"
                                                    ? "cirActive6"
                                                    : "cir6"
                                                }
                                                onClick={(e, v) =>
                                                  handleColorInput("#FF2379C0")
                                                }
                                              ></i>
                                              <i
                                                className={
                                                  selectedColor === "#FF147FB2"
                                                    ? "cirActive7"
                                                    : "cir7"
                                                }
                                                onClick={(e, v) =>
                                                  handleColorInput("#FF147FB2")
                                                }
                                              ></i>
                                            </div>
                                            <div className="pl-8">
                                              <i
                                                className={
                                                  selectedColor === "#FF078296"
                                                    ? "cirActive8"
                                                    : "cir8"
                                                }
                                                onClick={(e, v) =>
                                                  handleColorInput("#FF078296")
                                                }
                                              ></i>
                                              <i
                                                className={
                                                  selectedColor === "#FF0A857A"
                                                    ? "cirActive9"
                                                    : "cir9"
                                                }
                                                onClick={(e, v) =>
                                                  handleColorInput("#FF0A857A")
                                                }
                                              ></i>
                                              <i
                                                className={
                                                  selectedColor === "#FF07870C"
                                                    ? "cirActive10"
                                                    : "cir10"
                                                }
                                                onClick={(e, v) =>
                                                  handleColorInput("#FF07870C")
                                                }
                                              ></i>
                                              <i
                                                className={
                                                  selectedColor === "#FF727B02"
                                                    ? "cirActive11"
                                                    : "cir11"
                                                }
                                                onClick={(e, v) =>
                                                  handleColorInput("#FF727B02")
                                                }
                                              ></i>
                                              <i
                                                className={
                                                  selectedColor === "#FF827503"
                                                    ? "cirActive12"
                                                    : "cir12"
                                                }
                                                onClick={(e, v) =>
                                                  handleColorInput("#FF827503")
                                                }
                                              ></i>
                                              <i
                                                className={
                                                  selectedColor === "#FFA76502"
                                                    ? "cirActive13"
                                                    : "cir13"
                                                }
                                                onClick={(e, v) =>
                                                  handleColorInput("#FFA76502")
                                                }
                                              ></i>
                                              <i
                                                className={
                                                  selectedColor === "#FFC44F2A"
                                                    ? "cirActive14"
                                                    : "cir14"
                                                }
                                                onClick={(e, v) =>
                                                  handleColorInput("#FFC44F2A")
                                                }
                                              ></i>
                                            </div>
                                            <Button
                                              variant="contained"
                                              disableElevation
                                              onClick={handleFilterModalClose}
                                              style={{
                                                marginRight: "10px",
                                                top: "30px",
                                                borderColor: "#0F4391",
                                                width: "99px",
                                                height: "39px",
                                                borderRadius: "9999px",
                                                backgroundColor: "#E8FAFF",
                                                color: "#0F4391",
                                                border: "1px solid #0F4391",
                                                marginBottom: "5px",
                                              }}
                                            >
                                              CANCEL
                                            </Button>
                                            <Button
                                              variant="contained"
                                              disableElevation
                                              onClick={handleColorPaleteModal}
                                              style={{
                                                marginRight: "10px",
                                                top: "30px",
                                                borderColor: "#0F4391",
                                                width: "99px",
                                                height: "39px",
                                                borderRadius: "9999px",
                                                backgroundColor: "#E8FAFF",
                                                color: "#0F4391",
                                                border: "1px solid #0F4391",
                                                marginBottom: "5px",
                                              }}
                                            >
                                              SAVE
                                            </Button>
                                          </Box>
                                        </Modal>

                                        <td
                                          className="tableborder"
                                          style={{
                                            width: "150px",
                                          }}
                                          onKeyDown={(e) => {
                                            e.key === "Enter" &&
                                              e.preventDefault();
                                          }}
                                        >
                                          {item.id ? (
                                            <>
                                              <Tooltip
                                                title={
                                                  encounterSystemStates &&
                                                  encounterSystemStates[i] &&
                                                  encounterSystemStates[i]
                                                    .isDefault === true
                                                    ? "you are not able to delete this encounterstate"
                                                    : ""
                                                }
                                              >
                                                <IconButton>
                                                  <img
                                                    className="h-8"
                                                    src={Delete}
                                                    onClick={() => {
                                                      encounterSystemStates &&
                                                        encounterSystemStates[
                                                          i
                                                        ] &&
                                                        encounterSystemStates[i]
                                                          .isDefault ===
                                                          false &&
                                                        handleDeleteRow(
                                                          i,
                                                          item.id
                                                        );
                                                    }}
                                                  />
                                                </IconButton>
                                              </Tooltip>
                                              <span
                                                className="ml-4"
                                                style={{
                                                  verticalAlign: "middle",
                                                }}
                                              >
                                                {item.isDisabled ||
                                                encounterCustomStates[i]
                                                  ?.isDisabled ? (
                                                  <CheckCircleIcon
                                                    color="success"
                                                    onClick={() =>
                                                      handleCheckboxChange(
                                                        false,
                                                        i,
                                                        item
                                                      )
                                                    }
                                                  />
                                                ) : (
                                                  <CheckCircleOutlineIcon
                                                    onClick={() =>
                                                      handleCheckboxChange(
                                                        true,
                                                        i,
                                                        item
                                                      )
                                                    }
                                                  />
                                                )}
                                              </span>
                                            </>
                                          ) : (
                                            <>
                                              <span
                                                className="ml-4"
                                                style={{
                                                  verticalAlign: "middle",
                                                }}
                                              >
                                                {item?.isDisabled ||
                                                encounterCustomStates[i]
                                                  ?.isDisabled ? (
                                                  <CheckCircleIcon
                                                    color="success"
                                                    onClick={() =>
                                                      handleCheckboxChange(
                                                        false,
                                                        i,
                                                        item
                                                      )
                                                    }
                                                  />
                                                ) : (
                                                  <CheckCircleOutlineIcon
                                                    onClick={() =>
                                                      handleCheckboxChange(
                                                        true,
                                                        i,
                                                        item
                                                      )
                                                    }
                                                  />
                                                )}
                                              </span>
                                              <span
                                                className="ml-4"
                                                style={{
                                                  verticalAlign: "middle",
                                                }}
                                              >
                                                <CloseIcon
                                                  onClick={() =>
                                                    handleCloseIconClick(
                                                      i,
                                                      item.id
                                                    )
                                                  }
                                                />
                                              </span>
                                            </>
                                          )}
                                        </td>
                                      </tr>
                                    );
                                  })}
                              </tbody>
                            </table>
                          </div>
                          {showCustomFiedlError ? (
                            <div
                              className="custom-field-error"
                              style={{
                                color: "#d32f2f",
                                marginLeft: "20px",
                                marginTop: "15px",
                                fontSize: "0.75rem",
                              }}
                            >
                              Required Custom and System States.
                            </div>
                          ) : null}

                          <Button
                            className="m-2"
                            onKeyDown={(e) => {
                              e.key === "Enter" && e.preventDefault();
                            }}
                            onClick={(e, v) => addRow("loc")}
                            variant="contained"
                            disableElevation
                            style={{
                              marginRight: "10px",
                              borderColor: "#0F4391",
                              width: "43px",
                              height: "22px",
                              borderRadius: "30px",
                              backgroundColor: "#E8FAFF",
                              color: "#0F4391",
                              border: "1px solid #0F4391",
                              margin: "14px",
                            }}
                            disabled={
                              encounterSystemStates?.filter(
                                (item, index) =>
                                  item.name === "" ||
                                  (encounterCustomStates &&
                                    encounterCustomStates[index] &&
                                    encounterCustomStates[index].value === "")
                              ).length
                            }
                          >
                            Add
                          </Button>
                          <div className="mt-5 pl-5 OrgNameContainer">
                            <h3 className="font-normal text-o-form-label-color">
                              Last Modified On
                            </h3>
                            <p className="last-modified-value m-r-f">
                            <TextField
                            disabled
                            variant="standard"
                            autoComplete="OFF"
                            name="encounterType"
                            value={rowItem.lastModifiedByName}
                            sx={{ width: 200 }}
                            id="standard-basic"
                            label=""
                          />
                          
                            </p>
                            <h3 className=" mt-5 font-normal text-o-form-label-color">
                            Last Modified Date & Time
                          </h3>
                            <p>
                            <TextField
                            disabled
                            variant="standard"
                            autoComplete="OFF"
                            name="encounterType"
                            value=  {new Date(
                              rowItem.lastModifiedTime
                            ).toLocaleString("en-US")}
                            sx={{ width: 200 }}
                            id="standard-basic"
                            label=""
                          />
                            
                            </p>
                          </div>
                          <div className="mt-5 pl-5 OrgNameContainer ">
                            <div className="mt-9 flex justify-center items-center  towButtonContainer h-40">
                              <Button
                                onClick={hideForm}
                                type="submit"
                                variant="contained"
                                disableElevation
                                style={{
                                  marginRight: "10px",
                                  borderColor: "#0F4391",
                                  width: "99px",
                                  height: "39px",
                                  borderRadius: "9999px",
                                  backgroundColor: "#E8FAFF",
                                  color: "#0F4391",

                                  border: "1px solid #0F4391",
                                  marginBottom: "100px",
                                }}
                              >
                                CANCEL
                              </Button>
                              <Button
                                onClick={refreshForm}
                                variant="contained"
                                disableElevation
                                style={{
                                  borderColor: "#0F4391",
                                  width: "99px",
                                  height: "39px",
                                  borderRadius: "9999px",
                                  backgroundColor: "#E8FAFF",
                                  color: "#0F4391",
                                  border: "1px solid #0F4391",
                                  marginBottom: "100px",
                                }}
                              >
                                SAVE
                              </Button>
                            </div>
                          </div>
                        </form>
                      </>
                    </>
                  </div>
                </div>
              </Grid>
            ) : null}
          </Grid>
        </div>
      )}
      <AdminCookieConsentBanner />
    </div>
  );
};
export default EncounterTypes;
