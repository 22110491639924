import React, { useEffect, useState } from "react";
import {
  Select,
  FormControl,
  MenuItem,
  Button,
  Autocomplete,
  TextField,
} from "@mui/material";
import AdminHome from "../AdminHome";
import axios from 'axios';
import "./patientImport.css";
import { useGetTemplateUrlListMutation, useCreateValidateAndImportMutation} from "../../../services/adminLogIn";
import { getTokensFromStorage } from "../../../globalFunctions/getLocalStorageData";
import { useNavigate } from "react-router-dom";
import AdminCookieConsentBanner from "../home/AdminCookieConsentBanner";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const PatientImport = (props) => {
  const [expand, setexpand] = useState({
    heading: "",
    icon: "",
    isSubListExpanded: false,
  });
  const [expand1, setexpand1] = useState({
    empty: true,
  });
  const navigate = useNavigate();
  const bulkImportsData = {
    selectedRecordType: "Member Registration",
  };
  const recordTypeOptions = ["Member Registration"];

  const [bulkImportsFormData, setBulkImportsData] = useState(bulkImportsData);
  const [getTemplateUrlListData] = useGetTemplateUrlListMutation();
  const [createValidateAndImport] = useCreateValidateAndImportMutation();
  const [bulkImportsError, setBulkImportsError] = useState({ isReportType: false, isFileType: false });
  const [inputFile, setInputFile] = useState();

  const handleOnChange = (field, event) => {
    if (field === "type") {
      setBulkImportsData((prev) => ({
        ...prev,
        selectedRecordType: event,
      }));
      setBulkImportsError((prev) => ({ ...prev, isReportType: false }));
    }
  };
  const [disableButton, setdisableButton] = useState(false);
  const handleChange = (event) => {
    setInputFile(event.target.files[0]);
    setBulkImportsError((prev) => ({ ...prev, isFileError: false }));
  };
  const handleDownload = async (url) => {
    const res = await getTemplateUrlListData();
    const element = document.createElement("a");
    element.href = res.error.data;
    element.click();
  };

  const callValidateAndImport = async (response) => {
    const payLoad =  {
      "name": bulkImportsFormData.selectedRecordType,
      "destination": response.data.destination,
      "filename": response.data.filename
     }
    const res = await createValidateAndImport(payLoad);
    navigate("/dashboard/patientstatuslist", { replace: true });
    setdisableButton(false)
  }

  const validateAndImport = async () => {
    setdisableButton(true)
    const tok = getTokensFromStorage();
    if (bulkImportsFormData.selectedRecordType === "" || bulkImportsFormData.selectedRecordType === null || bulkImportsFormData.selectedRecordType === undefined) {
      setBulkImportsError((prev) => ({ ...prev, isReportType: true }));
    }
    if (inputFile === null || inputFile === undefined) {
      setBulkImportsError((prev) => ({ ...prev, isFileError: true }));
    }
    if (bulkImportsFormData.selectedRecordType && inputFile) {
      const url = process.env.REACT_APP_API_URL + 'bulkUpload/upload';
      const formData = new FormData();
      formData.append('file', inputFile);
      formData.append('fileName', inputFile.name);
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
          "Authorization": "Bearer " + tok[0]
        },
      };
      axios.post(url, formData, config).then((response) => {
        callValidateAndImport(response);
        // navigate("/dashboard/patientstatuslist", { replace: true });
      });
    }
    // if (bulkImportsFormData.selectedRecordType && inputFile) {
     
    //   const payLoad =  {
    //     "name": bulkImportsFormData.selectedRecordType,
    //     "destination": response.data.destination,
    //     "filename": response.data.filename
    //    }
      
    //   // const res = await createValidateAndImport(inputFile );
    // }
  }

  useEffect(() => { }, []);
  return (
    <div className="create-package">
        <div
          className={props.expand1.empty === true ? "main1" : "main"}
          style={{ backgroundColor: "white" }}
        >
          <div className="breadCumContainer flex justify-start items-center h-9 w-full bg-o-color-one organ1">
            <ol className="ml-8 flex " style={{ listStyleType: "none" }}>
              <div className="flex mt-1">
                <li className="w-28 h-thirtyPix rounded-mysix flex justify-center items-center font-medium bg-white m-r-f orgbox">
                  <span>Patient Import</span>
                </li>
              </div>
              <i className="right"></i>
              <div className="flex mt-1">
                <li className="w-28 h-thirtyPix rounded-mysix flex justify-center items-center font-medium bg-white m-r-f  rows breadback">
                  <span>Create New Records</span>
                </li>
              </div>
            </ol>
          </div>

          <div className="bulk-imports-header m-r-f">
            <h1>Bulk Import Records</h1>
          </div>
          <div className="bulk-imports-form">
            <form>
              <div className="form-field-container">
                <h3 className="m-r-f">Select Records Type</h3>
                <Autocomplete
                  value={bulkImportsFormData && bulkImportsFormData.selectedRecordType}
                  autoHighlight={true}
                  onKeyDown={(e) => {
                    e.key === "Enter" && e.preventDefault();
                  }}
                  onChange={(e, v) => handleOnChange("type", v)}
                  getOptionLabel={(option) => (option ? option : "")}
                  id="loc-list-option"
                  options={recordTypeOptions ? recordTypeOptions : []}
                  sx={{ width: 300, marginLeft: "20px" }}
                  renderInput={(params) => (
                    <TextField
                      data-testid="salutation"
                      sx={{ width: 358 }}
                      {...params}
                      error={
                        bulkImportsError.isReportType ===
                        true
                      }
                      helperText={
                        bulkImportsError.isReportType
                          ? "Report type is required!"
                          : ""
                      }
                    />
                  )}
                />
              </div>
              <div className="form-field-container">
                <h3 className="m-r-f">Input Records File</h3>
                <input
                  type="file"

                  className={`input-file-field ${bulkImportsError.isFileError ? 'input-file-field-error' : ''}`}
                  onChange={handleChange}
                  accept=".xlsx,.xls"
                />
              </div>
              <div className={bulkImportsError.isFileError ? 'file-error' : 'file-text'}>Please choose a file</div>
              <div className="download-type-container m-r-f">
                <span onClick={handleDownload} style={{ cursor: "pointer" }} >
                  Download template file
                </span>
              </div>

              <div className="form-field-container button-container">
                <Button
                  variant="contained"
                  disableElevation
                  style={{
                    marginRight: "10px",
                    marginLeft: "30px",
                    borderColor: "#0F4391",
                    height: "39px",
                    borderRadius: "9999px",
                    backgroundColor: "#E8FAFF",
                    color: "#0F4391",
                    border: "1px solid #0F4391",
                    marginBottom: "100px",
                  }}
                  disabled={disableButton}
                  onClick={validateAndImport}
                >
                  Validate and Import
                </Button>

                <Button
                  type="submit"
                  variant="contained"
                  disableElevation
                  style={{
                    borderColor: "#0F4391",
                    width: "99px",
                    height: "39px",
                    borderRadius: "9999px",
                    backgroundColor: "#E8FAFF",
                    color: "#0F4391",
                    border: "1px solid #0F4391",
                    marginBottom: "100px",
                  }}
                >
                  CANCEL
                </Button>
              </div>
            </form>
          </div>
        </div>
        <AdminCookieConsentBanner />
    </div>
  );
};

export default PatientImport;
