// import { LocalConvenienceStoreOutlined } from "@mui/icons-material";
import { useState } from "react";
// import isEmail from 'validator/es/lib/isEmail';
// import isMobilePhone from 'validator/es/lib/isMobilePhone';
import { useCreatingOrganizationMutation, useCreatingSampleMutation, useUpdatigsampleMutation } from "../../../services/adminLogIn";

const Sample = () => {

    const [createSampleFunc, createBodyRes] = useCreatingSampleMutation();
    const [updatesampleFunc, updatesampleRes] = useUpdatigsampleMutation();
    const [showerror, setErrorMessage] = useState(false);

    const [orgState, setOrgState] = useState();

    const [bodyAllTextFieldState, setbodyTextFieldState] = useState({
        is_update: false,
        sampleTypeId: '',
        sampleTypeName: '',
        sampleClassificationId: '',
        description: '',
        quantity: '',
        status: ''

    });
    const [open, setOpen] = useState(false);
    const [openFilterModal, setOpenFilterModal] = useState(false);
    const [isSearchEvent, setIsSearchEvent] = useState(false);
    const [status, setStatus] = useState("");
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleFilterModalOpen = () => { setOpenFilterModal(true); }
    const handleFilterModalClose = () => { setOpenFilterModal(false); }

    const [filterData, setFilterData] = useState({ classificationId: "", sampleName: "", filterStatus: "" });


    const [dropsampleidState, setDropsampleidState] = useState({
        sampleClassificationId: '', sampleClassificationName: ''
    });

    const [isOrgError, setError] = useState({
        isNameError: '', issampleError: ''
    });

    // dropdown handler 
    const handleDropDownChange = (e, v) => {
        const value = v;
        if (e === "sampleClassificationId") {

            setDropsampleidState(value)

        }
        if (e === "status") {
            handleOpen()
        }
    }

    const halndleClickonProceed = () => {
        setbodyTextFieldState((prev) => ({
            ...prev,
            ["status"]: bodyAllTextFieldState.status === "ACTIVE" ? "INACTIVE" : "ACTIVE"

        }))
        setStatus(bodyAllTextFieldState.status === "ACTIVE" ? "DELETED" : "ACTIVE")
        handleClose()
    }
    const getDistrictValFun1 = (value) => {
        //   getDistrictValFun(value)
    }

    const resetErrorHandler = (e) => {
        const name = e.target.name;
        switch (name) {
            case "sampleTypeName":
                setError((prev) => ({
                    ...prev,
                    isNameError: isOrgError.isNameError = false
                }))
                break;

            case "sampleClassificationId":
                setError((prev) => ({
                    ...prev,
                    issampleError: isOrgError.issampleError = false
                }))
                break;
        }
    }

    // form on submit handleer 
    const bodySubmitHandler = (e) => {
        e.preventDefault();
        if (bodyAllTextFieldState.sampleTypeName === "") {
            setError((prev) => ({
                ...prev,
                isNameError: isOrgError.isNameError = true,
                // issampleError:isOrgError.issampleError = true
            }))

        } else if (dropsampleidState.sampleClassificationId == '') {
            setError((prev) => ({
                ...prev,
                isNameError: isOrgError.isNameError = false,
                issampleError: isOrgError.issampleError = true,
            }))

        }
        else {

            setError((prev) => ({
                ...prev,
                isNameError: isOrgError.isNameError = false,

                issampleError: isOrgError.issampleError = false,


            }))

            const sendOrgDetails = {
                // sampleTypeId: bodyAllTextFieldState.sampleTypeId,
                // SampleTypeMasterId: bodyAllTextFieldState.is_update === false ? "SAM-" + bodyAllTextFieldState.sampleTypeName.split(' ').join('-').toUpperCase().replace(/[^a-zA-Z0-9 ]/g, '-') : bodyAllTextFieldState.sampleTypeName,
                sampleTypeName: bodyAllTextFieldState.sampleTypeName,
                sampleClassificationId: dropsampleidState.sampleClassificationId,
                description: bodyAllTextFieldState.description,
                quantity: bodyAllTextFieldState.quantity,
                icon: 'icon',
                "color": "Red",
                "status": bodyAllTextFieldState.status === "INACTIVE" ? "DELETED" : "ACTIVE",
                "extension": {
                    "": ""
                }
            }

            if (bodyAllTextFieldState.is_update === false) {
                createSampleFunc(sendOrgDetails)
            }
            else {
                sendOrgDetails.sampleTypeId = bodyAllTextFieldState.sampleTypeId
                updatesampleFunc(sendOrgDetails)
            }
            setErrorMessage(true)
        }

    }
    const whenInputsChange = (e) => {
        const { name, value } = e.target;
        if (name === "sampleTypeName") {
            setbodyTextFieldState((prev) => ({
                ...prev,
                [name]: value

            }))
        }
        else if (name === "sampleClassificationId") {
            setbodyTextFieldState((prev) => ({
                ...prev,
                [name]: value.replace(/[^a-z]/gi, "")

            }))
        }
        else if (name === "description") {
            if (value.length <= 250) {
                setbodyTextFieldState((prev) => ({
                    ...prev,
                    [name]: value

                }))
            }
        }
        else if (name === "quantity") {
            setbodyTextFieldState((prev) => ({
                ...prev,
                [name]: value.replace(/[^0-9]/gi, "")

            }))

        }

        else {
            setbodyTextFieldState((prev) => ({
                ...prev,
                [name]: value

            }))
        }

    }

    const handleFilterInputChange = (e) => {
        setFilterData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    }

    const handleFilterValueChange = (name, value) => {
        setFilterData((prev) => ({ ...prev, [name]: value }));
    }

    const resetFilterData = () => {
        setFilterData({ classificationId: "", sampleName: "", filterStatus: "" });
    }
    // exporting all handler and state and etc
    const exportAll = {
        bodySubmitHandler,
        whenInputsChange,
        handleDropDownChange,
        orgState,
        //    dropsampleState,
        bodyAllTextFieldState,
        isOrgError,
        createBodyRes,
        updatesampleRes,
        resetErrorHandler,
        setbodyTextFieldState,
        //    setDropsampleState,
        getDistrictValFun1,
        dropsampleidState,
        setDropsampleidState,
        open,
        handleClose,
        handleOpen,
        setOpen,
        halndleClickonProceed,
        setErrorMessage,
        handleFilterModalOpen,
        handleFilterModalClose,
        openFilterModal,
        showerror,
        filterData,
        isSearchEvent,
        resetFilterData,
        handleFilterInputChange,
        handleFilterValueChange,
        setIsSearchEvent,
        //    districtlist

    }

    return exportAll;
}

export default Sample;
