import React, { useEffect, useState } from "react";
import AdminHome from "../AdminHome";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import DeleteIcon from "@mui/icons-material/Delete";
import filter from "../../../images/filter.png";
import CircularProgress from "@mui/material/CircularProgress";
import "../Organization/Mainhome.css";
import { Grid, Button } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import InputAdornment from "@mui/material/InputAdornment";
import useSamplelogic from "./samplereqlogic";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux";
import Modal from "@mui/material/Modal";
import "./sample.css";
import AdminCookieConsentBanner from "../home/AdminCookieConsentBanner";

import { LoggerFunction } from "../../../logger/logger";
import {
  useGetActiveLocationValueMutation,
  useGetActiveOrgValueMutation,
  useGetsampleclssListMutation,
  useGetsamplematchingListMutation,
  useGetservicereqlistMutation,
  useCreateservicerequestMutation,
  useUpdatingservicerequestMutation,
  useGetservicereqSearchlistMutation,
  useGetpatientpaginationMutation,
  useFilterServiceReqDataMutation,
  useFilterLocationDataMutation,
  useFilterOrganizationDataMutation,
} from "../../../services/adminLogIn";
import ServiceReqList from "./samplereqlist";
import { red } from "@mui/material/colors";
import Breadcrums from "../../global/breadcrums/Breadcrums";
import { toasterFunction } from "../../../globalFunctions/getLocalStorageData";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  height: 360,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const ServiceRequest = (props) => {
  const [expand, setexpand] = useState({
    heading: "",
    icon: "",
    isSubListExpanded: false,
  });
  const [expand1, setexpand1] = useState({
    empty: true,
  });
  const [isFormVisible, setFormVisible] = useState({
    isOpen: false,
    isClose: true,
  });

  const [servicepageList, setservicepageList] = useState([]);
  const [access, setAccess] = useState(
    JSON.parse(sessionStorage.getItem("access"))
  );
  const [serviceImport, setserviceImport] = useState();
  const [isSearchFilter, setIsSearchFilter] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const getservicepageList = async (page) => {
    seIsNewData(false);
    setCurrentPage(page);
    if (isSearchFilter) {
      handleFilterData(page - 1);
    } else {
      let payload = {};
      payload.string = searchValue ? searchValue : "";
      payload.page = page - 1;
      setPaginationData({ ...payload });
      geServicereqList(payload);
    }
  };
  const [getFilterList] = useFilterLocationDataMutation();
  const [getFilterOrgList] = useFilterOrganizationDataMutation();
  const [getActiveValFun] = useGetActiveLocationValueMutation();
  const [getsamplelistfunc] = useGetsampleclssListMutation();
  const [getServiceReqList] = useGetservicereqlistMutation();
  const [getSampleListFun] = useGetsamplematchingListMutation();
  const [getOrganizationValFun] = useGetActiveOrgValueMutation();
  const [createServceRequest] = useCreateservicerequestMutation();
  const [updateServiceRequest] = useUpdatingservicerequestMutation();
  const [getServiceSearchList] = useGetservicereqSearchlistMutation();
  const [getpatientpaginationfunc] = useGetpatientpaginationMutation();
  const [filterServicReqData] = useFilterServiceReqDataMutation();
  const [locPriceData, setLocPriceData] = useState();
  const [locOrgData, setLocOrgData] = useState();
  const [simpleClassList, setSimpleClassList] = useState([]);
  const [sampleList, setSampleList] = useState([]);
  const [serviceReqList, setServicereq] = useState([]);
  const [locationMultiSelectOptions, setLocationMultiSelectOptions] = useState(
    []
  );
  const [orgDataOptions, setOrgDataOptions] = useState([]);
  const [errorDisplay, setErrorDisplay] = useState("");
  const packageStatusList = ["ACTIVE", "INACTIVE"];
  const serviceReqCatList = ["Laboratory", "Radiology", "Procedure"];
  const modalityList = ["USG", "MRI", "X-RAY", "CT", "PET CT", "MRA"];
  const lateralityList = [
    "Unilateral",
    "Unilateral Left",
    "Unilateral Right",
    "Bilateral",
  ];

  const [id, setId] = useState("");
  const [totalPage, setTotalPage] = useState(1);
  const [calculateData, setTotalPageCalculate] = useState({
    number: "",
    numberOfElements: "",
    totalElements: "",
    showcount: "",
  });
  const [isNewData, seIsNewData] = useState(true);
  const [paginationdata, setPaginationData] = useState({
    string: "",
    page: 0,
  });
  const [sampleReqRecordsLoading, setSampleReqRecordsLoading] = useState(true);
  const [searchValue, setSearchValue] = useState();
  const [filterResults, setFilterResults] = useState();
  const filterStatusOptions = ["ACTIVE", "INACTIVE"];

  const {
    serviceFormFields,
    createServiceError,
    openFilterModal,
    locpriceList,
    locMasterValues,
    price,
    handleFilterModalOpen,
    handleFilterModalClose,
    handleInputChange,
    resetErrorHandler,
    addRow,
    handleDropDownChange,
    deleteRow,
    handleDropDownPriceLoc,
    getPackageTransformedData,
    procedureCode,
    bodysiteCode,
    setServiceFormFields,
    serviceFormdata,
    setErrorMessage,
    handleInputChangeProc,
    setBodysiteCode,
    setProcedureCode,
    setLocPriceList,
    showerror,
    handleOpen,
    handleClose,
    handleProceed,
    statusChange,
    setStatusChange,
    open,
    setCreatePackageError,
    spacimenname,
    setSpecimenName,
    filterData,
    isSearchEvent,
    resetFilterData,
    handleFilterInputChange,
    handleFilterValueChange,
    setIsSearchEvent,
  } = useSamplelogic();

  const showFormActions = () => {
    if (access?.servicerequestadd == true) {
      setErrorMessage(false);
      setCreatePackageError((prev) => ({
        ...prev,
        isNameError: (createServiceError.isNameError = false),
        isServiceRequestCategoryId:
          (createServiceError.isServiceRequestCategoryId = false),
        isSpecimen: (createServiceError.isSpecimen = false),
        isSupportingOrg: (createServiceError.isSupportingOrg = false),
        isSupportingLocations:
          (createServiceError.isSupportingLocations = false),
      }));
      if (!isFormVisible.isOpen) {
        setFormVisible((prev) => ({
          ...prev,
          isOpen: (isFormVisible.isOpen = true),
          isFormVisible,
          isClose: (isFormVisible.isClose = false),
        }));
      }
    } else {
      toasterFunction(" No Permission", "error");
    }
  };

  const hideForm = () => {
    if (isFormVisible.isOpen) {
      setSearchValue("");
      setFormVisible((prev) => ({
        ...prev,
        isOpen: (isFormVisible.isOpen = false),
        isFormVisible,
        isClose: (isFormVisible.isClose = true),
      }));
      setServiceFormFields({
        is_update: false,
        name: "",
        serviceRequestMasterId: "",
        description: "",
        specimen: "",
        subject: "",
        serviceRequestCategoryId: "",
        supportinglocations: [],
        instructionToPerformer: [],
        supportingLocationsForTable: [],
        specialInstructionsToPerformer: "",
        reasonCode: "",
        reasonDescription: "",
        quantity: {
          value: "",
          comparator: "<",
          unit: "ml",
        },
        supportingOrganisations: [],
        laterality: [],
        status: "ACTIVE",
      });
      setBodysiteCode([]);
      setProcedureCode([]);
      setLocPriceList([]);
      setSpecimenName({});
      setId("");
      setStatusChange(false);
      setCreatePackageError((prev) => ({
        ...prev,
        isNameError: (createServiceError.isNameError = false),
        isServiceRequestCategoryId:
          (createServiceError.isServiceRequestCategoryId = false),
        isSpecimen: (createServiceError.isSpecimen = false),
        isSupportingOrg: (createServiceError.isSupportingOrg = false),
        isSupportingLocations:
          (createServiceError.isSupportingLocations = false),
        locationAndPriceingError:
          (createServiceError.locationAndPriceingError = false),
      }));
    }
  };

  const searchsampleevent = (value) => {
    setSearchValue(value);
    geServiceSearchreqList(value);
    seIsNewData(true);
    setIsSearchFilter(false);
  };

  const updateLoactionOptions = (data, optionItem) => {
    if (data.length) {
      const optionArr = data.map((item) => item);
      switch (optionItem) {
        case "location": {
          setLocationMultiSelectOptions(optionArr);
        }
        case "org": {
          setOrgDataOptions(optionArr);
        }
      }
    }
  };

  const handleFilterCancel = () => {
    handleFilterData(0);
    handleFilterModalClose();
  };

  const handleFilterData = async (page) => {
    if (page === 0) {
      seIsNewData(true);
    }
    setIsSearchFilter(true);
    const reqBody = {
      serviceRequestMasterName: filterData.serReqName,
      serviceRequestCategoryId: filterData.serReqCategory,
      modality: filterData.modality,
      laterality: filterData.laterality,
      supportingOrganisationId: filterData?.supportingOrganization?.id,
      supportinglocationId: filterData?.supportingLoc?.id,
      status:
        filterData.filterStatus === "INACTIVE"
          ? "SUSPENDED"
          : filterData.filterStatus === "ACTIVE"
          ? "ACTIVE"
          : "",
      page: page ? page : 0,
    };
    const res = await filterServicReqData(reqBody);
    if (res?.data?.content) {
      setTotalPage(res.data.totalPages);
      setTotalPageCalculate({
        numberOfElements: res?.data?.numberOfElements,
        number: res?.data?.number,
        totalElements: res?.data?.totalElements,
      });
      setServicereq(res.data.content);
    } else {
      setServicereq([]);
    }
    handleFilterModalClose();
  };

  const geServicereqData = async () => {
      var locRes = await getActiveValFun(0);
      var locationsList = [];
      locationsList = locationsList.concat(...locRes['data']['content']);
      for(var i=1; i<locRes.data.totalPages;i++){
         const tempLocList = await getActiveValFun(i);
         locationsList = locationsList.concat(...tempLocList['data']['content']);
      };
      if (locationsList) {
        updateLoactionOptions(locationsList, "location");
        var log = await LoggerFunction();
        if (locationsList)
          log.info({
            data: "Data get successfully",
            message: locationsList,
          });
        if (locRes?.error) log.error(locRes?.error);
        setLocPriceData(locationsList);
        setSampleReqRecordsLoading(false);
      }
      var orgList =[];
      const orgRes = await getOrganizationValFun(0);
      orgList = orgList.concat(...orgRes['data']['content']);
      for(var i=1; i<orgRes.data.totalPages;i++){
             const tempOrg = await getOrganizationValFun(i);
             orgList = orgList.concat(...tempOrg['data']['content']);
          };
      if (orgList) {
        updateLoactionOptions(orgList, "org");
        var log = await LoggerFunction();
        if (orgList)
          log.info({
            data: "Data get successfully",
            message: orgList,
          });

        if (orgRes?.error) log.error(orgRes?.error);
        setLocOrgData(orgList);
        setSampleReqRecordsLoading(true);
      }
      const sampleClassListRes = await getsamplelistfunc();
      if (sampleClassListRes?.data?.content) {
        setSimpleClassList(sampleClassListRes?.data?.content);
        var log = await LoggerFunction();
        if (sampleClassListRes?.data?.content)
          log.info({
            data: "Data get successfully",
            message: sampleClassListRes.data.content,
          });
        if (sampleClassListRes?.error) log.error(sampleClassListRes?.error);
      }
      const sampleres = await getSampleListFun(paginationdata);
      if (sampleres?.data?.content) {
        setSampleList(sampleres?.data?.content);

        setSampleReqRecordsLoading(false);
      }
    };

  const geServicereqList = async (serachStr) => {
    let data = {};
    data.status = "";
    data.serachStr = serachStr;
    const servicereqList = await getServiceSearchList(serachStr);
    if (servicereqList?.data?.content) {
      setServicereq([...servicereqList.data.content]);
      setTotalPage(servicereqList?.data?.totalPages);
      setTotalPageCalculate({
        numberOfElements: servicereqList?.data?.numberOfElements,
        number: servicereqList?.data?.number,
        totalElements: servicereqList?.data?.totalElements,
      });
    }
  };
  const geServiceSearchreqList = async (serachStr) => {
    let data = {};
    data.status = "";
    data.string = serachStr;
    data.page = 0;
    const servicereqList = await getServiceSearchList(data);
    if (servicereqList?.data?.content) {
      setServicereq([...servicereqList.data.content]);
      setTotalPage(servicereqList?.data?.totalPages);
      setTotalPageCalculate({
        numberOfElements: servicereqList?.data?.numberOfElements,
        number: servicereqList?.data?.number,
        totalElements: servicereqList?.data?.totalElements,
      });
    } else {
      setServicereq([]);
    }
  };

  const submitCreatePackageForm = async () => {
    const sendData = getPackageTransformedData();
    let data = sendData;
    data.statusChange = statusChange;
    data.specimen = spacimenname.sampleTypeId;
    data.supportinglocations = data.supportinglocations.map((item) => item.id);
    data.supportingOrganisations = data.supportingOrganisations.map(
      (item) => item.id
    );
    data.status = sendData.status === "ACTIVE" ? "ACTIVE" : "SUSPENDED";
    if (serviceFormFields.name === "") {
      setCreatePackageError((prev) => ({
        ...prev,
        isNameError: (createServiceError.isNameError = true),
      }));
    }
    if (
      serviceFormFields.serviceRequestCategoryId === "" ||
      serviceFormFields.serviceRequestCategoryId === null
    ) {
      setCreatePackageError((prev) => ({
        ...prev,
        isServiceRequestCategoryId:
          (createServiceError.isServiceRequestCategoryId = true),
      }));
    }
    if (
      serviceFormFields.serviceRequestCategoryId === "Laboratory" &&
      (serviceFormFields.specimen === "" || serviceFormFields.specimen === null)
    ) {
      setCreatePackageError((prev) => ({
        ...prev,
        isSpecimen: (createServiceError.isSpecimen = true),
      }));
    }
    if (serviceFormFields.supportingOrganisations.length === 0) {
      setCreatePackageError((prev) => ({
        ...prev,
        isSupportingOrg: (createServiceError.isSupportingOrg = true),
      }));
    }
    if (serviceFormFields.supportinglocations.length === 0) {
      setCreatePackageError((prev) => ({
        ...prev,
        isSupportingLocations:
          (createServiceError.isSupportingLocations = true),
      }));
    }
    if (
      sendData.locationSpecificDetails.length === 0 ||
      sendData.locationSpecificDetails.some(
        (item) => !item.locationId || item.amount === ""
      )
    ) {
      setCreatePackageError((prev) => ({
        ...prev,
        locationAndPriceingError:
          (createServiceError.locationAndPriceingError = true),
      }));
    } else if (
      !createServiceError.isNameError &&
      !createServiceError.isServiceRequestCategoryId &&
      !createServiceError.isSpecimen &&
      !createServiceError.isSupportingOrg &&
      !createServiceError.isSupportingLocations &&
      !createServiceError.locationAndPriceingError
    ) {
      setCreatePackageError((prev) => ({
        ...prev,
        isNameError: (createServiceError.isNameError = false),
        isServiceRequestCategoryId:
          (createServiceError.isServiceRequestCategoryId = false),
        isSpecimen: (createServiceError.isSpecimen = false),
        isSupportingLocations:
          (createServiceError.isSupportingLocations = false),
        isSupportingOrg: (createServiceError.isSupportingOrg = false),
        locationAndPriceingError:
          (createServiceError.locationAndPriceingError = false),
      }));
      if (serviceFormFields.is_update === false) {
        const res = await createServceRequest(sendData);
        if (res?.data?.serviceRequestMasterId) {
          // setSampleList([])
          geServicereqList(paginationdata);
          // geServicereqData()
          hideForm();
        } else {
          setErrorDisplay(res?.error?.data?.message);
          setErrorMessage(true);
        }
      } else {
        const res = await updateServiceRequest(sendData);
        if (res?.data?.serviceRequestMasterId) {
          // setSampleList([])
          geServicereqList(paginationdata);
          // geServicereqData()
          hideForm();
        } else {
          setErrorDisplay(res?.error?.data?.message);
          setErrorMessage(true);
        }
      }
    }
  };
  const callListDropdownDataOrg = async (value) => {
    const payload = {
      organizationName: value,
      partOf: "",
      managingOrg: "",
      state: "",
      city: "",
      status: "",
      page: 0,
      size: 100,
    };
    const orgRes = await getFilterOrgList(payload);
    if (orgRes?.data?.content) {
      updateLoactionOptions(orgRes.data.content, "org");
      setLocOrgData(orgRes?.data?.content);
    }
  };
  const handleDropDownSearchOrg = (e, value) => {
    callListDropdownDataOrg(value);
  };

  const callListDropdownDataLoc = async (value) => {
    let payload = {
      locName: value,
      partOf: "",
      managingOrg: "",
      state: "",
      city: "",
      status: "",
      page: 0,
      size: 100,
    };

    const locRes = await getFilterList(payload);
    if (locRes?.data?.content) {
      updateLoactionOptions(locRes.data.content, "location");
      setLocPriceData(locRes.data.content);
      setSampleReqRecordsLoading(false);
    }
  };
  const handleDropDownSearchLoc = (e, value) => {
    callListDropdownDataLoc(value);
  };

  useEffect(() => {
    geServicereqList(paginationdata);
    geServicereqData();
    getSampleListFun(paginationdata);
  }, []);
  return (
    <div className="create-package">
      {sampleReqRecordsLoading ? (
        <div style={{ position: "fixed", left: "50%", top: "50%" }}>
          <CircularProgress />
        </div>
      ) : (
        <div className={props.expand1.empty === true ? "main1" : "main"}>
          <Breadcrums
            firsttab={"Categories"}
            secondtab="Services"
            thirdtab="Service Request"
          ></Breadcrums>
          <ToastContainer />
          <Grid container mt={-2}>
            <Grid item xs={6}>
              <div
                className={
                  isFormVisible.isOpen
                    ? "leftcontainer rounded-lg"
                    : "leftcontainer1 rounded-lg"
                }
              >
                <Grid className="">
                  <Grid className=" p-7 ">
                    <TextField
                      label=""
                      onChange={(e) => searchsampleevent(e.target.value)}
                      onKeyDown={(e) => {
                        e.key === "Enter" && e.preventDefault();
                      }}
                      sx={{ m: 1, width: "25ch" }}
                      value={searchValue}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchOutlinedIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>

                  <Grid className="editContainer rounded-radiusFourPix ">
                    <Grid
                      disabled={access?.servicerequestadd == false}
                      onClick={showFormActions}
                      className={
                        isFormVisible.isOpen ? "addIconchange" : "addIcon"
                      }
                    >
                      <span className=" p-2 m-r-f ">Add</span>
                      <AddCircleOutlineIcon
                        style={{
                          fontSize: "14px",
                          color: "#545454",
                          marginBottom: "3px",
                          color: `${
                            isFormVisible.isOpen ? "#ffffff" : "#545454"
                          }`,
                        }}
                      />
                    </Grid>

                    {/* <Grid className="Addaction">
                      <span className="p-2 m-r-f">Actions</span>
                      <AddCircleOutlineIcon
                        style={{
                          fontSize: "14px",
                          color: "#545454",
                          marginBottom: "3px",
                        }}
                      />
                    </Grid> */}

                    <Grid onClick={handleFilterModalOpen} className="addIcon">
                      <span className="p-2 m-r-f">Filter</span>
                      <img
                        src={filter}
                        alt="filter.png"
                        style={{
                          position: "absolute",
                          left: "90%",
                          right: "20px",
                          top: "15px",
                          bottom: "95px",
                          width: "11px",
                          paddingTop: "3px",
                        }}
                      ></img>
                    </Grid>
                    <Modal
                      open={openFilterModal}
                      // onClose={handleClose}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box
                        sx={style}
                        style={{
                          width: "450px",
                          padding: "50px",
                          height: "auto",
                          borderColor: "white",
                        }}
                      >
                        <Typography
                          className="filtermain"
                          id="modal-modal-title"
                          variant="h6"
                          component="h2"
                          style={{
                            fontFamily: "MontserratRegular",
                            marginTop: "5px",
                          }}
                        >
                          Filter by
                        </Typography>

                        <div className="OrgNameContainer">
                          <h3
                            className="font-normal text-o-form-label-color"
                            id="Org"
                          >
                            Service Request Name
                          </h3>
                          <TextField
                            onFocus={(e) => resetErrorHandler(e)}
                            autoComplete="OFF"
                            onChange={(e) => handleFilterInputChange(e)}
                            name="serReqName"
                            value={filterData.serReqName}
                            style={{ width: "360px" }}
                            id="standard-basic"
                            label=""
                            variant="standard"
                          />
                        </div>

                        <div className="mt-5 OrgNameContainer ">
                          <h3 className="font-normal text-o-form-label-color">
                            Service Request Category
                          </h3>
                          <Autocomplete
                            value={filterData.serReqCategory}
                            name="serReqCategory"
                            autoHighlight={true}
                            onChange={(e, v) =>
                              handleFilterValueChange("serReqCategory", v)
                            }
                            getOptionLabel={(option) => (option ? option : "")}
                            id="org-list-option"
                            options={serviceReqCatList ? serviceReqCatList : []}
                            sx={{ width: 360 }}
                            renderInput={(params) => (
                              <TextField
                                variant="standard"
                                onFocus={(e) => resetErrorHandler(e)}
                                autoComplete="OFF"
                                {...params}
                                label=""
                              />
                            )}
                          />
                        </div>
                        {/* <div className="mt-5 OrgNameContainer ">
                          <h3 className="font-normal text-o-form-label-color">
                            Sample
                          </h3>
                          <Autocomplete
                            value={filterData.sample}
                            autoHighlight={true}
                            onChange={(e, v) =>
                              handleDropDownPriceLoc("sample", v)
                            }
                            getOptionLabel={(option) =>
                              option.sampleTypeName ? option.sampleTypeName : ""
                            }
                            id="org-list-option"
                            options={sampleList ? sampleList : []}
                            sx={{ width: 360 }}
                            renderInput={(params) => (
                              <TextField
                                variant="standard"
                                onFocus={(e) => resetErrorHandler(e)}
                                autoComplete="OFF"

                                {...params}
                                label=""
                              />
                            )}
                          />
                        </div> */}
                        <div className="mt-5 OrgNameContainer ">
                          <h3 className="font-normal text-o-form-label-color">
                            Modality
                          </h3>
                          <Autocomplete
                            value={filterData.modality}
                            autoHighlight={true}
                            onChange={(e, v) =>
                              handleFilterValueChange("modality", v)
                            }
                            getOptionLabel={(option) => (option ? option : "")}
                            id="org-list-option"
                            options={modalityList ? modalityList : []}
                            sx={{ width: 360 }}
                            renderInput={(params) => (
                              <TextField
                                variant="standard"
                                onFocus={(e) => resetErrorHandler(e)}
                                autoComplete="OFF"
                                {...params}
                                label=""
                              />
                            )}
                          />
                        </div>
                        <div className="mt-5 OrgNameContainer ">
                          <h3 className="font-normal text-o-form-label-color">
                            Laterality
                          </h3>
                          <Autocomplete
                            value={filterData.laterality}
                            autoHighlight={true}
                            onChange={(e, v) =>
                              handleFilterValueChange("laterality", v)
                            }
                            getOptionLabel={(option) => (option ? option : "")}
                            id="org-list-option"
                            options={lateralityList ? lateralityList : []}
                            sx={{ width: "360px" }}
                            renderInput={(params) => (
                              <TextField
                                variant="standard"
                                onFocus={(e) => resetErrorHandler(e)}
                                autoComplete="OFF"
                                {...params}
                                label=""
                              />
                            )}
                          />
                        </div>

                        <div className="mt-5 OrgNameContainer ">
                          <h3 className="font-normal text-o-form-label-color">
                            Supporting Organizations
                          </h3>
                          <Autocomplete
                            onKeyDown={(e) => {
                              e.key === "Enter" && e.preventDefault();
                            }}
                            value={filterData.supportingOrganization}
                            autoHighlight={true}
                            onChange={(e, v) =>
                              handleFilterValueChange(
                                "supportingOrganization",
                                v
                              )
                            }
                            onInputChange={(e, value) =>
                              handleDropDownSearchOrg(e, value)
                            }
                            getOptionLabel={(option) =>
                              option ? option.name : ""
                            }
                            id="loc-list-option"
                            options={
                              orgDataOptions.length ? orgDataOptions : []
                            }
                            sx={{ width: 300 }}
                            renderInput={(params) => (
                              <TextField
                                variant="standard"
                                onFocus={(e) => resetErrorHandler(e)}
                                autoComplete="OFF"
                                sx={{ width: "360px" }}
                                {...params}
                                label=" "
                              />
                            )}
                          />
                        </div>
                        <div className="mt-5 OrgNameContainer ">
                          <h3 className="font-normal text-o-form-label-color">
                            Supporting Locations
                          </h3>
                          <Autocomplete
                            onKeyDown={(e) => {
                              e.key === "Enter" && e.preventDefault();
                            }}
                            value={filterData.supportingLoc}
                            autoHighlight={true}
                            onChange={(e, v) =>
                              handleFilterValueChange("supportingLoc", v)
                            }
                            onInputChange={(e, value) =>
                              handleDropDownSearchLoc(e, value)
                            }
                            getOptionLabel={(option) =>
                              option ? option.name : ""
                            }
                            id="loc-list-option"
                            options={
                              locationMultiSelectOptions.length
                                ? locationMultiSelectOptions
                                : []
                            }
                            sx={{ width: 300 }}
                            renderInput={(params) => (
                              <TextField
                                variant="standard"
                                sx={{ width: "360px" }}
                                {...params}
                                label=" "
                              />
                            )}
                          />
                        </div>

                        <div className="mt-5 OrgNameContainer ">
                          <h3 className="font-normal text-o-form-label-color">
                            Status
                          </h3>
                          <Autocomplete
                            value={filterData.filterStatus}
                            autoHighlight={true}
                            disableClearable
                            onChange={(e, v) =>
                              handleFilterValueChange("filterStatus", v)
                            }
                            getOptionLabel={(option) => (option ? option : "")}
                            id="org-list-option"
                            options={filterStatusOptions}
                            sx={{ width: 360 }}
                            renderInput={(params) => (
                              <TextField
                                variant="standard"
                                onFocus={(e) => resetErrorHandler(e)}
                                autoComplete="OFF"
                                {...params}
                                label=""
                              />
                            )}
                          />
                        </div>

                        <Button
                          variant="contained"
                          disableElevation
                          onClick={handleFilterCancel}
                          style={{
                            marginRight: "10px",
                            top: "30px",
                            borderColor: "#0F4391",
                            width: "99px",
                            height: "39px",
                            borderRadius: "9999px",
                            backgroundColor: "#E8FAFF",
                            color: "#0F4391",
                            border: "1px solid #0F4391",
                            marginBottom: "5px",
                          }}
                        >
                          CANCEL
                        </Button>

                        <Button
                          variant="contained"
                          disableElevation
                          onClick={resetFilterData}
                          style={{
                            marginRight: "10px",
                            top: "30px",
                            borderColor: "#0F4391",
                            width: "99px",
                            height: "39px",
                            borderRadius: "9999px",
                            backgroundColor: "#E8FAFF",
                            color: "#0F4391",
                            border: "1px solid #0F4391",
                            marginBottom: "5px",
                          }}
                        >
                          Reset
                        </Button>
                        <Button
                          variant="contained"
                          disableElevation
                          disabled={
                            filterData.filterStatus === "" &&
                            filterData.classificationId === "" &&
                            filterData.sampleName === ""
                          }
                          onClick={() => handleFilterData(0)}
                          style={{
                            top: "30px",
                            borderColor: "#0F4391",
                            width: "99px",
                            height: "39px",
                            borderRadius: "9999px",
                            backgroundColor: "#E8FAFF",
                            color: "#0F4391",
                            border: "1px solid #0F4391",
                            marginBottom: "5px",
                          }}
                        >
                          Search
                        </Button>
                      </Box>
                    </Modal>
                  </Grid>
                </Grid>
                <>
                  <ServiceReqList
                    setErrorMessage={setErrorMessage}
                    setSpecimenName={setSpecimenName}
                    sampleList={sampleList}
                    setBodysiteCode={setBodysiteCode}
                    setProcedureCode={setProcedureCode}
                    setLocPriceList={setLocPriceList}
                    setTotalPage={setTotalPage}
                    totalPage={totalPage}
                    setId={setId}
                    id={id}
                    getservicepageList={getservicepageList}
                    serviceReqList={[...serviceReqList]}
                    showFormActions={showFormActions} 
                    setServiceFormFields={setServiceFormFields}
                    calculateData={calculateData}
                    locationMultiSelectOptions={locationMultiSelectOptions}
                    orgDataOptions={orgDataOptions}
                    currentPage={isNewData ? 1 : currentPage}
                    servicerequestview={access?.servicerequestview}
                    servicerequestupdate={access?.servicerequestupdate}
                  />
                </>
              </div>
            </Grid>
            {isFormVisible.isOpen ? (
              <Grid item xs={6}>
                <div className="rightcontainer rounded-lg servicefullright">
                  <div className="overflow-y-auto o-form-container">
                    <>
                      <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <Box sx={style}>
                          <Typography
                            id="modal-modal-title"
                            variant="h6"
                            component="h2"
                          >
                            <Alert severity="info">
                              {serviceFormFields.status === "ACTIVE"
                                ? "Are you sure you want to reactivate the Service Request ?"
                                : "Are you sure you want to Deactivate the Service Request ?"}
                            </Alert>
                          </Typography>
                          <Typography
                            id="modal-modal-description"
                            sx={{ mt: 2 }}
                          >
                            {serviceFormFields.status === "ACTIVE"
                              ? "This activity will enable the Service Request and user will be able to access it across all products."
                              : "This activity disable the Service Request and user will not able to access it. You can reactivate the Service Request at anytime?"}

                            <div className="mt-10 flex justify-center items-center  towButtonContainer">
                              <Button
                                type="submit"
                                onClick={handleClose}
                                variant="contained"
                                disableElevation
                                style={{
                                  marginRight: "10px",
                                  borderColor: "#0F4391",
                                  width: "99px",
                                  height: "39px",
                                  borderRadius: "9999px",
                                  backgroundColor: "#E8FAFF",
                                  color: "#0F4391",
                                  border: "1px solid #0F4391",
                                  marginBottom: "100px",
                                }}
                              >
                                CANCEL
                              </Button>

                              <Button
                                type="submit"
                                onClick={handleProceed}
                                variant="contained"
                                disableElevation
                                style={{
                                  borderColor: "#0F4391",
                                  width: "99px",
                                  height: "39px",
                                  borderRadius: "9999px",
                                  backgroundColor: "#E8FAFF",
                                  color: "#0F4391",
                                  border: "1px solid #0F4391",
                                  marginBottom: "100px",
                                }}
                              >
                                PROCEED
                              </Button>
                            </div>
                          </Typography>
                        </Box>
                      </Modal>
                      <ToastContainer />
                      <div className="font-normal text-o-form-label-color mainhead flex justify-between items-center pl-5 pr-1 h-10">
                        <h1 id="COLOR">Service Request Overview</h1>
                        {/* <div className="closeIconContainer" onClick={hideForm}>
                          <IconButton
                            color="warning"
                            aria-label="upload picture"
                            component="span"
                          >
                            <CloseIcon />
                          </IconButton>
                        </div> */}
                      </div>

                      <form autoComplete="off">
                        <div className="mt-5 pl-5 OrgNameContainer">
                          <h3
                            className="font-normal text-o-form-label-color"
                            id="COLOR"
                          >
                            Service Request Master Name{" "}
                            <span style={{ color: "red" }}> *</span>
                          </h3>
                          <TextField
                            onKeyDown={(e) => {
                              e.key === "Enter" && e.preventDefault();
                            }}
                            onFocus={(e) => resetErrorHandler(e)}
                            autoComplete="OFF"
                            error={createServiceError.isNameError === true}
                            helperText={
                              createServiceError.isNameError
                                ? "Valid (ID) Required !"
                                : ""
                            }
                            onChange={(e) => handleInputChange(e)}
                            name="name"
                            value={serviceFormFields.name}
                            style={{ width: "890px" }}
                            id="standard-basic"
                            label=""
                            variant="standard"
                          />
                        </div>

                        <div className="mt-5 pl-5 OrgNameContainer ">
                          <h3 className="font-normal text-o-form-label-color">
                            Service Request Category{" "}
                            <span style={{ color: "red" }}> *</span>
                          </h3>
                          <Autocomplete
                            value={serviceFormFields.serviceRequestCategoryId}
                            autoHighlight={true}
                            onChange={(e, v) =>
                              handleDropDownPriceLoc(
                                "serviceRequestCategoryId",
                                "",
                                v
                              )
                            }
                            getOptionLabel={(option) => (option ? option : "")}
                            id="org-list-option"
                            options={serviceReqCatList ? serviceReqCatList : []}
                            sx={{ width: "890px" }}
                            renderInput={(params) => (
                              <TextField
                                variant="standard"
                                onFocus={(e) => resetErrorHandler(e)}
                                autoComplete="OFF"
                                error={
                                  createServiceError.isServiceRequestCategoryId ===
                                  true
                                }
                                helperText={
                                  createServiceError.isServiceRequestCategoryId
                                    ? "Valid (ServiceRequestCategoryId) Required !"
                                    : ""
                                }
                                {...params}
                                label=""
                              />
                            )}
                          />
                        </div>

                        <div className="mt-5 pl-5 OrgNameContainer">
                          <h3
                            className="font-normal text-o-form-label-color"
                            id="COLOR"
                          >
                            Description
                          </h3>

                          <TextField
                            rows={3}
                            multiline
                            onFocus={(e) => resetErrorHandler(e)}
                            autoComplete="OFF"
                            error={
                              createServiceError.isDescriptionError === true
                            }
                            helperText={
                              createServiceError.isDescriptionError
                                ? "Valid (ID) Required !"
                                : ""
                            }
                            onChange={(e) => handleInputChange(e)}
                            name="description"
                            value={serviceFormFields.description}
                            style={{ width: "890px" }}
                            id="standard-basic"
                            label=""
                            variant="outlined"
                          />
                        </div>

                        <div className="mt-5 pl-5 OrgNameContainer">
                          <h3
                            className="font-normal text-o-form-label-color"
                            id="COLOR"
                          >
                            Subject
                          </h3>
                          <TextField
                            onKeyDown={(e) => {
                              e.key === "Enter" && e.preventDefault();
                            }}
                            onFocus={(e) => resetErrorHandler(e)}
                            autoComplete="OFF"
                            onChange={(e) => handleInputChange(e)}
                            name="subject"
                            value={serviceFormFields.subject}
                            style={{ width: "890px" }}
                            id="standard-basic"
                            label=""
                            variant="standard"
                          />
                        </div>

                        <div className="mt-5 pl-5 OrgNameContainer">
                          <h3
                            className="font-normal text-o-form-label-color"
                            id="COLOR"
                          >
                            Instruction To Performer
                          </h3>
                          <TextField
                            onKeyDown={(e) => {
                              e.key === "Enter" && e.preventDefault();
                            }}
                            onFocus={(e) => resetErrorHandler(e)}
                            autoComplete="OFF"
                            onChange={(e) => handleInputChange(e)}
                            name="instructionToPerformer"
                            value={
                              serviceFormFields.instructionToPerformer &&
                              serviceFormFields.instructionToPerformer[0] &&
                              serviceFormFields.instructionToPerformer[0]
                            }
                            style={{ width: "890px" }}
                            id="standard-basic"
                            label=""
                            variant="standard"
                          />
                        </div>

                        <div className="mt-5 pl-5 OrgNameContainer">
                          <h3
                            className="font-normal text-o-form-label-color"
                            id="COLOR"
                          >
                            Reason Code
                          </h3>
                          <TextField
                            onKeyDown={(e) => {
                              e.key === "Enter" && e.preventDefault();
                            }}
                            onFocus={(e) => resetErrorHandler(e)}
                            autoComplete="OFF"
                            onChange={(e) => handleInputChange(e)}
                            name="reasonCode"
                            value={serviceFormFields.reasonCode}
                            style={{ width: "890px" }}
                            id="standard-basic"
                            label=""
                            variant="standard"
                          />
                        </div>

                        <div className="mt-5 pl-5 OrgNameContainer">
                          <h3
                            className="font-normal text-o-form-label-color"
                            id="COLOR"
                          >
                            Reason Description
                          </h3>
                          <TextField
                            onFocus={(e) => resetErrorHandler(e)}
                            autoComplete="OFF"
                            rows={3}
                            multiline
                            onChange={(e) => handleInputChange(e)}
                            name="reasonDescription"
                            value={serviceFormFields.reasonDescription}
                            style={{ width: "890px" }}
                            id="standard-basic"
                            label=""
                            variant="outlined"
                          />
                        </div>

                        <div className="mt-5 pl-5 OrgNameContainer ">
                          <h3 className="font-normal text-o-form-label-color">
                            Sample
                          </h3>
                          <Autocomplete
                            value={spacimenname}
                            autoHighlight={true}
                            onChange={(e, v) =>
                              handleDropDownPriceLoc("specimen", "", v)
                            }
                            getOptionLabel={(option) =>
                              option.sampleTypeName ? option.sampleTypeName : ""
                            }
                            id="org-list-option"
                            options={sampleList ? sampleList : []}
                            sx={{ width: "890px" }}
                            renderInput={(params) => (
                              <TextField
                                variant="standard"
                                onFocus={(e) => resetErrorHandler(e)}
                                autoComplete="OFF"
                                error={createServiceError.isSpecimen === true}
                                helperText={
                                  createServiceError.isSpecimen
                                    ? "Valid (Sample) Required !"
                                    : ""
                                }
                                {...params}
                                label=""
                              />
                            )}
                          />
                        </div>
                        <div className="mt-5 pl-5 OrgNameContainer">
                          <h3
                            className="font-normal text-o-form-label-color"
                            id="COLOR"
                          >
                            Quantity
                          </h3>
                          <TextField
                            onKeyDown={(e) => {
                              e.key === "Enter" && e.preventDefault();
                            }}
                            onFocus={(e) => resetErrorHandler(e)}
                            autoComplete="OFF"
                            onChange={(e) => handleInputChange(e)}
                            name="quantity"
                            value={
                              serviceFormFields.quantity &&
                              serviceFormFields.quantity.value &&
                              serviceFormFields.quantity.value
                            }
                            style={{ width: "890px" }}
                            id="standard-basic"
                            label=""
                            variant="standard"
                          />
                        </div>

                        <div className="mt-5 pl-5 OrgNameContainer ">
                          <h3 className="font-normal text-o-form-label-color">
                            Modality
                          </h3>
                          <Autocomplete
                            value={serviceFormFields.modality}
                            autoHighlight={true}
                            onChange={(e, v) =>
                              handleDropDownChange("modality", v)
                            }
                            getOptionLabel={(option) => (option ? option : "")}
                            id="org-list-option"
                            options={modalityList ? modalityList : []}
                            sx={{ width: "890px" }}
                            renderInput={(params) => (
                              <TextField
                                variant="standard"
                                onFocus={(e) => resetErrorHandler(e)}
                                autoComplete="OFF"
                                {...params}
                                label=""
                              />
                            )}
                          />
                        </div>
                        <div className="mt-5 pl-5 OrgNameContainer ">
                          <h3 className="font-normal text-o-form-label-color">
                            Laterality
                          </h3>
                          <Autocomplete
                            multiple
                            value={serviceFormFields.laterality}
                            autoHighlight={true}
                            onChange={(e, v) =>
                              handleDropDownChange("laterality", v)
                            }
                            getOptionLabel={(option) => (option ? option : "")}
                            id="org-list-option"
                            options={lateralityList ? lateralityList : []}
                            sx={{ width: "890px" }}
                            renderInput={(params) => (
                              <TextField
                                variant="standard"
                                onFocus={(e) => resetErrorHandler(e)}
                                autoComplete="OFF"
                                {...params}
                                label=""
                              />
                            )}
                          />
                        </div>

                        <div className="mt-5 pl-5 OrgNameContainer ">
                          {/* <h3 className="font-normal text-o-form-label-color">Sample </h3> */}
                          {/* <Autocomplete
                            value={serviceFormFields.sample}
                            autoHighlight={true}
                            onChange={(e, v) => handleDropDownChange('laterality', v)}
                            getOptionLabel={(option) => option.sampleTypeName ? option.sampleTypeName : ""}
                            id="org-list-option"
                            options={sampleList ? sampleList : []}
                            sx={{ width: 360 }}
                            renderInput={(params) => <TextField variant="standard" onFocus={(e) => resetErrorHandler(e)} autoComplete="OFF" error={createServiceError.isServiceRequestCategoryId === true} helperText={createServiceError.isServiceRequestCategoryId ? "Valid (ServiceRequestCategoryId) Required !" : ""}{...params} label="" />}

                          /> */}
                        </div>

                        <div className="mt-5 pl-5 OrgNameContainer ">
                          <h3 className="font-normal text-o-form-label-color">
                            Supporting Organizations
                            <span style={{ color: "red" }}> *</span>
                          </h3>
                          <Autocomplete
                            multiple
                            onKeyDown={(e) => {
                              e.key === "Enter" && e.preventDefault();
                            }}
                            value={
                              serviceFormFields &&
                              serviceFormFields.supportingOrganisations &&
                              serviceFormFields.supportingOrganisations
                            }
                            autoHighlight={true}
                            onChange={(e, v) =>
                              handleDropDownPriceLoc("organization", "", v)
                            }
                            getOptionLabel={(option) =>
                              option ? option.name : ""
                            }
                            id="loc-list-option"
                            options={
                              orgDataOptions.length ? orgDataOptions : []
                            }
                            onInputChange={(e, value) =>
                              handleDropDownSearchOrg(e, value)
                            }
                            sx={{ width: 300 }}
                            renderInput={(params) => (
                              <TextField
                                variant="standard"
                                sx={{ width: "890px" }}
                                {...params}
                                error={
                                  createServiceError.isSupportingOrg === true
                                }
                                helperText={
                                  createServiceError.isSupportingOrg
                                    ? "Valid Supporting Organization Required !"
                                    : ""
                                }
                                label=" "
                              />
                            )}
                          />
                        </div>
                        <div className="mt-5 pl-5 OrgNameContainer ">
                          <h3 className="font-normal text-o-form-label-color">
                            Supporting Locations
                            <span style={{ color: "red" }}> *</span>
                          </h3>
                          <Autocomplete
                            multiple
                            onKeyDown={(e) => {
                              e.key === "Enter" && e.preventDefault();
                            }}
                            value={
                              serviceFormFields &&
                              serviceFormFields.supportinglocations &&
                              serviceFormFields.supportinglocations
                            }
                            autoHighlight={true}
                            onChange={(e, v) =>
                              handleDropDownPriceLoc("locations", "", v)
                            }
                            onInputChange={(e, value) =>
                              handleDropDownSearchLoc(e, value)
                            }
                            getOptionLabel={(option) =>
                              option ? option.name : ""
                            }
                            id="loc-list-option"
                            options={
                              locationMultiSelectOptions.length
                                ? locationMultiSelectOptions
                                : []
                            }
                            sx={{ width: 300 }}
                            renderInput={(params) => (
                              <TextField
                                variant="standard"
                                sx={{ width: "890px" }}
                                {...params}
                                error={
                                  createServiceError.isSupportingLocations ===
                                  true
                                }
                                helperText={
                                  createServiceError.isSupportingLocations
                                    ? "Valid Supporting location Required !"
                                    : ""
                                }
                                label=" "
                              />
                            )}
                          />
                        </div>

                        <div className="mt-5 pl-5 OrgNameContainer ">
                          <h3 className="labelf m-s-f" id="color">
                            Procedure Code
                          </h3>
                        </div>

                        <div className="mt-5 pl-5 OrgNameContainer ">
                          <table
                            className="tableborder"
                            style={{ width: "66%", marginRight: "130px" }}
                          >
                            <thead className="tableborder">
                              <tr>
                                <td
                                  className="tableborder set m-r-f"
                                  style={{ width: "25%" }}
                                >
                                  System
                                </td>
                                <td
                                  className="tableborder set m-r-f"
                                  style={{ width: "23%" }}
                                >
                                  Code
                                </td>
                                <td
                                  className=" tableborder set m-r-f"
                                  style={{ width: "25%" }}
                                >
                                  Description
                                </td>
                                <td
                                  className=" tableborder set m-r-f"
                                  style={{ width: "22%" }}
                                >
                                  Url
                                </td>

                                <td
                                  className="tableborder set m-r-f"
                                  style={{ width: "5%" }}
                                >
                                  Action
                                </td>
                              </tr>
                            </thead>
                            <tbody>
                              {procedureCode &&
                                procedureCode.length > 0 &&
                                procedureCode.map((item, i) => {
                                  return (
                                    <tr key={i} className="tablebor a">
                                      <td className="tableborder m-r-f">
                                        <TextField
                                          InputProps={{
                                            disableUnderline: true,
                                          }}
                                          onFocus={(e) => resetErrorHandler(e)}
                                          autoComplete="OFF"
                                          onChange={(e) =>
                                            handleInputChangeProc(e, "proc", i)
                                          }
                                          name="system"
                                          value={procedureCode[i].system}
                                          id="standard-basic"
                                          multiline
                                          rowsMax="3"
                                          label=""
                                          variant="standard"
                                        />
                                      </td>
                                      <td
                                        className="tableborder m-r-f"
                                        style={{ width: "", paddingLeft: "" }}
                                      >
                                        <TextField
                                          InputProps={{
                                            disableUnderline: true,
                                          }}
                                          onFocus={(e) => resetErrorHandler(e)}
                                          autoComplete="OFF"
                                          onChange={(e) =>
                                            handleInputChangeProc(e, "proc", i)
                                          }
                                          name="code"
                                          value={procedureCode[i].code}
                                          id="standard-basic"
                                          label=""
                                          variant="standard"
                                          multiline
                                          rowsMax="3"
                                        />
                                      </td>
                                      <td
                                        className="tableborder m-r-f"
                                        style={{ width: "", paddingLeft: "" }}
                                      >
                                        <TextField
                                          InputProps={{
                                            disableUnderline: true,
                                          }}
                                          onFocus={(e) => resetErrorHandler(e)}
                                          autoComplete="OFF"
                                          onChange={(e) =>
                                            handleInputChangeProc(e, "proc", i)
                                          }
                                          name="description"
                                          value={procedureCode[i].description}
                                          id="standard-multiline-static"
                                          label=""
                                          multiline
                                          rowsMax="3"
                                          variant="standard"
                                        />
                                      </td>
                                      <td
                                        className="tableborder m-r-f"
                                        style={{ width: "", paddingLeft: "" }}
                                      >
                                        <TextField
                                          InputProps={{
                                            disableUnderline: true,
                                          }}
                                          onFocus={(e) => resetErrorHandler(e)}
                                          autoComplete="OFF"
                                          onChange={(e) =>
                                            handleInputChangeProc(e, "proc", i)
                                          }
                                          name="url"
                                          value={procedureCode[i].url}
                                          id="standard-basic"
                                          label=""
                                          variant="standard"
                                          multiline
                                          rowsMax="3"
                                        />
                                      </td>
                                      <td
                                        className="tableborder"
                                        style={{ width: "" }}
                                        onKeyDown={(e) => {
                                          e.key === "Enter" &&
                                            e.preventDefault();
                                        }}
                                        onClick={() => deleteRow(i, "proc")}
                                      >
                                        <DeleteIcon />
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                        <Button
                          className="m-2"
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          onClick={(e, v) => addRow("procedure")}
                          variant="contained"
                          disableElevation
                          style={{
                            marginRight: "10px",
                            borderColor: "#0F4391",
                            width: "43px",
                            height: "22px",
                            borderRadius: "30px",
                            backgroundColor: "#E8FAFF",
                            color: "#0F4391",
                            border: "1px solid #0F4391",
                            margin: "14px",
                          }}
                        >
                          Add
                        </Button>
                        <div className="mt-5 pl-5 OrgNameContainer ">
                          <h3 className="labelf m-s-f" id="color">
                            Location Specific Details
                            <span style={{ color: "red" }}> *</span>
                          </h3>
                        </div>
                        <div className="mt-5 pl-5 OrgNameContainer ">
                          <table
                            className="tableborder"
                            style={{ width: "66%", marginRight: "130px" }}
                          >
                            <thead className="tableborder">
                              <tr>
                                <td
                                  className="tableborder m-r-f"
                                  style={{ width: "" }}
                                >
                                  Location
                                </td>
                                <td
                                  className="tableborder m-r-f"
                                  style={{ width: "" }}
                                >
                                  Pricing
                                </td>
                                <td
                                  className="tableborder m-r-f"
                                  style={{ width: "" }}
                                >
                                  Action
                                </td>
                              </tr>
                            </thead>
                            <tbody>
                              {locpriceList &&
                                locpriceList.length > 0 &&
                                locpriceList.map((item, i) => {
                                  return (
                                    <tr key={i} className="tablebor a">
                                      <td className="tableborder m-r-f">
                                        <Autocomplete
                                          underlineStyle={{
                                            display: "none",
                                            borderBottom: "none",
                                          }}
                                          InputProps={{
                                            disableUnderline: true,
                                          }}
                                          onKeyDown={(e) => {
                                            e.key === "Enter" &&
                                              e.preventDefault();
                                          }}
                                          value={item}
                                          autoHighlight={true}
                                          onChange={(e, v) =>
                                            handleDropDownPriceLoc("loc", i, v)
                                          }
                                          getOptionLabel={(option) =>
                                            option ? option.name : ""
                                          }
                                          id="org-list-option"
                                          options={
                                            serviceFormFields.supportingLocationsForTable
                                              ? serviceFormFields.supportingLocationsForTable
                                              : []
                                          }
                                          sx={{ width: "600px" }}
                                          renderInput={(params) => (
                                            <TextField
                                              variant="standard"
                                              InputProps={{
                                                disableUnderline: true,
                                              }}
                                              {...params}
                                              label=""
                                            />
                                          )}
                                        />
                                      </td>
                                      <td
                                        className="tableborder m-r-f"
                                        style={{ width: "", paddingLeft: "" }}
                                      >
                                        <TextField
                                          InputProps={{
                                            disableUnderline: true,
                                          }}
                                          onFocus={(e) => resetErrorHandler(e)}
                                          onKeyDown={(e) => {
                                            e.key === "Enter" &&
                                              e.preventDefault();
                                          }}
                                          autoComplete="OFF"
                                          onChange={(e) =>
                                            handleInputChange(e, i)
                                          }
                                          name="price"
                                          value={item.amount}
                                          style={{ width: "150px" }}
                                          id="standard-basic"
                                          label=""
                                          variant="standard"
                                        />
                                      </td>
                                      <td
                                        className="tableborder"
                                        style={{ width: "" }}
                                        onKeyDown={(e) => {
                                          e.key === "Enter" &&
                                            e.preventDefault();
                                        }}
                                        onClick={() => deleteRow(i, "loc")}
                                      >
                                        <DeleteIcon />
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                        <Button
                          className="m-2"
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          onClick={(e, v) => addRow("loc")}
                          variant="contained"
                          disableElevation
                          style={{
                            marginRight: "10px",
                            borderColor: "#0F4391",
                            width: "43px",
                            height: "22px",
                            borderRadius: "30px",
                            backgroundColor: "#E8FAFF",
                            color: "#0F4391",
                            border: "1px solid #0F4391",
                            margin: "14px",
                          }}
                        >
                          Add
                        </Button>
                        {createServiceError.locationAndPriceingError ? (
                          <div
                            className="locationPricingError"
                            style={{
                              color: "#d32f2f",
                              marginLeft: "20px",
                              fontSize: "0.75rem",
                            }}
                          >
                            Location and pricing required.
                          </div>
                        ) : null}
                        <div className="mt-5 pl-5 OrgNameContainer ">
                          <h3 className="labelf m-s-f" id="color">
                            Body Site Code
                          </h3>
                        </div>

                        <div className="mt-5 pl-5 OrgNameContainer ">
                          <table
                            className="tableborder a"
                            style={{ width: "66%", marginRight: "130px" }}
                          >
                            <thead className="a">
                              <tr>
                                <td
                                  className="tableborder a m-r-f"
                                  style={{ width: "19%" }}
                                >
                                  System
                                </td>
                                <td
                                  className="tableborder a m-r-f"
                                  style={{ width: "19%" }}
                                >
                                  Code
                                </td>
                                <td
                                  className="tableborder a m-r-f"
                                  style={{ width: "19%" }}
                                >
                                  Description
                                </td>
                                <td
                                  className="tableborder a m-r-f"
                                  style={{ width: "19%" }}
                                >
                                  Site
                                </td>
                                <td
                                  className="tableborder a m-r-f"
                                  style={{ width: "19%" }}
                                >
                                  Url
                                </td>

                                <td
                                  className="tableborder a m-r-f"
                                  style={{ width: "5%" }}
                                >
                                  Action
                                </td>
                              </tr>
                            </thead>
                            <tbody>
                              {bodysiteCode &&
                                bodysiteCode.length > 0 &&
                                bodysiteCode.map((item, i) => {
                                  return (
                                    <tr key={i} className="tablebor a">
                                      <td className=" tableborder m-r-f">
                                        <tr>
                                          <TextField
                                            InputProps={{
                                              disableUnderline: true,
                                            }}
                                            onFocus={(e) =>
                                              resetErrorHandler(e)
                                            }
                                            autoComplete="OFF"
                                            onChange={(e) =>
                                              handleInputChangeProc(
                                                e,
                                                "body",
                                                i
                                              )
                                            }
                                            name="system"
                                            value={bodysiteCode[i].system}
                                            id="standard-basic"
                                            label=""
                                            variant="standard"
                                            multiline
                                            rowsMax="3"
                                          />
                                        </tr>
                                      </td>
                                      <td
                                        className="tableborder m-r-f"
                                        style={{ width: "", paddingLeft: "" }}
                                      >
                                        <TextField
                                          InputProps={{
                                            disableUnderline: true,
                                          }}
                                          onFocus={(e) => resetErrorHandler(e)}
                                          autoComplete="OFF"
                                          onChange={(e) =>
                                            handleInputChangeProc(e, "body", i)
                                          }
                                          name="code"
                                          value={bodysiteCode[i].code}
                                          id="standard-basic"
                                          label=""
                                          variant="standard"
                                          multiline
                                          rowsMax="3"
                                        />
                                      </td>
                                      <td
                                        className="tableborder m-r-f"
                                        style={{ width: "", paddingLeft: "" }}
                                      >
                                        <TextField
                                          InputProps={{
                                            disableUnderline: true,
                                          }}
                                          onFocus={(e) => resetErrorHandler(e)}
                                          autoComplete="OFF"
                                          onChange={(e) =>
                                            handleInputChangeProc(e, "body", i)
                                          }
                                          name="description"
                                          value={bodysiteCode[i].description}
                                          id="standard-multiline-static"
                                          label=""
                                          multiline
                                          rowsMax="2"
                                          variant="standard"
                                        />
                                      </td>
                                      <td
                                        className="tableborder m-r-f"
                                        style={{ width: "", paddingLeft: "" }}
                                      >
                                        <TextField
                                          InputProps={{
                                            disableUnderline: true,
                                          }}
                                          onFocus={(e) => resetErrorHandler(e)}
                                          autoComplete="OFF"
                                          onChange={(e) =>
                                            handleInputChangeProc(e, "body", i)
                                          }
                                          name="site"
                                          value={bodysiteCode[i].site}
                                          id="standard-basic"
                                          label=""
                                          variant="standard"
                                          multiline
                                          rowsMax="3"
                                        />
                                      </td>
                                      <td
                                        className="tableborder m-r-f"
                                        style={{ width: "", paddingLeft: "" }}
                                      >
                                        <TextField
                                          InputProps={{
                                            disableUnderline: true,
                                          }}
                                          onFocus={(e) => resetErrorHandler(e)}
                                          autoComplete="OFF"
                                          onChange={(e) =>
                                            handleInputChangeProc(e, "body", i)
                                          }
                                          name="url"
                                          value={bodysiteCode[i].url}
                                          id="standard-basic"
                                          label=""
                                          variant="standard"
                                          multiline
                                          rowsMax="3"
                                        />
                                      </td>
                                      <td
                                        className="tableborder"
                                        style={{ width: "" }}
                                        onKeyDown={(e) => {
                                          e.key === "Enter" &&
                                            e.preventDefault();
                                        }}
                                        onClick={() => deleteRow(i, "body")}
                                      >
                                        <DeleteIcon />
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                        <Button
                          className="m-2"
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          onClick={(e, v) => addRow("body")}
                          variant="contained"
                          disableElevation
                          style={{
                            marginRight: "10px",
                            borderColor: "#0F4391",
                            width: "43px",
                            height: "22px",
                            borderRadius: "30px",
                            backgroundColor: "#E8FAFF",
                            color: "#0F4391",
                            border: "1px solid #0F4391",
                            margin: "14px",
                          }}
                        >
                          Add
                        </Button>

                        {serviceFormFields.is_update === true ? (
                          <div className="mt-5 pl-5 OrgNameContainer">
                            <h3 className="font-normal text-o-form-label-color">
                              Status
                            </h3>
                            <Autocomplete
                              onKeyDown={(e) => {
                                e.key === "Enter" && e.preventDefault();
                              }}
                              value={
                                serviceFormFields &&
                                serviceFormFields.status &&
                                serviceFormFields.status
                              }
                              autoHighlight={true}
                              onChange={(e, v) =>
                                handleDropDownPriceLoc("status", "", v)
                              }
                              getOptionLabel={(option) =>
                                option ? option : ""
                              }
                              id="loc-list-option"
                              options={
                                packageStatusList ? packageStatusList : []
                              }
                              sx={{ width: 300 }}
                              renderInput={(params) => (
                                <TextField
                                  variant="standard"
                                  sx={{ width: "890px" }}
                                  {...params}
                                  error={
                                    createServiceError.isStatusError === true
                                  }
                                  label=" "
                                  helperText={
                                    createServiceError.isStatusError
                                      ? "Required !"
                                      : ""
                                  }
                                />
                              )}
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="mt-5 pl-5 OrgNameContainer ">
                          <div className="progressContainer mt-5 flex justify-center items-center">
                            {showerror === true ? (
                              <Alert severity="error" color="warning">
                                {errorDisplay}
                              </Alert>
                            ) : (
                              // (createOrgRes.isLoading || updateOrgRes.isLoading) ?
                              // <CircularProgress /> :
                              ""
                            )}
                          </div>
                          <div className="mt-9 flex justify-center items-center  towButtonContainer h-40">
                            <Button
                              onClick={hideForm}
                              type="submit"
                              variant="contained"
                              disableElevation
                              style={{
                                marginRight: "10px",
                                borderColor: "#0F4391",
                                width: "99px",
                                height: "39px",
                                borderRadius: "9999px",
                                backgroundColor: "#E8FAFF",
                                color: "#0F4391",
                                border: "1px solid #0F4391",
                                marginBottom: "100px",
                              }}
                            >
                              CANCEL
                            </Button>
                            <Button
                              variant="contained"
                              disableElevation
                              onClick={submitCreatePackageForm}
                              style={{
                                borderColor: "#0F4391",
                                width: "99px",
                                height: "39px",
                                borderRadius: "9999px",
                                backgroundColor: "#E8FAFF",
                                color: "#0F4391",
                                border: "1px solid #0F4391",
                                marginBottom: "100px",
                              }}
                            >
                              SAVE
                            </Button>
                          </div>
                        </div>
                      </form>
                    </>
                  </div>
                </div>
              </Grid>
            ) : null}
          </Grid>
        </div>
      )}
       <AdminCookieConsentBanner />
    </div>
  );
};

export default ServiceRequest;
