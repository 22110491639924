import React, { useEffect, useState } from "react";
import AdminHome from "../AdminHome";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import DeleteIcon from "@mui/icons-material/Delete";
import filter from "../../../images/filter.png";
import CircularProgress from "@mui/material/CircularProgress";
import "../Organization/Mainhome.css";
import { Grid, Button } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Alert from "@mui/material/Alert";
import { createStyles, makeStyles } from "@mui/styles";
import Autocomplete from "@mui/material/Autocomplete";
import InputAdornment from "@mui/material/InputAdornment";
//import useCreatePackageHook from "./packagelogic";
import { LoggerFunction } from "../../../logger/logger";
import { toasterFunction } from "../../../globalFunctions/getLocalStorageData";
import Tooltip from '@mui/material/Tooltip';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import {
  useGetBodySitesListMutation,
  usePutBodySitesMutation,
  useSearchBodySitesMutation,
  useGetTerminologyMutation,
} from "../../../services/adminLogIn";
import BodySiteList from "./bodysitesList";
import "../bodysites/bodysites.css";
import { isContentEditable } from "@testing-library/user-event/dist/utils";
import { TextFieldsRounded } from "@mui/icons-material";
import useBodySiteHook from "./bodySiteLogic";
import Breadcrums from "../../global/breadcrums/Breadcrums";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminCookieConsentBanner from "../home/AdminCookieConsentBanner";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const useStyles = makeStyles((theme) => ({
  menuPaper: {
    maxHeight: 200,
  },
}));

const BodySites = (props) => {
  const [isFormVisible, setFormVisible] = useState({
    isOpen: false,
    isClose: true,
  });
  const [serachVal, setsearchVal] = useState("");
  const [paginationdata, setPaginationData] = useState({
    string: "",
    page: 1,
  });
  const [calculateData, setTotalPageCalculate] = useState({
    number: "",
    numberOfElements: "",
    totalElements: "",
    showcount: ""
  });
  const [currentPage, setCurrentPage] = useState(1);


  const [bodySitesListData, setBodySitesListData] = useState();
  const [totalPages, setTotalPages] = useState(0);
  const [getBodySitesList] = useGetBodySitesListMutation();
  const [getTerminology] = useGetTerminologyMutation();
  const [putBodySites] = usePutBodySitesMutation();
  const [searchBodySites] = useSearchBodySitesMutation();
  const [isFormUpdate, setIsFormUpdata] = useState(false);
  const [bodySiteListLoading, setbodySiteListLoading] = useState(true);
  const [bodySitesStatus, setBodySitesStatus] = useState();
  const [status, setStatus] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [name, setName] = useState("");
  const [bodysiteSystem, setbodysiteSystem] = useState([]);
  const [searchValue, setSearchValue] = useState();
  const [access, setAccess] = useState(
    JSON.parse(sessionStorage.getItem("access"))
  );

  const statuslist = ["Active", "Inactive"];

  const getTerminologyFunc = async (page) => {
    const res = await getTerminology();
    setbodysiteSystem([...res?.data]);
  };

  const {
    handleInputsChange,
    handleDropDownChange,
    setBodySiteFormData,
    setBodySiteErrors,
    resetErrorHandler,
    bodySiteErrors,
    bodySiteFormData,
  } = useBodySiteHook();

  const showFormActions = () => {
    if (access?.userview == true) {
      if (!isFormVisible.isOpen) {
        setFormVisible((prev) => ({
          ...prev,
          isOpen: (isFormVisible.isOpen = true),
          isFormVisible,
          isClose: (isFormVisible.isClose = false),
        }));
      }
    } else {
      toasterFunction(" No Permission", "error");
    }
  };

  const handleStatusChange = (value) => {
    setModalOpen(true);
    setBodySitesStatus(value);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  const halndleClickonProceed = async () => {
    setStatus(bodySitesStatus);
    // const res = await updateBodySitesStatus(bodySitesStatus);
    setModalOpen(false);
  };

  const hideForm = () => {
    setSearchValue("");
    setBodySiteFormData({
      bodysiteCode: "",
      bodySiteDesc: "",
      bodysiteDisplayText: "",
      bodysiteName: "",
      bodysiteSystemValue: "",
    });
    setBodySiteErrors({
      bodySiteNameError: false,
      bodySiteNamenumerror: false,
      bodySiteSystemError: false,
    });
    if (isFormVisible.isOpen) {
      setFormVisible((prev) => ({
        ...prev,
        isOpen: (isFormVisible.isOpen = false),
        isFormVisible,
        isClose: (isFormVisible.isClose = true),
      }));
    }
    setIsFormUpdata(false);
    setName("");
  };
  // function isnum(bodysiteName) {
  //   return !/^[0-9]+$/.test(bodysiteName)
  // }

  // function isNumericUsingRegularExpression(string) {
  //   const numericRegex = /^-?(([0-9])|(([0-9])\.([0-9]*)))$/;
  //   return numericRegex.test(string);
  // }
  function isNumeric(value) {
    return /^-?\d+$/.test(value)
  }
  const isformValid = () => {
      if (bodySiteFormData.bodysiteName === "") {
      setBodySiteErrors((prev) => ({ ...prev, bodySiteNameError: true }));
    }
    if (isNumeric(bodySiteFormData.bodysiteName) === true) {
      setBodySiteErrors((prev) => ({ ...prev, bodySiteNamenumerror: true }));

    }

    if (
      bodySiteFormData.bodysiteSystemValue === "" ||
      bodySiteFormData.bodysiteSystemValue === undefined ||
      bodySiteFormData.bodysiteSystemValue === null
    ) {
      setBodySiteErrors((prev) => ({ ...prev, bodySiteSystemError: true }));
    }

    if (bodySiteFormData.bodysiteCode === "") {
      setBodySiteErrors((prev) => ({ ...prev, bodySiteCodeError: true }));
    }

    if (
      bodySiteFormData.bodysiteName === "" ||
      isNumeric(bodySiteFormData.bodysiteName) === true ||
      bodySiteFormData.bodySiteNamenumerror === true ||
      bodySiteFormData.bodysiteCode === "" ||
      bodySiteFormData.bodysiteSystemValue === "" ||
      bodySiteFormData.bodysiteSystemValue === undefined ||
      bodySiteFormData.bodysiteSystemValue === null
    ) {
      return false;
    } else return true;
  };

  const submitBodySitesForm = async () => {
    const obj = {
      // code: bodySiteFormData.bodysiteCode,
      displayText: bodySiteFormData.bodysiteCode,
      code: bodySiteFormData.bodysiteCode,
      terminology: bodySiteFormData.bodysiteSystemValue.name,
      description: bodySiteFormData.bodysiteDesc,
      site: bodySiteFormData.bodysiteName,
      system: bodySiteFormData.bodysiteSystemValue.system,
    };
    const isValid = isformValid();

    if (isValid) {
      const nameComponents = bodySitesListData.map((item) => (
        item?.site.toLowerCase()
      ));
      let duplicate = nameComponents?.includes(bodySiteFormData.bodysiteName.toLowerCase())
      if (isFormUpdate === true) {
        obj["active"] = status === "Active" ? true : false;
        const res = await putBodySites(obj);
        if (res) {
          toasterFunction("Updated Successfully", "success")
        }
        else {
          toasterFunction("Something went wrong", "error")
        }
      }
      else {
        if (duplicate === true) {
          toasterFunction(bodySiteFormData.bodysiteName + " already exist", "error")
          const lowercaseBodysiteName = bodySiteFormData.bodysiteName.toLowerCase();
          nameComponents?.includes(lowercaseBodysiteName) || nameComponents?.includes(lowercaseBodysiteName.toUpperCase());
        }
        else {
          obj["active"] = true;
          const res = await putBodySites(obj);
          if (res) {
            toasterFunction("Created Successfully", "success")

          }
          else {
            toasterFunction("Something went wrong", "error")
          }
        }
      }
      hideForm();
      const response = getBodySitesListData(1);
      setTotalPages(response.data.totalPages);
    }
  };

  const handleRowClick = (data) => {
    setIsFormUpdata(true);
    setName(data.code);
    setStatus(data.active ? "Active" : "Inactive");
    setBodySiteErrors({
      bodySiteNameError: false,
      bodySiteNamenumerror: false,
      bodySiteSystemError: false,
    });
    let systemObj = {};
    bodysiteSystem.forEach((element) => {
      if (element.name === data.terminology) {
        systemObj = element;
      }
    });
    const obj = {
      bodysiteCode: data.code,
      bodysiteDesc: data.description,
      bodysiteDisplayText: data.terminology,
      bodysiteName: data.site,

      bodysiteSystemValue: systemObj,
    };
    setBodySiteFormData(obj);
    showFormActions();
  };

  const getBodySitesListData = async (page) => {
    let payload = {};
    payload.searchStr = "";
    payload.page = page - 1;
    setPaginationData({ ...payload });
    const res = await getBodySitesList(page - 1);
    if (res.data.content.length) {
      setBodySitesListData(res.data.content);
      setbodySiteListLoading(false);
      setTotalPages(res.data.totalPages);
      setTotalPageCalculate({ numberOfElements: res?.data?.numberOfElements, number: res?.data?.number, totalElements: res?.data?.totalElements })

    }
  };

  const searchUserEvent = async (value) => {
    setSearchValue(value);
    const res = await searchBodySites(value);
    if (value.length == 0) {
      getBodySitesListData(paginationdata.page);
    }
    if (res?.data?.content?.length > 0) {
      setBodySitesListData([...res?.data?.content]);
    } else {
      setBodySitesListData([]);
    }
  };
  useEffect(() => {
    getBodySitesListData(paginationdata.page);
    getTerminologyFunc();
    setCurrentPage(1);
  }, []);

  return (
    <div className="create-package">
      {bodySiteListLoading ? (
        <div style={{ position: "fixed", left: "50%", top: "50%" }}>
          <CircularProgress />
        </div>
      ) : (
        <div className={props.expand1.empty === true ? "main1" : "main"}>
          <div className="breadCumContainer flex justify-start items-center h-9 w-full bg-o-color-one organ1">
            <ol className="ml-8 flex " style={{ listStyleType: "none" }}>
              <div className="flex mt-1">
                <li className="w-28 h-thirtyPix rounded-mysix flex justify-center items-center font-medium bg-white m-r-f orgbox">
                  <span>Categories</span>
                </li>
              </div>
              <i className="right"></i>
              <div className="flex mt-1">
                <li className="w-28 h-thirtyPix rounded-mysix flex justify-center items-center font-medium bg-white m-r-f orgbox rows">
                  <span>Clinical</span>
                </li>
              </div>
              <i className="right"></i>
              <div className="flex mt-1">
                <li className="w-28 h-thirtyPix rounded-mysix flex justify-center items-center font-medium bg-white m-r-f breadback rows">
                  <span>Bodysites</span>
                </li>
              </div>
            </ol>
          </div>
          <ToastContainer />
          <Grid container mt={-2}>
            <Grid item xs={6}>
              <div
                className={
                  isFormVisible.isOpen
                    ? "leftcontainer rounded-lg"
                    : "leftcontainer1 rounded-lg"
                }
              >
                {/* <Grid className="OsearchContainer w-1/2 h-9 border border-o-color-two  rounded-searchRadius"> */}
                <Grid className=" p-7 ">
                  <TextField
                    label=""
                    onChange={(e) => searchUserEvent(e.target.value)}
                    onKeyDown={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                    // id="outlined-start-adornment"
                    sx={{ m: 1, width: "25ch" }}
                    value={searchValue}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchOutlinedIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid className="editContainer rounded-radiusFourPix ">
                  <Grid
                    disabled={access?.useradd == false}
                    onClick={showFormActions}
                    className={
                      isFormVisible.isOpen ? "addIconchange" : "addIcon"
                    }
                    data-testid="open"
                  >
                    <span className=" p-2 m-r-f ">Add</span>
                    <AddCircleOutlineIcon
                      style={{
                        fontSize: "14px",
                        color: "#545454",
                        marginBottom: "3px",
                        color: `${isFormVisible.isOpen ? "#ffffff" : "#545454"
                          }`,
                      }}
                    />
                  </Grid>

                  <Grid className="Addaction">
                    <span className="p-2 m-r-f">Actions</span>
                    <AddCircleOutlineIcon
                      style={{
                        fontSize: "14px",
                        color: "#545454",
                        marginBottom: "3px",
                      }}
                    />
                  </Grid>

                  <Grid className="addIcon">
                    <span className="p-2 m-r-f">Filter</span>
                    <img
                      src={filter}
                      alt="filter.png"
                      style={{
                        position: "absolute",
                        left: "93%",
                        right: "20px",
                        top: "15px",
                        bottom: "95px",
                        width: "10px",
                        paddingTop: "3px",
                      }}
                    ></img>
                  </Grid>
                </Grid>
                <>
                  <BodySiteList
                    bodySitesList={bodySitesListData}
                    setTotalPages={setTotalPages}
                    totalPages={totalPages}
                    handleRowClick={handleRowClick}
                    name={name}
                    getBodySitesListData={getBodySitesListData}
                    userview={access?.userview}
                    calculateData={calculateData}
                    currentPage={currentPage}
                    userupdate={access?.userupdate}
                  />
                </>
              </div>
            </Grid>
            {isFormVisible.isOpen ? (
              <Grid item xs={6}>
                <div className="rightcontainer rounded-lg">
                  <div className="overflow-y-auto o-form-container">
                    <Modal
                      open={modalOpen}
                      onClose={handleClose}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box sx={style}>
                        <Typography
                          id="modal-modal-title"
                          variant="h6"
                          component="h2"
                        >
                          <Alert severity="info">
                            {bodySitesStatus === "Active"
                              ? "Are you sure you want to activate the Body Site ? This activity will enable the Body Site and user will be able to access it across all products. You can deactivate the Body Site at anytime?"
                              : "Are you sure you want to Deactivate the Body Site ? This activity disable the Body Site and user will not able to access it. You can reactivate the Body Site at anytime?"}
                          </Alert>
                        </Typography>
                        <div className="mt-10 flex justify-center items-center  towButtonContainer">
                          <Button
                            type="submit"
                            onClick={handleClose}
                            variant="contained"
                            disableElevation
                            style={{
                              marginRight: "10px",
                              borderColor: "#0F4391",
                              width: "99px",
                              height: "39px",
                              borderRadius: "9999px",
                              backgroundColor: "#E8FAFF",
                              color: "#0F4391",
                              border: "1px solid #0F4391",
                              marginBottom: "100px",
                            }}
                          >
                            CANCEL
                          </Button>

                          <Button
                            type="submit"
                            onClick={halndleClickonProceed}
                            variant="contained"
                            disableElevation
                            style={{
                              borderColor: "#0F4391",
                              width: "99px",
                              height: "39px",
                              borderRadius: "9999px",
                              backgroundColor: "#E8FAFF",
                              color: "#0F4391",
                              border: "1px solid #0F4391",
                              marginBottom: "100px",
                            }}
                          >
                            PROCEED
                          </Button>
                        </div>
                      </Box>
                    </Modal>

                    <>
                      <div className="font-normal text-o-form-label-color mainhead flex justify-between items-center pl-5 pr-1 h-10">
                        <h1 id="color">Bodysites Overview</h1>
                        <div className="closeIconContainer" onClick={hideForm}>
                          {/* <IconButton
                                                    color="warning"
                                                    aria-label="upload picture"
                                                    component="span"
                                                >
                                                    <CloseIcon />
                                                </IconButton> */}
                        </div>
                      </div>
                      <form autoComplete="off">
                        <div className=" pl-5 mt-5 OrgNameContainer ">
                          <h3
                            className="text-o-form-label-color font-normal "
                            id="color"
                          >
                            Bodysite Name
                            <span style={{ color: "red" }}> *</span>
                          </h3>
                          <TextField
                            onKeyDown={(e) => {
                              e.key === "Enter" && e.preventDefault();
                            }}
                            onFocus={(e) => resetErrorHandler(e)}
                            autoComplete="OFF"
                            error={  bodySiteErrors.bodySiteNameError === true || bodySiteErrors.bodySiteNamenumerror === true}
                           
                            helperText={
                              bodySiteErrors.bodySiteNameError
                                ? "Valid (name) Required !"
                                :
                                  
                              bodySiteErrors.bodySiteNamenumerror
                                ? "Only (numbers) should not allowed !"
                                : ""
                            }

                            onChange={(e) => handleInputsChange(e)}
                            name="bodysiteName"
                            value={bodySiteFormData.bodysiteName}
                            style={{ width: "360px" }}
                            id="standard-basic"
                            label=""
                            variant="standard"
                          />


                        </div>
                        <div className="mt-5 pl-5 OrgNameContainer ">
                          <h3
                            className="font-normal text-o-form-label-color "
                            id="color"
                          >
                            System<span style={{ color: "red" }}> *</span>
                          </h3>
                          <Autocomplete
                            data-testid="partof"
                            value={bodySiteFormData.bodysiteSystemValue}
                            onKeyDown={(e) => {
                              e.key === "Enter" && e.preventDefault();
                            }}
                            name="bodysiteSystemValue"
                            autoHighlight={true}
                            onChange={(e, v) =>
                              handleDropDownChange("bodysiteSystemValue", v)
                            }
                            getOptionLabel={(option) =>
                              option ? option.name : ""
                            }
                            id="loc-list-option"
                            options={bodysiteSystem && bodysiteSystem}
                            sx={{ width: 360 }}
                            disabled={isFormUpdate}
                            renderInput={(params) => (
                              <TextField
                                variant="standard"
                                {...params}
                                label=""
                                onFocus={(e) => resetErrorHandler(e)}
                                autoComplete="OFF"
                                error={
                                  bodySiteErrors.bodySiteSystemError === true
                                }
                                helperText={
                                  bodySiteErrors.bodySiteSystemError
                                    ? "Valid (system) Required !"
                                    : ""
                                }
                                disabled={isFormUpdate}
                              />
                            )}
                          />
                        </div>
                        <div className=" pl-5 mt-5 OrgNameContainer ">
                          <h3
                            className="font-normal text-o-form-label-color m-r-f"
                            id="color"
                          >
                            Code
                            <span style={{ color: "red" }}> *</span>
                          </h3>
                          <TextField
                            onKeyDown={(e) => {
                              e.key === "Enter" && e.preventDefault();
                            }}
                            onFocus={(e) => resetErrorHandler(e)}
                            autoComplete="OFF"
                            error={bodySiteErrors.bodySiteCodeError === true}
                            helperText={
                              bodySiteErrors.bodySiteCodeError
                                ? "Valid (Code) Required !"
                                : ""
                            }
                            onChange={(e) => handleInputsChange(e)}
                            name="bodysiteCode"
                            value={bodySiteFormData.bodysiteCode}
                            style={{ width: "360px" }}
                            id="standard-basic"
                            label=""
                            variant="standard"
                            disabled={isFormUpdate}
                          />
                        </div>

                        <div className=" pl-5 mt-5 OrgNameContainer ">
                          <h3
                            className="font-normal text-o-form-label-color m-r-f"
                            id="color"
                          >
                            Description
                          </h3>
                          <TextField
                            rows={3}
                            multiline
                            //   onFocus={(e) => resetErrorHandler(e)}
                            autoComplete="OFF"
                            //   error={isLocError.isNameError === true}
                            //   helperText={
                            //     isLocError.isNameError
                            //       ? "Valid (Name) Required !"
                            //       : ""
                            //   }
                            onChange={(e) => handleInputsChange(e)}
                            name="bodysiteDesc"
                            value={bodySiteFormData.bodysiteDesc}
                            style={{ width: "360px" }}
                            id="standard-basic"
                            label=""
                            variant="outlined"
                          />
                        </div>
                        {isFormUpdate ? (
                          <div className="mt-5 pl-5 OrgNameContainer ">
                            <h3 className="font-normal text-o-form-label-color">
                              Status
                            </h3>
                            <Autocomplete
                              autoHighlight={true}
                              value={status}
                              onChange={(e, v) => handleStatusChange(v)}
                              getOptionLabel={(option) =>
                                option ? option : ""
                              }
                              id="city-list-option"
                              onKeyDown={(e) => {
                                e.key === "Enter" && e.preventDefault();
                              }}
                              options={statuslist ? statuslist : []}
                              sx={{ width: 360 }}
                              renderInput={(params) => (
                                <TextField
                                  variant="standard"
                                  {...params}
                                  label=""
                                />
                              )}
                            />
                          </div>
                        ) : null}
                        <div className="mt-9 flex justify-center items-center  towButtonContainer h-40">
                          <Button
                            onClick={hideForm}
                            type="submit"
                            variant="contained"
                            disableElevation
                            style={{
                              marginRight: "10px",
                              borderColor: "#0F4391",
                              width: "99px",
                              height: "39px",
                              borderRadius: "9999px",
                              backgroundColor: "#E8FAFF",
                              color: "#0F4391",
                              border: "1px solid #0F4391",
                              marginBottom: "100px",
                            }}
                          >
                            CANCEL
                          </Button>
                          <Button
                            variant="contained"
                            disableElevation
                            onClick={submitBodySitesForm}
                            style={{
                              borderColor: "#0F4391",
                              width: "99px",
                              height: "39px",
                              borderRadius: "9999px",
                              backgroundColor: "#E8FAFF",
                              color: "#0F4391",
                              border: "1px solid #0F4391",
                              marginBottom: "100px",
                            }}
                          >
                            SAVE
                          </Button>
                        </div>
                      </form>
                    </>
                  </div>
                </div>
              </Grid>
            ) : (
              ""
            )}
          </Grid>
        </div>
      )}
      <AdminCookieConsentBanner />
    </div>
  );
};

export default BodySites;
