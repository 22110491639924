import { useState, useEffect } from "react";
import { Link, NavLink, Navigate ,useNavigate} from "react-router-dom";
import KarkinosLogo from "../../images/karkinoslogo1.png";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
// import validator from 'validator';
import { makeStyles } from '@mui/styles';
import useCustomHook from "./authx";
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import { useDispatch } from "react-redux";
import { checkIfAdminLoggedIn, checkRoleAccess } from "../../features/adminLogged";
import CryptoJS from "crypto-js";
import { Grid, Input, ThemeProvider } from "@mui/material";
import { alpha, withStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useAdminLogInMutation, useGetByConstantMutation } from "../../services/adminLogIn";
import Autocomplete from "@mui/material/Autocomplete";

const CssTextField = withStyles({
  root: {
    '& label': {
      color: '#8A8A8A'
    },
    '& label.Mui-focused': {
      color: 'white',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#2196F3',
    },

    '& .MuiInput-underline:before': {
      borderBottomColor: '#DBDADA',
    },

    '& .MuiInput-underline:hover:before': {
      borderBottomColor: '#DBDADA !important', // Solid underline on hover
    },




  },
})(TextField);


const useStyles = makeStyles({
  button: {
    "&.active": {
      backgroundColor: 'black',
    },
  },


});


const Login = () => {

  const classes = useStyles();
  const [logvalue, setLogValue] = useState(false);
  const { userInput, resetErrorMsg, handleLoginForm, userSelectProfile, isError, userDetails, adminLogInResponse, isInputEmpty
  } = useCustomHook();
  const [getByConstant] = useGetByConstantMutation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const profileList = ["KDS", "KCLOUD"];





  const saveEAdminDataToLocalStorage = () => {

    try {
      const eId = CryptoJS.AES.encrypt(adminLogInResponse.data.user.userId.toString(), process.env.REACT_APP_SECX_ISKEY.toString());
      const eData = CryptoJS.AES.encrypt(JSON.stringify(adminLogInResponse.data.user), eId.toString());

      sessionStorage.setItem("muik", eId && eId);
      sessionStorage.setItem("BxqArt", eData && eData);


    } catch (e) {
      // console.log(e);
    }


  }


  const storesToken = async () => {
    try {
      const isLogged = adminLogInResponse.isSuccess;

      if (isLogged) {
        const refreshToken = adminLogInResponse.data.refreshToken;
        const accessToken = adminLogInResponse.data.accessToken;
        // setting refresh and access token in local storage
        sessionStorage.setItem("zExT1_a", accessToken);
        sessionStorage.setItem("e_Aophg", refreshToken);
        sessionStorage.setItem("resetTokenFlag", false);
        // console.log(adminLogInResponse?.data?.user?.roles)
        let res = await getByConstant("role_access1")
        let data = {}
        // let finsalresult = {}
        // res?.data.forEach(element => {
          for (const key of Object.keys(res?.data)) {
            if (res?.data[key].filter(arr1Item => adminLogInResponse?.data?.user?.roles.includes(arr1Item)).length != 0) {
              data[key] = true
            }
            else{
              data[key] = false
            }
          }
          //  finsalresult.push(data)
        // });
        dispatch(checkRoleAccess(data));
       
        dispatch(checkIfAdminLoggedIn(true));





        sessionStorage.setItem('access', JSON.stringify(data)); 
        let path = `/dashboard`; 
        navigate(path);
        //  window.location.reload();

      }
    } catch (e) {
      console.log("error of local strorage = " + e);

    }
  }

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [values, setValues] = useState({
    amount: '',
    password: '',
    weight: '',
    weightRange: '',
    showPassword: false,
  });
  useEffect(() => {

    let count = 0
    storesToken();
    saveEAdminDataToLocalStorage();
    if (adminLogInResponse.isSuccess === true) {
      setLogValue(true)
      // adminLogInResponse.data.user.roles.forEach(element => {
        // if (element === "admin-portal-manager" || element === "command_center") {
        //   count = 1
        
        // }
        // if (count === 0) {
        //   setLogValue(false)
        // }
      // });
    }
    if(adminLogInResponse.isError) {
      setLogValue(false);
    }

  }, [adminLogInResponse.isSuccess])
console.log(adminLogInResponse)


  return (
    <>
      <Grid className=" content">
        <div className="">
          <div className=" mx-auto hus w-6/12  h-14">
            <figure>
              <img
                className="imgcontainer"
                src={KarkinosLogo}
                alt="karkinos Logo"
              />
            </figure>
            <p className="contentlogo m-r-f">
              A PURPOSE DRIVEN ONCOLOGY PLATFORM
            </p>
          </div>

          <div className="marginh ">
            <div className="">
              <span className="signin m-r-f">Sign In</span>
            </div>

            <div
              className={
                adminLogInResponse.isError ||
                (adminLogInResponse.isSuccess && logvalue === false)
                  ? " signcontainererror w-mywidth mwidth sm:w-3/4 mx-auto marginh1 border border-solid border-bordercus rounded-myrounded"
                  : " signcontainer w-mywidth mwidth sm:w-3/4 mx-auto marginh1 border border-solid border-bordercus rounded-myrounded"
              }
            >
              <form
                data-testid="onSubmit"
                onSubmit={(e) => handleLoginForm(e)}
                autoComplete="off"
              >
                <div className="emailcontainer  m-4 mb-2 p-4 h-16 formsub">
                  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                    {/* <AccountCircle sx={{ color: 'action.active', mr: 1, my: 0.5 }} /> */}
                    <TextField
                      inputProps={{
                        "data-testid": "username",
                        style: { color: "#8A8A8A" },
                      }}
                      InputLabelProps={{
                        style: {
                          fontFamily: "MontserratRegular",
                          fontSize: "14px",
                        },
                      }}
                      onFocus={(e) => resetErrorMsg(e)}
                      error={isError.userEmailError === true}
                      helperText={
                        isError.userEmailError ? "Valid Email is Required!" : ""
                      }
                      onChange={(e) => userInput(e)}
                      name="userName"
                      value={userDetails.userName}
                      autoComplete="off"
                      type="text"
                      id="userName"
                      placeholder="Enter Email ID"
                      variant="standard"
                      style={{ width: "100%" }}
                    />
                  </Box>
                </div>

                <div className="emailcontainer  ml-4 mr-4 mt-2 p-4  h-16">
                  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                    {/* <VisibilityOffIcon  sx={{ color: 'action.active', mr: 1, my: 0.5 }} /> */}
                    {/* <TextField 
                    InputLabelProps={{ style: { fontFamily: 'MontserratRegular', fontSize: '14px' } }} 
                    onFocus={(e) => resetErrorMsg(e)} error={isError.userPasswordError === true} 
                    helperText={isError.userPasswordError ? "Password cannot be Blank" : ""}
                     onChange={(e) => userInput(e)} name="password" value={userDetails.password} 
                     autoComplete="off" type="password" id="userPassword" placeholder="Password"
                      variant="standard" style={{ width: '100%' }} /> */}
                    <Input
                      inputProps={{
                        "data-testid": "password",
                        style: { color: "#8A8A8A" },
                      }}
                      InputLabelProps={{
                        style: {
                          fontFamily: "MontserratRegular",
                          fontSize: "14px",
                        },
                      }}
                      onFocus={(e) => resetErrorMsg(e)}
                      error={isError.userPasswordError === true}
                      helperText={
                        isError.userPasswordError
                          ? "Password cannot be Blank"
                          : ""
                      }
                      type={values.showPassword ? "text" : "password"}
                      name="password"
                      value={userDetails.password}
                      autoComplete="off"
                      id="userPassword"
                      placeholder="Password"
                      variant="standard"
                      style={{ width: "100%" }}
                      onChange={(e) => userInput(e)}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {values.showPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      label=""
                    />
                  </Box>
                </div>
                <div className="emailcontainer ml-4 mr-4 mt-2 p-4 h-16">
                  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                    <Autocomplete
                      autoHighlight={true}
                      value={userDetails.profileName}
                      name="profileName"
                      onChange={(e, v) => userSelectProfile(v)}
                      getOptionLabel={(option) => (option ? option : "")}
                      id="city-list-option"
                      onKeyDown={(e) => {
                        e.key === "Enter" && e.preventDefault();
                      }}
                      options={profileList ? profileList : []}
                      sx={{ width: 360 }}
                      renderInput={(params) => (
                        <TextField variant="standard" {...params} label="" />
                      )}
                    />
                  </Box>
                </div>

                <div className="progressBar justify-content-center container pb-3 flex justify-center items-center">
                  {adminLogInResponse?.isError ? (
                    <span
                      style={{ color: "red", fontSize: "14px" }}
                      className="m-r-f"
                    >
                      {adminLogInResponse?.error?.data?.statusCode == 2100
                        ? "Please check your Email ID or Password and try again"
                        : adminLogInResponse?.error?.data?.statusCode == 21055
                        ? "You are blocked from the system due to inactivity. Please contact your administrator."
                        : adminLogInResponse?.error?.data?.message}
                    </span>
                  ) : adminLogInResponse.isLoading ? (
                    <CircularProgress />
                  ) : (adminLogInResponse.isError && !logvalue) ? (
                    <span
                      style={{ color: "red", fontSize: "14px" }}
                      className="m-r-f"
                    >
                      You don't have permission to proceed. Please contact
                      administrator.
                    </span>
                  ) : (
                    ""
                  )}
                </div>

                <div className="buttoncontainer pb-1 w-1/2 mx-auto rounded-full  h-11 signbutton">
                  <Button
                    type="submit"
                    variant="contained"
                    disableElevation
                    style={{
                      border: "1px solid #0F4391",
                      width: "181px",
                      height: "100%",
                      borderRadius: "9999px",
                      backgroundColor: ` ${
                        isInputEmpty ? "#E8FAFF" : "#0F4391"
                      }`,
                      color: ` ${isInputEmpty ? "#0F4391" : "#FFFFFF"}`,
                    }}
                  >
                    <span className={`m-r-f`} style={{ fontSize: "18px" }}>
                      SIGN IN
                    </span>
                  </Button>
                </div>
              </form>
            </div>

            <div
              className="linkcontainer text-center hover:text-blue-500  mx-auto  w-1/2 "
              style={{ marginTop: "20px" }}
            >
              <NavLink to="/forgot-password">
                <span
                  className={`m-r-f`}
                  style={{
                    fontSize: "14px",
                    fontWeight: "400px",
                    color: "#333333",
                    textDecoration: "underline",
                    textDecorationThickness: "1px",
                    textUnderlineOffset: "1px",
                  }}
                >
                  Forgot Password?
                </span>
              </NavLink>
            </div>
          </div>
        </div>
      </Grid>
      {/* root-container-for-form-end */}
    </>
  );
}

export default Login;