import { useState, useEffect, useRef } from "react";
import "./Mainhome.css";
import filter from "../../../images/filter.png";
import AdminHome from "../AdminHome";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import Button from "@mui/material/Button";
import useOrgHook from "../orga/OrgCusHook";
import OrgTable from "./organizationlist";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  useGetActiveOrgValueMutation,
  useSearchOrganizationMutation,
  useGetActiveStateValueMutation,
  useGetActiveDistrictValueMutation,
  useGetAllOrgValueMutation,
  useGetpatientpaginationMutation,
  useFilterOrganizationDataMutation,
  useGetCountryListMutation
} from "../../../services/adminLogIn";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import { createStyles, makeStyles } from "@mui/styles";
import Autocomplete from "@mui/material/Autocomplete";
import "./Mainhome.css";
import { Grid } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import ErrorBoundary from "../../../logger/errorboundrycomp";
import { LoggerFunction } from "../../../logger/logger";
import Breadcrums from "../../global/breadcrums/Breadcrums";
import AdminCookieConsentBanner from "../home/AdminCookieConsentBanner";
import Tooltip from "@mui/material/Tooltip";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const useStyles = makeStyles((theme) => ({
  menuPaper: {
    maxHeight: 200,
  },
}));

// /organization/active/{active}
// /organization/all
// organization/{{organization-id}}

const Organization = (props) => {
  const classes = useStyles();
  const [getFilterList] = useFilterOrganizationDataMutation();
  const [getAllValFun] = useGetActiveOrgValueMutation();
  const [getActiveValFun, { isLoading, isSuccess, isError, data }] =
    useGetAllOrgValueMutation();
  const [getDistricts] = useGetActiveDistrictValueMutation();
  const [getCountryList] = useGetCountryListMutation();
  const [getSearchListFun, { ...searchList }] = useSearchOrganizationMutation();
  const [getpatientpaginationfunc] = useGetpatientpaginationMutation();
  const [getStateValFun, { isLoading1, isSuccess1, isError1, ...data1 }] =
    useGetActiveStateValueMutation();
  const [currentPage, setCurrentPage] = useState(1);
  const [isNewData, setIsNewData] = useState(true);
  const [dataactive, setdataactive] = useState({});
  const [access, setAccess] = useState(
    JSON.parse(sessionStorage.getItem("access"))
  );

  // const [getDistrictValFun ,  {isLoading2 , isSuccess2 , isError2 ,...districtlist}] =  useGetActiveDistrictValueMutation();

  const {
    orgSubmitHandler,
    whenInputsChange,
    orgState,
    dropOrgState,
    orgAllTextFieldState,
    isOrgError,
    halndleClickonProceed,
    proccedVal,
    handleDropDownChange,
    createOrgRes,
    updateOrgRes,
    districtlist,
    showerror,
    setErrorMessage,
    handleClose,
    handleOpen,
    open,
    resetErrorHandler,
    setOrgTextFieldState,
    setDropOrgState,
    getDistrictValFun1,
  } = useOrgHook();
  const [filterData, setFilterData] = useState({
    organizationName: "",
    level:"",
    partOf: "",
    state: "",
    city: "",
    status: "",
    page: 0,
    size: 20,
  });

  const callActiveListApiFunct = async (data) => {
    const res = await getAllValFun();
    if (res?.data?.content) {
      setdataactive(res);
    }
  };

  const callListApiFunct = async (data) => {
    if (data?.page === 0 || data === undefined) {
      setIsNewData(true);
    }
    const res = await getFilterList(data ? data : filterData);
    setOrgList(res?.data?.content);
    setTotalPage(res?.data?.totalPages);
    setTotalPageCalculate({
      numberOfElements: res?.data?.numberOfElements,
      number: res?.data?.number,
      totalElements: res?.data?.totalElements,
    });
  };

  // useEffect(() => {
  //   if (data && data?.content) {
  //     setOrgList([...data.content])
  //     setTotalPage(data?.totalPages)
  //     setTotalPageCalculate({ numberOfElements: data?.numberOfElements, number: data?.number, totalElements: data?.totalElements })
  //   }
  // }, [data && data])

  const callFilterListApiFunct = async () => {
    setsearchVal(filterData.organizationName);
    if (filterData.page === 0) {
      setIsNewData(true);
    }
    const res = await getFilterList(filterData);
    setOrgList(res?.data?.content);
    setTotalPage(res?.data?.totalPages);
    setTotalPageCalculate({
      numberOfElements: res?.data?.numberOfElements,
      number: res?.data?.number,
      totalElements: res?.data?.totalElements,
    });
    setOpenFilterModal(false);
    setIsSearchEvent(true);
  };
  const handleFilterValueChange = (name, value) => {
    if (name === "partOf") {
      setDropPartOf(value);
      setFilterData((prev) => ({ ...prev, [name]: value?.id }));
    }
    if (name === "state") {
      getDistrictValFun1(value);
      setDropState(value);
      setFilterData((prev) => ({ ...prev, [name]: value?.name }));
    }
    if (name === "city") {
      setDropCity(value);
      setFilterData((prev) => ({ ...prev, [name]: value?.name }));
    }
    if (name === "status") {
      setFilterData((prev) => ({ ...prev, [name]: value }));
    }
  };
  const [dropCity, setDropCity] = useState({});
  const [dropState, setDropState] = useState({});
  const [droppartOf, setDropPartOf] = useState({});
  const [dropCountry, setDropCountry] = useState({});

  const [isFormVisible, setFormVisible] = useState({
    isOpen: false,
    isClose: true,
  });

  const [expand, setexpand] = useState({
    heading: "",
    icon: "",
    isSubListExpanded: false,
  });
  const [expand1, setexpand1] = useState({
    empty: true,
  });
  const [orgapageList, setorgapageList] = useState([]);
  const [orgaImport, setorgaImport] = useState();
  const [countryList, setCountryList] = useState([]);

  const getorgapageList = async (page) => {
    //let data = ({...filterData, ["page"]: page-1 });
    setCurrentPage(page);
    setIsNewData(false);
    let data = {
      ...filterData,
      ["page"]: page - 1,
      ["organizationName"]: serachVal,
    };
    callListApiFunct(data);
  };

  const filterStatusOptions = ["Active", "Inactive"];

  const [orgList, setOrgList] = useState({});
  const [statuslist] = useState(["Active", "Inactive"]);
  const [serachVal, setsearchVal] = useState("");
  const [totalPage, setTotalPage] = useState(1);
  const [calculateData, setTotalPageCalculate] = useState({
    number: "",
    numberOfElements: "",
    totalElements: "",
    showcount: "",
  });
  const [paginationdata, setPaginationData] = useState({
    string: "",
    page: 0,
  });

  const [isSearchEvent, setIsSearchEvent] = useState(false);
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [filterResults, setFilterResults] = useState();
  const [districts, setDistricts] = useState();
  const showFormActions = () => {
    if (access?.organisationview == true) {
      if (!isFormVisible.isOpen) {
        setFormVisible((prev) => ({
          ...prev,
          isOpen: (isFormVisible.isOpen = true),
          isFormVisible,
          isClose: (isFormVisible.isClose = false),
        }));
      }
    } else {
      toast(" No Permission", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        type: "error",
        closeOnClick: true,
        theme: "light",
      });
    }
  };

  const hideForm = () => {
    setOrgTextFieldState({});
    if (isFormVisible.isOpen) {
      setFormVisible((prev) => ({
        ...prev,
        isOpen: (isFormVisible.isOpen = false),
        isFormVisible,
        isClose: (isFormVisible.isClose = true),
      }));
      setOrgTextFieldState({
        is_update: false,
        name: "",
        level:"",
        id: "",
        address: "",
        postalCode: "",
        country: "",
        phoneNumber: "",
        active: "",
        emailId: "",
        state: {},
        city: {},
      });
      setDropOrgState({});
    }
  };

  const bredStyle = {
    fontSize: "12px",
  };

  const handleFilterModalOpen = () => {
    setOpenFilterModal(true);
  };
  const handleFilterModalClose = () => {
    const data = {
      organizationName: "",
      level:"",
      partOf: "",
      state: "",
      city: "",
      status: "",
      page: 0,
      size: 20,
    };
    callListApiFunct(filterData);
    setOpenFilterModal(false);
  };

  const handleFilterInputChange = (e) => {
    setFilterData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  const handleFilterInputlevelChange = (e) => {
    setFilterData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  const resetFilterData = () => {
    setFilterData({
      organizationName: "",
      level:"",
      partOf: "",
      state: "",
      city: "",
      status: "",
      page: 0,
      size: 20,
    });
    setDropPartOf({});
    setDropState({});
    setDropCity({});
    setDropCountry({});
    setsearchVal("");
  };

  const handleDropDownSearch = (e, value) => {
    callListDropdownData(value);
  };
  const callListDropdownData = async (value) => {
    const payload = {
      organizationName: value,
      level:"",
      partOf: "",
      state: "",
      city: "",
      status: "Active",
      page: 0,
      size: 100,
    };
    const res = await getFilterList(payload);
    if (res?.data?.content) setdataactive(res);
  };
 const callListCountryDropdownData = async (value) => {

      const countryListData = await getCountryList();
      setCountryList(countryListData?.data?.content);
    };
  useEffect(async () => {
    callListApiFunct();
    callListDropdownData();
    getStateValFun();
    callActiveListApiFunct();
    callListCountryDropdownData();
    // var data = await LoggerFunction()

    if (
      orgAllTextFieldState.state &&
      orgAllTextFieldState.state.name != undefined
    ) {
      getDistrictValFun1(orgAllTextFieldState.state);
    }
    //  getDistrictValFun();
  }, []);

  useEffect(() => {
    if (orgAllTextFieldState.id != "") {
      let data = {};
      data = orgAllTextFieldState.state;
      getDistrictValFun1(data);
    }
  }, [orgAllTextFieldState.id]);
  useEffect(() => {
    if (createOrgRes.isSuccess === true) {
      setOrgTextFieldState({
        is_update: false,
        name: "",
        level:"",
        id: "",
        address: "",
        postalCode: "",
        country: "",
        phoneNumber: "",
        emailId: "",
        state: {},
        city: {},
      });
      hideForm();
      setDropOrgState({});
      callActiveListApiFunct();
      callListDropdownData();
      callListApiFunct();
    }
  }, [createOrgRes.isSuccess === true]);

  useEffect(() => {
    if (updateOrgRes.isSuccess === true) {
      callListApiFunct();
      setIsNewData(true);
      callActiveListApiFunct();
      callListDropdownData();
      hideForm();
    }
  }, [updateOrgRes.isSuccess === true]);

  // useEffect(() => {
  //   if (serachVal.length > 0 && searchList && searchList.data) {
  //     setOrgList(searchList?.data?.content)
  //     setTotalPage(searchList?.data?.totalPages)
  //     setTotalPageCalculate({ numberOfElements: searchList.data?.numberOfElements, number: searchList.data?.number, totalElements: searchList.data?.totalElements })
  //   } else
  //     if (serachVal.length > 0 && searchList) {
  //       setOrgList([])
  //     }
  // }, [searchList && searchList.isSuccess])

  const onSearchevent = (value) => {
    setsearchVal(value);
    if (value.length > 0) {
      let data = { ...filterData, ["organizationName"]: value };
      callListApiFunct(data);
    }
    if (value.length === 0) {
      callListApiFunct();
      setIsNewData(true);
    }
  };

  return (
    <>
      <ErrorBoundary>
        <div className={props.expand1.empty === true ? "main1" : "main"}>
          <Breadcrums
            firsttab={"Categories"}
            secondtab="Administration"
            thirdtab="Organisation"
          ></Breadcrums>
          <ToastContainer />
          <Grid container mt={-2}>
            <Grid item xs={6}>
              <div
                className={
                  isFormVisible.isOpen
                    ? "leftcontainer rounded-lg"
                    : "leftcontainer1 rounded-lg"
                }
              >
                <Grid className=" p-7 ">
                  <TextField
                    label=""
                    onChange={(e) => onSearchevent(e.target.value)}
                    // id="outlined-start-adornment"
                    sx={{ m: 1, width: "25ch" }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchOutlinedIcon />
                        </InputAdornment>
                      ),
                      "data-testid": "content",
                    }}
                  />
                </Grid>
                <Grid className="editContainer rounded-radiusFourPix ">
                <Tooltip title={access?.organisationadd ? "" : "No Access"} arrow>
                  <Grid
                   onClick={access?.organisationadd ? showFormActions : null}
                    className={
                      isFormVisible.isOpen ? "addIconchange" : "addIcon"
                    }
                    data-testid="open"
                  >
                    <span className=" p-2 m-r-f ">Add</span>
                    <AddCircleOutlineIcon
                      style={{
                        fontSize: "14px",
                        color: "#545454",
                        marginBottom: "3px",
                        color: `${
                          isFormVisible.isOpen ? "#ffffff" : "#545454"
                        }`,
                      }}
                    />
                  </Grid>
                  </Tooltip>

                  {/* <Grid className="Addaction">
                    <span className="p-2 m-r-f">Actions</span>
                    <AddCircleOutlineIcon style={{ fontSize: '14px', color: '#545454', 'marginBottom': '3px' }} />

                  </Grid> */}

                  <Grid className="addIcon" onClick={handleFilterModalOpen}>
                    <span className="p-2 m-r-f">Filter</span>
                    <img
                      src={filter}
                      alt="filter.png"
                      style={{
                        position: "absolute",
                        left: "90%",
                        right: "20%",
                        top: "29%",
                        bottom: "92%",
                        width: "11px",
                        paddingTop: "3px",
                      }}
                    ></img>

                    {/* <FilterListIcon style={{ fontSize: '14px', color: '#545454', 'marginBottom': '3px' }} /> */}
                  </Grid>
                  <Modal
                    open={openFilterModal}
                    // onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box
                      sx={style}
                      style={{
                        width: "450px",
                        padding: "50px",
                        borderColor: "white",
                      }}
                    >
                      <Typography
                        className="filtermain"
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                        style={{
                          fontFamily: "MontserratRegular",
                          marginTop: "5px",
                        }}
                      >
                        Filter by
                      </Typography>

                      <div className="OrgNameContainer">
                        <h3
                          className="font-normal text-o-form-label-color"
                          id="Org"
                        >
                          Organisation name
                        </h3>
                        <TextField
                          onFocus={(e) => resetErrorHandler(e)}
                          autoComplete="OFF"
                          onChange={(e) => handleFilterInputChange(e)}
                          name="organizationName"
                          value={filterData.organizationName}
                          style={{ width: "360px" }}
                          id="standard-basic"
                          label=""
                          variant="standard"
                        />
                      </div>

                      <div className="mt-5 OrgNameContainer ">
                        <h3 className="font-normal text-o-form-label-color">
                          Part Of
                        </h3>
                        <Autocomplete
                          value={droppartOf}
                          data-testid="partof"
                          autoHighlight={true}
                          onChange={(e, v) =>
                            handleFilterValueChange("partOf", v)
                          }
                          onInputChange={(e, value) =>
                            handleDropDownSearch(e, value)
                          }
                          getOptionLabel={(option) =>
                            option.name ? option.name : ""
                          }
                          id="org-list-option"
                          options={
                            dataactive.data &&
                            dataactive.data.content &&
                            dataactive.data.content
                              ? dataactive.data.content
                              : []
                          }
                          sx={{ width: 360 }}
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          renderInput={(params) => (
                            <TextField
                              variant="standard"
                              error={isOrgError.isDropDownError}
                              {...params}
                              label=""
                              value={orgAllTextFieldState.partOf}
                            />
                          )}
                        />{" "}
                        <br />
                      </div>
                      <div className="OrgNameContainer">
                        <h3
                          className="font-normal text-o-form-label-color"
                          id="Org"
                        >
                         Level
                        </h3>
                        <TextField
                          onFocus={(e) => resetErrorHandler(e)}
                          autoComplete="OFF"
                          onChange={(e) => handleFilterInputlevelChange(e)}
                          name="level"
                          value={filterData.level}
                          style={{ width: "360px" }}
                          id="standard-basic"
                          label=""
                          variant="standard"
                        />
                      </div>

                      <div className="mt-5 OrgNameContainer ">
                        <h3 className="font-normal text-o-form-label-color">
                          State
                        </h3>
                        <Autocomplete
                          autoHighlight={true}
                          data-testid="state"
                          value={dropState}
                          onChange={(e, v) =>
                            handleFilterValueChange("state", v)
                          }
                          getOptionLabel={(option) =>
                            option.name ? option.name : ""
                          }
                          id="state-list-option"
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          options={data1 && data1.data ? data1.data : []}
                          sx={{ width: 360 }}
                          renderInput={(params) => (
                            <TextField
                              variant="standard"
                              error={isOrgError.isDropDownError}
                              {...params}
                              label=""
                            />
                          )}
                        />
                      </div>

                      <div className="mt-5 OrgNameContainer ">
                        <h3 className="font-normal text-o-form-label-color">
                          City
                        </h3>
                        <Autocomplete
                          data-testid="city"
                          autoHighlight={true}
                          value={dropCity}
                          onChange={(e, v) =>
                            handleFilterValueChange("city", v)
                          }
                          getOptionLabel={(option) =>
                            option.name ? option.name : ""
                          }
                          id="city-list-option"
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          options={
                            districtlist &&
                            districtlist.data &&
                            districtlist.data
                              ? districtlist.data
                              : []
                          }
                          sx={{ width: 360 }}
                          renderInput={(params) => (
                            <TextField
                              variant="standard"
                              error={isOrgError.isDropDownError}
                              {...params}
                              label=""
                            />
                          )}
                        />
                      </div>

                      <div className="mt-5 OrgNameContainer ">
                        <h3 className="font-normal text-o-form-label-color">
                          Status
                        </h3>
                        <Autocomplete
                          value={filterData.status}
                          autoHighlight={true}
                          disableClearable
                          onChange={(e, v) =>
                            handleFilterValueChange("status", v)
                          }
                          getOptionLabel={(option) => (option ? option : "")}
                          id="org-list-option"
                          options={filterStatusOptions}
                          sx={{ width: 360 }}
                          renderInput={(params) => (
                            <TextField
                              variant="standard"
                              onFocus={(e) => resetErrorHandler(e)}
                              autoComplete="OFF"
                              {...params}
                              label=""
                            />
                          )}
                        />
                      </div>

                      <Button
                        variant="contained"
                        disableElevation
                        onClick={handleFilterModalClose}
                        style={{
                          marginRight: "10px",
                          top: "30px",
                          borderColor: "#0F4391",
                          width: "99px",
                          height: "39px",
                          borderRadius: "9999px",
                          backgroundColor: "#E8FAFF",
                          color: "#0F4391",
                          border: "1px solid #0F4391",
                          marginBottom: "5px",
                        }}
                      >
                        CANCEL
                      </Button>

                      <Button
                        variant="contained"
                        disableElevation
                        onClick={resetFilterData}
                        style={{
                          marginRight: "10px",
                          top: "30px",
                          borderColor: "#0F4391",
                          width: "99px",
                          height: "39px",
                          borderRadius: "9999px",
                          backgroundColor: "#E8FAFF",
                          color: "#0F4391",
                          border: "1px solid #0F4391",
                          marginBottom: "5px",
                        }}
                      >
                        Reset
                      </Button>
                      <Button
                        variant="contained"
                        disableElevation
                        onClick={callFilterListApiFunct}
                        // onClick={handleFilterData}
                        style={{
                          top: "30px",
                          borderColor: "#0F4391",
                          width: "99px",
                          height: "39px",
                          borderRadius: "9999px",
                          backgroundColor: "#E8FAFF",
                          color: "#0F4391",
                          border: "1px solid #0F4391",
                          marginBottom: "5px",
                        }}
                      >
                        Search
                      </Button>
                    </Box>
                  </Modal>
                </Grid>

                <>
                  <OrgTable
                    orgList={orgList}
                    setTotalPage={setTotalPage}
                    totalPage={totalPage}
                    getorgapageList={getorgapageList}
                    showFormActions={showFormActions}
                    setErrorMessage={setErrorMessage}
                    setOrgTextFieldState={setOrgTextFieldState}
                    setDropOrgState={setDropOrgState}
                    calculateData={calculateData}
                    currentPage={isNewData ? 1 : currentPage}
                    organisationview={access?.organisationview}
                    organisationupdate={access?.organisationupdate}
                  />
                </>
              </div>
            </Grid>
            {/* <div data-testid="title">sandeep</div> */}
            {isFormVisible.isOpen ? (
              <Grid item xs={6}>
                <div className="rightcontainer rounded-lg">
                  <div className="overflow-y-auto o-form-container">
                    <Modal
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box sx={style}>
                        <Typography
                          id="modal-modal-title"
                          variant="h6"
                          component="h2"
                        >
                          <Alert severity="info">
                            {orgAllTextFieldState.active === "Active"
                              ? "Are you sure you want to reactivate  the organization ?"
                              : "Are you sure you want to Deactivate the organization ?"}
                          </Alert>
                        </Typography>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                          {orgAllTextFieldState.active === "Active"
                            ? "This activity will enable the organization and user will be able to access it across all products."
                            : "This activity disable the organization and user will not able to access it. You can reactivate the organization at anytime?"}

                          <div className="mt-10 flex justify-center items-center  towButtonContainer">
                            <Button
                              type="submit"
                              onClick={handleClose}
                              variant="contained"
                              disableElevation
                              style={{
                                marginRight: "10px",
                                borderColor: "#0F4391",
                                width: "99px",
                                height: "39px",
                                borderRadius: "9999px",
                                backgroundColor: "#E8FAFF",
                                color: "#0F4391",
                                border: "1px solid #0F4391",
                                marginBottom: "100px",
                              }}
                            >
                              CANCEL
                            </Button>

                            <Button
                              type="submit"
                              onClick={halndleClickonProceed}
                              variant="contained"
                              disableElevation
                              style={{
                                borderColor: "#0F4391",
                                width: "99px",
                                height: "39px",
                                borderRadius: "9999px",
                                backgroundColor: "#E8FAFF",
                                color: "#0F4391",
                                border: "1px solid #0F4391",
                                marginBottom: "100px",
                              }}
                            >
                              PROCEED
                            </Button>
                          </div>
                        </Typography>
                      </Box>
                    </Modal>
                    <ToastContainer />
                    <>
                      <div className="font-normal form-heading flex justify-between items-center pl-5 pr-1 h-10 mainhead">
                        <h1>Organisation Overview</h1>
                        {/* <div className="closeIconContainer" >
                            <IconButton onClick={hideForm} color="warning" aria-label="br picture" component="span">
                              <CloseIcon />
                            </IconButton>
                          </div> */}
                      </div>

                      <form autoComplete="off" onSubmit={orgSubmitHandler}>
                        <div className=" pl-5 OrgNameContainer">
                          <h3
                            className="font-normal text-o-form-label-color"
                            id="Org"
                          >
                            Organisation Name
                            <span style={{ color: "red" }}> *</span>
                          </h3>
                          <TextField
                            data-testid="name"
                            InputProps={
                              {
                                // "data-testid": "orgname"
                              }
                            }
                            onFocus={(e) => resetErrorHandler(e)}
                            label=""
                            onKeyDown={(e) => {
                              e.key === "Enter" && e.preventDefault();
                            }}
                            autoComplete="OFF"
                            error={isOrgError.isNameError === true}
                            helperText={
                              isOrgError.isNameError
                                ? "Valid (Name) Required !"
                                : ""
                            }
                            onChange={(e) => whenInputsChange(e)}
                            name="name"
                            value={orgAllTextFieldState.name}
                            style={{ width: "360px" }}
                            id="orgname"
                            variant="standard"
                          />
                        </div>

                        <div className="mt-5 pl-5 OrgNameContainer ">
                          <h3 className="font-normal text-o-form-label-color">
                            Part Of
                          </h3>
                          <Autocomplete
                            value={dropOrgState}
                            data-testid="partof"
                            autoHighlight={true}
                            onChange={(e, v) =>
                              handleDropDownChange("partof", v)
                            }
                            onInputChange={(e, value) =>
                              handleDropDownSearch(e, value)
                            }
                            getOptionLabel={(option) =>
                              option.name ? option.name : ""
                            }
                            id="org-list-option"
                            options={
                              dataactive.data &&
                              dataactive.data.content &&
                              dataactive.data.content
                                ? dataactive.data.content
                                : []
                            }
                            sx={{ width: 360 }}
                            onKeyDown={(e) => {
                              e.key === "Enter" && e.preventDefault();
                            }}
                            renderInput={(params) => (
                              <TextField
                                variant="standard"
                                error={isOrgError.isDropDownError}
                                {...params}
                                label=""
                                value={orgAllTextFieldState.partOf}
                              />
                            )}
                          />{" "}
                          <br />
                        </div>
                        <div className=" pl-5 OrgNameContainer">
                          <h3
                            className="font-normal text-o-form-label-color"
                            id="Org"
                          >
                           Level
                            
                          </h3>
                          <TextField
                            data-testid="level"
                            InputProps={
                              {
                                // "data-testid": "orgname"
                              }
                            }
                            onFocus={(e) => resetErrorHandler(e)}
                            label=""
                            onKeyDown={(e) => {
                              e.key === "Enter" && e.preventDefault();
                            }}
                            autoComplete="OFF"
                           
                            onChange={(e) => whenInputsChange(e)}
                            name="level"
                            value={orgAllTextFieldState.level}
                            style={{ width: "360px" }}
                            id="orgname"
                            variant="standard"
                          />
                        </div>
                        <div className="mt-5 pl-5 OrgNameContainer ">
                                                  <h3 className="font-normal text-o-form-label-color">
                                                    Country
                                                  </h3>
                                                  <Autocomplete
                                                    autoHighlight={true}
                                                    data-testid="country"
                                                    value={
                                                      orgAllTextFieldState &&
                                                      orgAllTextFieldState.country &&
                                                      orgAllTextFieldState.country
                                                    }
                                                    onChange={(e, v) =>
                                                      handleDropDownChange("country", v)
                                                    }
                                                    getOptionLabel={(option) =>
                                                      option.country ? option.country : ""
                                                    }
                                                    id="state-list-option"
                                                    onKeyDown={(e) => {
                                                      e.key === "Enter" && e.preventDefault();
                                                    }}
                                                    options={countryList == null ?[]:countryList}
                                                    sx={{ width: 360 }}
                                                    renderInput={(params) => (
                                                      <TextField
                                                        variant="standard"
                                                        error={isOrgError.isDropDownError}
                                                        {...params}
                                                        label=""
                                                      />
                                                    )}
                                                  />
                                                </div>
                        <div className="mt-5 pl-5 OrgNameContainer ">
                          <h3 className="font-normal text-o-form-label-color">
                            State
                          </h3>
                          <Autocomplete
                            autoHighlight={true}
                            data-testid="state"
                            value={
                              orgAllTextFieldState &&
                              orgAllTextFieldState.state &&
                              orgAllTextFieldState.state
                            }
                            onChange={(e, v) =>
                              handleDropDownChange("state", v)
                            }
                            getOptionLabel={(option) =>
                              option.name ? option.name : ""
                            }
                            id="state-list-option"
                            onKeyDown={(e) => {
                              e.key === "Enter" && e.preventDefault();
                            }}
                            options={data1 && data1.data ? data1.data : []}
                            sx={{ width: 360 }}
                            renderInput={(params) => (
                              <TextField
                                variant="standard"
                                error={isOrgError.isDropDownError}
                                {...params}
                                label=""
                              />
                            )}
                          />
                        </div>

                        <div className="mt-5 pl-5 OrgNameContainer ">
                          <h3 className="font-normal text-o-form-label-color">
                            City
                          </h3>
                          <Autocomplete
                            data-testid="city"
                            autoHighlight={true}
                            value={
                              orgAllTextFieldState &&
                              orgAllTextFieldState.city &&
                              orgAllTextFieldState.city
                            }
                            onChange={(e, v) => handleDropDownChange("city", v)}
                            getOptionLabel={(option) =>
                              option.name ? option.name : ""
                            }
                            id="city-list-option"
                            onKeyDown={(e) => {
                              e.key === "Enter" && e.preventDefault();
                            }}
                            options={
                              districtlist &&
                              districtlist.data &&
                              districtlist.data
                                ? districtlist.data
                                : []
                            }
                            sx={{ width: 360 }}
                            renderInput={(params) => (
                              <TextField
                                variant="standard"
                                error={isOrgError.isDropDownError}
                                {...params}
                                label=""
                              />
                            )}
                          />
                        </div>

                        <div className="mt-5 pl-5 OrgPostalCode">
                          <h3 className="font-normal text-o-form-label-color">
                            Postal Code
                          </h3>
                          <TextField
                            variant="standard"
                            onKeyDown={(e) => {
                              e.key === "Enter" && e.preventDefault();
                            }}
                            maxLength="8"
                            onFocus={(e) => resetErrorHandler(e)}
                            autoComplete="OFF"
                            error={isOrgError.isPostalCodeError === true}
                            helperText={
                              isOrgError.isPostalCodeError
                                ? "Valid (Postal Code) Required !"
                                : ""
                            }
                            onChange={(e) => whenInputsChange(e)}
                            name="postalCode"
                            data-testid="postalCode"
                            value={orgAllTextFieldState.postalCode}
                            style={{ width: "360px" }}
                            id="postalCode"
                            label=""
                          />
                        </div>

                        <div className="mt-5 pl-5 OrgNameContainer ">
                          <h3 className="font-normal text-o-form-label-color">
                            Phone Number
                          </h3>
                          <TextField
                            variant="standard"
                            onKeyDown={(e) => {
                              e.key === "Enter" && e.preventDefault();
                            }}
                            onFocus={(e) => resetErrorHandler(e)}
                            autoComplete="OFF"
                            error={isOrgError.isPhoneError === true}
                            helperText={
                              isOrgError.isPhoneError
                                ? "Valid (PhoneNumber) Required !"
                                : ""
                            }
                            onChange={(e) => whenInputsChange(e)}
                            name="phoneNumber"
                            data-testid="phoneNumber"
                            value={orgAllTextFieldState.phoneNumber}
                            style={{ width: "360px" }}
                            id="standard-basic"
                            label=""
                          />
                        </div>

                        <div className="mt-5 pl-5 OrgNameContainer ">
                          <h3 className="font-normal text-o-form-label-color">
                            Email ID
                          </h3>
                          <TextField
                            variant="standard"
                            onKeyDown={(e) => {
                              e.key === "Enter" && e.preventDefault();
                            }}
                            onFocus={(e) => resetErrorHandler(e)}
                            autoComplete="OFF"
                            error={isOrgError.isEmailError === true}
                            helperText={
                              isOrgError.isEmailError
                                ? "Valid (Email) Required !"
                                : ""
                            }
                            onChange={(e) => whenInputsChange(e)}
                            name="emailId"
                            value={orgAllTextFieldState.emailId}
                            style={{ width: "360px" }}
                            id="standard-basic"
                            label=""
                            data-testid="emailId"
                          />
                        </div>
                        {orgAllTextFieldState.is_update === true ? (
                          <div className="mt-5 pl-5 OrgNameContainer ">
                            <h3 className="font-normal text-o-form-label-color">
                              Status
                            </h3>
                            <Autocomplete
                              autoHighlight={true}
                              value={
                                orgAllTextFieldState &&
                                orgAllTextFieldState.active === true
                                  ? "Active"
                                  : "Inactive"
                              }
                              onChange={(e, v) =>
                                handleDropDownChange("active", v)
                              }
                              getOptionLabel={(option) =>
                                option ? option : ""
                              }
                              id="city-list-option"
                              onKeyDown={(e) => {
                                e.key === "Enter" && e.preventDefault();
                              }}
                              options={statuslist ? statuslist : []}
                              sx={{ width: 360 }}
                              renderInput={(params) => (
                                <TextField
                                  data-testid="active"
                                  variant="standard"
                                  error={isOrgError.isDropDownError}
                                  {...params}
                                  label=""
                                />
                              )}
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        {/* <h5 className="text-o-form-label-color text-xs pl-5 mt-7"> Last Update : 13 Jun , 2021</h5> */}

                        <div className="mt-5 pl-5 OrgNameContainer ">
                          <div className="progressContainer mt-5 flex justify-center items-center">
                            {(createOrgRes.isError || updateOrgRes.isError) &&
                            showerror === true ? (
                              <Alert severity="error" color="warning">
                                {createOrgRes.error &&
                                createOrgRes.error.data &&
                                orgAllTextFieldState.is_update === false
                                  ? createOrgRes.error.data.message
                                  : updateOrgRes.error &&
                                    updateOrgRes.error.data &&
                                    updateOrgRes.error.data.message}
                              </Alert>
                            ) : createOrgRes.isLoading ||
                              updateOrgRes.isLoading ? (
                              <CircularProgress />
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="mt-9 flex justify-center items-center  towButtonContainer h-40">
                            <Button
                              onClick={hideForm}
                              type="submit"
                              variant="contained"
                              disableElevation
                              style={{
                                marginRight: "10px",
                                borderColor: "#0F4391",
                                width: "99px",
                                height: "39px",
                                borderRadius: "9999px",
                                backgroundColor: "#E8FAFF",
                                color: "#0F4391",
                                border: "1px solid #0F4391",
                                marginBottom: "100px",
                              }}
                            >
                              CANCEL
                            </Button>

                            <Button
                              type="submit"
                              variant="contained"
                              disableElevation
                              style={{
                                borderColor: "#0F4391",
                                width: "99px",
                                height: "39px",
                                borderRadius: "9999px",
                                backgroundColor: "#E8FAFF",
                                color: "#0F4391",
                                border: "1px solid #0F4391",
                                marginBottom: "100px",
                              }}
                            >
                              SAVE
                            </Button>
                          </div>
                        </div>
                      </form>
                    </>
                  </div>
                  <div></div>
                </div>
              </Grid>
            ) : (
              ""
            )}
          </Grid>
        </div>
      </ErrorBoundary>
      <AdminCookieConsentBanner />
    </>
  );
};

export default Organization;
