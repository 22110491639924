
import {useAdminLogoutMutation} from "../../services/adminLogIn";
import {checkIfAdminLoggedIn} from "../../features/adminLogged";
import { useEffect , useState} from "react";
import { Navigate , useNavigate} from "react-router-dom";
import {decryptAdminId} from "../../globalFunctions/getLocalStorageData";
import LinearProgress from '@mui/material/LinearProgress';
import { useDispatch , useSelector} from "react-redux";
import { Extension } from "@mui/icons-material";




const Logout = ()=>{
    const [adminLogFun , adminLogRes] = useAdminLogoutMutation();
    const navigate = useNavigate();
    const dispatch = useDispatch();


    const adminLogoutSendRequest = async ()=>{
     
        const adminId = decryptAdminId();
       
        const adminObj = {
          userId:adminId && adminId
        }
      
          const  res =  await adminLogFun(adminObj);
          
       
         
           
        }
        
        useEffect(()=>{
            adminLogoutSendRequest();
        },[])
        
        useEffect(()=>{
            
               if(adminLogRes.isSuccess){
                sessionStorage.removeItem("e_Aophg");
                sessionStorage.removeItem("zExT1_a");
                sessionStorage.removeItem("muik");
                sessionStorage.removeItem("BxqArt");
                
           
                dispatch(checkIfAdminLoggedIn(false));
               
               }
          
        
        },[adminLogRes.isSuccess])
      
       
     

    return (
        <>
           {
            adminLogRes.isError ? 
            <Navigate to="/dashboard" replace/> :
            adminLogRes.isLoading ?
            <LinearProgress /> 
            : adminLogRes.isSuccess ?  <Navigate to="/" replace/>
            : ""
             

           }
        </>
    )
}

export default Logout;


