// here we are defining the all function realted to auth login
import { useState } from "react";  
import { useNavigate } from "react-router-dom";
import isEmail from 'validator/es/lib/isEmail';
import { useAdminChangePasswordMutation } from "../../../services/adminLogIn";
import {decryptAdminId} from "../../../globalFunctions/getLocalStorageData";


const useLogicHook =  ()=>{

const [adminChangePassowrdFunction , adminChangePasswordResponse] = useAdminChangePasswordMutation();




  


const navigate = useNavigate();

  const [userDetails , setUserDetails] = useState({
      password:'',
      confirmPassword:'',
  });
  const [isError , setError] = useState({
       userPasswordError:'',
       userConfirmPasswordError:''
  });  


    
     // getting user input start onChange  
   const  userInput = (e) =>{
         const {name , value} = e.target;
          setUserDetails({...userDetails , [name]:value});
      } 

      // resetting erro onFocus
   const  resetErrorMsg =  (e)=>{
          const {name} = e.target;
        if(name === "password"){
          

             if(isError.userPasswordError){
               setError((prev)=> ({...prev ,  userPasswordError : prev. userPasswordError = false}));
             }
           
        }else if(name === "confirmPassword"){
          

           if(isError.userConfirmPasswordError){
               setError((prev)=> ({...prev , userConfirmPasswordError : prev.userConfirmPasswordError = false}));
             }
        }
     }

     

      // when user Click On sign in button
    const handleChangePasswordForm =  (e)=>{
      e.preventDefault();

      
      if(userDetails.password.length < 8){
          console.log("Error in Password");
          setError((prev)=> ({...prev , userPasswordError : prev.userPasswordError = true}));
         return false;
        
      }else if(userDetails.confirmPassword !== userDetails.password ){
        console.log("Error in Confirm Password");
         setError((prev)=> ({...prev , userPasswordError  : prev.userPasswordError  = false ,  userConfirmPasswordError : prev. userConfirmPasswordError = true}));
         return false;
      }
      else{
        setError((prev)=> ({...prev , userPasswordError : prev.userPasswordError = false ,  userConfirmPasswordError : prev.userConfirmPasswordError = false}));

          const dataIsOk = userDetails;

          // making new object for changeing password becoz confirm password
          // filed not go in api request body only new password and Admin Id 
          // admin Id i am getting from sessionStorage see in service folder
       
          const adminId = decryptAdminId();

          const newPasswordWithAdminId = {
            password:userDetails.password,
            userId:adminId && adminId  
          }

          adminChangePassowrdFunction(newPasswordWithAdminId);
          
      
       
        
          

          // navigate("dashboard" ,{replace:true}); 
      }
      

     
    
      
}



      const changePasswordAction = {
          userInput,
          resetErrorMsg,
          handleChangePasswordForm,
          isError,
          userDetails,
          adminChangePasswordResponse 

      }

      return changePasswordAction;

}

export default useLogicHook;

