import { useState, useEffect, useRef } from "react";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import TextField from "@mui/material/TextField";
import DoctorpanelLogic from "./DoctorpanelLogic";
import DoctorpanelList from "./Doctorpanellist";
import Autocomplete from "@mui/material/Autocomplete";
import { Button } from "@mui/material";
import {
  useFilterLocationDataMutation,
  useGetencountertypeslistMutation,
  useGetHealthCareListdataMutation,
  useCreateDoctorpanelMutation,
  useGetSpacializationListMutation
} from "../../../services/adminLogIn";
import filter from "../../../images/filter.png";
import "../Organization/Mainhome.css";
import { Grid } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import Breadcrums from "../../global/breadcrums/Breadcrums";
import { toasterFunction } from "../../../globalFunctions/getLocalStorageData";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminCookieConsentBanner from "../home/AdminCookieConsentBanner";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const Doctorpanel = (props) => {
  const [getSpecailizationList] = useGetSpacializationListMutation();
  const [getLocationList] = useFilterLocationDataMutation();
  const [getEncounterTypeListData] = useGetencountertypeslistMutation();
  const [getHealthCareListdata] = useGetHealthCareListdataMutation();
  const [createDoctorpanel] =useCreateDoctorpanelMutation(); 

  const [access, setAccess] = useState(
    JSON.parse(sessionStorage.getItem("access"))
  );
  const [data1, setdata1] = useState({});
  const [data2, setdata2] = useState([]);
  const [healthCareListData, setHealthCareListData] = useState([]);
  const [specializationList, setSpecialization] = useState([]);


  const [isNewData, setIsNewData] = useState(true);
 
  const [open, setOpen] = useState(false);
  const [indexvalue, setIndex] = useState(null);
  const [locationMultiSelectOptions, setLocationMultiSelectOptions] = useState(
    []
  );

  const [calculateData, setTotalPageCalculate] = useState({
    number: "",
    numberOfElements: "",
    totalElements: "",
    showcount: "",
  });
  const [paginationdata, setPaginationData] = useState({
    string: "",
    page: 0,
  });
  const {
    setDropLocState,
    dropLocState,
    userAllTextFieldState,
    setUserTextFieldState,
    handleDropDownChange,
    
  } = DoctorpanelLogic();
  const [isFormVisible, setFormVisible] = useState({
    isOpen: false,
    isClose: true,
  });
  const [id, setId] = useState("");

  const showFormActions = () => {
    if (access?.sampleadd == true) {
      setId("");
      if (!isFormVisible.isOpen) {
        setFormVisible((prev) => ({
          ...prev,
          isOpen: (isFormVisible.isOpen = true),
          isFormVisible,
          isClose: (isFormVisible.isClose = false),
        }));
        if (userAllTextFieldState.is_update === false) {
          setId("");
        }
      }
    } else {
      toasterFunction(" No Permission", "error");
    }
  };
  const bodySubmitHandler = async (e) => {
    e.preventDefault();
    // console.log(userAllTextFieldState.repeats)
    // if (userAllTextFieldState.encounterTypes === "") {
    //   setError((prev) => ({
    //     ...prev,
    //     isencounterTypesError: isError.iscencounterTypesError = true,
    //     islocationsError: isError.islocationsError = true,
    //     isrepeatsError: isError.isrepeatsError = true,
       

    //   }))

    // }    else if (userAllTextFieldState.locations == '') {
    //   setError((prev) => ({
    //     ...prev,
    //     isencounterTypesError: isError.isencounterTypesError = false,
    //     islocationsError: isError.islocationsError = true,
    //     isencounterTypesError: isError.isencounterTypesError = true,
      

  
    //   }))

    // }   else if (userAllTextFieldState.encounterTypes == '') {
    //     setError((prev) => ({
    //       ...prev,
    //       isencounterTypesError: isError.isencounterTypesError = false,
    //       islocationsError: isError.islocationsError =false ,
    //       isencounterTypesError: isError.isencounterTypesError = true,
          

    
    //     }))
  
    //   } 
 
    // else {

    //   setError((prev) => ({
    //     ...prev,
    //     isencounterTypesError: isError.isencounterTypesError = false,
    //     islocationsError: isError.islocationsError = false,
    //     isencounterTypesError: isError.isencounterTypesError = false,
       

      
    //   }))
    console.log(userAllTextFieldState);
    const sendOrgDetails = {
      // sampleTypeId: AllTextFieldState.sampleTypeId,
      // SampleTypeMasterId: AllTextFieldState.is_update === false ? "SAM-" + AllTextFieldState.sampleTypeName.split(' ').join('-').toUpperCase().replace(/[^a-zA-Z0-9 ]/g, '-') : AllTextFieldState.sampleTypeName,
      // MedicineDosageid: AllTextFieldState.name.toUpperCase()
      // .replace(" ", "-"),
      // id: userAllTextFieldState.is_update === false ? "LOC-" + userAllTextFieldState.name.split(' ').join('-').toUpperCase().replace(/[^a-zA-Z0-9 ]/g, '-') : userAllTextFieldState.id,
      locationId:userAllTextFieldState.locations.id,
      // encounterId:userAllTextFieldState.encounterId,
      // userId:userAllTextFieldState.userId,
      doctorPanelUserIds: userAllTextFieldState.userId.map((item) => item.userId),

      // locationIds: userAllTextFieldState.locname.map((item) => item.id),
      encounterTypeIds: userAllTextFieldState.encounterId.map((item) => item.encounterId),

      // locations: userAllTextFieldState.locations.map((item) => item.id),
      // encounterId: userAllTextFieldState.is_update === false ? userAllTextFieldState.encounterId.name: userAllTextFieldState.encounterId,
    
      "isActive":true
    }
    
    const res = await createDoctorpanel(sendOrgDetails)
    if(res?.data?.createdBy){
      hideForm()
      toasterFunction(res?.data?.code?"Docter's panel Updated successfully":"Docter's panel Created successfully", "success")
    }
    // else{

    //   toasterFunction(res?.error?.data?.message, "error")
    // }
    console.log(sendOrgDetails);
  };

  const callListLocDropdownData = async (value) => {
    const payload = {
      locName: value,
      partOf: "",
      managingOrg: "",
      state: "",
      city: "",
      status: "",
      page: 0,
      size: 100,
    };
    const res = await getLocationList(payload);
    if (res?.data?.content) setdata1(res);
  };

  const handleLocDropDownSearch = (e, value) => {
    callListLocDropdownData(value);
  };
  const getEncounterTypeList = async (encounterTypeName) => {
    const res = await getEncounterTypeListData(encounterTypeName);
    if (res?.data) setdata2(res);
  };
  const  getListData = async (userId) => {
    const res = await getHealthCareListdata(userId);
    setHealthCareListData(res?.data?.content);
    setTotalPage(res?.data?.totalPages);
    setTotalPageCalculate({
      numberOfElements: res?.data?.numberOfElements,
      number: res?.data?.number,
      totalElements: res?.data?.totalElements,
    });
    
  };
  const handleEncDropDownSearch = (e, encounterTypeName) => {
    getEncounterTypeList(encounterTypeName);
  
  };
const handlepanelDropDownSearch=(e,userId)=>{
  getListData(userId)
}
const getMasterData = async () => {
const specializationList = await getSpecailizationList("");
if (specializationList?.data?.content) {
  setSpecialization(specializationList.data.content);
}
}

  const handleClose = (item, index) => {
    setOpen(false);
    setIndex(item);
  };
 
  const hideForm = () => {
    // setErrorMessage(false)

    setUserTextFieldState({
      is_update: false,
      locations: [],
      encounterId:[],
      userId:[],
      
      
    })
    if (isFormVisible.isOpen) {
      setFormVisible((prev) => ({ ...prev, isOpen: isFormVisible.isOpen = false, isFormVisible, isClose: isFormVisible.isClose = true }));
    }
    // setError((prev) => ({
    //   ...prev,
    //   iscodeError: isError.iscodeError = false,
    //   isamountError: isError.isamountError = false,
    //   isrepeatsError: isError.isrepeatsError = false,
    //   isshortDescriptionError: isError.isshortDescriptionError = false,
    //   islongDescriptionError: isError.islongDescriptionError = false,
    // }))
    // setsearchVal("")
    // getMedicineFrequencyList()
  }

  
  const [totalPage, setTotalPage] = useState(0);

  useEffect(() => {
    callListLocDropdownData("");
    getEncounterTypeList("");
    getListData(paginationdata);
    getMasterData();
    createDoctorpanel("")
  }, []);

  return (
    <>
      <div className={props.expand1.empty === true ? "main1" : "main"}>
      <div className="breadCumContainer flex justify-start items-center h-9 w-full bg-o-color-one organ1">
          <ol className="ml-8 flex " style={{ listStyleType: "none" }}>
            <div className="flex mt-1">
              <li className="w-28 h-thirtyPix rounded-mysix flex justify-center items-center font-medium bg-white m-r-f orgbox">
                <span>Categories</span>
              </li>
            </div>
            <i className="right"></i>
            <div className="flex mt-1">
              <li className="w-28 h-thirtyPix rounded-mysix flex justify-center items-center font-medium bg-white m-r-f orgbox rows">
                <span>Clinical</span>
              </li>
            </div>
            <i className="right"></i>
            <div className="flex mt-1">
              <li className="w-28 h-thirtyPix rounded-mysix flex justify-center items-center font-medium bg-white m-r-f orgbox rows">
                <span>Report</span>
              </li>
            </div>

            <i className="right"></i>
            <div className="flex mt-1">
              <li className="w-36 h-thirtyPix rounded-mysix flex justify-center items-center font-medium bg-white m-r-f orgbox rows">
                <span>Report Configuration</span>
              </li>
            </div>
            <i className="right"></i>
            <div className="flex mt-1">
              <li className="w-32 h-thirtyPix rounded-mysix flex justify-center items-center font-medium bg-white m-r-f orgbox rows breadback">
                <span>Doctor Panel</span>
              </li>
            </div>
          </ol>
        </div>

        <Grid container mt={-2}>
          <Grid item xs={6}>
            <div
              className={
                isFormVisible.isOpen
                  ? "leftcontainer rounded-lg"
                  : "leftcontainer1 rounded-lg"
              }
            >
              <Grid className=" p-7 ">
                <TextField
                  label=""
                  onChange={""}
                  onKeyDown={(e) => {
                    e.key === "Enter" && e.preventDefault();
                  }}
                  sx={{ m: 1, width: "25ch" }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchOutlinedIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid className="editContainer rounded-radiusFourPix ">
                <Grid
                  disabled={access?.sampleadd == false}
                  onClick={showFormActions}
                  className={isFormVisible.isOpen ? "addIconchange" : "addIcon"}
                >
                  <span className=" p-2 m-r-f ">Add</span>
                  <AddCircleOutlineIcon
                    style={{
                      fontSize: "14px",
                      color: "#545454",
                      marginBottom: "3px",
                      color: `${isFormVisible.isOpen ? "#ffffff" : "#545454"}`,
                    }}
                  />
                </Grid>

                <Grid className="addIcon">
                  <span className="p-2 m-r-f">Filter</span>
                  <img
                    src={filter}
                    alt="filter.png"
                    style={{
                      position: "absolute",
                      left: "90%",
                      right: "20px",
                      top: "15px",
                      bottom: "95px",
                      width: "11px",
                      paddingTop: "3px",
                    }}
                  ></img>
                </Grid>
              </Grid>
              <>
                <DoctorpanelList
                  setTotalPage={setTotalPage}
                  totalPage={totalPage}
                  setId={setId}
                  id={id}
                  showFormActions={showFormActions}
                  locationMultiSelectOptions={locationMultiSelectOptions}
                  calculateData={calculateData}
                  sampleview={access?.sampleview}
                  sampleupdate={access?.sampleupdate}
                

                />
              </>
            </div>
          </Grid>

          {isFormVisible.isOpen ? (
            <Grid item xs={6}>
              <div className="rightcontainer rounded-lg">
                <div className="overflow-y-auto o-form-container">
                  <>
                    <div className="font-normal text-o-form-label-color mainhead flex justify-between items-center pl-5 pr-1 h-10">
                      <h1>Doctor's Panel Configuration Overview</h1>
                    </div>

                    <form autoComplete="off" onSubmit={bodySubmitHandler}>
                      <div className="mt-5 pl-5 OrgNameContainer ">
                        <h3 className="font-normal text-o-form-label-color">
                          Select Location <span style={{ color: "red" }}> *</span>
                        </h3>
                        <Autocomplete
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          value={
                            userAllTextFieldState &&
                            userAllTextFieldState.locations &&
                            userAllTextFieldState.locations
                          }
                          autoHighlight={true}
                          onChange={(e, v) =>
                            handleDropDownChange("locname", v, e)
                          }
                          onInputChange={(e, v, value) =>
                            handleLocDropDownSearch(e, v, value)
                          }
                          getOptionLabel={(option) =>
                            option.name ? option.name : ""
                          }
                          id="loc-list-option"
                          options={
                            data1 &&
                            data1?.data &&
                            data1?.data?.content &&
                            data1?.data?.content?.length > 0
                              ? data1?.data?.content
                              : []
                          }
                          sx={{ width: 300 }}
                          renderInput={(params) => (
                            <TextField
                              variant="standard"
                              sx={{ width: 358 }}
                              {...params}
                              label=" "
                            />
                          )}
                        />
                      </div>

                      <div className="mt-5 pl-5 OrgNameContainer ">
                        <h3 className="font-normal text-o-form-label-color">
                         Select Encounter(s){" "}
                          <span style={{ color: "red" }}> *</span>
                        </h3>
                        <Autocomplete
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          value={
                            userAllTextFieldState &&
                            userAllTextFieldState.encounterId &&
                            userAllTextFieldState.encounterId
                          }
                          autoHighlight={true}
                          multiple
                          onChange={(e, v) =>
                            handleDropDownChange("encname", v, e)
                          }
                          onInputChange={(e, v, value) =>
                            handleEncDropDownSearch(e, v, value)
                          }
                          getOptionLabel={(option) =>
                            option.name ? option.name : ""
                          }
                          id="loc-list-option"
                          options={
                            data2 && data2?.data && data2?.data?.length > 0
                              ? data2?.data
                              : []
                          }
                          sx={{ width: 300 }}
                          renderInput={(params) => (
                            <TextField
                              variant="standard"
                              sx={{ width: 358 }}
                              {...params}
                              label=" "
                            />
                          )}
                        />
                      </div>

                      <div className="mt-5 pl-5 OrgNameContainer ">
                        <h3 className="font-normal text-o-form-label-color">
                         Set Panel Member(s){" "}
                          <span style={{ color: "red" }}> *</span>
                        </h3>
                        <Autocomplete
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          value={
                            userAllTextFieldState &&
                            userAllTextFieldState.userId &&
                            userAllTextFieldState.userId
                          }
                          autoHighlight={true}
                          multiple
                          onChange={(e, v) =>
                            handleDropDownChange("userId", v, e)
                          }
                          onInputChange={(e, v, value) =>
                            handlepanelDropDownSearch(e, v, value)
                          }
                          getOptionLabel={(option) =>
                            option.firstName ? option.firstName : ""
                          }
                          id="loc-list-option"
                          options={
                            healthCareListData && healthCareListData && healthCareListData?.length > 0
                              ? healthCareListData
                              : []
                          }
                          sx={{ width: 300 }}
                          renderInput={(params) => (
                            <TextField
                              variant="standard"
                              sx={{ width: 358 }}
                              {...params}
                              label=" "
                            />
                          )}
                        />
                      </div>
                     
                      <div className="mt-9 flex justify-center items-center  towButtonContainer h-40">
                        <Button
                          onClick={hideForm}
                          type="submit"
                          variant="contained"
                          disableElevation
                          style={{
                            marginRight: "10px",
                            borderColor: "#0F4391",
                            width: "99px",
                            height: "39px",
                            borderRadius: "9999px",
                            backgroundColor: "#E8FAFF",
                            color: "#0F4391",
                            border: "1px solid #0F4391",
                            marginBottom: "100px",
                          }}
                        >
                          CANCEL
                        </Button>
                        <Button
                           onClick={bodySubmitHandler}
                          type="submit"
                          variant="contained"
                          disableElevation
                          style={{
                            borderColor: "#0F4391",
                            width: "99px",
                            height: "39px",
                            borderRadius: "9999px",
                            backgroundColor: "#E8FAFF",
                            color: "#0F4391",
                            border: "1px solid #0F4391",
                            marginBottom: "100px",
                          }}
                        >
                          SAVE
                        </Button>
                      </div>
                    </form>
                  </>
                </div>
              </div>
            </Grid>
          ) : (
            ""
          )}
        </Grid>
      </div>
      <AdminCookieConsentBanner />
    </>
  );
};

export default Doctorpanel;
