import { FlareSharp } from "@mui/icons-material";
import { useState, useEffect } from "react";
import {
  useGetActiveLocValueMutation,
  useGetActiveLocationValueMutation,
} from "../../../services/adminLogIn";
import { LoggerFunction } from "../../../logger/logger";
const createPackageErrorInitState = {
  isNameError: false,
  isDescriptionError: "",
  isLocPriceTextValError: "",
  isDropLocError: "",
  isStatusError: "",
  locationAndPriceingError: "",
};

const useCreatePackageHook = () => {
  const packageFormData = {
    name: "",
    packageId: "",
    description: "",
    locAndPriceTextVal: "",
    isUpdate: false,
    externalId: [
      {
        id: "",
        nameSpace: "",
        additionalAttributes: {
          additionalProp1: "",
          additionalProp2: "",
          additionalProp3: "",
        },
      },
    ],
    linkedEncounterNotes: [
      {
        encounterTypeId: "",
        noteId: "",
      },
    ],
    locationSpecificDetails: [],
    associatedServiceRequests: [],
    idColor: "",
    idIcon: {
      additionalProp1: "",
      additionalProp2: "",
      additionalProp3: "",
    },
    supportingOrganizations: [],
    supportingLocations: [],
    supportingLocationsForTable: [],
    targetClients: [],
    status: "ACTIVE",
  };
  const [packageFormFields, setPackageFormFields] = useState(packageFormData);
  const [showerror, setErrorMessage] = useState(false);

  const [createPackageError, setCreatePackageError] = useState(
    createPackageErrorInitState
  );
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [locpriceList, setLocPriceList] = useState([]);
  const [isSearchEvent, setIsSearchEvent] = useState(false);
  const [price, setLocPrice] = useState([]);
  const [getActiveValFun] = useGetActiveLocationValueMutation();
  const [locPriceData, setLocPriceData] = useState();
  const [locMasterValues, setLocMasterValues] = useState([]);
  const handleFilterModalOpen = () => {
    setOpenFilterModal(true);
  };
  const handleFilterModalClose = () => {
    setOpenFilterModal(false);
  };

  const [filterData, setFilterData] = useState({
    packageName: "",
    supportingOrg: [],
    associatedServiceReq: [],
    supportingLoc: [],
    filterStatus: "",
  });

  const checkAlphaNumericAndSpecialChars = (value) => {
    const regex = new RegExp("^(?!d+$)(?:[a-zA-Z0-9][a-zA-Z0-9 @&$]*)?$");
    return regex.test(value);
  };
  const handleInputChange = (e, i) => {
    const { name, value } = e.target;
    if (name === "name") {
      setPackageFormFields((prev) => ({
        ...prev,
        [name]: value,
      }));
      const id = value.toUpperCase().trim().replaceAll(" ", "-");
      setPackageFormFields((prev) => ({
        ...prev,

        packageId: id,
      }));
      setCreatePackageError((prev) => ({ ...prev, isNameError: false }));
    }
    if (name === "description") {
      // const isNameValidstring = checkAlphaNumericAndSpecialChars(value);
      if (value.length <= 250) {
        setPackageFormFields((prev) => ({
          ...prev,
          [name]: value,
        }));
      }
    }
    if (name === "price") {
      if (value.length <= 6) {
        let arr = [...price];
        arr[i].value = value.replace(/[^0-9]/gi, "");
        arr[i].id = i;
        setLocPrice([...arr]);
        setPackageFormFields((prev) => ({
          ...prev,
          [name]: value.replace(/[^0-9]/gi, ""),
        }));
        if (arr.length) {
          setPackageFormFields((prev) => ({
            ...prev,
            locationAndPriceingError:
              (createPackageError.locationAndPriceingError = false),
          }));
        }
      }
    }
  };

  const handleDropDownPriceLoc = (field, i, v) => {
    const value = v;
    console.log(value);
    if (field === "loc" && value) {
      const arr = locPriceData.filter(
        (item) => item?.id?.trim() === value?.id?.trim()
      );
      locpriceList.forEach((element, index) => {
        if (i === index) {
          element.locationId = arr[0]?.id;
          element.amount = price[index].value;
          element.name = value?.name;
          setLocMasterValues([...locMasterValues, value]);
        }
      });
      setLocPriceList(locpriceList);
      const arrLoc = packageFormFields.supportingLocationsForTable.filter(
        (item) => item.id !== value.id
      );
      setPackageFormFields((prev) => ({
        ...prev,
        supportingLocationsForTable: arrLoc,
      }));
    }
    // else {
    //   locpriceList.forEach((element, index) => {
    //     if (i === index) {
    //       element.amount = price[index].value;
    //       element.name = value;
    //       setLocMasterValues([...locMasterValues, value]);
    //     }
    //   });
    //   setLocPriceList(locpriceList);
    //   const arrOptions = packageFormFields.supportingLocations.filter((item) => item.trim() === locpriceList[i].name.trim());
    //   setPackageFormFields((prev) => ({
    //     ...prev,
    //     supportingLocationsForTable: [...packageFormFields.supportingLocationsForTable, ...arrOptions],
    //   }));
    // }

    if (field === "locations") {
      if (packageFormFields.supportingLocationsForTable) {
        setPackageFormFields((prev) => ({
          ...prev,
          supportingLocations: value,
          supportingLocationsForTable: [
            ...packageFormFields.supportingLocationsForTable,
            value[value.length - 1],
          ],
        }));
      }
    }

    if (field === "organization") {
      setPackageFormFields((prev) => ({
        ...prev,
        supportingOrganizations: value,
      }));
    }
    if (field === "servicerequest") {
      setPackageFormFields((prev) => ({
        ...prev,
        associatedServiceRequests: value,
      }));
    }

    if (field === "status") {
      setPackageFormFields((prev) => ({
        ...prev,
        [field]: value,
      }));
    }
  };

  const addRow = (value) => {
    if (value === "loc") {
      const data = {
        locationId: "",
        amount: "",
        name: "",
        currency: "INR",
        etaDetails: {
          value: "4",
          units: "HOURS",
        },
      };
      setLocPriceList([...locpriceList, data]);
    }
    const priceObj = {
      id: "",
      value: "",
    };
    setLocPrice([...price, priceObj]);
  };

  const deleteRow = (i) => {
    const arr = locpriceList.filter((item, index) => index !== i);
    const priceArr = price.filter((item, index) => index !== i);
    setLocPriceList(arr);
    setLocPrice(priceArr);
    const arrOptions = packageFormFields.supportingLocations.filter(
      (item) => item.trim() === locpriceList[i].name.trim()
    );
    setPackageFormFields((prev) => ({
      ...prev,
      supportingLocationsForTable: [
        ...packageFormFields.supportingLocationsForTable,
        ...arrOptions,
      ],
    }));
  };

  const resetErrorHandler = (e) => {
    const { name } = e.target;
    if (name === "name") {
      setCreatePackageError((prev) => ({ ...prev, isNameError: false }));
    }
    if (name === "description") {
      setCreatePackageError((prev) => ({ ...prev, isDescriptionError: false }));
    }
  };

  const getPackageTransformedData = () => {
    const data = packageFormFields;
    if (data.name === "") {
      setCreatePackageError((prev) => ({ ...prev, isNameError: true }));
      return false;
    } else {
      locpriceList.forEach((item, index) => {
        locpriceList[index].amount = price[index].value;
      });
      const packageData = { ...data, locationSpecificDetails: locpriceList };
      if (packageData.status === "Active") {
        packageData.status = "ACTIVE";
      } else if (packageData.status === "Inactive") {
        packageData.status = "CANCELLED";
      }
      return packageData;
    }
  };

  const gePackageData = async () => {
    const locRes = await getActiveValFun();
    var log = await LoggerFunction();
    if (locRes?.data?.content)
      log.info({ data: "Data get successfully", message: locRes.data.content });
    if (locRes?.error) log.error(locRes?.error);
    if (locRes?.data?.content) {
      setLocPriceData(locRes.data.content);
    }
  };
  const handleFilterInputChange = (e) => {
    setFilterData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleFilterValueChange = (name, value) => {
    setFilterData((prev) => ({ ...prev, [name]: value }));
  };

  const resetFilterData = () => {
    setFilterData({
      packageName: "",
      supportingOrg: [],
      associatedServiceReq: [],
      supportingLoc: [],
      filterStatus: "",
    });
  };

  useEffect(() => {
    gePackageData();
  }, []);

  const exportAll = {
    packageFormFields,
    createPackageError,
    handleInputChange,
    resetErrorHandler,
    handleFilterModalOpen,
    handleFilterModalClose,
    openFilterModal,
    addRow,
    deleteRow,
    handleDropDownPriceLoc,
    locpriceList,
    setErrorMessage,
    showerror,
    getPackageTransformedData,
    setPackageFormFields,
    setLocPriceList,
    setLocPrice,
    locMasterValues,
    price,
    packageFormData,
    filterData,
    isSearchEvent,
    resetFilterData,
    handleFilterInputChange,
    handleFilterValueChange,
  };

  return exportAll;
};

export default useCreatePackageHook;
