import { useEffect , useState} from "react";
import useSideNavLogic from "./sidenavlogic";
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import {NavLink , useLocation} from "react-router-dom";
import DashboardLogo from "../icons/dashboard.svg";
import DashboardLightLogo from "../icons/dashboardlight.svg";
import CategoryLogo from "../icons/category.svg";
import CategoryLightLogo from "../icons/categorylight.svg";
import PartnersLogo from "../icons/partner.svg";
import PartnersLightLogo from "../icons/partnerlight.svg";
import BulkImport from "../icons/BulkImport.svg";
import FileSync from "../icons/File Sync.svg";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import "./Sidenav.css"
export const SideNav = (props) => {
  const location = useLocation();
  const { drawerStyleForSideBar} = useSideNavLogic();
  const [linkId,setLinkId] = useState({});
  const [isLinkActiveState , setLinkActiveState] = useState({
     home:true,
     category:false,
     partners:false,
     addUser:false,
     patient:false,
     jobs : false,
     menu:false,
     shouldAlways:true,
  })
  const [access, setAccess] = useState(
    JSON.parse(sessionStorage.getItem("access"))
  );

  const profileName = useSelector((state) => state.adminLog.profileName);

  const linkActiveStyle = {
    borderLeft:'5px solid #0F4391',
    height:'89px',
    backgroundColor:'#E8FAFF'
  }

  const linkUnActiveStyle = {
    
    height:'89px',
    
  }



  const detectWhichLinkIsActive = (linkIdentity)=>{
    setLinkId(linkIdentity)
     switch(linkIdentity){
          case 'home':
            setLinkActiveState((prev)=>(
              {...prev , 
                home: isLinkActiveState.home = true,
                category: isLinkActiveState.category = false,
                partners: isLinkActiveState.partners = false,
                addUser: isLinkActiveState.addUser = false,
                patient: isLinkActiveState.patient = false,
                menu: isLinkActiveState.menu = false,
                jobs: isLinkActiveState.jobs = false,
                shouldAlways:isLinkActiveState.shouldAlways = true

              })) 
              
              
          break;

          case 'category':
            setLinkActiveState((prev)=>(
              {...prev , 
                home: isLinkActiveState.home = false,
                category: isLinkActiveState.category = true,
                partners: isLinkActiveState.partners = false,
                addUser: isLinkActiveState.addUser = false,
                menu: isLinkActiveState.menu = false,
                patient: isLinkActiveState.patient = false,
                jobs: isLinkActiveState.jobs = false,
                shouldAlways:isLinkActiveState.shouldAlways = true

              })) 
              
             

          break;

          case 'jobs':
            setLinkActiveState((prev)=>(
              {...prev , 
                home: isLinkActiveState.home = false,
                category: isLinkActiveState.category = false,
                partners: isLinkActiveState.partners = false,
                addUser: isLinkActiveState.addUser = false,
                menu: isLinkActiveState.menu = false,
                patient: isLinkActiveState.patient = false,
                jobs: isLinkActiveState.jobs = true,
                shouldAlways:isLinkActiveState.shouldAlways = true

              })) 
              
             

          break;

          case 'partners':
            setLinkActiveState((prev)=>(
              {...prev , 
                home: isLinkActiveState.home = false,
                category: isLinkActiveState.category = false,
                partners: isLinkActiveState.partners = true,
                addUser: isLinkActiveState.addUser = false,
                menu: isLinkActiveState.menu = false,
                patient: isLinkActiveState.patient = false,
                jobs: isLinkActiveState.jobs = false,
                shouldAlways:isLinkActiveState.shouldAlways = true

              }))  
              break;

              case 'patient':
                setLinkActiveState((prev)=>(
                  {...prev , 
                    home: isLinkActiveState.home = false,
                    category: isLinkActiveState.category = false,
                    partners: isLinkActiveState.partners = false,
                    addUser: isLinkActiveState.addUser = false,
                    menu: isLinkActiveState.menu = false,
                    patient: isLinkActiveState.patient = true,
                    jobs: isLinkActiveState.jobs = false,
                    shouldAlways:isLinkActiveState.shouldAlways = true
    
                  }))
          break;

          case 'addUsers':
            setLinkActiveState((prev)=>(
              {...prev , 
                home: isLinkActiveState.home = false,
                category: isLinkActiveState.category = false,
                partners: isLinkActiveState.partners = false,
                addUser: isLinkActiveState.addUser = true,
                menu: isLinkActiveState.menu = false,
                patient: isLinkActiveState.patient = false,
                jobs: isLinkActiveState.jobs = false,
                shouldAlways:isLinkActiveState.shouldAlways = true

              }))  
          break;

          case 'menu':
            setLinkActiveState((prev)=>(
              {...prev , 
                home: isLinkActiveState.home = false,
                category: isLinkActiveState.category = false,
                partners: isLinkActiveState.partners = false,
                addUser: isLinkActiveState.addUser = false,
                patient: isLinkActiveState.patient = false,
                menu: isLinkActiveState.menu = true,
                jobs: isLinkActiveState.jobs = false,
                shouldAlways:isLinkActiveState.shouldAlways = true

              }))  
          break;

     }
  }

   useEffect(()=>{
     props.getStateWhichLinkIsActive(isLinkActiveState);
     
    
    
   },[isLinkActiveState])


   useEffect(()=>{
    if(location.pathname == "/dashboard/organization" || location.pathname == "/dashboard/location" || location.pathname == "/dashboard/user" ||location.pathname == "/dashboard/encounterTypes" || '/dashboard/bodysites'){
      setLinkActiveState((prev)=>(
        {...prev , 
          home: isLinkActiveState.home = false,
          category: isLinkActiveState.category = true,
          partners: isLinkActiveState.partners = false,
          addUser: isLinkActiveState.addUser = false,
          patient: isLinkActiveState.patient = false,
          menu: isLinkActiveState.menu = false,
          jobs: isLinkActiveState.jobs = false,
          shouldAlways:isLinkActiveState.shouldAlways = false
        })) 
    }
    if( location.pathname == "/dashboard/patientimport" || location.pathname == "/dashboard/patientstatuslist"){
      setLinkActiveState((prev)=>(
        {...prev , 
          home: isLinkActiveState.home = false,
          category: isLinkActiveState.category = false,
          partners: isLinkActiveState.partners = false,
          addUser: isLinkActiveState.addUser = false,
          patient: isLinkActiveState.patient = true,
          menu: isLinkActiveState.menu = false,
          jobs: isLinkActiveState.jobs = false,
          shouldAlways:isLinkActiveState.shouldAlways = false
        })) 
    }
    if( location.pathname == "/dashboard/patientimport" || location.pathname == "/dashboard/patientstatuslist"){
      setLinkActiveState((prev)=>(
        {...prev , 
          home: isLinkActiveState.home = false,
          category: isLinkActiveState.category = false,
          partners: isLinkActiveState.partners = false,
          addUser: isLinkActiveState.addUser = false,
          patient: isLinkActiveState.patient = false,
          menu: isLinkActiveState.menu = false,
          jobs: isLinkActiveState.jobs = true,
          shouldAlways:isLinkActiveState.shouldAlways = false
        })) 
    }
    if( location.pathname == "/dashboard"){
      setLinkActiveState((prev)=>(
        {...prev , 
          home: isLinkActiveState.home = true,
          category: isLinkActiveState.category = false,
          partners: isLinkActiveState.partners = false,
          addUser: isLinkActiveState.addUser = false,
          patient: isLinkActiveState.patient = false,
          jobs: isLinkActiveState.jobs = false,
          menu: isLinkActiveState.menu = false,
          shouldAlways:isLinkActiveState.shouldAlways = false
        })) 
    }
   },[])
  
  return (
    <>
      <aside
        className={`fixed  border bg-white transition-all duration-200 ease-linear shadow-lg h-screen w-16  }`}
        style={
          props.passingStateToSideNav.drawerState
            ? drawerStyleForSideBar
            : { width: "108px" }
        }
      >
        {props.passingStateToSideNav.drawerState ? (
          <header className="bg-gray-50 side-bar-header border border-dashed-gray h-20">
            <h1 className="text-3xl text-center  font-bold">Karkinos</h1>
          </header>
        ) : (
          ""
        )}

        <div className="sideNavLinkContainer flex overflow-auto flex-col w-full h-full bg-sideBarColor aside">
          <NavLink
            to="/dashboard"
            onClick={() => detectWhichLinkIsActive("home")}
            className="cursor-pointer img_container flex-shrink-0  flex justify-center items-center   navlinkcontainer "
            style={isLinkActiveState.home ? linkActiveStyle : linkUnActiveStyle}
          >
            {/* <LinkComp icon={DashboardLogo} text="Home" isTrue={isLinkActiveState.home}/> */}
            <div class="item mt-2">
              <img
                src={
                  isLinkActiveState.home ? DashboardLogo : DashboardLightLogo
                }
              />
              <br />
              <span className="caption m-r-f" style={{ marginTop: "-10px" }}>
                Dashboard
              </span>
            </div>
          </NavLink>

          <div
            onClick={() => detectWhichLinkIsActive("category")}
            className="cursor-pointer flex-shrink-0  flex justify-center items-center   navlinkcontainer h-20 img_container"
            style={
              isLinkActiveState.category ? linkActiveStyle : linkUnActiveStyle
            }
          >
            {/* <LinkComp icon={CategoryOutlinedIcon} text="Categories" isTrue={isLinkActiveState.category} /> */}
            <div class="item">
              <img
                src={
                  isLinkActiveState.category ? CategoryLogo : CategoryLightLogo
                }
              />
              <span className="caption m-r-f mt-2">Categories</span>
            </div>
          </div>

          {/* <div onClick={()=>detectWhichLinkIsActive("partners")}  className="cursor-pointer flex-shrink-0  flex justify-center items-center  navlinkcontainer h-20" style={isLinkActiveState.partners ?  linkActiveStyle : linkUnActiveStyle }>
                        <div class="item">
                        <img src={isLinkActiveState.partners ? PartnersLogo : PartnersLightLogo}  />
                        <span className="caption m-r-f mt-2">Partners</span>
                        </div>
                      </div> */}

          {profileName === "KDS" ? (
            <>
  {(access?.ccadmin || access?.rolesview) && !(access?.ccadmin && !access?.rolesview) && (
              <div
                onClick={() => detectWhichLinkIsActive("patient")}
                className="cursor-pointer flex-shrink-0  flex justify-center items-center  navlinkcontainer h-20"
                style={
                  isLinkActiveState.patient
                    ? linkActiveStyle
                    : linkUnActiveStyle
                }
              >
                <div class="item">
                  <img
                    src={isLinkActiveState.patient ? BulkImport : BulkImport}
                  />
                  <span className="caption m-r-f mt-2">Bulk</span>
                  <span className="caption m-r-f mt-2">Import</span>
                </div>
              </div>
            )}
             

             {access?.faileddatasyncjobsprocess === true ? (<div
                onClick={() => detectWhichLinkIsActive("jobs")}
                className="cursor-pointer flex-shrink-0  flex justify-center items-center  navlinkcontainer h-20"
                style={
                  isLinkActiveState.jobs ? linkActiveStyle : linkUnActiveStyle
                }
              >
                <div class="item">
                  <img src={isLinkActiveState.jobs ? FileSync : FileSync} />
                  <span className="caption m-r-f mt-2">Jobs</span>
                </div>
              </div>
               ) : null}
              <div className="cursor-pointer flex-shrink-0   navlinkcontainer h-20 flex-grow-2"></div>
            </>
          ) : null}

          {/* <div  onClick={()=>detectWhichLinkIsActive("addUsers")} className="cursor-pointer flex-shrink-0  flex justify-center items-center  navlinkcontainer h-20 mb-10" style={isLinkActiveState.addUser ?  linkActiveStyle : linkUnActiveStyle }>
                         <LinkComp icon={PersonAddAltOutlinedIcon} text="Add User" isTrue={isLinkActiveState.addUser}/>
                      </div> */}

          {/* <div onClick={()=>detectWhichLinkIsActive("menu")} className="cursor-pointer flex-shrink-0  flex justify-center items-center  navlinkcontainer h-20 mb-12" style={isLinkActiveState.menu ? linkActiveStyle : linkUnActiveStyle }>
                         <LinkMenu icon={MoreHorizOutlinedIcon} text="Menu" isTrue={isLinkActiveState.menu}/>
                      </div> */}
        </div>

        {/* <Divider/> */}

        {/* <nav>
                     <ul>
                         <li></li>
                         <li></li>
                         <li></li>
                         <li></li>
                         <li></li>
                     </ul>
                </nav> */}
      </aside>
    </>
  );
}


const LinkComp = (props)=>{
  return(
    <>
    <NavLink  to="/dashboard/user">
   
                    <div className="navItemContainer  flex flex-col justify-center items-center  h-20 w-16">
                             <div className={`iconContainer ${props.isTrue ? 'bg-iconColor' : 'bg-myColor'} flex justify-center items-center rounded-full w-12 h-12`}>
                                <props.icon style={{color:`${props.isTrue ? 'white' : '#0F4391'}`,'width':'25px','height':'25px'}}/>
                             </div>
                             <span className="text-xs font-medium text-iconColor mt-1 addicon m-r-f">{props.text}</span>
                         </div>
                         </NavLink>

    </>
  )
}
const LinkMenu = (props)=>{
  return(
    <>
   
                    <div className="navItemContainer  flex flex-col justify-center items-center  h-20 w-16">
                             <div className={`iconContainer ${props.isTrue ? 'bg-iconColor' : 'bg-myColor'} flex justify-center items-center rounded-full w-12 h-12`}>
                                <props.icon style={{color:`${props.isTrue ? 'white' : '#0F4391'}`,'width':'25px','height':'25px'}}/>
                             </div>
                             <span className="text-xs font-medium text-iconColor mt-1 addicon m-r-f">{props.text}</span>
                         </div>

    </>
  )
}




export default SideNav;