import { useState, useEffect, useRef } from "react";
import AdminHome from "../AdminHome";
import sideImageDashboardHome from "../../../images/sideImageDashboardHome.svg";
import CryptoJS from "crypto-js";
import { NavLink, useLocation } from "react-router-dom";
import { getAdminActualValue } from "../../../globalFunctions/getLocalStorageData";
import {
  useGetActiveLocValueMutation,
  useGetActiveOrgValueMutation,
  useGetActiveUserValueMutation,
  useGetBodySitesListMutation,
  useGetencountertypeslistMutation,
  useGetPackageListMutation,
  useGetservicereqSearchlistMutation,
  useGetsampleclssListMutation,
  useGetsamplematchingListMutation,
  useGetMedicinemastersearchMutation,
  useGetMedicinedosageMutation,
  useGetMedicinerouteMutation,
  useGetMedicineSiteListMutation,
  useGetMedicinedurationMutation,
  useGetMedicineStrenthUomListMutation,
  useGetMedicineFrequencymasterMutation,
} from "../../../services/adminLogIn";
import { getTokensFromStorage } from "../../../../src/globalFunctions/getLocalStorageData";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import "./home.css";
import moment from "moment";
import Tooltip from "@mui/material/Tooltip";
import { useDispatch, useSelector } from "react-redux";
import AdminCookieConsentBanner from './AdminCookieConsentBanner';

import { useNavigate } from "react-router-dom";
// import { set } from "immer/dist/internal";

const bull = (
  <Box
    component="span"
    sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
  ></Box>
);

// useEffect(() => {
//     getsamplelistfunc();

//   }, [])
//   useEffect(() => {

//   }, [sampleclsslist])
// const Item = styled(Paper)(({ theme }) => ({
//     backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
//     ...theme.typography.body2,
//     padding: theme.spacing(1),
//     textAlign: 'center',
//     color: theme.palette.text.secondary,
// }));
const Home = (props) => {
  //    const eText =  CryptoJS.AES.encrypt("har har mahadev", "inder");
  //    const eTextDec =  CryptoJS.AES.decrypt(eText , "inder");
  //    const orgText = eTextDec.toString(CryptoJS.enc.Utf8);
  const [getActiveValFun, { isLoading, isSuccess, isError, data }] =
    useGetsamplematchingListMutation();
  const [getActiveUserFun, { ...user }] = useGetActiveUserValueMutation();
  const [
    getActiveValFun1,
    { isLoading1, isSuccess1, isError1, ...organization },
  ] = useGetActiveOrgValueMutation();
  const [
    getActiveValFun2,
    { isLoading2, isSuccess2, isError2, ...locationlist },
  ] = useGetActiveLocValueMutation();
  const [getServiceSearchList] = useGetservicereqSearchlistMutation();
  const [getPackageListData] = useGetPackageListMutation();
  const [getEncounterTypeListData] = useGetencountertypeslistMutation();
  const [getMedicinerouteList] = useGetMedicinerouteMutation();
  const [getBodySitesList] = useGetBodySitesListMutation();
  const [getmedicinelist] = useGetMedicinemastersearchMutation();
  const [getMedicinedosageList] = useGetMedicinedosageMutation();
  const [getMedicineSiteList] = useGetMedicineSiteListMutation();
  const [getMedicinedurationList] = useGetMedicinedurationMutation();
  const [getMedicineSTrenthUomList] = useGetMedicineStrenthUomListMutation();
  const [getMedicineFrequencymasterList] =
    useGetMedicineFrequencymasterMutation();
  const [adminDetails, setAdminDetails] = useState();
  const [access, setAccess] = useState(
    JSON.parse(sessionStorage.getItem("access"))
  );

  const tok = getTokensFromStorage();

  const location = useLocation();
  const [servicetotalcount, setTotalcount] = useState(0);
  const [packagetotalcount, setcounts] = useState(0);
  const [encountertotalcount, setencountcounts] = useState(0);
  const [bodysitescount, setbodysitescount] = useState({});
  const [medicinemastercount, setmedicinemastercount] = useState({});
  const [medicinedosagecount, setmedicinedosagecount] = useState({});
  const [medcineroutecount, setmedicineroutecount] = useState({});
  const [medicinesitecount, setmedicinesitecount] = useState({});
  const [medicineDurationcount, setmedicineDurationcount] = useState({});
  const [medicineStrengthcount, setmedicineStrengthcount] = useState({});
  const [medicinefrequencycount, setmedicinefrequencycount] = useState({});
  // const [mastersall, setmastersall] = useState(false);

  const navigate = useNavigate();
  const onClickEvent = (field) => {
    navigate(field);
  };
  const profileName = useSelector((state) => state.adminLog.profileName);

  useEffect(() => {
    getActiveValFun({ string: "", page: "" });
    getActiveUserFun();
    getActiveValFun1();
    getActiveValFun2();
    geServicereqList();
    getPackageList();
    getEncounterTypeList();
    getBodySitesListData();
    getMedicineMasterListData();
    getMedicineDosageListData();
    getMedicineRouteListData();
    getMedicineSiteListData();
    getMedicineDurationListData();
    getMedicineStrengthListData();
    getMedicineFrequencyListData();
  }, []);
  useEffect(() => {
    setAdminDetails(tok && tok[0] && JSON.parse(atob(tok[0].split(".")[1])));
  }, []);

  const geServicereqList = async () => {
    let data = {};
    data.status = "";
    data.string = "";
    const servicereqList = await getServiceSearchList(data);

    setTotalcount(servicereqList?.data);
  };
  const getPackageList = async () => {
    const listData = await getPackageListData({ status: "", searchStr: "" });
    setcounts(listData?.data);
  };
  const getEncounterTypeList = async () => {
    const listData = await getEncounterTypeListData("");
    setencountcounts(listData?.data?.totalElements);
  };

  const getBodySitesListData = async (page) => {
    let payload = {};
    payload.searchStr = "";
    payload.page = page - 1;

    const BodySitesListData = await getBodySitesList(page - 1);
    setbodysitescount(BodySitesListData?.data);
  };
  const getMedicineMasterListData = async (page) => {
    const MedicineMasterListData = await getmedicinelist({
      searchString: "",
      page: 0,
      size: 20,
    });
    setmedicinemastercount(MedicineMasterListData?.data);
  };
  const getMedicineDosageListData = async (page) => {
    const MedicineDosageListData = await getMedicinedosageList({
      string: "",
      page: 0,
      size: 20,
    });
    setmedicinedosagecount(MedicineDosageListData?.data);
  };
  const getMedicineRouteListData = async (page) => {
    const MedicineRouteListData = await getMedicinerouteList({
      string: "",
      page: 0,
      size: 20,
    });
    setmedicineroutecount(MedicineRouteListData?.data);
  };
  const getMedicineSiteListData = async (page) => {
    const MedicineSiteListData = await getMedicineSiteList({
      string: "",
      page: 0,
      size: 20,
    });
    setmedicinesitecount(MedicineSiteListData?.data);
  };
  const getMedicineDurationListData = async (page) => {
    const MedicineDurationListData = await getMedicinedurationList({
      string: "",
      page: 0,
      size: 20,
    });
    setmedicineDurationcount(MedicineDurationListData?.data);
  };
  const getMedicineStrengthListData = async (page) => {
    const MedicineStrengthListData = await getMedicineSTrenthUomList({
      string: "",
      page: 0,
      size: 20,
    });
    setmedicineStrengthcount(MedicineStrengthListData?.data);
  };
  const getMedicineFrequencyListData = async (page) => {
    const MedicineFrequencyListData = await getMedicineFrequencymasterList({
      string: "",
      page: 0,
      size: 20,
    });
    setmedicinefrequencycount(MedicineFrequencyListData?.data);
  };

  // encrypting admin data
  const mainStyleForContent = {
    width: `calc(100% - 240px)`,
    marginLeft: "240px",
  };

  const bredStyle = {
    fontSize: "12px",
  };

  const [expand, setexpand] = useState({
    heading: "",
    icon: "",
    isSubListExpanded: false,
  });
  const [expand1, setexpand1] = useState({
    empty: true,
  });
  // const handleClick = () => {
  //     setmastersall(true);
  //   };

  return (
    <>
      <div className="boxcontent">
        <div>
          <div
            className={
              props.expand1.empty === true ? "maincontainer1" : "maincontainer"
            }
          >
            <div className="breadCumContainer flex justify-start items-center h-11 w-full bg-breadCumConColor">
              <ol className="ml-6 flex" style={{ listStyleType: "none" }}>
                <div className="dashboard1">
                  <li
                    className="dashboard w-28 h-thirtyPix rounded-mysix flex justify-center items-center font-medium bg-red"
                    style={bredStyle}
                  >
                    <span>Dashboard</span>
                  </li>
                </div>
              </ol>
            </div>

            {/* userCards starts */}
            <div className="userCardsMainContainer bg-white flex justify-evenly w-mywidth-8  p-4 pt-6  mr-2 ml-2 rounded-lg ">
              <div className="userCardContainer w-3/4 h-72">
                <div className="mainHeading ">
                  <h1 className="m-r-f font-medium mb-5"> LIVE STATUS </h1>
                </div>
                <>
                  {profileName === "KDS" ? (
                    <Grid container spacing={2}>
                      <Grid item xs={3}>
                        <Tooltip
                          title={
                            access?.organisationview == true ? "" : "No access"
                          }
                          followCursor
                        >
                          <Card
                            style={{ cursor: "pointer" }}
                            onClick={(e) =>
                              access?.organisationview == true
                                ? onClickEvent("/dashboard/organization")
                                : ""
                            }
                            sx={{ minWidth: 125, height: 100 }}
                          >
                            <CardContent>
                              <Typography
                                sx={{ fontSize: 12 }}
                                color="text.secondary"
                                gutterBottom
                              >
                                ORGANISATIONS
                              </Typography>
                              <Typography variant="h5" component="div">
                                {organization.data &&
                                  organization.data.totalElements}
                              </Typography>
                              <Typography
                                sx={{ fontSize: 8 }}
                                color="text.secondary"
                              >
                                Last Updated :
                                {moment(
                                  organization.data?.content &&
                                    organization.data?.content[0]
                                      .lastModifiedTime
                                ).format("Do MMMM YYYY, h:mm:ss a")}
                              </Typography>
                            </CardContent>
                          </Card>
                        </Tooltip>
                      </Grid>

                      <Grid item xs={3}>
                        <Tooltip
                          title={
                            access?.locationview == true ? "" : "No access"
                          }
                          followCursor
                        >
                          <Card
                            style={{ cursor: "pointer" }}
                            onClick={(e) =>
                              access?.locationview == true
                                ? onClickEvent("/dashboard/location")
                                : ""
                            }
                            sx={{ minWidth: 125, height: 100 }}
                          >
                            <CardContent>
                              <Typography
                                sx={{ fontSize: 12 }}
                                color="text.secondary"
                                gutterBottom
                              >
                                LOCATIONS
                              </Typography>
                              <Typography variant="h5" component="div">
                                {locationlist.data &&
                                  locationlist.data.totalElements}
                              </Typography>
                              <Typography
                                sx={{ fontSize: 8 }}
                                color="text.secondary"
                              >
                                {/* Last Updated :{new Date(locationlist.data?.content &&  locationlist.data?.content[0].lastModifiedTime).toLocaleString()}                                                </Typography> */}
                                Last Updated :
                                {moment(
                                  locationlist.data?.content &&
                                    locationlist.data?.content[0]
                                      .lastModifiedTime
                                ).format("Do MMMM YYYY, h:mm:ss a")}{" "}
                              </Typography>
                            </CardContent>
                          </Card>
                        </Tooltip>
                      </Grid>
                      <Grid item xs={3}>
                        <Tooltip
                          title={access?.userview == true ? "" : "No access"}
                          followCursor
                        >
                          <Card
                            style={{ cursor: "pointer" }}
                            onClick={(e) =>
                              access?.userview == true
                                ? onClickEvent("/dashboard/user")
                                : ""
                            }
                            sx={{ minWidth: 125, height: 100 }}
                          >
                            <CardContent>
                              <Typography
                                sx={{ fontSize: 12 }}
                                color="text.secondary"
                                gutterBottom
                              >
                                USERS
                              </Typography>
                              <Typography variant="h5" component="div">
                                {user.data && user.data.totalElements}
                              </Typography>
                              <Typography
                                sx={{ fontSize: 8 }}
                                color="text.secondary"
                              >
                                {/* Last Updated : */}
                                {/* {new Date(parseFloat(user.data?.content && user.data?.content[0] &&  user.data?.content[0].lastModifiedTime.toString().replace(".", ""))).toLocaleString()} */}
                                Last Updated :
                                {moment(
                                  user.data?.content &&
                                    user.data?.content[0] &&
                                    user.data?.content[0].lastModifiedTime *
                                      1000
                                ).format("Do MMMM YYYY, h:mm:ss a")}
                              </Typography>
                            </CardContent>
                          </Card>
                        </Tooltip>
                      </Grid>
                      {(access?.ccadmin || access?.rolesview) &&
                        !(access?.ccadmin && !access?.rolesview) && (
                          <>
                            <Grid item xs={3}>
                              <Tooltip
                                title={
                                  access?.encounteview == true
                                    ? ""
                                    : "No access"
                                }
                                followCursor
                              >
                                <Card
                                  className={
                                    access?.encounteview == true
                                      ? ""
                                      : "disabled-card"
                                  }
                                  onClick={(e) =>
                                    access?.encounteview == true
                                      ? onClickEvent(
                                          "/dashboard/encounterTypes"
                                        )
                                      : ""
                                  }
                                  sx={{ minWidth: 125, height: 100 }}
                                >
                                  <CardContent>
                                    <Typography
                                      sx={{ fontSize: 12 }}
                                      color="text.secondary"
                                      gutterBottom
                                    >
                                      ENCOUNTER TYPES
                                    </Typography>
                                    <Typography variant="h5" component="div">
                                      40
                                    </Typography>
                                    <Typography
                                      sx={{ fontSize: 8 }}
                                      color="text.secondary"
                                    >
                                      Last Updated: 18 March, 2022
                                    </Typography>
                                  </CardContent>
                                </Card>
                              </Tooltip>
                            </Grid>
                            <Grid item xs={3}>
                              <Tooltip
                                title={
                                  access?.bodysiteview == true
                                    ? ""
                                    : "No access"
                                }
                                followCursor
                              >
                                <Card
                                  style={{ cursor: "pointer" }}
                                  onClick={(e) =>
                                    access?.bodysiteview == true
                                      ? onClickEvent("/dashboard/bodysites")
                                      : ""
                                  }
                                  sx={{ minWidth: 125, height: 100 }}
                                >
                                  <CardContent>
                                    <Typography
                                      sx={{ fontSize: 12 }}
                                      color="text.secondary"
                                      gutterBottom
                                    >
                                      BODYSITES
                                    </Typography>
                                    <Typography variant="h5" component="div">
                                      {bodysitescount?.totalElements}
                                    </Typography>
                                    <Typography
                                      sx={{ fontSize: 8 }}
                                      color="text.secondary"
                                    >
                                      Last Updated :
                                      {moment(
                                        bodysitescount?.content &&
                                          bodysitescount?.content[0]
                                            .lastModifiedTime
                                      ).format("Do MMMM YYYY, h:mm:ss a")}
                                    </Typography>
                                  </CardContent>
                                </Card>
                              </Tooltip>
                            </Grid>
                            <Grid item xs={3}>
                              <Tooltip
                                title={
                                  access?.sampleview == true ? "" : "No access"
                                }
                                followCursor
                              >
                                <Card
                                  style={{ cursor: "pointer" }}
                                  onClick={(e) =>
                                    access?.sampleview == true
                                      ? onClickEvent("/dashboard/sample")
                                      : ""
                                  }
                                  sx={{ minWidth: 150, height: 100 }}
                                >
                                  <CardContent>
                                    <Typography
                                      sx={{ fontSize: 12 }}
                                      color="text.secondary"
                                      gutterBottom
                                    >
                                      SAMPLES
                                    </Typography>
                                    <Typography variant="h5" component="div">
                                      {data && data.totalElements}
                                    </Typography>
                                    <Typography
                                      sx={{ fontSize: 8 }}
                                      color="text.secondary"
                                    >
                                      Last Updated :
                                      {moment(
                                        data?.content &&
                                          data?.content[0].lastModifiedTime
                                      ).format("Do MMMM YYYY, h:mm:ss a")}
                                    </Typography>
                                  </CardContent>
                                </Card>
                              </Tooltip>
                            </Grid>
                            <Grid item xs={3}>
                              <Tooltip
                                title={
                                  access?.servicerequestview == true
                                    ? ""
                                    : "No access"
                                }
                                followCursor
                              >
                                <Card
                                  style={{ cursor: "pointer" }}
                                  onClick={(e) =>
                                    access?.servicerequestview == true
                                      ? onClickEvent("/dashboard/servicereq")
                                      : ""
                                  }
                                  sx={{ minWidth: 125, height: 100 }}
                                >
                                  <CardContent>
                                    <Typography
                                      sx={{ fontSize: 12 }}
                                      color="text.secondary"
                                      gutterBottom
                                    >
                                      SERVICE REQUESTS
                                    </Typography>
                                    <Typography variant="h5" component="div">
                                      {servicetotalcount?.totalElements}
                                    </Typography>
                                    <Typography
                                      sx={{ fontSize: 8 }}
                                      color="text.secondary"
                                    >
                                      Last Updated :
                                      {moment(
                                        servicetotalcount?.content &&
                                          servicetotalcount?.content[0]
                                            .lastModifiedTime
                                      ).format("Do MMMM YYYY, h:mm:ss a")}
                                    </Typography>
                                  </CardContent>
                                </Card>
                              </Tooltip>
                            </Grid>

                            <Grid item xs={3}>
                              <Tooltip
                                title={
                                  access?.packageview == true ? "" : "No access"
                                }
                                followCursor
                              >
                                <Card
                                  style={{ cursor: "pointer" }}
                                  onClick={(e) =>
                                    access?.packageview == true
                                      ? onClickEvent("/dashboard/package")
                                      : ""
                                  }
                                  sx={{ minWidth: 125, height: 100 }}
                                >
                                  <CardContent>
                                    <Typography
                                      sx={{ fontSize: 12 }}
                                      color="text.secondary"
                                      gutterBottom
                                    >
                                      PACKAGES
                                    </Typography>
                                    <Typography variant="h5" component="div">
                                      {packagetotalcount?.totalElements}
                                    </Typography>
                                    <Typography
                                      sx={{ fontSize: 8 }}
                                      color="text.secondary"
                                    >
                                      Last Updated :{" "}
                                      {moment(
                                        packagetotalcount?.content &&
                                          packagetotalcount?.content[0]
                                            .lastModifiedTime
                                      ).format("Do MMMM YYYY, h:mm:ss a")}
                                    </Typography>
                                  </CardContent>
                                </Card>
                              </Tooltip>
                            </Grid>
                            <Grid item xs={3}>
                              <Tooltip
                                title={
                                  access?.medicinemasterview == true
                                    ? ""
                                    : "No access"
                                }
                                followCursor
                              >
                                <Card
                                  style={{ cursor: "pointer" }}
                                  onClick={(e) =>
                                    access?.medicinemasterview == true
                                      ? onClickEvent(
                                          "/dashboard/medicinemaster"
                                        )
                                      : ""
                                  }
                                  sx={{ minWidth: 125, height: 100 }}
                                >
                                  <CardContent>
                                    <Typography
                                      sx={{ fontSize: 12 }}
                                      color="text.secondary"
                                      gutterBottom
                                    >
                                      MEDICINE MASTER
                                    </Typography>
                                    <Typography variant="h5" component="div">
                                      {medicinemastercount?.totalElements}
                                    </Typography>
                                    <Typography
                                      sx={{ fontSize: 8 }}
                                      color="text.secondary"
                                    >
                                      Last Updated :{" "}
                                      {moment(
                                        medicinemastercount?.content &&
                                          medicinemastercount?.content[0]
                                            .lastModifiedTime
                                      ).format("Do MMMM YYYY, h:mm:ss a")}
                                    </Typography>
                                  </CardContent>
                                </Card>
                              </Tooltip>
                            </Grid>
                            <Grid item xs={3}>
                              <Tooltip
                                title={
                                  access?.medicinedosageview == true
                                    ? ""
                                    : "No access"
                                }
                                followCursor
                              >
                                <Card
                                  style={{ cursor: "pointer" }}
                                  onClick={(e) =>
                                    access?.medicinedosageview == true
                                      ? onClickEvent(
                                          "/dashboard/medicineDosage"
                                        )
                                      : ""
                                  }
                                  sx={{ minWidth: 125, height: 100 }}
                                >
                                  <CardContent>
                                    <Typography
                                      sx={{ fontSize: 12 }}
                                      color="text.secondary"
                                      gutterBottom
                                    >
                                      MEDICINE DOSAGE INSTRUCTION
                                    </Typography>
                                    <Typography variant="h5" component="div">
                                      {medicinedosagecount?.totalElements}
                                    </Typography>
                                    <Typography
                                      sx={{ fontSize: 8 }}
                                      color="text.secondary"
                                    >
                                      Last Updated :{" "}
                                      {moment(
                                        medicinedosagecount?.content &&
                                          medicinedosagecount?.content[0]
                                            .lastModifiedTime
                                      ).format("Do MMMM YYYY, h:mm:ss a")}
                                    </Typography>
                                  </CardContent>
                                </Card>
                              </Tooltip>
                            </Grid>
                            <Grid item xs={3}>
                              <Tooltip
                                title={
                                  access?.medicinerouteview == true
                                    ? ""
                                    : "No access"
                                }
                                followCursor
                              >
                                <Card
                                  style={{ cursor: "pointer" }}
                                  onClick={(e) =>
                                    access?.medicinerouteview == true
                                      ? onClickEvent("/dashboard/medicineroute")
                                      : ""
                                  }
                                  sx={{ minWidth: 125, height: 100 }}
                                >
                                  <CardContent>
                                    <Typography
                                      sx={{ fontSize: 12 }}
                                      color="text.secondary"
                                      gutterBottom
                                    >
                                      MEDICINE ROUTE
                                    </Typography>
                                    <Typography variant="h5" component="div">
                                      {medcineroutecount?.totalElements}
                                    </Typography>
                                    <Typography
                                      sx={{ fontSize: 8 }}
                                      color="text.secondary"
                                    >
                                      Last Updated :{" "}
                                      {moment(
                                        medcineroutecount?.content &&
                                          medcineroutecount?.content[0]
                                            .lastModifiedTime
                                      ).format("Do MMMM YYYY, h:mm:ss a")}
                                    </Typography>
                                  </CardContent>
                                </Card>
                              </Tooltip>
                            </Grid>
                            <Grid item xs={3}>
                              <Tooltip
                                title={
                                  access?.medicinesiteview == true
                                    ? ""
                                    : "No access"
                                }
                                followCursor
                              >
                                <Card
                                  style={{ cursor: "pointer" }}
                                  onClick={(e) =>
                                    access?.medicinesiteview == true
                                      ? onClickEvent("/dashboard/medicinesite")
                                      : ""
                                  }
                                  sx={{ minWidth: 125, height: 100 }}
                                >
                                  <CardContent>
                                    <Typography
                                      sx={{ fontSize: 12 }}
                                      color="text.secondary"
                                      gutterBottom
                                    >
                                      MEDICINE SITE
                                    </Typography>
                                    <Typography variant="h5" component="div">
                                      {medicinesitecount?.totalElements}
                                    </Typography>
                                    <Typography
                                      sx={{ fontSize: 8 }}
                                      color="text.secondary"
                                    >
                                      Last Updated :{" "}
                                      {moment(
                                        medicinesitecount?.content &&
                                          medicinesitecount?.content[0]
                                            .lastModifiedTime
                                      ).format("Do MMMM YYYY, h:mm:ss a")}
                                    </Typography>
                                  </CardContent>
                                </Card>
                              </Tooltip>
                            </Grid>
                            <Grid item xs={3}>
                              <Tooltip
                                title={
                                  access?.medicinefrequencyview == true
                                    ? ""
                                    : "No access"
                                }
                                followCursor
                              >
                                <Card
                                  style={{ cursor: "pointer" }}
                                  onClick={(e) =>
                                    access?.medicinefrequencyview == true
                                      ? onClickEvent(
                                          "/dashboard/MedicineFrequency"
                                        )
                                      : ""
                                  }
                                  sx={{ minWidth: 125, height: 100 }}
                                >
                                  <CardContent>
                                    <Typography
                                      sx={{ fontSize: 12 }}
                                      color="text.secondary"
                                      gutterBottom
                                    >
                                      MEDICINE FREQUENCY
                                    </Typography>
                                    <Typography variant="h5" component="div">
                                      {medicinefrequencycount?.totalElements}
                                    </Typography>
                                    <Typography
                                      sx={{ fontSize: 8 }}
                                      color="text.secondary"
                                    >
                                      Last Updated :{" "}
                                      {moment(
                                        medicinefrequencycount?.content &&
                                          medicinefrequencycount?.content[0]
                                            .lastModifiedTime
                                      ).format("Do MMMM YYYY, h:mm:ss a")}
                                    </Typography>
                                  </CardContent>
                                </Card>
                              </Tooltip>
                            </Grid>
                            <Grid item xs={3}>
                              <Tooltip
                                title={
                                  access?.medicinedurationview == true
                                    ? ""
                                    : "No access"
                                }
                                followCursor
                              >
                                <Card
                                  style={{ cursor: "pointer" }}
                                  onClick={(e) =>
                                    access?.medicinedurationview == true
                                      ? onClickEvent(
                                          "/dashboard/medicineduration"
                                        )
                                      : ""
                                  }
                                  sx={{ minWidth: 125, height: 100 }}
                                >
                                  <CardContent>
                                    <Typography
                                      sx={{ fontSize: 12 }}
                                      color="text.secondary"
                                      gutterBottom
                                    >
                                      MEDICINE DURATION UOM
                                    </Typography>
                                    <Typography variant="h5" component="div">
                                      {medicineDurationcount?.totalElements}
                                    </Typography>
                                    <Typography
                                      sx={{ fontSize: 8 }}
                                      color="text.secondary"
                                    >
                                      Last Updated :{" "}
                                      {moment(
                                        medicineDurationcount?.content &&
                                          medicineDurationcount?.content[0]
                                            .lastModifiedTime
                                      ).format("Do MMMM YYYY, h:mm:ss a")}
                                    </Typography>
                                  </CardContent>
                                </Card>
                              </Tooltip>
                            </Grid>
                            <Grid item xs={3}>
                              <Tooltip
                                title={
                                  access?.medicinestrengthview == true
                                    ? ""
                                    : "No access"
                                }
                                followCursor
                              >
                                <Card
                                  style={{ cursor: "pointer" }}
                                  onClick={(e) =>
                                    access?.medicinestrengthview == true
                                      ? onClickEvent(
                                          "/dashboard/medicinestrenthuom"
                                        )
                                      : ""
                                  }
                                  sx={{ minWidth: 125, height: 100 }}
                                >
                                  <CardContent>
                                    <Typography
                                      sx={{ fontSize: 12 }}
                                      color="text.secondary"
                                      gutterBottom
                                    >
                                      MEDICINE STRENGTH UOM
                                    </Typography>
                                    <Typography variant="h5" component="div">
                                      {medicineStrengthcount?.totalElements}
                                    </Typography>
                                    <Typography
                                      sx={{ fontSize: 8 }}
                                      color="text.secondary"
                                    >
                                      Last Updated :{" "}
                                      {moment(
                                        medicineStrengthcount?.content &&
                                          medicineStrengthcount?.content[0]
                                            .lastModifiedTime
                                      ).format("Do MMMM YYYY, h:mm:ss a")}
                                    </Typography>
                                  </CardContent>
                                </Card>
                              </Tooltip>
                            </Grid>
                          </>
                        )}
                    </Grid>
                  ) : (
                    <Grid container spacing={2}>
                      <Grid item xs={3}>
                        <Tooltip
                          title={access?.userview == true ? "" : "No access"}
                          followCursor
                        >
                          <Card
                            style={{ cursor: "pointer" }}
                            onClick={(e) =>
                              access?.userview == true
                                ? onClickEvent("/dashboard/user")
                                : ""
                            }
                            sx={{ minWidth: 125, height: 100 }}
                          >
                            <CardContent>
                              <Typography
                                sx={{ fontSize: 12 }}
                                color="text.secondary"
                                gutterBottom
                              >
                                USERS
                              </Typography>
                              <Typography variant="h5" component="div">
                                {user.data && user.data.totalElements}
                              </Typography>
                              <Typography
                                sx={{ fontSize: 8 }}
                                color="text.secondary"
                              >
                                {/* Last Updated : */}
                                {/* {new Date(parseFloat(user.data?.content && user.data?.content[0] &&  user.data?.content[0].lastModifiedTime.toString().replace(".", ""))).toLocaleString()} */}
                                Last Updated :
                                {moment(
                                  user.data?.content &&
                                    user.data?.content[0] &&
                                    user.data?.content[0].lastModifiedTime *
                                      1000
                                ).format("Do MMMM YYYY, h:mm:ss a")}
                              </Typography>
                            </CardContent>
                          </Card>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  )}
                </>
              </div>

              <div className="sideImageContainer w-3/12 flex flex-col items-center h-72 mt-4 pl-2">
                <div className="sideImageHeading  mb-2 font-mono font-medium">
                  <h2 style={{ fontSize: "16px" }}>
                    {" "}
                    Welcome,{" "}
                    {adminDetails &&
                      adminDetails?.name
                        ?.charAt(0)
                        .toUpperCase()
                        .concat(
                          adminDetails &&
                            adminDetails?.name?.split(" ")[0].slice(1)
                        )}{" "}
                    {adminDetails &&
                      adminDetails?.name
                        ?.split(" ")[1]
                        .charAt(0)
                        .toUpperCase()
                        .concat(
                          adminDetails &&
                            adminDetails?.name?.split(" ")[1].slice(1)
                        )}
                  </h2>
                </div>

                <div className="sideImageContainer h-36">
                  <figure>
                    <img
                      className="h-36"
                      src={sideImageDashboardHome}
                      alt="Doctor Conversation"
                    />
                  </figure>
                </div>
              </div>
            </div>
            {/* bottomSiteMapsLink */}

            {/* <div className="w-mywidth-8 bg-white ml-2 mr-2 mt-4 rounded-md  ">
                            <h1 className="text-d-color-2 p-4 text-lg font-medium">MASTERS</h1>
                            <div className="flex">

                                {
                                    siteMapJson.map((v, i) => {
                                        return (
                                            <>
                                                <SiteMapBoxChild keyA={i + 1} heading={v.heading} content={v.content}
                                                    itemOne={v.itemOne}
                                                    itemTwo={v.itemTwo}
                                                    itemThree={v.itemThree}
                                                    itemFour={v.itemFour}
                                                    itemFive={v.itemFive}
                                                    itemOnePath = {v?.itemOnePath}
                                                    itemTwoPath = {v?.itemTwoPath}
                                                    itemThreePath = {v?.itemThreePath}
                                                    itemFourPath = {v?.itemFourPath}
                                                    itemFivePath = {v?.itemFivePath}
                                                    onClickEvent = {onClickEvent}
                                                />
                                            </>
                                        )
                                    })
                                }
                            </div>
                        </div> */}
          </div>
         
        </div>
      </div>
      <AdminCookieConsentBanner />
    </>
   
  );
};
{
  /* <siteMapJson to="/dashboard/patientimport" content="Create New Records" heading="Bulk Import" /> */
}

const siteMapJson = [
  //     {
  //         heading: "Administration",
  //         content: "Create/Manage Locations, Organisations and more ",
  //         itemOne: "Organisations",
  //         itemOnePath : "/dashboard/organization",
  //         itemTwo: "Locations",
  //         itemTwoPath : "/dashboard/location",
  //         itemFour: "Users",
  //         itemFourPath : "/dashboard/user",
  //         itemFive:  "Healthcare Practitioners",
  //         itemFivePath : "/dashboard/healthcarePractitioner",
  //         // itemThree: "Roles",
  //     }
  //   ,
  //     {
  //         heading: "Clinical ",
  //         content: "Create/Manage Clinical Masters",
  //         itemOne: "Encounter Types",
  //         itemOnePath : "/dashboard/encounterTypes",
  //         itemTwo: "Bodysites",
  //         itemTwoPath : "/dashboard/bodysites",
  //         // itemTwo: "Report Category",
  //         // itemThree: "Sample Types",
  //         // itemFour: "Clinical Notes",
  //         // itemFive: "Sections",
  //     },
  //     {
  //         heading: "Services",
  //         content: "Create/Manage Service Requests",
  //         itemOne: "Samples",
  //         itemOnePath : "/dashboard/sample",
  //         itemTwo: "Service Requests",
  //         itemTwoPath : "/dashboard/servicereq",
  //         itemThree: "Packages",
  //         itemThreePath : "/dashboard/package",
  //         // itemThree: "Medical Codes",
  //         // itemFive: "Body Site",
  // },
  // {
  //     heading: "Command Centre",
  //     content: "Create/Manage CC related masters ",
  //     itemOne: "Task Type",
  //     itemOnePath : "/dashboard/taskType",
  //     itemTwo: "Task Resolution",
  //     itemTwoPath : "/dashboard/taskResolution",
  //     itemThree: "Tags",
  //     itemThreePath : "/dashboard/tags",
  // },
  // {{
  //     heading: "Partners",
  //     content: "Create/Manage Partner & Subscription Details",
  //     itemOne: "Oganization",
  //     itemTwo: "Locations",
  //     itemThree: "Roles",
  //     itemFour: "Users",
  //     itemFive: "Practitioners",
  // }}
];

const SiteMapBoxChild = (props) => {
  return (
    <>
      {/* <NavLink to={props}> */}
      <div
        key={props.key}
        className="  border border-o-color-two h-72 w-64 ml-4 rounded-mysix"
      >
        <h2 className="pt-3 pl-5 text-lg font-medium text-d-color-3">
          {props.heading}
        </h2>
        <p className=" pl-5 pr-2 text-o-form-label-color text-sm font-normal">
          {props.content}
        </p>
        <ol className="pt-5 pl-5" style={{ cursor: "pointer" }}>
          <SiteMapLi
            item={props.itemOne}
            pathname={props.itemOnePath}
            onClickEvent={props.onClickEvent}
          />
          <SiteMapLi
            item={props.itemTwo}
            pathname={props.itemTwoPath}
            onClickEvent={props.onClickEvent}
          />
          <SiteMapLi
            item={props.itemThree}
            pathname={props.itemThreePath}
            onClickEvent={props.onClickEvent}
          />
          <SiteMapLi
            item={props.itemFour}
            pathname={props.itemFourPath}
            onClickEvent={props.onClickEvent}
          />
          <SiteMapLi
            item={props.itemFive}
            pathname={props.itemFivePath}
            onClickEvent={props.onClickEvent}
          />
        </ol>
      </div>
      {/* </NavLink> */}
    </>
  );
};

const SiteMapLi = (props) => {
  // console.log(props)
  return (
    <>
      {/* <NavLink to={props.pathname}> */}
      <li
        className="text-d-color-3 font-normal text-lg"
        onClick={(e) => props.onClickEvent(props.pathname)}
      >
        {props.item}
      </li>
      {/* </NavLink>          */}
    </>
  );
};

const UserCardComp = (props) => {
  return (
    <>
      <div className="mainusercard shadow-md rounded-userCardRadius w-mywidth-4 h-userCardHeight">
        <div className="cardHeader h-1/3">
          <h1 className="font-medium text-userCardHeadingColor text-sm p-2">
            {props.heading}
          </h1>
        </div>
        {/* <Divider variant="middle"  /> */}
        <div className="userCardFooter flex flex-col justify-center  h-2/3  w-full">
          <b className="text-black text-4xl pl-1">{props.number}</b>
          <p className="font-medium text-xs pl-2 text-userCardFooterColor">
            Last Updated : 18 March , 2022
          </p>
          {/* <div className="h-8 flex justify-between items-center  w-full ">
                  <b className="text-iconColor pl-1">{props.number}</b>
                  <p className="font-medium text-xs pr-1 text-userCardFooterColor">Last Updated : 13 Jun , 2021</p>
                 
 
                 </div> */}
        </div>
      </div>
    </>
  );
};

export default Home;
