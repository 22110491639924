import * as React from "react";
import { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Link from "@mui/material/Link";
import { DataGrid } from "@mui/x-data-grid";
import autoprefixer from "autoprefixer";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  useGetActiveOrgValueMutation,
  useGetActiveDistrictValueMutation,
} from "../../../services/adminLogIn";
import useHealthcarePractitionerLogic from "./healthcarePractitionerlogic";
import "../Organization/Mainhome.css";
import { Grid } from "@mui/material";
import "../table.css";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

const HealthcareList = (props) => {
  const { handleDeleteRow } = props;
  const handleClick = (row) => {
    props.showUpdateData(row)
  };

  const handleOnChange = (event, page) => {
    props.handlePaginationClick(page);
  };

  return (
    <Grid>
      <div class="scrollit">
        {props.healthcarepractitionerupdate===true && props.list && props.list.length > 0 ? (
          <table
            style={{
              width: "90%",
              marginLeft: "20px",
              tableLayout: "fixed",
              height: "20vh",
            }}
          >
            <thead>
              <tr>
                <th className=" m-r-f" style={{ width: "50px" }}>
                  <input type="checkbox" id="" name="vehicle1" value="Bike" />
                </th>
                <th className=" m-r-f">Healthcare Practitioner Name</th>
                <th className=" m-r-f">Email ID</th>
                <th className=" m-r-f">Registration Number</th>
                <th className="m-r-f"> Action </th>
              </tr>
            </thead>
            <tbody style={{ color: "#000000" }}>
              {props &&
                props.list &&
                props.list.length > 0 &&
                props.list.map((item, i) => {
                  return (
                    <tr
                      key={i}
                      style={{
                        cursor: "pointer",
                        backgroundColor:
                          props.id && props.id === item.serviceRequestMasterId
                            ? "rgb(15, 67, 145)"
                            : "",
                        color:
                          props.id && props.id === item.serviceRequestMasterId
                            ? "white"
                            : "",
                      }}
                    >
                      <td>
                        <input type="checkbox" id={i} />
                      </td>
                      <td
                        className=" m-r-f"
                        onClick={(event) => handleClick(item)}
                      >{`${item.firstName} ${item.middleName} ${item.lastName}`}</td>
                      <td
                        className=" m-r-f"
                        onClick={(event) => handleClick(item)}
                      >
                        {item.emailId}
                      </td>
                      <td
                        className=" m-r-f"
                        onClick={(event) => handleClick(item)}
                      >
                        {item.registrationNumber}
                      </td>
                      <td
                        className=" m-r-f"
                      >
                        <DeleteIcon onClick={()=> handleDeleteRow(item)} />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        ) : (
          <div className="record">No Records Found</div>
        )}
      </div>

      { props.healthcarepractitionerupdate===true && props.list && props.list.length > 0 && (
        <div className="pagesam pt-1 m-1">
          <Stack spacing={2}>
            <Pagination
              count={props.totalPage}
              color="primary"
              onChange={(e, page) => handleOnChange(e, page)}
            />
          </Stack>
          <div className="pagenum">
            <span className="ml-2">
              {props.calculateData.number * 20 + 1}-
              {props.calculateData.number * 20 +
                props.calculateData.numberOfElements}{" "}
              of {props.calculateData.totalElements}
            </span>
          </div>
        </div>
      )}
    </Grid>
  );
};

export default HealthcareList;
