import * as React from "react";
import { useState, useEffect, useRef } from "react";
import { Grid } from "@mui/material";
import "../table.css";
import "react-toastify/dist/ReactToastify.css";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

const Jobslist = (props) => {
  const { hasFocus, value ,jobdataList} = props;
  const handleClick = (row,i) => {
    // if (props.sampleupdate == true) {
    //   let data = {};
    //   data.is_update = true;
    //   props.setAllTextFieldState({ "job_name":row,is_update:true});
    //   props.showFormActions();
    //   props.setErrorMessage(false);
    //   props.setId(i);
    // } 
  };

  const handleOnChange = (event, page) => {
    props.getjobdatalistPage(page)
  }; 
  return (
    <Grid className="">
      <div class="scrollit">
        {
        jobdataList &&
        jobdataList?.data?.content &&
        jobdataList?.data?.content &&
        jobdataList?.data?.content?.length > 0 ? (
          <table
            className="b "
            style={{
              width: "60%",
              marginLeft: "20px",
              tableLayout: "fixed",
              height: "20vh",
            }}
          >
            <thead style={{ position: "sticky", zIndex: "1", top: "0" }}>
              <tr>
                <th className=" m-r-f">Job Name</th>
                
              </tr>
             
            </thead>
            <tbody>
              {jobdataList &&
             jobdataList?.data?.content &&
             jobdataList?.data?.content.length > 0 &&
             jobdataList?.data?.content?.map((item, i) => {
                  return (
                    <tr
                      key={i}
                      className="tablebor"
                      style={{
                        cursor: "pointer",
                        backgroundColor:
                          props.id === i
                            ? "rgb(15, 67, 145)"
                            : "",
                        color: props.id === i ? "white" : "",
                      }}
                    >
                      <td
                        className="  m-r-f"
                        onClick={(event) => handleClick(item,i)}
                        style={{ cursor: "pointer" }}
                      >
                        <a>{item}</a>
                      </td>
                      
                    </tr>
                  );
                })}
            </tbody>
          </table>
        ) : (
          <div className="record">No Records Found</div>
        )}
      </div>
      {jobdataList?.data?.content &&jobdataList?.data?.content && jobdataList?.data?.content?.length>0 &&
      <div className="pagesam pt-1 m-1">
        
        <Stack spacing={2}>
          <Pagination count={props?.totalPage} color="primary" page={props.currentPage} onChange={(e, page) => handleOnChange(e, page)} />
        </Stack>
        <div className='pagenum'>
          <span className='ml-2'>{props?.calculateData?.number * 20 + 1}-{props?.calculateData?.number * 20 + props?.calculateData?.numberOfElements} of {props?.calculateData?.totalElements}</span>
        </div>
      </div>
        }
    </Grid>
  );
};

export default Jobslist;
