import * as React from "react";
import { useState, useEffect, useRef } from "react";
import { Grid } from "@mui/material";
import "../table.css";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DeleteIcon from "@mui/icons-material/Delete";
import Tooltip from '@mui/material/Tooltip';

const DoctorpanelList = (props) => {
    
  const { hasFocus, value } = props;

  const userList = [
    {
      "location":"Shabiya",
      "encounter":"testing",
      "status":true,
       "panelmember" : ["user","delete","add","edit"]
    }
  ]
  const handleClick = (row,i) => {
    if (props.sampleupdate == true) {
      let data = {};
      data.is_update = true;
      data.value = row.value;
      props.setAllTextFieldState({ "reportCategoryName":row,is_update:true});
      props.showFormActions();
      props.setErrorMessage(false);
      props.setId(i);
    } 
  };


  return (
    <Grid className="">
      <div class="scrollit">
        {userList?.length > 0 ? (
          <table
            className="b "
            style={{
              width: "90%",
              marginLeft: "20px",
              tableLayout: "fixed",
              height: "20vh",
            }}
          >
            <thead style={{ position: "sticky", zIndex: "1", top: "0" }}>
              <tr>
              <th className=" m-r-f" style={{ width: '50px' }}><input type="checkbox" id="" name="vehicle1" value="Bike" /></th>
                <th className=" m-r-f">Location</th>
                <th className=" m-r-f">Encounter</th>
                <th className=" m-r-f">Panel Members</th>
                <th className=" m-r-f">Status</th>
              </tr>
            </thead>
            <tbody>
              {userList.map((item, i) => {
                  return (
                    <tr  key={i}
                    className="tablebor"
                    style={{
                      cursor: "pointer",
                      backgroundColor:
                        props.id === i
                          ? "rgb(15, 67, 145)"
                          : "",
                      color: props.id === i ? "white" : "",
                    }}>
                    <td  >< input type="checkbox"  /></td>
                    <td className="  m-r-f" onClick={event => handleClick(item)} ><a>{item.location}</a></td>
                    <td className="  m-r-f" onClick={event => handleClick(item)} ><a>{item.encounter}</a></td>
                    <Tooltip title={item.panelmember == true ? "" : "garima@gmail.com"+"  " +"shabiya@gmail.com"+ "   " +"sirisha@gmail.com"} followCursor>
                    <td className="  m-r-f" onClick={event => handleClick(item)} ><a>{item.panelmember[0] + "  +3"}</a></td>
                    </Tooltip>
                    <td className=" m-r-f" onClick={event => handleClick(item)}> 
                  
                      </td>
                   

                  </tr>
                  );
                })}
            </tbody>
          </table>
        ) : (
          <div className="record">No Records Found</div>
        )}
      </div>
      {/* {props && props.data && props.data.content && props.data.content.length>0 && */}
      {/* <div className="pagesam pt-1 m-1">
        <Stack spacing={2}>
          <Pagination
            count={props.data?.totalPages}
            color="primary"
            page={props.currentPage}
            onChange={(e, page) => handleOnChange(e, page)}
          />
        </Stack>
        <div className="pagenum">
          <span className="ml-2">
            {props?.data?.number * 20 + 1}-
            {props?.data?.number * 20 + props?.data?.numberOfElements} of{" "}
            {props?.data?.totalElements}
          </span>
        </div>
      </div> */}
      {/* }    */}
    </Grid>
  );
};

export default DoctorpanelList;
