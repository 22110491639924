import * as React from 'react';
import { useState, useEffect, useRef } from "react";
import { Grid } from '@mui/material';
import "../table.css"
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Delete from '../../global/icons/delete.png';
import DeleteWhite from '../../global/icons/unnamed.png';

const MedicinerouteList = (props) => {
  
  const { hasFocus, value } = props;
  const [id, setId] = useState(0);


  const handleClick = (row) => {
    props.setId(row.code)  
    setId(row.code)
    // props.getUserByIdFunct(row.lastModifiedBy,row.tagMasterId)
    props.showFormActions()
    let sys = {}
    props.bodysiteSystem.forEach(element => {
        if (element.system === row.system) {
            sys = element
        }
      });
      props.setTextFieldState({...row , 'is_update':true,'system':sys})
  }

  const handleOnChange = (event, page) => {
    props.getMedicineDosagePage(page)

  }; 
  return (
    <Grid className="" >
      <div class="scrollit">
      { props.tagsview===true && props.MedicinerouteList && props.MedicinerouteList.length > 0  ?
    
          <table className='b ' style={{ width: '90%', marginLeft: '20px', tableLayout: "fixed", height: "20vh" }}>
            <thead style={{ position: 'sticky', zIndex: '1', top: '0' }}>
              <tr>
                <th className=" m-r-f" style={{ width: '50px' }}><input type="checkbox" id="" name="vehicle1" value="Bike" /></th>
                <th className=" m-r-f">Display</th>
                <th className=" m-r-f">Code</th>
                <th className=" m-r-f">System</th>
                <th className=" m-r-f">Action</th>

              </tr>
            </thead>
            <tbody>

              {props.MedicinerouteList&& props.MedicinerouteList && props.MedicinerouteList.length > 0 && props.MedicinerouteList.map((item, i) => {
                return (
                  //
                  <tr key={i} className="tablebor"  style={{ cursor: 'pointer', backgroundColor: id === item.code ? "rgb(15, 67, 145)" : "", color: id === item.code ? "white" : "" }} >
                    <td  >< input type="checkbox" id={i} /></td>
                    <td className="  m-r-f" onClick={event => handleClick(item)} ><a>{item.display}</a></td>
                    <td className="  m-r-f" onClick={event => handleClick(item)} ><a>{item.code}</a></td>
                    <td className="  m-r-f" onClick={event => handleClick(item)} style={{ cursor: 'pointer', }}  >{item.system=="http://karkinos.in/others"? "OTHERS" : item.system=="http://loinc.org"?"LOINC":item.system=="http://snomed.info/sct"?"SNOMED":""}</td>
                    <td className=" m-r-f" onClick={event => handleClick(item)}> 
                    <figure>
                      <img
                    className="h-10"
                    src={id === item.code ? DeleteWhite : Delete} 
                    style={{
                      height:
                      id === item.code
                          ? "23px"
                          : "",
                      marginLeft: id === item.code ? "10px" : "",
                    }}
                    onClick={() => props.handleDelete(item)} 
                      />
                    </figure>
                      </td>
                   

                  </tr>

                )
              })
            }
              
            </tbody>

          </table>
          : <div className='record'>
            No Records Found
          </div>}
      
      </div>
      
      {props.tagsview===true && props.MedicinerouteList&& props.MedicinerouteList && props.MedicinerouteList.length>0 &&
      <div className="pagesam pt-1 m-1">
        
        <Stack spacing={2}>
          <Pagination count={props?.totalPage} color="primary" page={props.currentPage} onChange={(e, page) => handleOnChange(e, page)} />
        </Stack>
        <div className='pagenum'>
          <span className='ml-2'>{props?.calculateData?.number * 20 + 1}-{props?.calculateData?.number * 20 + props?.calculateData?.numberOfElements} of {props?.calculateData?.totalElements}</span>
        </div>

       
      </div>
        }
    </Grid>

  );

}

export default MedicinerouteList
