import { useState, useEffect, useRef } from "react";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import TextField from "@mui/material/TextField";
import FailedDataSyncJobsList from "./failedDataSyncJobsList";
import { Button } from "@mui/material";
import {
  useGetJobNameAndFailureCountMutation,
  useProcessFailedDataSyncJobsMutation,
} from "../../../services/adminLogIn";
import RefreshIcon from "@mui/icons-material/Refresh";
import "../Organization/Mainhome.css";
import { Grid } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { toasterFunction } from "../../../globalFunctions/getLocalStorageData";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminCookieConsentBanner from "../home/AdminCookieConsentBanner";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const ReportCategory = (props) => {
  const [getJobNameAndFailureCount] = useGetJobNameAndFailureCountMutation();
  const [processFailedData] = useProcessFailedDataSyncJobsMutation();

  const [jobNameList, setJobNameList] = useState([]);
  const [access, setAccess] = useState(
    JSON.parse(sessionStorage.getItem("access"))
  );
  const [open, setOpen] = useState(false);
 
 
  const jobNameAndFailureCountList = async () => {
    const res = await getJobNameAndFailureCount();
    setJobNameList(Object.entries(res.data));
  };

  const handleProcess = async (item) => {
    let payload = ["c", "u", "d"];
    if(item === "CARE_TEAM_APRTICIPANT_JOB" || item === "PRESCRIPTION_RECORD_JOB") {
      payload = ["du", "d"];
    }
    let res 
    for (var i = 0; i < payload.length; i++) {
      const dataToSend = {
      type : payload[i],
      jobName: item
      }
      res = await processFailedData(dataToSend);
     
    }
    if(res){
      toasterFunction("Processing started", "success");
     }
     else{
      toasterFunction("Something went wrong", "error");
     }
   
  };
  const [isFormVisible, setFormVisible] = useState({
    isOpen: false,
    isClose: true,
  });
  const [id, setId] = useState("");

  useEffect(() => {
    jobNameAndFailureCountList();
  }, []);


  return (
    <>
      <div className={props.expand1.empty === true ? "main1" : "main"}>
        <div className="breadCumContainer flex justify-start items-center h-9 w-full bg-o-color-one organ1">
          <ol className="ml-8 flex " style={{ listStyleType: "none" }}>
            <div className="flex mt-1">
              <li className="w-28 h-thirtyPix rounded-mysix flex justify-center items-center font-medium bg-white m-r-f orgbox">
                <span>JObs</span>
              </li>
            </div>
            <i className="right"></i>
            <div className="flex mt-1">
              <li
                className="w-40 h-thirtyPix rounded-mysix flex justify-center items-center font-medium bg-white m-r-f orgbox3 rows breadback"
                style={{ padding: "0px" }}
              >
                <span>Failed Sync Jobs</span>
              </li>
            </div>
          </ol>
        </div>
        <ToastContainer />
        <Grid container mt={-2}>
          <Grid item xs={6}>
            <div
              className={
                isFormVisible.isOpen
                  ? "leftcontainer rounded-lg"
                  : "leftcontainer1 rounded-lg"
              }
            >
              <Grid className=" p-7 ">
                <TextField
                  label=""
                  onKeyDown={(e) => {
                    e.key === "Enter" && e.preventDefault();
                  }}
                  sx={{ m: 1, width: "25ch" }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchOutlinedIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid className="editContainer rounded-radiusFourPix ">
              <div className=" m-r-f ">
              <Button
                variant="outlined"
                onClick={() => jobNameAndFailureCountList()}
                startIcon={<RefreshIcon />}
              >
                Refresh
              </Button>
            </div>
                </Grid>
           
              <>
                <FailedDataSyncJobsList
                  setId={setId}
                  id={id}
                  jobNameAndFailureCountList={jobNameAndFailureCountList}
                  handleProcess={handleProcess}
                  jobNameList={jobNameList}
                />
              </>
            </div>
          </Grid>
        </Grid>
      </div>
      <AdminCookieConsentBanner />
    </>
  );
};

export default ReportCategory;
