// import { LocalConvenienceStoreOutlined } from "@mui/icons-material";
import { useState } from "react";
// import isEmail from 'validator/es/lib/isEmail';
// import isMobilePhone from 'validator/es/lib/isMobilePhone';
import { useCreateTagMasterMutation, useCreatingSampleMutation, useUpdatigsampleMutation } from "../../../services/adminLogIn";

const TagLogic = () => {


    const [showerror, setErrorMessage] = useState(false);

    const [orgState, setOrgState] = useState();
    const [AllTextFieldState, setTextFieldState] = useState({
        is_update: false,
        tagMasterId: '',
        tagMasterName: '',
        tagMasterType: '',
        description: '',
        followUpBeforeHours: '',
        followUpCallback: '',
        icon:'',
        roles:'',
        color: '',
        status: ''
    });


    const [open, setOpen] = useState(false);
    const [openFilterModal, setOpenFilterModal] = useState(false);
    const [isSearchEvent, setIsSearchEvent] = useState(false);
    const [status, setStatus] = useState("");
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleFilterModalOpen = () => { setOpenFilterModal(true); }
    const handleFilterModalClose = () => { setOpenFilterModal(false); }

    const [filterData, setFilterData] = useState({ classificationId: "", sampleName: "", filterStatus: "" });


    const [dropdownState, setDropdowntate] = useState({
        sampleClassificationId: '', sampleClassificationName: ''
    });

    const [isError, setError] = useState({
        isTagMasterNameError: false,
        isTagMasterTypeError: false,
        isColor: false
    });

    // dropdown handler 
    const handleDropDownChange = (e, v) => {
        const value = v;
        if (e === "tagMasterType") {
            if (value == "Regular Tag") {
                setTextFieldState((prev) => ({
                    ...prev,
                    [e]: "GENERIC",
                    ['followUpCallback']: "",
                    ['followUpBeforeHours']: 0,

                }))
            }

            if (value == "Date Tag") {
                setTextFieldState((prev) => ({
                    ...prev,
                    [e]: "FOLLOWUP",
                    ['followUpCallback']: "FOLLOWUP",
                    ['followUpBeforeHours']: 2,

                }))
            }
            setError((prev) => ({
                ...prev,
                isTagMasterTypeError: isError.isTagMasterTypeError = false
            }))
        }
       
    
    if (e === "status") {
        handleOpen()
    }

}

const halndleClickonProceed = () => {
    setTextFieldState((prev) => ({
        ...prev,
        ["status"]: AllTextFieldState.status === "ACTIVE" ? "INACTIVE" : "ACTIVE"

    }))
    setStatus(AllTextFieldState.status === "ACTIVE" ? "DELETED" : "ACTIVE")
    handleClose()
}
const getDistrictValFun1 = (value) => {
    //   getDistrictValFun(value)
}

const resetErrorHandler = (e) => {
    const name = e.target.name;
    switch (name) {
        case "tagMasterName":
            setError((prev) => ({
                ...prev,
                isTagMasterNameError: isError.isTagMasterNameError = false
            }))
            break;

        case "tagMasterType":
            setError((prev) => ({
                ...prev,
                isTagMasterTypeError: isError.isTagMasterTypeError = false
            }))
            break;
        case "color":
            setError((prev) => ({
                ...prev,
                isColor: isError.isColor = false
            }))
            break;
    }
}

// form on submit handleer 

const whenInputsChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
        case "tagMasterName":
            setError((prev) => ({
                ...prev,
                isTagMasterNameError: isError.isTagMasterNameError = false
            }))
            break;


    }
    setTextFieldState((prev) => ({
        ...prev,
        [name]: value

    }))


}
// exporting all handler and state and etc
const exportAll = {
    whenInputsChange,
    handleDropDownChange,
    orgState,
    //    dropsampleState,
    setError,
    AllTextFieldState,
    isError,
    resetErrorHandler,
    setTextFieldState,
    //    setDropsampleState,
    getDistrictValFun1,
    dropdownState,
    setDropdowntate,
    open,
    handleClose,
    handleOpen,
    setOpen,
    halndleClickonProceed,
    setErrorMessage,
    handleFilterModalOpen,
    handleFilterModalClose,
    openFilterModal,
    showerror,
    filterData,
    isSearchEvent,
    setIsSearchEvent,
    //    districtlist

}

return exportAll;
}

export default TagLogic;
