import * as React from "react";
import { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Link from "@mui/material/Link";

import { DataGrid } from "@mui/x-data-grid";
import autoprefixer from "autoprefixer";
import {
  useGetActiveOrgValueMutation,
  useGetActiveStateValueMutation,
} from "../../../services/adminLogIn";
import { Grid, Button, IconButton } from "@mui/material";
import "../Organization/Mainhome.css";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack"; 
import LockOpenIcon from '@mui/icons-material/LockOpen';
import Tooltip from '@mui/material/Tooltip';
import Unlock from '../../global/icons/unlock.png';
import UnlockWhite from '../../global/icons/unlockwhite.svg';
import moment from "moment";

const UserTable = (props) => {
  const { handleunlockerow, setCurrentRowItem, currentRowItem } = props;
  const { hasFocus, value } = props;
  const [data, setData] = useState([]);
  const [list, setList] = useState([]);
  const [access, setAccess] = useState(
    JSON.parse(sessionStorage.getItem("access"))
  );

  const handleClick = (row) => {
    if (access?.rolesview){
      if (props.userupdate == true) {
        let data = {};
        data.is_update = true;
        data.userName = row.userName;
        data.userId = row.userId;
        data.salutation = row.salutation;
        data.firstName = row.firstName;
        data.middleName = row.middleName;
        data.lastName = row.lastName;
        data.dob = row.dob;
        data.email = row.email != null ? row.email : "";;
        data.gender = row.gender;
        data.mobileNumber = row.mobileNumber != null ? row.mobileNumber : "";
        data.deleted = row.deleted;
        data.roles = row.roles === false ? [] : row.roles;
        data.locations = row.locations != null ? row.locations : [];
        data.organizations = row.organizations != null ? row.organizations : [];
        data.imageType = row.imageType;
        data.imageUrl = row.imageUrl;
        //data.profilePhoto = row.profilePhoto;
        props.setId(row.userId);
       let isPractioner =  row.roles.find(function (element) {
          return element === "practitioner" ? true : false;
      });
        props.setPhoto(row.profilePhoto);
        props.setUserTextFieldState({
          ...data,
          is_update: true,
          lastModifiedTime: row.lastModifiedTime,
        });
        props.getUserByIdFunct(row.createdBy);
        // props.setUserTextFieldState({ ...data });
        props.showFormActions();
        props.setErrorMessage(false);
        props.setRoleList(data.roles);
  
        props.isUserCanInactivateFunc(data.roles);
      }
      else {
        alert("you didnt have permission")
      }
    }
    else
    if(access?.ccadmin){
      if (props.userupdate == true) {
        if ((row.roles.includes('command_center'))){
          let data = {};
          data.is_update = true;
          data.userName = row.userName;
          data.userId = row.userId;
          data.salutation = row.salutation;
          data.firstName = row.firstName;
          data.middleName = row.middleName;
          data.lastName = row.lastName;
          data.dob = row.dob;
          data.email = row.email != null ? row.email : "";;
          data.gender = row.gender;
          data.mobileNumber = row.mobileNumber != null ? row.mobileNumber : "";
          data.deleted = row.deleted;
          data.roles = row.roles === false ? [] : row.roles;
          data.locations = row.locations != null ? row.locations : [];
          data.organizations = row.organizations != null ? row.organizations : [];
          data.imageType = row.imageType;
          data.imageUrl = row.imageUrl;
          //data.profilePhoto = row.profilePhoto;
          props.setId(row.userId);
         let isPractioner =  row.roles.find(function (element) {
            return element === "practitioner" ? true : false;
        });
        console.log(isPractioner)
          props.setIsHealthcarePractitioner(isPractioner==="practitioner"? true :false);
          props.setPhoto(row.profilePhoto);
          props.setUserTextFieldState({
            ...data,
            is_update: true,
            lastModifiedTime: row.lastModifiedTime,
          });
          props.getUserByIdFunct(row.createdBy);
          // props.setUserTextFieldState({ ...data });
          props.showFormActions();
          props.setErrorMessage(false);
          props.setRoleList(data.roles);
    
          props.isUserCanInactivateFunc(data.roles);
          // setCurrentRowItem(row);
        }
        else {
          alert("you didnt have permission")
        }
        }
        
    }
   

  };
  const handleScroll = (event) => {
    // console.log('scrollTop: ', event.currentTarget.scrollTop);
    // console.log('offsetHeight: ', event.currentTarget.offsetHeight);
  };
  const handleOnChange = (event, page) => {
    props.getuserpageList(page);
  };
  return (
    <Grid className="">
      <div class="scrollit">
       
        {props.userview === true && props.userList && props.userList.length > 0 ? (
          <table
            className="b "
            style={{
              width: "90%",
              marginLeft: "20px",
              tableLayout: "fixed",
              height: "20vh",
            }}
        
          >
            <thead style={{ position: "sticky", zIndex: "1", top: "0" }}>
              <tr>
                <th className=" m-r-f" style={{ width: "50px" }}>
                  <input type="checkbox" id="" name="vehicle1" value="Bike" />
                </th>
                <th className=" m-r-f" style={{}} >
                  Full Name
                </th>
                <th className=" m-r-f" style={{}}>
                  Email Id
                </th>
                <th className=" m-r-f" style={{}}>
                  Status
                </th>
                <th className="m-r-f" style={{}}> Action </th>
              </tr>
            </thead>
            <tbody onScroll={handleScroll}>
              
              {props.userList &&
                props.userList.length > 0 &&
                props.userList.map((item, i) => {
                  
                  return (
                    <tr
                      key={i}
                      style={{
                        cursor: "pointer",
                        backgroundColor:
                          props.id === item.userId ? "rgb(15, 67, 145)" : "",
                        color: props.id === item.userId ? "white" : "",
                      }}
                    >
                      <td>
                        <input type="checkbox" id={i} />
                      </td>
                      <td
                        className="  m-r-f"
                        onClick={(event) => handleClick(item)}
                       
                      >
                        <a>{item.fullName}</a>
                      </td>
                      <td
                        className="  m-r-f"
                        onClick={(event) => handleClick(item)}
                      >
                        {item.email}
                      </td>
                      <td
                        className="  m-r-f"
                        onClick={(event) => handleClick(item)}
                      >
                        <span
                          className={item.deleted === false ? "dotg" : "dotr"}
                        ></span>{" "}
                        {item.deleted === false ? " Active" : " Inactive"}
                      </td>
                      <td
                        className=" m-r-f"
                      >


                        <IconButton  >
                          <Tooltip title={props?.unlockuser == true ? "" : "No access"} followCursor>
                            <figure>
                              <img
                                className="h-10"
                                src={props.id === item.userId ? UnlockWhite : Unlock}

                                onClick={() => props?.unlockuser == true ? handleunlockerow(item) : ""}
                              />

                            </figure>
                          </Tooltip>
                        </IconButton>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        ) : (
          <div className="record">No Records Found</div>
        )}
      </div>


      {props.userview === true && props.userList && props.userList.length > 0 && (
        <div className="pagesam pt-1 m-1">
          <Stack spacing={2}>
            <Pagination
              count={props.totalPage}
              color="primary"
              onChange={(e, page) => handleOnChange(e, page)}
              page={props.currentPage}
            />
          </Stack>
          <div className="pagenum">
            <span className="ml-2">
              {props.calculateData.number * 20 + 1}-
              {props.calculateData.number * 20 +
                props.calculateData.numberOfElements}{" "}
              of {props.calculateData.totalElements}
            </span>
          </div>
        </div>
      )}
    </Grid>
  );
};

export default UserTable;
