const useSideNavLogic = ()=>{
    const drawerStyleForSideBar = {
        width:`240px`,
        position:'fixed',
        top:'0',
        left:'0',
     
        
        
    }

    const sendSideNavLogicBackToSideNav = {
        drawerStyleForSideBar 
    }

    return sendSideNavLogicBackToSideNav;
}

export default useSideNavLogic;