import { useState, useEffect, useRef } from "react";
import AdminHome from "../AdminHome";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import useLocHook from "./locationLogic";
import InputAdornment from "@mui/material/InputAdornment";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {
  useGetActiveLocValueMutation,
  useSearchlocationMutation,
  useGetActiveOrgValueMutation,
  useGetActiveLocationValueMutation,
  useGetActiveStateValueMutation,
  useGetActiveDistrictValueMutation,
  useFilterLocationDataMutation,
  useGetpatientpaginationMutation,
  useGetUserByUserIdMutation,
  useFilterOrganizationDataMutation,
  useFilterLocationLevelDataMutation,
  useGetCountryListMutation
} from "../../../services/adminLogIn";
import Autocomplete from "@mui/material/Autocomplete";
import filter from "../../../images/filter.png";
import LocationTable from "./locationtable";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import "../Organization/Mainhome.css";
import { Grid } from "@mui/material";
import Breadcrums from "../../global/breadcrums/Breadcrums";
import { Category } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Tooltip from "@mui/material/Tooltip";
import AdminCookieConsentBanner from "../home/AdminCookieConsentBanner";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const Location = (props) => {
  // const [getActiveValFun, { isLoading, isSuccess, isError, data }] = useGetActiveLocValueMutation();
  const [getACtiveLocation] = useGetActiveLocationValueMutation();
  const [getFilterList] = useFilterLocationDataMutation();
  const [getFilterLevel] = useFilterLocationLevelDataMutation();
  const [getOrganizationList] = useFilterOrganizationDataMutation();
  const [getCountryList] = useGetCountryListMutation();
  const [getLocValFun, { isLoading4, isSuccess4, isError4, data }] =
    useGetActiveLocationValueMutation();
  const [getpatientpaginationfunc] = useGetpatientpaginationMutation();
  const [getStateValFun, { isLoading1, isSuccess1, isError1, ...data1 }] =
    useGetActiveStateValueMutation();
  const [getActiveValFun1, { isLoading2, isSuccess2, isError2, ...org }] =
    useGetActiveOrgValueMutation();
  const [getSearchListFun, { ...searchList1 }] = useSearchlocationMutation();
  const [statuslist] = useState(["ACTIVE", "INACTIVE"]);
  const [getUserByUserId] = useGetUserByUserIdMutation();
  const [fullName, setFullName] = useState("");
  const [access, setAccess] = useState(
    JSON.parse(sessionStorage.getItem("access"))
  );

  const [filterData, setFilterData] = useState({
    locName: "",
    loclevel:"",
    partOf: "",
    managingOrg: "",
    state: "",
    city: "",
    status: "",
    page: 0,
    size: 20,
  });
  const callListApiFunct = async (data) => {
    if (data?.page === 0 || data === undefined) {
      setIsNewData(true);
    }
    const res = await getFilterList(data ? data : filterData);
    setloclist(res?.data?.content);
    setTotalPage(res?.data?.totalPages);
    setTotalPageCalculate({
      numberOfElements: res?.data?.numberOfElements,
      number: res?.data?.number,
      totalElements: res?.data?.totalElements,
    });
  };

  const callFilterListApiFunct = async () => {
    setsearchVal(filterData.locName);
    if (filterData.page === 0) {
      setIsNewData(true);
    }
    const res = await getFilterLevel(filterData);
    setloclist(res?.data?.content);
    setTotalPage(res?.data?.totalPages);
    setTotalPageCalculate({
      numberOfElements: res?.data?.numberOfElements, 
      number: res?.data?.number,
      totalElements: res?.data?.totalElements,
    });
    handleLocationFilterModalClose();
  };

  const handleFilterModalClose = () => {
    callListApiFunct(filterData);
    handleLocationFilterModalClose();
  };
  useEffect(() => {
    getLocValFun();
    getStateValFun();
    getActiveValFun1();
    callActiveListApiFunct();
    callListApiFunct();
    callListCountryDropdownData();
  }, []);
  // useEffect(() => {
  //   if (data && data.content) {

  //     setloclist(data.content)
  //     setTotalPage(data.totalPages)
  //     setTotalPageCalculate({ numberOfElements: data?.numberOfElements, number: data?.number, totalElements: data?.totalElements })
  //   }
  // }, [data])

  useEffect(() => {
    if (serachVal.length > 0 && searchList1 && searchList1?.data?.content) {
      setloclist(searchList1?.data?.content);
      setTotalPage(searchList1?.data?.totalPages);
      setTotalPageCalculate({
        numberOfElements: searchList1.data?.numberOfElements,
        number: searchList1.data?.number,
        totalElements: searchList1.data?.totalElements,
      });
    } else if (serachVal.length > 0 && searchList1) {
      setloclist([]);
    }
  }, [searchList1.isSuccess]);
  const [expand, setexpand] = useState({
    heading: "",
    icon: "",
    isSubListExpanded: false,
  });
  const [expand1, setexpand1] = useState({
    empty: false,
  });

  const [locpageList, setlocpageList] = useState([]);
  const [locImport, setlocImport] = useState();

  const getlocpageList = async (page) => {
    setCurrentPage(page);
    setIsNewData(false);
    let data = { ...filterData, ["page"]: page - 1, ["locName"]: serachVal };
    callListApiFunct(data);
  };

  const handleFilterInputChange = (e) => {
    if (e.target.name === "locName") {
      setsearchVal(e.target.value);
    }
    setFilterData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    if (e.target.name === "loclevel") {
      setsearchVal(e.target.value);
    }
    setFilterData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleFilterValueChange = (name, value) => {
    if (name === "managingOrg") {
      setDropManagingOrg(value);
      setFilterData((prev) => ({ ...prev, [name]: value?.id }));
    }
    if (name === "partOf") {
      setDropPartOf(value);
      setFilterData((prev) => ({ ...prev, [name]: value?.id }));
    }
    if (name === "state") {
      getDistrictValFun1(value);
      setDropState(value);
      setFilterData((prev) => ({ ...prev, [name]: value?.name }));
    }
    if (name === "city") {
      setDropCity(value);
      setFilterData((prev) => ({ ...prev, [name]: value?.name }));
    }
    if (name === "status") {
      setFilterData((prev) => ({ ...prev, [name]: value }));
    }
  };
  const [dropCity, setDropCity] = useState({});
  const [dropState, setDropState] = useState({});
  const [dropmanagingOrg, setDropManagingOrg] = useState({});
  const [data4, setdata4] = useState({});
  const [activeLocations, setActiveLocations] = useState({});
  const [droppartOf, setDropPartOf] = useState({});
   const [dropCountry, setDropCountry] = useState({});

  const resetLocationFilterData = () => {
    setFilterData({
      locName: "",
      loclevel:"",
      partOf: "",
      managingOrg: "",
      state: "",
      city: "",
      status: "",
      page: 0,
    });
    setDropManagingOrg({});
    setDropPartOf({});
    setDropState({});
    setDropCity({});
    setsearchVal("");
  };
  const {
    locSubmitHandler,
    whenInputsChange,
    locState,
    handleLocationFilterModalOpen,
    handleLocationFilterModalClose,

    filterLocationData,
    openLocationFilterModal,
    dropLocState,
    dropLocManage,
    locAllTextFieldState,
    isLocError,
    handleDropDownChange,
    createLocRes,
    updateLocRes,
    resetErrorHandler,
    setLocTextFieldState,
    setDropLocState,
    setDropLocManage,
    districtlist,
    getDistrictValFun1,
    open,
    handleClose,
    handleOpen,
    setOpen,
    setErrorMessage,
    showerror,
    halndleClickonProceed,
  } = useLocHook();

  const [isFormVisible, setFormVisible] = useState({
    isOpen: false,
    isClose: true,
  });
  const [cityList, setcityList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const filterLocationStatusOptions = ["ACTIVE", "INACTIVE"];
  const [locList, setlocList] = useState({});
  const [id, setId] = useState("");
  const [totalPage, setTotalPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [isNewData, setIsNewData] = useState(true);
  const [calculateData, setTotalPageCalculate] = useState({
    number: "",
    numberOfElements: "",
    totalElements: "",
    showcount: "",
  });
  const [paginationdata, setPaginationData] = useState({
    string: "",
    page: 0,
  });

  const showFormActions = () => {
    if (access?.locationview == true) {
      if (!isFormVisible.isOpen) {
        setFormVisible((prev) => ({
          ...prev,
          isOpen: (isFormVisible.isOpen = true),
          isFormVisible,
          isClose: (isFormVisible.isClose = false),
        }));
        if (locAllTextFieldState.is_update === false) {
          setId("");
        }
      }
    } else {
      toast(" No Permission", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        type: "error",
        closeOnClick: true,
        theme: "light",
      });
    }
  };
  // console.log(access);
  const hideForm = () => {
    setLocTextFieldState({});
    if (isFormVisible.isOpen) {
      setFormVisible((prev) => ({
        ...prev,
        isOpen: (isFormVisible.isOpen = false),
        isFormVisible,
        isClose: (isFormVisible.isClose = true),
      }));
      setLocTextFieldState({
        is_update: false,
        name: "",
        level:"",
        id: "",
        address: "",
        postalCode: "",
        country: "",
        phoneNumber: "",
        state: {},
        city: {},
        emailId: "",
        status: "",
      });
      setDropLocState({});
      setDropLocManage({});
      setsearchVal("");
    }
  };

  const bredStyle = {
    fontSize: "12px",
  };
  const callActiveListApiFunct = async (data) => {
    const res = await getActiveValFun1();
    if (res?.data?.content) {
      setdata4(res);
    }
  };
  const handleOrgDropDownSearch = (e, value) => {
    callListOrgDropdownData(value);
  };
  const callListOrgDropdownData = async (value) => {
    const payload = {
      organizationName: value,
      partOf: "",
      state: "",
      city: "",
      status: "Active",
      page: 0,
      size: 100,
    };
    const res = await getOrganizationList(payload);
    console.log(res);
    if (res?.data?.content) setdata4(res);
  };

  const callListCountryDropdownData = async (value) => {

      const countryListData = await getCountryList();
      setCountryList(countryListData?.data?.content);
    };

  const callListDropdownData = async (value) => {
    const payload = {
      locName: value,
      loclevel:"",
      partOf: "",
      managingOrg: "",
      state: "",
      city: "",
      status: "ACTIVE",
      page: 0,
      size: 100,
    };
    const res = await getFilterList(payload);
    console.log(res);
    if (res?.data?.content) setActiveLocations(res);
  };

  const handleDropDownSearch = (e, value) => {
    callListDropdownData(value);
  };

  useEffect(() => {
    if (createLocRes.isSuccess) {
      setLocTextFieldState({
        is_update: false,
        name: "",
        level:"",
        id: "",
        address: "",
        postalCode: "",
        country: "",
        phoneNumber: "",
        state: {},
        city: {},
        emailId: "",
        status: "",
      });

      setDropLocState({});
      setDropLocManage({});
      callListApiFunct();
      callActiveListApiFunct();
      callListDropdownData("");
      getLocValFun();
      hideForm();
    }
  }, [createLocRes.isSuccess]);

  useEffect(() => {
    if (updateLocRes.isSuccess) {
      setLocTextFieldState({
        is_update: false,
        name: "",
        level:"",
        id: "",
        address: "",
        postalCode: "",
        country: "",
        phoneNumber: "",
        state: {},
        city: {},
        emailId: "",
        status: "",
      });
      setDropLocState({});
      setDropLocManage({});
      hideForm();
      callActiveListApiFunct();
      callListDropdownData("");
      callListApiFunct();
      getLocValFun();
    }
  }, [updateLocRes.isSuccess]);

  useEffect(() => {
    let data = {};
    data = locAllTextFieldState.state;
    getDistrictValFun1(data);
    callActiveListApiFunct();
    callListDropdownData("");
  }, [locAllTextFieldState.id]);

  const searchlocevent = (value) => {
    setsearchVal(value);

    if (value.length > 0) {
      let data = { ...filterData, ["locName"]: value };
      callListApiFunct(data);
    }
    if (value.length === 0) {
      // getActiveValFun(0)
      setIsNewData(true);

      callListApiFunct();
    }
  };

  const getUserByIdFunct = async (userId) => {
    const res = await getUserByUserId(userId);
    if (res?.data?.content.length > 0) {
      setFullName(res?.data?.content[0].fullName);
    }
  };

  const [loclist, setloclist] = useState([]);
  const [serachVal, setsearchVal] = useState("");
  return (
    <>
      <div className={props.expand1.empty === true ? "main1" : "main"}>
        <Breadcrums
          firsttab={"Categories"}
          secondtab="Administration"
          thirdtab="Location"
        ></Breadcrums>
        <ToastContainer />
        <Grid container mt={-2}>
          <Grid item xs={6}>
            <div
              className={
                isFormVisible.isOpen
                  ? "leftcontainer rounded-lg"
                  : "leftcontainer1 rounded-lg"
              }
            >
              {/* <Grid className="OsearchContainer w-1/2 h-9 border border-o-color-two  rounded-searchRadius"> */}
              <Grid className=" p-7 ">
                <TextField
                  label=""
                  onChange={(e) => searchlocevent(e.target.value)}
                  onKeyDown={(e) => {
                    e.key === "Enter" && e.preventDefault();
                  }}
                  sx={{ m: 1, width: "25ch" }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchOutlinedIcon />
                      </InputAdornment>
                    ),
                  }}
                  value={serachVal}
                />
              </Grid>

              <Grid className="editContainer rounded-radiusFourPix ">
              <Tooltip title={access?.locationadd ? "" : "No Access"} arrow>
                <Grid
                  onClick={access?.locationadd ? showFormActions : null}
                  className={isFormVisible.isOpen ? "addIconchange" : "addIcon"}
                  data-testid="open"
                >
                  <span className=" p-2 m-r-f ">Add</span>
                  <AddCircleOutlineIcon
                    style={{
                      fontSize: "14px",
                      color: "#545454",
                      marginBottom: "3px",
                      color: `${isFormVisible.isOpen ? "#ffffff" : "#545454"}`,
                    }}
                  />
                </Grid>
                </Tooltip>

                {/* <Grid className="Addaction">
                    <span className="p-2 m-r-f">Actions</span>
                    <AddCircleOutlineIcon style={{ fontSize: '14px', color: '#545454', 'marginBottom': '3px' }} />

                  </Grid> */}

                <Grid
                  className="addIcon"
                  onClick={handleLocationFilterModalOpen}
                >
                  <span className="p-2 m-r-f">Filter</span>
                  <img
                    src={filter}
                    alt="filter.png"
                    style={{
                      position: "absolute",
                      left: "90%",
                      right: "20%",
                      top: "29%",
                      bottom: "92%",
                      width: "10px",
                      paddingTop: "3px",
                    }}
                  ></img>
                </Grid>

                <Modal
                  open={openLocationFilterModal}
                  // onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box
                    sx={style}
                    style={{
                      width: "450px",
                      padding: "50px",
                      borderColor: "white",
                    }}
                  >
                    <Typography
                      className="filtermain"
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                      style={{
                        fontFamily: "MontserratRegular",
                        marginTop: "5px",
                      }}
                    >
                      Filter by
                    </Typography>

                    <div className="OrgNameContainer">
                      <h3
                        className="font-normal text-o-form-label-color"
                        id="Org"
                      >
                        Location Name
                      </h3>
                      <TextField
                        onFocus={(e) => resetErrorHandler(e)}
                        autoComplete="OFF"
                        onChange={(e) => handleFilterInputChange(e)}
                        name="locName"
                        value={filterData?.locName}
                        style={{ width: "360px" }}
                        id="standard-basic"
                        label=""
                        variant="standard"
                      />
                    </div>
                    
                    <div className="mt-5 OrgNameContainer ">
                      <h3
                        className="font-normal text-o-form-label-color "
                        id="color"
                      >
                        Part Of
                      </h3>
                      <Autocomplete
                        data-testid="partof"
                        value={droppartOf}
                        onKeyDown={(e) => {
                          e.key === "Enter" && e.preventDefault();
                        }}
                        autoHighlight={true}
                        onChange={(e, v) =>
                          handleFilterValueChange("partOf", v)
                        }
                        onInputChange={(e, value) =>
                          handleDropDownSearch(e, value)
                        }
                        getOptionLabel={(option) =>
                          option.name ? option.name : ""
                        }
                        id="loc-list-option"
                        options={
                          activeLocations &&
                          activeLocations.data &&
                          activeLocations.data.content &&
                          activeLocations.data.content
                            ? activeLocations.data.content
                            : []
                        }
                        sx={{ width: 360 }}
                        renderInput={(params) => (
                          <TextField variant="standard" {...params} label="" />
                        )}
                        // error = {isLocError.isDropDownError}
                      />
                    </div>
                    <div className="mt-5 OrgNameContainer ">
                      <h3
                        className="font-normal text-o-form-label-color"
                        id="color"
                      >
                        Managing Organization
                      </h3>
                      <Autocomplete
                        data-testid="manageorg"
                        value={dropmanagingOrg}
                        onKeyDown={(e) => {
                          e.key === "Enter" && e.preventDefault();
                        }}
                        onInputChange={(e, value) =>
                          handleOrgDropDownSearch(e, value)
                        }
                        autoHighlight={true}
                        onChange={(e, v) =>
                          handleFilterValueChange("managingOrg", v)
                        }
                        getOptionLabel={(option) =>
                          option.name ? option.name : ""
                        }
                        id="loc-list-option"
                        options={
                          org && org.data && org.data.content
                            ? org.data.content
                            : []
                        }
                        sx={{ width: 360 }}
                        renderInput={(params) => (
                          <TextField variant="standard" {...params} label="" />
                        )}
                      />
                    </div>
                    <div className="OrgNameContainer mt-5">
                      <h3
                        className="font-normal text-o-form-label-color"
                        id="Org"
                      >
                        Level
                      </h3>
                      <TextField
                        onFocus={(e) => resetErrorHandler(e)}
                        autoComplete="OFF"
                        onChange={(e) => handleFilterInputChange(e)}
                        name="loclevel"
                        value={filterData?.loclevel}
                        style={{ width: "360px" }}
                        id="standard-basic"
                        label=""
                        variant="standard"
                      />
                    </div>
                    <div className="mt-5 OrgNameContainer ">
                      <h3
                        className="font-normal text-o-form-label-color"
                        id="color"
                      >
                        State
                      </h3>
                      <Autocomplete
                        data-testid="state"
                        value={dropState}
                        autoHighlight={true}
                        onKeyDown={(e) => {
                          e.key === "Enter" && e.preventDefault();
                        }}
                        onChange={(e, v) => handleFilterValueChange("state", v)}
                        getOptionLabel={(option) =>
                          option.name ? option.name : ""
                        }
                        id="state-list-option"
                        options={data1 && data1.data ? data1.data : []}
                        sx={{ width: 360 }}
                        renderInput={(params) => (
                          <TextField variant="standard" {...params} label="" />
                        )}
                      />
                    </div>
                    <div className="mt-5 OrgNameContainer ">
                      <h3
                        className="font-normal text-o-form-label-color"
                        id="color"
                      >
                        City
                      </h3>
                      <Autocomplete
                        data-testid="city"
                        value={dropCity}
                        autoHighlight={true}
                        onChange={(e, v) => handleFilterValueChange("city", v)}
                        getOptionLabel={(option) =>
                          option.name ? option.name : ""
                        }
                        id="city-list-option"
                        onKeyDown={(e) => {
                          e.key === "Enter" && e.preventDefault();
                        }}
                        options={
                          cityList && cityList.data && cityList.data.length > 0
                            ? cityList.data
                            : districtlist &&
                              districtlist.data &&
                              districtlist.data.length != 0
                            ? districtlist.data
                            : []
                        }
                        sx={{ width: 360 }}
                        renderInput={(params) => (
                          <TextField variant="standard" {...params} label="" />
                        )}
                      />
                    </div>

                    <div className="mt-5 OrgNameContainer ">
                      <h3 className="font-normal text-o-form-label-color">
                        Status
                      </h3>
                      <Autocomplete
                        value={filterData?.status}
                        autoHighlight={true}
                        disableClearable
                        onChange={(e, v) =>
                          handleFilterValueChange("status", v)
                        }
                        getOptionLabel={(option) => (option ? option : "")}
                        id="org-list-option"
                        options={filterLocationStatusOptions}
                        sx={{ width: 360 }}
                        renderInput={(params) => (
                          <TextField
                            variant="standard"
                            onFocus={(e) => resetErrorHandler(e)}
                            autoComplete="OFF"
                            {...params}
                            label=""
                          />
                        )}
                      />
                    </div>

                    <Button
                      variant="contained"
                      disableElevation
                      onClick={handleFilterModalClose}
                      style={{
                        marginRight: "10px",
                        top: "30px",
                        borderColor: "#0F4391",
                        width: "99px",
                        height: "39px",
                        borderRadius: "9999px",
                        backgroundColor: "#E8FAFF",
                        color: "#0F4391",
                        border: "1px solid #0F4391",
                        marginBottom: "5px",
                      }}
                    >
                      CANCEL
                    </Button>

                    <Button
                      variant="contained"
                      disableElevation
                      onClick={resetLocationFilterData}
                      style={{
                        marginRight: "10px",
                        top: "30px",
                        borderColor: "#0F4391",
                        width: "99px",
                        height: "39px",
                        borderRadius: "9999px",
                        backgroundColor: "#E8FAFF",
                        color: "#0F4391",
                        border: "1px solid #0F4391",
                        marginBottom: "5px",
                      }}
                    >
                      Reset
                    </Button>
                    <Button
                      variant="contained"
                      disableElevation
                      onClick={callFilterListApiFunct}
                      // disabled={filterLocationData.filterStatus === "" && filterLocationData.classificationId === "" && filterLocationData.sampleName === ""}
                      style={{
                        top: "30px",
                        borderColor: "#0F4391",
                        width: "99px",
                        height: "39px",
                        borderRadius: "9999px",
                        backgroundColor: "#E8FAFF",
                        color: "#0F4391",
                        border: "1px solid #0F4391",
                        marginBottom: "5px",
                      }}
                    >
                      Search
                    </Button>
                  </Box>
                </Modal>
              </Grid>

              <>
                <LocationTable
                  loclist={loclist}
                  setId={setId}
                  id={id}
                  setTotalPage={setTotalPage}
                  totalPage={totalPage}
                  getlocpageList={getlocpageList}
                  setErrorMessage={setErrorMessage}
                  setDropLocManage={setDropLocManage}
                  setcityList={setcityList}
                  showFormActions={showFormActions}
                  setLocTextFieldState={setLocTextFieldState}
                  setDropLocState={setDropLocState}
                  calculateData={calculateData}
                  currentPage={isNewData ? 1 : currentPage}
                  locationview={access?.locationview}
                  locationupdate={access?.locationupdate}
                  getUserByIdFunct={getUserByIdFunct}
                />
              </>
            </div>
          </Grid>
          {isFormVisible.isOpen ? (
            <Grid item xs={6}>
              <div className="rightcontainer rounded-lg">
                <div className="overflow-y-auto o-form-container">
                  <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={style}>
                      <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                      >
                        <Alert severity="info">
                          {locAllTextFieldState.status === "INACTIVE"
                            ? "Are you sure you want to reactivate  the Location ?"
                            : " Are you sure you want to Deactivate the Location ?"}
                        </Alert>
                      </Typography>
                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {locAllTextFieldState.status === "INACTIVE"
                          ? "This activity will enable the Location and user will be able to access it across all products."
                          : "This activity disable the Location and user will not able to access it. You can reactivate the Location at anytime?"}

                        <div className="mt-10 flex justify-center items-center  towButtonContainer">
                          <Button
                            type="submit"
                            onClick={handleClose}
                            variant="contained"
                            disableElevation
                            style={{
                              marginRight: "10px",
                              borderColor: "#0F4391",
                              width: "99px",
                              height: "39px",
                              borderRadius: "9999px",
                              backgroundColor: "#E8FAFF",
                              color: "#0F4391",
                              border: "1px solid #0F4391",
                              marginBottom: "100px",
                            }}
                          >
                            CANCEL
                          </Button>

                          <Button
                            type="submit"
                            onClick={halndleClickonProceed}
                            variant="contained"
                            disableElevation
                            style={{
                              borderColor: "#0F4391",
                              width: "99px",
                              height: "39px",
                              borderRadius: "9999px",
                              backgroundColor: "#E8FAFF",
                              color: "#0F4391",
                              border: "1px solid #0F4391",
                              marginBottom: "100px",
                            }}
                          >
                            PROCEED
                          </Button>
                        </div>
                      </Typography>
                    </Box>
                  </Modal>
                  <ToastContainer />
                  <>
                    <div className="font-normal text-o-form-label-color mainhead flex justify-between items-center pl-5 pr-1 h-10">
                      <h1 id="color">Location Overview</h1>
                      {/* <div className="closeIconContainer" onClick={hideForm}>
                            <IconButton color="warning" aria-label="upload picture" component="span">
                              <CloseIcon />
                            </IconButton>
                          </div> */}
                    </div>

                    <form autoComplete="off" onSubmit={locSubmitHandler}>
                      {/* organisation name  */}
                      <div className=" pl-5 OrgNameContainer ">
                        <h3
                          className=" text-o-form-label-color font-normal"
                          id="color"
                        >
                          Location Name <span style={{ color: "red" }}> *</span>
                        </h3>
                        <TextField
                          data-testid="name"
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          onFocus={(e) => resetErrorHandler(e)}
                          autoComplete="OFF"
                          error={isLocError.isNameError === true}
                          helperText={
                            isLocError.isNameError
                              ? "Valid (Name) Required !"
                              : ""
                          }
                          onChange={(e) => whenInputsChange(e)}
                          name="name"
                          value={locAllTextFieldState.name}
                          style={{ width: "360px" }}
                          id="standard-basic"
                          label=""
                          variant="standard"
                        />
                      </div>
                      

                      <div className="mt-5 pl-5 OrgNameContainer ">
                        <h3
                          className="font-normal text-o-form-label-color "
                          id="color"
                        >
                          Part Of
                        </h3>
                        <Autocomplete
                          data-testid="partof"
                          value={dropLocState}
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          autoHighlight={true}
                          onChange={(e, v) => handleDropDownChange("partof", v)}
                          onInputChange={(e, value) =>
                            handleDropDownSearch(e, value)
                          }
                          getOptionLabel={(option) =>
                            option.name ? option.name : ""
                          }
                          id="loc-list-option"
                          options={
                            activeLocations &&
                            activeLocations.data &&
                            activeLocations.data.content &&
                            activeLocations.data.content
                              ? activeLocations.data.content
                              : []
                          }
                          sx={{ width: 360 }}
                          renderInput={(params) => (
                            <TextField
                              variant="standard"
                              {...params}
                              label=""
                            />
                          )}
                          // error = {isLocError.isDropDownError}
                        />
                      </div>

                      <div className="mt-5 pl-5 OrgNameContainer ">
                        <h3
                          className="font-normal text-o-form-label-color"
                          id="color"
                        >
                          Managing Organization{" "}
                          <span style={{ color: "red" }}> *</span>
                        </h3>
                        <Autocomplete
                          data-testid="manageorg"
                          value={dropLocManage}
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          onInputChange={(e, value) =>
                            handleOrgDropDownSearch(e, value)
                          }
                          autoHighlight={true}
                          onChange={(e, v) =>
                            handleDropDownChange("manageorg", v)
                          }
                          getOptionLabel={(option) =>
                            option.name ? option.name : ""
                          }
                          id="loc-list-option"
                          options={
                            org && org.data && org.data.content
                              ? org.data.content
                              : []
                          }
                          sx={{ width: 360 }}
                          renderInput={(params) => (
                            <TextField
                              variant="standard"
                              error={isLocError.isDropDownError}
                              helperText={
                                isLocError.isDropDownError
                                  ? "Valid (Organization) Required !"
                                  : ""
                              }
                              {...params}
                              label=""
                            />
                          )}
                          error={isLocError.isDropDownError}
                          helperText={
                            isLocError.isDropDownError
                              ? "Manging Organization Required !"
                              : ""
                          }
                        />
                      </div>
                      <div className=" pl-5 OrgNameContainer mt-5 ">
                        <h3
                          className=" text-o-form-label-color font-normal"
                          id="color"
                        >
                          Level
                        </h3>
                        <TextField
                          data-testid="level"
                          autoComplete="OFF"
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          onFocus={(e) => resetErrorHandler(e)}
                          onChange={(e) => whenInputsChange(e)}
                          name="level"
                          value={locAllTextFieldState.level}
                          style={{ width: "360px" }}
                          id="standard-basic"
                          label=""
                          variant="standard"
                        />
                      </div>
                      {/* location Text Area*/}

                      {/* <div className="mt-5 pl-5 OrgNameContainer ">
                                    <h3 className="font-normal text-o-form-label-color">Contact Information</h3>
                                      <br/>
                                       <h2>Address</h2>

                                    <TextareaAutosize
                                      onFocus={(e)=>resetErrorHandler(e)}
                                      autoComplete="OFF"
                                      onChange={(e)=>whenInputsChange(e)}
                                      name="address"
                                      value={locAllTextFieldState.address}
                                      aria-label="Location Address"
                                      placeholder=" Enter Address"
                                      minRows={3}
                                      style={{ width:"360px", border:`${isLocError.isAddressError === true ? '1px solid red' : '1px solid  #DBDADA'}`}}
                                     
                                     
                                    />
                               </div> */}
                               <div className="mt-5 pl-5 OrgNameContainer ">
                                                       <h3
                                                         className="font-normal text-o-form-label-color"
                                                         id="color"
                                                       >
                                                         Country
                                                       </h3>
                                                       <Autocomplete
                                                         data-testid="country"
                                                         value={
                                                           locAllTextFieldState &&
                                                           locAllTextFieldState.country &&
                                                           locAllTextFieldState.country
                                                         }
                                                         autoHighlight={true}
                                                         onKeyDown={(e) => {
                                                           e.key === "Enter" && e.preventDefault();
                                                         }}
                                                         onChange={(e, v) => handleDropDownChange("country", v)}
                                                         getOptionLabel={(option) =>
                                                           option.country ? option.country : ""
                                                         }
                                                         id="state-list-option"
                                                         options={countryList == null ?[]:countryList

                                                                                   }
                                                         sx={{ width: 360 }}
                                                         renderInput={(params) => (
                                                           <TextField
                                                             variant="standard"
                                                             {...params}
                                                             label=""
                                                           />
                                                         )}
                                                       />
                                                     </div>


                      <div className="mt-5 pl-5 OrgNameContainer ">
                        <h3
                          className="font-normal text-o-form-label-color"
                          id="color"
                        >
                          State
                        </h3>
                        <Autocomplete
                          data-testid="state"
                          value={
                            locAllTextFieldState &&
                            locAllTextFieldState.state &&
                            locAllTextFieldState.state
                          }
                          autoHighlight={true}
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          onChange={(e, v) => handleDropDownChange("state", v)}
                          getOptionLabel={(option) =>
                            option.name ? option.name : ""
                          }
                          id="state-list-option"
                          options={data1 && data1.data ? data1.data : []}
                          sx={{ width: 360 }}
                          renderInput={(params) => (
                            <TextField
                              variant="standard"
                              {...params}
                              label=""
                            />
                          )}
                        />
                      </div>

                      <div className="mt-5 pl-5 OrgNameContainer ">
                        <h3
                          className="font-normal text-o-form-label-color"
                          id="color"
                        >
                          City
                        </h3>
                        <Autocomplete
                          data-testid="city"
                          value={
                            locAllTextFieldState &&
                            locAllTextFieldState.city &&
                            locAllTextFieldState.city
                          }
                          autoHighlight={true}
                          onChange={(e, v) => handleDropDownChange("city", v)}
                          getOptionLabel={(option) =>
                            option.name ? option.name : ""
                          }
                          id="city-list-option"
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          options={
                            cityList &&
                            cityList.data &&
                            cityList.data.length > 0
                              ? cityList.data
                              : districtlist &&
                                districtlist.data &&
                                districtlist.data.length != 0
                              ? districtlist.data
                              : []
                          }
                          sx={{ width: 360 }}
                          renderInput={(params) => (
                            <TextField
                              variant="standard"
                              {...params}
                              label=""
                            />
                          )}
                        />
                      </div>

                      <div className="mt-5 pl-5 OrgPostalCode ">
                        <h3 className="font-normal text-o-form-label-color">
                          Postal Code
                        </h3>
                        <TextField
                          data-testid="postalCode"
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          onFocus={(e) => resetErrorHandler(e)}
                          pattern="[0-9]*"
                          autoComplete="OFF"
                          error={isLocError.isPostalCodeError === true}
                          helperText={
                            isLocError.isPostalCodeError
                              ? "Valid (Postal Code) Required !"
                              : ""
                          }
                          onChange={(e) => whenInputsChange(e)}
                          name="postalCode"
                          value={locAllTextFieldState.postalCode}
                          style={{ width: "360px" }}
                          id="postalCode"
                          label=""
                          variant="standard"
                        />
                      </div>

                      <div className="mt-5 pl-5 OrgNameContainer ">
                        <h3 className="font-normal text-o-form-label-color">
                          Phone Number
                        </h3>
                        <TextField
                          data-testid="phoneNumber"
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          onFocus={(e) => resetErrorHandler(e)}
                          autoComplete="OFF"
                          error={isLocError.isPhoneError === true}
                          helperText={
                            isLocError.isPhoneError
                              ? "Valid (PhoneNumber) Required !"
                              : ""
                          }
                          onChange={(e) => whenInputsChange(e)}
                          name="phoneNumber"
                          value={locAllTextFieldState.phoneNumber}
                          style={{ width: "360px" }}
                          id="standard-basic"
                          label=""
                          variant="standard"
                        />
                      </div>

                      <div className="mt-5 pl-5 OrgNameContainer ">
                        <h3 className="font-normal text-o-form-label-color">
                          Email ID
                        </h3>
                        <TextField
                          data-testid="emailId"
                          onKeyDown={(e) => {
                            e.key === "Enter" && e.preventDefault();
                          }}
                          onFocus={(e) => resetErrorHandler(e)}
                          autoComplete="OFF"
                          error={isLocError.isEmailError === true}
                          helperText={
                            isLocError.isEmailError
                              ? "Valid (Email) Required !"
                              : ""
                          }
                          onChange={(e) => whenInputsChange(e)}
                          name="emailId"
                          value={locAllTextFieldState.emailId}
                          style={{ width: "360px" }}
                          id="standard-basic"
                          label=""
                          variant="standard"
                        />
                      </div>

                      {locAllTextFieldState.is_update === true ? (
                        <>
                          <div className="mt-5 pl-5 OrgNameContainer">
                            <h3 className="font-normal text-o-form-label-color">
                              Created By
                            </h3>
                            <p className="last-modified-value m-r-f">
                              <TextField
                                disabled={locAllTextFieldState.is_update}
                                value={fullName}
                                style={{ width: "360px" }}
                                id="standard-basic"
                                label=""
                                variant="standard"
                              />
                            </p>
                            <h3 className=" mt-5 font-normal text-o-form-label-color">
                              Location ID
                            </h3>
                            <p className="last-modified-value m-r-f">
                              <TextField
                                disabled={locAllTextFieldState.is_update}
                                value={id}
                                style={{ width: "360px" }}
                                id="standard-basic"
                                label=""
                                variant="standard"
                              />
                            </p>
                            <h3 className=" mt-5 font-normal text-o-form-label-color">
                              Last Modified Date & Time
                            </h3>
                            <p className="last-modified-value m-r-f">
                              <TextField
                                disabled={locAllTextFieldState.is_update}
                                value={new Date(
                                  locAllTextFieldState.lastModifiedTime
                                ).toLocaleString("en-US")}
                                style={{ width: "360px" }}
                                id="standard-basic"
                                label=""
                                variant="standard"
                              />
                            </p>
                          </div>
                          <div className="mt-5 pl-5 OrgNameContainer ">
                            <h3 className="font-normal text-o-form-label-color">
                              Status
                            </h3>
                            <Autocomplete
                              autoHighlight={true}
                              value={
                                locAllTextFieldState &&
                                locAllTextFieldState.status
                              }
                              onChange={(e, v) =>
                                handleDropDownChange("status", v)
                              }
                              getOptionLabel={(option) =>
                                option ? option : ""
                              }
                              id="city-list-option"
                              onKeyDown={(e) => {
                                e.key === "Enter" && e.preventDefault();
                              }}
                              options={statuslist ? statuslist : []}
                              sx={{ width: 360 }}
                              renderInput={(params) => (
                                <TextField
                                  variant="standard"
                                  {...params}
                                  label=""
                                />
                              )}
                            />
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                      <div className="mt-5 OrgNameContainer ">
                        <div className="progressContainer mt-5 flex justify-center items-center">
                          {(createLocRes.isError || updateLocRes.isError) &&
                          showerror === true ? (
                            <Alert severity="error" color="warning">
                              {createLocRes.error &&
                              createLocRes.error.data &&
                              locAllTextFieldState.is_update === false
                                ? createLocRes.error.data.message
                                : updateLocRes.error &&
                                  updateLocRes.error.data &&
                                  updateLocRes.error.data.message}
                            </Alert>
                          ) : createLocRes.isLoading ||
                            updateLocRes.isLoading ? (
                            <CircularProgress />
                          ) : (
                            ""
                          )}
                        </div>

                        <div className="mt-5 flex justify-center   towButtonContainer h-40">
                          <Button
                            className="mb-2"
                            onClick={hideForm}
                            type="submit"
                            variant="contained"
                            disableElevation
                            style={{
                              marginRight: "10px",
                              borderColor: "#0F4391",
                              width: "99px",
                              height: "39px",
                              borderRadius: "9999px",
                              backgroundColor: "#E8FAFF",
                              color: "#0F4391",
                              border: "1px solid #0F4391",
                              marginBottom: "100px",
                            }}
                          >
                            CANCEL
                          </Button>

                          <Button
                            className="mb-2"
                            type="submit"
                            variant="contained"
                            disableElevation
                            style={{
                              borderColor: "#0F4391",
                              width: "99px",
                              height: "39px",
                              borderRadius: "9999px",
                              backgroundColor: "#E8FAFF",
                              color: "#0F4391",
                              border: "1px solid #0F4391",
                              marginBottom: "100px",
                            }}
                          >
                            SAVE
                          </Button>
                        </div>
                      </div>
                    </form>
                  </>
                </div>
              </div>
            </Grid>
          ) : (
            ""
          )}
        </Grid>
      </div>
      <AdminCookieConsentBanner />
    </>
  );
};

export default Location;
