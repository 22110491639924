import { useState, useEffect, useRef } from "react";
import AdminHome from "../AdminHome";
import Tooltip from "@mui/material/Tooltip";
import Delete from "../../global/icons/delete.png";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import bodysitelogic from "./tasktypelogic";
import TaskTypelist from "./taskTypelist";
import filter from "../../../images/filter.png";
import {
  useGetTaskTypeListMutation,
  useGetUserByUserIdMutation,
  useGetTaskTypeUsageCountTagMutation,
  useGetCustomTaskListMutation,
  useCreateUpdateCustomTaskStatusMutation,
  useGetCustomTaskStatusByTaskTypeIdMutation
} from "../../../services/adminLogIn";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import "../Organization/Mainhome.css";
import { Grid } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Breadcrums from "../../global/breadcrums/Breadcrums";
import moment from "moment";
import { useSelector } from "react-redux";
import { toasterFunction } from "../../../globalFunctions/getLocalStorageData";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminCookieConsentBanner from "../home/AdminCookieConsentBanner";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const TaskTypes = (props) => {
  const [getTypeListData] = useGetTaskTypeListMutation();
  const [getUserByUserId] = useGetUserByUserIdMutation();
  const [getUsageCountTag] = useGetTaskTypeUsageCountTagMutation();
  const [createUpdateCustomTaskStatus] = useCreateUpdateCustomTaskStatusMutation();
  const [getCustomTaskStatusByTaskTypeId] = useGetCustomTaskStatusByTaskTypeIdMutation();
  const [deleteIndex, setDeleteIndex] = useState();
  const [deleteId, setDeleteId] = useState();
  const [typeListData, setTypeListData] = useState();
  const [access, setAccess] = useState(
    JSON.parse(sessionStorage.getItem("access"))
  );

  const [expand, setexpand] = useState({
    heading: "",
    icon: "",
    isSubListExpanded: false,
  });
  const [expand1, setexpand1] = useState({
    empty: true,
  });
  const [encounterListData, setEncounterListData] = useState([]);
  const [systemStates, setSystemStates] = useState([]);
  const [isNewData, setIsNewData] = useState(true);
  const [fullName, setFullName] = useState("");
  const [usageCount, setUsageount] = useState(0);
  const [servicetotalcount, setTotalcount] = useState(0);
  const [encounterTypeId, setEncounterTypeId] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [calculateData, setTotalPageCalculate] = useState({
    number: "",
    numberOfElements: "",
    totalElements: "",
    showcount: "",
  });

  const {
    setError,
    setTaskSubmitStatus,
    updatigtasktype,
    createTaskType,
    whenInputsChange,
    bodyAllTextFieldState,
    isOrgError,
    dropsampleidState,
    setDropsampleidState,
    handleDropDownChange,
    createBodyRes,
    updatesampleRes,
    resetErrorHandler,
    setbodyTextFieldState,
    setDropbodyState,
    open,
    setOpen,
    handleClose,
    setErrorMessage,
    showerror,
    halndleClickonProceed,
    taskSubmitStatus,
    addRow,
    addRowByIndex,
    handleDropdownStates,
    deleteRow,
    setEncounterSystemStates,
    setEncounterCustomStates,
    setShowCustomFieldError,
    // showCustomFiedlError,
    // encounterFormFields,
    encounterSystemStates,
    encounterCustomStates,
    setCustomStateList,
    customStateList,
    removeCustomStateListWithId,
    getCustomStatusListData

  } = bodysitelogic();
  const [statuslist] = useState(["ACTIVE", "INACTIVE"]);
  const [isFormVisible, setFormVisible] = useState({
    isOpen: false,
    isClose: true,
  });
  // const [sampleclsslist, setSampleList] = useState(["BIO-BANKING", "Laboratory"]);
  const [id, setId] = useState("");
  const [paginationdata, setPaginationData] = useState({
    string: "",
    page: 0,
  });
  const handleDeleteRow = (i, row) => {
    if (row.customTaskStatusId) {
      // setOpen(true);
      setDeleteIndex(i);
      deleteRow(i, row);
    }
  };




  const showFormActions = async(isFlag) => {
    if (access?.tasktypeview == true) {
      setErrorMessage(false);
      setId("");
      if (!isFormVisible.isOpen) {
        setFormVisible((prev) => ({
          ...prev,
          isOpen: (isFormVisible.isOpen = true),
          isFormVisible,
          isClose: (isFormVisible.isClose = false),
        }));
        if (!isFlag) {
          setId("");
          let customStatus = [
            {
            "customTaskStatusId": "TO_DO",
            "customTaskStatus":"TO_DO",
            "taskTypeId": "",
            "taskStatus": "OPEN",
            "ordering": 1,
            "taskTypeCustomTaskStatusMapId": "",
          },
          {
            "customTaskStatusId": "INPROGRESS",
            "customTaskStatus":"INPROGRESS",
            "taskTypeId": "",
            "taskStatus": "INPROGRESS",
            "ordering": 2,
            "taskTypeCustomTaskStatusMapId": "",
          },
          {
            "customTaskStatusId": "COMPLETED",
            "customTaskStatus":"COMPLETED",
            "taskTypeId": "",
            "taskStatus": "COMPLETED",
            "ordering": 3,
            "taskTypeCustomTaskStatusMapId": "",
          },
          {
            "customTaskStatusId": "ON_HOLD",
            "customTaskStatus":"ON_HOLD",
            "taskTypeId": "",
            "taskStatus": "ON HOLD",
            "ordering": 4,
            "taskTypeCustomTaskStatusMapId": "",
          },
          {
            "customTaskStatusId": "CANCELLED",
            "customTaskStatus":"CANCELLED",
            "taskTypeId": "",
            "taskStatus": "CANCELLED",
            "ordering": 5,
            "taskTypeCustomTaskStatusMapId": "",
          }
        ]
         
          setEncounterSystemStates(customStatus)
          for (let i = 0; i < customStatus.length; i++) {
            await removeCustomStateListWithId(customStatus[i].customTaskStatusId)
          }
        }
      }
    } else {
      toasterFunction(" No Permission", "error");
    }
  };


  const hideForm = () => {
    setErrorMessage(false);
    setDropsampleidState({
      sampleClassificationId: "",
      sampleClassificationName: "",
    });
    setbodyTextFieldState({
      is_update: false,
      taskTypeId: "",
      taskTypeName: "",
      usage: "",
      usageCount: "",
      status: "",
    });
    if (isFormVisible.isOpen) {
      setFormVisible((prev) => ({
        ...prev,
        isOpen: (isFormVisible.isOpen = false),
        isFormVisible,
        isClose: (isFormVisible.isClose = true),
      }));
    }
    setError((prev) => ({
      ...prev,
      isNameError: isOrgError.isNameError = false,
      isCustomError: isOrgError.isCustomError = false,
    }));
    setEncounterSystemStates([])
    setsearchVal("");
    getListData(paginationdata)
  };

  const bredStyle = {
    fontSize: "12px",
  };



  const onSearchevent = (value) => {
    setsearchVal(value);
    getListData({
      page: 0,
      string: value,
    });
    if (value === "") {
      setIsNewData(true);
      setCurrentPage(1);
    }
  };
  const [totalPage, setTotalPage] = useState(0);

  const [searchVal, setsearchVal] = useState("");
  const handleCloseIconClick = (i, id) => {
    if (id === "") {
      deleteRow(i);
    }
  };
  const getUserByIdFunct = async (userId, taskTypeId) => {
    const res = await getUserByUserId(userId);
    if (res?.data?.content.length > 0) {
      setFullName(res?.data?.content[0].fullName);
    }
    let data = {
      taskTypeList: [taskTypeId],
    };
    const usageRes = await getUsageCountTag(data);
    if (usageRes?.data?.content.length > 0) {
      setUsageount(usageRes?.data?.totalElements);
    }

    const response = await getCustomTaskStatusByTaskTypeId(taskTypeId);
    if (response?.data?.length > 0) {
      let customStatus = []
      response?.data.forEach(element => {
        let newcustomObject = {
          "customTaskStatusId": element.customTaskStatus.customTaskStatusId,
          "customTaskStatus": element.customTaskStatus.customTaskStatus,
          "taskTypeId": element.taskTypeId,
          "taskStatus": element.customTaskStatus.taskStatus,
          "ordering": element.ordering,
          "taskTypeCustomTaskStatusMapId": element.taskTypeCustomTaskStatusMapId,
        }
        customStatus.push(newcustomObject)

      })
      setEncounterSystemStates([...customStatus]);
      for (let i = 0; i < customStatus.length; i++) {
        await removeCustomStateListWithId(customStatus[i].customTaskStatusId)
      }

    }
  };
  const getPageData = (page) => {
    setIsNewData(false);
    setCurrentPage(page);
    getListData({ string: "", page: page - 1 });
  };

  const getListData = async (data) => {
    const res = await getTypeListData(data);
    if (res?.data?.content) {
      setTypeListData(res.data.content);
      setTotalPage(res?.data?.totalPages);
      setTotalPageCalculate({
        numberOfElements: res?.data?.numberOfElements,
        number: res?.data?.number,
        totalElements: res?.data?.totalElements,
      });
    } else {
      setTypeListData([]);
    }
    const customstateList = await getCustomStatusListData({ "customTaskStatus": "", "page": 0, "size": 499 });
    const newArray = customstateList?.data?.content.filter(({ customTaskStatusId }) => !encounterSystemStates.some((e) => e.customTaskStatusId === customTaskStatusId))
    setCustomStateList(newArray)
  };



  const bodySubmitHandler = async (e) => {
    e.preventDefault();
    if (bodyAllTextFieldState.taskTypeName === "") {
      setError((prev) => ({
        ...prev,
        isNameError: isOrgError.isNameError = true,
        issampleError: isOrgError.isCustomError = true
      }));
    } else if (encounterSystemStates.length === 0) {
      setError((prev) => ({
        ...prev,
        isNameError: isOrgError.isNameError = false,
        isCustomError: isOrgError.isCustomError = true
      }));
    } else {
      setError((prev) => ({
        ...prev,
        isNameError: (isOrgError.isNameError = false),

        issampleError: (isOrgError.issampleError = false),
      }));
      const sendOrgDetails = {
        taskTypeId: bodyAllTextFieldState.taskTypeName
          .toUpperCase()
          .replace(" ", "-"),
        taskType: bodyAllTextFieldState.taskTypeName,
        taskTypeDetails: bodyAllTextFieldState.usage,

        status:
          bodyAllTextFieldState.status === "INACTIVE" ? "DELETED" : "ACTIVE",
        extension: {
          additionalProp1: bodyAllTextFieldState.usageCount,
          additionalProp2: "string",
          additionalProp3: "string",
        },
      };

      if (bodyAllTextFieldState.is_update === false) {
        const res = await createTaskType(sendOrgDetails);
        if (res?.data?.taskTypeId) {
          toasterFunction("Task type created successfully", "success");
          let result = await createUpdateCustomList(res?.data?.taskTypeId)
          hideForm()
        }
        else {
          toasterFunction(res?.error?.data?.message, "error");
        }
        if (res) {
          setTaskSubmitStatus(true);
        }
        else {
          setTaskSubmitStatus(false);
        }
      }
      else {
        const updatetaskpayload = {
          taskTypeDetails: bodyAllTextFieldState.usage,
          status:
            bodyAllTextFieldState.status === "INACTIVE" ? "DELETED" : "ACTIVE",

          taskTypeId: bodyAllTextFieldState.taskTypeId,
          taskType: bodyAllTextFieldState.taskTypeName,
        }
        const res = await updatigtasktype(updatetaskpayload);
        if (res?.data?.taskTypeId) {
          let result = await createUpdateCustomList(res?.data?.taskTypeId)
          toasterFunction("Task type Updated successfully", "success");
        } else {
          toasterFunction(res?.error?.data?.message, "error");
        }
        getTsaktypePage()
        hideForm()
      }


      setErrorMessage(true);
    }
  };

  const createUpdateCustomList = async (taskTypeId) => {
    let customStatus = []
    encounterSystemStates.forEach((element,i)=> {
      let newcustomObject = {
        "customTaskStatusId": element.customTaskStatusId,
        "taskTypeId": taskTypeId,
        "taskStatus": element.taskStatus,
        "ordering": i+1,
        "taskTypeCustomTaskStatusMapId": element.taskTypeCustomTaskStatusMapId,
      }
      customStatus.push(newcustomObject)
    });
    let res = await createUpdateCustomTaskStatus(customStatus)
    return res
  }

  const getTsaktypePage = async (page) => {
    const res = await getTypeListData({ string: "", page: page - 1, size: 20 });
    setCurrentPage(page)
    if (res?.data?.content) {
      setTypeListData(res?.data?.content)
      setTotalPage(res?.data?.totalPages)
      setTotalPageCalculate({ numberOfElements: res?.data?.numberOfElements, number: res?.data?.number, totalElements: res?.data?.totalElements })

    }
  }

  useEffect(() => {
    getListData(paginationdata);
    setIsNewData(true);
    setCurrentPage(1);
  }, []);

 
  return (
    <>
      <div className={props.expand1.empty === true ? "main1" : "main"}>
        <Breadcrums
          firsttab={"Categories"}
          secondtab="Command Centre"
          thirdtab="Task Type"
        ></Breadcrums>
        <ToastContainer />
        <Grid container mt={-2}>
          <Grid item xs={6}>
            <div
              className={
                isFormVisible.isOpen
                  ? "leftcontainer rounded-lg"
                  : "leftcontainer1 rounded-lg"
              }
            >
              <Grid className=" p-7 ">
                <TextField
                  label=""
                  onChange={(e) => onSearchevent(e.target.value)}
                  onKeyDown={(e) => {
                    e.key === "Enter" && e.preventDefault();
                  }}
                  sx={{ m: 1, width: "25ch" }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchOutlinedIcon />
                      </InputAdornment>
                    ),
                  }}
                  value={searchVal}
                />
              </Grid>
              <Grid className="editContainer rounded-radiusFourPix ">
                <Grid
                  disabled={access?.useradd == false}
                  onClick={() => {showFormActions(false)}}
                  className={isFormVisible.isOpen ? "addIconchange" : "addIcon"}
                >
                  <span className=" p-2 m-r-f ">Add</span>
                  <AddCircleOutlineIcon
                    style={{
                      fontSize: "14px",
                      color: "#545454",
                      marginBottom: "3px",
                      color: `${isFormVisible.isOpen ? "#ffffff" : "#545454"}`,
                    }}
                  />
                </Grid>
                <Grid className="addIcon">
                  <span className="p-2 m-r-f">Filter</span>
                  <img
                    src={filter}
                    alt="filter.png"
                    style={{
                      position: "absolute",
                      left: "90%",
                      right: "20px",
                      top: "15px",
                      bottom: "95px",
                      width: "11px",
                      paddingTop: "3px",
                    }}
                  ></img>
                </Grid>
              </Grid>
              <>
                <TaskTypelist
                  totalPage={totalPage}
                  setId={setId}
                  calculateData={calculateData}
                  getUserByIdFunct={getUserByIdFunct}
                  showFormActions={showFormActions}
                  setbodyTextFieldState={setbodyTextFieldState}
                  currentPage={isNewData ? 1 : currentPage}
                  data={typeListData}
                  getPageData={getPageData}
                  isFormVisible={isFormVisible}
                  getTsaktypePage={getTsaktypePage}
                  tasktypeview={access?.tasktypeview}
                  tasktypeupdate={access?.tasktypeupdate}
                />
              </>
            </div>
          </Grid>

          {isFormVisible.isOpen ? (
            <Grid item xs={6}>
              <div className="rightcontainer rounded-lg servicefullright">
                <div className="overflow-y-auto o-form-container">
                  <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={style}>
                      <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                      >
                        <Alert severity="info">
                          {" "}
                          {bodyAllTextFieldState.status === "INACTIVE"
                            ? "Are you sure you want to reactivate  the Task type ?"
                            : "Are you sure you want to Deactivate the Task type ?"}
                        </Alert>
                      </Typography>
                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {bodyAllTextFieldState.status === "INACTIVE"
                          ? "This activity will enable the Task type and user will be able to access it across all products."
                          : "This activity disable the Task type and user will not able to access it. You can reactivate the Task type at anytime?"}

                        <div className="mt-10 flex justify-center items-center  towButtonContainer">
                          <Button
                            type="submit"
                            onClick={hideForm}
                            variant="contained"
                            disableElevation
                            style={{
                              marginRight: "10px",
                              borderColor: "#0F4391",
                              width: "99px",
                              height: "39px",
                              borderRadius: "9999px",
                              backgroundColor: "#E8FAFF",
                              color: "#0F4391",
                              border: "1px solid #0F4391",
                              marginBottom: "100px",
                            }}
                          >
                            CANCEL
                          </Button>

                          <Button
                            type="submit"
                            onClick={halndleClickonProceed}
                            variant="contained"
                            disableElevation
                            style={{
                              borderColor: "#0F4391",
                              width: "99px",
                              height: "39px",
                              borderRadius: "9999px",
                              backgroundColor: "#E8FAFF",
                              color: "#0F4391",
                              border: "1px solid #0F4391",
                              marginBottom: "100px",
                            }}
                          >
                            PROCEED
                          </Button>
                        </div>
                      </Typography>
                    </Box>
                  </Modal>
                  <ToastContainer />
                  <>
                    <div className="font-normal text-o-form-label-color mainhead flex justify-between items-center pl-5 pr-1 h-10">
                      <h1>Task Type Overview</h1>
                    </div>

                    <form autoComplete="off" onSubmit={bodySubmitHandler}>
                      <div className=" pl-5 OrgNameContainer">
                        <h3
                          className="font-normal text-o-form-label-color"
                          id="Org"
                        >
                          Task Type Name
                          <span style={{ color: "red" }}> *</span>
                        </h3>
                        <TextField
                          onFocus={(e) => resetErrorHandler(e)}
                          autoComplete="OFF"
                          error={isOrgError.isNameError === true}
                          helperText={
                            isOrgError.isNameError
                              ? "Valid (Name) Required !"
                              : ""
                          }
                          onChange={(e) => whenInputsChange(e)}
                          name="taskTypeName"
                          value={bodyAllTextFieldState.taskTypeName}
                          style={{ width: "360px" }}
                          id="standard-basic"
                          label=""
                          variant="standard"
                        />
                      </div>
                      <div className=" pl-5 mt-5 OrgNameContainer">
                        <h3
                          className="font-normal text-o-form-label-color"
                          id="Org"
                        >
                          Usage
                        </h3>
                        <TextField
                          rows={3}
                          multiline
                          onFocus={(e) => resetErrorHandler(e)}
                          autoComplete="OFF"
                          onChange={(e) => whenInputsChange(e)}
                          name="usage"
                          value={bodyAllTextFieldState.usage}
                          style={{ width: "360px" }}
                          id="standard-basic"
                          label=""
                          variant="outlined"
                        />
                      </div>


                      <div className="mt-5 pl-5 OrgNameContainer ">
                        <table
                          className="a tableborder"
                        // style={{ width: "95%" }}
                        >
                          <thead className="a tableborder">
                            <tr>
                              <td
                                className="a m-r-f tableborder"
                                style={{ width: "" }}
                              >
                                Order No
                              </td>
                              <td
                                className="a m-r-f tableborder"
                                style={{ width: "" }}
                              >
                                Custom Task Status
                              </td>
                              <td
                                className="a m-r-f tableborder"
                                style={{ width: "" }}
                              >
                                System Task Status
                              </td>

                              <td
                                className="a m-r-f tableborder"
                                style={{ width: "" }}
                              >
                                Action
                              </td>
                            </tr>
                          </thead>
                          <tbody>
                            {encounterSystemStates &&
                              encounterSystemStates.length > 0 &&
                              encounterSystemStates.map((item, i) => {
                                return (
                                  <tr key={i} className="tableborder a">
                                    <td
                                      className=" m-r-f tableborder a"
                                      style={{
                                        width: "200px",
                                        paddingLeft: "",
                                        height: "5px",
                                      }}
                                    >{i + 1}</td>
                                    <td
                                      className=" m-r-f tableborder a"
                                      style={{
                                        width: "200px",
                                        paddingLeft: "",
                                        height: "5px",
                                      }}
                                    >
                                      <Autocomplete
                                        onKeyDown={(e) => {
                                          e.key === "Enter" &&
                                            e.preventDefault();
                                        }}
                                        value={item}
                                        autoHighlight={true}
                                        onChange={(e, v) =>
                                          handleDropdownStates(
                                            "systemStates",
                                            i,
                                            v
                                          )
                                        }
                                        getOptionLabel={(option) =>
                                          option ? option.customTaskStatus : ""
                                        }
                                        id="org-list-option"
                                        options={
                                          customStateList ? customStateList : []
                                        }
                                        sx={{ width: 300 }}
                                        renderInput={(params) => (
                                          <TextField
                                            variant="standard"
                                            {...params}
                                            label=""
                                          />
                                        )}
                                      />
                                    </td>
                                    <td
                                      className=" m-r-f tableborder a"
                                      style={{
                                        width: "200px",
                                      }}
                                    >

                                      <TextField
                                        onFocus={(e) =>
                                          resetErrorHandler(e)
                                        }
                                        onKeyDown={(e) => {
                                          e.key === "Enter" &&
                                            e.preventDefault();
                                        }}
                                        autoComplete="OFF"

                                        name="customState"
                                        value={item.taskStatus}
                                        style={{ width: "150px" }}
                                        id="standard-basic"
                                        disabled
                                        label=""
                                        variant="standard"
                                      />
                                    </td>

                                    <td
                                      className="tableborder"
                                      style={{
                                        width: "150px",
                                      }}
                                      onKeyDown={(e) => {
                                        e.key === "Enter" &&
                                          e.preventDefault();
                                      }}
                                    >
                                      <>
                                        <Tooltip
                                          title={
                                            item.customTaskStatusId === "TO_DO"
                                              ? "you are not able to delete this Custom Status"
                                              : ""
                                          }
                                        >
                                          <IconButton>
                                            <img
                                              className="h-8"
                                              src={Delete}
                                              onClick={() => {
                                                encounterSystemStates &&
                                                  encounterSystemStates[
                                                  i
                                                  ] && item.customTaskStatusId !== "TO_DO" &&
                                                  handleDeleteRow(
                                                    i,
                                                    item
                                                  );
                                              }}
                                            />
                                          </IconButton>
                                        </Tooltip>

                                      </>

                                    </td>
                                    <td
                                      className="tableborder"
                                      style={{
                                        width: "150px",
                                      }}
                                      onKeyDown={(e) => {
                                        e.key === "Enter" &&
                                          e.preventDefault();
                                      }}
                                    >
                                    <Button
                            className="m-2"
                            onKeyDown={(e) => {
                              e.key === "Enter" && e.preventDefault();
                            }}
                            onClick={(e, v) => addRowByIndex(i)}
                            variant="contained"
                            disableElevation
                            style={{
                              marginRight: "10px",
                              borderColor: "#0F4391",
                              width: "43px",
                              height: "22px",
                              borderRadius: "30px",
                              backgroundColor: "#E8FAFF",
                              color: "#0F4391",
                              border: "1px solid #0F4391",
                              margin: "14px",
                            }}

                          >
                            Add
                          </Button>
                          </td>
                        
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                        {isOrgError.isCustomError ? (
                          <div
                            className="custom-field-error"
                            style={{
                              color: "#d32f2f",
                              marginLeft: "20px",
                              marginTop: "15px",
                              fontSize: "0.75rem",
                            }}
                          >
                            Required Custom and System Task Status.
                          </div>
                        ) : null}
                        {customStateList?.length != 0 &&
                          <Button
                            className="m-2"
                            onKeyDown={(e) => {
                              e.key === "Enter" && e.preventDefault();
                            }}
                            onClick={(e, v) => addRow("loc")}
                            variant="contained"
                            disableElevation
                            style={{
                              marginRight: "10px",
                              borderColor: "#0F4391",
                              width: "43px",
                              height: "22px",
                              borderRadius: "30px",
                              backgroundColor: "#E8FAFF",
                              color: "#0F4391",
                              border: "1px solid #0F4391",
                              margin: "14px",
                            }}

                          >
                            Add
                          </Button>
                        }
                      </div>



                      {bodyAllTextFieldState.is_update === true ? (
                        <>
                          <div className=" pl-5 mt-5 OrgNameContainer">
                            <h3
                              className="font-normal text-o-form-label-color"
                              id="Org"
                            >
                              Usage Count
                            </h3>
                            <TextField
                              onFocus={(e) => resetErrorHandler(e)}
                              autoComplete="OFF"
                              name="usageCount"
                              value={usageCount}
                              style={{ width: "360px" }}
                              id="standard-basic"
                              disabled
                              label=""
                              variant="standard"
                            />
                          </div>
                          <div className="mt-5 pl-5 OrgNameContainer">
                            <h3 className="font-normal text-o-form-label-color">
                              Created By
                            </h3>
                            <p className="last-modified-value m-r-f">
                              <TextField
                                disabled
                                variant="standard"
                                autoComplete="OFF"
                                name="encounterType"
                                value={fullName}
                                style={{ width: "360px" }}
                                id="standard-basic"
                                label=""
                              />
                            </p>
                            <h3 className="mt-5 font-normal text-o-form-label-color">
                              Last Modified Date & Time
                            </h3>
                            <p className="last-modified-value m-r-f">
                              <TextField
                                disabled
                                variant="standard"
                                autoComplete="OFF"
                                name="encounterType"
                                value={new Date(
                                  bodyAllTextFieldState.lastModifiedTime
                                ).toLocaleString("en-US")}
                                style={{ width: "360px" }}
                                id="standard-basic"
                                label=""
                              />
                            </p>
                          </div>
                          <div className="mt-5 pl-5 OrgNameContainer ">
                            <h3 className="font-normal text-o-form-label-color">
                              Status
                            </h3>
                            <Autocomplete
                              autoHighlight={true}
                              value={
                                bodyAllTextFieldState &&
                                bodyAllTextFieldState.status
                              }
                              onChange={(e, v) =>
                                handleDropDownChange("status", v)
                              }
                              getOptionLabel={(option) =>
                                option ? option : ""
                              }
                              id="city-list-option"
                              onKeyDown={(e) => {
                                e.key === "Enter" && e.preventDefault();
                              }}
                              options={statuslist ? statuslist : []}
                              sx={{ width: 360 }}
                              renderInput={(params) => (
                                <TextField
                                  variant="standard"
                                  {...params}
                                  label=""
                                />
                              )}
                            />
                          </div>
                        </>
                      ) : (
                        ""
                      )}


                      <div className="mt-5  OrgNameContainer ">

                        <div className="mt-5 flex justify-center   towButtonContainer h-40">
                          <Button
                            onClick={hideForm}
                            type="submit"
                            variant="contained"
                            disableElevation
                            style={{
                              marginRight: "10px",
                              borderColor: "#0F4391",
                              width: "99px",
                              height: "39px",
                              borderRadius: "9999px",
                              backgroundColor: "#E8FAFF",
                              color: "#0F4391",
                              border: "1px solid #0F4391",
                            }}
                          >
                            CANCEL
                          </Button>

                          <Button
                            type="submit"
                            variant="contained"
                            disableElevation
                            style={{
                              borderColor: "#0F4391",
                              width: "99px",
                              height: "39px",
                              borderRadius: "9999px",
                              backgroundColor: "#E8FAFF",
                              color: "#0F4391",
                              border: "1px solid #0F4391",
                            }}
                          >
                            SAVE
                          </Button>
                        </div>
                      </div>
                    </form>
                  </>
                </div>
              </div>
            </Grid>
          ) : (
            ""
          )}
        </Grid>
      </div>
      <AdminCookieConsentBanner />
    </>
  );
};

export default TaskTypes;
