import * as React from "react";
import { useState, useEffect, useRef } from "react";
import { Grid } from "@mui/material";
import "../table.css";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Sampletable = (props) => {
  const { hasFocus, value } = props;

  //   const buttonElement = React.useRef(null);
  //   const rippleRef = React.useRef(null);

  // useEffect(()=>{
  // props.setTotalPage(props.data.content.totalPages)
  // getActiveValFun();
  // },[])

  const handleClick = (row) => {
    if (props.sampleupdate == true) {
      let data = {};
      data.is_update = true;
      data.sampleTypeName = row.sampleTypeName;
      data.status = row.status === "DELETED" ? "INACTIVE" : "ACTIVE";
      props.sampleclsslist.data.content.forEach((element) => {
        if (element.sampleClassificationId === row.sampleClassificationId) {
          let sampledata = {};
          sampledata.sampleClassificationId = row.sampleClassificationId;
          sampledata.sampleClassificationName =
            element.sampleClassificationName;
          props.setDropsampleidState({ ...sampledata });
        }
      });
      data.description = row.description;
      data.quantity = row.quantity;
      data.sampleTypeId = row.sampleTypeId;

      props.setbodyTextFieldState({ ...data });
      props.showFormActions();
      props.setErrorMessage(false);
      props.setId(row.sampleTypeId);
      // props.setDropsampleState({...data})
    } else {
      toast(" you didnt have permission", {
        position: "top-center",
        autoClose: 2000,
hideProgressBar: true,
        type: "error",
        closeOnClick: true,
        theme: "light",
      });
    }
  };

  const handleOnChange = (event, page) => {
    props.getsampageList(page);
  };

  return (
    <Grid className="">
      <div class="scrollit">
        {props &&
        props.sampleview === true &&
        props.data &&
        props.data.content &&
        props.data.content.length > 0 ? (
          <table
            className="b "
            style={{
              width: "90%",
              marginLeft: "20px",
              tableLayout: "fixed",
              height: "20vh",
            }}
          >
            <thead style={{ position: "sticky", zIndex: "1", top: "0" }}>
              <tr>
                <th className=" m-r-f" style={{ width: "50px" }}>
                  <input type="checkbox" id="" name="vehicle1" value="Bike" />
                </th>
                <th className=" m-r-f">Sample Name</th>
                <th className=" m-r-f">Quantity</th>
                <th className=" m-r-f">Status</th>
              </tr>
            </thead>
            <tbody>
              {props &&
                props.data &&
                props.data.content &&
                props.data.content.length > 0 &&
                props.data.content.map((item, i) => {
                  return (
                    <tr
                      key={i}
                      className="tablebor"
                      style={{
                        cursor: "pointer",
                        backgroundColor:
                          props.id === item.sampleTypeId
                            ? "rgb(15, 67, 145)"
                            : "",
                        color: props.id === item.sampleTypeId ? "white" : "",
                      }}
                    >
                      <td>
                        <input type="checkbox" id={i} />
                      </td>
                      <td
                        className="  m-r-f"
                        onClick={(event) => handleClick(item)}
                        style={{ cursor: "pointer" }}
                      >
                        <a>{item.sampleTypeName}</a>
                      </td>
                      <td
                        className="  m-r-f"
                        onClick={(event) => handleClick(item)}
                      >
                        {item.quantity}
                      </td>
                      <td
                        className=" m-r-f"
                        onClick={(event) => handleClick(item)}
                      >
                        <span
                          className={item.status === "ACTIVE" ? "dotg" : "dotr"}
                        ></span>{" "}
                        {item.status === "ACTIVE" ? " Active" : " Inactive"}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        ) : (
          <div className="record">No Records Found</div>
        )}
      </div>
      {/* {props && props.data && props.data.content && props.data.content.length>0 && */}
      <div className="pagesam pt-1 m-1">
        <Stack spacing={2}>
          <Pagination
            count={props.data?.totalPages}
            color="primary"
            page={props.currentPage}
            onChange={(e, page) => handleOnChange(e, page)}
          />
        </Stack>
        <div className="pagenum">
          <span className="ml-2">
            {props?.data?.number * 20 + 1}-
            {props?.data?.number * 20 + props?.data?.numberOfElements} of{" "}
            {props?.data?.totalElements}
          </span>
        </div>
      </div>
      {/* }    */}
    </Grid>
  );
};

export default Sampletable;
