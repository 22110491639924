import { useState, useEffect } from "react";


const useHealthcarePractitionerLogic = () => {
 
  const [healthCarePractitionerState, setHealthCarePractitionerState] =
    useState({
      is_upadte : false,
      title: "",
      userId: 0,
      id : 0,
      salutation: "",
      firstName: "",
      middleName: "",
      lastName: "",
      gender: "",
      registrationNumber: "",
      designation: "",
      qualification: "",
      specializations: [],
      department: "",
      mobileNumber: null,
      alternateMobileNumber: "",
      emailId: "",
      dob: "",
      roles: [],
      organizations: [],
      locations: [],
      bio: "",
      photo:"",
      signature:"",
      isPractitioner: false,
    });
    const [healthPractitionerErrors, setHealthPractitionerErrors] = useState({
      isFirstNameError: false,
      isDepartmentError: false,
      isLastNameError:false,
      isQualificationError: false,
      isRegNumberError: false,
      isPhotoError : false,
      isSignError : false,
      isMobileNumberError:false,
      isSpecializationError : false,
      isTitleLimitExceeded: false,
      isRegNumLimitExceeded: false,
      isDesignationLimitExceeded: false,
      isQualificationLimitExceeded: false,
      isDepartmentLimitExceeded: false,
      isBioLimitExceeded: false,
    });
    const [mobileValidationApi, setMobileValidation] = useState({});
  const handleInputChange = (e, i) => {
    const { name, value } = e.target;
    console.log(name)
    console.log(mobileValidationApi)
    if (name === "title") {
      setHealthCarePractitionerState((prev) => ({
        ...prev,
        [name]: value,
      }));
      if (value.length >= 50) {
        setHealthPractitionerErrors((prev) => ({
          ...prev,
          isTitleLimitExceeded: true,
        }));
      } else {
        setHealthPractitionerErrors((prev) => ({
          ...prev,
          isTitleLimitExceeded: false,
        }));
      }
    }
    if (name === "firstName" || name === "lastName" || name === "middleName") {
      setHealthCarePractitionerState((prev) => ({
        ...prev,
        [name]: value.replace(/[^A-Za-z ]/gi, ""),
      }));
    }
    if (
      name === "registrationNumber" ||
      name === "designation" ||
      name === "department"
    ) {
      setHealthCarePractitionerState((prev) => ({
        ...prev,
        [name]: value,
      }));
      switch (name) {
        case "registrationNumber":
          if (value.length >= 150) {
            setHealthPractitionerErrors((prev) => ({
              ...prev,
              isRegNumLimitExceeded: true,
            }));
          } else {
            setHealthPractitionerErrors((prev) => ({
              ...prev,
              isRegNumLimitExceeded: false,
            }));
          }
          break;
        case "designation":
          if (value.length >= 150) {
            setHealthPractitionerErrors((prev) => ({
              ...prev,
              isDesignationLimitExceeded: true,
            }));
          } else {
            setHealthPractitionerErrors((prev) => ({
              ...prev,
              isDesignationLimitExceeded: false,
            }));
          }
          break;
        case "department":
          if (value.length >= 150) {
            setHealthPractitionerErrors((prev) => ({
              ...prev,
              isDepartmentLimitExceeded: true,
            }));
          } else {
            setHealthPractitionerErrors((prev) => ({
              ...prev,
              isDepartmentLimitExceeded: false,
            }));
          }
          break;
      }
    }
    if (name === "qualification") {
      setHealthCarePractitionerState((prev) => ({
        ...prev,
        [name]: value,
      }));
      if (value.length >= 250) {
        setHealthPractitionerErrors((prev) => ({
          ...prev,
          isQualificationLimitExceeded: true,
        }));
      } else {
        setHealthPractitionerErrors((prev) => ({
          ...prev,
          isQualificationLimitExceeded: false,
        }));
      }
    }
    if (name === "mobileNumber" || name === "alternateMobileNumber") {
      if (value.trim().length<11) {
        setHealthCarePractitionerState((prev) => ({
          ...prev,
          [name]: value.replace(/[^0-9]/gi, ""),
        }));
      }
    }

    if (name === "bio") {
      setHealthCarePractitionerState((prev) => ({
        ...prev,
        [name]: value,
      }));
      if (value.length >= 300) {
        setHealthPractitionerErrors((prev) => ({
          ...prev,
          isBioLimitExceeded: true,
        }));
      } else {
        setHealthPractitionerErrors((prev) => ({
          ...prev,
          isBioLimitExceeded: false,
        }));
      }
    } if (name === "emailId") {
      setHealthCarePractitionerState((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleDropDownChange = (field, value) => {
    setHealthCarePractitionerState((prev) => ({ ...prev, [field]: value }));
  };

  const resetErrorHandler = (e) => {
    const { name } = e.target;
    if(name === "title") {
        setHealthPractitionerErrors((prev) => ({...prev,isTitleLimitExceeded: false })) 
    }
    if(name === "registrationNumber") {
        setHealthPractitionerErrors((prev) => ({...prev,isRegNumLimitExceeded: false })) ||
        setHealthPractitionerErrors((prev) => ({...prev,isRegNumberError: false })) 
    }
    if(name === "designation") {
        setHealthPractitionerErrors((prev) => ({...prev,isDesignationLimitExceeded: false })) 
    }
    if(name === "qualification") {
        setHealthPractitionerErrors((prev) => ({...prev,isQualificationLimitExceeded: false })) ||
        setHealthPractitionerErrors((prev) => ({...prev,isQualificationError: false })) 
    }
    if(name === "department") {
        setHealthPractitionerErrors((prev) => ({...prev,isDepartmentLimitExceeded: false })) ||
        setHealthPractitionerErrors((prev) => ({...prev,isDepartmentError: false }))
    }
    if(name === "bio") {
        setHealthPractitionerErrors((prev) => ({...prev,isBioLimitExceeded: false })) 
    }
    if(name === "firstName") {
        setHealthPractitionerErrors((prev) => ({...prev,isFirstNameError: false })) 
    }
    if(name === "lastName") {
        setHealthPractitionerErrors((prev) => ({...prev,isLastNameError: false })) 
    }
    if(name === "photo") {
        setHealthPractitionerErrors((prev) => ({...prev,isPhotoError: false })) 
    }
    if(name === "signature") {
        setHealthPractitionerErrors((prev) => ({...prev,isSignError: false })) 
    }
    if(name === "specializations") {
        setHealthPractitionerErrors((prev) => ({...prev,isSpecializationError: false })) 
    }
    if(name === "mobileNumber") {
      setHealthPractitionerErrors((prev) => ({...prev,isMobileNumberError: false })) 
  }
  };
  

  const resetAllErrors = () => {
    setHealthPractitionerErrors({
        isFirstNameError: false,
        isDepartmentError: false,
        isLastNameError:false,
        isQualificationError: false,
        isRegNumberError: false,
        isPhotoError: false,
        isSignError: false,
        isMobileNumberError: false,
        isSpecializationError: false,
        isTitleLimitExceeded: false,
        isRegNumLimitExceeded: false,
        isDesignationLimitExceeded: false,
        isQualificationLimitExceeded: false,
        isDepartmentLimitExceeded: false,
        isBioLimitExceeded: false,
      });
  }
 

  const resetHealthPractitionerData = () => {
    setHealthCarePractitionerState({
      is_upadte :false,
        title: "",
        userId: 0,
        id : 0,
        salutation: "",
        firstName: "",
        middleName: "",
        lastName: "",
        gender: "",
        registrationNumber: "",
        designation: "",
        qualification: "",
        specializations: [],
        department: "",
        mobileNumber: null,
        alternateMobileNumber: "",
        emailId: "",
        dob: "",
        roles: [],
        organizations: [],
        locations: [],
        bio: "",
        photo:"",
        signature:"",
      });
  }

  const exportAll = {
    healthCarePractitionerState,
    healthPractitionerErrors,
    handleInputChange,
    handleDropDownChange,
    setHealthCarePractitionerState,
    resetErrorHandler,
    resetHealthPractitionerData,
    resetAllErrors,
    setHealthPractitionerErrors,
    setMobileValidation,
    mobileValidationApi
  };

  return exportAll;
};

export default useHealthcarePractitionerLogic;
